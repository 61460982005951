const medicalCell = {
  fontWeight: "bold",
  color: "black",
  py: 0,
};

const medicalRow = {
  "&:last-child td, &:last-child th": {
    border: 0,
  },
};

const paddedMedicalCellHeader = {
  color: "black",
  width: "35%",
  fontSize: "1.2rem",
};

const paddedMedicalCell = {
  color: "black",
  width: "55%",
};

const paddedMedicalCellDate = {
  color: "black",
  pr: "13px",
};
const titleStyle = {
  fontSize: "28px",
};
const tableContainerStyle = {
  paddingLeft: "1.5rem",
};

export {
  medicalCell,
  medicalRow,
  paddedMedicalCellHeader,
  paddedMedicalCell,
  paddedMedicalCellDate,
  titleStyle,
  tableContainerStyle,
};
