import React, { useContext, useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import FormRenderer from "../../Admissions/Setup/FormRenderer/FormRenderer";
import admissionFormService from "../../../service/admissionFormService";
import { Form } from "../../Admissions/Setup/FormBuilder/Models/Form";
import { FormBuilderContext } from "../../Admissions/Setup/FormBuilder/FormBuilderContext";
import { sortSectionsAndFields } from "../../Admissions/Setup/FormBuilder/FormBuilderUtils";

export default function FormFill() {
  const params = useParams();
  const [studentId, setStudentId] = useState();
  const [searchParams] = useSearchParams();
  const elementInstanceId = searchParams.get("element_instance_id");
  const formBuilderContext = useContext(FormBuilderContext);
  const { form, updateForm } = formBuilderContext;
  useEffect(() => {
    admissionFormService
      .getFormRender(params.form_id, elementInstanceId)
      .then((response) => {
        const formObj = response.data;
        formObj.admission_form_pages = formObj.admission_form_pages.map(
          (page) => {
            const updatedPage = page;
            updatedPage.form_sections = sortSectionsAndFields(
              page.form_sections
            );
            return updatedPage;
          }
        );

        updateForm(new Form(formObj));
        setStudentId(response.data.student_id);
      });
  }, []);

  return form && <FormRenderer studentId={studentId} guardianView />;
}
