import * as React from "react";
import { styled } from "@mui/material/styles";
import SettingsIcon from "@mui/icons-material/Settings";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Container, Tabs, Tab, Button, Stack, Box } from "@mui/material";
import theme from "../utils";

const tableGroupBox = { borderTop: "1px solid #D3D4D5" };

const cssTabBordered = (isCompact) => ({
  borderRight: isCompact && "1px solid #D3D4D5",
});

const tableGroupContainer = (isCompact) => ({
  padding: isCompact && "0 !important",
});

const tableGroupButton = {
  p: "0 16px",
  color: "rgb(211, 212, 213)",
  textTransform: "capitalize",
};

export default function TableGroup({
  isCompact,
  setSearchParams,
  searchParams,
}) {
  const CssTabs = styled(Tabs)({
    "&.MuiTabs-root": {
      minHeight: 30,
    },
    "& .MuiTab-root": {
      color: theme.palette.primary.main,
      minHeight: 30,
      paddingTop: 0,
      paddingBottom: 0,
      borderRight: "1px solid #D3D4D5",
      textTransform: "capitalize",
      ...(isCompact && { fontSize: 10, minWidth: 65 }),
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#ffffff",
      backgroundColor: theme.palette.primary.main,
      borderRight: 0,
    },
  });

  const handleGroup = (value) => {
    searchParams.set("group", value);
    setSearchParams(searchParams);
  };

  const handleResetParams = () => {
    searchParams.delete("group");
    searchParams.delete("q");
    searchParams.delete("sort");
    searchParams.delete("direction");
    searchParams.delete("page");
    setSearchParams(searchParams);
  };

  return (
    <Box sx={tableGroupBox}>
      <Container
        maxWidth={false}
        variant={
          !isCompact && useMediaQuery(theme.breakpoints.up("xl"))
            ? "table-group"
            : "table-group-md"
        }
        sx={tableGroupContainer(isCompact)}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={isCompact && "center"}
        >
          <CssTabs value={0} sx={cssTabBordered(isCompact)}>
            <Tab label="All" onClick={() => handleResetParams()} />
            <Tab label="Group 1" onClick={() => handleGroup("group-1")} />
            <Tab label="Group 2" onClick={() => handleGroup("group-2")} />
            <Tab label="Group 3" onClick={() => handleGroup("group-3")} />
          </CssTabs>
          {!isCompact && (
            <Button
              startIcon={<SettingsIcon size="small" />}
              sx={tableGroupButton}
            >
              Table Settings
            </Button>
          )}
        </Stack>
      </Container>
    </Box>
  );
}
