import { Grid, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

export default function DecimalPlacesComponent({ selectedField }) {
  const { register, setValue } = useFormContext();

  useEffect(() => {
    setValue("decimalPlaces", selectedField.properties.decimalPlaces);
  }, [selectedField]);

  return (
    <Grid item md={12}>
      <TextField
        type="number"
        fullWidth
        label="Decimal Places"
        {...register("decimalPlaces")}
        disabled={selectedField.source_field_id}
      />
    </Grid>
  );
}
