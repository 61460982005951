import React, { useContext } from "react";
import { Box } from "@mui/material";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { SecurityGroupText } from "./SecurityGroup.style";
import securityGroupsService from "../../service/securityGroupsService";
import { PermissionsContext } from "../../context/PermissionsContext";
import APP_PERMISSIONS from "../../utils/constants/permissions";
import PERMISSION_TYPES from "../../utils/constants/permission_types";

export default function SecurityGroupRow({
  securityGroup,
  selectedSecurityGroup,
  setSelectedSecurityGroup,
  handleClone,
  setSnackBarOpen,
}) {
  const isSelected =
    selectedSecurityGroup && selectedSecurityGroup.id === securityGroup.id;

  const deleteSecurityGroup = async () => {
    if (securityGroup.user_security_groups.length > 0) {
      setSnackBarOpen({
        open: true,
        message: "Cannot delete. Security group has users assigned.",
        severity: "error",
      });
    } else {
      const response = await securityGroupsService.deleteSecurityGroup(
        securityGroup.id
      );

      if (response.data) {
        setSnackBarOpen({
          open: true,
          message: "Security group deleted.",
        });
      }
    }
  };

  const { hasPermission } = useContext(PermissionsContext);

  const hasManagePermission = hasPermission(
    APP_PERMISSIONS.SECURITY,
    PERMISSION_TYPES.MANAGE
  );

  return (
    <Box
      sx={SecurityGroupText(isSelected)}
      onClick={() => setSelectedSecurityGroup(securityGroup)}
    >
      {securityGroup.name}
      <Box>
        <CopyAllIcon
          sx={{ marginRight: "25px", cursor: "pointer" }}
          onClick={
            hasManagePermission ? () => handleClone(securityGroup) : null
          }
        />
        <DeleteOutlineOutlinedIcon
          sx={{ marginRight: "25px", cursor: "pointer" }}
          onClick={hasManagePermission ? () => deleteSecurityGroup() : null}
        />
      </Box>
    </Box>
  );
}
