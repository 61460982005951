import axios from "../utils/axios-util";

const groupsEndpoint = "/api/groups";

const fetchAllGroups = async () => {
  const { data } = await axios.get(groupsEndpoint);

  if (!data.data) {
    console.error("Error fetching groups from the API");
    return [];
  }
  return data;
};

const fetchGroup = async (params) => {
  const { data } = await axios.get(
    `${groupsEndpoint}/${params.params.groupId}`,
    params
  );

  if (!data.data) {
    console.error("Error fetching group from the API");
    return [];
  }
  return data;
};

const createGroup = async (params) => {
  const { data } = await axios.post(groupsEndpoint, params);

  if (!data.data) {
    console.error("Error posting group to the API");
    return [];
  }
  return data;
};

const updateGroup = async (params) => {
  const { data } = await axios.patch(
    `${groupsEndpoint}/${params.groupId}`,
    params
  );

  if (!data.data) {
    console.error("Error posting group to the API");
    return [];
  }
  return data;
};

const deleteGroup = async (groupId) => {
  const { data } = await axios.delete(`${groupsEndpoint}/${groupId}`);
  if (!data.data) {
    console.error("Error deleting group from the API");
    return [];
  }
  return data;
};

const fetchAddMembersList = async (params) => {
  const { data } = await axios.get(
    `${groupsEndpoint}/add_members_list`,
    params
  );

  if (!data.data) {
    console.error("Error fetching list from the API");
    return [];
  }
  return data;
};

export default {
  createGroup,
  fetchAllGroups,
  fetchAddMembersList,
  deleteGroup,
  updateGroup,
  fetchGroup,
};
