const assignmentsListTable = {
  color: "black",
};

const assignmentsListHead = (isCentered, isActive = false) => ({
  borderBottom: "none",
  color: isActive ? "black" : "#808080",
  cursor: "pointer",
  padding: 0,
  "& p": {
    fontSize: "14px",
    display: "flex",
    alignItems: "end",
    justifyContent: isCentered ? "center" : "flex-start",
  },
});

const assignmentsListCell = {
  color: "black",
  paddingLeft: 0,
  "& p": {
    fontSize: "16px",
  },
};

const assignmentsListCellName = {
  ...assignmentsListCell,
  "& p": {
    fontSize: "20px",
  },
};

const assignmentsListName = {
  width: "200px",
};

const assignmentsListHeadKlass = {
  width: "120px",
};

export {
  assignmentsListHeadKlass,
  assignmentsListName,
  assignmentsListHead,
  assignmentsListCell,
  assignmentsListTable,
  assignmentsListCellName,
};
