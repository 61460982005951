import React from "react";
// eslint-disable-next-line import/no-unresolved
import { stripHtml } from "string-strip-html";
import { useFormContext, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { recipientsTitle } from "./Recipients.styles";
import {
  contentContainer,
  contentTitleContainer,
  contentSubjectContainer,
  contentMessageContainer,
  contentMessageAlert,
} from "./Content.styles";
import ErasableTextField from "../../ErasableTextField";
import TextEditor from "../../TextEditor/TextEditor";
import { danger } from "../../sharedStyles";

export default function Content({
  isAlert,
  handleBodyValidation,
  descriptionError,
  editorRef,
}) {
  const { t } = useTranslation(["messages", "common"]);

  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Box sx={contentContainer}>
      <Box sx={contentTitleContainer}>
        <Typography sx={recipientsTitle}>{t("content")}</Typography>
      </Box>

      <Box sx={contentSubjectContainer}>
        <ErasableTextField
          id="subject"
          label={t("subject")}
          fullWidth
          isRequired
          type="text"
          defaultValue=""
          hasError={errors?.subject && true}
        />

        {errors?.subject && (
          <Typography sx={danger}>{t("fieldRequired")}</Typography>
        )}
      </Box>

      <Box sx={contentMessageContainer}>
        {isAlert && (
          <Typography sx={contentMessageAlert}>
            {t("alertOnlySubjectTextMessage")}
          </Typography>
        )}

        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <TextEditor
              {...field}
              isSimplified
              handleBodyValidation={handleBodyValidation}
              descriptionError={descriptionError}
              editorRef={editorRef}
              hasError={errors?.description}
            />
          )}
          rules={{
            validate: {
              required: (v) =>
                (v && stripHtml(v).result.length > 0) ||
                t("descriptionRequired"),
            },
          }}
          defaultValue=""
        />
        {errors?.description && (
          <Typography sx={danger}>{t("fieldRequired")}</Typography>
        )}
      </Box>
    </Box>
  );
}
