import FieldBase from "./FieldBase";

export default class DateField extends FieldBase {
  constructor(field) {
    super(field);
    this.label = field.label;
    this.dataType = "value_date";

    this.propertyComponents = ["name", "label", "supporting-text", "required"];
  }
}
