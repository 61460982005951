import { AttachFile } from "@mui/icons-material";
import { Box, Button, Chip, Grid, Typography } from "@mui/material";
import React, { useContext, useRef } from "react";
import { my5 } from "../sharedStyles";
import { SnackbarContext } from "../../context/SnackbarContext";

export default function Attachment({ uploadedFiles, setUploadedFiles }) {
  const snackbarContext = useContext(SnackbarContext);
  const fileInputRef = useRef(null);
  const handleFileUploads = (e) => {
    if (!e.target?.files) return;
    const files = [...uploadedFiles];
    Array.from(e.target.files).forEach((file) => {
      files.push(file);
    });

    try {
      if (files) {
        setUploadedFiles(files);
      }
    } catch (err) {
      if (err.error === "sizeException") {
        snackbarContext.setSnackbar({
          message: err.message,
          open: true,
          severity: "error",
        });
      }
    }
    fileInputRef.current.value = "";
  };

  const handleDeleteFile = (index) => {
    const cloneFiles = [...uploadedFiles];
    if (cloneFiles[index].id) cloneFiles[index].deleted = true;
    else cloneFiles.splice(index, 1);
    setUploadedFiles(cloneFiles);
  };

  const uniqueKey = (fileName, index) => `${fileName}_${index}`;

  return (
    <Grid
      container
      justifyContent="space-between"
      borderBottom="1px solid #BFC7CA"
      py={1.5}
      px={1}
    >
      <Grid item md={6} display="flex" alignItems="center">
        <AttachFile sx={{ transform: "rotate(45deg)", color: "#79747E" }} />
        <Typography variant="h6" fontWeight={400}>
          Attachments
        </Typography>
      </Grid>
      <Grid item md={6} display="flex" alignItems="center" justifyContent="end">
        <Button
          component="label"
          variant="text"
          size="large"
          sx={{ color: "#5077A4", fontWeight: 400 }}
          disableRipple
        >
          ADD ATTACHMENT
          <input
            hidden
            accept=".jpeg, .jpg, .pdf, .png, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .zip"
            type="file"
            multiple
            onChange={handleFileUploads}
            ref={fileInputRef}
          />
        </Button>
      </Grid>
      <Grid item md={12}>
        {uploadedFiles &&
          uploadedFiles?.map(
            (file, index) =>
              !file.deleted && (
                <Box sx={my5} key={uniqueKey(file.name, index)}>
                  <Chip
                    label={file.name}
                    onDelete={() => handleDeleteFile(index)}
                  />
                </Box>
              )
          )}
      </Grid>
    </Grid>
  );
}
