import theme from "../../../utils";

const tableContent = {
  marginY: "1vh",
};

const inboxRowCount = {
  backgroundColor: "black",
  ml: "50px ",
  fontSize: "16px",
  width: "35px",
  height: "35px",
};

const messagesRowName = {
  fontWeight: 500,
  lineHeight: "20px",
};

const tableRow = {
  "& .MuiTableCell-root": {
    fontSize: "11px",
  },
};

const tableCellProfileImage = {
  width: "10%",
};

const tableCellMessage = {
  width: "70%",
};

const badgeAnchorOrigin = {
  vertical: "bottom",
  horizontal: "left",
};

const badgeContent = {
  borderRadius: "50%",
  left: "6px",
  bottom: "8px",
};

const profileImage = {
  width: "100px",
  height: "100px",
  borderRadius: "50%",
  overflow: "hidden",
  backgroundColor: "blue",
};

const boldFont = {
  fontWeight: "600",
};

const mainColor = {
  color: theme.palette.success.main,
};

const minWidth = {
  width: "5%",
};

const dataFont = {
  fontSize: "16px",
};

const paginationStyle = {
  display: "flex",
  justifyContent: "center",
  mt: "3%",
};

export {
  tableContent,
  tableRow,
  tableCellProfileImage,
  tableCellMessage,
  badgeAnchorOrigin,
  badgeContent,
  profileImage,
  boldFont,
  mainColor,
  minWidth,
  dataFont,
  paginationStyle,
  inboxRowCount,
  messagesRowName,
};
