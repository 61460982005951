import React, { useState, useEffect } from "react";
import {
  Link as RouterLink,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import {
  klassStudentListTable,
  klassStudentListHeaderText,
  klassStudentListName,
  klassStudentListActive,
  klassStudentListBackBtn,
} from "./KlassStudentList.styles";
import klassesStudentsService from "../../../../service/klassesStudentsService";
import { cursorPointer } from "../../../sharedStyles";

export default function KlassStudentList({
  subjectId,
  schoolId,
  studentId,
  klassId,
}) {
  const [searchParams] = useSearchParams();
  const [klassStudents, setKlassStudents] = useState([]);
  const [order, setOrder] = useState("asc");
  const navigate = useNavigate();

  const klassesIds = searchParams.get("classes") || null;
  const termId = searchParams.get("term") || null;

  const getKlassStudents = async () => {
    const response = await klassesStudentsService.fetchKlassesStudentsInSubject(
      {
        params: {
          order,
          subject_id: subjectId,
          klasses_ids: klassesIds,
          term_id: termId,
          prek: false,
        },
      }
    );
    if (response.klass_students) {
      setKlassStudents(response.klass_students);
    }
  };

  const formattedParams = () => {
    let paramsUrl = "?";
    if (klassesIds) {
      paramsUrl = paramsUrl.concat(`&classes=${klassesIds}`);
    }

    if (termId) {
      paramsUrl = paramsUrl.concat(`&term=${termId}`);
    }
    return paramsUrl;
  };

  const renderSortChevron =
    order === "asc" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;

  const handleSortDirection = () =>
    order === "asc" ? setOrder("desc") : setOrder("asc");

  const isActive = (slug, klassStudentKlassId) =>
    slug === studentId && klassStudentKlassId === Number(klassId);

  useEffect(() => {
    getKlassStudents();
  }, [order, klassesIds]);

  return (
    <>
      <Box
        sx={klassStudentListBackBtn}
        onClick={() =>
          navigate(
            `/school/${schoolId}/subjects/${subjectId}/students${formattedParams()}`
          )
        }
      >
        <Typography>View list</Typography>
      </Box>
      <TableContainer sx={klassStudentListTable}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
                style={{ minWidth: 300 }}
                sortDirection={order}
                onClick={() => handleSortDirection()}
              >
                <Box sx={klassStudentListHeaderText}>
                  NAME
                  {renderSortChevron}
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {klassStudents.map((klassStudent) => (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={klassStudent.id}
                sx={
                  isActive(klassStudent.slug, klassStudent.klass_id)
                    ? klassStudentListActive
                    : null
                }
              >
                <TableCell sx={cursorPointer}>
                  <Link
                    to={`/school/${schoolId}/subjects/${subjectId}/classes/${
                      klassStudent.klass_id
                    }/students/${klassStudent.slug}${formattedParams()}`}
                    underline="none"
                    component={RouterLink}
                  >
                    <Typography sx={klassStudentListName}>
                      {`${klassStudent.first_name} ${klassStudent.last_name} (${klassStudent.klass.abbreviation})`}
                    </Typography>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
