import React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { title, datePicker } from "./Profile.styles";
import { pb15, pl20, pt20, ml30, black } from "../../../sharedStyles";

export default function Demographic({ kidInfo }) {
  const { t } = useTranslation("guardian", { keyPrefix: "studentProfile" });

  return (
    <>
      <Typography sx={title}>{t("demographics")}</Typography>

      <FormGroup sx={[pl20, pt20, black]}>
        <FormControlLabel
          sx={pb15}
          control={<Checkbox checked={Boolean(kidInfo.denomination)} />}
          label={t("isChurchMember")}
        />

        <Box sx={[pb15, ml30]}>
          <FormControl fullWidth>
            <InputLabel id="denomination-label">{t("denomination")}</InputLabel>
            <Select
              disabled
              labelId="denomination-label"
              id="denomination"
              value={kidInfo.denomination || ""}
              label={t("denomination")}
            >
              {kidInfo.denominations.map((d) => (
                <MenuItem key={d.id} value={d.name}>
                  {d.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <FormControlLabel
          sx={pb15}
          control={<Checkbox checked={Boolean(kidInfo.baptism_year)} />}
          label={t("isBaptizedAdventist")}
        />

        <Box sx={[ml30, datePicker]}>
          <DesktopDatePicker
            onChange={() => {}}
            disabled
            id="baptism_date"
            label={t("baptismDate")}
            value={kidInfo.baptism_year}
            renderInput={(params) => <TextField {...params} />}
          />
        </Box>
      </FormGroup>
    </>
  );
}
