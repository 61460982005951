import FieldBase from "./FieldBase";

export default class SignatureField extends FieldBase {
  constructor(field) {
    super(field);
    this.isResizable = false;
    this.label = field.label;
    this.dataType = "value_text";
    this.propertyComponents = ["name", "label", "required"];
  }
}
