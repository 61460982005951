import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";
import theme from "../../../utils";

const CssTextField = styled(TextField)({
  "&.MuiFormControl-root": {
    display: "block",
  },
  "& .MuiOutlinedInput-root": {
    backgroundColor: "#ffffff !important",
    color: theme.palette.primary.main,
    borderRadius: 3,
    "&:before": {
      borderBottom: "0 !important",
    },
    "& fieldset": {
      borderColor: theme.palette.primary.main,
    },
  },
});

const infoContactsFields = {
  "& .MuiTextField-root": {
    "& .MuiInputBase-root": {
      width: "100%",
    },
  },
};
const infoMedicalFormControl = {
  "& .MuiFormControlLabel-label": {
    fontSize: "25.92px",
    color: "black",
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: theme.palette.hover.primary,
  },
};

const infoContactsRow = {
  py: "10px",
  borderBottom: 1,
  borderColor: "primary.main",
};

const infoEmergency = {
  width: "54%",
};

export {
  CssTextField,
  infoContactsFields,
  infoContactsRow,
  infoEmergency,
  infoMedicalFormControl,
};
