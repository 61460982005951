const klassFormTeacher = {
  padding: "25px",
};

const clearIconStyle = (value) => ({
  visibility: value ? "visible" : "hidden",
});

const arrowDownIconStyle = (value) => ({
  "& .MuiSelect-iconOutlined": {
    display: value ? "none" : "",
  },
  "&.Mui-focused .MuiIconButton-root": {
    color: "primary.main",
  },
});

export { klassFormTeacher, clearIconStyle, arrowDownIconStyle };
