const gradeCard = {
  boxSizing: "border-box",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  gap: "3px",
  width: "163px",
  height: "189px",
  border: "1px solid #C4C4C4",
  borderRadius: "4px",
  marginLeft: "6px",
  color: "#000000",
};

const gradeCardContent = {
  padding: "0px",
};

const gradeCardContentRow1 = {
  fontWeight: 500,
  fontSize: "15px",
  lineHeight: "18px",
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "center",
  letterSpacing: "-0.05em",
  textTransform: "uppercase",
  height: "30px",
  marginBottom: "10px",
  marginTop: "10px",
};

const gradeCardContentRow2 = {
  lineHeight: "70px",
  textAlign: "center",
  letterSpacing: "-0.06em",
};

const gradeCardContentRow3 = {
  textAlign: "center",
  paddingTop: "10px",
};

const gradeBox = {
  fontSize: "28px",
  lineHeight: "36px",
  letterSpacing: "-0.05em",
  color: "#000000",
  pb: "10px",
};

export {
  gradeCard,
  gradeCardContent,
  gradeCardContentRow1,
  gradeCardContentRow2,
  gradeCardContentRow3,
  gradeBox,
};
