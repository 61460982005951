import { useMediaQuery } from "@mui/material";

const generalInfoContainer = {
  borderRight: 1,
  borderColor: "divider",
  py: "45px !important",
};

const generalInfoGrid = { borderBottom: 1, borderColor: "divider", margin: 0 };

const generalInfoColoredStack = { color: "#000000", mt: "30px" };

const generalInfoRightStack = { textAlign: "center", ml: "45px" };

const generalInfoScheduleContainer = () => ({
  py: "45px !important",
});

const generalInfoChip = {
  color: "#ffffff",
  width: "55px",
  borderRadius: "4px",
  mx: "auto",
  my: "20px",
};

const generalInfoImageContainer = {
  width: 250,
  height: 325,
  bottom: 47,
  position: "relative",
};

const generalInfoImageStyle = {
  width: "inherit",
  height: "inherit",
  objectFit: "contain",
  position: "absolute",
};

const generalInfoStackSpacing = (theme) =>
  useMediaQuery(theme.breakpoints.down("xl")) ? "space-around" : "center";

const generalInfoStackColor = {
  color: "#000000",
};

export {
  generalInfoContainer,
  generalInfoGrid,
  generalInfoColoredStack,
  generalInfoRightStack,
  generalInfoScheduleContainer,
  generalInfoChip,
  generalInfoImageContainer,
  generalInfoImageStyle,
  generalInfoStackSpacing,
  generalInfoStackColor,
};
