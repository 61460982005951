import { formContainer, danger } from "./sharedStyles";

const form = { ...formContainer, paddingTop: 2 };
const schoolYearField = { width: "15rem" };
const checkbox = { float: "right" };
const helperText = {
  maxWidth: "250px",
  ".MuiFormHelperText-root": {
    color: `${danger.color} !important`,
  },
};

export { form, schoolYearField, checkbox, helperText };
