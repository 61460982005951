import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography, Box, Stack, Divider } from "@mui/material";
import {
  guardianNotificationsGrid,
  guardianNotificationContainer,
  guardianNotificationTitle,
} from "./Guardian.styles";
import { font12, mb15 } from "../../../sharedStyles";
import NotificationCheckmarkSelect from "./NotificationCheckmarkSelect";
import NotificationsSettings from "./NotificationsSettings";

export default function Notifications({
  currentGuardian,
  studentId,
  studentSlug,
}) {
  const { t } = useTranslation("notifications", { keyPrefix: "settings" });
  const [studentSetting, setStudentSetting] = useState();

  const getSettingBy = () => {
    let setting = currentGuardian?.notifications_profile?.settings;

    if (setting && setting.length > 0) {
      if (studentId) {
        // eslint-disable-next-line prefer-destructuring
        setting = setting.filter((s) => s.student_id === Number(studentId))[0];
      } else {
        // eslint-disable-next-line prefer-destructuring
        setting = setting.filter((s) => s.student_slug === studentSlug)[0];
      }
    }

    setStudentSetting(setting || {});
  };

  useEffect(() => {
    if (!studentId || studentSlug) return;

    getSettingBy();
  }, [studentId, studentSlug]);

  useEffect(() => {
    if (!studentSlug || studentId) return;

    getSettingBy();
  }, [studentSlug, studentId]);

  return (
    <Box sx={guardianNotificationContainer}>
      <Typography sx={guardianNotificationTitle}>{t("messages")}</Typography>

      <Stack direction="row" justifyContent="end">
        <Box sx={guardianNotificationsGrid}>
          <Typography sx={font12}>{t("email")}</Typography>
        </Box>
        <Box sx={guardianNotificationsGrid}>
          <Typography sx={font12}>{t("text")}</Typography>
        </Box>
      </Stack>

      <NotificationCheckmarkSelect
        label={t("receiveMessages")}
        id="email_notifications"
        currentGuardian={currentGuardian}
      />

      <Divider sx={mb15} />

      <Typography sx={guardianNotificationTitle}>
        {t("notifications")}
      </Typography>

      {studentSetting && (
        <>
          <Typography>
            {t("settingsFor")} {currentGuardian?.student_name}
          </Typography>

          <NotificationsSettings
            setting={studentSetting}
            enabled={currentGuardian?.has_portal_access}
          />
        </>
      )}
    </Box>
  );
}
