const studentsSubjectTableHeadCell = {
  width: "100px",
  padding: "16px 0",
  color: "gray",
  fontSize: "14px",
  cursor: "pointer",
  borderBottom: "none",
};

const studentsSubjectTableHeadCellActive = {
  ...studentsSubjectTableHeadCell,
  color: "black",
};

const studentsSubjectTableCell = (cursor) => ({
  ...(cursor && { cursor: "pointer" }),
  ...(!cursor && { cursor: "default" }),
  px: "10px",
  py: "15px",
  width: "1%",
  whiteSpace: "no-wrap",
});

const studentsSubjectTableHeaderText = (header) => ({
  display: "flex",
  alignItems: "center",
  mx: header === "alerts" ? "50px" : "0",
  justifyContent:
    header === "student" || header === "alerts" ? "flex-start" : "center",
});

const studentsSubjectTableName = {
  color: "black",
  fontSize: "20px",
};

const studentTableCell = {
  fontSize: "16px",
  color: "black",
};

const studentsSubjectNameCell = (cursor) => ({
  ...(cursor && { cursor: "pointer" }),
  width: "200px",
  textAlign: "left",
});

export {
  studentsSubjectTableHeadCell,
  studentsSubjectTableCell,
  studentsSubjectTableHeadCellActive,
  studentsSubjectTableHeaderText,
  studentsSubjectTableName,
  studentTableCell,
  studentsSubjectNameCell,
};
