import axios from "../utils/axios-util";

const permissionsEndpoint = "/api/permissions";

const fetchAll = async (params) => {
  const { data } = await axios.post(permissionsEndpoint, params);

  if (!data.data) {
    console.error("Error fetching permissions from API");
    return [];
  }
  return data;
};

const fetchCurrentUserPermission = async (params) => {
  const { data } = await axios.post(`${permissionsEndpoint}/current`, params);

  if (!data.data) {
    console.error("Error fetching user permissions from API");
    return [];
  }
  return data.data;
};

export default {
  fetchAll,
  fetchCurrentUserPermission,
};
