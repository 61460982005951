import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { cursorPointer } from "../../sharedStyles";
import { tableStyles } from "./KlassRoster.style";

export default function ClassStudents({ students }) {
  return (
    <TableContainer>
      <Table sx={tableStyles} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Grade</TableCell>
            <TableCell align="left">Student</TableCell>
            <TableCell align="center">Gender</TableCell>
            <TableCell align="left">D.O.B</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {students.map((student) => (
            <TableRow key={student.id} hover>
              <TableCell align="center" scope="student" sx={cursorPointer}>
                <Typography>{student.grade_level}</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>
                  {student.first_name} {student.last_name}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography>{student.gender?.charAt(0)}</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>
                  {student.date_of_birth
                    ? new Date(
                        student.date_of_birth
                          .replace(/-/g, "/")
                          .replace(/T.+/, "")
                      ).toLocaleString("en-US", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      })
                    : ""}{" "}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
