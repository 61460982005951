import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { font14, loader, mt25 } from "../../../sharedStyles";
import messagesService from "../../../../service/messagesService";
import {
  conversationContainer,
  conversationInner,
  conversationTitle,
  backButton,
  backArrow,
  conversationDeletedMessagesContainer,
} from "./Messages.styles";
import ConversationBox from "./ConversationBox";

export default function GuardianKidsInfoConversationMessages() {
  const { t } = useTranslation("messages");
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const conversationKey = params.conversation_key;
  const [originalMessage, setOriginalMessage] = useState(null);
  const [conversationMessages, setConversationMessages] = useState([]);
  const hasConversation = conversationMessages.length > 0 && !loading;
  const navigate = useNavigate();
  const guardianId = params.id;
  const kidId = params.kid_id;
  const [deletedMessages, setDeletedMessages] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await messagesService.fetchConversation(
          conversationKey
        );

        if (response) {
          const messages = [
            response.data.original_message,
            ...response.data.replies,
          ];

          setOriginalMessage(response.data.original_message);
          setConversationMessages(
            messages.filter((message) => !message.is_deleted)
          );
          setDeletedMessages(messages.filter((message) => message.is_deleted));
          setLoading(false);
        }
      } catch (e) {
        // handle catch
      }
    })();
  }, []);

  if (loading) {
    return <CircularProgress sx={loader} size={100} />;
  }

  return (
    <>
      <Button
        onClick={() =>
          navigate(`/guardian/${guardianId}/students/${kidId}/messages`)
        }
        sx={backButton}
      >
        <ArrowBackRoundedIcon size="small" sx={backArrow} />
        {t("viewAllMessages")}
      </Button>
      <Container maxWidth={false} variant="header" sx={conversationContainer}>
        <Container sx={conversationInner}>
          <Typography sx={conversationTitle}>
            {hasConversation && originalMessage?.subject}
          </Typography>

          <Box sx={mt25}>
            {hasConversation ? (
              conversationMessages.map((message) => (
                <ConversationBox message={message} key={message.id} />
              ))
            ) : (
              <Typography align="center">
                {t("conversationNotExist")}
              </Typography>
            )}
          </Box>

          {deletedMessages.length > 0 && (
            <Box sx={conversationDeletedMessagesContainer}>
              <Typography sx={font14}>
                {t("deletedMessages", { count: deletedMessages.length })}
              </Typography>
            </Box>
          )}
        </Container>
      </Container>
    </>
  );
}
