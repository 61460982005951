const klassStudentListTable = {
  borderRight: "1px solid black",
  maxHeight: "100vh",
  minHeight: "100vh",
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: 0,
  },
};

const klassStudentListHeaderText = {
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  color: "black",
};

const klassStudentListName = {
  fontSize: "20px",
  color: "black",
};

const klassStudentListActive = {
  backgroundColor: "rgba(0, 0, 0, 0.04)",
};

const klassStudentListBackBtn = {
  position: "absolute",
  zIndex: 9999,
  right: "10px",
  cursor: "pointer",
  p: {
    color: "#5077A4",
  },
};

export {
  klassStudentListTable,
  klassStudentListHeaderText,
  klassStudentListName,
  klassStudentListActive,
  klassStudentListBackBtn,
};
