import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Tooltip } from "@mui/material";
import gradeCheckIconContainer from "./GradeCheckIcon.styles";

export default function GradeCheckIcon({ isGreen, needsContainer = true }) {
  const tooltip = isGreen
    ? "Grades are posted"
    : "Grade changed after being posted";
  const color = isGreen ? "success" : "error";

  return (
    <Box sx={needsContainer && gradeCheckIconContainer}>
      <Tooltip title={tooltip} placement="left-start" arrow>
        <CheckCircleIcon color={color} />
      </Tooltip>
    </Box>
  );
}
