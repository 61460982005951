import React, { useContext } from "react";
import TextEditor from "../../../../../TextEditor/TextEditor";
import { FormBuilderContext } from "../../FormBuilderContext";

export default function DescriptionArea({ field }) {
  const formBuilderContext = useContext(FormBuilderContext);
  const { form, currentSectionIndex, currentPageIndex, updateForm } =
    formBuilderContext;

  const onNotesChange = (data) => {
    if (field.properties.descriptionText === data) return;
    const formUpdate = { ...form };
    const fieldIndex = formUpdate.formPages[currentPageIndex].sections[
      currentSectionIndex
    ].fields.findIndex((f) => f.fieldId === field.fieldId);
    if (
      formUpdate.formPages[currentPageIndex].sections[currentSectionIndex]
        .fields[fieldIndex]
    )
      formUpdate.formPages[currentPageIndex].sections[
        currentSectionIndex
      ].fields[fieldIndex].properties.descriptionText = data;
    updateForm(formUpdate);
  };

  return (
    <TextEditor
      style={{ backgroundColor: "#f7fcfe" }}
      value={field.properties.descriptionText}
      onChange={(data) => onNotesChange(data)}
    />
  );
}
