const gridContainer = { pt: "20px", color: "#000000" };

const gridItemSchoolInfo = {
  fontSize: "22px",
  letterSpacing: "-0.05em",
};

const gridItemTeacherInfo = {
  textAlign: "end",
  fontSize: "22px",
  letterSpacing: "-0.05em",
};

const displayInline = { display: "inline-grid" };

const gridItemTeacherInfoPrincipalLabel = {
  display: "inline-grid",
  fontSize: "16px",
  pl: "10px",
};

const gridItemTeacherInfoLabel = {
  ...gridItemTeacherInfoPrincipalLabel,
  pr: "20px",
};

export {
  gridContainer,
  gridItemSchoolInfo,
  displayInline,
  gridItemTeacherInfo,
  gridItemTeacherInfoLabel,
  gridItemTeacherInfoPrincipalLabel,
};
