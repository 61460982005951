import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSubmitting } from "../context/SubmittingContext";
import axiosClient from "./axios-util";
import { removeToken } from "./constants/auth";
import { getStoredCurrentSchoolId } from "./constants/schoolIdStore";

const AxiosSubmitInterceptor = ({ children }) => {
  const { setSubmitting } = useSubmitting();
  const watchedMethods = ["post", "put", "patch", "delete"];

  const navigate = useNavigate();

  useEffect(() => {
    axiosClient.interceptors.request.use(
      (req) => {
        if (watchedMethods.includes(req.method)) {
          setSubmitting(true);
        }
        return req;
      },
      (err) => {
        setSubmitting(false);
        return Promise.reject(err);
      }
    );

    axiosClient.interceptors.response.use(
      (res) => {
        if (watchedMethods.includes(res.config.method)) {
          setSubmitting(false);
        }
        return res;
      },
      (err) => {
        setSubmitting(false);
        if (err.response.status === 422) {
          throw err;
        }
        return Promise.reject(err);
      }
    );
  }, [setSubmitting]);

  useEffect(() => {
    axiosClient.interceptors.response.use(
      (res) => res,
      (error) => {
        const isTokenExpired = error.response.data === "Signature has expired";
        const unprocessableEntity = 422;

        if (
          window.location.href !== `${window.origin}/login` &&
          error.response.status !== unprocessableEntity
        ) {
          if (isTokenExpired) {
            removeToken();
            window.location.href = `${window.origin}/login`;
          } else {
            // window.location.href = `${window.origin}/${error.response.status}`;
          }
        }

        return Promise.reject(error);
      }
    );
  }, [navigate]);

  const url = window.location.href;
  const urlParts = url.split("/");
  const schoolId = urlParts[3] === "school" ? urlParts[4] : null;

  axiosClient.interceptors.request.use(
    (req) => {
      req.headers["X-School-Slug"] =
        schoolId || getStoredCurrentSchoolId() || "";
      return req;
    },
    (error) => Promise.reject(error)
  );

  return children;
};

export default AxiosSubmitInterceptor;
