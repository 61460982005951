import { tableCellClasses, tableRowClasses } from "@mui/material";
import theme from "../../../utils";

const profileFilesGrid = { display: "flex", justifyContent: "space-between" };

const profileFilesButton = {
  color: theme.palette.text.strongBlue,
  textTransform: "none",
};
const profileFilesRow = {
  "&:last-child td, &:last-child th": {
    border: 0,
  },
};
const profileVisibleByGrid = {
  textAlign: "right",
  pl: "0px !important",
};

const button = {
  height: "fit-content",
  textTransform: "none",
};

const tableCellName = {
  width: "35%",
  fontSize: 25,
};
const tableCellType = {
  width: "10%",
  fontSize: 25,
};
const tableCellDateAdded = {
  width: "35%",
  fontSize: 25,
};
const tableStyles = {
  minWidth: 650,
  [`& .${tableCellClasses.root}`]: {
    borderBottom: "none",
  },
  [`& .${tableRowClasses.root}:not(:last-child)`]: {
    borderBottom: "1px solid #d9d9d9",
  },
  "& .MuiTableCell-head": {
    color: "#8f8e8e",
    fontWeight: "500 !important",
  },
  "& .MuiTableCell-body": {
    color: "black",
    fontSize: 16,
  },
};
const actionContainerStyle = {
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
};
const actionButtonStyle = {
  fontSize: "1.75rem",
  marginRight: "0.25rem",
};

export {
  profileFilesRow,
  profileFilesButton,
  profileFilesGrid,
  profileVisibleByGrid,
  button,
  tableCellName,
  tableCellType,
  tableCellDateAdded,
  tableStyles,
  actionButtonStyle,
  actionContainerStyle,
};
