const hidden = { visibility: "hidden" };

const listName = {
  width: "250px",
};

const listSubject = {
  width: "250px",
};

const listDescription = {
  width: "300px",
};

const settingsCell = {
  color: "#808080",
  cursor: "pointer",
};

const tableContainer = { mx: "65px" };
const divider = { borderColor: "black" };
const listTable = {
  color: "black",
};
const listHead = (isCentered, isActive = false) => ({
  borderBottom: "none",
  color: isActive ? "black" : "#808080",
  cursor: "pointer",
  padding: 0,
  "& p": {
    fontSize: "14px",
    display: "flex",
    alignItems: "end",
    justifyContent: isCentered ? "center" : "flex-start",
  },
});

const listCell = (isCentered = false) => ({
  padding: 2,
  color: "black",
  paddingLeft: isCentered ? 2 : 0,
});

const listCellName = {
  ...listCell(),
  "& p": {
    fontSize: "20px",
  },
};

export {
  listName,
  listSubject,
  listDescription,
  settingsCell,
  hidden,
  tableContainer,
  divider,
  listTable,
  listHead,
  listCellName,
  listCell,
};
