import React from "react";
import { TableRow, TableCell, Typography } from "@mui/material";
import {
  calculationsTableCell,
  calculationsLastRow,
} from "./Calculations.styles";
import formattedGrade from "../../../../utils/formattedGrade";

export default function CalculationsRow({
  category,
  isLastRow,
  markingCodes,
  specialMarks,
  isPassFail,
  viewTotalOverHundred,
}) {
  const categoryLetterGrade = () => {
    const score = category.category_score;

    if (score === null) {
      return false;
    }

    if (score === 0) {
      return "";
    }

    if (isPassFail) {
      return score >
        Number(
          markingCodes?.find((markingCode) => markingCode.code === "P")?.value
        )
        ? "P"
        : "F";
    }

    const letterGrade = formattedGrade(score, 100, markingCodes, specialMarks);
    return letterGrade;
  };

  const calculationResult = `${
    category.category_score
  }% ${categoryLetterGrade()}`;

  return (
    <TableRow sx={isLastRow ? calculationsLastRow : { position: "relative" }}>
      <TableCell sx={calculationsTableCell}>{category.name}</TableCell>
      <TableCell sx={calculationsTableCell}>
        {category.category_score === null ? "--" : calculationResult}
      </TableCell>
      <TableCell sx={calculationsTableCell} />
      <TableCell sx={calculationsTableCell}>
        {viewTotalOverHundred ? (
          <Typography> {category.category_weight} / 100</Typography>
        ) : (
          <Typography> {category.category_weight}% of grade</Typography>
        )}
      </TableCell>
    </TableRow>
  );
}
