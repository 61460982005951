import React from "react";
import { Grid, Typography } from "@mui/material";
import { birthdaysHeader } from "./Birthdays.style";

export default function BirthdaysHeader({ content }) {
  return (
    <Grid container sx={birthdaysHeader} className="header-info">
      <Typography fontSize={32} color="black">
        {content}
      </Typography>
      <Typography fontSize={11} color="black">
        Date:{" "}
        {new Date().toLocaleDateString("en-US", {
          weekday: "long",
          month: "long",
          day: "numeric",
          year: "numeric",
        })}
      </Typography>
    </Grid>
  );
}
