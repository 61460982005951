import FieldBase from "./FieldBase";

export default class DescriptionArea extends FieldBase {
  constructor(field) {
    super(field);
    this.label = field.label;
    this.dataType = "value_text";
    this.propertyComponents = ["name", "label"];
    this.properties.formBuilderField = true;
    this.properties.descriptionText = field?.properties?.descriptionText || "";
    this.w = 12;
    this.h = 3;
    this.draggableItemStyle = {};
    this.fieldContainerStyle = { height: "100%" };
    this.isResizable = false;
  }
}
