import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { fetchGuardianKidSchedule } from "../../../../service/guardiansService";
import moment from "../../../../utils/constants/momentConfig";
import dateTimeFormats from "../../../../utils/constants/dateTimeFormats";
import { weekLongDate, onlyTime } from "../../../../utils/dateTimePresets";
import { textCenter } from "../../../sharedStyles";
import {
  scheduleBox,
  scheduleBoxDate,
  scheduleCard,
  tableContainer,
  tableHead,
  tableBodyTime,
  tableBodyClass,
} from "./Schedule.styles";

export default function GuardianKidsInfoHomeSchedule() {
  const { t, i18n } = useTranslation(["guardian", "common"]);
  const params = useParams();
  const guardianId = params.id;
  const kidId = params.kid_id;
  const [kidClassSchedule, setKidClassSchedule] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);

  const displayTime = (period) => {
    const time = (strDate) =>
      onlyTime(strDate, { language: i18n.language, timeZone: "UTC" });

    return `${time(period.start_time)} - ${time(period.end_time)}`;
  };

  function setCurrentDateToToday() {
    const today = moment();
    searchParams.set("date", today.format(dateTimeFormats.YYYYMMDD));
    return today;
  }

  const currentDate =
    searchParams.get("date") !== null
      ? moment(searchParams.get("date"))
      : setCurrentDateToToday();

  const directions = { back: 1, forward: 2 };

  useEffect(() => {
    (async () => {
      if (guardianId && kidId) {
        try {
          setKidClassSchedule([]);
          setLoading(true);

          const response = await fetchGuardianKidSchedule(
            guardianId,
            kidId,
            searchParams.get("date")
          );

          if (response.data) {
            setKidClassSchedule(response.data);
            setLoading(false);
          }
        } catch (e) {
          // handle catch
        }
      }
    })();
  }, [kidId, searchParams.get("date")]);

  const handleDayChange = (direction) => {
    const canGoBack = currentDate.add(-1, "d");
    const canGoForward = currentDate.add(1, "d");

    if (direction === directions.back && canGoBack) {
      searchParams.set(
        "date",
        currentDate.add(-1, "d").format(dateTimeFormats.YYYYMMDD)
      );
    } else if (direction === directions.forward && canGoForward) {
      searchParams.set(
        "date",
        currentDate.add(1, "d").format(dateTimeFormats.YYYYMMDD)
      );
    }
    setSearchParams(searchParams);
  };

  return (
    <>
      <Box sx={scheduleBox}>{t("schedule", { keyPrefix: "homeSchedule" })}</Box>

      <Card sx={scheduleCard}>
        <CardContent>
          <Box sx={scheduleBoxDate}>
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ cursor: "pointer" }}
              onClick={() => handleDayChange(directions.back)}
            >
              <g opacity="0.7">
                <path d="M17.5 7L9 15L17.5 23" stroke="black" />
                <rect
                  x="0.5"
                  y="0.5"
                  width="29"
                  height="29"
                  rx="14.5"
                  stroke="black"
                />
              </g>
            </svg>
            <p style={{ paddingLeft: "10px", paddingRight: "10px" }}>
              {weekLongDate(currentDate, { language: i18n.language })}
            </p>
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ cursor: "pointer" }}
              onClick={() => handleDayChange(directions.forward)}
            >
              <path d="M12 23L20.5 15.5L12 7" stroke="black" />
              <rect
                x="0.5"
                y="0.5"
                width="29"
                height="29"
                rx="14.5"
                stroke="black"
              />
            </svg>
          </Box>

          <TableContainer sx={tableContainer}>
            <Table stickyHeader aria-label="schedule">
              {kidClassSchedule.length > 0 && (
                <>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={tableHead}>
                        {t("time", { keyPrefix: "homeSchedule" })}
                      </TableCell>
                      <TableCell sx={tableHead}>
                        {t("class", { keyPrefix: "homeSchedule" })}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {kidClassSchedule.map((schedule) => (
                      <TableRow key={schedule.id} hover>
                        <TableCell sx={tableBodyTime}>
                          {displayTime(schedule)}
                        </TableCell>
                        <TableCell sx={tableBodyClass}>
                          {schedule.klasses.map((klass) => (
                            <Typography key={klass.id}>{klass.name}</Typography>
                          ))}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </>
              )}
              {kidClassSchedule.length === 0 && (
                <TableBody>
                  <TableRow sx={textCenter}>
                    <TableCell style={{ borderBottom: "none" }} sx={textCenter}>
                      {loading
                        ? t("loading", { ns: "common" })
                        : t("noSchedule", { keyPrefix: "homeSchedule" })}
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </>
  );
}
