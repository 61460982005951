import Section from "./Section";

export default class FormPage {
  constructor(_formPage) {
    const formPage = _formPage || {};
    this.id = formPage.id || null;
    this.pageNumber = formPage.pageNumber || formPage.page_number || 1;
    const formSections = [];
    if (formPage.form_sections) {
      formPage.form_sections.forEach((element) => {
        formSections.push(new Section(element));
      });
    }
    this.sections = formPage.sections ||
      (formSections.length && formSections) || [new Section()];
  }

  serialize() {
    return {
      id: this.id,
      page_number: this.pageNumber,
      form_sections_attributes: this.sections.map((section) =>
        new Section(section).serialize()
      ),
    };
  }
}
