import React from "react";
import {
  actionButtonStyles,
  containerStyles,
  errorCodeTextStyles,
  imageStyles,
  infoTextStyles,
  titleStyles,
} from "./401.styles";

function Error401() {
  const goBack = () => {
    window.location.assign("/");
  };

  return (
    <div style={containerStyles}>
      <img src="/Dog-02.svg" alt="Error 401" style={imageStyles} />
      <h1 style={titleStyles}>
        Oh no! It doesn&apos;t appear you have permission to view this page.
      </h1>
      <p className="error-code-text" style={errorCodeTextStyles}>
        HTTP ERROR 401
      </p>
      <p className="info-text" style={infoTextStyles}>
        Contact your school administrator if you believe you should be able to
        access this page.
      </p>
      <button
        className="action-button"
        style={actionButtonStyles}
        onClick={goBack}
        type="button"
      >
        RETURN TO AE-CONNECT
      </button>
    </div>
  );
}

export default Error401;
