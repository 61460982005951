const gpaScaleCol = {
  minWidth: "365px",
  mr: "60px",
  mt: "60px",
};

const gpaScaleName = {
  width: "225px",
};

const gpaScaleType = {
  width: "95px",
};

const gpaCodeWidth = {
  width: "60px",
  mr: "5px",
};

const gpaWidth = {
  width: "85px",
  mr: "5px",
};

const gpaUgpaWidth = {
  width: "90px",
  mr: "5px",
};

const gpaPassWidth = {
  width: "45px",
  mr: "5px",
};

const gpaCalcWidth = {
  width: "35px",
  mr: "5px",
};

export {
  gpaScaleCol,
  gpaScaleName,
  gpaScaleType,
  gpaCodeWidth,
  gpaWidth,
  gpaUgpaWidth,
  gpaPassWidth,
  gpaCalcWidth,
};
