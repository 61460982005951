import { Grid, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import * as React from "react";
import TextEditor from "../../../TextEditor/TextEditor";

export default function Notes({ teacher, disabled }) {
  const { setValue, getValues } = useFormContext();
  let timer;

  React.useEffect(() => {
    setValue("notes", teacher.notes);
  }, []);

  const onNotesChange = (data) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      if (getValues("notes") === data) return;
      setValue("notes", data, { shouldDirty: true });
    }, 2000);
  };

  return (
    <Grid container mb={5}>
      <Grid item xs={12} my={3}>
        <Typography variant="profileTitle">Notes</Typography>
      </Grid>
      <Grid item xs={12} px={3}>
        <TextEditor
          value={teacher.notes}
          onChange={(data) => onNotesChange(data)}
          isSimplified
          readonly={disabled}
        />
      </Grid>
    </Grid>
  );
}
