const contentContainer = {
  my: "30px",
};

const contentTitleContainer = {
  borderBottom: "1px solid #BFC7CA",
  mb: "25px",
};

const contentSubjectContainer = {
  mb: "25px",
};

const contentMessageContainer = {
  height: "450px",
};

const contentMessageAlert = {
  color: "red",
  fontSize: "12px",
  mb: "15px",
};

export {
  contentContainer,
  contentTitleContainer,
  contentSubjectContainer,
  contentMessageContainer,
  contentMessageAlert,
};
