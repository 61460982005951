import React, { createContext, useState, useMemo } from "react";
import { useParams } from "react-router-dom";

export const StaffActionsContext = createContext({
  drawerProperties: {},
  isEContactsDrawerOpen: false,
  econtactUpdated: false,
  temporaryStaffDrawerOpen: false,
  temporaryStaffUpdated: false,
});
export function StaffActionsProvider({ children }) {
  const { id } = useParams();
  const [isEContactsDrawerOpen, setIsEContactsDrawerOpen] = useState(false);

  const [temporaryStaffDrawerOpen, setTemporaryStaffDrawerOpen] =
    useState(false);

  const [drawerProperties, setDrawerProperties] = useState({});

  const [econtactUpdated, setEContactUpdated] = useState(false);
  const [temporaryStaffUpdated, setTemporaryStaffUpdated] = useState(false);

  const openEmergencyContactDrawer = (currentContact, fetchAllContacts) => {
    const properties = {
      studentId: id,
      currentContact,
      fetchAllContacts,
      setIsEContactsDrawerOpen,
    };
    setDrawerProperties(properties);
    setIsEContactsDrawerOpen(true);
  };

  const openTemporaryStaffDrawer = (currentStaff) => {
    const properties = {
      currentStaff,
      setTemporaryStaffDrawerOpen,
    };
    setDrawerProperties(properties);
    setTemporaryStaffDrawerOpen(true);
  };

  const fireEContactUpdated = () => {
    setEContactUpdated(true);
  };

  const cleanEContactUpdated = () => {
    setEContactUpdated(false);
  };

  const fireTemporaryStaffUpdated = () => {
    setTemporaryStaffUpdated(true);
  };

  const cleanTemporaryStaffUpdated = () => {
    setTemporaryStaffUpdated(false);
  };

  const contextValue = useMemo(
    () => ({
      drawerProperties,
      isEContactsDrawerOpen,
      openEmergencyContactDrawer,
      fireEContactUpdated,
      cleanEContactUpdated,
      econtactUpdated,
      openTemporaryStaffDrawer,
      fireTemporaryStaffUpdated,
      cleanTemporaryStaffUpdated,
      temporaryStaffDrawerOpen,
      temporaryStaffUpdated,
    }),
    [
      isEContactsDrawerOpen,
      temporaryStaffDrawerOpen,
      econtactUpdated,
      temporaryStaffUpdated,
    ]
  );

  return (
    <StaffActionsContext.Provider value={contextValue}>
      {children}
    </StaffActionsContext.Provider>
  );
}
