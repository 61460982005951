const attachmentsInfoContainer = {
  width: "450px",
  mx: "auto",
  textAlign: "center",
  mt: "20px",
  "& p": {
    fontSize: "14px",
  },
};

const attachmentBtnLabel = {
  fontSize: "18px",
  color: "#000",
  textTransform: "none",
};

const attachmentsBox = {
  backgroundColor: "#f0f0f0",
  p: "30px",
};

const messagesAttachIcon = {
  transform: "rotate(45deg)",
  "&.MuiSvgIcon-root": {
    fontSize: "xx-large",
  },
};

const attachButton = {
  "&:hover": {
    backgroundColor: "transparent",
  },
};

const attachmentsList = {
  display: "flex",
  flexWrap: "wrap",
};

export {
  attachmentsInfoContainer,
  attachmentBtnLabel,
  attachmentsBox,
  messagesAttachIcon,
  attachButton,
  attachmentsList,
};
