import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import { useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import {
  modalCloseIcon,
  newMessageBox,
  newMessageDesc,
  newMessageDescError,
  newMessageModal,
  newMessageSend,
  newMessageTo,
  newMessageRecipientTo,
  newMessageRecipients,
} from "../Home/Home.styles";
import { danger } from "../../sharedStyles";
import Attachments from "./Attachments";
import { newMessageIcon } from "./Messages.styles";
import messagesService from "../../../service/messagesService";
import { fetchStudentRecipients } from "../../../service/studentPortalService";

export default function StudentPortalNewMessage({
  handleModalClose,
  setSnackBarOpen,
  setReloadPage,
}) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm();
  const [attachments, setAttachments] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const maxFileSize = 20000000;
  const [sendMessageLoader, setSendMessageLoader] = useState(false);
  const params = useParams();
  const studentId = params.id;
  const schoolId = params.school_id;
  const [recipientsValue, setRecipientsValue] = useState("");
  const [recipients, setRecipients] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetchStudentRecipients(studentId, {
          params: { school_id: schoolId },
        });
        if (response) {
          setRecipients(response.data);
        }
      } catch (e) {
        // handle catch
      }
    })();
  }, []);

  const handleFileUploads = (e) => {
    const files = [...uploadedFiles];
    Array.prototype.forEach.call(e.target.files, (file, index) => {
      const fileWithId = file;
      const fileId =
        uploadedFiles.length > 0 ? index + uploadedFiles.at(-1).id + 1 : index;
      fileWithId.id = fileId;
      files.push(fileWithId);
    });

    try {
      if (files) {
        setUploadedFiles(files);
      }
    } catch (err) {
      if (err.error === "sizeException") {
        setSnackBarOpen({
          message: err.message,
          open: true,
          severity: "error",
        });
      }
    }
  };

  function SizeException(message) {
    setSnackBarOpen({
      message,
      open: true,
      severity: "error",
    });
    setSendMessageLoader(false);
    return {
      message,
      error: "sizeException",
    };
  }

  const onSubmit = async (data) => {
    setSendMessageLoader(true);
    const formData = new FormData();

    const formParams = {
      subject: data.newMessageDescription.substring(0, 10),
      description: data.newMessageDescription,
      school_id: schoolId,
      sender_type: "Student",
    };

    Object.keys(formParams).map((keyName) =>
      formData.append(keyName, formParams[keyName])
    );

    formData.append("recipients", JSON.stringify(recipientsValue));

    Array.prototype.forEach.call(uploadedFiles, (file, index) => {
      if (uploadedFiles.length > 10)
        throw new SizeException("Maximum of 10 files are permitted.");
      if (file.size > maxFileSize)
        throw new SizeException(`The file ${file.name} is bigger than 20Mb.`);
      formData.append(`attachments[]`, uploadedFiles[index]);
    });

    const response = await messagesService.createMessage(formData);

    if (response.data) {
      setSnackBarOpen({
        open: true,
        message: "Message sent.",
      });

      setSendMessageLoader(false);
      handleModalClose();
      setReloadPage(true);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={newMessageModal}>
        <Box sx={newMessageBox} id="new-message-title">
          <Typography sx={newMessageTo}>New Message</Typography>
          <IconButton
            aria-label="modal-close"
            onClick={handleModalClose}
            sx={modalCloseIcon}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box>
          <Stack direction="row" alignItems="center">
            <Typography sx={newMessageRecipientTo}>To:</Typography>
            <Controller
              render={() => (
                <FormControl fullWidth>
                  <Select
                    disableUnderline
                    variant="standard"
                    sx={newMessageRecipients}
                    defaultValue=""
                    id="recipients"
                    {...register("recipients", {
                      required: "This field is required.",
                    })}
                  >
                    {recipients.map((data) => (
                      <MenuItem
                        key={data.id}
                        value={data.id}
                        onClick={() =>
                          setRecipientsValue([
                            {
                              target: "single_teacher",
                              teacher_id: data.id,
                              school_id: schoolId,
                            },
                          ])
                        }
                      >
                        {data.first_name} {data.last_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              name="recipients"
              control={control}
            />
          </Stack>
          {errors.recipients && (
            <Typography sx={danger} style={{ paddingLeft: "40px" }}>
              {errors.recipients.message}
            </Typography>
          )}
        </Box>
        <TextField
          autoFocus
          focused
          sx={newMessageDesc}
          id="new-message-description"
          placeholder="Type your message..."
          multiline
          variant="filled"
          rows={10}
          error={errors?.newMessageDescription && true}
          {...register("newMessageDescription", {
            required: {
              value: true,
              message: "This field is required.",
            },
          })}
        />
        {errors?.newMessageDescription && (
          <Typography sx={[danger, newMessageDescError]}>
            {errors?.newMessageDescription.message}
          </Typography>
        )}
        <Attachments
          handleFileUploads={handleFileUploads}
          attachments={attachments}
          setAttachments={setAttachments}
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles}
        />
        <Box sx={{ textAlign: "center" }}>
          <Button
            type="submit"
            disabled={sendMessageLoader}
            sx={newMessageSend}
            variant="contained"
            endIcon={<SendOutlinedIcon sx={newMessageIcon} />}
          >
            {sendMessageLoader ? "Sending message..." : "Send"}
          </Button>
        </Box>
      </Box>
    </form>
  );
}
