import React from "react";
import { useTranslation } from "react-i18next";
import { CssBaseline, Divider, Grid } from "@mui/material";
import {
  copyrightContainer,
  copyrightText,
  footerAeLogo,
  footerAeLogoContainer,
  footerGridContainer,
  footerGridItem,
  footerLogosGrid,
  footerSacLogo,
  footerSacLogoContainer,
  footerStyle,
} from "./Footer.style";

export default function Footer() {
  const { t } = useTranslation("common", { keyPrefix: "footer" });

  return (
    <footer style={footerStyle}>
      <CssBaseline />
      <Grid container alignItems="baseline" sx={footerGridContainer}>
        <Grid item md={6} container sx={footerGridItem}>
          {" "}
        </Grid>
        <Grid item xs={6} container sx={footerLogosGrid}>
          <div style={footerSacLogoContainer}>
            <img
              src="/assets/footer-sac-logo.png"
              style={footerSacLogo}
              alt="sac logo"
            />
          </div>
          <div style={footerAeLogoContainer}>
            <img
              src="/assets/ae-footer-logo.png"
              style={footerAeLogo}
              alt="ae logo"
            />
          </div>
        </Grid>
      </Grid>
      <Divider style={{ borderColor: "#ffffff" }} />
      <div style={copyrightContainer}>
        <p style={copyrightText}>
          {t("copyrightText", { year: new Date().getFullYear() })}{" "}
          <a
            href="https://www.v1.adventisteducation.org/privacy_policy.html"
            target="_blank"
            rel="noreferrer"
          >
            {t("privacyPolicy")}
          </a>
        </p>
      </div>
    </footer>
  );
}
