import { generateGUID } from "../../../../../../utils/constants/utils";
import FieldBase from "./FieldBase";

export default class RadioButtonList extends FieldBase {
  constructor(field) {
    super(field);
    this.label = field.label;
    this.dataType = "value_text";

    this.properties.options = field.properties?.options || [
      { id: generateGUID(), value: "Option1" },
      { id: generateGUID(), value: "Option2" },
    ];
    this.propertyComponents = [
      "name",
      "label",
      "supporting-text",
      "required",

      "options",
    ];
  }
}
