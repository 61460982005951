const klassStudentDetailName = {
  fontSize: "24px",
  color: "black",
};

const klassStudentDetailColumn = {
  mt: "20px",
};

const klassStudentDetailInfoCol = {
  px: 4,
  width: "100%",
};

const klassStudentDetailGradeTxt = {
  textAlign: "center",
  color: "black",
  fontSize: "12px",
};

const klassStudentDetailKlassAbbr = {
  color: "black",
  fontSize: "22px",
};

const klassStudentDetailGradeLetter = {
  color: "black",
  fontSize: "34px",
  lineHeight: 1,
};

const assignmentDetailScoreInput = {
  "& fieldset": { border: "1px solid black" },
  "& .MuiInputBase-input": {
    padding: "6.5px 6.5px",
  },
};

const assignmentDetailScore = {
  width: "75px",
  px: "8px",
};
export {
  klassStudentDetailName,
  klassStudentDetailColumn,
  klassStudentDetailInfoCol,
  klassStudentDetailGradeTxt,
  klassStudentDetailKlassAbbr,
  klassStudentDetailGradeLetter,
  assignmentDetailScoreInput,
  assignmentDetailScore,
};
