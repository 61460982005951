import { mt70 } from "../components/sharedStyles";

const nameHeader = {
  width: "400px",
};

const descHeader = {
  width: "500px",
};

const divider = { borderColor: "black" };

const coursesListHead = (isCentered, isActive = false) => ({
  borderBottom: "none",
  color: isActive ? "black" : "#808080",
  cursor: "pointer",
  padding: 0,
  "& p": {
    fontSize: "14px",
    display: "flex",
    alignItems: "end",
    justifyContent: isCentered ? "center" : "flex-start",
  },
});

const coursesListTable = {
  color: "black",
};

const tableContainer = { mx: "65px" };

const emptyCourseBox = {
  ...mt70,
  display: "flex",
  justifyContent: "center",
};

const settingsCell = {
  color: "#808080",
  cursor: "pointer",
};

export {
  nameHeader,
  descHeader,
  divider,
  coursesListHead,
  coursesListTable,
  tableContainer,
  emptyCourseBox,
  settingsCell,
};
