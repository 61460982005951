import React from "react";
import { Tooltip } from "@mui/material";
import AssignmentLateOutlinedIcon from "@mui/icons-material/AssignmentLateOutlined";

export default function PrekGradeNotPostedIcon() {
  return (
    <Tooltip title="Grades not posted" placement="left-start" arrow>
      <AssignmentLateOutlinedIcon color="warning" />
    </Tooltip>
  );
}
