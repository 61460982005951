import {
  Box,
  ButtonBase,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { font12 } from "../components/sharedStyles";
import {
  socialDevelopmentHead,
  socialDevelopmentLevelsBorderedHead,
  socialDevelopmentLevelsHead,
  socialDevelopmentsHeadTerm,
  tableHead,
  noBorderBottom,
  boxTop,
  boxRow,
  emptyCell,
  studentCell,
} from "./SocialDevelopment.styles";

export default function SocialDevelopmentHead({
  gradingScales,
  level,
  handleGradeChange,
}) {
  return (
    <TableHead sx={tableHead}>
      <TableRow>
        <Box sx={boxTop}>
          <TableCell sx={socialDevelopmentLevelsHead} />
          <TableCell sx={socialDevelopmentLevelsHead} />
          <TableCell sx={socialDevelopmentLevelsHead} />
          <TableCell sx={socialDevelopmentLevelsHead} />
        </Box>
        {gradingScales?.map(
          (gradingScale) =>
            level === gradingScale.name && (
              <TableCell
                colSpan={gradingScale.social_development_skills.length}
                sx={socialDevelopmentLevelsBorderedHead}
                key={gradingScale.name}
                align="center"
              >
                <ButtonBase
                  onClick={(e) => {
                    handleGradeChange(
                      e.currentTarget,
                      `grading_input_${gradingScale.id}`,
                      gradingScale
                    );
                  }}
                >
                  <Typography>{gradingScale.name}</Typography>
                </ButtonBase>
              </TableCell>
            )
        )}
        <TableCell sx={noBorderBottom} />
      </TableRow>
      <TableRow>
        <Box sx={boxRow}>
          <TableCell sx={emptyCell} />
          <TableCell sx={emptyCell} />
          <TableCell sx={emptyCell} />
          <TableCell sx={studentCell} align="center">
            Student <br /> Level
          </TableCell>
        </Box>
        {gradingScales?.map(
          (gradingScale) =>
            level === gradingScale.name &&
            gradingScale.social_development_skills.map((skill) => (
              <TableCell
                sx={socialDevelopmentsHeadTerm}
                align="center"
                key={skill.id}
              >
                <ButtonBase
                  onClick={(e) => {
                    handleGradeChange(
                      e.currentTarget,
                      `skill_input_${skill.id}`,
                      gradingScale
                    );
                  }}
                >
                  <Typography sx={font12}>{skill.name}</Typography>
                </ButtonBase>
              </TableCell>
            ))
        )}
        <TableCell sx={socialDevelopmentHead} />
      </TableRow>
    </TableHead>
  );
}
