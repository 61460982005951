import React from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { Box, Grid, Typography, Badge, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { longShortDateTime } from "../../utils/dateTimePresets";
import { notificationsRow } from "../../views/Notifications.styles";

export default function Row({ notification }) {
  const { t, i18n } = useTranslation("notifications");
  const location = useLocation();
  const params = useParams();

  const portalLink = () => {
    if (location.pathname.includes("/school/"))
      return `/school/${params.school_id}/notifications/${notification.id}/details`;

    if (params.school_id)
      return `/student/${params.id}/schools/${params.school_id}/notifications/${notification.id}/details`;

    return `/guardian/${params.id}/notifications/${notification.id}/details`;
  };

  return (
    <Link to={portalLink()}>
      <Box
        sx={notificationsRow}
        className={notification.read_at ? undefined : "unread"}
      >
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={9}>
            <Typography className="category">
              {t(notification.event_category, { keyPrefix: "categories" })}
            </Typography>

            <Typography className="title">
              {t("name", { keyPrefix: `events.${notification.event_name}` })}
            </Typography>

            <Typography>
              {t("description", {
                keyPrefix: `events.${notification.event_name}`,
                data: notification.data,
              })}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className="timestamp">
              <Badge
                color="error"
                variant="dot"
                invisible={!!notification.read_at}
              />
              <Tooltip
                title={Intl.DateTimeFormat().resolvedOptions().timeZone}
                placement="top"
                arrow
              >
                <span>
                  {longShortDateTime(notification.created_at, {
                    language: i18n.language,
                  })}
                </span>
              </Tooltip>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Link>
  );
}
