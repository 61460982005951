import { Box, Typography } from "@mui/material";
import React from "react";
import { useDrag } from "react-dnd";
import StageElementType from "../Models/constants";

export default function TaskTab() {
  const [{ isDragging }, dragRef] = useDrag(() => ({
    type: "stage-element-type",
    item: {
      type: StageElementType.TASK,
      name: "Action",
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <Box width="100%" display="block">
      <Typography textTransform="none" color="#000000">
        <Typography
          ref={dragRef}
          variant="body2"
          pt={1}
          sx={{ opacity: isDragging ? 0.5 : 1, cursor: "pointer" }}
        >
          Action
        </Typography>
      </Typography>
    </Box>
  );
}
