import React from "react";
import { Button, Stack } from "@mui/material";
import {
  filterByKlassBtn,
  filterByKlassBtnActive,
} from "../Subject/SubjectFilterByClass.styles";

export default function KlassSelect({
  klasses,
  setKlasses,
  manageAllStudentsPermission,
}) {
  const handleClick = (klassId) => {
    const filteredKlasses = [...klasses];
    const filteredKlass = filteredKlasses.find((k) => k.id === klassId);
    filteredKlass.is_active = !filteredKlass.is_active;
    if (filteredKlasses.filter((fk) => fk.is_active === true).length > 0) {
      setKlasses(filteredKlasses);
    }
  };

  return (
    <Stack
      direction="row"
      sx={{ overflowX: "hidden", mt: manageAllStudentsPermission ? "15px" : 0 }}
    >
      {klasses.length > 0 &&
        klasses.map((klass) => (
          <Button
            onClick={() => handleClick(klass.id)}
            sx={klass.is_active ? filterByKlassBtnActive : filterByKlassBtn}
            key={klass.id}
          >
            {klass.abbreviation}
          </Button>
        ))}
    </Stack>
  );
}
