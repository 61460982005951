import axios from "../utils/axios-util";

const securityGroupsEndpoint = "/api/security_groups";

const fetchAll = async (params) => {
  const { data } = await axios.post(securityGroupsEndpoint, params);

  if (!data.data) {
    console.error("Error fetching school years from API");
    return [];
  }
  return data;
};

const deleteSecurityGroup = async (sgId) => {
  const { data } = await axios.delete(`${securityGroupsEndpoint}/${sgId}`);
  return data;
};

const create = async (params) => {
  await axios.post(`${securityGroupsEndpoint}/create`, params);
};

const cloneSecurityGroup = async (params) => {
  await axios.post(`${securityGroupsEndpoint}/clone`, params);
};

export default {
  fetchAll,
  deleteSecurityGroup,
  cloneSecurityGroup,
  create,
};
