const reportCardClassesSubtitle = {
  fontSize: "24px",
  color: "#3F464B",
};

const reportCardClassesSelectContainer = {
  width: "200px",
};

const reportCardClassesFilters = {
  color: "#5077A4",
  textDecoration: "none",
  fontSize: "14px",
};

const reportCardParentContainer = {
  borderTop: "1px solid black",
  mx: "65px",
  pt: "40px",
};

const reportCardContainer = {
  borderTop: "1px solid black",
};

const reportCardHeaderContainer = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

export {
  reportCardClassesSubtitle,
  reportCardClassesSelectContainer,
  reportCardClassesFilters,
  reportCardParentContainer,
  reportCardContainer,
  reportCardHeaderContainer,
};
