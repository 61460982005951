import React from "react";
import { Typography, Box } from "@mui/material";
import {
  notificationsTitle,
  notificationsContainer,
} from "./EmergencyContactForm.styles";
import NotificationCheckmarkSelect from "./NotificationCheckmarkSelect";

export default function Notifications({ currentContact }) {
  return (
    <Box sx={notificationsContainer}>
      <Typography sx={notificationsTitle}>Notifications</Typography>
      <NotificationCheckmarkSelect
        label="Emergency"
        id="notifications"
        currentContact={currentContact}
      />
    </Box>
  );
}
