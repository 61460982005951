import {
  proficiencyLevels,
  ipntOptionsWithNa,
  osnOptions,
} from "../../utils/constants/toolTipOptions";

export default function ValidInputCodes(
  markingCodes,
  specialMarks,
  isLetterGrade,
  isSkill = false,
  isSocialDevelopment = false
) {
  if (isSkill) {
    if (isLetterGrade) {
      return ipntOptionsWithNa(markingCodes)
        .flatMap((obj) => Object.values(obj))
        .map((obj) => obj.code?.toLowerCase());
    }

    return proficiencyLevels
      .flatMap((obj) => obj)
      .map((obj) => obj.value.toString().toLowerCase());
  }

  if (isSocialDevelopment) {
    if (isLetterGrade) {
      return markingCodes
        .flatMap((obj) => Object.values(obj))
        .map((obj) => obj.code.toLowerCase());
    }

    return osnOptions
      .flatMap((obj) => Object.values(obj))
      .map((obj) => obj.toLowerCase());
  }

  if (!isSkill && !isSocialDevelopment) {
    if (isLetterGrade) {
      return markingCodes
        .flatMap((obj) => Object.values(obj))
        .map((obj) => obj.code.toLowerCase())
        .concat(specialMarks.map((obj) => obj.code.toLowerCase()));
    }

    return specialMarks.map((obj) => obj.code.toLowerCase());
  }
}
