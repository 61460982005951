const classesBox = {
  fontSize: "24px",
  lineHeight: "32px",
  pb: "10px",
};

const classesCard = {
  background: "#F7F7F7",
  boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.15)",
  borderRadius: "6px",
  maxHeight: 600,
  minHeight: 600,
  overflowY: "auto",
};

const classesBoxDate = {
  fontSize: "25.92px",
  lineHeight: "31px",
  letterSpacing: "-0.04em",
  color: "#3F464B",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const tableContainer = { maxHeight: 470 };

const tableHead = {
  fontWeight: 500,
  fontSize: "11px",
  lineHeight: "16px",
  textTransform: "uppercase",
  color: "#000000",
  backgroundColor: "#F7F7F7",
  borderBottom: "none",
};

const tableBodyClassName = {
  cursor: "pointer",
  marginLeft: "-15px",
  fontSize: "24px",
  color: "#000000",
  pl: "0px",
  display: "inline",
  pr: "10px",
};

const tableBodyMeeting = {
  mt: "-8px",
  textTransform: "none",
  backgroundColor: "#2196F3",
  borderRadius: "4px",
  "&:hover": {
    backgroundColor: "#2196F3",
  },
};

const tableBodyText = {
  fontSize: "16px",
  color: "#000000",
  pb: "5px",
};

const tableBodyMissingAssignment = {
  cursor: "pointer",
  fontSize: "12px",
  color: "#F50E0E",
  fontWeight: 500,
};

export {
  classesBox,
  classesCard,
  classesBoxDate,
  tableContainer,
  tableHead,
  tableBodyClassName,
  tableBodyMeeting,
  tableBodyText,
  tableBodyMissingAssignment,
};
