import React, { useEffect, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useSearchParams, useLocation } from "react-router-dom";
import { Box, Stack, Typography, Collapse } from "@mui/material";
import { font14 } from "../sharedStyles";
import { selectDropdown, selectDropdownOpen } from "../SelectDropdown.styles";
import termSelectContainer from "../TermSelect.styles";

export default function TermSelect({
  terms,
  selectedTerm,
  setSelectedTerm,
  cleanFilters,
}) {
  const location = useLocation();
  const [selectOpen, setSelectOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const termIdParam = searchParams.get("term");

  useEffect(() => {
    if (terms !== undefined && terms.length > 0) {
      const term = terms.find((tt) => tt.id === Number(termIdParam));
      const params = {};
      searchParams.forEach((urlValue, key) => {
        params[key] = urlValue;
      });
      params.term = term ? term.id : "select";
      setSearchParams(params);
    }
  }, [terms]);

  const handleChange = (term) => {
    cleanFilters();
    setSelectedTerm(term);
    if (term) {
      setSearchParams({
        term: term.id,
      });
    } else {
      setSearchParams({
        term: "select",
      });
    }
  };

  useEffect(() => {
    setSelectOpen(false);
  }, [location]);

  return (
    terms.length > 0 && (
      <Box sx={termSelectContainer}>
        <Box
          sx={{
            ...(selectOpen ? selectDropdownOpen : selectDropdown),
            width: "100%",
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            onClick={() => setSelectOpen(!selectOpen)}
          >
            <Typography sx={font14} color="primary">
              {selectedTerm?.name || "Select term"}
            </Typography>
            {selectOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </Stack>
          <Collapse in={selectOpen} timeout={150}>
            {terms
              .filter((term) => term.id !== selectedTerm?.id)
              .map((term) => (
                <Typography
                  key={term.id}
                  sx={{ ...font14, py: "5px" }}
                  color="primary"
                  onClick={() => handleChange(term)}
                >
                  {term.name}
                </Typography>
              ))}
          </Collapse>
        </Box>
      </Box>
    )
  );
}
