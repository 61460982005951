import theme from "../../utils";
import { font14 } from "../sharedStyles";

const styledAppBar = {
  minWidth: "1400px",
  width: "100vw",
  "& .MuiAppBar-root": {
    zIndex: "1400",
  },
};

const navBarIconButton = { mr: 2, transform: "scaleX(-1)" };

const appBarBox = (openPopover) => ({
  overflowX: openPopover ? "unset" : "hidden",
});

const navBarSchoolLink = {
  color: "white",
};

const navBarTypography = {
  flexGrow: 1,
  alignSelf: "center",
  fontSize: "28px",
  fontWeight: 500,
};

const navBarDragIcon = {
  fontSize: "32px",
  WebkitTransform: "rotate(90deg)",
  MozTransformOrigin: "rotate(90def)",
  msTransform: "rotate(90deg)",
  transform: "rotate(90deg)",
};

const navBarSettingsBadge = {
  borderRadius: "50%",
  bgcolor: "primary.main",
};

const navBarSettingsIcon = {
  color: "#ffffff",
  fontSize: "1rem",
};

const navBarAccountIcon = {
  width: "100%",
  height: "100%",
  color: "primary.main",
};

const navBarLinksGrid = {
  backgroundColor: theme.topNavBar.secondary.backgroundColor,
  borderBottom: 1,
  borderColor: "divider",
};

const navBarLinks = {
  fontSize: font14,
  color: "black",
  "&:hover": {
    color: theme.palette.hover.primary,
  },
};

const navBarMenu = {
  overflow: "visible",
  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
  mt: 1.5,
  backgroundColor: theme.palette.primary.main,
  "& .MuiAvatar-root": {
    width: 32,
    height: 32,
    ml: -0.5,
    mr: 1,
  },
  "&:before": {
    content: '""',
    display: "block",
    position: "absolute",
    top: 0,
    right: 22,
    width: 10,
    height: 10,
    // left: "155px",
    bgcolor: theme.palette.primary.main,
    transform: "translateY(-50%) rotate(45deg)",
  },
};

const menuDefaultColor = {
  color: theme.palette.background.default,
};

const menuDefaultBackground = {
  backgroundColor: theme.palette.background.default,
};

const navBarAvatar = { bgcolor: "#ffffff" };

export {
  navBarSchoolLink,
  appBarBox,
  menuDefaultBackground,
  menuDefaultColor,
  navBarLinks,
  navBarIconButton,
  navBarAccountIcon,
  navBarAvatar,
  navBarDragIcon,
  navBarLinksGrid,
  navBarMenu,
  navBarSettingsBadge,
  navBarSettingsIcon,
  navBarTypography,
  styledAppBar,
};
