import React from "react";
import { Box, TextField } from "@mui/material";

export default function SignatureField({ field }) {
  return (
    <Box sx={{ backgroundColor: "white" }}>
      <TextField fullWidth disabled label={field.label} key={field.fieldId} />
    </Box>
  );
}
