import { Checkbox, Grid, FormControlLabel } from "@mui/material";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

export default function IsMaskedComponent({ selectedField }) {
  const { register, setValue } = useFormContext();

  useEffect(() => {
    setValue("isMasked", selectedField.properties.isMasked);
  }, [selectedField]);

  return (
    <Grid item md={12}>
      <FormControlLabel
        sx={{ width: "100%" }}
        control={
          <Checkbox defaultChecked={selectedField.properties.isMasked} />
        }
        label="Masked"
        {...register("isMasked")}
        disabled={selectedField.source_field_id}
      />
    </Grid>
  );
}
