import {
  Grid,
  Typography,
  Divider,
  Stack,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  Table,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useOutletContext, useParams, useSearchParams } from "react-router-dom";
import reportsService from "../../../service/reportsService";
import specialMarkService from "../../../service/specialMarkService";
import ClassAssignments from "./ClassAssignments";
import PrintLayout from "../PrintLayoutComponents/PrintLayout";
import { font11, font22, mx5 } from "../../sharedStyles";
import moment from "../../../utils/constants/momentConfig";
import dateTimeFormats from "../../../utils/constants/dateTimeFormats";
import { tableHeader, tableStyles } from "./ClassAssignment.style";

export default function Assignments() {
  const [specialMarks, setSpecialMarks] = useState([]);
  const params = useParams();
  const [schoolName] = useOutletContext();
  const [searchParams] = useSearchParams();
  const missingAssignments = searchParams.get("showMissingAssignments");
  const termId = searchParams.get("term");
  const homeroomTeacherId = searchParams.get("homeroomTeacherId");
  const [, , selectedTeacherStudent] = useOutletContext();
  const [assignments, setAssignments] = useState([]);

  const getAssignments = async () => {
    const response = await reportsService.assignments({
      school_id: params.school_id,
      term_id: termId,
      missing_assignments: missingAssignments,
      homeroom_teacher_id: homeroomTeacherId,
      student_id:
        selectedTeacherStudent !== "all" ? selectedTeacherStudent : null,
    });
    const assignmentsToRender = response.filter(
      (a) =>
        a.klasses.length > 0 &&
        a.klasses.filter((k) => k.assignments.length > 0).length > 0
    );
    setAssignments(assignmentsToRender);
  };

  const getSpecialMarks = async () => {
    const response = await specialMarkService.fetchSchoolSpecialMarks({
      params: {
        school_id: params.school_id,
      },
    });
    if (response.data) {
      setSpecialMarks(response.data.special_marks);
    }
  };

  useEffect(() => {
    if (termId) {
      const fetchData = async () => {
        await getAssignments();
        await getSpecialMarks();
      };

      fetchData();
    }
  }, [termId, homeroomTeacherId, selectedTeacherStudent, missingAssignments]);

  const generateBodyContent = assignments.map(
    (assignment, parentIndex) =>
      !!assignment.klasses?.length && (
        <tr className="report-data" key={assignment.id}>
          <td
            className="report-content-cell"
            style={{ padding: "2rem 1rem 0 2rem" }}
          >
            <div>
              <Grid container>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="end"
                  >
                    <Typography color="black" sx={font22}>
                      {assignment.first_name} {assignment.last_name}
                    </Typography>
                    <Stack direction="row">
                      <Typography color="black" sx={font11}>
                        {assignment.grade_level} Grade
                      </Typography>
                      <Typography color="black" sx={[font11, mx5]}>
                        |
                      </Typography>
                      <Typography color="black" sx={font11}>
                        Date:{" "}
                        {moment().utc().format(dateTimeFormats.ddddMMMDDYYYY)}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Divider />
                  <TableContainer>
                    <Table sx={tableStyles} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={tableHeader}>CLASS</TableCell>
                          <TableCell sx={tableHeader}>DUE DATE</TableCell>
                          <TableCell sx={tableHeader} />
                          <TableCell sx={tableHeader} />
                          <TableCell sx={tableHeader}>GRADE</TableCell>
                        </TableRow>
                      </TableHead>
                      {assignment.klasses.map((klass) => (
                        <ClassAssignments
                          missingAssignments={missingAssignments}
                          key={klass.id}
                          klass={klass}
                          specialMarks={specialMarks}
                          termGrade={klass.term_grades.find(
                            (tg) => tg.student_id === assignment.id
                          )}
                        />
                      ))}
                    </Table>
                  </TableContainer>
                  {parentIndex < assignments.length - 1 && (
                    <div style={{ pageBreakBefore: "always" }} />
                  )}
                </Grid>
              </Grid>
            </div>
          </td>
        </tr>
      )
  );

  return (
    <PrintLayout
      bodyContent={generateBodyContent}
      headerContent="Assignments"
      footerContent={schoolName}
    />
  );
}
