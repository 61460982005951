import axios from "../utils/axios-util";

const newGradebookEndpoint = "/api/new_gradebook";

const fetchAssignmentStudents = async (params) => {
  const { data } = await axios.get(newGradebookEndpoint, params);

  if (!data.data) {
    console.error("Error fetching assignments from the API");
    return [];
  }

  return data.data;
};

const updateAndCalculateScores = async (params) => {
  const data = await axios.post(`${newGradebookEndpoint}/calculate`, params);

  if (!data) {
    console.error("Error fetching assignments from the API");
    return [];
  }

  return data;
};

const updateAndCalculateMultipleScores = async (params) => {
  const data = await axios.post(
    `${newGradebookEndpoint}/calculate_multiple`,
    params
  );

  if (!data) {
    console.error("Error fetching assignments from the API");
    return [];
  }

  return data;
};

const fetchTermGrades = async (params) => {
  const { data } = await axios.get(
    `${newGradebookEndpoint}/term/grade`,
    params
  );

  if (!data.data) {
    console.error("Error fetching term grades from the API");
    return [];
  }

  return data.data;
};

export default {
  fetchAssignmentStudents,
  updateAndCalculateScores,
  fetchTermGrades,
  updateAndCalculateMultipleScores,
};
