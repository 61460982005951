import { generateGUID } from "../../../../../utils/constants/utils";
import StageElementType from "./constants";
import StageElementProperties from "./StageElementProperties";

export default class ProcessStageElement {
  constructor(_stageElement) {
    const stageElement = _stageElement || {};
    this.id = stageElement.id || null;
    this.elementId = generateGUID();
    this.name = stageElement.name || null;
    this.admissionStageId =
      stageElement.admission_stage_id || stageElement.admissionStageId || null;
    this.type =
      stageElement.type || stageElement.element_type || StageElementType.FORM;
    this.properties =
      stageElement.properties || new StageElementProperties(null, stageElement);
  }

  serialize() {
    return {
      id: this.id,
      name: this.name,
      element_type: this.type,
      admission_stage_id: this.admissionStageId,
      properties: this.properties,
    };
  }
}
