import React from "react";
import { Container, Box, Typography } from "@mui/material";
import { Outlet, useOutletContext, useParams } from "react-router-dom";
import { pageHeaderTitle } from "../components/sharedStyles";
import {
  reportCardContainer,
  reportCardHeaderContainer,
} from "../components/ReportCard/ReportCard.styles";
import SchoolTabs from "../components/School/SchoolTabs";

export default function SchoolProfile() {
  const schoolId = useParams().school_id;
  const [, , , , , , , , , , , currentSchool] = useOutletContext();

  return (
    <Container maxWidth={false} variant="header">
      <Box sx={reportCardHeaderContainer}>
        <Typography sx={pageHeaderTitle}>School Profile</Typography>
      </Box>
      <Container maxWidth="xl">
        <SchoolTabs schoolId={schoolId} />

        <Box sx={reportCardContainer}>
          <Outlet context={[schoolId, currentSchool]} />
        </Box>
      </Container>
    </Container>
  );
}
