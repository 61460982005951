import { Box, Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import EmergencyContactTable from "./EmergencyContactTable";
import reportsService from "../../../service/reportsService";
import PrintLayout from "../PrintLayoutComponents/PrintLayout";

export default function EmergencyInformation() {
  const [students, setStudents] = useState([]);
  const params = useParams();
  const [schoolName] = useOutletContext();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      reportsService
        .studentsEmergencyContacts({
          school_id: params.school_id,
        })
        .then((response) => {
          const studentsToRender = response.filter(
            (r) =>
              r.emergency_contacts.length > 0 ||
              r.emergency_guardians.length > 0
          );
          setStudents(studentsToRender);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    })();
  }, []);

  const generateBodyContent = () => {
    if (loading) {
      return (
        <Box display="flex" justifyContent="center">
          <Typography color="black" fontSize="20px" mt={5}>
            Loading...
          </Typography>
        </Box>
      );
    }

    if (students.length === 0) {
      return (
        <tr>
          <td>
            <Box textAlign="center" mt={3}>
              No emergency contacts found.
            </Box>
          </td>
        </tr>
      );
    }

    return students.map((student) => (
      <tr className="report-data" key={student.id}>
        <td
          className="report-content-cell"
          style={{ padding: "2rem 1rem 0 2rem" }}
        >
          <div>
            <Grid container direction="column">
              <Typography fontWeight={700} fontSize={18} color="black">
                {student.first_name} {student.last_name}
              </Typography>
              {!!student.teacher && (
                <Typography fontSize={14} color="black">
                  D.O.B:{" "}
                  {student.date_of_birth
                    ? new Date(student.date_of_birth).toLocaleString("en-US", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      })
                    : ""}{" "}
                  | Grade: {student.grade_level} | Homeroom Teacher:{" "}
                  {`${student.teacher.name_title || ""}. ${
                    student.teacher.first_name
                  } ${student.teacher.last_name}`}
                  , Homeroom Teacher
                </Typography>
              )}
              <Grid py={2}>
                <EmergencyContactTable
                  emergencyContacts={student.emergency_contacts}
                  emergencyGuardians={student.emergency_guardians}
                />
              </Grid>
            </Grid>
          </div>
        </td>
      </tr>
    ));
  };

  return (
    <PrintLayout
      bodyContent={generateBodyContent()}
      headerContent="Emergency Information"
      footerContent={schoolName}
    />
  );
}
