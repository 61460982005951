import React, { useState } from "react";
import { IconButton, InputAdornment, Stack, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { groupSearchButton, groupSearchButtonIcon } from "./GroupSearch.styles";

export default function GroupSearch({
  query,
  setQuery,
  searchParams,
  setSearchParams,
}) {
  const { register, handleSubmit, setValue } = useForm();

  const [showClearButton, setShowClearButton] = useState(
    searchParams.get("query") || false
  );

  const handleSearch = (data) => {
    searchParams.set("query", data.group_search);
    setQuery(data.group_search);
    setSearchParams(searchParams);
  };

  const handleQuickFindChange = (event) => {
    if (event.target.value) setShowClearButton(true);
    else setShowClearButton(false);
  };

  const handleClickOnClearButton = () => {
    setValue("group_search", "");
    setShowClearButton(false);
    searchParams.delete("query");
    setSearchParams(searchParams);
    handleSearch({ group_search: "" });
  };

  const cancelButtonStyle = {
    visibility: showClearButton ? "inherit" : "hidden",
  };

  return (
    <form
      onSubmit={handleSubmit(handleSearch)}
      onChange={handleQuickFindChange}
    >
      <Stack direction="row" alignItems="end">
        <IconButton type="submit" disableRipple sx={groupSearchButton}>
          <SearchIcon sx={groupSearchButtonIcon} />
        </IconButton>
        <TextField
          label="Search..."
          variant="standard"
          color="primary"
          defaultValue={query}
          {...register("group_search")}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  sx={cancelButtonStyle}
                  onClick={() => handleClickOnClearButton()}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    </form>
  );
}
