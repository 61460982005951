import theme from "../../../utils/index";
import { font14, font32 } from "../../sharedStyles";

const editSchoolYear = {
  float: "right",
  textTransform: "none",
  color: theme.palette.text.strongBlue,
  fontSize: font14,
};
const hidden = { visibility: "hidden" };

const alignCenter = { textAlign: "center" };
const dayStyle = {
  borderLeft: "1px solid #E0E6E8",
  minHeight: "150px",
};

const colorMap = {
  grey: "rgba(28, 27, 31, 0.08)",
  red: "rgba(211, 102, 96, 0.12)",
};

const boxStyle = (color) => ({
  backgroundColor: colorMap[color] || "inherit",
  minHeight: "150px",
  margin: 1,
  padding: 1,
});
const monthTitle = { fontSize: font32, color: "#81888C", marginTop: 2 };
const formControlStyle = { minWidth: 120, maxWidth: 150 };
const emptyMessageContainer = {
  display: "flex",
  justifyContent: "center",
  width: "100%",
  "& p": {
    color: "black",
    fontSize: "20px",
    mt: 5,
  },
};
export {
  editSchoolYear,
  hidden,
  alignCenter,
  monthTitle,
  dayStyle,
  boxStyle,
  formControlStyle,
  emptyMessageContainer,
};
