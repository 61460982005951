const filterContainer = {
  width: "100%",
  mx: "100px",
  mt: "10px",
};

const filterButton = {
  color: "#2196F3",
};

const filterChip = {
  backgroundColor: "#9DD2F8",
  color: "#ffffff",
};

export { filterContainer, filterButton, filterChip };
