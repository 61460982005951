import React, { useState } from "react";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import {
  Button,
  FormControlLabel,
  Menu,
  MenuItem,
  Stack,
  Switch,
} from "@mui/material";
import { filterButton } from "./Filters.styles";
import { px10 } from "../../../sharedStyles";

export default function Columns({
  tableColumns,
  setTableColumns,
  setSearchParams,
  searchParams,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (columnId) => {
    const updatedColumns = tableColumns.map((column) => {
      if (column.id === columnId) {
        return { ...column, visible: !column.visible };
      }

      if (columnId === "hide-all") {
        return { ...column, visible: false };
      }

      if (columnId === "show-all") {
        return { ...column, visible: true };
      }

      return column;
    });

    const urlParams = updatedColumns
      .filter((column) => column.visible === true)
      .map((c) => c.id);

    if (columnId === "show-all") {
      searchParams.delete("columns");
      setSearchParams(searchParams);
    } else if (columnId === "hide-all") {
      searchParams.set("columns", "hide-all");
      setSearchParams(searchParams);
    } else {
      searchParams.set("columns", urlParams);
      setSearchParams(searchParams);
    }

    setTableColumns(updatedColumns);
  };

  return (
    <>
      <Button
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant="text"
        sx={filterButton}
        startIcon={<ViewColumnIcon />}
      >
        Columns
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {tableColumns.map((column) => (
          <MenuItem key={column.id}>
            <FormControlLabel
              control={
                <Switch
                  onChange={() => handleChange(column.id)}
                  checked={column.visible}
                />
              }
              label={column.name}
            />
          </MenuItem>
        ))}
        <Stack direction="row" sx={px10}>
          <Button onClick={() => handleChange("hide-all")}>Hide All</Button>
          <Button onClick={() => handleChange("show-all")}>Show All</Button>
        </Stack>
      </Menu>
    </>
  );
}
