import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import {
  assignmentCategoryRow,
  assignmentEditKlassRow,
  assignmentInputErrorColumn,
  assignmentInputFormControl,
  assignmentInputRow,
  assignmentMaxErrorColumn,
  assignmentMaxFormControl,
  assignmentCategoryErrorColumn,
} from "./KlassRow.styles";
import { bgWhite, font16, gcLogoContainer } from "../sharedStyles";
import { assignmentsFormKlassesOptionsCheckbox } from "./Klasses.styles";
import ErasableTextField from "../ErasableTextField";
import { renderError } from "../../utils/constants/forms";

export default function EditAssignmentRow({
  klass,
  categories,
  selectedCategory,
  activeAssignment,
  affectsGrade,
  setAffectsGrade,
  selectedInputAs,
  setSelectedInputAs,
  defaultGradingOption,
  hasCategoryError,
  hasInputAsError,
  hasMaxError,
  assignmentStudents,
  isGoogleAssignment,
}) {
  const {
    register,
    setValue,
    control,
    formState: { errors },
  } = useFormContext();

  const students = assignmentStudents || activeAssignment.assignment_students;

  const hasGrades = () => {
    let hg = false;

    students.forEach((assignmentStudent) => {
      if (assignmentStudent.score != null) {
        hg = true;
      }
    });

    return hg;
  };

  const gcLogoPath = `${window.location.origin}/assets/gc-logo.png`;

  if (selectedInputAs && selectedInputAs !== "") {
    setValue(`klasses.klass-${klass.id}.inputAs`, selectedInputAs);
  }

  return (
    <>
      <TableRow tabIndex={-1} key={klass.id} sx={assignmentEditKlassRow(true)}>
        <TableCell padding="none">
          {categories.length > 0 && (
            <FormControl
              fullWidth
              error={errors[`klasses.klass-${klass.id}.category`] && true}
            >
              <InputLabel
                id={`klasses.klass-${klass.id}.category`}
                shrink
                sx={bgWhite}
              >
                Category*
              </InputLabel>
              <Select
                disabled={isGoogleAssignment}
                labelId={`klasses.klass-${klass.id}.category`}
                id={`klasses.klass-${klass.id}.category`}
                defaultValue={selectedCategory}
                {...register(`klasses.klass-${klass.id}.category`, {
                  required: {
                    value: !isGoogleAssignment,
                    message: "This field is required",
                  },
                })}
              >
                {categories.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.category}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </TableCell>
        <TableCell padding="none" sx={assignmentsFormKlassesOptionsCheckbox}>
          {!defaultGradingOption && (
            <FormControl fullWidth sx={assignmentInputFormControl}>
              <InputLabel
                id={`klasses.klass-${klass.id}.inputAs`}
                shrink
                sx={bgWhite}
              >
                Input as*
              </InputLabel>
              <Controller
                name={`klasses.klass-${klass.id}.inputAs`}
                control={control}
                rules={{
                  required: {
                    value:
                      !isGoogleAssignment ||
                      !klass.maxPointsAsWeight ||
                      !hasGrades(),
                    message: "This field is required.",
                  },
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId={`klasses.klass-${klass.id}.inputAs`}
                    id={`klasses.klass-${klass.id}.inputAs`}
                    label="Input as*"
                    disabled={
                      klass.maxPointsAsWeight ||
                      hasGrades() ||
                      isGoogleAssignment
                    }
                    onChange={(e) => {
                      field.onChange(e);
                      setSelectedInputAs(e.target.value);
                    }}
                    value={selectedInputAs}
                  >
                    <MenuItem value="points">Points</MenuItem>
                    <MenuItem value="percent">Percentage</MenuItem>
                    <MenuItem value="letter_grade">Letter Grade</MenuItem>
                  </Select>
                )}
              />
              {errors[`klasses.klass-${klass.id}.inputAs`] && (
                <p>{errors[`klasses.klass-${klass.id}.inputAs`].message}</p>
              )}
            </FormControl>
          )}
          {!defaultGradingOption &&
            selectedInputAs !== null &&
            selectedInputAs.toString() === "points" && (
              <FormControl fullWidth sx={assignmentMaxFormControl}>
                <ErasableTextField
                  id={`klasses.klass-${klass.id}.max`}
                  label="Max*"
                  isRequired={!hasGrades()}
                  fullWidth
                  type="text"
                  disabled={hasGrades()}
                  defaultValue={activeAssignment.max_score}
                />
              </FormControl>
            )}
        </TableCell>
        <TableCell padding="none" sx={assignmentsFormKlassesOptionsCheckbox}>
          <Checkbox
            color="primary"
            checked={!affectsGrade}
            {...register(`klasses.klass-${klass.id}.affectsGrade`, {
              onChange: () => {
                setValue(
                  `klasses.klass-${klass.id}.affectsGrade`,
                  affectsGrade
                );
                setAffectsGrade(!affectsGrade);
              },
            })}
          />
          <Typography sx={font16}>Does not affect grade</Typography>
        </TableCell>
        {isGoogleAssignment && (
          <TableCell>
            <Box
              sx={gcLogoContainer}
              onClick={() =>
                window.open(activeAssignment.gc_assignment_link, "_blank")
              }
            >
              <img src={gcLogoPath} alt="logo" />
            </Box>
          </TableCell>
        )}
      </TableRow>
      <TableRow tabIndex={-1} sx={assignmentCategoryRow(true)}>
        <TableCell padding="none" />
        <TableCell padding="none" sx={assignmentInputRow} />
        <TableCell />
      </TableRow>
      {(hasMaxError() || hasCategoryError() || hasInputAsError()) && (
        <TableRow tabIndex={-1}>
          <TableCell padding="none">
            <Box sx={assignmentCategoryErrorColumn}>
              {hasCategoryError() &&
                renderError(
                  errors?.klasses?.[`klass-${klass.id}`]?.category?.message
                )}
            </Box>
          </TableCell>
          <TableCell padding="none">
            <Box sx={assignmentInputErrorColumn}>
              {hasInputAsError() &&
                renderError(
                  errors?.klasses?.[`klass-${klass.id}`]?.inputAs?.message
                )}
            </Box>
            <Box sx={assignmentMaxErrorColumn}>
              {hasMaxError() &&
                renderError(
                  errors?.klasses?.[`klass-${klass.id}`]?.max.message
                )}
            </Box>
          </TableCell>
          <TableCell />
        </TableRow>
      )}
    </>
  );
}
