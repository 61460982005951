import { formButtons } from "../../../sharedStyles";

const submitButton = {
  ...formButtons,
  backgroundColor: "#2196F3",
  color: "white",
  textTransform: "none",
  border: "none",
  paddingX: "1.25rem",
  "&:hover": {
    backgroundColor: "#2196F3",
  },
};

const cancelButton = {
  ...formButtons,
  backgroundColor: "#E0E0E0",
  color: "black",
  textTransform: "none",
  border: "none",
  paddingX: "1.25rem",
  "&:hover": {
    backgroundColor: "#E0E0E0;",
  },
};

const containerStyle = (formattedView = false) => ({
  borderRadius: formattedView ? "none" : "10px",
  margin: formattedView ? "inherit" : "50px 20px 50px 10px",
  padding: 2,
  border: formattedView ? "none" : "1px solid #CBE5EF",
  minHeight: "3rem",
  backgroundColor: formattedView ? "none" : "#F7FCFE",
  maxWidth: formattedView ? "800px !important" : "none",
  minWidth: formattedView ? "800px !important" : "none",
});

const fieldDelete = {
  position: "absolute",
  right: 0,
  top: 0,
  transform: "translateY(-50%) translateX(50%)",
  color: "red",
  display: "none",
  zIndex: 1200,
};

const fieldDrag = {
  position: "absolute",
  right: 15,
  top: 0,
  transform: "translateY(-50%)",
  zIndex: 1200,
  display: "none",
};

const fieldContainer = {
  position: "absolute",
  top: 0,
  right: 0,
  bottom: "10px",
  left: 0,
  cursor: "pointer",
};

const fieldStyle = {
  cursor: "pointer",
  p: 0.5,
  justifyContent: "space-between",
  alignItems: "center",
  "& .MuiGrid-item": {
    display: "flex",
    alignItems: "center",
  },
  "& .MuiSvgIcon-root": {
    display: "none",
    color: "#2196f399",
  },
  "&:hover": {
    border: "2px solid #2196f399",
    borderRadius: "6px",
    "& .MuiSvgIcon-root": {
      display: "inline-block",
    },
  },
};

const errorBadge = {
  color: "white",
  backgroundColor: "red",
  borderRadius: "50%",
  width: "13px",
  height: "13px",
  fontSize: "0.65rem",
  fontWeight: "bold",
  position: "absolute",
  top: 0,
  right: "-4px",
};

export {
  submitButton,
  cancelButton,
  containerStyle,
  fieldDelete,
  fieldContainer,
  fieldStyle,
  errorBadge,
  fieldDrag,
};
