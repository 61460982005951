import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  homeroomStudentInput,
  studentCell,
  studentCellSquare,
} from "./HomeroomRoster.style";

export default function ClassStudents({ students }) {
  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell width={200} sx={{ borderBottom: "1px solid black" }} />
            <TableCell sx={studentCell}>
              <TextField
                variant="standard"
                sx={studentCellSquare}
                inputProps={{
                  sx: {
                    ...homeroomStudentInput,
                  },
                }}
                /* eslint-disable-next-line react/jsx-no-duplicate-props */
                InputProps={{
                  sx: {
                    ...homeroomStudentInput,
                  },
                  disableUnderline: true,
                }}
              />
            </TableCell>
            <TableCell sx={studentCell}>
              <TextField
                variant="standard"
                sx={studentCellSquare}
                inputProps={{
                  sx: {
                    ...homeroomStudentInput,
                  },
                }}
                /* eslint-disable-next-line react/jsx-no-duplicate-props */
                InputProps={{
                  sx: {
                    ...homeroomStudentInput,
                  },
                  disableUnderline: true,
                }}
              />
            </TableCell>
            <TableCell sx={studentCell}>
              <TextField
                variant="standard"
                sx={studentCellSquare}
                inputProps={{
                  sx: {
                    ...homeroomStudentInput,
                  },
                }}
                /* eslint-disable-next-line react/jsx-no-duplicate-props */
                InputProps={{
                  sx: {
                    ...homeroomStudentInput,
                  },
                  disableUnderline: true,
                }}
              />
            </TableCell>
            <TableCell sx={studentCell}>
              <TextField
                variant="standard"
                sx={studentCellSquare}
                inputProps={{
                  sx: {
                    ...homeroomStudentInput,
                  },
                }}
                /* eslint-disable-next-line react/jsx-no-duplicate-props */
                InputProps={{
                  sx: {
                    ...homeroomStudentInput,
                  },
                  disableUnderline: true,
                }}
              />
            </TableCell>
            <TableCell sx={studentCell}>
              <TextField
                variant="standard"
                sx={studentCellSquare}
                inputProps={{
                  sx: {
                    ...homeroomStudentInput,
                  },
                }}
                /* eslint-disable-next-line react/jsx-no-duplicate-props */
                InputProps={{
                  sx: {
                    ...homeroomStudentInput,
                  },
                  disableUnderline: true,
                }}
              />
            </TableCell>
            <TableCell sx={studentCell}>
              <TextField
                variant="standard"
                sx={studentCellSquare}
                inputProps={{
                  sx: {
                    ...homeroomStudentInput,
                  },
                }}
                /* eslint-disable-next-line react/jsx-no-duplicate-props */
                InputProps={{
                  sx: {
                    ...homeroomStudentInput,
                  },
                  disableUnderline: true,
                }}
              />
            </TableCell>
            <TableCell sx={studentCell}>
              <TextField
                variant="standard"
                sx={studentCellSquare}
                inputProps={{
                  sx: {
                    ...homeroomStudentInput,
                  },
                }}
                /* eslint-disable-next-line react/jsx-no-duplicate-props */
                InputProps={{
                  sx: {
                    ...homeroomStudentInput,
                  },
                  disableUnderline: true,
                }}
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {students?.map((student) => (
            <TableRow key={student.id} hover>
              <TableCell
                align="center"
                scope="student"
                width={200}
                sx={{
                  borderTop: "1px solid black",
                  borderBottom: "1px solid black",
                }}
              >
                <TextField
                  variant="standard"
                  sx={studentCellSquare}
                  inputProps={{
                    sx: {
                      ...homeroomStudentInput,
                    },
                  }}
                  /* eslint-disable-next-line react/jsx-no-duplicate-props */
                  InputProps={{
                    sx: {
                      ...homeroomStudentInput,
                    },
                    disableUnderline: true,
                  }}
                />
                <Typography>{`${student.first_name} ${student.last_name}`}</Typography>
              </TableCell>
              <TableCell sx={studentCell}>
                <TextField
                  variant="standard"
                  sx={studentCellSquare}
                  inputProps={{
                    sx: {
                      ...homeroomStudentInput,
                    },
                  }}
                  /* eslint-disable-next-line react/jsx-no-duplicate-props */
                  InputProps={{
                    sx: {
                      ...homeroomStudentInput,
                    },
                    disableUnderline: true,
                  }}
                />
              </TableCell>
              <TableCell sx={studentCell}>
                <TextField
                  variant="standard"
                  sx={studentCellSquare}
                  inputProps={{
                    sx: {
                      ...homeroomStudentInput,
                    },
                  }}
                  /* eslint-disable-next-line react/jsx-no-duplicate-props */
                  InputProps={{
                    sx: {
                      ...homeroomStudentInput,
                    },
                    disableUnderline: true,
                  }}
                />
              </TableCell>
              <TableCell sx={studentCell}>
                <TextField
                  variant="standard"
                  sx={studentCellSquare}
                  inputProps={{
                    sx: {
                      ...homeroomStudentInput,
                    },
                  }}
                  /* eslint-disable-next-line react/jsx-no-duplicate-props */
                  InputProps={{
                    sx: {
                      ...homeroomStudentInput,
                    },
                    disableUnderline: true,
                  }}
                />
              </TableCell>
              <TableCell sx={studentCell}>
                <TextField
                  variant="standard"
                  sx={studentCellSquare}
                  inputProps={{
                    sx: {
                      ...homeroomStudentInput,
                    },
                  }}
                  /* eslint-disable-next-line react/jsx-no-duplicate-props */
                  InputProps={{
                    sx: {
                      ...homeroomStudentInput,
                    },
                    disableUnderline: true,
                  }}
                />
              </TableCell>
              <TableCell sx={studentCell}>
                <TextField
                  variant="standard"
                  sx={studentCellSquare}
                  inputProps={{
                    sx: {
                      ...homeroomStudentInput,
                    },
                  }}
                  /* eslint-disable-next-line react/jsx-no-duplicate-props */
                  InputProps={{
                    sx: {
                      ...homeroomStudentInput,
                    },
                    disableUnderline: true,
                  }}
                />
              </TableCell>
              <TableCell sx={studentCell}>
                <TextField
                  variant="standard"
                  sx={studentCellSquare}
                  inputProps={{
                    sx: {
                      ...homeroomStudentInput,
                    },
                  }}
                  /* eslint-disable-next-line react/jsx-no-duplicate-props */
                  InputProps={{
                    sx: {
                      ...homeroomStudentInput,
                    },
                    disableUnderline: true,
                  }}
                />
              </TableCell>
              <TableCell sx={studentCell}>
                <TextField
                  variant="standard"
                  sx={studentCellSquare}
                  inputProps={{
                    sx: {
                      ...homeroomStudentInput,
                    },
                  }}
                  /* eslint-disable-next-line react/jsx-no-duplicate-props */
                  InputProps={{
                    sx: {
                      ...homeroomStudentInput,
                    },
                    disableUnderline: true,
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
