const termGradeDetailRadioContainer = {
  width: "130px",
  alignItems: "start",
};

const termGradeDetailLabel = {
  mt: "9px",
};

export { termGradeDetailRadioContainer, termGradeDetailLabel };
