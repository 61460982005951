import { ValueType } from "./constants";

export default class Expression {
  constructor() {
    this.operand1 = "";
    this.operand2 = "";
    this.operator = "";
    this.valueType = ValueType.FIELD;
    this.value = "";
  }
}
