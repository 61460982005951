import React from "react";
import { useTranslation } from "react-i18next";
import { CssBaseline, Box, Grid, Button } from "@mui/material";
import {
  footerStyle,
  footerGridContainer,
  textGridItem,
  launchGridItem,
  launchBtn,
  poweredByLogoGridItem,
  poweredByTxt,
  poweredByLogo,
  poweredByLogoTagLine,
  aeLogoGridItem,
  aeLogo,
} from "./Footer.styles";

const openSchoolWebsite = (url) => {
  // eslint-disable-next-line no-param-reassign
  url = url.match(/^https?:/) ? url : `//${url}`;
  window.open(url, "_blank", "noreferrer");
};

export default function Footer({ kidSchool }) {
  const { t } = useTranslation("guardian", { keyPrefix: "footer" });

  return (
    <footer style={footerStyle}>
      <CssBaseline />
      <Grid container alignItems="stretch" sx={footerGridContainer}>
        <Grid item md={6} container sx={textGridItem}>
          {kidSchool?.description && <Box>{kidSchool?.description}</Box>}
        </Grid>
        <Grid item md={6} container sx={launchGridItem}>
          <Box>
            {kidSchool?.website && (
              <Button
                sx={launchBtn}
                onClick={() =>
                  openSchoolWebsite(kidSchool?.website?.toString())
                }
              >
                {t("launchSchoolWebsite")}
              </Button>
            )}
          </Box>
        </Grid>

        <Grid item xs={6} container sx={poweredByLogoGridItem}>
          <div>
            <div style={poweredByTxt}> {t("poweredBy")} </div>
            <img
              src="/assets/logo-new.png"
              style={poweredByLogo}
              alt="ae logo"
            />
            <div style={poweredByLogoTagLine}>
              {t("AEStudentInformationSystem")}
            </div>
          </div>
        </Grid>
        <Grid item xs={6} container sx={aeLogoGridItem}>
          <div>
            <img
              src="/assets/ae-footer-logo.png"
              style={aeLogo}
              alt="ae logo"
            />
          </div>
        </Grid>
      </Grid>
    </footer>
  );
}
