import React, { useState } from "react";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import { SecurityGroupModalStyle } from "./SecurityGroup.style";
import securityGroupsService from "../../service/securityGroupsService";

function SecurityGroupModal({
  open,
  handleClose,
  operation,
  sgName,
  setSgName,
  sgClone,
  getSecurityGroups,
  schoolId,
}) {
  const [error, setError] = useState("");

  const handleCreate = async () => {
    if (sgName === "") {
      return setError("Security group name is required.");
    }
    setError("");

    if (operation === "clone") {
      await securityGroupsService.cloneSecurityGroup({
        name: sgName,
        securityGroupId: sgClone.id,
      });
      await getSecurityGroups();

      return handleClose();
    }

    if (operation === "create") {
      await securityGroupsService.create({
        name: sgName,
        schoolId,
      });

      await getSecurityGroups();

      return handleClose();
    }

    return true;
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setError("");
        handleClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={SecurityGroupModalStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Create a new security group
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
        </Typography>
        <Box
          sx={{
            display: "flex",
            mt: "15px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: "300px" }}>
            <TextField
              id="sgName"
              label="Security group name"
              fullWidth
              value={sgName}
              onChange={(e) => setSgName(e.target.value)}
            />
          </Box>
          <Button
            sx={{
              backgroundColor: "#2196F3",
              padding: "2px 15px",
              height: "40px",
            }}
            variant="contained"
            onClick={() => handleCreate()}
          >
            Create
          </Button>
        </Box>

        {error !== "" && <Box sx={{ color: "red", ml: "5px" }}>{error}</Box>}
      </Box>
    </Modal>
  );
}

export default SecurityGroupModal;
