import React, { useEffect, useState, useContext } from "react";
import {
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Switch,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { MoreVert } from "@mui/icons-material";
import { tableStyles } from "./ParentSection.styles";
import studentsService from "../../../service/studentsService";
import { updatePortalAccess } from "../../../service/guardiansService";
import usersService from "../../../service/usersService";
import { formatPhoneNumber } from "../../../utils/constants/utils";
import SnackBarNotification from "../../SnackBarNotification";
import { StudentActionsContext } from "../../../context/StudentActionsContext";
import { PermissionsContext } from "../../../context/PermissionsContext";
import APP_PERMISSIONS from "../../../utils/constants/permissions";
import PERMISSION_TYPES from "../../../utils/constants/permission_types";
import { switchBtn } from "../../sharedStyles";

function ParentSection({
  setCurrentGuardianId,
  setResidesWith,
  currentGuardianId,
}) {
  const params = useParams();
  const [guardians, setGuardians] = useState();
  const [portalEnabled, setPortalEnabled] = useState(false);
  const studentActionsContext = useContext(StudentActionsContext);
  const [snackBarOpen, setSnackBarOpen] = useState({
    open: false,
    message: null,
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const [guardianEmail, setGuardianEmail] = useState(null);
  const menuOpen = Boolean(anchorEl);

  const { hasPermission } = useContext(PermissionsContext);

  const managePermission = hasPermission(
    APP_PERMISSIONS.STUDENT_ACCOUNT,
    PERMISSION_TYPES.MANAGE
  );

  const handleMenuClick = (event, guardian) => {
    const shouldEnablePortal = guardian.has_portal_access;
    setCurrentGuardianId(guardian.id);
    setPortalEnabled(shouldEnablePortal);
    setGuardianEmail(guardian.email);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSackBarClose = () => {
    setSnackBarOpen({ open: false });
  };

  const loadGuardians = async () => {
    const response = await studentsService.fetchGuardiansByStudent(
      params.student_slug
    );
    setGuardians(response);
    setResidesWith(response);
  };

  const editGuardian = () => {
    setAnchorEl(null);
    studentActionsContext.openGuardianDrawer(currentGuardianId);
  };

  const handlePortalAccess = async (e, guardianId) => {
    const { checked } = e.target;
    const response = await updatePortalAccess(guardianId, {
      student_slug: params.student_slug,
      has_portal_access: checked,
    });

    if (response.data) {
      setSnackBarOpen({
        open: true,
        message: response.data,
      });
    }
  };

  const handlePasswordReset = async () => {
    const response = await usersService.sendResetPasswordInstructions({
      email: guardianEmail,
    });

    if (response.message) {
      setSnackBarOpen({
        open: true,
        message: response.message,
      });
    }
  };

  useEffect(() => {
    (async () => {
      await loadGuardians();
    })();
  }, [snackBarOpen]);

  useEffect(() => {
    if (studentActionsContext.parentsUpdated) {
      loadGuardians();
      studentActionsContext.cleanParentsUpdated();
    }
  }, [studentActionsContext.parentsUpdated]);

  return (
    <Grid
      container
      item
      justifyContent="space-around"
      alignItems="baseline"
      rowSpacing={3}
      sm={11}
      md={12}
    >
      <Grid container item sm={12} justifyContent="space-between">
        <Typography variant="h3">Parent/Guardian</Typography>
      </Grid>
      <TableContainer>
        <Table sx={tableStyles} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>NAME</TableCell>
              <TableCell align="left">RELATIONSHIP</TableCell>
              <TableCell align="left">ADDRESS</TableCell>
              <TableCell align="left">EMAIL</TableCell>
              <TableCell align="left">PHONE</TableCell>
              <TableCell align="left">PORTAL</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {guardians &&
              guardians.map((guardian) => (
                <TableRow key={guardian.id} hover>
                  <TableCell component="th" scope="guardian">
                    <Typography variant="h6">
                      {`${guardian.first_name} ${guardian.last_name}`}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography>{guardian.relationship}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {guardian.mailing_address_line1 &&
                        `${guardian.mailing_address_line1},`}{" "}
                      {guardian.mailing_address_line2 &&
                        `${guardian.mailing_address_line2},`}{" "}
                      {guardian.mailing_address_city &&
                        `${guardian.mailing_address_city},`}{" "}
                      {guardian.mailing_address_state_abbreviation}{" "}
                      {guardian.mailing_address_postal_code}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography>{guardian.email}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography>
                      {formatPhoneNumber(guardian.cell_phone)}
                    </Typography>
                  </TableCell>
                  {managePermission && (
                    <>
                      <TableCell>
                        <Switch
                          disabled={!guardian.email}
                          defaultChecked={guardian.has_portal_access}
                          sx={switchBtn}
                          onChange={(e) => handlePortalAccess(e, guardian.id)}
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={(e) => handleMenuClick(e, guardian)}
                        >
                          <MoreVert />
                        </IconButton>
                      </TableCell>
                    </>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Menu anchorEl={anchorEl} open={menuOpen} onClose={handleMenuClose}>
        <MenuItem autoFocus={false} onClick={() => editGuardian()}>
          Edit Parent/Guardian
        </MenuItem>
        <MenuItem
          disabled={!portalEnabled}
          onClick={() => handlePasswordReset()}
        >
          Send password reset
        </MenuItem>
      </Menu>
      <SnackBarNotification
        message={snackBarOpen.message}
        open={snackBarOpen.open}
        handleClose={handleSackBarClose}
      />
    </Grid>
  );
}

export default ParentSection;
