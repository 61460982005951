import React from "react";
import StarOutlineOutlinedIcon from "@mui/icons-material/StarOutlineOutlined";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { tableStyles, primaryIcon, email } from "./EmergencyInformation.styles";
import { formatPhoneNumber } from "../../../utils/constants/utils";

export default function EmergencyContactTable({
  emergencyContacts,
  emergencyGuardians,
}) {
  return (
    <TableContainer>
      <Typography fontSize={13} color="black" fontWeight={500}>
        EMERGENCY CONTACTS
      </Typography>
      <Table sx={tableStyles} aria-label="simple table">
        <TableBody>
          {emergencyGuardians.map((emergencyGuardian) => (
            <TableRow key={emergencyGuardian.id}>
              <TableCell component="th" scope="guardian">
                <Typography>{`${emergencyGuardian.guardian.first_name} ${emergencyGuardian.guardian.last_name}`}</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>{emergencyGuardian.relationship}</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography sx={email}>
                  {emergencyGuardian.guardian.email}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>
                  {formatPhoneNumber(emergencyGuardian.guardian.cell_phone)}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
          {emergencyContacts.map((contact) => (
            <TableRow key={contact.id}>
              <TableCell component="th" scope="contact">
                <Typography>
                  {`${contact.first_name} ${contact.last_name}`}
                  {contact.is_primary && (
                    <StarOutlineOutlinedIcon sx={primaryIcon} />
                  )}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>{contact.relationship}</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography sx={email}>{contact.email}</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>{formatPhoneNumber(contact.cell_phone)}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
