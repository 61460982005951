import axios from "../utils/axios-util";

const countriesEndpoint = "/api/countries";

const fetchAllCountries = async () => {
  const { data } = await axios.get(`${countriesEndpoint}`);
  if (!data.data) {
    console.error("Error fetching countries from the API");
    return [];
  }
  return data;
};

const fetchStatesByCountry = async (countryId) => {
  const { data } = await axios.get(`${countriesEndpoint}/${countryId}/states`);
  if (!data.data) {
    console.error("Error fetching countries from the API");
    return [];
  }
  return data;
};

const fetchAllStates = async () => {
  const { data } = await axios.get(`${countriesEndpoint}/all/states`);
  if (!data.data) {
    console.error("Error fetching states from the API");
    return [];
  }
  return data;
};

export default {
  fetchAllCountries,
  fetchStatesByCountry,
  fetchAllStates,
};
