const gridContainer = { color: "#000000" };

const gridItemStudentInfo = {
  fontSize: "36px",
};

const gridItemTeacherInfo = {
  textAlign: "end",
  fontSize: "22px",
  letterSpacing: "-0.05em",
};

const displayInline = {
  display: "inline-grid",
};

const gridItemTeacherInfoPrincipalLabel = {
  display: "inline-grid",
  fontSize: "16px",
  pl: "10px",
};

const gridItemTeacherInfoLabel = {
  ...gridItemTeacherInfoPrincipalLabel,
  pr: "20px",
};

const newMessageTo = {
  color: "#000",
  fontSize: "26px",
  pr: "20px",
  margin: "revert",
};

const newMessageRecipientTo = {
  ...newMessageTo,
  fontSize: "16px",
};

const newMessageName = {
  color: "#000",
  fontSize: "42px",
  mt: "10px",
  ml: "10px",
};

const newMessageBox = {
  justifyContent: "center",
  display: "flex",
};

const newMessageDesc = {
  mt: 2,
  width: "100%",
  "& .MuiFilledInput-underline": {
    p: "30px",
    "&:before": {
      left: "30px",
      right: "30px",
    },
    "&:after": {
      borderBottom: "none",
      left: "30px",
      right: "30px",
    },
  },
};

const newMessageModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  width: "50%",
};

const newMessageSend = {
  mt: "10px",
  textTransform: "none",
  backgroundColor: "#2CB6D8",
  borderRadius: "3px",
  "&:hover": {
    backgroundColor: "#2CB6D8",
  },
};

const newMessageDescError = {
  pl: "30px",
  pt: "10px",
  backgroundColor: "#f0f0f0",
};

const modalCloseIcon = {
  position: "absolute",
  right: "10px",
  top: "10px",
};

const newMessageRecipients = {
  backgroundColor: "#f0f0f0",
  padding: "10px",
  borderRadius: "4px",
  "& #recipients": {
    background: "none",
  },
};

export {
  gridContainer,
  gridItemStudentInfo,
  displayInline,
  gridItemTeacherInfo,
  gridItemTeacherInfoLabel,
  gridItemTeacherInfoPrincipalLabel,
  newMessageTo,
  newMessageName,
  newMessageBox,
  newMessageDesc,
  newMessageModal,
  newMessageSend,
  newMessageDescError,
  modalCloseIcon,
  newMessageRecipientTo,
  newMessageRecipients,
};
