import CheckboxField from "./CheckboxField";
import DropdownList from "./DropdownList";
import LongText from "./LongText";
import NumberField from "./NumberField";
import RadioButtonList from "./RadioButtonList";
import ShortText from "./ShortText";
import PhoneNumber from "./PhoneNumber";
import EmailAddress from "./EmailAddress";
import Name from "./Name";
import DateField from "./DateField";
import DescriptionArea from "./DescriptionArea";
import HorizontalDivider from "./HorizontalDivider";
import PageBreak from "./PageBreak";
import SignatureField from "./SignatureField";
import SubHeading from "./SubHeading";

const classesMap = {
  "short-text": ShortText,
  "long-text": LongText,
  "dropdown-list": DropdownList,
  "radio-button-list": RadioButtonList,
  "checkbox-field": CheckboxField,
  "number-field": NumberField,
  "email-address": EmailAddress,
  "phone-number": PhoneNumber,
  "name-field": Name,
  "date-field": DateField,
  "description-area": DescriptionArea,
  "horizontal-divider": HorizontalDivider,
  "page-break": PageBreak,
  "signature-field": SignatureField,
  "sub-heading": SubHeading,
};

export default classesMap;
