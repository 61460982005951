import CustomFieldBase from "./CustomFieldBase";

export default class CustomNumberField extends CustomFieldBase {
  constructor(field) {
    super(field);
    this.dataType = "value_number";
    this.type = "number-field";
    this.prompt = "NUMBER";
    this.propertyComponents = ["characterLimit", "isVisible", "isRequired"];
  }
}
