import { generateGUID } from "../../../../../utils/constants/utils";

export default class CustomFieldBase {
  constructor(_field) {
    const field = _field || {};
    this.id = field.id || null;
    this.fieldId =
      field.fieldId ||
      field.field_id ||
      `_${generateGUID().replaceAll("-", "_")}`;
    this.label = field.label || "";
    this.identifier = field.identifier || "";
    this.type = field.type || field.field_type || "";
    this.dataType = field.dataType || field.data_type || "";
    this.sectionType = field.sectionType || field.section_type || "";
    this.responseType = field.responseType || field.response_type || "";
    this.properties = field.properties || {};
    this.order = field.order || 0;
  }

  serialize() {
    return {
      id: this.id,
      field_id: this.fieldId,
      properties: this.properties,
      label: this.label,
      identifier: this.identifier,
      field_type: this.type,
      data_type: this.dataType,
      section_type: this.sectionType,
      response_type: this.responseType,
      order: this.order,
    };
  }
}
