import React from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Link, useParams } from "react-router-dom";
import { Box, Stack, Typography } from "@mui/material";
import {
  messagesRow,
  messagesRowTimestamp,
  messagesAttachIcon,
  messagesInfoContainer,
  messagesRowDescription,
} from "../../views/Messages.styles";
import { sentMessagesAttachmentContainer } from "./SentMessagesRow.styles";
import { font14 } from "../sharedStyles";
import dateTimeFormats from "../../utils/constants/dateTimeFormats";
import moment from "../../utils/constants/momentConfig";

export default function SentMessagesRow({ message }) {
  const params = useParams();
  const schoolId = params.school_id;
  const recipient = `${message.recipient.first_name} ${message.recipient.last_name} (${message.recipient_type})`;
  const conversationKey =
    message.conversation_key || message.original_message_conversation_key;

  const formattedBody = message.description.replace(/(<([^>]+)>)/gi, "");

  return (
    <Box sx={messagesRow}>
      <Link to={`/school/${schoolId}/messages/conversation/${conversationKey}`}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row">
            <Box sx={messagesInfoContainer}>
              <Typography sx={font14}>
                <strong>{message.subject}</strong>
              </Typography>
              <Typography sx={font14}>To: {recipient}</Typography>
              <Typography sx={font14}>{message.recipient.email}</Typography>
            </Box>
            <Box sx={messagesRowDescription}>
              <Typography>{formattedBody.replaceAll("&nbsp;", "")}</Typography>
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center">
            <Typography sx={messagesRowTimestamp}>
              {moment(message.created_at).format(dateTimeFormats.LLL)}
            </Typography>
            <Box sx={sentMessagesAttachmentContainer}>
              {message.has_attachments && (
                <AttachFileIcon sx={messagesAttachIcon} />
              )}
            </Box>
          </Stack>
        </Stack>
      </Link>
    </Box>
  );
}
