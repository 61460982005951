import { tableCellClasses, tableRowClasses } from "@mui/material";

const addButtonStyle = {
  marginTop: "1.5rem",
  "& button": {
    background: "transparent",
    color: "#5077A4",
    boxShadow: "none",
    border: "1px solid #79747E",
    borderRadius: "0.5rem",
    padding: "0.75rem !important",
    "&:hover": { background: "transparent" },
  },
};
const tableStyles = {
  minWidth: 650,
  [`& .${tableCellClasses.root}`]: {
    borderBottom: "none",
  },
  [`& .${tableRowClasses.root}:not(:last-child)`]: {
    borderBottom: "1px solid #d9d9d9",
  },
  "& .MuiTableCell-head": {
    color: "#8f8e8e",
    fontWeight: "500 !important",
  },
  "& .MuiTableCell-body": {
    color: "black",
    fontSize: 16,
  },
};

const documentFilesRow = {
  "&:last-child td, &:last-child th": {
    border: 0,
  },
};
const tableCellName = {
  width: "70%",
  fontSize: 25,
};
const tableCellNameInnerContainer = {
  display: "flex",
  alignItems: "center",
};
const fileIconStyle = {
  fontSize: "2rem",
  marginRight: 1,
};
const tableCellType = {
  width: "20%",
  fontSize: 25,
  textAlign: "center",
};
const actionContainerStyle = {
  display: "flex",
  alignItems: "center",
};
const actionButtonStyle = {
  fontSize: "1.75rem",
  marginRight: "0.25rem",
  cursor: "pointer",
};
const iconButtonStyle = {
  border: "none",
  color: "black",
};

const actionButtonCursorStyle = {
  cursor: "pointer",
};

export {
  addButtonStyle,
  tableStyles,
  documentFilesRow,
  tableCellName,
  tableCellType,
  actionContainerStyle,
  actionButtonStyle,
  iconButtonStyle,
  tableCellNameInnerContainer,
  fileIconStyle,
  actionButtonCursorStyle,
};
