import axios from "../utils/axios-util";

const staffsEndpoint = "/api/staffs";

const fetchAllStaffs = async (params) => {
  const { data } = await axios.get(`${staffsEndpoint}`, params);
  if (!data) {
    console.error("Error fetching users from the API");
    return [];
  }
  return data;
};

const fetchHomeroomTeachers = async (params) => {
  const { data } = await axios.get(
    `${staffsEndpoint}/homeroom-teachers`,
    params
  );
  if (!data) {
    console.error("Error fetching users from the API");
    return [];
  }
  return data;
};

const fetchPaginatedTeachers = async (params) => {
  const { data } = await axios.get(`${staffsEndpoint}/paginated_list`, params);
  if (!data) {
    console.error("Error fetching users from the API");
    return [];
  }
  return data;
};

const fetchTeacher = async (id, params) => {
  const { data } = await axios.get(`${staffsEndpoint}/${id}`, params);
  if (!data) {
    console.error("Error fetching teacher from the API");
    return [];
  }
  return data;
};

const syncStaff = async (params) => {
  const { data } = await axios.get(`${staffsEndpoint}/sync_staff`, {
    params,
  });
  if (!data) {
    console.error("Error fetching teacher from the API");
    return [];
  }
  return data;
};

const teacherHasHomeroomStudents = async (params) => {
  const { data } = await axios.get(
    `${staffsEndpoint}/has-homeroom-students`,
    params
  );
  if (!data) {
    console.error("Error fetching data from the API");
    return false;
  }
  return data.data;
};

const fetchByCurrentUser = async (params) => {
  const { data } = await axios.get(`${staffsEndpoint}/current-teacher`, params);

  if (!data) {
    console.error("Error fetching current teacher from API");
    return null;
  }
  return data;
};

const fetchSchools = async (id) => {
  const { data } = await axios.get(`${staffsEndpoint}/${id}/schools`);
  if (!data) {
    console.error("Error fetching schools from the API");
    return [];
  }
  return data;
};

const update = (id, data) => axios.put(`${staffsEndpoint}/${id}`, data);

const createAttendanceSubstitute = (data) =>
  axios.post(`${staffsEndpoint}/attendance_substitute`, data);

const fetchSubstituteTeachers = async () => {
  const { data } = await axios.get(`${staffsEndpoint}/attendance_substitute`);
  if (!data) {
    console.error("Error fetching users from the API");
    return [];
  }
  return data;
};

export default {
  fetchAllStaffs,
  fetchHomeroomTeachers,
  fetchPaginatedTeachers,
  teacherHasHomeroomStudents,
  fetchByCurrentUser,
  fetchTeacher,
  update,
  syncStaff,
  fetchSchools,
  createAttendanceSubstitute,
  fetchSubstituteTeachers,
};
