const hidden = { visibility: "hidden" };

const listTable = {
  color: "black",
};

const listHead = (isCentered, isActive = false) => ({
  borderBottom: "none",
  color: isActive ? "black" : "#808080",
  cursor: "pointer",
  padding: 0,
  "& p": {
    fontSize: "12px !important",
    display: "flex",
    alignItems: "end",
    justifyContent: isCentered ? "center" : "flex-start",
    whiteSpace: "nowrap",
  },
});

const listHeadName = (isCentered, isActive = false) => ({
  ...listHead(isCentered, isActive),
  width: "250px",
});

const listCell = (isCentered = false) => ({
  padding: 2,
  color: "black",
  paddingLeft: isCentered ? 2 : 0,
  fontSize: "14px !important",
});

const settingsCell = {
  color: "#808080",
  cursor: "pointer",
};

const buttonsTabsContainer = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  marginTop: 2,
};

export {
  hidden,
  listTable,
  listHead,
  listCell,
  settingsCell,
  listHeadName,
  buttonsTabsContainer,
};
