import theme from "../../../utils";

const termGradeSkillCell = {
  color: "black",
  borderColor: "black",
  py: 0,
  position: "relative",
};

const studentCell = {
  ...termGradeSkillCell,
  px: 0,
  width: "100px",
  height: "70px",
  borderLeft: "1px solid #BFC7CA",
  borderRight: "1px solid #BFC7CA",
  "& :hover": {
    cursor: "pointer",
  },
  "& p": {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

const studentCellSquare = {
  "&:focus-within": {
    border: `6px solid ${theme.palette.cell.selected}`,
  },
  height: "100%",
  width: "100%",
  fontSize: "20px",
  fontWeight: "400",
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
};

const homeroomStudentInput = {
  textAlign: "center",
  color: "black",
  textTransform: "uppercase",
};
export { studentCell, studentCellSquare, homeroomStudentInput };
