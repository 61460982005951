import React, { useContext, createContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SchedulerContext } from "./SchedulerContext";
import { useUnsavedChanges } from "./UnsavedChangesContext";
import { admissionFormUnsavedKey } from "../components/Admissions/Setup/FormBuilder/FormBuilderUtils";

export const NavigationContext = createContext({
  navigationAlert: false,
  navigationPath: "",
});

export function NavigationProvider({ children }) {
  const navigate = useNavigate();
  const [navigationPath, setNavigationPath] = useState("");
  const [navigationAlert, setNavigationAlert] = useState(false);
  const schedulerContext = useContext(SchedulerContext);
  const { unsavedChanges } = useUnsavedChanges();

  const navigateTo = (path) => {
    if (
      schedulerContext.taskCount.current > 0 ||
      schedulerContext.savingCurrent.current ||
      unsavedChanges ||
      localStorage.getItem(admissionFormUnsavedKey)
    ) {
      setNavigationPath(path);
      setNavigationAlert(true);
    } else navigate(path);
  };

  const cleanNavigationAlert = () => {
    setNavigationAlert(false);
    localStorage.removeItem(admissionFormUnsavedKey);
  };

  const contextValue = useMemo(
    () => ({
      navigateTo,
      navigationPath,
      cleanNavigationAlert,
      navigationAlert,
    }),
    [navigationPath, navigationAlert, unsavedChanges]
  );

  return (
    <NavigationContext.Provider value={contextValue}>
      {children}
    </NavigationContext.Provider>
  );
}
