import React, { useState, useEffect } from "react";
import { useOutletContext, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Container,
  Box,
  Pagination,
  Typography,
  CircularProgress,
} from "@mui/material";
import NotificationRow from "./Row";
import notificationsService from "../../service/notificationsService";
import { paginationContent, textCenter, loader } from "../sharedStyles";

export default function List() {
  const { t } = useTranslation("notifications");
  const [searchParams, setSearchParams] = useSearchParams();
  const { query } = useOutletContext();
  const [notifications, setNotifications] = useState();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [count, setCount] = useState(10);

  const getNotifications = async () => {
    const response = await notificationsService.listNotifications({
      params: {
        query,
        count,
        page,
      },
    });

    if (response) {
      const queryPage = searchParams.get("page") || 1;
      const lastPage = response.pagination.total_pages;
      const currentPage = queryPage > lastPage ? lastPage : queryPage;
      const countPerPage = searchParams.get("count") || 10;
      setTotalPages(lastPage);
      setPage(currentPage || 1);
      setCount(countPerPage);
      setNotifications(response.data);
    }
  };

  const handleChangePage = (event, value) => {
    setPage(value);
    searchParams.set("page", value);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (page > 0 && query != null) {
      getNotifications();
    }
  }, [page, query]);

  if (!notifications) {
    return <CircularProgress sx={loader} size={100} />;
  }

  return (
    <Container maxWidth={false} variant="header">
      <Container maxWidth="xl">
        {notifications.length > 0 ? (
          <>
            <Box>
              {notifications.map((notification) => (
                <NotificationRow
                  notification={notification}
                  key={notification.id}
                />
              ))}
            </Box>

            <Pagination
              count={totalPages}
              page={parseInt(page, 10)}
              onChange={handleChangePage}
              sx={paginationContent}
            />
          </>
        ) : (
          <Box sx={textCenter}>
            <Typography>{t("noNotifications")}</Typography>
          </Box>
        )}
      </Container>
    </Container>
  );
}
