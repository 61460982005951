import { Button, Divider, Grid } from "@mui/material";
import React, { useContext } from "react";
import { Add } from "@mui/icons-material";
import ProcessStage from "./ProcessStage";
import AdmissionProcessStage from "./Models/AdmissionProcessStage";
import { ProcessBuilderContext } from "./ProcessBuilderContext";

export default function AdmissionProcessContainer() {
  const { process, updateProcess, updateCurrentStageIndex } = useContext(
    ProcessBuilderContext
  );

  const addStage = () => {
    const updatedStages = [...process.stages];
    const newStage = new AdmissionProcessStage();
    newStage.order = updatedStages.length + 1;
    updatedStages.push(newStage);
    updateProcess({ ...process, stages: updatedStages });
  };

  return (
    <Grid container justifyContent="center">
      {process.stages.map((stage, index) => (
        <>
          <Grid
            onClick={() => {
              updateCurrentStageIndex(index);
            }}
            item
            md={10}
          >
            <ProcessStage stage={stage} stageIndex={index} />
          </Grid>

          <Grid container item md={10} justifyContent="center">
            <Divider
              orientation="vertical"
              flexItem
              sx={{ height: "50px", width: "1px", borderColor: "#000000" }}
            />
          </Grid>
        </>
      ))}
      <Grid container item md={10} justifyContent="center">
        <Button
          sx={{
            textTransform: "none",
            color: "#2196F3",
            borderColor: "#2196F3",
          }}
          variant="outlined"
          startIcon={<Add />}
          onClick={addStage}
        >
          Add New Stage
        </Button>
      </Grid>
    </Grid>
  );
}
