import axios from "../utils/axios-util";

const socialDevelopmentCommentServiceEndpoint =
  "/api/social_development_comments";

const fetch = async (params) => {
  const { data } = await axios.get(
    `${socialDevelopmentCommentServiceEndpoint}/${params.term_id}/${params.student_id}`
  );
  if (!data.data) {
    console.error("Error fetching social development comment from API");
    return [];
  }
  return data;
};

const fetchAll = async (params) => {
  const { data } = await axios.get(
    socialDevelopmentCommentServiceEndpoint,
    params
  );
  if (!data.data) {
    console.error("Error fetching social development comments from API");
    return [];
  }
  return data;
};

const save = async (params) => {
  const { data } = await axios.post(
    socialDevelopmentCommentServiceEndpoint,
    params
  );
  if (!data.data) {
    console.error("Error saving social development comment");
    return [];
  }
  return data;
};

export default { fetch, fetchAll, save };
