import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Typography,
  Container,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Drawer,
  CircularProgress,
} from "@mui/material";
import { useParams, useSearchParams, useOutletContext } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import {
  hidden,
  listTable,
  listHead,
  listName,
  listSubject,
  listCell,
  listCellName,
  settingsCell,
} from "./DayTemplates.styles";
import dayTemplateService from "../../../service/dayTemplateService";
import { mr10, py30, rightDrawerContainer, loader } from "../../sharedStyles";
import DayTemplatesForm from "./DayTemplatesForm";
import { PermissionsContext } from "../../../context/PermissionsContext";
import APP_PERMISSIONS from "../../../utils/constants/permissions";
import PERMISSION_TYPES from "../../../utils/constants/permission_types";
import Authorize from "../../Authorize";

export default function DayTemplates() {
  const params = useParams();
  const schoolId = params.school_id;

  const [dayTemplates, setDayTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [order, setOrder] = useState(searchParams.get("direction") || "asc");
  const [orderBy, setOrderBy] = useState(searchParams.get("sort") || "name");
  const [drawerStatusEdit, setDrawerStatusEdit] = useState(false);
  const [selectedDayTemplate, setSelectedDayTemplate] = useState(null);
  const [selectedFormattedDayTemplate, setSelectedFormattedDayTemplate] =
    useState(null);
  const [
    // eslint-disable-next-line no-unused-vars
    selectedSchoolYear,
    // eslint-disable-next-line no-unused-vars
    setSelectedSchoolYear,
    // eslint-disable-next-line no-unused-vars
    termsDrawerStatus,
    // eslint-disable-next-line no-unused-vars
    setSnackBarOpen,
    dayTemplateDrawerStatus,
  ] = useOutletContext();
  const { hasPermission, hasAnyPermissionType } =
    useContext(PermissionsContext);

  const managePermission = hasPermission(
    APP_PERMISSIONS.DAY_TEMPLATE,
    PERMISSION_TYPES.MANAGE
  );

  const getDayTemplates = async () => {
    if (!drawerStatusEdit && !dayTemplateDrawerStatus) {
      setLoading(true);
    }

    if (!drawerStatusEdit) {
      const response = await dayTemplateService.fetchAllDayTemplates({
        params: {
          school_year_id: selectedSchoolYear?.id,
          order,
          order_by: orderBy,
        },
      });
      if (response.data) {
        setDayTemplates(response.data);
        setLoading(false);
      }
    }
  };

  const renderSortChevron = (label) => {
    const noColumnSelected = searchParams.get("sort") === null;

    if ((noColumnSelected && label === "name") || label === orderBy) {
      return order === "asc" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
    }
    return <ArrowDropUpIcon sx={hidden} />;
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    const direction = isAsc ? "desc" : "asc";
    setOrder(direction);
    setOrderBy(property);

    searchParams.set("sort", property);
    searchParams.set("direction", direction);
    setSearchParams(searchParams);
  };

  const formattedDayTemplate = (dayTemplate) => {
    const trackMap = new Map();
    dayTemplate.periods.forEach((period) => {
      const track = trackMap.get(period.track);
      if (!track) {
        trackMap.set(period.track, { name: period.track, periods: [period] });
      } else {
        track.periods.push(period);
      }
    });

    const formatted = { ...dayTemplate, tracks: [...trackMap.values()] };
    delete formatted.periods;
    return formatted;
  };

  const handleDrawer = (status, dayTemplate) => {
    setDrawerStatusEdit(status);
    if (status === false) {
      setSelectedDayTemplate(null);
      setSelectedFormattedDayTemplate(null);
    } else {
      setSelectedDayTemplate(dayTemplate);
      setSelectedFormattedDayTemplate(formattedDayTemplate(dayTemplate));
    }
  };

  useEffect(() => {
    getDayTemplates();
    setOrder(searchParams.get("direction") || "asc");
    setOrderBy(searchParams.get("sort") || "name");
  }, [
    order,
    orderBy,
    drawerStatusEdit,
    dayTemplateDrawerStatus,
    selectedSchoolYear,
  ]);

  if (loading) {
    return <CircularProgress sx={loader} size={100} />;
  }

  return (
    <Authorize permission={hasAnyPermissionType(APP_PERMISSIONS.DAY_TEMPLATE)}>
      <Container maxWidth={false} variant="header">
        <Container maxWidth="lg">
          <Grid container>
            <Grid item sm={12}>
              {dayTemplates.length > 0 ? (
                <TableContainer sx={py30}>
                  <Table sx={listTable}>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={listHead(false, orderBy === "name")}
                          onClick={() => handleRequestSort("name")}
                        >
                          <Typography sx={listName}>
                            NAME {renderSortChevron("name")}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={listHead(false, orderBy === "periods")}
                          onClick={() => handleRequestSort("periods")}
                        >
                          <Typography sx={mr10}>
                            PERIODS {renderSortChevron("periods")}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={listHead(false, orderBy === "total_time")}
                          onClick={() => handleRequestSort("total_time")}
                        >
                          <Typography sx={listSubject}>
                            TOTAL TIME {renderSortChevron("total_time")}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={listHead(false, orderBy === "in_use")}
                          onClick={() => handleRequestSort("in_use")}
                        >
                          <Typography sx={listSubject}>
                            IN USE {renderSortChevron("in_use")}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={listHead(false, orderBy === "abbreviation")}
                          onClick={() => handleRequestSort("abbreviation")}
                        >
                          <Typography sx={listSubject}>
                            ABBR. {renderSortChevron("abbreviation")}
                          </Typography>
                        </TableCell>
                        <TableCell sx={listHead} />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dayTemplates.map((dayTemplate) => (
                        <TableRow hover key={dayTemplate.id}>
                          <TableCell sx={listCellName}>
                            <Typography>
                              <span>{dayTemplate.name}</span>
                            </Typography>
                          </TableCell>
                          <TableCell sx={listCell()}>
                            <Typography>
                              <span>{dayTemplate.periods.length}</span>
                            </Typography>
                          </TableCell>
                          <TableCell sx={listCell()}>
                            <Typography>
                              <span>{dayTemplate.total_time}</span>
                            </Typography>
                          </TableCell>
                          <TableCell sx={listCell()}>
                            <Typography>
                              <span>{dayTemplate.in_use ? "yes" : "no"}</span>
                            </Typography>
                          </TableCell>
                          <TableCell sx={listCell()}>
                            <Typography>
                              <span>{dayTemplate.abbreviation}</span>
                            </Typography>
                          </TableCell>
                          {managePermission && (
                            <TableCell
                              sx={settingsCell}
                              onClick={() => handleDrawer(true, dayTemplate)}
                            >
                              <SettingsOutlinedIcon />
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography align="center">No day templates found.</Typography>
              )}
            </Grid>
          </Grid>
        </Container>
      </Container>

      <Drawer anchor="right" open={drawerStatusEdit}>
        <Box sx={rightDrawerContainer}>
          {selectedDayTemplate ? (
            <DayTemplatesForm
              schoolId={schoolId}
              handleDayTemplateDrawer={handleDrawer}
              setSnackBarOpen={setSnackBarOpen}
              dayTemplate={selectedFormattedDayTemplate}
              originalDayTemplate={selectedDayTemplate}
            />
          ) : (
            <CircularProgress sx={loader} size={100} />
          )}
        </Box>
      </Drawer>
    </Authorize>
  );
}
