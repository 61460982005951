import React, { useState, useEffect } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { font14, font28, mb30, ml20 } from "../../../sharedStyles";
import ErasableTextField from "../../../ErasableTextField";
import {
  renderError,
  requiredMessage,
} from "../../../../utils/constants/forms";
import { selectLabel } from "./Guardian.styles";

export default function Options({ currentGuardian, languages }) {
  const {
    register,
    formState: { errors },
    setValue,
    watch,
    control,
  } = useFormContext();

  const getDefaultValue = (property) =>
    currentGuardian && currentGuardian[property];

  const [authorizedPickupChecked, setAuthorizedPickupChecked] = useState(
    getDefaultValue("authorized_pickup")
  );
  const [schoolTransportationChecked, setSchoolTransportationChecked] =
    useState(getDefaultValue("school_transportation"));

  const handleAuthorizedPickupChange = (event) => {
    setAuthorizedPickupChecked(event.target.checked);
    if (!event.target.checked) {
      setValue("authorized_pickup_vehicle_license", "");
    }
  };

  const handleSchoolTransportationChange = (event) => {
    setSchoolTransportationChecked(event.target.checked);
    if (!event.target.checked) {
      setValue("school_transportation_vehicle_license", "");
    }
  };

  const [isEmergencyContact, setIsEmergencyContact] = useState(
    getDefaultValue("is_emergency_contact")
  );

  const watchEmergencyContact = watch(
    "is_emergency_contact",
    isEmergencyContact
  );

  useEffect(() => {
    setIsEmergencyContact(watchEmergencyContact);
  }, [watchEmergencyContact]);

  function getDefaultContactType() {
    if (!currentGuardian) return "";

    if (currentGuardian.is_primary_contact) {
      return "is_primary_contact";
    }

    if (currentGuardian.is_custodial_parent) {
      return "is_custodial_parent";
    }

    return "";
  }

  function getDefaultEmergencyContactType() {
    if (!currentGuardian) return "";

    if (currentGuardian.is_primary_emergency_contact) {
      return "is_primary_emergency_contact";
    }

    if (currentGuardian.is_emergency_contact) {
      return "is_emergency_contact";
    }

    return "";
  }

  return (
    <>
      <Grid item sm={12} sx={mb30}>
        <Typography sx={font28}>Options</Typography>
      </Grid>
      <Grid item sm={10}>
        <FormControl fullWidth sx={{ ml: "16px" }}>
          <InputLabel id="preferred_language" shrink sx={selectLabel}>
            Preferred Language
          </InputLabel>
          <Select
            labelId="preferred_language"
            id="preferred_language"
            label="Preferred Language"
            defaultValue={getDefaultValue("preferred_language") || "English"}
            {...register("preferred_language", {
              required: requiredMessage,
            })}
          >
            {languages.map((language) => (
              <MenuItem key={language.id} value={language.name}>
                {language.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item sm={12}>
        <FormGroup
          sx={{
            ...ml20,
            borderBottom: "2px solid black",
            paddingY: "1rem",
            marginRight: "2rem",
          }}
        >
          <Controller
            name="contactType"
            control={control}
            defaultValue={getDefaultContactType()}
            render={({ field }) => (
              <RadioGroup {...field}>
                <FormControlLabel
                  value="is_custodial_parent"
                  control={<Radio />}
                  label={
                    <Typography sx={{ fontSize: 14 }}>
                      Custodial Contact
                    </Typography>
                  }
                />
                <FormControlLabel
                  value="is_primary_contact"
                  control={<Radio />}
                  label={
                    <Typography sx={{ fontSize: 14 }}>
                      Primary Custodial Contact
                    </Typography>
                  }
                />
              </RadioGroup>
            )}
          />
        </FormGroup>
      </Grid>
      <Grid item sm={12}>
        <FormGroup
          sx={{
            ...ml20,
            borderBottom: "2px solid black",
            paddingY: "1rem",
            marginRight: "2rem",
          }}
        >
          <Controller
            name="emergencyContactType"
            control={control}
            defaultValue={getDefaultEmergencyContactType()}
            render={({ field }) => (
              <RadioGroup {...field}>
                <FormControlLabel
                  value="is_emergency_contact"
                  control={<Radio />}
                  label={
                    <Typography sx={{ fontSize: 14 }}>
                      Emergency Contact
                    </Typography>
                  }
                />
                <FormControlLabel
                  value="is_primary_emergency_contact"
                  control={<Radio />}
                  label={
                    <Typography sx={{ fontSize: 14 }}>
                      Primary Emergency Contact
                    </Typography>
                  }
                />
              </RadioGroup>
            )}
          />
        </FormGroup>
      </Grid>
      <Grid item sm={12} sx={{ paddingY: "1rem" }}>
        <FormGroup sx={ml20}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={getDefaultValue("is_approved_volunteer")}
                {...register("is_approved_volunteer", {})}
              />
            }
            label={<Typography sx={font14}>Approved Volunteer</Typography>}
          />
        </FormGroup>
      </Grid>
      <Grid item sm={12}>
        <FormGroup sx={ml20}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={getDefaultValue("authorized_pickup")}
                {...register("authorized_pickup", {
                  onChange: (e) => handleAuthorizedPickupChange(e),
                })}
              />
            }
            label={<Typography sx={font14}>Authorized Pickup</Typography>}
          />
          <Box>
            <ErasableTextField
              id="authorized_pickup_vehicle_license"
              disabled={!authorizedPickupChecked}
              label="Vehicle License"
              fullWidth
              defaultValue={currentGuardian?.authorized_pickup_vehicle_license}
              hasError={errors?.authorized_pickup_vehicle_license}
              inputSx={{
                maxWidth: "200px",
                marginLeft: "50px",
              }}
            />

            {errors?.authorized_pickup_vehicle_license &&
              renderError(errors?.authorized_pickup_vehicle_license.message)}
          </Box>
        </FormGroup>
      </Grid>
      <Grid item sm={12}>
        <FormGroup sx={ml20}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={getDefaultValue("school_transportation")}
                {...register("school_transportation", {
                  onChange: (e) => handleSchoolTransportationChange(e),
                })}
              />
            }
            label={<Typography sx={font14}>School Transportation</Typography>}
          />
          <ErasableTextField
            id="school_transportation_vehicle_license"
            disabled={!schoolTransportationChecked}
            label="Vehicle License"
            fullWidth
            hasError={errors?.school_transportation_vehicle_license}
            defaultValue={currentGuardian.school_transportation_vehicle_license}
            inputSx={{
              maxWidth: "200px",
              marginLeft: "50px",
            }}
          />
        </FormGroup>
      </Grid>
    </>
  );
}
