const sentRowDescription = {
  mx: "25px",
  fontSize: "14px",
  textOverflow: "ellipsis",
  maxWidth: "850px",
  whiteSpace: "nowrap",
  overflow: "hidden",
};

const sentMessagesAttachmentContainer = {
  ml: "20px",
  width: "30px",
};

export { sentRowDescription, sentMessagesAttachmentContainer };
