import { tableCellClasses, tableRowClasses } from "@mui/material";

const tableStyles = {
  minWidth: 650,
  [`& .${tableCellClasses.root}`]: {
    borderBottom: "none",
  },
  [`& .${tableRowClasses.root}:not(:last-child)`]: {
    borderBottom: "1px solid #d9d9d9",
  },
  "& .MuiTableCell-head": {
    color: "#8f8e8e",
    fontWeight: "500 !important",
    textTransform: "uppercase",
  },
  "& .MuiTableCell-body": {
    color: "black",
    fontSize: 16,
  },
};
const primaryIcon = {
  ml: "25px",
  fontSize: "20px",
};
const guardianDrawer = {
  "& .MuiPaper-root": {
    backgroundColor: "white",
    width: "800px",
  },
};
export { tableStyles, primaryIcon, guardianDrawer };
