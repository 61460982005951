import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { tableStyles, monthText } from "./Birthdays.style";
import { calculateAge } from "../../../utils/constants/utils";

export default function StudentsTable({ monthName, students }) {
  function formatDate(dateString) {
    const parts = dateString.split("T")[0].split("-");
    const year = Number(parts[0]);
    const month = Number(parts[1]) - 1;
    const day = Number(parts[2]);

    // Check if year is less than 100 (non-standard format)
    if (year < 100) {
      // Adjust the year to the correct format (e.g., "0016" to "2016")
      const currentYear = new Date().getFullYear();
      const adjustedYear = currentYear - (currentYear % 100) + year;

      // Create a new Date object with the adjusted year
      const adjustedDate = new Date(adjustedYear, month, day);

      // Format the adjusted date
      return adjustedDate.toLocaleString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    }

    return new Date(year, month, day).toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  }

  return (
    <TableContainer>
      <Table sx={tableStyles} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">
              <Typography sx={monthText}>{monthName}</Typography>
            </TableCell>
            <TableCell align="center">GRADE</TableCell>
            <TableCell align="center">GENDER</TableCell>
            <TableCell align="left">BIRTHDAY</TableCell>
            <TableCell align="left">AGE</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {students.map((student) => (
            <TableRow key={student.id} hover>
              <TableCell align="left" scope="student">
                <Typography
                  fontSize={13}
                >{`${student.last_name}, ${student.first_name}`}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography fontSize={13}>{student.grade_level}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography fontSize={13}>
                  {student.gender && (student.gender === "Male" ? "M" : "F")}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography fontSize={13}>
                  {formatDate(student.date_of_birth)}
                </Typography>
              </TableCell>
              <TableCell align="center">
                {calculateAge(formatDate(student.date_of_birth))}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
