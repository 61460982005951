const rowTitle = {
  fontSize: "20px",
  fontWeight: "500",
};

const rowContainer = (isActive) => ({
  backgroundColor: isActive ? "#2196F3" : "white",
  py: "15px",
  px: "10px",
  color: isActive ? "white" : "black",
  cursor: "pointer",
});

export { rowTitle, rowContainer };
