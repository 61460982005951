import { Box, Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useOutletContext, useParams, useSearchParams } from "react-router-dom";
import reportsService from "../../../service/reportsService";
import GradeStudents from "./GradeStudents";
import PrintLayout from "../PrintLayoutComponents/PrintLayout";
import { convertToOrdinal } from "../../../utils/constants/utils";

export default function Enrollment() {
  const [sortedGrades, setSortedGrades] = useState([]);
  const params = useParams();
  const [searchParams] = useSearchParams();
  const [schoolName] = useOutletContext();
  const [gradeGroups, setGradeGroups] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const termId = searchParams.get("term");
    if (termId)
      (async () => {
        setLoading(true);
        reportsService
          .enrollments({
            school_id: params.school_id,
            term_id: termId,
          })
          .then((response) => {
            setGradeGroups(response);
            const sortedKeys = Object.keys(response).sort((a, b) => {
              // If both keys are numbers, compare numerically
              if (!Number.isNaN(a) && !Number.isNaN(b)) {
                return Number(a) - Number(b);
              }
              // If only one key is a number, prioritize alphabetical order
              if (!Number.isNaN(a)) {
                return 1;
              }
              if (!Number.isNaN(b)) {
                return -1;
              }
              // Both keys are non-numeric, compare alphabetically

              return a.localeCompare(b);
            });

            setSortedGrades(sortedKeys);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      })();
  }, [searchParams.get("term")]);

  const genderCount = (gradeGroup, gender) =>
    gradeGroup.filter((student) => student.gender === gender).length;

  const generateBodyContent = () => {
    if (loading) {
      return (
        <Box display="flex" justifyContent="center">
          <Typography color="black" fontSize="20px" mt={5}>
            Loading...
          </Typography>
        </Box>
      );
    }

    if (sortedGrades.length === 0) {
      return (
        <tr>
          <td>
            <Box textAlign="center" mt={3}>
              No enrollments found.
            </Box>
          </td>
        </tr>
      );
    }

    return sortedGrades.map((gradeGroup, index) => (
      <tr className="report-data" key={gradeGroup}>
        <td
          className="report-content-cell"
          style={{ padding: "2rem 1rem 0 2rem" }}
        >
          <div>
            <Grid pt={3}>
              <Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  borderBottom="1px solid #8F8F8F"
                >
                  <Grid item>
                    <Typography fontWeight={400} fontSize="24px" color="black">
                      {convertToOrdinal(gradeGroup)}
                    </Typography>
                  </Grid>
                  <Grid item display="flex" alignItems="center" mr={5}>
                    <Typography fontWeight={400} fontSize="12px" color="black">
                      Total: {gradeGroups[gradeGroup].length}
                    </Typography>
                    <Typography px={2}>|</Typography>
                    <Typography fontWeight={400} fontSize="12px" color="black">
                      Male: {genderCount(gradeGroups[gradeGroup], "Male")}{" "}
                      &nbsp; &nbsp; Female:{" "}
                      {genderCount(gradeGroups[gradeGroup], "Female")}
                    </Typography>
                  </Grid>
                </Grid>
                <GradeStudents students={gradeGroups[gradeGroup]} />
              </Grid>
              {index < sortedGrades.length - 1 && (
                <div style={{ pageBreakBefore: "always" }} />
              )}
            </Grid>
          </div>
        </td>
      </tr>
    ));
  };
  return (
    <PrintLayout
      headerContent="Enrollment"
      bodyContent={generateBodyContent()}
      footerContent={schoolName}
    />
  );
}
