import React, { useState, useEffect } from "react";
import { Tab, Tabs } from "@mui/material";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { pageTabs } from "../sharedStyles";

export default function StaffTabs({ setTabChanged }) {
  const params = useParams();
  const schoolId = params.school_id;

  const tabs = [
    {
      index: 0,
      label: "Staff",
      url: `/school/${schoolId}/staff/staffs`,
    },
    {
      index: 1,
      label: "Temporary Staff",
      url: `/school/${schoolId}/staff/temporary-staff`,
    },
  ];

  const [tabValue, setTabValue] = useState(tabs[0]);
  const location = useLocation();
  const navigate = useNavigate();

  const handleTabChange = (value, path) => {
    setTabValue(tabs[value]);
    setTabChanged(true);
    navigate(path);
  };

  useEffect(() => {
    const getTabFromUrl = tabs.find((tab) =>
      location.pathname.includes(tab.url.split("?")[0])
    );
    setTabValue(getTabFromUrl || tabs[0]);
  }, []);

  return (
    <Tabs value={tabValue?.index} sx={pageTabs}>
      {tabs?.map((tab) => (
        <Tab
          key={tab.index}
          label={tab.label}
          onClick={() => handleTabChange(tab.index, tab.url)}
        />
      ))}
    </Tabs>
  );
}
