import React, { useState, useEffect } from "react";
import { Tab, Tabs } from "@mui/material";
import {
  useNavigate,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { tabsStyle } from "./FieldSet.style";

export default function FieldSetTabs() {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const schoolId = params.school_id;

  const formattedParams = () => {
    let paramsUrl = "?";
    const schoolYearId = searchParams.get("school_year");
    const formId = searchParams.get("form_id");
    if (schoolYearId) {
      paramsUrl = paramsUrl.concat(`&school_year=${schoolYearId}`);
    }
    if (formId) {
      paramsUrl = paramsUrl.concat(`&form_id=${formId}`);
    }
    return paramsUrl;
  };

  const tabs = [
    {
      index: 0,
      label: "Fields",
      url: `/school/${schoolId}/admissions/setup/form-builder/field-types${formattedParams()}`,
    },
    {
      index: 1,
      label: "Reusable Fields",
      url: `/school/${schoolId}/admissions/setup/form-builder/data${formattedParams()}`,
    },
    {
      index: 2,
      label: "Layout",
      url: `/school/${schoolId}/admissions/setup/form-builder/layout${formattedParams()}`,
    },
  ];

  const [tabIndex, setTabIndex] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  const handleTabChange = (value, path) => {
    setTabIndex(value);
    navigate(path);
  };

  useEffect(() => {
    const getTabFromUrl = tabs.find((tab) =>
      location.pathname.includes(tab.url.split("?")[0])
    )?.index;
    if (getTabFromUrl) setTabIndex(getTabFromUrl);
    else navigate(tabs[0].url);
  }, []);

  return (
    <Tabs value={tabIndex} sx={tabsStyle}>
      {tabs?.map((tab) => (
        <Tab
          key={tab.index}
          value={tab.index}
          label={tab.label}
          onClick={() => handleTabChange(tab.index, tab.url)}
          sx={{ fontSize: "14px !important" }}
        />
      ))}
    </Tabs>
  );
}
