import axios from "../utils/axios-util";

const socialDevelopmentScoreServiceEndpoint = "/api/social_development_scores";

const fetchAll = async (params) => {
  const { data } = await axios.get(
    socialDevelopmentScoreServiceEndpoint,
    params
  );
  if (!data.data) {
    console.error("Error fetching social development scores from API");
    return [];
  }
  return data;
};

const create = async (params) => {
  const { data } = await axios.post(
    socialDevelopmentScoreServiceEndpoint,
    params
  );
  if (!data.data) {
    console.error("Error saving social development score");
    return [];
  }
  return data;
};

const createMultiple = async (params) => {
  const { data } = await axios.post(
    `${socialDevelopmentScoreServiceEndpoint}/create_multiple`,
    params
  );
  if (!data.success) {
    console.error("Error saving social development score");
    return [];
  }
  return data;
};

const postSkills = async (params) => {
  const { data } = await axios.post(
    `${socialDevelopmentScoreServiceEndpoint}/post`,
    params
  );
  if (!data.data) {
    console.error("Error posting social development");
    return [];
  }
  return data;
};

export default { fetchAll, create, postSkills, createMultiple };
