import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  studentListTable,
  studentListTableCell,
  studentListTableHeader,
} from "./StudentListTable.styles";

export default function StudentListTable({ students }) {
  return (
    <TableContainer>
      <Table sx={studentListTable} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={studentListTableHeader} align="left">
              LAST NAME
            </TableCell>
            <TableCell sx={studentListTableHeader} align="left">
              FIRST NAME
            </TableCell>
            <TableCell sx={studentListTableHeader} align="left">
              ID
            </TableCell>
            <TableCell sx={studentListTableHeader} align="left">
              EMAIL
            </TableCell>
            <TableCell sx={studentListTableHeader} align="left">
              GRADE LEVEL
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {students.map((student) => (
            <TableRow key={student.id} hover>
              <TableCell sx={studentListTableCell} align="left" scope="student">
                <Typography fontSize={13}>{student.last_name}</Typography>
              </TableCell>
              <TableCell sx={studentListTableCell} align="left">
                <Typography fontSize={13}>{student.first_name}</Typography>
              </TableCell>
              <TableCell sx={studentListTableCell} align="left">
                <Typography fontSize={13}>{student.ae_id}</Typography>
              </TableCell>
              <TableCell sx={studentListTableCell} align="left">
                <Typography fontSize={13}>{student.primary_email}</Typography>
              </TableCell>
              <TableCell sx={studentListTableCell} align="left">
                {student.grade_level}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
