import { Box, TextField } from "@mui/material";
import React from "react";

export default function EmailAddress({ field }) {
  return (
    <Box>
      <TextField
        fullWidth
        type="email-address"
        label={field.label}
        key={field.fieldId}
        helperText={field.properties.supportingText}
        required={field.properties.required}
        disabled
      />
    </Box>
  );
}
