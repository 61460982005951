import React from "react";
import { useFormContext } from "react-hook-form";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  courseFormDivider,
  courseFormDuration,
  courseFormRow,
  reportCardInput,
} from "./CourseForm.styles";
import { bgWhite, formTitle, mr10, mr30, mt25, mx10 } from "../../sharedStyles";

export default function ReportCard({ activeCourse }) {
  const { register } = useFormContext();

  return (
    <Box sx={courseFormDivider}>
      <Typography sx={formTitle}>Report Card</Typography>
      <Stack direction="row" sx={[mt25, courseFormRow]}>
        <Stack direction="row" sx={mr30} alignItems="center">
          <Typography sx={mr10}>Weight</Typography>
          <FormControl sx={reportCardInput}>
            <InputLabel id="term" shrink sx={bgWhite}>
              TERM
            </InputLabel>
            <TextField
              size="small"
              {...register("rc_term")}
              defaultValue={activeCourse?.rc_term}
            />
          </FormControl>
          <FormControl sx={[reportCardInput, mx10]}>
            <InputLabel id="semester" shrink sx={bgWhite}>
              SEMESTER
            </InputLabel>
            <TextField
              size="small"
              {...register("rc_semester")}
              defaultValue={activeCourse?.rc_semester}
            />
          </FormControl>
          <FormControl sx={reportCardInput}>
            <InputLabel id="final" shrink sx={bgWhite}>
              FINAL
            </InputLabel>
            <TextField
              size="small"
              {...register("rc_final")}
              defaultValue={activeCourse?.rc_final}
            />
          </FormControl>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Typography sx={mr10}>Duration</Typography>
          <FormControl fullWidth sx={courseFormDuration}>
            <Select
              labelId="duration"
              id="duration"
              defaultValue={activeCourse?.rc_duration || ""}
              {...register("rc_duration")}
            >
              <MenuItem value="quarter">Quarter</MenuItem>
              <MenuItem value="semester">Semester</MenuItem>
              <MenuItem value="all_year">All Year</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </Stack>
      <FormGroup>
        <FormControlLabel
          required
          control={
            <Checkbox
              {...register("rc_include")}
              defaultChecked={activeCourse?.rc_include}
            />
          }
          label="Include on report card"
        />
        <FormControlLabel
          required
          control={
            <Checkbox
              {...register("rc_calculate_gpa")}
              defaultChecked={activeCourse?.rc_calculate_gpa}
            />
          }
          label="Calculate GPA on report card"
        />
      </FormGroup>
    </Box>
  );
}
