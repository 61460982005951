import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Stack,
  CircularProgress,
  FormControl,
  Checkbox,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { font11, font28, mb15, my30, loader } from "../../sharedStyles";
import {
  collectionLabelContainer,
  departmentInputField,
  collectionNameLabel,
  collectionItemsContainer,
  collectionSubmitBtn,
  conllectionBtnContainer,
} from "./Departments.style";
import SnackBarNotification from "../../SnackBarNotification";
import { renderError, requiredMessage } from "../../../utils/constants/forms";
import departmentsService from "../../../service/departmentsService";

export default function Departments({ managePermission }) {
  const [loading, setLoading] = useState(false);

  const [snackBarOpen, setSnackBarOpen] = useState({
    open: false,
    message: null,
  });

  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "departments",
  });

  const getDepartments = async () => {
    setLoading(true);
    const response = await departmentsService.fetchAll();

    if (response.data) {
      if (response.data.length > 0) {
        response.data.forEach((item, index) => {
          append({
            itemId: item.id,
            name: item.name,
            isDeleted: false,
            active: item.active,
          });

          setValue(`departments.${index}.name`, item.name);
          setValue(`departments.${index}.active`, item.active);
        });
      } else {
        append({ value: "", isDeleted: false });
      }

      setLoading(false);
    }
  };

  const onSubmit = async (data) => {
    const departmentsToDelete = data.departments.filter(
      (department) => department.isDeleted === true
    );
    const departmentsToUpdate = data.departments.filter(
      (department) => department.isDeleted === false && department.itemId
    );
    const departmentsToCreate = data.departments.filter(
      (department) => department.isDeleted === false && !department.itemId
    );

    const requestsList = [];

    if (departmentsToDelete.length > 0) {
      requestsList.push(
        departmentsService.bulkDelete({
          params: {
            departments_ids: departmentsToDelete.map((m) => m.itemId),
          },
        })
      );
    }

    if (departmentsToUpdate.length > 0) {
      requestsList.push(
        departmentsService.bulkUpdate({
          departments: departmentsToUpdate,
        })
      );
    }

    if (departmentsToCreate.length > 0) {
      requestsList.push(
        departmentsService.bulkCreate({
          departments: departmentsToCreate,
        })
      );
    }

    Promise.all(requestsList)
      .then(() => {
        setSnackBarOpen({
          open: true,
          message: "Departments updated.",
        });

        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
        setSnackBarOpen({
          open: true,
          message: error.response?.data?.data?.message || "Error updating.",
          severity: "error",
        });
      });
  };

  const handleDelete = async (index, id) => {
    if (id) {
      setValue(`departments.${index}.isDeleted`, true);
    } else {
      remove(index);
    }
  };

  const handleUndoDelete = (index) => {
    setValue(`departments.${index}.isDeleted`, false);
  };

  const requiredValidation = {
    value: true,
    message: requiredMessage,
  };

  useEffect(() => {
    getDepartments();
  }, []);

  if (loading) {
    return <CircularProgress sx={loader} size={100} />;
  }

  return (
    <Box sx={my30}>
      <Typography sx={font28}>Departments</Typography>
      <Stack direction="row" sx={collectionLabelContainer}>
        <Typography sx={collectionNameLabel}>DEPARTMENT NAME</Typography>
        <Typography sx={[font11, collectionItemsContainer]}>ACTIVE</Typography>
      </Stack>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          {fields.map((field, index) => (
            <div key={field.id}>
              <Stack direction="row" alignItems="center" sx={mb15}>
                <TextField
                  error={errors?.departments?.[index]}
                  sx={departmentInputField}
                  disabled={!managePermission}
                  label="Name"
                  {...register(`departments.${index}.name`, {
                    required: requiredValidation,
                  })}
                />
                <FormControl sx={{ ml: "29px" }}>
                  <Controller
                    name={`departments.${index}.active`}
                    defaultValue=""
                    control={control}
                    render={({ field: renderField }) => (
                      <Checkbox
                        {...renderField}
                        checked={!!renderField.value}
                        onChange={(e) => renderField.onChange(e.target.checked)}
                      />
                    )}
                  />
                </FormControl>
                {managePermission && (
                  <Box>
                    {!watch(`departments.${index}.isDeleted`) ? (
                      <Typography
                        sx={{ cursor: "pointer", mt: "5px" }}
                        onClick={() => handleDelete(index, field.itemId)}
                      >
                        <CloseIcon />
                      </Typography>
                    ) : (
                      <Typography
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleUndoDelete(index)}
                      >
                        Undo
                      </Typography>
                    )}
                  </Box>
                )}
              </Stack>

              {errors?.departments?.[index] &&
                renderError(errors?.departments?.[index]?.value?.message)}
            </div>
          ))}
        </Box>
        {managePermission && (
          <Stack
            sx={conllectionBtnContainer}
            direction="row"
            justifyContent="space-between"
          >
            <Button
              sx={collectionSubmitBtn}
              startIcon={<AddIcon />}
              variant="text"
              component="label"
              onClick={() =>
                append({ value: "", isDeleted: false, active: true })
              }
            >
              Add New Department
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
          </Stack>
        )}
      </form>

      <SnackBarNotification
        message={snackBarOpen.message}
        open={snackBarOpen.open}
        severity={snackBarOpen.severity}
        handleClose={() => setSnackBarOpen(false)}
      />
    </Box>
  );
}
