import axios from "../utils/axios-util";

const portfolioFilesEndpoint = "/api/portfolio_files";

const fetchAll = async (params) => {
  const { data } = await axios.get(portfolioFilesEndpoint, params);

  if (!data.data) {
    console.error("Error fetching portfolio files from API");
    return [];
  }
  return data;
};

const fetchAttachments = async (fileId) => {
  const { data } = await axios.get(
    `${portfolioFilesEndpoint}/${fileId}/attachments`
  );

  if (!data.data) {
    console.error("Error fetching portfolio files from API");
    return [];
  }
  return data;
};

const create = async (params) => {
  const { data } = await axios.post(portfolioFilesEndpoint, params);
  if (!data.data) {
    console.error("Error creating portfolio files");
    return [];
  }
  return data;
};

const update = async (fileId, params) => {
  const { data } = await axios.put(
    `${portfolioFilesEndpoint}/${fileId}`,
    params
  );

  if (!data.data) {
    console.error("Error updating portfolio files");
    return [];
  }
  return data;
};

const deleteFile = (fileId) =>
  axios.delete(`${portfolioFilesEndpoint}/${fileId}`);

export default { fetchAll, create, update, fetchAttachments, deleteFile };
