import FieldBase from "./FieldBase";

export default class LongText extends FieldBase {
  constructor(field) {
    super(field);
    this.label = field.label;
    this.dataType = "value_text";

    this.propertyComponents = [
      "name",
      "label",
      "supporting-text",
      "min-length",
      "max-length",
      "required",
    ];
    this.properties.maxLength = field.properties?.maxLength || "";
    this.properties.minLength = field.properties?.minLength || "";
  }
}
