import DeleteIcon from "@mui/icons-material/Delete";
import { Controller, useFormContext } from "react-hook-form";
import {
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { bgWhite } from "../sharedStyles";
import { requiredMessage, renderError } from "../../utils/constants/forms";
import moment from "../../utils/constants/momentConfig";
import dateTimeFormats from "../../utils/constants/dateTimeFormats";

export default function KlassScheduleDetail({
  index,
  dayTemplates,
  field,
  remove,
  errors,
  getValues,
  setValue,
  activeKlass,
  classSchedule,
  setSelectedPeriods,
}) {
  const { control, watch } = useFormContext({});
  const [tracks, setTracks] = useState([]);
  const [periods, setPeriods] = useState([]);

  const removeButtonDisabled = () =>
    (classSchedule?.is_published && !!field?.created_at) ||
    watch(`klass_schedule_details[${index}].is_deleted`);

  const getTracksFromSelectedDayTemplate = (dayTemplate) => {
    const result = [];

    const trackNames = [];
    dayTemplate.periods.forEach((period) => {
      if (!trackNames.includes(period.track)) {
        trackNames.push(period.track);
      }
    });

    trackNames.forEach((trackName) => {
      const track = { id: trackName + dayTemplate.name, name: trackName };
      result.push(track);
    });

    return result;
  };

  const handleTrackChange = (e) => {
    setPeriods(() =>
      dayTemplates
        .find(
          ({ id }) =>
            id === getValues(`klass_schedule_details[${index}].day_template`)
        )
        .periods.filter(({ track }) => track === e.target.value)
    );
    setValue(`klass_schedule_details[${index}].period`, "");
  };

  const handleDayTemplateChange = (e) => {
    setValue(`klass_schedule_details[${index}].period`, "");
    setValue(`klass_schedule_details[${index}].track`, "");
    setPeriods([]);
    setTracks(
      getTracksFromSelectedDayTemplate(
        dayTemplates.find((dt) => dt.id === e.target.value)
      )
    );
  };

  const disabledButton = (periodId) => {
    const ksds = getValues(`klass_schedule_details`);
    return ksds.some((ksd) => ksd.period === periodId);
  };

  useEffect(() => {
    if (activeKlass && dayTemplates.length && field?.day_template_id) {
      setTracks(
        getTracksFromSelectedDayTemplate(
          dayTemplates.find((dt) => dt.id === field?.day_template_id)
        )
      );
      setPeriods(() =>
        dayTemplates
          .find(({ id }) => id === field?.day_template_id)
          .periods.filter(({ track }) => track === field?.period?.track)
      );
      setValue(`klass_schedule_details[${index}].period`, field?.period_id);
    }
  }, []);

  return (
    <Grid container key={field.id} mb={2} spacing={2}>
      <Grid item xs={3}>
        <FormControl fullWidth error={errors?.dayTemplate && true}>
          <InputLabel id="dayTemplate" shrink sx={bgWhite}>
            Day Template*
          </InputLabel>
          <Controller
            name={`klass_schedule_details[${index}].day_template`}
            control={control}
            defaultValue={field?.day_template_id || ""}
            rules={{
              required: { value: true, message: requiredMessage },
            }}
            render={({ field: { onChange, value } }) => (
              <Select
                labelId={`klass_schedule_details[${index}].day_template`}
                id={`klass_schedule_details[${index}].day_template`}
                value={value}
                onChange={(e) => {
                  onChange(e);
                  handleDayTemplateChange(e);
                }}
              >
                {dayTemplates.map((dayTemplate) => (
                  <MenuItem key={dayTemplate.id} value={dayTemplate.id}>
                    {dayTemplate.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          {errors?.klass_schedule_details?.[index]?.day_template &&
            renderError(
              errors?.klass_schedule_details?.[index]?.day_template.message
            )}
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth error={errors?.tracks && true}>
          <InputLabel id="track" shrink sx={bgWhite}>
            Track*
          </InputLabel>
          {tracks.length > 0 && (
            <Controller
              name={`klass_schedule_details[${index}].track`}
              control={control}
              defaultValue={field?.period?.track || ""}
              rules={{
                required: { value: true, message: requiredMessage },
              }}
              render={({ field: { onChange, value } }) => (
                <Select
                  labelId={`klass_schedule_details[${index}].track`}
                  id={`klass_schedule_details[${index}].track`}
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    handleTrackChange(e);
                  }}
                >
                  {tracks.map((track) => (
                    <MenuItem key={track.id} value={track.name}>
                      {track.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          )}
          {errors?.klass_schedule_details?.[index]?.track &&
            renderError(errors?.klass_schedule_details?.[index]?.track.message)}
        </FormControl>
      </Grid>
      <Grid item xs={5}>
        <FormControl fullWidth error={errors?.period}>
          <InputLabel id="period" shrink sx={bgWhite}>
            Period*
          </InputLabel>
          {periods.length > 0 && (
            <Controller
              name={`klass_schedule_details[${index}].period`}
              control={control}
              defaultValue={field?.period.id || ""}
              rules={{
                required: { value: true, message: requiredMessage },
              }}
              render={({ field: { onChange, value } }) => (
                <Select
                  labelId={`klass_schedule_details[${index}].period`}
                  id={`klass_schedule_details[${index}].period`}
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    setSelectedPeriods((prev) => [...prev, e.target.value]);
                  }}
                >
                  {periods.map((period) => (
                    <MenuItem
                      key={period.id}
                      value={period.id}
                      disabled={disabledButton(period.id)}
                    >
                      {`${period.name} (${moment(period.start_time)
                        .utc()
                        .format(dateTimeFormats.TIME)} - ${moment(
                        period.end_time
                      )
                        .utc()
                        .format(dateTimeFormats.TIME)})`}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          )}
          {errors?.klass_schedule_details?.[index]?.period &&
            renderError(
              errors?.klass_schedule_details?.[index]?.period.message
            )}
        </FormControl>
      </Grid>
      <Grid item xs={1}>
        <Box>
          <IconButton
            size="large"
            disabled={removeButtonDisabled()}
            onClick={() => {
              if (getValues(`klass_schedule_details[${index}].id`)) {
                setValue(
                  `klass_schedule_details[${index}].is_deleted`,
                  !getValues(`klass_schedule_details[${index}].is_deleted`)
                );
              } else {
                remove(index);
              }
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  );
}
