import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSubmitting } from "../context/SubmittingContext";
import { formButtons } from "./sharedStyles";

function GenericSubmitButton({
  text,
  submittingText = "Submitting...",
  sx = formButtons,
  onClick = () => {},
  type = "submit",
}) {
  const { submitting } = useSubmitting();
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    if (!submitting) {
      setClicked(false);
    }
  }, [submitting]);

  return (
    <Button
      sx={sx}
      type={type}
      disabled={clicked && submitting}
      onClick={() => {
        setClicked(true);
        onClick();
      }}
    >
      {clicked && submitting ? submittingText : text}
    </Button>
  );
}

export default GenericSubmitButton;
