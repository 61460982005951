import React from "react";
import { InputAdornment, IconButton, TextField } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";

const CssSearchField = styled(TextField)({
  "& .MuiInput-root": {
    marginRight: "17px",
    "&:before": {
      borderBottom: "1px solid #22282C !important",
    },
  },
});

export default function Search() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = React.useState(searchParams.get("query") || "");
  const { register, handleSubmit, getValues, setValue } = useForm();
  const [showClearButton, setShowClearButton] = React.useState(
    searchParams.get("query") || false
  );

  const handleSearch = (data) => {
    searchParams.set("query", data.search);
    setQuery(data.search);
    setSearchParams(searchParams);
  };

  const handleQuickFindChange = (event) => {
    if (event.target.value) setShowClearButton(true);
    else setShowClearButton(false);
  };

  const handleClickOnClearButton = () => {
    setValue("search", "");
    setShowClearButton(false);
    handleSearch(getValues());
  };

  const cancelButtonStyle = {
    visibility: showClearButton ? "inherit" : "hidden",
  };

  return (
    <form
      onSubmit={handleSubmit(handleSearch)}
      onChange={handleQuickFindChange}
    >
      <CssSearchField
        label="Quick Search"
        variant="standard"
        color="primary"
        defaultValue={query}
        {...register("search")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={handleClickOnClearButton}
                sx={cancelButtonStyle}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
              <IconButton type="submit">
                <SearchIcon size="small" color="primary" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </form>
  );
}
