import { Box, Grid, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useDrag } from "react-dnd";
import { ArrowRightAlt, Segment } from "@mui/icons-material";
import { fieldStyle } from "../../FormBuilder.style";
import { FormBuilderContext } from "../../FormBuilderContext";

export default function Layout() {
  const formBuilderContext = useContext(FormBuilderContext);

  const [, dragSectionElement] = useDrag({
    type: "section-type",
    item: {
      name: "Untitled Section",
      fieldComponents: [],
    },
  });

  const [, dragPageBreakElement] = useDrag({
    type: "section-type",
    item: {
      editable: false,
      formBuilderOnly: true,
      name: "Page Break",
      fieldComponents: [
        {
          type: "page-break",
          removable: false,
          editable: false,
          properties: {
            fieldWidth: 12,
          },
        },
      ],
    },
  });

  return (
    <Box width="100%">
      <Typography variant="body1">Layout Elements</Typography>
      <Box style={{ marginLeft: "10px" }}>
        <Grid
          container
          draggable
          unselectable="on"
          onDragStart={(e) => {
            formBuilderContext.fireOnDragStart({
              type: "form_field",
              w: 12,
              h: 3,
            });
            e.dataTransfer.setData(
              "text/plain",
              JSON.stringify({
                type: "description-area",
                label: "Description Area",
              })
            );
          }}
          onDragEnd={() => {
            formBuilderContext.fireOnDragStop();
          }}
          sx={fieldStyle}
        >
          <Grid item>
            <Typography variant="body2">Description Area</Typography>
          </Grid>
          <Grid item>
            <ArrowRightAlt fontSize="small" />
            <Segment fontSize="small" />
          </Grid>
        </Grid>
        <Grid
          container
          draggable
          unselectable="on"
          onDragStart={(e) => {
            formBuilderContext.fireOnDragStart({
              type: "form_field",
              w: 12,
              h: 1,
            });
            e.dataTransfer.setData(
              "text/plain",
              JSON.stringify({
                type: "horizontal-divider",
              })
            );
          }}
          onDragEnd={() => {
            formBuilderContext.fireOnDragStop();
          }}
          sx={fieldStyle}
        >
          <Grid item>
            <Typography variant="body2">Line</Typography>
          </Grid>
          <Grid item>
            <ArrowRightAlt fontSize="small" />
            <Segment fontSize="small" />
          </Grid>
        </Grid>
        <Grid
          container
          onDragStart={() =>
            formBuilderContext.fireOnDragStart({ type: "section" })
          }
          onDragEnd={() => {
            formBuilderContext.fireOnDragStop();
          }}
          ref={dragSectionElement}
          sx={fieldStyle}
        >
          <Grid item>
            <Typography variant="body2">Section</Typography>
          </Grid>
          <Grid item>
            <ArrowRightAlt fontSize="small" />
            <Segment fontSize="small" />
          </Grid>
        </Grid>
        <Grid
          container
          onDragStart={() =>
            formBuilderContext.fireOnDragStart({ type: "section" })
          }
          onDragEnd={() => {
            formBuilderContext.fireOnDragStop();
          }}
          ref={dragPageBreakElement}
          sx={fieldStyle}
        >
          <Grid item>
            <Typography variant="body2">Page Break</Typography>
          </Grid>
          <Grid item>
            <ArrowRightAlt fontSize="small" />
            <Segment fontSize="small" />
          </Grid>
        </Grid>
        <Grid
          container
          draggable
          unselectable="on"
          onDragStart={(e) => {
            formBuilderContext.fireOnDragStart({
              type: "form_field",
              w: 12,
              h: 1,
            });
            e.dataTransfer.setData(
              "text/plain",
              JSON.stringify({
                type: "sub-heading",
                label: "Heading",
              })
            );
          }}
          onDragEnd={() => {
            formBuilderContext.fireOnDragStop();
          }}
          sx={fieldStyle}
        >
          <Grid item>
            <Typography variant="body2">Heading</Typography>
          </Grid>
          <Grid item>
            <ArrowRightAlt fontSize="small" />
            <Segment fontSize="small" />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
