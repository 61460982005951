import React from "react";
import { Stack, Grid, Button } from "@mui/material";
import { osnOptions } from "../../utils/constants/toolTipOptions";

export default function renderSocialDevelopmentOptions(
  gradingScale,
  formattedMarkingCodes,
  handleClick
) {
  return (
    <Grid
      container
      item
      xs={12}
      align="center"
      mb={2}
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      <Grid
        container
        item
        xs={12}
        justifyContent="center"
        alignItems="center"
        mt={2}
      >
        {gradingScale.name !== "TK-K" ? (
          <Grid
            container
            item
            xs={12}
            align="center"
            mb={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              mt={2}
            >
              {osnOptions.map((option) => (
                <Stack
                  align="center"
                  justifyContent="center"
                  alignItems="center"
                  key={option.button}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      maxWidth: "50px",
                      maxHeight: "40px",
                      minWidth: "50px",
                      minHeight: "40px",
                      mx: "5px",
                    }}
                    onClick={() => handleClick(option.button)}
                  >
                    {option.button}
                  </Button>
                </Stack>
              ))}
            </Grid>
          </Grid>
        ) : (
          <>
            {formattedMarkingCodes.map((row, index) => (
              <Grid
                container
                item
                xs={12}
                align="center"
                mt={1}
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                key={row[index]?.code}
              >
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  {row?.map((option) => (
                    <Button
                      key={option?.code}
                      variant="outlined"
                      sx={{
                        maxWidth: "50px",
                        maxHeight: "40px",
                        minWidth: "50px",
                        minHeight: "40px",
                      }}
                      disabled={option.code === ""}
                      onClick={() => handleClick(option?.code)}
                    >
                      {option?.code}
                    </Button>
                  ))}
                </Stack>
              </Grid>
            ))}
            <Grid item xs={12} align="center" mt={2} />
          </>
        )}
      </Grid>
    </Grid>
  );
}
