const filterByKlassBtn = {
  color: "#5077A4",
  backgroundColor: "#F8F7F5",
  py: "7px",
  borderRadius: "16px",
  lineHeight: "16px",
  px: "15px",
  textTransform: "capitalize",
  fontSize: "12px",
  minWidth: "80px",
  "&:not(:last-of-type)": {
    mr: "10px",
  },
};

const filterByKlassIconDisabled = {
  color: "#D9D9D9",
};

const filterByKlassBtnActive = {
  ...filterByKlassBtn,
  backgroundColor: "#678FBE !important",
  color: "#ffffff !important",
};

export { filterByKlassBtn, filterByKlassIconDisabled, filterByKlassBtnActive };
