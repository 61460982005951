import React, { useState, useEffect } from "react";
import {
  Stack,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import ClassSchedulesDaySelect from "./ClassSchedulesDaySelect";
import ClassSchedulesSelect from "./ClassSchedulesSelect";
import {
  classSchedulesFilterContainer,
  dayTemplateWidth,
  select,
} from "./ClassSchedulesDayView.styles";
import ClassSchedulesRow from "./ClassSchedulesRow";
import { mb55, bgWhite } from "../../../sharedStyles";

export default function ClassSchedulesDayView({
  term,
  periods,
  dayTemplate,
  searchParams,
  setSearchParams,
  classes,
  selectedClass,
  setSelectedClass,
  teachers,
  selectedTeacher,
  setSelectedTeacher,
  students,
  selectedStudent,
  setSelectedStudent,
  selectedTrack,
  setSelectedTrack,
  fetchFilters,
}) {
  const defaultActiveFilter = () => {
    if (selectedTeacher !== "") {
      return "Teacher";
    }
    return "Class";
  };
  const [activeFilter, setActiveFilter] = useState(defaultActiveFilter());
  const [trackValue, setTrackValue] = useState(dayTemplate?.tracks?.[0] || "");
  const handleChange = (e) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set("track", e.target.value);
    setSelectedTrack(e.target.value);
    setSearchParams(updatedSearchParams);
  };

  useEffect(() => {
    fetchFilters();
    setTrackValue(
      dayTemplate?.tracks?.includes(selectedTrack)
        ? selectedTrack
        : dayTemplate?.tracks?.[0] || ""
    );
  }, [dayTemplate]);
  return (
    <Box sx={mb55}>
      <Stack direction="row" sx={classSchedulesFilterContainer}>
        <Stack direction="column" alignItems="baseline" sx={dayTemplateWidth}>
          <Stack direction="row" alignItems="baseline" sx={dayTemplateWidth}>
            <ClassSchedulesDaySelect
              term={term}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              dayTemplate={dayTemplate}
              setSelectedTrack={setSelectedTrack}
              setTrackValue={setTrackValue}
            />
          </Stack>
          <Stack direction="row" alignItems="baseline" sx={dayTemplateWidth}>
            <FormControl sx={select}>
              <InputLabel id="class" shrink sx={bgWhite}>
                Track
              </InputLabel>
              <Select
                labelId="class"
                id="class"
                value={trackValue}
                onChange={handleChange}
              >
                {dayTemplate?.tracks &&
                  dayTemplate?.tracks.length > 0 &&
                  dayTemplate?.tracks.map((track) => (
                    <MenuItem key={track} value={track}>
                      {track}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Stack>
        </Stack>
        <Stack direction="row">
          <ClassSchedulesSelect
            activeFilter={activeFilter}
            setActiveFilter={setActiveFilter}
            label="Class"
            items={classes}
            selectedItem={selectedClass}
            setSelectedItem={setSelectedClass}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
          <ClassSchedulesSelect
            activeFilter={activeFilter}
            setActiveFilter={setActiveFilter}
            label="Teacher"
            items={teachers}
            selectedItem={selectedTeacher}
            setSelectedItem={setSelectedTeacher}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
          <ClassSchedulesSelect
            activeFilter={activeFilter}
            setActiveFilter={setActiveFilter}
            label="Student"
            items={students}
            selectedItem={selectedStudent}
            setSelectedItem={setSelectedStudent}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
        </Stack>
      </Stack>

      {periods &&
        periods.map((period) => (
          <ClassSchedulesRow
            period={period}
            key={period.id}
            selectedClass={selectedClass}
          />
        ))}
    </Box>
  );
}
