import React, { useState, useEffect, useContext } from "react";
import { useSearchParams, Link, useParams } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Container,
  CircularProgress,
  Drawer,
  Pagination,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  TextField,
  InputAdornment,
  styled,
  Switch,
  Checkbox,
} from "@mui/material";
import MUILink from "@mui/material/Link";
import { MoreVert } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import {
  py10,
  mr55,
  rightDrawerContainer,
  loader,
  gcLogoContainer,
  paginationContent,
  switchBtn,
} from "../../sharedStyles";
import {
  assignmentsListName,
  assignmentsListHead,
  assignmentsListCell,
  assignmentsListTable,
  assignmentsListCellName,
  assignmentsListHeadKlass,
} from "./AssignmentsList.styles";
import { searchContainer } from "../../../views/Subject.styles";
import assignmentService from "../../../service/assignmentService";
import googleService from "../../../service/googleService";
import moment from "../../../utils/constants/momentConfig";
import dateTimeFormats from "../../../utils/constants/dateTimeFormats";
import { klassesSettingsCell } from "../../../views/MyKlasses.styles";
import AssignmentsForm from "../../Assignments/AssignmentsForm";
import { QuickBarContext } from "../../../context/QuickBarContext";
import { SnackbarContext } from "../../../context/SnackbarContext";
import gcLogoPath from "../../../utils/constants/googleClassroom";
import truncateString from "../../../utils/truncateString";
import { PermissionsContext } from "../../../context/PermissionsContext";
import APP_PERMISSIONS from "../../../utils/constants/permissions";
import PERMISSION_TYPES from "../../../utils/constants/permission_types";
import BulkDelete from "./AssignmentsBulkDelete";

const CssSearchField = styled(TextField)({
  "& .MuiInput-root": {
    "&:before": {
      borderBottom: "1px solid #22282C !important",
    },
  },
});

export default function AssignmentsList({
  showAll,
  query,
  setQuery,
  queryChanged,
  setQueryChanged,
}) {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [assignments, setAssignments] = useState([]);
  const [assignmentsDrawerStatusEdit, setAssignmentsDrawerStatusEdit] =
    useState(false);
  const [order, setOrder] = useState(searchParams.get("direction") || "asc");
  const [orderBy, setOrderBy] = useState(searchParams.get("sort") || "name");
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = React.useState(searchParams.get("page") || 1);
  const [count, setCount] = useState(10);
  const [duplicateAssignment, setDuplicateAssignment] = useState(false);
  const [activeAssignment, setActiveAssignment] = useState(null);
  const [loading, setLoading] = useState(false);
  const quickbarContext = useContext(QuickBarContext);
  const snackbarContext = useContext(SnackbarContext);
  const { hasPermission } = useContext(PermissionsContext);
  const [assignmentsToDelete, setAssignmentsToDelete] = useState([]);
  const [openBulkDelete, setOpenBulkDelete] = useState(false);

  const { register, handleSubmit, getValues, setValue } = useForm();
  const [showClearButton, setShowClearButton] = React.useState(
    searchParams.get("query") || false
  );
  const cancelButtonStyle = {
    visibility: showClearButton ? "inherit" : "hidden",
  };
  const [localQuery, setLocalQuery] = React.useState(
    searchParams.get("query") || ""
  );

  const subjectId = params.subject_id;
  const schoolId = params.school_id;
  const klassesIds = searchParams.get("classes") || null;

  const [anchorEl, setAnchorEl] = useState(null);
  const [assignmentId, setAssignmentId] = useState(null);
  const open = Boolean(anchorEl);

  const managePermission = hasPermission(
    APP_PERMISSIONS.KLASSES_ASSIGNMENTS,
    PERMISSION_TYPES.MANAGE
  );

  const handleMenuClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setAssignmentId(id);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const getSubjectAssignments = async () => {
    if (!quickbarContext.assignmentDrawer && !assignmentsDrawerStatusEdit) {
      setLoading(true);
    }

    if (searchParams.get("term") && !assignmentsDrawerStatusEdit) {
      const response = await assignmentService.fetchSubjectAssignments({
        params: {
          term_id: searchParams.get("term"),
          school_id: schoolId,
          subject_id: subjectId,
          klass_id: klassesIds?.split(",").map((k) => Number(k)),
          order,
          orderBy,
          query: localQuery,
          count,
          page,
        },
      });
      if (response) {
        const lastPage = response.pagination.total_pages;
        setTotalPages(lastPage);

        const queryPage = searchParams.get("page") || 1;
        const currentPage = queryPage > lastPage ? lastPage : queryPage;
        setPage(currentPage || 1);

        const countPerPage = searchParams.get("count") || 10;
        setCount(countPerPage);

        setOrder(searchParams.get("direction") || "asc");
        setOrderBy(searchParams.get("sort") || "name");

        setAssignments(response.assignments);
        setLocalQuery(searchParams.get("query") || "");

        if (response.google_klasses) {
          quickbarContext.fireGoogleAssignments();
        } else {
          quickbarContext.cleanGoogleAssignments();
        }
      }
    }
    setLoading(false);
  };

  const getAllAssignments = async () => {
    if (!quickbarContext.assignmentDrawer && !assignmentsDrawerStatusEdit) {
      setLoading(true);
    }

    if (searchParams.get("term") !== "select" && !assignmentsDrawerStatusEdit) {
      const response = await assignmentService.fetchAllAssignments({
        params: {
          term_id: searchParams.get("term"),
          school_id: schoolId,
          subject_id: subjectId,
          klass_id: klassesIds?.split(",").map((k) => Number(k)),
          order,
          orderBy,
          query,
          count,
          page,
        },
      });
      const lastPage = response.pagination.total_pages;
      setTotalPages(lastPage);

      const queryPage = searchParams.get("page") || 1;
      const currentPage = queryPage > lastPage ? lastPage : queryPage;
      setPage(currentPage || 1);

      const countPerPage = searchParams.get("count") || 10;
      setCount(countPerPage);

      setOrder(searchParams.get("direction") || "asc");
      setOrderBy(searchParams.get("sort") || "name");
      setQuery(searchParams.get("query") || "");

      if (response.data) {
        setAssignments(response.data.assignments);
      }
    }
    setLoading(false);
  };

  // eslint-disable-next-line no-shadow
  const handleDisplayInPortal = async (event, assignment) => {
    const { checked } = event.target;
    const response = await assignmentService.updateDisplayInPortal({
      assignment_id: assignment.id,
      display_in_portal: checked,
    });

    if (response) {
      // eslint-disable-next-line no-param-reassign
      assignment.display_in_portal = checked;
      snackbarContext.setSnackbar({
        message: "Saved",
        severity: "success",
        open: true,
      });
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    const direction = isAsc ? "desc" : "asc";
    setOrder(direction);
    setOrderBy(property);

    searchParams.set("sort", property);
    searchParams.set("direction", direction);
    setSearchParams(searchParams);
  };

  const renderSortChevron = (label) => {
    const noColumnSelected = searchParams.get("sort") === null;

    if ((noColumnSelected && label === "assigned-date") || label === orderBy) {
      return order === "asc" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
    }
    return <ArrowDropUpIcon sx={{ visibility: "hidden" }} />;
  };

  const getActiveAssignment = async (id) => {
    const response = await assignmentService.fetch(id);
    if (response.data) {
      setActiveAssignment(response.data);
    }
  };

  const handleAssignmentsDrawer = (status, duplicate = false) => {
    handleMenuClose();
    setDuplicateAssignment(duplicate);
    setAssignmentsDrawerStatusEdit(status);

    if (status === false) {
      setActiveAssignment(null);
    } else if (assignmentId) {
      getActiveAssignment(assignmentId);
    }
  };

  const syncAssignments = async () => {
    snackbarContext.setSnackbar({
      message: "Syncing assignments, please wait...",
      severity: "info",
      open: true,
    });

    const response = await googleService.sync({
      params: { subjectId },
    });

    if (response.data === "Synced") {
      quickbarContext.cleanSyncAssignments();
      getSubjectAssignments();
      snackbarContext.setSnackbar({
        message: "Assignments synced",
        severity: "success",
        open: true,
      });
    }

    if (response.data === "Error") {
      quickbarContext.cleanSyncAssignments();
      snackbarContext.setSnackbar({
        message: "Something went wrong, try again later.",
        severity: "error",
        open: true,
      });
    }
  };

  const handleBulkDeleteCheckbox = (event) => {
    const { value, checked } = event.target;

    if (checked) {
      setAssignmentsToDelete([
        ...assignmentsToDelete,
        {
          id: Number(value),
          name: assignments.find((a) => a.id === Number(value)).name,
        },
      ]);
    } else {
      setAssignmentsToDelete(
        assignmentsToDelete.filter((a) => a.id !== Number(value))
      );
    }
  };

  const handleBulkDeleteClose = () => {
    setOpenBulkDelete(false);
  };

  const handleBulkDeleteSuccess = () => {
    setOpenBulkDelete(false);
    setAssignmentsToDelete([]);
    searchParams.set("page", 1);
    getSubjectAssignments();
  };

  const handleUnselectAssignmentsToDelete = () => {
    setAssignmentsToDelete([]);
  };

  useEffect(() => {
    if (quickbarContext.syncAssignments === true) {
      syncAssignments();
    }
  }, [quickbarContext.syncAssignments]);

  useEffect(() => {
    if (showAll) getAllAssignments();
    else getSubjectAssignments();
  }, [
    order,
    orderBy,
    subjectId,
    klassesIds,
    activeAssignment,
    quickbarContext.assignmentDrawer,
    localQuery,
    page,
    searchParams.get("term"),
    queryChanged,
  ]);

  useEffect(() => {
    if (queryChanged) {
      searchParams.set("page", 1);
      setSearchParams(searchParams);
      setQueryChanged(false);
    }
  }, [queryChanged]);

  const handleChangePage = (event, value) => {
    setPage(value);
    searchParams.set("page", value);
    setSearchParams(searchParams);
  };

  const handleSearch = (data) => {
    setPage(1);
    searchParams.set("page", 1);
    searchParams.set("query", data.search);
    setLocalQuery(data.search);
    setSearchParams(searchParams);
  };

  const handleQuickFindChange = (event) => {
    if (event.target.value) setShowClearButton(true);
    else setShowClearButton(false);
  };

  const handleClickOnClearButton = () => {
    setValue("search", "");
    setShowClearButton(false);
    handleSearch(getValues());
  };

  useEffect(() => {
    setValue("search", localQuery);
  }, []);

  useEffect(() => {
    if (quickbarContext.deleteAssignments) {
      setOpenBulkDelete(true);
      quickbarContext.cleanDeleteAssignments();
    }
  }, [quickbarContext.deleteAssignments]);

  if (loading) {
    return <CircularProgress sx={loader} size={100} />;
  }

  return (
    <>
      <Box sx={searchContainer}>
        {!showAll && (
          <form
            onSubmit={handleSubmit(handleSearch)}
            onChange={handleQuickFindChange}
          >
            <CssSearchField
              label="Quick Find"
              variant="standard"
              color="primary"
              {...register("search")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickOnClearButton}
                      sx={cancelButtonStyle}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                    <IconButton type="submit">
                      <SearchIcon size="small" color="primary" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </form>
        )}
      </Box>
      <Container maxWidth={false} variant="header">
        <Container maxWidth="lg">
          {assignments.length > 0 ? (
            <>
              <TableContainer sx={py10}>
                <Table sx={assignmentsListTable}>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={assignmentsListHead} />

                      <TableCell
                        sx={assignmentsListHead(false, orderBy === "name")}
                        onClick={() => handleRequestSort("name")}
                      >
                        <Typography sx={assignmentsListName}>
                          NAME {renderSortChevron("name")}
                        </Typography>
                      </TableCell>
                      {showAll && (
                        <TableCell
                          sx={assignmentsListHead(false, orderBy === "subject")}
                          onClick={() => handleRequestSort("subject")}
                        >
                          <Typography sx={assignmentsListHeadKlass}>
                            SUBJECT {renderSortChevron("subject")}
                          </Typography>
                        </TableCell>
                      )}
                      <TableCell
                        sx={assignmentsListHead(false, orderBy === "class")}
                        onClick={() => handleRequestSort("class")}
                      >
                        <Typography sx={assignmentsListHeadKlass}>
                          CLASS {renderSortChevron("class")}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={assignmentsListHead(false, orderBy === "category")}
                        onClick={() => handleRequestSort("category")}
                      >
                        <Typography>
                          CATEGORY {renderSortChevron("category")}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={assignmentsListHead(true, orderBy === "students")}
                        onClick={() => handleRequestSort("students")}
                      >
                        <Typography sx={mr55}>
                          STUDENTS {renderSortChevron("students")}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={assignmentsListHead(
                          false,
                          orderBy === "assigned-date" ||
                            searchParams.get("sort") === null
                        )}
                        onClick={() => handleRequestSort("assigned-date")}
                      >
                        <Typography>
                          ASSIGNED DATE {renderSortChevron("assigned-date")}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={assignmentsListHead(false, orderBy === "due-date")}
                        onClick={() => handleRequestSort("due-date")}
                      >
                        <Typography>
                          DUE DATE {renderSortChevron("due-date")}
                        </Typography>
                      </TableCell>
                      <TableCell sx={assignmentsListHead}>
                        <Typography>PORTAL</Typography>
                      </TableCell>
                      <TableCell sx={assignmentsListHead} />
                      <TableCell sx={assignmentsListHead} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {assignments.map((assignment) => (
                      <TableRow hover key={assignment.id}>
                        <TableCell>
                          {subjectId && managePermission && (
                            <Checkbox
                              value={assignment.id}
                              onChange={handleBulkDeleteCheckbox}
                              checked={assignmentsToDelete
                                .map((a) => a.id)
                                .includes(assignment.id)}
                            />
                          )}
                        </TableCell>

                        <TableCell align="left" sx={assignmentsListCellName}>
                          {managePermission ? (
                            <Link
                              to={`/school/${schoolId}/subjects/${
                                subjectId || assignment.klass.subject.id
                              }/class/${assignment.klass_id}/assignments/${
                                assignment.id
                              }?term=${searchParams.get("term")}`}
                            >
                              <Tooltip
                                placement="top"
                                title={assignment.name}
                                key={assignment.id}
                              >
                                <Typography>
                                  <span>
                                    {truncateString(assignment.name, 30)}
                                  </span>
                                </Typography>
                              </Tooltip>
                            </Link>
                          ) : (
                            <Tooltip
                              placement="top"
                              title={assignment.name}
                              key={assignment.id}
                            >
                              <Typography>
                                <span>
                                  {truncateString(assignment.name, 30)}
                                </span>
                              </Typography>
                            </Tooltip>
                          )}
                        </TableCell>
                        {showAll && (
                          <TableCell align="left" sx={assignmentsListCell}>
                            <Typography>
                              <span>{assignment.klass.subject.name}</span>
                            </Typography>
                          </TableCell>
                        )}
                        <TableCell align="left" sx={assignmentsListCell}>
                          <Typography>
                            <span>{assignment.klass.abbreviation}</span>
                          </Typography>
                        </TableCell>
                        <TableCell align="left" sx={assignmentsListCell}>
                          <Typography>
                            <span>{assignment.category}</span>
                          </Typography>
                        </TableCell>
                        <TableCell align="center" sx={assignmentsListCell}>
                          <Typography sx={mr55}>
                            {assignment.assignment_students_count}
                          </Typography>
                        </TableCell>
                        <TableCell align="left" sx={assignmentsListCell}>
                          <Typography>
                            <span>
                              {moment(assignment.assigned_date).format(
                                dateTimeFormats.MMDDYYYY
                              )}
                            </span>
                          </Typography>
                        </TableCell>
                        <TableCell align="left" sx={assignmentsListCell}>
                          <Typography>
                            <span>
                              {assignment.due_date !== null
                                ? moment(assignment.due_date).format(
                                    dateTimeFormats.MMDDYYYY
                                  )
                                : "-"}
                            </span>
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Switch
                            sx={switchBtn}
                            checked={assignment.display_in_portal}
                            onChange={(event) =>
                              handleDisplayInPortal(event, assignment)
                            }
                          />
                        </TableCell>
                        <TableCell>
                          {assignment.lms_assignment_id && (
                            <Box
                              sx={gcLogoContainer}
                              onClick={() =>
                                window.open(
                                  assignment.gc_assignment_link,
                                  "_blank"
                                )
                              }
                            >
                              <img src={gcLogoPath} alt="logo" />
                            </Box>
                          )}
                        </TableCell>
                        {managePermission && (
                          <TableCell sx={klassesSettingsCell}>
                            <IconButton
                              onClick={(e) => handleMenuClick(e, assignment.id)}
                            >
                              <MoreVert />
                            </IconButton>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
              >
                <MenuItem
                  autoFocus={false}
                  onClick={() => handleAssignmentsDrawer(true)}
                >
                  Edit Assignment
                </MenuItem>
                <MenuItem onClick={() => handleAssignmentsDrawer(true, true)}>
                  Duplicate
                </MenuItem>
              </Menu>

              {openBulkDelete && (
                <BulkDelete
                  typeDeleteToConfirm
                  assignments={assignmentsToDelete}
                  onClose={handleBulkDeleteClose}
                  onSuccess={handleBulkDeleteSuccess}
                />
              )}

              {assignmentsToDelete.length > 0 && (
                <Typography align="center" color="error">
                  Total of <b>{assignmentsToDelete.length}</b> assignments
                  selected.{" "}
                  <MUILink
                    onClick={handleUnselectAssignmentsToDelete}
                    sx={{ display: "inline" }}
                  >
                    Unselect all
                  </MUILink>
                </Typography>
              )}

              <Pagination
                count={totalPages}
                onChange={handleChangePage}
                sx={paginationContent}
                page={parseInt(page, 10)}
              />
            </>
          ) : (
            <Typography align="center">No assignments found.</Typography>
          )}
        </Container>
      </Container>
      <Drawer anchor="right" open={assignmentsDrawerStatusEdit}>
        <Box sx={rightDrawerContainer}>
          {activeAssignment ? (
            <AssignmentsForm
              schoolId={schoolId}
              handleAssignmentsDrawer={handleAssignmentsDrawer}
              activeAssignment={activeAssignment}
              duplicate={duplicateAssignment}
              termId={searchParams.get("term")}
              onListScreen
            />
          ) : (
            <CircularProgress sx={loader} size={100} />
          )}
        </Box>
      </Drawer>
    </>
  );
}
