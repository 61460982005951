import * as React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useOutletContext, useParams } from "react-router-dom";
import { Box, Grid, Divider } from "@mui/material";
import { useContext } from "react";
import MedicalDetailsSection from "./MedicalDetailsSection";
import InfoAndContacts from "./InfoAndContacts";
import MedicalAccomodations from "./MedicalAccomodations";
import {
  infoMedicalBox,
  profilePicContainer,
  academicsImg,
  accountVerticalContainer,
} from "./StudentInfoMedical.style";
import { mb30, ml20, py45 } from "../../sharedStyles";
import MedicalEvents from "./MedicalEvents";
import studentsService from "../../../service/studentsService";
import AutoSave from "../../AutoSave";
import { StudentActionsContext } from "../../../context/StudentActionsContext";
import { PermissionsContext } from "../../../context/PermissionsContext";
import APP_PERMISSIONS from "../../../utils/constants/permissions";
import Authorize from "../../Authorize";
import CustomFieldRenderer from "../../School/CustomFields/CustomFieldRenderer/CustomFieldRenderer";

export default function StudentInfoMedical() {
  const [, open] = useOutletContext();
  const methods = useForm();
  const params = useParams();
  const studentActionsContext = React.useContext(StudentActionsContext);
  const [medicalRecords, setMedicalRecords] = React.useState({});
  const [medicalEvents, setMedicalEvents] = React.useState([]);
  const [student, setStudent] = React.useState(null);
  const { hasAnyPermissionType } = useContext(PermissionsContext);

  const medicalProfilePermission = hasAnyPermissionType(
    APP_PERMISSIONS.STUDENT_MEDICAL
  );

  const [profilePictureSrc, setProfilePictureSrc] = React.useState(
    "/assets/default_avatar.jpg"
  );

  React.useEffect(() => {
    (async () => {
      const response = await studentsService.fetchMedicalDetails(
        params.student_slug,
        {
          params: { school_id: params.school_id },
        }
      );
      setStudent(response);
    })();
  }, []);

  const loadMedicalRecords = () => {
    studentsService.getMedicalRecords(params.student_slug).then((response) => {
      if (Object.prototype.hasOwnProperty.call(response.data.data, "")) {
        const medicalRecordsSorted = response.data.data;
        if (!medicalRecordsSorted.Other)
          medicalRecordsSorted.Other = medicalRecordsSorted[""];
        else
          medicalRecordsSorted.Other = medicalRecordsSorted.Other.concat(
            medicalRecordsSorted[""]
          );
        delete medicalRecordsSorted[""];
        setMedicalRecords(medicalRecordsSorted);
      } else {
        setMedicalRecords(response.data.data);
      }
    });
  };

  const loadMedicalEvents = () => {
    studentsService.fetchMedicalEvents(params.student_slug).then((response) => {
      if (response.data.data)
        setMedicalEvents(
          response.data.data.filter(
            (data) => data.student_event_category_id === 1
          )
        );
    });
  };

  const editMedicalRecord = (id) => {
    studentActionsContext.openMedicalRecordDrawer(loadMedicalRecords, id);
  };

  const editMedicalEvent = (id) => {
    studentActionsContext.openEventDrawer(loadMedicalEvents, id);
  };

  const renderMedicalRecords = Object.keys(medicalRecords)
    .filter((key) => key !== "Medical Accommodation")
    .map((key) => (
      <MedicalDetailsSection
        key={key}
        title={key}
        details={medicalRecords[key]}
        editMedicalRecord={editMedicalRecord}
      />
    ));

  const getMedicalAccommodation = Object.keys(medicalRecords)
    .filter((key) => key === "Medical Accommodation")
    .map((key) => medicalRecords[key]);

  React.useEffect(() => {
    loadMedicalRecords();
    loadMedicalEvents();
    if (student?.image_url) {
      setProfilePictureSrc(student.image_url.url);
    }
  }, []);

  React.useEffect(() => {
    if (studentActionsContext.medicalRecordsUpdated) {
      loadMedicalRecords();
      studentActionsContext.cleanMedicalRecordsUpdated();
    }
    if (studentActionsContext.medicalEventsUpdated) {
      loadMedicalEvents();
      studentActionsContext.cleanMedicalEventsUpdated();
    }
  }, [
    studentActionsContext.medicalRecordsUpdated,
    studentActionsContext.medicalEventsUpdated,
  ]);

  return (
    <Authorize permission={medicalProfilePermission}>
      {student && (
        <FormProvider {...methods}>
          <Grid sx={py45} md={open ? 12 : 9.5} paddingX={1}>
            <Grid container mb={5}>
              <Grid item md={9} p={3} sx={accountVerticalContainer}>
                <Box sx={infoMedicalBox} paddingRight="none">
                  <Box sx={mb30}>
                    <InfoAndContacts student={student} />
                  </Box>
                  <Divider sx={{ width: "100%", borderColor: "black" }} />
                  <Box my={3}>
                    <CustomFieldRenderer
                      sectionType="student_medical"
                      customFieldResponses={student.custom_field_responses}
                    />
                  </Box>
                  <Divider sx={{ width: "100%", borderColor: "black" }} />
                </Box>
              </Grid>

              <Grid container item xs={12} sm={11} md={3}>
                <Grid
                  item
                  xs={12}
                  ml={{ sx: "10%", sm: "5%" }}
                  sx={profilePicContainer}
                >
                  <img
                    alt="profile"
                    src={profilePictureSrc}
                    style={academicsImg}
                  />
                </Grid>
              </Grid>
            </Grid>
            {Boolean(Object.keys(medicalRecords).length) && (
              <>
                <Divider sx={{ borderColor: "black" }} />
                <Grid container>
                  <Grid item md={9} my={5} sx={accountVerticalContainer}>
                    <Box sx={infoMedicalBox}>{renderMedicalRecords}</Box>
                  </Grid>
                  <Grid item md={3}>
                    <Box sx={ml20}>
                      {Boolean(
                        getMedicalAccommodation?.length &&
                          getMedicalAccommodation[0]
                      ) && (
                        <MedicalAccomodations
                          details={getMedicalAccommodation[0]}
                          editMedicalRecord={editMedicalRecord}
                        />
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </>
            )}
            {Boolean(medicalEvents.length) && (
              <>
                <Divider sx={{ borderColor: "black" }} />
                <Grid container my={5}>
                  <MedicalEvents
                    medicalEvents={medicalEvents}
                    editMedicalEvent={editMedicalEvent}
                  />
                </Grid>
              </>
            )}
          </Grid>
          {methods.formState.isDirty && (
            <AutoSave
              saveMethod={studentsService.updateMedicalDetails}
              params={[params.student_slug]}
              successMessage="Profile saved"
              progressMessage="Saving profile..."
            />
          )}
        </FormProvider>
      )}
    </Authorize>
  );
}
