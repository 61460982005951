import {
  Box,
  Button,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import {
  classesBox,
  classesBoxDate,
  classesCard,
  tableBodyClassName,
  tableBodyText,
  tableBodyMeeting,
  tableBodyMissingAssignment,
  tableContainer,
  tableHead,
} from "./Classes.styles";
import dateTimeFormats from "../../../utils/constants/dateTimeFormats";
import { textCenter } from "../../sharedStyles";
import { fetchStudentClassesAndAssignments } from "../../../service/studentPortalService";
import moment from "../../../utils/constants/momentConfig";
import truncateString from "../../../utils/truncateString";

export default function StudentPortalHomeClasses() {
  const params = useParams();
  const studentId = params.id;
  const schoolId = params.school_id;
  const [studentClassesAndAssignments, setStudentClassesAndAssignments] =
    useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const currentDate =
    searchParams.get("date") !== null
      ? moment(searchParams.get("date"))
      : moment();

  const directions = { back: 1, forward: 2 };

  const lastSunday = currentDate
    .startOf("week")
    .format(dateTimeFormats.MMMDYYYY);

  const nextSaturday = currentDate
    .endOf("week")
    .format(dateTimeFormats.MMMDYYYY);

  useEffect(() => {
    (async () => {
      if (studentId && schoolId) {
        try {
          setStudentClassesAndAssignments([]);
          setLoading(true);

          const response = await fetchStudentClassesAndAssignments(studentId, {
            params: {
              school_id: schoolId,
              date: searchParams.get("date"),
            },
          });

          if (response.data) {
            setStudentClassesAndAssignments(response.data);
            setLoading(false);
          }
        } catch (e) {
          // handle catch
        }
      }
    })();
  }, [schoolId, searchParams.get("date")]);

  const handleDayChange = (direction) => {
    if (direction === directions.back) {
      searchParams.set(
        "date",
        currentDate.add(-7, "d").format(dateTimeFormats.YYYYMMDD)
      );
    } else if (direction === directions.forward) {
      searchParams.set(
        "date",
        currentDate.add(7, "d").format(dateTimeFormats.YYYYMMDD)
      );
    }
    setSearchParams(searchParams);
  };

  const calculateMissingAssignments = (assignments) => {
    let count = 0;
    // eslint-disable-next-line array-callback-return
    assignments.map((assignment) => {
      count += assignment.assignment_students.filter(
        (as) => as.score === "/"
      ).length;
    });

    if (count === 0) {
      count = "";
    } else if (count === 1) {
      count = `${count} missing assignment`;
    } else {
      count = `${count} missing assignments`;
    }
    return count;
  };

  const openMeetingURL = (url) => {
    // eslint-disable-next-line no-param-reassign
    url = url.match(/^https?:/) ? url : `//${url}`;
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <>
      <Box sx={classesBox}>My Classes and Assignments</Box>

      <Card sx={classesCard}>
        <CardContent>
          <Box sx={classesBoxDate}>
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ cursor: "pointer" }}
              onClick={() => handleDayChange(directions.back)}
            >
              <g opacity="0.7">
                <path d="M17.5 7L9 15L17.5 23" stroke="black" />
                <rect
                  x="0.5"
                  y="0.5"
                  width="29"
                  height="29"
                  rx="14.5"
                  stroke="black"
                />
              </g>
            </svg>
            <p style={{ paddingLeft: "10px", paddingRight: "10px" }}>
              {`${lastSunday} - ${nextSaturday}`}
            </p>
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ cursor: "pointer" }}
              onClick={() => handleDayChange(directions.forward)}
            >
              <path d="M12 23L20.5 15.5L12 7" stroke="black" />
              <rect
                x="0.5"
                y="0.5"
                width="29"
                height="29"
                rx="14.5"
                stroke="black"
              />
            </svg>
          </Box>

          <TableContainer sx={tableContainer}>
            <Table stickyHeader aria-label="class">
              {studentClassesAndAssignments.length > 0 && (
                <>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={tableHead}>Assignment</TableCell>
                      <TableCell sx={tableHead}>Type</TableCell>
                      <TableCell sx={tableHead}>Due Date</TableCell>
                      <TableCell sx={tableHead} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {studentClassesAndAssignments.map((klass) => (
                      <React.Fragment key={Math.random() * 10}>
                        <TableRow key={klass.id}>
                          <TableCell>
                            <Typography
                              sx={tableBodyClassName}
                              /* eslint-disable-next-line no-return-assign */
                              onClick={() =>
                                navigate(
                                  `/student/${studentId}/schools/${schoolId}/classes/${klass.id}`
                                )
                              }
                            >
                              {klass.name}
                            </Typography>
                            {klass.meeting_url && (
                              <Button
                                /* eslint-disable-next-line no-return-assign */
                                onClick={() =>
                                  openMeetingURL(klass.meeting_url.toString())
                                }
                                sx={tableBodyMeeting}
                                variant="contained"
                                endIcon={<VideocamOutlinedIcon />}
                              >
                                Join Class
                              </Button>
                            )}
                          </TableCell>
                          <TableCell />
                          <TableCell />
                          <TableCell>
                            <Typography
                              sx={tableBodyMissingAssignment}
                              onClick={() =>
                                navigate(
                                  `/student/${studentId}/schools/${schoolId}/classes/${klass.id}?assignments=missing`
                                )
                              }
                            >
                              {calculateMissingAssignments(klass.assignments)}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow key={`${klass.id} ${klass.name}`} hover>
                          {klass.assignments.length > 0 ? (
                            <>
                              <TableCell sx={{ border: "none" }}>
                                {klass.assignments
                                  .filter((a) => a.display_in_portal === true)
                                  .map((assignment) => (
                                    <Typography
                                      key={assignment.id}
                                      sx={tableBodyText}
                                    >
                                      {truncateString(assignment.name, 30)}
                                    </Typography>
                                  ))}
                              </TableCell>
                              <TableCell sx={{ border: "none" }}>
                                {klass.assignments
                                  .filter((a) => a.display_in_portal === true)
                                  .map((assignment) => (
                                    <Typography
                                      key={assignment.id}
                                      sx={tableBodyText}
                                    >
                                      {assignment.klass_category?.category ||
                                        "Classwork"}
                                    </Typography>
                                  ))}
                              </TableCell>
                              <TableCell sx={{ border: "none" }}>
                                {klass.assignments
                                  .filter((a) => a.display_in_portal === true)
                                  .map((assignment) => (
                                    <Typography
                                      key={assignment.id}
                                      sx={tableBodyText}
                                    >
                                      {moment(assignment.due_date).format(
                                        dateTimeFormats.ddddMMMMD
                                      )}
                                    </Typography>
                                  ))}
                              </TableCell>
                              <TableCell sx={{ border: "none" }} />
                            </>
                          ) : (
                            <TableCell sx={{ border: "none" }}>
                              <Typography sx={tableBodyText}>
                                No assignments for this week.
                              </Typography>
                            </TableCell>
                          )}
                        </TableRow>
                      </React.Fragment>
                    ))}
                  </TableBody>
                </>
              )}
              {studentClassesAndAssignments.length === 0 && (
                <TableBody>
                  <TableRow sx={textCenter}>
                    <TableCell style={{ borderBottom: "none" }} sx={textCenter}>
                      {loading ? "Loading..." : "No classes for this week."}
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </>
  );
}
