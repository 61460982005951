import React, { useState, useEffect } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  Grid,
  Typography,
  Box,
  Autocomplete,
  Checkbox,
  TextField,
} from "@mui/material";
import permissionsSubtitle from "./Permissions.styles";
import { my5 } from "../../../sharedStyles";
import securityGroupsService from "../../../../service/securityGroupsService";

export default function Permissions({
  selectedTeacher,
  teacher,
  managePermission,
}) {
  const { control, register, setValue } = useFormContext();
  const urlParams = useParams();
  const schoolId = urlParams.school_id;
  const [securityGroups, setSecurityGroups] = useState([]);

  const getSecurityGroups = async () => {
    if (schoolId) {
      const response = await securityGroupsService.fetchAll({ schoolId });

      if (response.data) {
        setSecurityGroups(response.data);
      }
    }
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  useEffect(() => {
    setValue("securityGroups", selectedTeacher.security_groups, {
      shouldDirty: true,
    });
  }, [selectedTeacher]);

  useEffect(() => {
    (async () => {
      await getSecurityGroups();
    })();
  }, []);

  const isLoggedTeacher = teacher.id === selectedTeacher.id;

  return (
    <Grid container mb={5}>
      <Grid item xs={12} my={3}>
        <Typography variant="profileTitle">Permissions</Typography>
        <Box sx={my5}>
          <Typography sx={permissionsSubtitle}>
            Select each group that applies.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} px={3}>
        <Controller
          name="securityGroups"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              disabled={isLoggedTeacher || !managePermission}
              multiple
              options={securityGroups}
              disableCloseOnSelect
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
              style={{ width: 500 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...register("securityGroups")}
                  label="Security Groups"
                />
              )}
              onChange={(e, data) => {
                field.onChange(data);
              }}
              defaultValue={selectedTeacher.security_groups}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}
