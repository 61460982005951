import axios from "../utils/axios-util";

const reportCardCommentServiceEndpoint = "/api/report_card_comments";

const fetchAll = async (params) => {
  const { data } = await axios.get(reportCardCommentServiceEndpoint, params);
  return data;
};

const save = async (params) => {
  const { data } = await axios.post(reportCardCommentServiceEndpoint, params);
  return data;
};

export default { fetchAll, save };
