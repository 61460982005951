import * as React from "react";
import {
  Stack,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useContext } from "react";
import { infoMedicalFormControl } from "./InfoAndContacts.style";
import ErasableTextField from "../../ErasableTextField";
import { formatPhoneNumber } from "../../../utils/constants/utils";
import { renderError } from "../../../utils/constants/forms";
import { PermissionsContext } from "../../../context/PermissionsContext";
import APP_PERMISSIONS from "../../../utils/constants/permissions";
import PERMISSION_TYPES from "../../../utils/constants/permission_types";

export default function InfoAndContacts({ student }) {
  const {
    setValue,
    register,
    formState: { errors },
    trigger,
  } = useFormContext();
  const handlePhoneNumberChange = (control, event) => {
    setValue(control, formatPhoneNumber(event.target.value), {
      shouldDirty: true,
    });
    trigger(control);
  };

  const { hasPermission } = useContext(PermissionsContext);

  const managePermission = hasPermission(
    APP_PERMISSIONS.STUDENT_MEDICAL,
    PERMISSION_TYPES.MANAGE
  );

  return (
    <Grid container direction="row" columnSpacing={5}>
      <Grid item md={4} />
      <Grid item md={4} />
      <Grid item marginBottom={3} md={4}>
        <FormControlLabel
          control={
            <Checkbox
              {...register("permission_to_treat")}
              defaultChecked={
                student.student_medical_detail?.permission_to_treat
              }
              disabled={!managePermission}
            />
          }
          label="Permission to treat"
          sx={infoMedicalFormControl}
        />
      </Grid>
      <Grid item md={4}>
        <Stack spacing={2}>
          <Typography variant="title">Doctor</Typography>
          <ErasableTextField
            id="doctor_name"
            label="Doctor's Name"
            fullWidth
            defaultValue={student.student_medical_detail?.doctor_name}
            disabled={!managePermission}
          />
          <ErasableTextField
            id="doctor_hospital_name"
            label="Hospital/Clinic Name"
            fullWidth
            defaultValue={student.student_medical_detail?.doctor_hospital_name}
            disabled={!managePermission}
          />
          <ErasableTextField
            id="doctor_phone_number"
            label="Phone Number"
            fullWidth
            hasError={errors.doctor_phone_number}
            defaultValue={student.student_medical_detail?.doctor_phone_number}
            onChange={(e) => handlePhoneNumberChange("doctor_phone_number", e)}
            pattern={/^\(\d{3}\) \d{3}-\d{4}$/}
            disabled={!managePermission}
          />
          {errors?.doctor_phone_number &&
            renderError(errors?.doctor_phone_number.message)}
        </Stack>
      </Grid>
      <Grid item md={4}>
        <Stack spacing={2}>
          <Typography variant="title">Dentist</Typography>
          <ErasableTextField
            id="dentist_name"
            label="Doctor's Name"
            fullWidth
            defaultValue={student.student_medical_detail?.dentist_name}
            disabled={!managePermission}
          />
          <ErasableTextField
            id="dentist_hospital_name"
            label="Hospital/Clinic Name"
            fullWidth
            defaultValue={student.student_medical_detail?.dentist_hospital_name}
            disabled={!managePermission}
          />
          <ErasableTextField
            id="dentist_phone_number"
            label="Phone Number"
            fullWidth
            hasError={errors.dentist_phone_number}
            defaultValue={student.student_medical_detail?.dentist_phone_number}
            onChange={(e) => handlePhoneNumberChange("dentist_phone_number", e)}
            pattern={/^\(\d{3}\) \d{3}-\d{4}$/}
            disabled={!managePermission}
          />
          {errors?.dentist_phone_number &&
            renderError(errors?.dentist_phone_number.message)}
        </Stack>
      </Grid>
      <Grid item md={4}>
        <Stack spacing={2}>
          <Typography variant="title">Insurance</Typography>
          <ErasableTextField
            id="insurance_company_name"
            label="Company's Name"
            fullWidth
            defaultValue={
              student.student_medical_detail?.insurance_company_name
            }
            disabled={!managePermission}
          />
          <ErasableTextField
            id="insurance_policy_number"
            label="Policy Number"
            fullWidth
            defaultValue={
              student.student_medical_detail?.insurance_policy_number
            }
            disabled={!managePermission}
          />
        </Stack>
      </Grid>
    </Grid>
  );
}
