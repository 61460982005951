import React, { useState, useContext, cloneElement } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { SnackbarContext } from "../../context/SnackbarContext";
import formResponseService from "../../service/formResponseService";
import APP_PERMISSIONS from "../../utils/constants/permissions";
import PERMISSION_TYPES from "../../utils/constants/permission_types";
import { PermissionsContext } from "../../context/PermissionsContext";

export default function DeleteFormResponse({
  formResponseId,
  typeDeleteToConfirm,
  children,
}) {
  const { hasPermission } = useContext(PermissionsContext);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const snackbarContext = useContext(SnackbarContext);
  const [deleteDisabled, setDeleteDisabled] = useState(typeDeleteToConfirm);

  const hasManagePermission = () =>
    hasPermission(APP_PERMISSIONS.ADMISSIONS, PERMISSION_TYPES.MANAGE);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSuccess = () => {
    snackbarContext.setSnackbar({
      message: "Form deleted.",
      severity: "success",
      open: true,
    });

    navigate(`/school/${params.school_id}/admissions/students/inquiries`);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    if (deleteDisabled) return;

    const request = formResponseService.deleteFormResponse(formResponseId);

    request
      .then(() => {
        handleSuccess();
      })
      .catch((error) => {
        console.log("Error deleting form response:", error);
      });
  };

  const contentChildren = cloneElement(children, {
    onClick: handleClickOpen,
  });

  if (!hasManagePermission()) return <span />;

  return (
    <>
      {contentChildren}

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: handleDelete,
        }}
      >
        <DialogTitle>Warning!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`
              If you proceed, the form will be permanently deleted.
            `}

            {typeDeleteToConfirm &&
              `
              If you really want to permanently remove this form, type DELETE
              in the field below and then click on the "Delete permanently" button.
            `}
          </DialogContentText>

          {typeDeleteToConfirm && (
            <TextField
              sx={{ marginTop: 3 }}
              autoFocus
              autoComplete="off"
              required
              label="Type DELETE to confirm"
              type="text"
              fullWidth
              variant="standard"
              placeholder="DELETE"
              onChange={(e) => {
                if (e.target.value.toLowerCase() === "delete") {
                  setDeleteDisabled(false);
                } else {
                  setDeleteDisabled(true);
                }
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" color="error" disabled={deleteDisabled}>
            Delete permanently
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
