import axios from "../utils/axios-util";

const assignmentsEndpoint = "/api/assignments_students";

const fetchAssignmentStudents = async (params) => {
  const { data } = await axios.get(assignmentsEndpoint, params);

  if (!data.data) {
    console.error("Error fetching assignments from the API");
    return [];
  }

  return data.data;
};

const updateAndCalculateScores = async (params) => {
  const data = await axios.post(`${assignmentsEndpoint}/calculate`, params);

  if (!data) {
    console.error("Error fetching assignments from the API");
    return [];
  }

  return data;
};

const updateAndCalculateMultipleScores = async (params) => {
  const data = await axios.post(
    `${assignmentsEndpoint}/calculate_multiple`,
    params
  );

  if (!data) {
    console.error("Error fetching assignments from the API");
    return [];
  }

  return data;
};

const fetchTermGrades = async (params) => {
  const { data } = await axios.get(`${assignmentsEndpoint}/term/grade`, params);

  if (!data.data) {
    console.error("Error fetching term grades from the API");
    return [];
  }

  return data.data;
};

export default {
  fetchAssignmentStudents,
  updateAndCalculateScores,
  fetchTermGrades,
  updateAndCalculateMultipleScores,
};
