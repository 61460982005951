import { Grid } from "@mui/material";
import React from "react";
import StudentWithProcessList from "./StudentWithProcessList";
import StudentSteps from "./StudentsStepEnum";

export default function Denied() {
  return (
    <Grid container mt={5}>
      <StudentWithProcessList status={StudentSteps.DENIED} />
    </Grid>
  );
}
