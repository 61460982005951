const filtersContainer = {
  borderBottom: "1px solid black",
};

const groupTableHead = {
  borderBottom: "none",
  color: "black",
  cursor: "pointer",
  px: "0px !important",
  pr: "7px",
  pb: "7px",
  userSelect: "none",
  "& p": {
    fontSize: "11px",
    fontWeight: "500",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
};

const groupTableCell = {
  px: "0px !important",
  pr: "7px",
  py: "7px",
  borderBottom: "none",
  color: "black",
};

export { filtersContainer, groupTableHead, groupTableCell };
