import useMediaQuery from "@mui/material/useMediaQuery";
import theme from "../utils";

const footerStyle = {
  backgroundColor: theme.palette.primary.main,
  color: "#ffffff",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
};

const footerGridContainer = {
  pt: "60px",
  pl: "70px",
  pr: "70px",
  mb: "16px",
  justifyContent: () => useMediaQuery(theme.breakpoints.down("md")) && "center",
};

const footerGridItem = {
  justifyContent: () =>
    useMediaQuery(theme.breakpoints.up("md")) ? "space-between" : "center",
};

const footerSystemStatus = { marginLeft: 20, marginRight: 5 };

const footerAppStatus = { display: "flex", alignItems: "center" };

const footerLogosGrid = {
  justifyContent: () =>
    useMediaQuery(theme.breakpoints.up("md")) ? "flex-end" : "center",
};

const footerSacLogoContainer = {
  width: 200,
  height: 60,
  marginRight: 10,
};

const footerSacLogo = {
  width: "inherit",
  height: "inherit",
  objectFit: "contain",
};

const footerAeLogoContainer = {
  width: 200,
  height: 60,
};

const footerAeLogo = {
  width: "inherit",
  height: "inherit",
  objectFit: "contain",
};

const copyrightContainer = { marginLeft: 70, marginRight: 70 };

const copyrightText = { margin: 0, paddingTop: 16, paddingBottom: 16 };

export {
  footerAeLogo,
  copyrightText,
  copyrightContainer,
  footerSacLogo,
  footerGridContainer,
  footerAeLogoContainer,
  footerStyle,
  footerSacLogoContainer,
  footerAppStatus,
  footerGridItem,
  footerLogosGrid,
  footerSystemStatus,
};
