const klassFormAddCatBtn = {
  textTransform: "none",
  fontWeight: "normal",
};

const klassFormCategoryBox = {
  width: "350px",
  mr: "25px",
  display: "flex",
};

const klassCategoryInputBox = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
};

const klassCategoryFormControl = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  width: "350px",
};

const klassCategoryWeightFormControl = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  width: "135px",
};

const klassCategoryCloseIcon = { color: "#1F1F1F", cursor: "pointer" };

const klassWeightError = { color: "red" };
export {
  klassFormAddCatBtn,
  klassFormCategoryBox,
  klassCategoryInputBox,
  klassCategoryFormControl,
  klassCategoryWeightFormControl,
  klassCategoryCloseIcon,
  klassWeightError,
};
