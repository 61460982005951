import { useCallback, useEffect, useContext } from "react";
import { useWatch } from "react-hook-form";
import { SnackbarContext } from "../context/SnackbarContext";
import { SchedulerContext } from "../context/SchedulerContext";
import { QuickBarContext } from "../context/QuickBarContext";

function AutoSave({
  saveMethod,
  params = [],
  queryParams = {},
  delay = 3000,
  successMessage = null,
  progressMessage = null,
  afterSaving = null,
  watcher = null,
}) {
  const snackbarContext = useContext(SnackbarContext);
  const schedulerContext = useContext(SchedulerContext);
  const quickbarContext = useContext(QuickBarContext);
  const data = watcher || useWatch();
  let timer;
  function debounce(func, timeout) {
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  const debouncedSave = useCallback(
    debounce(async (newData) => {
      schedulerContext.taskCount.current += 1;
      (async () => {
        schedulerContext.scheduleUpdateTask(
          saveMethod,
          params,
          newData,
          queryParams,
          successMessage,
          afterSaving
        );
        quickbarContext.cleanAutoSaving();
        schedulerContext.savingCurrent.current = false;
      })();
    }, delay),
    []
  );

  useEffect(() => {
    if (data) {
      if (progressMessage) {
        snackbarContext.setSnackbar({
          message: progressMessage,
          severity: "info",
          open: true,
        });
      }
      schedulerContext.savingCurrent.current = true;
      quickbarContext.fireAutoSaving();
      debouncedSave(data);
    }
  }, [data]);

  return null;
}

export default AutoSave;
