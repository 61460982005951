import React from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import KlassStudentAssignmentRow from "./KlassStudentAssignmentRow";
import { font12, black } from "../../../sharedStyles";

export default function KlassStudentAssignmentList({
  assignments,
  klass,
  specialMarks,
  activeStudent,
  getStudentDetail,
  hasPointsGrading,
}) {
  return (
    <TableContainer>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell sx={black}>
              <Typography sx={font12}>ASSIGNMENT</Typography>
            </TableCell>
            <TableCell sx={black}>
              <Typography sx={font12}>DUE DATE</Typography>
            </TableCell>
            <TableCell sx={black}>
              <Typography sx={font12}>CATEGORY</Typography>
            </TableCell>
            <TableCell sx={black}>
              <Typography sx={font12}>SCORE</Typography>
            </TableCell>
            {hasPointsGrading && (
              <TableCell sx={black}>
                <Typography sx={font12}>MAX</Typography>
              </TableCell>
            )}
            <TableCell sx={black}>
              <Typography sx={font12}>GRADE</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {assignments?.map((a) => (
            <KlassStudentAssignmentRow
              assignment={a}
              klass={klass}
              specialMarks={specialMarks}
              activeStudent={activeStudent}
              getStudentDetail={getStudentDetail}
              hasPointsGrading={hasPointsGrading}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
