import React, { useContext } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { containerStyle } from "../FormBuilder/FormBuilder.style";
import formRenderMap from "./formRenderMap";
import { FormBuilderContext } from "../FormBuilder/FormBuilderContext";
import { formTitle } from "../../../sharedStyles";

export default function FormSectionRenderer({
  section,
  sectionIndex,
  formattedView = false,
  dataView,
}) {
  const formBuilderContext = useContext(FormBuilderContext);

  const setCurrentSection = () => {
    formBuilderContext.updateCurrentSectionIndex(sectionIndex);
  };

  return (
    <Box
      onClick={setCurrentSection}
      container
      sx={containerStyle(formattedView)}
    >
      <Grid mb={2} container justifyContent="space-between">
        <Typography color="black" sx={formattedView ? formTitle : ""}>
          {section.name}
        </Typography>
      </Grid>
      <Grid
        container
        columnSpacing={2}
        rowGap={formattedView ? 0 : 2}
        alignItems="center"
      >
        {section.fields.map((field) => {
          if (!field.isVisible) {
            return null;
          }
          const Component = formRenderMap[field.type];
          return (
            <Grid
              key={field.fieldId}
              item
              md={field.w}
              sx={{
                position: "relative",
                height: "fit-content",
                // display: field.isVisible ? "" : "none",
              }}
            >
              <Component
                field={field}
                properties={{ ...field.properties, shrink: formattedView }}
                dataView={dataView}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
