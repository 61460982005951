const containerStyles = {
  height: "95vh",
  width: "100vw",
  overflow: "hidden",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  fontFamily: "arial, sans-serif",
  flexDirection: "column",
};

const imageStyles = {
  width: "230px",
  height: "159px",
};

const titleStyles = {
  color: "#605D66",
  fontWeight: 400,
  marginTop: "3rem",
};

const errorCodeTextStyles = {
  color: "#B3261E",
  textAlign: "center",
  fontSize: "14px",
  marginTop: "2rem",
  fontWeight: "bold",
};

const infoTextStyles = {
  color: "#605D66",
  fontSize: "14px",
  marginTop: "2rem",
};

const actionButtonStyles = {
  color: "#ffffff",
  backgroundColor: "#2196F3",
  fontSize: "14px",
  boxShadow:
    "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
  borderRadius: "4px",
  border: "none",
  padding: "0.5rem",
  cursor: "pointer",
  marginTop: "2.5rem",
};

export {
  containerStyles,
  imageStyles,
  titleStyles,
  errorCodeTextStyles,
  infoTextStyles,
  actionButtonStyles,
};
