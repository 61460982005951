import React from "react";
import { Route } from "react-router-dom";
import Guardian from "../views/Guardian";
import GuardianKidsInfo from "../components/Guardian/Kids/Info";
import GuardianKidsInfoHome from "../components/Guardian/Kids/Home/Home";
import GuardianKidsInfoClasses from "../components/Guardian/Kids/Classes/Classes";
import GuardianKidsInfoReportCards from "../components/Guardian/Kids/ReportCards/ReportCards";
import GuardianKidsInfoMessages from "../components/Guardian/Kids/Messages/Messages";
import GuardianKidsInfoConversationMessages from "../components/Guardian/Kids/Messages/Conversation";
import GuardianKidsInfoProfile from "../components/Guardian/Kids/Profile/Profile";
import GuardianMessages from "../components/Guardian/Messages/Messages";
import GuardianConversationMessages from "../components/Guardian/Messages/Conversation";
import GuardianKidsInfoClassDetails from "../components/Guardian/Kids/ClassDetails/ClassDetails";
import EnrollmentChecklist from "../components/Guardian/EnrollmentChecklist/EnrollmentChecklist";
import FormFill from "../components/Guardian/FormFill/FormFill";
import { FormBuilderContextProvider } from "../components/Admissions/Setup/FormBuilder/FormBuilderContext";
import Checklist from "../components/Guardian/EnrollmentChecklist/Checklist";
import Documents from "../components/Guardian/EnrollmentChecklist/Documents";
import Fees from "../components/Guardian/EnrollmentChecklist/Fees";
import Notifications from "../views/Notifications";
import NotificationsList from "../components/Notifications/List";
import NotificationDetails from "../components/Notifications/Details";

export default [
  <Route path="/guardian/:id" element={<Guardian />} key={0}>
    <Route
      path="/guardian/:id/messages"
      element={<GuardianMessages />}
      key={1}
    />
    <Route
      path="/guardian/:id/messages/:conversation_key"
      element={<GuardianConversationMessages />}
      key={2}
    />
    <Route
      path="/guardian/:id/students/enrollment-checklist"
      element={<EnrollmentChecklist />}
      key={4}
    />
    <Route
      path="/guardian/:id/students/:kid_id"
      element={<GuardianKidsInfo />}
      key={3}
    >
      <Route
        path="/guardian/:id/students/:kid_id/home"
        element={<GuardianKidsInfoHome />}
        key={3.1}
      >
        <Route
          key={1}
          path="/guardian/:id/students/:kid_id/home/enrollments/checklist"
          element={<Checklist />}
        />
        <Route
          key={2}
          path="/guardian/:id/students/:kid_id/home/enrollments/documents"
          element={<Documents />}
        />
        <Route
          key={2}
          path="/guardian/:id/students/:kid_id/home/enrollments/fees"
          element={<Fees />}
        />
      </Route>
      <Route
        path="/guardian/:id/students/:kid_id/classes"
        element={<GuardianKidsInfoClasses />}
        key={3.2}
      />
      <Route
        path="/guardian/:id/students/:kid_id/classes/:class_id"
        element={<GuardianKidsInfoClassDetails />}
        key={3.3}
      />
      <Route
        path="/guardian/:id/students/:kid_id/report-cards"
        element={<GuardianKidsInfoReportCards />}
        key={3.4}
      />
      <Route
        path="/guardian/:id/students/:kid_id/messages"
        element={<GuardianKidsInfoMessages />}
        key={3.5}
      />
      <Route
        path="/guardian/:id/students/:kid_id/messages/:conversation_key"
        element={<GuardianKidsInfoConversationMessages />}
        key={3.6}
      />
      <Route
        path="/guardian/:id/students/:kid_id/profile"
        element={<GuardianKidsInfoProfile />}
        key={3.7}
      />
    </Route>
    <Route
      key={4}
      path="/guardian/:id/form-fill/:form_id"
      element={
        <FormBuilderContextProvider>
          <FormFill />
        </FormBuilderContextProvider>
      }
    />
    <Route
      path="/guardian/:id/notifications"
      element={<Notifications />}
      key={5}
    >
      <Route index element={<NotificationsList />} key={5.1} />

      <Route
        path="/guardian/:id/notifications/:notification_id/details"
        element={<NotificationDetails />}
        key={5.2}
      />
    </Route>
  </Route>,
];
