import axios from "../utils/axios-util";

const dayTemplatesEndpoint = "/api/day_templates";

const fetchAllDayTemplates = async (params) => {
  const { data } = await axios.get(`${dayTemplatesEndpoint}`, params);
  if (!data.data) {
    console.error("Error fetching day templates from the API");
    return [];
  }
  return data;
};

const create = async (params) => {
  const { data } = await axios.post(dayTemplatesEndpoint, params);
  if (!data.data) {
    console.error("Error creating new day template");
    return [];
  }
  return data;
};

const update = async (dayTemplate) => {
  const { data } = await axios.put(
    `${dayTemplatesEndpoint}/${dayTemplate.id}`,
    dayTemplate
  );
  if (!data.data) {
    console.error("Error updating day template");
    return [];
  }
  return data;
};

const deleteDayTemplate = async (id) => {
  const { data } = await axios.delete(`${dayTemplatesEndpoint}/${id}`);
  if (!data.data) {
    console.error("Error deleting day template");
    return [];
  }
  return data;
};

export default {
  fetchAllDayTemplates,
  create,
  update,
  deleteDayTemplate,
};
