import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Button,
  Grid,
  CircularProgress,
  Drawer,
  Box,
  Popover,
} from "@mui/material";
import {
  MailOutline,
  MedicationLiquid,
  Person,
  StarBorderPurple500,
  StarOutlineOutlined,
} from "@mui/icons-material";
import {
  userCardAvatar,
  contactsTableStyles,
  circularProgressContainer,
  userCardActionButton,
} from "./StudentUserCard.style";
import EventCreate from "../StudentActions/ActionDrawers/EventCreate";
import { rightDrawerContainer } from "../../sharedStyles";
import studentsService from "../../../service/studentsService";

export default function StudentUserCard({
  studentId,
  schoolId,
  handleClose = () => {},
  anchorEl,
  showStudentUserCard,
}) {
  const [isEventDrawerOpen, setIsEventDrawerOpen] = React.useState(false);
  const [student, setStudent] = React.useState(null);
  const [, , , , , , , , , , handleMessagesDrawer] = useOutletContext();
  const [profilePictureSrc, setProfilePictureSrc] = useState(
    "/assets/default_avatar.jpg"
  );
  const [emergencyContacts, setEmergencyContacts] = useState([]);

  const renderMedicalRecords =
    !!student?.user_card_medical_records &&
    Object.keys(student.user_card_medical_records)
      .filter((key) => key !== "Medical Accommodation")
      .map((key) => (
        <Grid key={key} item container xs={12} direction="column">
          <Typography color="#F50057">{key}</Typography>

          {student.user_card_medical_records[key].map((record) => (
            <Typography key={record.id} paddingLeft={2} py={1}>
              {record.name}{" "}
              {!!record.comment_or_instruction &&
                ` — ${record.comment_or_instruction}`}
            </Typography>
          ))}
        </Grid>
      ));

  const renderMedicalAccommodations =
    !!student?.user_card_medical_records &&
    Object.keys(student.user_card_medical_records)
      .filter((key) => key === "Medical Accommodation")
      .map((key) => (
        <Grid key={key} item container xs={12} direction="column">
          <Typography color="#F50057">{key}</Typography>

          {student.user_card_medical_records[key].map((record) => (
            <Typography key={record.id} paddingLeft={2} py={1}>
              {record.name}{" "}
              {!!record.comment_or_instruction &&
                ` — ${record.comment_or_instruction}`}
            </Typography>
          ))}
        </Grid>
      ));

  const cleanUserCard = () => {
    setStudent(null);
    handleClose();
  };
  const addEvent = () => {
    cleanUserCard();
    setIsEventDrawerOpen(true);
  };

  useEffect(() => {
    (async () => {
      if (studentId && showStudentUserCard) {
        const response = await studentsService.fetchStudentCard(studentId, {
          params: { school_id: schoolId },
        });
        setStudent(response);

        let emergencyContactsResponse = response.guardians.filter(
          (guardian) =>
            guardian.is_emergency_contact ||
            guardian.is_primary_emergency_contact
        );

        emergencyContactsResponse = [
          ...response.emergency_contacts,
          ...emergencyContactsResponse,
        ];

        emergencyContactsResponse.sort((a, b) => {
          const aIsPrimary = a.is_primary || a.is_primary_emergency_contact;
          const bIsPrimary = b.is_primary || b.is_primary_emergency_contact;

          if (aIsPrimary === bIsPrimary) {
            return 0;
          }

          if (aIsPrimary) {
            return -1;
          }

          return 1;
        });

        setEmergencyContacts(emergencyContactsResponse);

        if (response?.image_url) {
          setProfilePictureSrc(response.image_url.url);
        } else {
          setProfilePictureSrc("/assets/default_avatar.jpg");
        }
      }
    })();
  }, [studentId, showStudentUserCard]);

  return (
    <>
      {showStudentUserCard && (
        <Popover
          open={showStudentUserCard}
          anchorEl={anchorEl}
          onClose={cleanUserCard}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: -10,
          }}
        >
          {student ? (
            <Grid
              item
              container
              sx={{
                minWidth: "450px",
                maxWidth: "450px",
              }}
            >
              <Grid
                item
                container
                xs={12}
                sx={{
                  backgroundColor: "#F5F5F5",
                  justifyContent: "center",
                  padding: "1rem",
                }}
              >
                <Grid item container xs={12}>
                  <Grid item container md={4}>
                    <div style={userCardAvatar}>
                      <img
                        alt="profile"
                        src={profilePictureSrc}
                        style={{ objectFit: "cover", width: "100%" }}
                      />
                    </div>
                  </Grid>
                  <Grid
                    item
                    container
                    md={8}
                    sx={{ color: "black", paddingLeft: "2rem" }}
                  >
                    <Grid
                      item
                      container
                      direction="column"
                      justifyContent="end"
                      mb={1}
                      xs={12}
                    >
                      <Typography variant="boxTitle" fontWeight={500}>
                        {`${student.first_name} ${student.last_name}`}
                      </Typography>
                      <Typography
                        fontSize={16}
                      >{`${student.grade_level} Grade`}</Typography>
                    </Grid>
                    {student.teacher && (
                      <Grid item container direction="column" xs={12}>
                        <Typography fontSize={13} fontWeight={500}>
                          HOMEROOM TEACHER
                        </Typography>
                        <Typography
                          fontSize={16}
                        >{`${student.teacher.first_name} ${student.teacher.last_name}`}</Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  justifyContent="center"
                  columnGap={1}
                  paddingTop={6}
                  paddingBottom={1}
                >
                  <Button
                    sx={userCardActionButton}
                    variant="contained"
                    startIcon={<Person />}
                    href={`/school/${schoolId}/students/${student.id}/profile`}
                  >
                    Profile
                  </Button>
                  <Button
                    sx={userCardActionButton}
                    variant="contained"
                    startIcon={<MailOutline />}
                    onClick={() => handleMessagesDrawer(true, student.id)}
                  >
                    New Message
                  </Button>
                  <Button
                    sx={userCardActionButton}
                    variant="contained"
                    startIcon={<StarBorderPurple500 />}
                    onClick={() => {
                      addEvent();
                    }}
                  >
                    Add Event
                  </Button>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{
                  px: "1rem",
                  pb: "1rem",
                  maxHeight: "25rem",
                  overflow: "auto",
                }}
              >
                <Grid item container xs={12} justifyContent="end">
                  {student.student_medical_detail?.permission_to_treat && (
                    <Typography color="#F50057" fontSize={18} display="flex">
                      Permission to treat
                      <MedicationLiquid sx={{ ml: "0.5rem" }} />
                    </Typography>
                  )}
                </Grid>
                {!!emergencyContacts?.length && (
                  <Grid item container xs={12}>
                    <Typography sx={{ color: "#F50057" }}>
                      Emergency Contacts
                    </Typography>
                    <Table sx={contactsTableStyles} aria-label="simple table">
                      <TableBody>
                        {emergencyContacts.map((contact) => (
                          <TableRow key={contact.id}>
                            <TableCell width="40%" align="left">
                              <Typography>
                                {`${contact.first_name} ${contact.last_name}`}{" "}
                                {contact.is_primary ||
                                  (contact.is_primary_emergency_contact && (
                                    <StarOutlineOutlined fontSize="20px" />
                                  ))}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography>{contact.relationship}</Typography>
                            </TableCell>
                            <TableCell width="40%" align="left">
                              <Typography>{contact.cell_phone}</Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Grid>
                )}
                <Grid item container xs={12}>
                  {renderMedicalRecords}
                  {renderMedicalAccommodations}
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid sx={circularProgressContainer}>
              <CircularProgress color="inherit" size={100} />
            </Grid>
          )}
        </Popover>
      )}
      {isEventDrawerOpen && (
        <Drawer anchor="right" open={isEventDrawerOpen}>
          <Box sx={rightDrawerContainer}>
            <EventCreate
              studentId={studentId}
              setIsEventDrawerOpen={setIsEventDrawerOpen}
            />
          </Box>
        </Drawer>
      )}
    </>
  );
}
