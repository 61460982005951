const calculationsTableCell = {
  color: "black",
  px: 0,
  fontSize: "16px",
};

const calculationsLastRow = {
  borderBottom: "2px solid black",
};

const calculationsTableCellBold = {
  ...calculationsTableCell,
  fontWeight: "bold",
};

export {
  calculationsTableCell,
  calculationsLastRow,
  calculationsTableCellBold,
};
