import {
  ConditionNodeType,
  LogicalOperator,
  ValueType,
} from "../../../Models/Fields/ConditionNodes/constants";

let fields = [];

const getFieldById = (fieldId) =>
  fields.find((field) => field.fieldId === fieldId);

const generateExpressionCode = (expression) => {
  const { operand1, operand2, operator, valueType, value } = expression;
  const operand1Field = getFieldById(operand1);
  const operand2Field =
    parseInt(valueType, 10) === ValueType.FIELD ? getFieldById(operand2) : null;

  // Determine right-hand sfieldIde value based on operand1 type
  let rhs;
  if (parseInt(valueType, 10) === ValueType.FIELD) {
    rhs = operand2Field.fieldId;
  } else {
    switch (operand1Field.type) {
      case "date-field":
        rhs = `new Date("${value}").getTime()`;
        break;
      default:
        rhs = JSON.stringify(value);
        break;
    }
  }

  // Generate code for date comparison
  if (
    operator === "includes" ||
    operator === "startsWith" ||
    operator === "endsWith"
  ) {
    return `${operand1Field.fieldId}.${operator}(${rhs})`;
  }

  return `${operand1Field.fieldId} ${operator} ${String(rhs)}`;
};

const traverseConditionNode = (node) => {
  if (node.type === ConditionNodeType.NODE) {
    return generateExpressionCode(node.expression);
  }
  let result = traverseConditionNode(node.conditionNodes[0]);

  for (let i = 1; i < node.conditionNodes.length; i += 1) {
    const logicalOperator =
      parseInt(node.conditionNodes[i].logicalOperator, 10) ===
      LogicalOperator.AND
        ? "&&"
        : "||";
    result += ` ${logicalOperator} ${traverseConditionNode(
      node.conditionNodes[i]
    )}`;
  }

  return `(${result})`;
};

const generateCode = (conditionTree, fieldsParam) => {
  fields = fieldsParam;
  return conditionTree.map(traverseConditionNode).join(" && ");
};

export default generateCode;
