import axios from "../utils/axios-util";

const studentEventCategoriesEndpoint = "/api/student_event_categories/";

const fetchAllCategories = async () => {
  const { data } = await axios.get(`${studentEventCategoriesEndpoint}`);
  if (!data.data) {
    console.error("Error fetching events from the API");
    return [];
  }
  return data;
};

export default {
  fetchAllCategories,
};
