import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import {
  reportCardCheckboxCell,
  reportCardGradeLevelCell,
  reportCardKlassesCellBox,
  reportCardKlassHeaderCell,
  reportCardNameCell,
  reportCardPublishedCell,
  reportCardStudentsHeader,
  reportCardTable,
} from "./ReportCardTable.styles";
import ReportCardPrekRow from "./ReportCardPrekRow";

export default function ReportCardPrekTable({
  isCheckAll,
  handleSelectAll,
  prekDomains,
  prekStudents,
  isCheck,
  setIsCheck,
  handleDrawerOpen,
  setPrekDrawer,
  setSelectedPrekStudent,
  setSelectedPrekStudentDomain,
  selectedTerm,
  handleOpenPreview,
}) {
  return (
    <Table cellSpacing={0} sx={reportCardTable}>
      <TableHead>
        <TableRow sx={{ paddingTop: "50px" }}>
          <TableCell align="left" sx={reportCardCheckboxCell}>
            <Box sx={{ maxWidth: "60px" }}>
              <Checkbox value={isCheckAll} onChange={handleSelectAll} />
            </Box>
          </TableCell>
          <TableCell align="left" sx={reportCardNameCell}>
            <Box sx={{ width: "100px", marginLeft: 2 }}>
              <Typography sx={reportCardStudentsHeader}>Students</Typography>
            </Box>
          </TableCell>
          <TableCell sx={reportCardGradeLevelCell} align="center">
            <Box>
              <Typography>Grade Level</Typography>
            </Box>
          </TableCell>
          <TableCell sx={reportCardPublishedCell} align="center">
            <Box sx={reportCardKlassesCellBox}>
              <Typography>Published</Typography>
            </Box>
          </TableCell>
          {prekDomains.map((domain) => (
            <TableCell
              key={domain.id}
              sx={reportCardKlassHeaderCell}
              align="center"
            >
              <Box sx={reportCardKlassesCellBox}>
                <Typography>{domain.name}</Typography>
              </Box>
            </TableCell>
          ))}
          <TableCell sx={reportCardKlassHeaderCell} align="center">
            <Box sx={reportCardKlassesCellBox} />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody className="prek-tbody">
        {prekStudents.map((student) => (
          <ReportCardPrekRow
            key={student.id}
            student={student}
            isCheck={isCheck}
            setIsCheck={setIsCheck}
            handleDrawerOpen={handleDrawerOpen}
            setPrekDrawer={setPrekDrawer}
            setSelectedPrekStudent={setSelectedPrekStudent}
            setSelectedPrekStudentDomain={setSelectedPrekStudentDomain}
            selectedTerm={selectedTerm}
            handleOpenPreview={handleOpenPreview}
          />
        ))}
      </TableBody>
    </Table>
  );
}
