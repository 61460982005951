/* eslint-disable no-param-reassign */
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { FormBuilderContext } from "../../../FormBuilderContext";
import {
  ValueType,
  conditionOperators,
} from "../../../Models/Fields/ConditionNodes/constants";

function Condition({ expression, conditionControl }) {
  const formBuilderContext = useContext(FormBuilderContext);
  const { form, currentSectionIndex, currentPageIndex } = formBuilderContext;

  const { register, getFieldState, setValue, control } = useFormContext();

  const [valueType, setValueType] = useState(Number(expression.valueType));

  const [operand1, setOperand1] = useState(null);

  const [operatorType, setOperatorType] = useState(null);

  const [selectedOptions, setSelectedOption] = useState(expression.value || []);

  const evaluateFixedInput = () => {
    if (operand1?.type === "date-field" && operatorType === "comparison") {
      return (
        <Controller
          name={`${conditionControl}.expression.value`}
          control={control}
          rules={{ required: "This field is required." }}
          render={({
            field: { onChange, value, ...rest },
            fieldState: { error },
          }) => (
            <DesktopDatePicker
              label="Date"
              inputFormat="MM/DD/YYYY"
              value={value}
              onChange={onChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!error}
                  helperText={error ? error.message : null}
                  sx={{ width: "100%" }}
                  InputLabelProps={{ required: true }}
                />
              )}
              {...rest}
            />
          )}
        />
      );
    }
    if (operand1?.type === "number-field") {
      return (
        <Controller
          name={`${conditionControl}.expression.value`}
          control={control}
          rules={{ required: "This field is required." }}
          render={() => (
            <TextField
              fullWidth
              type="number"
              InputLabelProps={{ required: true }}
              label="Number"
              error={
                getFieldState(`${conditionControl}.expression.value`).error
              }
            />
          )}
        />
      );
    }
    if (operand1?.type === "checkbox-field") {
      return (
        <FormControl
          error={getFieldState(`${conditionControl}.expression.value`).error}
          fullWidth
        >
          <InputLabel required id="value-type-label">
            Value
          </InputLabel>
          <Select
            labelId="value-type-label"
            defaultValue={expression.value}
            onChange={(e) => {
              expression.value = e.target.value;
            }}
            label="Value"
            {...register(`${conditionControl}.expression.value`, {
              required: "This field is required.",
            })}
          >
            <MenuItem value="true">Checked</MenuItem>
            <MenuItem value="false">Unchecked</MenuItem>
          </Select>
        </FormControl>
      );
    }
    if (operand1?.type === "dropdown-list") {
      return (
        <FormControl
          error={getFieldState(`${conditionControl}.expression.value`).error}
          fullWidth
        >
          <InputLabel id="dropdownOperand2" required>
            Options
          </InputLabel>
          <Select
            labelId="dropdownOperand2"
            label="Options"
            multiple={operand1.isMultiselect}
            value={selectedOptions}
            placeholder="Select"
            {...register(`${conditionControl}.expression.value`, {
              required: "This field is required",
              onChange: (event) => {
                const sortedOptions = operand1.properties.options
                  .filter((op) => event.target.value.includes(op.value))
                  .map((op) => op.value);
                setSelectedOption(sortedOptions);
                setValue(
                  `${conditionControl}.expression.value`,
                  sortedOptions,
                  {
                    shouldDirty: true,
                  }
                );
              },
            })}
          >
            {operand1.properties.options.map((option) => (
              <MenuItem value={option.value} key={option.id}>
                <Typography textAlign="left">{option.value}</Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }

    return (
      <Controller
        name={`${conditionControl}.expression.value`}
        control={control}
        rules={{ required: "This field is required." }}
        render={({ field }) => (
          <TextField
            {...field}
            error={getFieldState(`${conditionControl}.expression.value`).error}
            label="Value"
            type="text"
            defaultValue={expression.value}
            InputLabelProps={{ required: true }}
          />
        )}
      />
    );
  };

  const evaluateOperand2 = () => {
    if (
      operatorType === "comparison" &&
      (operand1?.type === "checkbox-field" ||
        operand1?.type === "date-field" ||
        operand1?.type === "number-field")
    ) {
      return form.formPages[currentPageIndex].sections[
        currentSectionIndex
      ].fields.filter((el) => el.type === operand1?.type);
    }
    return form.formPages[currentPageIndex].sections[currentSectionIndex]
      .fields;
  };

  useEffect(() => {
    if (expression.operand1) {
      const operand = form.formPages[currentPageIndex].sections[
        currentSectionIndex
      ].fields.find((el) => el.fieldId === expression.operand1);
      setOperand1(operand);
      const operator = conditionOperators.find(
        (op) => op.value === expression.operator
      );
      setOperatorType(operator.type);
    }
  }, []);

  return (
    <Grid container justifyContent="center" spacing={3}>
      <Grid item md={3}>
        <FormControl
          error={getFieldState(`${conditionControl}.expression.operand1`).error}
          fullWidth
        >
          <InputLabel required id="operand1-label">
            Operand
          </InputLabel>
          <Select
            labelId="operand1-label"
            defaultValue={expression.operand1}
            label="Operand"
            {...register(`${conditionControl}.expression.operand1`, {
              required: "This field is required.",
            })}
          >
            {form.formPages[currentPageIndex].sections[
              currentSectionIndex
            ].fields.map((e) => (
              <MenuItem
                key={e.fieldId}
                value={e.fieldId}
                onClick={() => {
                  setOperand1(e);
                }}
              >
                {e.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={3}>
        <FormControl
          error={getFieldState(`${conditionControl}.expression.operator`).error}
          fullWidth
        >
          <InputLabel required id="operator-label">
            Operator
          </InputLabel>
          <Select
            labelId="operator-label"
            defaultValue={expression.operator}
            label="Operator"
            {...register(`${conditionControl}.expression.operator`, {
              required: "This field is required.",
            })}
          >
            {conditionOperators
              .filter((op) => op.fieldTypes.includes(operand1?.type))
              .map((operator) => (
                <MenuItem
                  key={operator.value}
                  value={operator.value}
                  onClick={() => {
                    setOperatorType(operator.type);
                  }}
                >
                  {operator.text}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={3}>
        <FormControl
          error={
            getFieldState(`${conditionControl}.expression.valueType`).error
          }
          fullWidth
        >
          <InputLabel required id="value-type-label">
            Value Type
          </InputLabel>
          <Select
            labelId="value-type-label"
            defaultValue={expression.valueType}
            onChange={(e) => {
              expression.valueType = e.target.value;
            }}
            label="Value Type"
            {...register(`${conditionControl}.expression.valueType`, {
              required: "This field is required.",
              onChange: (e) => {
                setValueType(e.target.value);
              },
            })}
          >
            <MenuItem value={ValueType.FIELD}>Field</MenuItem>
            <MenuItem value={ValueType.FIXED}>Fixed</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {valueType === 0 && (
        <Grid item md={3}>
          <FormControl
            error={
              getFieldState(`${conditionControl}.expression.operand2`).error
            }
            fullWidth
          >
            <InputLabel required id="operand2-label">
              Operand
            </InputLabel>
            <Select
              labelId="operand2-label"
              defaultValue={expression.operand2}
              label="Operand"
              {...register(`${conditionControl}.expression.operand2`, {
                required: "This field is required.",
              })}
            >
              {evaluateOperand2().map((e) => (
                <MenuItem key={e.fieldId} value={e.fieldId}>
                  {e.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      {valueType === 1 && (
        <Grid item md={3}>
          {evaluateFixedInput()}
        </Grid>
      )}
    </Grid>
  );
}

export default Condition;
