import React from "react";
import { useTranslation } from "react-i18next";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Box, Typography, Button, Chip } from "@mui/material";
import { recipientsTitle } from "./Recipients.styles";
import { contentTitleContainer } from "./Content.styles";
import {
  attachmentsContainer,
  attachmentsInfoContainer,
  attachmentBtnLabel,
} from "./Attachments.styles";
import { messagesAttachIcon } from "../../../views/Messages.styles";
import { my5 } from "../../sharedStyles";

export default function Attachments({
  handleFileUploads,
  uploadedFiles,
  setUploadedFiles,
}) {
  const { t } = useTranslation("messages");

  const handleDeleteFile = (objId) => {
    setUploadedFiles(uploadedFiles.filter((file) => file.id !== objId));
  };

  return (
    <Box sx={attachmentsContainer}>
      <Box sx={contentTitleContainer}>
        <Typography sx={recipientsTitle}>{t("attachments")}</Typography>
      </Box>
      <Box>
        <Button
          variant="text"
          component="label"
          startIcon={<AttachFileIcon sx={messagesAttachIcon} />}
        >
          <Typography sx={attachmentBtnLabel}>{t("addFile")}</Typography>
          <input
            hidden
            accept=".jpeg, .jpg, .pdf, .png, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .zip"
            type="file"
            multiple
            onChange={handleFileUploads}
          />
        </Button>

        <Box>
          {uploadedFiles &&
            uploadedFiles.map((file) => (
              <Box sx={my5} key={file.id}>
                <Chip
                  label={file.name}
                  onDelete={() => handleDeleteFile(file.id)}
                />
              </Box>
            ))}
        </Box>

        <Box sx={attachmentsInfoContainer}>
          <Typography>{t("attachmentsInfoContainer")}</Typography>
        </Box>
      </Box>
    </Box>
  );
}
