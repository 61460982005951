import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { useDrag } from "react-dnd";
import StageElementType from "../Models/constants";
import admissionProcessService from "../../../../../service/admissionProcessService";

export default function FormTab() {
  const [searchParams] = useSearchParams();
  const [forms, setForms] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await admissionProcessService.fetchFormElements(
        searchParams.get("school_year")
      );
      setForms(response.data);
    })();
  }, []);
  return forms.map((form) => (
    <Box width="100%" display="block">
      <Typography textTransform="none" color="#000000">
        <DraggableField form={form} key={form.id} />
      </Typography>
    </Box>
  ));
}

function DraggableField({ form }) {
  const [{ isDragging }, dragRef] = useDrag(() => ({
    type: "stage-element-type",
    item: {
      type: StageElementType.FORM,
      formId: form.id,
      id: null,
      name: form.name,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <Typography
      ref={dragRef}
      variant="body2"
      pt={1}
      sx={{ opacity: isDragging ? 0.5 : 1, cursor: "pointer" }}
    >
      {form.name}
    </Typography>
  );
}
