import React, { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Box, Stack, Button, Typography } from "@mui/material";
import {
  formHeader,
  formButtons,
  formTitle,
  formContainer,
  font20,
  font36,
  mt25,
  mb45,
  danger,
} from "../../../sharedStyles";
import ErasableTextField from "../../../ErasableTextField";
import InstructionalLevel from "./InstructionalLevel";
import Calculations from "./Calculations";
import OverrideGrade from "./OverrideGrade";
import termGradeDetailKlass from "./TermGradeDetail.styles";
import GenericSubmitButton from "../../../GenericSubmitButton";

export default function TermGradeDetail({
  handleTermGradeDrawerClose,
  activeGrade,
  specialMarks,
  handleTermGradesPost,
  setOverrideError,
  overrideError,
  commentError,
  setCommentError,
}) {
  const methods = useForm();

  const onSubmit = async (data) => {
    const isOverridden = !data.overridden || !overrideError;
    handleTermGradesPost(data, activeGrade?.termGrade?.id);
    if (isOverridden && !overrideError && !commentError) {
      handleTermGradeDrawerClose();
    }
  };

  const gradeLevel = Number(activeGrade?.termGrade?.student?.grade_level);
  const needsInstructionalLevel =
    activeGrade?.termGrade?.klass?.subject?.needs_instructional_level;

  const studentName = `${activeGrade?.termGrade?.student?.last_name}, ${activeGrade?.termGrade?.student?.first_name}`;

  useEffect(() => {
    if (methods.watch("comment")?.length > 500) {
      setCommentError(true);
    } else {
      setCommentError(false);
    }
  }, [methods.watch("comment")]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack direction="row" sx={formHeader}>
          <Typography sx={formTitle}>
            {activeGrade?.termGrade?.term?.name}
          </Typography>
          <Stack direction="row">
            <Button
              sx={formButtons}
              onClick={() => handleTermGradeDrawerClose()}
            >
              Cancel
            </Button>
            {!activeGrade?.readOnly && (
              <GenericSubmitButton text="Post" submittingText="Posting..." />
            )}
          </Stack>
        </Stack>

        <Box sx={formContainer}>
          <Box sx={termGradeDetailKlass}>
            <Typography sx={font20}>
              {activeGrade?.termGrade?.klass?.subject?.name}
            </Typography>
          </Box>
          <Box sx={mb45}>
            <Typography sx={font36}>{studentName}</Typography>
            <Box sx={mt25}>
              <ErasableTextField
                id="comment"
                label="Report Card Comment"
                fullWidth
                type="text"
                isMultiline
                lines={4}
                disabled={activeGrade?.readOnly}
                defaultValue={activeGrade?.termGrade?.comment || ""}
              />
            </Box>

            {methods.watch("comment")?.length > 500 && (
              <Box>
                <Typography sx={danger}>
                  Comment is too long. (Max. 500 characters)
                </Typography>
              </Box>
            )}
          </Box>
          {needsInstructionalLevel && gradeLevel >= 1 && gradeLevel <= 8 && (
            <InstructionalLevel activeGrade={activeGrade} />
          )}
          <Calculations activeGrade={activeGrade} specialMarks={specialMarks} />
          <OverrideGrade
            activeGrade={activeGrade}
            specialMarks={specialMarks}
            setOverrideError={setOverrideError}
            overrideError={overrideError}
          />
        </Box>
      </form>
    </FormProvider>
  );
}
