import axios from "axios";
import { storedToken } from "./constants/auth";

const axiosClient = axios.create({
  headers: {
    // eslint-disable-next-line no-undef
    baseUrl: settings.API_URL,
    Authorization: storedToken,
    "Content-type": "application/json",
  },
});

export default axiosClient;
