const staffTableBox = {
  width: "100%",
  position: "relative",
};

const staffTablePaper = { width: "100%", py: "10px" };

const staffTableHeaderCell = (isCompact) => ({
  cursor: "pointer",
  px: "10px",
  width: "1%",
  whiteSpace: "no-wrap",
  ...(isCompact && {
    py: "10px",
    pr: "20px",
  }),
});

const staffTableCellWide = (isCompact, cursor) => ({
  cursor: cursor ? "pointer" : "default",
  width: "400px",
  textAlign: "left",
  ...(isCompact && {
    py: "10px",
  }),
});

const staffTableSpan = (isCompact) => ({
  fontSize: 20,
  color: "#000000",
  ...(isCompact && {
    fontWeight: 200,
  }),
});

const staffTableCell = {
  width: "150px",
  padding: "23px 0",
  textAlign: "left",
  fontSize: 16,
  color: "black",
};

const staffTableCellTeacher = {
  ...staffTableCell,
  width: "200px",
};

const staffTableHeadCell = (sortable) => ({
  display: "flex",
  alignItems: "center",
  maxWidth: "max-content",
  fontSize: 14,
  color: "#808080",
  cursor: sortable ? "pointer" : "inherit",
  borderBottom: "none",
});

const staffTableHeadCellActive = (sortable) => ({
  ...staffTableHeadCell(sortable),
  color: "black",
});

const setupStaff = {
  color: "white",
  backgroundColor: "#ED6C02 !important",
  paddingX: "1.5rem",
  borderRadius: "0.4rem",
  textTransform: "capitalize",
  fontSize: 12,
};

export {
  staffTableBox,
  staffTablePaper,
  staffTableHeaderCell,
  staffTableCellWide,
  staffTableSpan,
  staffTableCell,
  staffTableHeadCell,
  staffTableHeadCellActive,
  staffTableCellTeacher,
  setupStaff,
};
