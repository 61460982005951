import React from "react";
import { Snackbar } from "@mui/material";
import Alert from "@mui/material/Alert";

export default function SnackBarNotification({
  message,
  open,
  handleClose,
  severity = "success",
}) {
  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
}
