const klassFormDivider = {
  borderBottom: "1px solid #BFC7CA",
  py: "25px",
};

const klassFormTitle = {
  fontSize: "28px",
};

const klassFormRow = {
  mb: "20px",
};

const klassFormAttendancePointSelect = {
  width: "150px",
};

const gradeAttendanceInput = {
  width: "70px",
  mx: "20px",
};

const gradeAttendanceLabel = {
  width: "161px",
};

export {
  klassFormDivider,
  klassFormTitle,
  klassFormRow,
  klassFormAttendancePointSelect,
  gradeAttendanceInput,
  gradeAttendanceLabel,
};
