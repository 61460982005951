import axios from "../utils/axios-util";

const emergencyContactsEndpoint = "/api/staff_emergency_contacts";

const fetchAllContacts = async (teacherId) => {
  const { data } = await axios.get(
    `${emergencyContactsEndpoint}?teacher_id=${teacherId}`
  );
  if (!data.data) {
    console.error("Error fetching events from the API");
    return [];
  }
  return data;
};

const createEmergencyContact = async (teacherId, params) => {
  const { data } = await axios.post(
    `${emergencyContactsEndpoint}?teacher_id=${teacherId}`,
    params
  );
  if (!data.data) {
    console.error("Error fetching event from the API");
    return [];
  }
  return data;
};

const getEmergencyContact = async (teacherId, emergencyContactId) => {
  const { data } = await axios.get(
    `${emergencyContactsEndpoint}/${emergencyContactId}?teacher_id=${teacherId}`
  );
  if (!data.data) {
    console.error("Error fetching event from the API");
    return [];
  }
  return data;
};

const updateEmergencyContact = async (
  teacherId,
  emergencyContactId,
  params
) => {
  const { data } = await axios.patch(
    `${emergencyContactsEndpoint}/${emergencyContactId}?teacher_id=${teacherId}`,
    params
  );

  if (!data.data) {
    console.error("Error fetching event from the API");
    return [];
  }
  return data;
};

const deleteEmergencyContact = async (teacherId, emergencyContactId) => {
  const { data } = await axios.delete(
    `${emergencyContactsEndpoint}/${emergencyContactId}?teacher_id=${teacherId}`
  );
  if (!data.data) {
    console.error("Error fetching event from the API");
    return [];
  }
  return data;
};

export default {
  fetchAllContacts,
  createEmergencyContact,
  getEmergencyContact,
  updateEmergencyContact,
  deleteEmergencyContact,
};
