const classSchedulesFilterContainer = {
  justifyContent: "space-between",
  alignItems: "center",
  mt: "5px",
  pb: "7px",
  borderBottom: "1px solid black",
};

const dayTemplateWidth = {
  width: "100%",
  marginTop: 1,
};

const boxWidth = { width: "50%" };
const select = { width: "70%" };

export { classSchedulesFilterContainer, dayTemplateWidth, boxWidth, select };
