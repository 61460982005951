import React from "react";
import { Box, Typography, IconButton, Stack } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  gDSContainer,
  navigationButtons,
  currentDateTypography,
  gDSContainerInner,
} from "./GradebookDaySelect.styles";
import { font40, daySelectButton } from "../../sharedStyles";
import moment from "../../../utils/constants/momentConfig";
import dateTimeFormats from "../../../utils/constants/dateTimeFormats";

export default function GradebookDaySelect({ searchParams, setSearchParams }) {
  const directions = { back: 1, forward: 2 };

  const currentDate =
    searchParams.get("date") !== null
      ? moment(searchParams.get("date"))
      : moment();
  const lastSunday = currentDate.startOf("week").format(dateTimeFormats.MMMD);
  const nextSaturday = currentDate
    .endOf("week")
    .format(dateTimeFormats.MMMDYYYY);

  const handleDayChange = (direction) => {
    if (direction === directions.back) {
      searchParams.set(
        "date",
        currentDate.add(-7, "d").format(dateTimeFormats.YYYYMMDD)
      );
    } else if (direction === directions.forward) {
      searchParams.set(
        "date",
        currentDate.add(7, "d").format(dateTimeFormats.YYYYMMDD)
      );
    }
    setSearchParams(searchParams);
  };
  return (
    <Stack sx={gDSContainer} direction="row">
      <Box sx={gDSContainerInner}>
        <Box>
          <Typography
            sx={currentDateTypography}
          >{`${lastSunday} - ${nextSaturday}`}</Typography>
        </Box>
        <Stack direction="row">
          <Box sx={navigationButtons}>
            <IconButton
              disableRipple
              sx={daySelectButton}
              onClick={() => handleDayChange(directions.back)}
            >
              <KeyboardArrowLeftIcon sx={font40} color="primary" />
            </IconButton>
            <IconButton
              disableRipple
              sx={daySelectButton}
              onClick={() => handleDayChange(directions.forward)}
            >
              <KeyboardArrowRightIcon sx={font40} color="primary" />
            </IconButton>
          </Box>
        </Stack>
      </Box>
    </Stack>
  );
}
