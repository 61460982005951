/* eslint-disable no-param-reassign */
import { Box, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { renderError } from "../../../../../utils/constants/forms";

export default function NumberRenderer({ field, properties, dataView }) {
  const {
    register,
    formState: { errors, isSubmitted },
    trigger,
    setValue,
  } = useFormContext();

  useEffect(() => {
    if (isSubmitted) trigger(field.fieldId);
  }, [field.isValid]);

  function createDecimalRegex(decimalPlaces) {
    if (decimalPlaces && Number(decimalPlaces) !== 0) {
      return new RegExp(`^-?\\d+(\\.\\d{0,${decimalPlaces}})?$`);
    }
    return /^\d+$/;
  }

  return (
    <Box padding={1}>
      <TextField
        fullWidth
        error={!!errors[field.fieldId]}
        label={field.label}
        key={field.fieldId}
        defaultValue={field.response || ""}
        InputLabelProps={{
          required: properties.isRequired,
          shrink: properties.shrink || undefined,
        }}
        type="number"
        helperText={properties.supportingText}
        InputProps={{
          readOnly: dataView,
          onInput:
            "this.value = this.value.replace(/[^0-9.]/g, '').replace(/(..*)./g, '$1');",
        }}
        {...register(field.fieldId, {
          required: properties.isRequired && "This field is required.",
          pattern: {
            value: properties.isDecimal
              ? createDecimalRegex(properties.decimalPlaces)
              : /^\d+$/,
            message: properties.isDecimal
              ? `Only ${properties.decimalPlaces} decimal places is allowed`
              : "Only integers is allowed",
          },
          min: {
            value: properties.minValue || null,
            message: `${field.label} must be at least ${properties.minValue}`,
          },
          max: {
            value: properties.maxValue || null,
            message: `${field.label} must not exceed ${properties.maxValue}`,
          },
          onChange: (e) => {
            const numberValue =
              e.target.value === "" ? "" : Number(e.target.value);
            setValue(field.fieldId, numberValue);
            field.response = numberValue;
          },
          validate: field.isValid
            ? () => true
            : () => properties.validityMessage || "Invalid input",
        })}
      />
      {errors[field.fieldId] && renderError(errors[field.fieldId].message)}
    </Box>
  );
}
