const divider = { borderColor: "black" };

const subjectsListTable = {
  color: "black",
};

const subjectsListHead = (isCentered, isActive = false) => ({
  borderBottom: "none",
  color: isActive ? "black" : "#808080",
  cursor: "pointer",
  padding: 0,
  "& p": {
    fontSize: "14px",
    display: "flex",
    alignItems: "end",
    justifyContent: isCentered ? "center" : "flex-start",
  },
});

const subjectsListCell = (isCentered = false) => ({
  padding: 2,
  color: "black",
  paddingLeft: isCentered ? 2 : 0,
});

const subjectsListCellName = {
  ...subjectsListCell(),
  "& p": {
    fontSize: "20px",
  },
};

const subjectsListName = {
  width: "150px",
};

const tableContainer = { mx: "65px" };

const hidden = { visibility: "hidden" };

export {
  divider,
  subjectsListTable,
  subjectsListHead,
  subjectsListCell,
  subjectsListName,
  subjectsListCellName,
  tableContainer,
  hidden,
};
