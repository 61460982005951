import axios from "../utils/axios-util";

const termsEndpoint = "/api/klass_terms";

const fetchAll = async (params) => {
  const { data } = await axios.get(termsEndpoint, params);

  if (!data.data) {
    console.error("Error fetching terms from the API");
    return [];
  }
  return data;
};

export default {
  fetchAll,
};
