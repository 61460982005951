import React, { useEffect, useState } from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
import { Box, Grid, Typography, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { font28 } from "../sharedStyles";
import { klassFormDivider } from "./KlassForm.styles";
import { buttons } from "./KlassScheduleInfo.styles";
import KlassScheduleDetail from "./KlassScheduleDetail";
import { renderError } from "../../utils/constants/forms";

export default function KlassScheduleInfo({
  classSchedule,
  dayTemplates,
  activeKlass,
}) {
  const {
    control,
    register,
    getValues,
    setValue,
    setError,
    formState: { errors, isSubmitting },
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "klass_schedule_details",
  });
  const [selectedPeriods, setSelectedPeriods] = useState([]);

  useEffect(() => {
    setValue("klass_schedule", classSchedule?.id);
  }, [classSchedule]);

  useEffect(() => {
    if (isSubmitting && getValues("klass_schedule_details").length === 0) {
      setError("klass_schedule_details", {
        type: "custom",
        message: "Please, add at least one class schedule.",
      });
    }
  }, [isSubmitting]);

  useEffect(() => {}, [selectedPeriods]);

  return (
    <Box sx={klassFormDivider}>
      <Grid container spacing={2} my={2}>
        <Grid
          container
          item
          xs={12}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography sx={font28}>Class Schedule</Typography>
            {errors?.klass_schedule_details &&
              renderError(errors?.klass_schedule_details.message)}
          </Grid>
        </Grid>
      </Grid>
      {fields.map((field, index) => (
        <KlassScheduleDetail
          key={field.id}
          dayTemplates={dayTemplates}
          index={index}
          field={field}
          remove={remove}
          errors={errors}
          register={register}
          getValues={getValues}
          setValue={setValue}
          activeKlass={activeKlass}
          classSchedule={classSchedule}
          setSelectedPeriods={setSelectedPeriods}
        />
      ))}
      <Grid container>
        <Grid item xs={12} ml={1}>
          <Button
            sx={buttons}
            onClick={() => append({ day_template: "", period: "", track: "" })}
            startIcon={<AddIcon />}
          >
            Add
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
