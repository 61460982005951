import { Typography } from "@mui/material";
import React from "react";
import { useDrag } from "react-dnd";
import StageElementType from "../Models/constants";

export default function FilesTab() {
  const [{ isDragging }, dragRef] = useDrag(() => ({
    type: "stage-element-type",
    item: {
      type: StageElementType.FILE,
      name: "File Request",
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <Typography
      ref={dragRef}
      sx={{ opacity: isDragging ? 0.5 : 1, cursor: "pointer" }}
    >
      File Request
    </Typography>
  );
}
