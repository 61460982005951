import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { title, divider, tableStyles } from "./Profile.styles";
import { formatPhoneNumber } from "../../../../utils/constants/utils";
import { pt30, textCenter } from "../../../sharedStyles";

export default function GuardianSection({ guardians }) {
  const { t } = useTranslation("guardian", { keyPrefix: "studentProfile" });

  return (
    <>
      <hr style={divider} />
      <Typography sx={title}>{t("parentsGuardians")}</Typography>
      {guardians.length > 0 ? (
        <TableContainer sx={pt30}>
          <Table sx={tableStyles} aria-label="guardian table">
            <TableHead>
              <TableRow>
                <TableCell>{t("name")}</TableCell>
                <TableCell>{t("relationship")}</TableCell>
                <TableCell>{t("address")}</TableCell>
                <TableCell>{t("email")}</TableCell>
                <TableCell>{t("phone")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {guardians.map((guardian) => (
                <TableRow key={guardian.id} hover>
                  <TableCell component="th" scope="guardian">
                    <Typography>
                      {`${guardian.first_name} ${guardian.last_name}`}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{guardian.relationship}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {guardian.mailing_address?.address_line1 &&
                        `${guardian.mailing_address?.address_line1},`}{" "}
                      {guardian.mailing_address?.address_line2 &&
                        `${guardian.mailing_address?.address_line2},`}{" "}
                      {guardian.mailing_address?.city &&
                        `${guardian.mailing_address?.city},`}{" "}
                      {guardian.mailing_address?.state?.abbreviation}{" "}
                      {guardian.mailing_address?.postal_code}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{guardian.email}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {formatPhoneNumber(guardian.cell_phone)}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography sx={[textCenter, pt30]}>{t("noGuardiansFound")}</Typography>
      )}
    </>
  );
}
