const gradebookInner = {
  position: "relative",
};

const gradeBookDaySelectWidth = {
  width: "100%",
};

const boxWidth = { width: "50%" };

const gradebookEmptyWeek = {
  height: "500px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "black",
};

export {
  gradebookEmptyWeek,
  gradebookInner,
  boxWidth,
  gradeBookDaySelectWidth,
};
