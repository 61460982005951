import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import tableStyles from "./ParentContacts.style";

export default function ParentContactsTable({ parentContacts }) {
  return (
    <Box sx={tableStyles}>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Student</TableCell>
              <TableCell align="left">Parent</TableCell>
              <TableCell align="left">Phone</TableCell>
              <TableCell align="left">Email</TableCell>
              <TableCell align="left">Address</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {parentContacts.map((parentContact) => (
              <TableRow key={parentContact.id} hover>
                <TableCell align="left" scope="student">
                  {parentContact.student}
                </TableCell>
                <TableCell align="left">{parentContact.parent}</TableCell>
                <TableCell align="left">{parentContact.phone}</TableCell>
                <TableCell align="left">
                  <div className="email-container">{parentContact.email}</div>
                </TableCell>
                <TableCell align="left">
                  {parentContact.address.address_line1 && (
                    <>
                      {parentContact.address.address_line1}
                      <br />
                      {parentContact.address.address_line2 && (
                        <>
                          {parentContact.address.address_line2}
                          <br />
                        </>
                      )}
                      {parentContact.address.city},{" "}
                      {parentContact.address.state}{" "}
                      {parentContact.address.postal_code}
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
