import axios from "../utils/axios-util";

const preKDevelopmentEndpoint = "/api/prek_development";

const fetchPrekDomains = async () => {
  const { data } = await axios.get(`${preKDevelopmentEndpoint}/domains`);
  return data;
};

const fetchPrekDomainElements = async (domainId) => {
  const { data } = await axios.get(
    `${preKDevelopmentEndpoint}/domain_elements`,
    {
      params: {
        domain_id: domainId,
      },
    }
  );
  return data;
};

const fetchStudentsComments = async (domainId, termId) => {
  const { data } = await axios.get(
    `${preKDevelopmentEndpoint}/students_comments`,
    {
      params: {
        domain_id: domainId,
        term_id: termId,
      },
    }
  );
  return data;
};

const fetchStudentsGrades = async (domainId, termId, order) => {
  const { data } = await axios.get(
    `${preKDevelopmentEndpoint}/students_grades`,
    {
      params: {
        domain_id: domainId,
        term_id: termId,
        order,
      },
    }
  );
  return data;
};

const updateStudentDomainComment = async (rowId, comment) =>
  // eslint-disable-next-line no-return-await
  await axios.put(`${preKDevelopmentEndpoint}/update_student_domain_comment`, {
    row_id: rowId,
    comment,
  });

const updateStudentDomainElementGrade = async (elementIds, grade) =>
  // eslint-disable-next-line no-return-await
  await axios.put(
    `${preKDevelopmentEndpoint}/update_student_domain_element_grade`,
    {
      element_ids: elementIds,
      grade,
    }
  );

const postTermGrades = async (ids) =>
  // eslint-disable-next-line no-return-await
  await axios.put(`${preKDevelopmentEndpoint}/post_term_grades`, { ids });

// eslint-disable-next-line camelcase
const fetchStudents = async (
  termId,
  teacherFilterActive,
  teacherId,
  rcStatus
) => {
  const { data } = await axios.get(`${preKDevelopmentEndpoint}/students`, {
    params: {
      term_id: termId,
      rc_status: rcStatus,
      ...(teacherFilterActive && { teacher_id: teacherId }),
    },
  });
  return data;
};

const fetchStudentsPrekDomainElements = async (termId, student, domain) => {
  const { data } = await axios.get(
    `${preKDevelopmentEndpoint}/students_prek_domain_elements`,
    {
      params: {
        term_id: termId,
        domain_id: domain.prek_development_domain.id,
        student_id: student.id,
      },
    }
  );
  return data;
};

export default {
  fetchPrekDomains,
  fetchPrekDomainElements,
  fetchStudentsComments,
  fetchStudentsGrades,
  updateStudentDomainComment,
  updateStudentDomainElementGrade,
  postTermGrades,
  fetchStudents,
  fetchStudentsPrekDomainElements,
};
