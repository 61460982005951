import axios from "../utils/axios-util";

const specialMarksEndpoint = "/api/special_marks";

const fetchSchoolSpecialMarks = async (params) => {
  const { data } = await axios.get(`${specialMarksEndpoint}`, params);
  if (!data.data) {
    console.error("Error fetching special marks from API");
    return [];
  }
  return data;
};

export default {
  fetchSchoolSpecialMarks,
};
