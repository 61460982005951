const conversationAttachment = {
  ml: "10px",
  color: "#5077A4",
  maxWidth: "250px",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
  display: "inline-block",
  verticalAlign: "middle",
  cursor: "pointer",
};

export default conversationAttachment;
