import React from "react";
import { useFormContext } from "react-hook-form";
import {
  Grid,
  Typography,
  Box,
  Stack,
  Checkbox,
  FormControlLabel,
  FormControl,
} from "@mui/material";
import { font28, mb25 } from "../../../../sharedStyles";
import {
  optionsCheckBox,
  optionsInputContainer,
  optionsRow,
} from "./EmergencyContactForm.styles";
import ErasableTextField from "../../../../ErasableTextField";
import OptionsCheckbox from "./OptionsCheckbox";
import { renderError } from "../../../../../utils/constants/forms";

export default function Options({ currentContact }) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Grid item sm={12} sx={mb25}>
        <Typography sx={font28}>Options</Typography>
      </Grid>
      <Box sx={optionsRow}>
        <Stack direction="row" sx={optionsCheckBox}>
          <OptionsCheckbox
            title="Authorized Pick-up"
            label="is_authorized_pickup"
            currentContact={currentContact}
          />
        </Stack>

        <Box sx={optionsInputContainer}>
          <ErasableTextField
            id="vehicle_license"
            label="Vehicle License"
            fullWidth
            defaultValue={currentContact?.vehicle_license}
            hasError={errors?.vehicle_license}
          />

          {errors?.vehicle_license &&
            renderError(errors?.vehicle_license.message)}
        </Box>
      </Box>

      <Box>
        <Stack direction="row" sx={optionsCheckBox}>
          <OptionsCheckbox
            title="School Transportation"
            label="school_transportation"
            currentContact={currentContact}
          />
        </Stack>

        <Box sx={optionsInputContainer}>
          <FormControl fullWidth>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={currentContact?.background_check || false}
                  {...register("background_check", {})}
                />
              }
              label={<Typography>Background check</Typography>}
            />
          </FormControl>
        </Box>
      </Box>
    </>
  );
}
