import { Button, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { cancelButton, submitButton } from "../FormBuilder/FormBuilder.style";
import AdmissionProcessContainer from "./AdmissionProcessContainer";
import ProcessFieldSet from "./ProcessFieldSet/ProcessFieldSet";
import ProcessStageProperties from "./ProcessStageProperties/ProcessStageProperties";
import ProcessTaskProperties from "./ProcessTaskProperties/ProcessTaskProperties";
import ProcessFileRequestProperties from "./ProcessFileRequestProperties/ProcessFileRequestProperties";
import { ProcessBuilderContext } from "./ProcessBuilderContext";
import AdmissionProcess from "./Models/AdmissionProcess";
import StageElementType from "./Models/constants";
import admissionProcessService from "../../../../service/admissionProcessService";
import { SnackbarContext } from "../../../../context/SnackbarContext";
import ProcessFormProperties from "./ProcessFormProperties/ProcessFormProperties";
import ProcessFeeProperties from "./ProcessFeeProperties/ProcessFeeProperties";

export default function AdmissionProcessBuilder() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { process, updateProcess, selectedElement, selectElement } = useContext(
    ProcessBuilderContext
  );

  const [processSaving, setFormSaving] = useState(false);
  const snackbarContext = useContext(SnackbarContext);

  const handleKeyDown = (event) => {
    if (event.ctrlKey || event.metaKey) {
      switch (event.key) {
        // Add cases for other shortcuts you want to block
        case "b": // Ctrl+B or Command+B
        case "i": // Ctrl+I or Command+I
        case "u": // Ctrl+U or Command+U
          event.preventDefault();
          break;
        default:
      }
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    // Get text from clipboard
    const text = (event.clipboardData || window.clipboardData).getData(
      "text/plain"
    );
    // Insert text at current cursor position
    document.execCommand("insertText", false, text);
  };

  const sortStageAndElements = (stages) =>
    stages
      .sort((a, b) => a.order - b.order)
      .map((stage) => {
        // eslint-disable-next-line no-param-reassign
        stage.stage_elements = stage.stage_elements.sort(
          (a, b) => a.properties.order - b.properties.order
        );
        return stage;
      });

  const saveProcess = async () => {
    setFormSaving(true);
    const transformedData = new AdmissionProcess(process).serialize();
    if (!process.id) {
      admissionProcessService
        .create(transformedData, searchParams.get("school_year"))
        .then((response) => {
          setFormSaving(false);
          const updatedData = response.data;
          updatedData.admission_process_stages = sortStageAndElements(
            updatedData.admission_process_stages
          );
          const admissionProcess = new AdmissionProcess(updatedData);
          updateProcess(admissionProcess);
          selectElement(admissionProcess.stages[0]);
          searchParams.set("process_id", updatedData.id);
          setSearchParams(searchParams);
          snackbarContext.setSnackbar({
            message: "Process saved.",
            severity: "success",
            open: true,
          });
        })
        .catch(() => {
          setFormSaving(false);
          snackbarContext.setSnackbar({
            message: "Failed to save process.",
            severity: "error",
            open: true,
          });
        });
    } else {
      admissionProcessService
        .updateProcess(transformedData)
        .then((response) => {
          setFormSaving(false);
          const updatedData = response.data;
          updatedData.admission_process_stages = sortStageAndElements(
            updatedData.admission_process_stages
          );
          const admissionProcess = new AdmissionProcess(updatedData);
          updateProcess(admissionProcess);
          selectElement(admissionProcess.stages[0]);
          snackbarContext.setSnackbar({
            message: "Process saved.",
            severity: "success",
            open: true,
          });
        })
        .catch(() => {
          setFormSaving(false);
          snackbarContext.setSnackbar({
            message: "Failed to save process.",
            severity: "error",
            open: true,
          });
        });
    }
  };

  const updateProcessName = (e) => {
    const processUpdate = { ...process };
    processUpdate.name = e.target.textContent;
    updateProcess(processUpdate);
  };

  const updateProcessDescription = (e) => {
    const processUpdate = { ...process };
    processUpdate.description = e.target.textContent;
    updateProcess(processUpdate);
  };

  useEffect(() => {
    const processId = searchParams.get("process_id");
    if (processId)
      (async () => {
        const response = await admissionProcessService.fetchProcess(processId);
        const updatedData = response.data;
        updatedData.admission_process_stages = sortStageAndElements(
          updatedData.admission_process_stages
        );
        const admissionProcess = new AdmissionProcess(updatedData);
        updateProcess(admissionProcess);
        selectElement(admissionProcess.stages[0]);
      })();
    else {
      const admissionProcess = new AdmissionProcess();
      updateProcess(admissionProcess);
      selectElement(admissionProcess.stages[0]);
    }
  }, []);

  return (
    process && (
      <Grid container pr={1}>
        <Grid container item md={12} py={2}>
          <Grid item md={3} />
          <Grid container item md={5.65} justifyContent="center">
            <Grid item md={6}>
              <Typography
                contentEditable
                onBlur={updateProcessName}
                onKeyDown={handleKeyDown}
                onPaste={handlePaste}
                fontSize={24}
                color="black"
                textAlign="center"
              >
                {process.name}
              </Typography>
            </Grid>
          </Grid>
          <Grid item md={3.35} container justifyContent="end">
            <Button variant="contained" sx={cancelButton}>
              Cancel
            </Button>
            <Button
              onClick={saveProcess}
              variant="contained"
              sx={submitButton}
              disabled={processSaving}
            >
              {processSaving ? "Saving..." : "Save"}
            </Button>
          </Grid>
        </Grid>
        <Grid item md={12} container>
          <Grid item md={3} sx={{ borderRight: "1px solid black" }}>
            <ProcessFieldSet />
          </Grid>
          <Grid item md={9} container>
            <Grid item md={7.5} container justifyContent="center">
              <Typography
                width="70%"
                textAlign="center"
                contentEditable
                onBlur={updateProcessDescription}
                onKeyDown={handleKeyDown}
                onPaste={handlePaste}
              >
                {process.description || "Add description"}
              </Typography>
            </Grid>
            <Grid item md={7.5} mt={3}>
              <AdmissionProcessContainer />
            </Grid>
            <Grid item md={4.5} mt={3}>
              {!selectedElement?.type && <ProcessStageProperties />}
              {selectedElement?.type === StageElementType.TASK && (
                <ProcessTaskProperties />
              )}
              {selectedElement?.type === StageElementType.FILE && (
                <ProcessFileRequestProperties />
              )}
              {selectedElement?.type === StageElementType.FORM && (
                <ProcessFormProperties />
              )}
              {selectedElement?.type === StageElementType.FEE && (
                <ProcessFeeProperties />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  );
}
