import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";

export default function RadioButtonList({ field }) {
  const { control } = useForm();
  return (
    <Box>
      <FormControl
        sx={{ minWidth: "fit-content" }}
        required={field.properties.isRequired}
        fullWidth
      >
        <FormLabel>{field.label}</FormLabel>
        <Controller
          name="radio_button_list"
          defaultValue=""
          control={control}
          rules={{
            required: field.properties.isRequired,
          }}
          render={({ field1 }) => (
            <RadioGroup row {...field1}>
              {field.properties.options.map((option) => (
                <FormControlLabel
                  value={option.value}
                  control={<Radio readOnly />}
                  sx={{ alignItems: "start" }}
                  label={
                    <Box mt={1}>
                      <Typography>{option.value}</Typography>
                    </Box>
                  }
                />
              ))}
            </RadioGroup>
          )}
        />
        <FormHelperText>{field.properties.supportingText}</FormHelperText>
      </FormControl>
    </Box>
  );
}
