import React, { useContext, useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import FormRenderer from "../../Setup/FormRenderer/FormRenderer";
import admissionFormService from "../../../../service/admissionFormService";
import { FormBuilderContext } from "../../Setup/FormBuilder/FormBuilderContext";
import { Form } from "../../Setup/FormBuilder/Models/Form";
import { sortSectionsAndFields } from "../../Setup/FormBuilder/FormBuilderUtils";

export default function DataView() {
  const params = useParams();
  const [studentId, setStudentId] = useState();
  const [searchParams] = useSearchParams();
  const elementInstanceId = searchParams.get("element_instance_id");
  const processId = searchParams.get("process_id");
  const formId = searchParams.get("form_id");
  const schoolYearId = searchParams.get("school_year");
  const formResponseId = searchParams.get("form_response_id");
  const formBuilderContext = useContext(FormBuilderContext);
  const { form, updateForm } = formBuilderContext;
  useEffect(() => {
    admissionFormService
      .getFormRender(formId, elementInstanceId, formResponseId, true)
      .then((response) => {
        const formObj = response.data;
        formObj.admission_form_pages = formObj.admission_form_pages.map(
          (page) => {
            const updatedPage = page;
            updatedPage.form_sections = sortSectionsAndFields(
              page.form_sections
            );
            return updatedPage;
          }
        );

        updateForm(new Form(formObj));
        setStudentId(formObj.student_id);
      });
  }, []);

  const formattedParams = () => {
    let paramsUrl = "?";

    if (schoolYearId) {
      paramsUrl = paramsUrl.concat(`&school_year=${schoolYearId}`);
    }

    if (studentId) {
      paramsUrl = paramsUrl.concat(`&student_id=${studentId}`);
    }

    if (processId) {
      paramsUrl = paramsUrl.concat(`&process_id=${processId}`);
    }

    return paramsUrl;
  };

  const getReturnUrl = () => {
    if (processId) {
      return `/school/${
        params.school_id
      }/admissions/student-details/process-overview${formattedParams()}`;
    }
    return `/school/${
      params.school_id
    }/admissions/students/inquiries?${formattedParams()}`;
  };

  const getReturnPrompt = () => {
    if (processId) {
      return "Process View";
    }
    return "Students";
  };

  return (
    form && (
      <FormRenderer
        studentId={studentId}
        dataView
        returnUrl={getReturnUrl()}
        returnPrompt={getReturnPrompt()}
        inquiryFormView
      />
    )
  );
}
