import {
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { CancelOutlined } from "@mui/icons-material";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import staffsService from "../../../../../service/staffsService";
import { ProcessBuilderContext } from "../ProcessBuilderContext";
import StaffType from "../../../../../utils/constants/staffTypeEnum";
import { chipStyles } from "../../../../sharedStyles";
import { renderError } from "../../../../../utils/constants/forms";

export default function ProcessTaskProperties() {
  const { process, updateProcess, selectedElement } = useContext(
    ProcessBuilderContext
  );
  const methods = useForm();
  const {
    handleSubmit,
    control,
    setValue,
    register,
    formState: { errors },
    reset,
  } = methods;
  const formWatcher = useWatch({
    control,
  });
  const [dateValue, setDateValue] = useState(
    selectedElement.properties.deadline || null
  );

  const [selectedOptions, setSelectedOption] = useState(
    selectedElement.properties.personsResponsible || []
  );
  const [personsResponsible, setPersonsResponsible] = useState([]);
  const [includeInTimeline, setIncludeInTimeline] = useState(false);
  const [notifyPersonsResponsible, setNotifyPersonsResponsible] =
    useState(false);

  useEffect(() => {
    staffsService
      .fetchAllStaffs({ staff_type: [StaffType.TEACHER, StaffType.CONFERENCE] })
      .then((response) => {
        setPersonsResponsible(response);
      });
  }, []);

  const onDateChanged = (date) => {
    if (date) {
      setDateValue(date);
      setValue("deadline", date.format("MM/DD/YYYY"), {
        shouldDirty: true,
      });
    }
  };

  const getNameOfSelectedPerson = (value) =>
    personsResponsible.find((item) => item.id === value)
      ? `${personsResponsible.find((item) => item.id === value).first_name} ${
          personsResponsible.find((item) => item.id === value).last_name
        }`
      : "";

  const onSubmit = (data) => {
    const updatedStages = [...process.stages];
    let stageIndex = null;

    // find the stage index the element was dropped to
    updatedStages.some((stage, index) => {
      const foundElementIndex = stage.elements.findIndex(
        (element) => element.elementId === selectedElement.elementId
      );
      if (foundElementIndex !== -1) {
        stageIndex = index;
        return true; // exit the loop once the element is found
      }
      return false;
    });

    const updateElements = [...updatedStages[stageIndex].elements];
    const elementIndex = updateElements.findIndex(
      (el) => el.elementId === selectedElement.elementId
    );
    updateElements[elementIndex].properties = {
      ...updateElements[elementIndex].properties,
      ...data,
    };
    updateElements[elementIndex].name = data.name;
    updatedStages[stageIndex] = {
      ...updatedStages[stageIndex],
      elements: updateElements,
    };

    updateProcess({ ...process, stages: updatedStages });
  };

  useEffect(() => {
    reset(
      {
        personsResponsible: selectedElement.properties.personsResponsible,
        deadline: selectedElement.properties.deadline,
        name: selectedElement.properties.name,
        description: selectedElement.properties.description,
        require: selectedElement.properties.require,
        includeInTimeline: selectedElement.properties.includeInTimeline,
        notifyPersonsResponsible:
          selectedElement.properties.notifyPersonsResponsible,
      },
      { keepTouched: true, keepDirty: true }
    );
    setSelectedOption(selectedElement.properties.personsResponsible);
    setDateValue(selectedElement.properties.deadline || null);
    setNotifyPersonsResponsible(
      selectedElement.properties.notifyPersonsResponsible
    );
    setIncludeInTimeline(selectedElement.properties.includeInTimeline);
  }, [selectedElement]);

  useEffect(() => {
    if (Object.keys(formWatcher).length) onSubmit(formWatcher);
  }, [formWatcher]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          rowGap={4}
          boxShadow="0px 4px 50px 0px rgba(29, 182, 231, 0.12)"
          borderRadius={1}
          padding={2.5}
        >
          <Grid item md={12}>
            <Typography variant="body1" align="center">
              TASK
            </Typography>
          </Grid>
          <Grid item md={12}>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField {...field} fullWidth label="NAME" />
              )}
            />
          </Grid>
          <Grid item md={12}>
            <Controller
              name="description"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField {...field} fullWidth label="DESCRIPTION" />
              )}
            />
          </Grid>
          <Grid item md={12}>
            <FormControl fullWidth>
              <InputLabel id="personsResponsible">
                Persons Responsible
              </InputLabel>
              <Select
                labelId="personsResponsible"
                label="Persons Responsible"
                value={selectedOptions}
                placeholder="Select"
                multiple
                {...register(`personsResponsible`, {
                  onChange: (event) => {
                    setSelectedOption(event.target.value);
                    setValue(`personsResponsible`, event.target.value, {
                      shouldDirty: true,
                    });
                  },
                })}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value, index) => (
                      <Chip
                        key={value}
                        sx={chipStyles}
                        label={getNameOfSelectedPerson(value)}
                        deleteIcon={
                          <CancelOutlined
                            sx={{ color: "black !important" }}
                            onMouseDown={(event) => event.stopPropagation()}
                          />
                        }
                        onDelete={() => {
                          const newValues = [...selectedOptions];
                          newValues.splice(index, 1);
                          setSelectedOption(newValues);
                          setValue("personsResponsible", newValues, {
                            shouldDirty: true,
                          });
                        }}
                      />
                    ))}
                  </Box>
                )}
              >
                {personsResponsible.map((person) => (
                  <MenuItem key={person.id} value={person.id}>
                    {`${person.first_name} ${person.last_name}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={8}>
            <DesktopDatePicker
              onChange={onDateChanged}
              label="Deadline"
              inputFormat="MM/DD/YYYY"
              value={dateValue}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ width: "100%" }}
                  {...register("deadline", {
                    pattern: {
                      value:
                        /(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/(19|20)\d{2}/,
                      message: "Invalid date.",
                    },
                  })}
                />
              )}
            />
            {errors.deadline && renderError(errors.deadline.message)}
          </Grid>
          <Grid item md={12}>
            <Typography variant="body1">Options</Typography>
            <FormControlLabel
              sx={{ width: "100%" }}
              control={
                <Checkbox
                  checked={notifyPersonsResponsible}
                  onChange={(e) => {
                    setValue("notifyPersonsResponsible", e.target.checked, {
                      shouldDirty: true,
                    });
                    setNotifyPersonsResponsible(e.target.checked);
                  }}
                />
              }
              label="Notify persons responsible"
            />
            <FormControlLabel
              sx={{ width: "100%" }}
              control={
                <Checkbox
                  checked={includeInTimeline}
                  onChange={(e) => {
                    setValue("includeInTimeline", e.target.checked, {
                      shouldDirty: true,
                    });
                    setIncludeInTimeline(e.target.checked);
                  }}
                />
              }
              label="Include in timeline"
            />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}
