const selectLabel = {
  backgroundColor: "white",
};

const pinCheckBox = {
  alignItems: "flex-end",
};

const displayOptionsContainer = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  my: "35px",
  mr: "55px",
};
const internalNoteBackground = {
  backgroundColor: "#F9DEDC",
};
const internalNoteComment = {
  color: "#B3261E",
  fontSize: "12px",
  paddingY: "0.25rem",
};

const displayOptionsTitle = {
  fontSize: "26px",
  mr: "60px",
};

const createdDate = {
  textAlign: "right",
  mt: "60px",
};

export {
  selectLabel,
  pinCheckBox,
  displayOptionsContainer,
  displayOptionsTitle,
  createdDate,
  internalNoteBackground,
  internalNoteComment,
};
