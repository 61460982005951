import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, TextField } from "@mui/material";
import { pl20 } from "../../../sharedStyles";
import { disabledTextField } from "./Profile.styles";
import formatFullName from "../../../../utils/formatFullName";

export default function NameInformation({ kidInfo }) {
  const { t } = useTranslation("guardian", { keyPrefix: "studentProfile" });

  return (
    <Grid container spacing={2} sx={pl20}>
      <Grid item md={6} sx={disabledTextField}>
        <TextField
          disabled
          id="fullLegalName"
          label={t("fullLegalName")}
          fullWidth
          defaultValue={formatFullName(kidInfo)}
          inputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid item md={3} sx={disabledTextField}>
        <TextField
          disabled
          id="preferredName"
          label={t("preferredName")}
          fullWidth
          defaultValue={kidInfo.preferred_name}
          inputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid item md={3} />
      <Grid item md={3} sx={disabledTextField}>
        <TextField
          disabled
          id="firstName"
          label={t("firstName")}
          fullWidth
          defaultValue={kidInfo.first_name}
          inputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid item md={3} sx={disabledTextField}>
        <TextField
          disabled
          id="middleName"
          label={t("middleName")}
          fullWidth
          defaultValue={kidInfo.middle_name}
          inputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid item md={3} sx={disabledTextField}>
        <TextField
          disabled
          id="lastName"
          label={t("lastName")}
          fullWidth
          defaultValue={kidInfo.last_name}
          inputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid item md={2} sx={disabledTextField}>
        <TextField
          disabled
          id="genName"
          label={t("genName")}
          fullWidth
          defaultValue={kidInfo.generation_name}
          inputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid item md={1} />
    </Grid>
  );
}
