import React from "react";
import { useSearchParams } from "react-router-dom";
import { Typography, IconButton, Grid } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { font25, font40, daySelectButton } from "../sharedStyles";
import dateTimeFormats from "../../utils/constants/dateTimeFormats";
import moment from "../../utils/constants/momentConfig";

export default function DaySelect({
  view,
  currentDay,
  weekDays,
  setCurrentDay,
  schoolDays,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const weekDayStart = weekDays[0].format(dateTimeFormats.DD);
  const weekDayEnd = weekDays[weekDays.length - 1];
  const dayViewMode = currentDay.format(dateTimeFormats.MMMDDYYYY);
  const isEndOfMonth =
    weekDayEnd.format(dateTimeFormats.MMM) !==
    weekDays[0].format(dateTimeFormats.MMM);
  const weekViewMode = `${weekDays[0].format(
    dateTimeFormats.MMM
  )} ${weekDayStart} - ${
    isEndOfMonth ? weekDayEnd.format(dateTimeFormats.MMM) : ""
  } ${weekDayEnd.format(dateTimeFormats.DD)}, ${weekDays[0].format(
    dateTimeFormats.YYYY
  )}`;

  const getSchoolDay = (direction, range) => {
    const iterateForward = direction === "forward";
    const iterateBackward = direction === "back";

    if (iterateForward) {
      for (let i = 0; i < schoolDays.length; i += 1) {
        const day = schoolDays[i];
        const dayDate = moment(day.date, "YYYY-MM-DD");

        if (
          (day.school_day_designation?.category === "school_day" ||
            day.day_template_id !== null) &&
          ((range === "days" && dayDate.isAfter(currentDay, "day")) ||
            (range === "weeks" && dayDate.isAfter(currentDay, "week")))
        ) {
          return moment(day.date);
        }
      }
    }

    if (iterateBackward) {
      for (let i = schoolDays.length - 1; i >= 0; i -= 1) {
        const day = schoolDays[i];
        const dayDate = moment(day.date, "YYYY-MM-DD");

        if (
          (day.school_day_designation?.category === "school_day" ||
            day.day_template_id !== null) &&
          ((range === "days" && dayDate.isBefore(currentDay, "day")) ||
            (range === "weeks" && dayDate.isBefore(currentDay, "week")))
        ) {
          return moment(day.date);
        }
      }
    }

    return null;
  };

  const handleDayChange = (direction) => {
    const range = view === "Week" ? "weeks" : "days";

    if (direction === "back") {
      const previousDay = getSchoolDay("back", range);
      if (previousDay) {
        setCurrentDay(previousDay);
        searchParams.set("day", previousDay.format(dateTimeFormats.YYYYMMDD));
      }
    } else {
      const nextDay = getSchoolDay("forward", range);
      if (nextDay) {
        setCurrentDay(nextDay);
        searchParams.set("day", nextDay.format(dateTimeFormats.YYYYMMDD));
      }
    }
    setSearchParams(searchParams);
  };

  return (
    <Grid container>
      <Grid item xs={view === "Day" ? 6 : 7}>
        <Typography sx={font25}>
          {view === "Week" ? weekViewMode : dayViewMode}
        </Typography>
      </Grid>
      <Grid item xs={view === "Day" ? 6 : 3}>
        <IconButton
          disableRipple
          onClick={() => handleDayChange("back")}
          sx={daySelectButton}
        >
          <KeyboardArrowLeftIcon sx={font40} color="primary" />
        </IconButton>
        <IconButton
          disableRipple
          onClick={() => handleDayChange("next")}
          sx={daySelectButton}
        >
          <KeyboardArrowRightIcon sx={font40} color="primary" />
        </IconButton>
      </Grid>
    </Grid>
  );
}
