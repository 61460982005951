import { Controller, useForm, useWatch } from "react-hook-form";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

import { DesktopDatePicker } from "@mui/x-date-pickers";
import React, { useContext, useEffect, useState } from "react";
import { ProcessBuilderContext } from "../ProcessBuilderContext";
import { renderError } from "../../../../../utils/constants/forms";

export default function ProcessFeeProperties() {
  const { selectedElement, currentStageIndex, process, updateProcess } =
    useContext(ProcessBuilderContext);

  const [earlyFeeIncludeInTimeline, setEarlyFeeIncludeInTimeline] = useState(
    selectedElement.properties.earlyFeeIncludeInTimeline
  );

  const [lateFeeIncludeInTimeline, setLateFeeIncludeInTimeline] = useState(
    selectedElement.properties.earlyFeeIncludeInTimeline
  );

  const [dateValue, setDateValue] = useState(
    selectedElement.properties.deadline || null
  );

  const {
    register,
    setValue,
    formState: { errors },
    reset,
    control,
    handleSubmit,
  } = useForm();

  const formWatcher = useWatch({
    control,
  });

  const onSubmit = (data) => {
    const updatedStages = [...process.stages];

    const updateElements = [...updatedStages[currentStageIndex].elements];
    const elementIndex = updateElements.findIndex(
      (el) => el.elementId === selectedElement.elementId
    );
    updateElements[elementIndex].properties = data;
    updatedStages[currentStageIndex] = {
      ...updatedStages[currentStageIndex],
      elements: updateElements,
    };

    updateProcess({ ...process, stages: updatedStages });
  };

  useEffect(() => {
    reset(
      {
        earlyFeeDescription: selectedElement.properties.earlyFeeDescription,
        earlyFeePaymentUrl: selectedElement.properties.earlyFeePaymentUrl,
        earlyFeeName: selectedElement.properties.earlyFeeName,
        earlyFeeCost: selectedElement.properties.earlyFeeCost,
        earlyFeeIncludeInTimeline:
          selectedElement.properties.earlyFeeIncludeInTimeline,
        deadline: selectedElement.properties.deadline,
        lateFeeDescription: selectedElement.properties.lateFeeDescription,
        lateFeePaymentUrl: selectedElement.properties.lateFeePaymentUrl,
        lateFeeName: selectedElement.properties.lateFeeName,
        lateFeeCost: selectedElement.properties.lateFeeCost,
        lateFeeIncludeInTimeline:
          selectedElement.properties.lateFeeIncludeInTimeline,
      },
      { keepTouched: true, keepDirty: true }
    );

    setEarlyFeeIncludeInTimeline(
      selectedElement.properties.earlyFeeIncludeInTimeline
    );

    setLateFeeIncludeInTimeline(
      selectedElement.properties.lateFeeIncludeInTimeline
    );
    setDateValue(selectedElement.properties.deadline);
  }, [selectedElement]);

  const onDateChanged = (date) => {
    if (date) {
      setDateValue(date);
      setValue("deadline", date.format("MM/DD/YYYY"), {
        shouldDirty: true,
      });
    }
  };

  useEffect(() => {
    if (Object.keys(formWatcher).length) onSubmit(formWatcher);
  }, [formWatcher]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        rowGap={4}
        boxShadow="0px 4px 50px 0px rgba(29, 182, 231, 0.12)"
        borderRadius={1}
        padding={2.5}
      >
        <Grid item md={12}>
          <Typography align="center" variant="body1">
            Application Fee
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          container
          border="1px solid #A2E6F5"
          p={2}
          justifyContent="center"
          rowGap={2.5}
        >
          <Grid item md={12} textAlign="center">
            <Typography variant="caption">Early Fee</Typography>
          </Grid>
          <Grid item md={12}>
            <Controller
              name="earlyFeeName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField {...field} fullWidth label="Name of Fee" />
              )}
            />
          </Grid>
          <Grid item md={12}>
            <Controller
              name="earlyFeeCost"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField {...field} fullWidth label="Cost" />
              )}
            />
          </Grid>
          <Grid item md={12}>
            <Controller
              name="earlyFeeDescription"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField {...field} fullWidth label="Description" />
              )}
            />
          </Grid>
          <Grid item md={12}>
            <Controller
              name="earlyFeePaymentUrl"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField {...field} fullWidth label="Payment Url" />
              )}
            />
          </Grid>
          <Grid item md={12}>
            <DesktopDatePicker
              onChange={onDateChanged}
              label="Deadline"
              inputFormat="MM/DD/YYYY"
              value={dateValue}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ width: "100%" }}
                  {...register("deadline", {
                    pattern: {
                      value:
                        /(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/(19|20)\d{2}/,
                      message: "Invalid date.",
                    },
                  })}
                />
              )}
            />
            {errors.deadline && renderError(errors.deadline.message)}
          </Grid>
          <Grid item md={6}>
            <FormControlLabel
              sx={{ width: "100%", justifyContent: "center" }}
              control={
                <Checkbox
                  checked={earlyFeeIncludeInTimeline}
                  onChange={(e) => {
                    setValue("earlyFeeIncludeInTimeline", e.target.checked, {
                      shouldDirty: true,
                    });
                    setEarlyFeeIncludeInTimeline(e.target.checked);
                  }}
                />
              }
              label="Include in timeline"
            />
          </Grid>
        </Grid>
        <Grid
          item
          md={12}
          container
          border="1px solid #A2E6F5"
          p={2}
          justifyContent="center"
          rowGap={2.5}
        >
          <Grid item md={12} textAlign="center">
            <Typography variant="caption">Standard Fee</Typography>
          </Grid>
          <Grid item md={12}>
            <Controller
              name="lateFeeName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField {...field} fullWidth label="Name of Fee" />
              )}
            />
          </Grid>
          <Grid item md={12}>
            <Controller
              name="lateFeeCost"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField {...field} fullWidth label="Cost" />
              )}
            />
          </Grid>
          <Grid item md={12}>
            <Controller
              name="lateFeeDescription"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField {...field} fullWidth label="Description" />
              )}
            />
          </Grid>
          <Grid item md={12}>
            <Controller
              name="lateFeePaymentUrl"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField {...field} fullWidth label="Payment Url" />
              )}
            />
          </Grid>
          <Grid item md={6}>
            <FormControlLabel
              sx={{ width: "100%", justifyContent: "center" }}
              control={
                <Checkbox
                  checked={lateFeeIncludeInTimeline}
                  onChange={(e) => {
                    setValue("lateFeeIncludeInTimeline", e.target.checked, {
                      shouldDirty: true,
                    });
                    setLateFeeIncludeInTimeline(e.target.checked);
                  }}
                />
              }
              label="Include in timeline"
            />
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}
