import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
// eslint-disable-next-line import/no-unresolved
import { stripHtml } from "string-strip-html";
import { useParams } from "react-router-dom";
import { FormStatus } from "./Models/Form";
import TextEditor from "../../../TextEditor/TextEditor";
import { FormBuilderContext } from "./FormBuilderContext";
import StaffsService from "../../../../service/staffsService";

export default function FormSettings() {
  const { form, updateForm } = useContext(FormBuilderContext);
  const methods = useForm();
  const {
    setValue,
    register,
    formState: { errors },
    control,
    trigger,
  } = methods;
  const [selectedStatus, setSelectedStatus] = useState(form.status);
  const [submissionTimezone, setSubmissionTimezone] = useState(
    form.properties.submissionTimezone
  );
  const [sendConfirmationEmail, setSendConfirmationEmail] = useState(
    form.properties.sendConfirmationEmail
  );
  const [sendNotificationReceiptEmail, setSendNotificationReceiptEmail] =
    useState(form.properties.sendNotificationReceiptEmail);

  const [emailBodyError, setEmailBodyError] = useState(false);
  const editorRef = useRef(null);
  const params = useParams();
  const [teachers, setTeachers] = useState([]);
  const [selectedStaffIds, setSelectedStaffIds] = useState([]);

  const handleBodyValidation = (hasText) =>
    hasText || !sendConfirmationEmail
      ? setEmailBodyError(false)
      : setEmailBodyError(true);

  const formWatcher = useWatch({
    control,
  });

  const onSubmit = (data) => {
    const updatedForm = { ...form };
    updatedForm.status = data.status;
    updatedForm.properties = data.properties;
    updateForm(updatedForm);
  };

  useEffect(() => {
    setValue("status", form.status, { shouldDirty: true });
    setValue(
      "properties.sendConfirmationEmail",
      form.properties.sendConfirmationEmail,
      { shouldDirty: true }
    );
    setValue(
      "properties.submissionTimezone",
      form.properties.submissionTimezone,
      { shouldDirty: true }
    );
    setValue(
      "properties.sendNotificationReceiptEmail",
      form.properties.sendNotificationReceiptEmail,
      { shouldDirty: true }
    );
    setValue(
      "properties.staffSendNotificationReceiptEmail",
      form.properties.staffSendNotificationReceiptEmail,
      { shouldDirty: true }
    );
  }, []);

  useEffect(() => {
    StaffsService.fetchAllStaffs({
      params: { school_id: params.school_id },
    }).then((response) => {
      setTeachers(response);
      const preselectedIds = form.properties.staffSendNotificationReceiptEmail;
      setSelectedStaffIds(preselectedIds);
    });
  }, []);

  useEffect(() => {
    if (teachers.length > 0) {
      const initialStaffIds =
        form.properties.staffSendNotificationReceiptEmail || [];
      setSelectedStaffIds(initialStaffIds);
      setValue("properties.staffSendNotificationReceiptEmail", initialStaffIds);
    }
  }, [teachers]);

  useEffect(() => {
    if (Object.keys(formWatcher).length) onSubmit(formWatcher);
  }, [formWatcher]);

  const getTeacherNamesByIds = (selectedIds) =>
    selectedIds
      .map((id) => {
        const teacher = teachers.find((t) => t.id === id);
        return `${teacher.first_name} ${teacher.last_name}`;
      })
      .join(", ");

  return (
    <FormProvider {...methods}>
      <Grid container rowGap={3}>
        <Grid item md={12} container>
          <Grid item md={2} />
          <Grid item md={3}>
            <Typography fontSize={20} fontWeight={500}>
              Form Status
            </Typography>
            <FormControl sx={{ ml: 5, mt: 1 }} fullWidth>
              <InputLabel id="formStatusDropdown">Form Status</InputLabel>
              <Select
                labelId="formStatusDropdown"
                label="Form Status"
                value={selectedStatus}
                defaultValue={form.status}
                placeholder="Select"
                {...register("status", {
                  onChange: (event) => {
                    setSelectedStatus(event.target.value);
                    setValue("status", event.target.value, {
                      shouldDirty: true,
                    });
                    trigger();
                  },
                })}
              >
                <MenuItem key={1} value={FormStatus.ENABLED}>
                  <Typography textAlign="left">Enabled</Typography>
                </MenuItem>
                <MenuItem key={2} value={FormStatus.DISABLED}>
                  <Typography textAlign="left">Disabled</Typography>
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item md={12} container>
          <Grid item md={2} />
          <Grid item md={3}>
            <Typography fontSize={20} fontWeight={500}>
              Time Zones for Submissions
            </Typography>
            <FormControl sx={{ ml: 5, mt: 1 }} fullWidth>
              <InputLabel id="formStatusDropdown">
                Time Zones for Submissions
              </InputLabel>
              <Select
                labelId="formStatusDropdown"
                label="Time Zones for Submissions"
                value={submissionTimezone}
                placeholder="Select"
                {...register("properties,submissionTimezone", {
                  onChange: (event) => {
                    setSubmissionTimezone(event.target.value);
                    setValue(
                      "properties.submissionTimezone",
                      event.target.value,
                      {
                        shouldDirty: true,
                      }
                    );
                    trigger();
                  },
                })}
              >
                <MenuItem value="Central Standard TimeChicago (GMT-6)">
                  <Typography textAlign="left">
                    Central Standard TimeChicago (GMT-6)
                  </Typography>
                </MenuItem>
                <MenuItem value="Mountain Standard TimeDenver (GMT-7)">
                  <Typography textAlign="left">
                    Mountain Standard TimeDenver (GMT-7)
                  </Typography>
                </MenuItem>
                <MenuItem value="Mountain Standard TimePhoenix (GMT-7)">
                  <Typography textAlign="left">
                    Mountain Standard TimePhoenix (GMT-7)
                  </Typography>
                </MenuItem>
                <MenuItem value="Pacific Standard TimeLos Angeles (GMT-8)">
                  <Typography textAlign="left">
                    Pacific Standard TimeLos Angeles (GMT-8)
                  </Typography>
                </MenuItem>
                <MenuItem value="Alaska Standard TimeAnchorage (GMT-9)">
                  <Typography textAlign="left">
                    Alaska Standard TimeAnchorage (GMT-9)
                  </Typography>
                </MenuItem>
                <MenuItem value="Hawaii-Aleutian Standard TimeHonolulu (GMT-10)">
                  <Typography textAlign="left">
                    Hawaii-Aleutian Standard TimeHonolulu (GMT-10)
                  </Typography>
                </MenuItem>
                <MenuItem value="Chuuk-Guam Standard TimeChuuk (GMT+10)">
                  <Typography textAlign="left">
                    Chuuk-Guam Standard TimeChuuk (GMT+10)
                  </Typography>
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item md={12} container>
          <Grid item md={2} />
          <Grid item md={3}>
            <Typography fontSize={20} fontWeight={500}>
              Emails and Actions
            </Typography>
            <FormControlLabel
              sx={{ width: "100%", ml: 5 }}
              control={
                <Checkbox
                  checked={sendConfirmationEmail}
                  onChange={(e) => {
                    setValue(
                      "properties.sendConfirmationEmail",
                      e.target.checked,
                      {
                        shouldDirty: true,
                      }
                    );
                    setSendConfirmationEmail(e.target.checked);
                  }}
                />
              }
              label="Send confirmation email"
            />
          </Grid>
        </Grid>
        {sendConfirmationEmail && (
          <Grid item md={12} container>
            <Grid item md={2} />
            <Grid item md={8}>
              <Box ml={10}>
                <Controller
                  name="properties.emailBody"
                  control={control}
                  render={({ field }) => (
                    <TextEditor
                      {...field}
                      value={form.properties.emailBody}
                      hasError={errors?.description}
                      handleBodyValidation={handleBodyValidation}
                      descriptionError={emailBodyError}
                      editorRef={editorRef}
                      showExpandIcon
                    />
                  )}
                  rules={{
                    validate: {
                      required: (v) =>
                        (v && stripHtml(v).result.length > 0) ||
                        "Please write some notes.",
                    },
                  }}
                  defaultValue=""
                />
              </Box>
            </Grid>
          </Grid>
        )}
        <Grid item md={12} container>
          <Grid item md={2} />
          <Grid item md={3}>
            <FormControlLabel
              sx={{ width: "100%", ml: 5 }}
              control={
                <Checkbox
                  checked={sendNotificationReceiptEmail}
                  onChange={(e) => {
                    setValue(
                      "properties.sendNotificationReceiptEmail",
                      e.target.checked,
                      {
                        shouldDirty: true,
                      }
                    );
                    setSendNotificationReceiptEmail(e.target.checked);
                  }}
                />
              }
              label="Send notification of receipt"
            />
          </Grid>
        </Grid>
        {sendNotificationReceiptEmail && (
          <Grid item md={12} container>
            <Grid item md={2} />
            <Grid item md={3}>
              <FormControl fullWidth sx={{ width: "100%", ml: 5 }}>
                <InputLabel id="multi-select-label">Staff</InputLabel>
                <Controller
                  name="properties.staffSendNotificationReceiptEmail"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      multiple
                      labelId="multi-select-label"
                      label="Select Multiple Options"
                      value={selectedStaffIds} // use `value` instead of `defaultValue`
                      onChange={(event) => {
                        const {
                          target: { value },
                        } = event;
                        setSelectedStaffIds(value); // maintain local state
                        setValue(
                          "properties.staffSendNotificationReceiptEmail",
                          value,
                          {
                            shouldDirty: true,
                          }
                        );
                      }}
                      renderValue={(selected) => getTeacherNamesByIds(selected)}
                    >
                      {teachers.map((teacher) => (
                        <MenuItem key={teacher.id} value={teacher.id}>
                          {`${teacher.first_name} ${teacher.last_name}`}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        )}
      </Grid>
    </FormProvider>
  );
}
