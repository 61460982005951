import React, { useEffect, useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { danger, switchBtn } from "../sharedStyles";
import {
  assignmentFormDescription,
  assignmentFormDivider,
  assignmentFormName,
  assignmentsTextArea,
} from "./GeneralInfo.styles";
import ErasableTextField from "../ErasableTextField";
import { renderError } from "../../utils/constants/forms";
import { assignmentFormRow } from "./AssignmentForm.styles";
import moment from "../../utils/constants/momentConfig";
import schoolDayService from "../../service/schoolDayService";
import termService from "../../service/termService";

export default function GeneralInfo({
  activeAssignment,
  setErrors,
  isGoogleAssignment,
  termId,
  selectedDays,
  repeatEventError,
  repeatEvent,
  setRepeatEvent,
  turnInOnline,
  setTurnInOnline,
  turnInOnlineUsing,
  handleTurnInOnlineUsing,
  setSelectedDays,
  displayInPortal,
  setDisplayInPortal,
}) {
  const {
    control,
    setError,
    clearErrors,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useFormContext();

  const [activeTerm, setActiveTerm] = useState();

  const isAssignedDate = () => {
    const dateRegex = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;
    const formmattedDate = moment(getValues("assignedDate")).format(
      "MM/DD/YYYY"
    );
    if (!dateRegex.test(formmattedDate)) return false;
    const formAssignedDate = new Date(getValues("assignedDate"));
    const formAssignedTime = new Date(getValues("assignedTime"));
    const assignedDate = new Date(activeAssignment?.assigned_date);
    const assignedTime = new Date(
      moment(activeAssignment?.assigned_time, "HH:mm a")
    );
    return (
      (activeAssignment?.assigned_date &&
        (assignedDate.getDate() !== formAssignedDate.getDate() ||
          assignedDate.getMonth() !== formAssignedDate.getMonth() ||
          assignedDate.getFullYear() !== formAssignedDate.getFullYear())) ||
      (activeAssignment?.assigned_time &&
        (assignedTime.getHours() !== formAssignedTime.getHours() ||
          assignedTime.getMinutes() !== formAssignedTime.getMinutes()))
    );
  };

  const getNextSchoolDay = () => {
    if (
      moment(getValues("assignedDate")).isValid() &&
      (!activeAssignment?.assigned_date ||
        (getValues("assignedDate") &&
          !Object.keys(errors.assignedDate || {}).length &&
          isAssignedDate()))
    ) {
      const formAssignedDate = moment(getValues("assignedDate")).format(
        "YYYY-MM-DD"
      );
      schoolDayService
        .nextSchoolDay(formAssignedDate, termId)
        .then((response) => {
          if (response.data.next_school_day)
            setValue(
              "dueDate",
              moment(response.data.next_school_day.date).format("MM/DD/YYYY"),
              {
                shouldDirty: true,
              }
            );
          else
            setValue("dueDate", moment(formAssignedDate).format("MM/DD/YYYY"), {
              shouldDirty: true,
            });
        });
    }
  };

  const assignedTimeValue = activeAssignment?.assigned_time
    ? new Date(moment(activeAssignment?.assigned_time, "HH:mm a"))
    : null;
  const dueTimeValue = activeAssignment?.due_time
    ? new Date(moment(activeAssignment?.due_time, "HH:mm a"))
    : null;

  const handleError = (error, field) => {
    if (error === "invalidDate") {
      setErrors(true);
      setError(field, {
        type: "custom",
        message: "Wrong format",
      });
    } else {
      setErrors(false);
      clearErrors(field);
    }
  };

  useEffect(() => {
    if (!activeAssignment) {
      getNextSchoolDay();
    }
  }, [getValues("assignedDate")]);

  useEffect(() => {
    termService.fetchTerm(termId).then((response) => {
      if (response.data.data) setActiveTerm(response.data.data);
    });
  }, []);

  const selectDay = (day, checked) => {
    const selectedDaysClone = { ...selectedDays };
    selectedDaysClone[day] = checked;
    setSelectedDays(selectedDaysClone);
  };

  const getAssignedDate = () => {
    if (activeAssignment?.assigned_date)
      return moment(activeAssignment?.assigned_date).format("MM/DD/YYYY");
    if (new Date() > new Date(activeTerm?.end_date))
      return moment(activeTerm?.end_date).format("MM/DD/YYYY");
    if (new Date() < new Date(activeTerm?.start_date))
      return moment(activeTerm?.start_date).format("MM/DD/YYYY");
    return moment(new Date()).format("MM/DD/YYYY");
  };

  const validateAssignedDate = () =>
    !getValues("assignedDate") ||
    (moment(getValues("assignedDate")).isSameOrAfter(
      moment(activeTerm?.start_date, "YYYY-MM-DD")
    ) &&
      moment(getValues("assignedDate")).isSameOrBefore(
        moment(activeTerm?.end_date, "YYYY-MM-DD")
      )) ||
    `Assigned date should be between ${moment(
      activeTerm?.start_date,
      "YYYY-MM-DD"
    ).format("MM/DD/YYYY")} and ${moment(
      activeTerm?.end_date,
      "YYYY-MM-DD"
    ).format("MM/DD/YYYY")}.`;

  const validateDueDate = () =>
    !getValues("dueDate") ||
    (moment(getValues("dueDate")).isSameOrAfter(getValues("assignedDate")) &&
      moment(getValues("dueDate")).isSameOrBefore(
        moment(activeTerm?.end_date, "YYYY-MM-DD")
      )) ||
    `Due date should be between ${moment(getValues("assignedDate"))?.format(
      "MM/DD/YYYY"
    )} and ${moment(activeTerm?.end_date, "YYYY-MM-DD").format("MM/DD/YYYY")}.`;

  return (
    <>
      <Box>
        <Box sx={assignmentFormRow}>
          <Stack direction="row">
            <Box sx={assignmentFormName}>
              <ErasableTextField
                id="name"
                label="Name of assignment*"
                fullWidth
                isRequired={!isGoogleAssignment}
                type="text"
                defaultValue={activeAssignment?.name || null}
                hasError={errors?.name && true}
                disabled={isGoogleAssignment}
                maxLength={250}
              />

              {errors?.name && renderError(errors?.name.message)}
            </Box>
          </Stack>
        </Box>
      </Box>

      <Box sx={assignmentFormRow}>
        <Box>
          <Stack direction="row">
            <Box sx={assignmentFormDescription}>
              <ErasableTextField
                id="description"
                label="Description"
                fullWidth
                type="text"
                defaultValue={activeAssignment?.description || null}
                inputSx={assignmentsTextArea}
                isMultiline
                lines={4}
                disabled={isGoogleAssignment}
              />
            </Box>
          </Stack>
        </Box>
      </Box>
      <Box sx={assignmentFormDivider}>
        <Grid container columnSpacing={2}>
          {repeatEvent && (
            <Grid item container md={9} mb={3} borderRight="1px solid #BFC7CA">
              <Typography>
                Select the days in term to repeat the assignment:
              </Typography>
              <Box mt={3}>
                <FormControl sx={{ alignItems: "flex-end" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={selectedDays.Monday}
                        onClick={(e) => {
                          selectDay("Monday", e.target.checked);
                        }}
                      />
                    }
                    label={<Typography fontSize="0.8rem">Monday</Typography>}
                  />
                </FormControl>
                <FormControl sx={{ alignItems: "flex-end" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={selectedDays.Tuesday}
                        onClick={(e) => {
                          selectDay("Tuesday", e.target.checked);
                        }}
                      />
                    }
                    label={<Typography fontSize="0.8rem">Tuesday</Typography>}
                  />
                </FormControl>
                <FormControl sx={{ alignItems: "flex-end" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={selectedDays.Wednesday}
                        onClick={(e) => {
                          selectDay("Wednesday", e.target.checked);
                        }}
                      />
                    }
                    label={<Typography fontSize="0.8rem">Wednesday</Typography>}
                  />
                </FormControl>
                <FormControl sx={{ alignItems: "flex-end" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={selectedDays.Thursday}
                        onClick={(e) => {
                          selectDay("Thursday", e.target.checked);
                        }}
                      />
                    }
                    label={<Typography fontSize="0.8rem">Thursday</Typography>}
                  />
                </FormControl>
                <FormControl sx={{ alignItems: "flex-end" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={selectedDays.Friday}
                        onClick={(e) => {
                          selectDay("Friday", e.target.checked);
                        }}
                      />
                    }
                    label={<Typography fontSize="0.8rem">Friday</Typography>}
                  />
                </FormControl>
              </Box>
              {repeatEvent && repeatEventError && (
                <Typography sx={danger}>
                  Please choose repeating days for assignment.
                </Typography>
              )}
            </Grid>
          )}
          {!repeatEvent && activeTerm && (
            <Grid item container md={9} rowGap={2.5}>
              <Grid item container md={12} columnSpacing={2}>
                <Grid item md={6}>
                  <Controller
                    name="assignedDate"
                    control={control}
                    defaultValue={getAssignedDate()}
                    rules={{
                      required: {
                        value: true,
                        message: "This field is required.",
                      },
                      pattern: {
                        value:
                          /(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/(19|20)\d{2}/,
                        message: "Invalid date.",
                      },
                      validate: validateAssignedDate,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        disabled={isGoogleAssignment}
                        label="Assigned Date"
                        id="assignedDate"
                        value={value}
                        minDate={activeTerm?.start_date}
                        maxDate={activeTerm?.end_date}
                        onError={(newError) =>
                          handleError(newError, "assignedDate")
                        }
                        onChange={(e) => {
                          onChange(e);
                          getNextSchoolDay();
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={!!errors?.assignedDate}
                          />
                        )}
                      />
                    )}
                  />
                  {errors?.assignedDate && (
                    <Typography sx={danger} fontSize="0.9rem">
                      {errors?.assignedDate.message}
                    </Typography>
                  )}
                </Grid>
                <Grid item md={6}>
                  <Controller
                    name="assignedTime"
                    control={control}
                    defaultValue={assignedTimeValue}
                    render={({ field: { onChange, value } }) => (
                      <TimePicker
                        disabled={isGoogleAssignment}
                        label="Assigned Date Time"
                        id="assignedTime"
                        value={value}
                        onError={(newError) =>
                          handleError(newError, "assignedTime")
                        }
                        onChange={(e) => {
                          onChange(e);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    )}
                  />
                  {errors?.assignedTime &&
                    renderError(errors?.assignedTime.message)}
                </Grid>
              </Grid>

              <Grid item container md={12} columnSpacing={2}>
                <Grid item md={6}>
                  <Controller
                    name="dueDate"
                    control={control}
                    defaultValue={
                      activeAssignment?.due_date
                        ? moment(activeAssignment?.due_date).format(
                            "MM/DD/YYYY"
                          )
                        : null
                    }
                    rules={{
                      pattern: {
                        value:
                          /(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/(19|20)\d{2}/,
                        message: "Invalid date.",
                      },
                      validate: validateDueDate,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        disabled={isGoogleAssignment}
                        label="Due Date"
                        id="dueDate"
                        value={value}
                        minDate={watch("assignedDate")}
                        maxDate={activeTerm?.end_date}
                        onError={(newError) => handleError(newError, "dueDate")}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} error={!!errors?.dueDate} />
                        )}
                      />
                    )}
                  />
                  {errors?.dueDate && (
                    <Typography sx={danger} fontSize="0.9rem">
                      {errors?.dueDate.message}
                    </Typography>
                  )}
                </Grid>
                <Grid item md={6}>
                  <Controller
                    name="dueTime"
                    control={control}
                    defaultValue={dueTimeValue}
                    render={({ field: { onChange, value } }) => (
                      <TimePicker
                        disabled={isGoogleAssignment}
                        label="Due Date Time"
                        id="dueTime"
                        value={value}
                        onError={(newError) => handleError(newError, "dueTime")}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    )}
                  />

                  {errors?.dueTime && renderError(errors?.dueTime.message)}
                </Grid>
              </Grid>
            </Grid>
          )}
          {!activeAssignment && (
            <Grid item md={3}>
              <FormControl fullWidth sx={{ alignItems: "flex-end" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={repeatEvent}
                      onClick={(e) => {
                        setRepeatEvent(e.target.checked);
                      }}
                    />
                  }
                  label={<Typography>Repeat Event</Typography>}
                />
              </FormControl>
            </Grid>
          )}

          <Grid item md={12}>
            <FormControl
              fullWidth
              sx={{ pt: "10px" }}
              disabled={activeAssignment}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={turnInOnline}
                    onClick={(e) => {
                      setTurnInOnline(e.target.checked);
                    }}
                  />
                }
                label={
                  <Typography>
                    Allow students to turn in assignment online
                  </Typography>
                }
              />
            </FormControl>
          </Grid>

          {turnInOnline && (
            <Grid item md={12}>
              <FormControl
                fullWidth
                sx={{ pl: "30px" }}
                disabled={activeAssignment}
              >
                <RadioGroup
                  row
                  aria-labelledby="trun-in-online-using-label"
                  name="trun-in-online-using"
                  value={turnInOnlineUsing}
                  onChange={handleTurnInOnlineUsing}
                >
                  <FormControlLabel
                    value="file"
                    control={<Radio />}
                    label="Upload File"
                  />
                  <FormControlLabel
                    value="link"
                    control={<Radio />}
                    label="Link"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          )}

          <Grid item md={12}>
            <FormControlLabel
              sx={{ pt: "10px" }}
              control={
                <Switch
                  sx={switchBtn}
                  checked={displayInPortal == null ? true : displayInPortal}
                  onChange={(e) => {
                    setDisplayInPortal(e.target.checked);
                  }}
                />
              }
              label="Display in student and parent portal"
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
