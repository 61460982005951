import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Link as RouterLink,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import { fetchStudentClasses } from "../../../service/studentPortalService";
import {
  classActiveBox,
  classInActiveBox,
  classActiveLink,
  classInActiveLink,
  gridContainer,
  displayFlex,
  borderBottom,
  gridItemClassName,
  meetingBtn,
  gridItemTeacherName,
  gridItemTeacherName1,
  gridItemBox1,
  assignmentsTab,
  filesTab,
  assignmentsActiveTab,
  filesActiveTab,
  gridItemBox2,
  gradeTag,
  gridItemBox3,
  gridItemBox4,
  currentGrade,
  gradeTag1,
  assignmentsTypeBtn,
  gridItem1,
  assignmentsTypeBtnInactive,
  filesDiv,
  assignmentsTypeBtn1,
  assignmentsTypeBtnInactive1,
  gridItemBox5,
} from "./ClassDetails.styles";
import termGradeLetter from "../../../utils/termGradeLetter";
import { loader } from "../../sharedStyles";
import AssignmentsTable from "./Assignments";
import AssignmentDetail from "./AssignmentDetail";
import profilePictureIcon from "../../../utils/profilePictureIcon";

export default function StudentPortalClassDetails() {
  const [student] = useOutletContext();
  const setDisplay =
    ["TK", "K", "1", "2"].includes(student.grade_level) && "none";
  const params = useParams();
  const studentId = params.id;
  const schoolId = params.school_id;
  const classId = params.class_id;
  const [termGrades, setTermGrades] = useState([]);
  const [specialMarks, setSpecialMarks] = useState([]);
  const [activeClass, setActiveClass] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams({
    type: "assignments",
    assignments: "all",
  });
  const [loading, setLoading] = useState(false);
  const [activeAssignment, setActiveAssignment] = useState(null);
  const [activeAssignmentGrade, setActiveAssignmentGrade] = useState(null);
  const [showDetailedAssignment, setShowDetailedAssignment] = useState(false);

  const openMeetingURL = (url) => {
    // eslint-disable-next-line no-param-reassign
    url = url.match(/^https?:/) ? url : `//${url}`;
    window.open(url, "_blank", "noreferrer");
  };

  const setType = (type) => {
    setShowDetailedAssignment(false);
    searchParams.set("type", type);
    if (type === "files") {
      searchParams.delete("assignments");
    }
    setSearchParams(searchParams);
  };

  const setAssignmentsType = (type) => {
    searchParams.set("assignments", type);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await fetchStudentClasses(studentId, {
          params: {
            school_id: schoolId,
          },
        });

        if (response.data) {
          setLoading(false);
          setTermGrades(response.data.term_grades);
          setSpecialMarks(response.data.special_marks);
          setActiveClass(
            response.data.term_grades.find(
              (tg) => tg.klass.id === parseInt(classId, 10)
            )
          );
        }
      } catch (e) {
        // handle catch
      }
    })();
  }, [schoolId]);

  const filterAssignments = (assignments) =>
    searchParams.get("assignments") === "all"
      ? assignments
      : assignments.filter((a) =>
          a.assignment_students.some((as) => as.score === "/")
        );

  if (loading) {
    return <CircularProgress sx={loader} size={100} />;
  }

  if (termGrades.length === 0) {
    return <Typography>No classes for the current term.</Typography>;
  }

  if (!activeClass) {
    return <Typography>Class not found.</Typography>;
  }

  return (
    <>
      <Grid container sx={gridContainer}>
        {termGrades.map((termGrade) => (
          <Box
            mx={2}
            my={1}
            key={termGrade.id}
            sx={
              termGrade.klass.id === parseInt(classId, 10)
                ? classActiveBox
                : classInActiveBox
            }
          >
            <Link
              underline="none"
              component={RouterLink}
              sx={
                termGrade.klass.id === parseInt(classId, 10)
                  ? classActiveLink
                  : classInActiveLink
              }
              to={`/student/${studentId}/schools/${schoolId}/classes/${termGrade.klass.id}`}
              onClick={() => {
                setActiveClass(termGrade);
                setShowDetailedAssignment(false);
              }}
            >
              {termGrade.klass.name}
            </Link>
          </Box>
        ))}
      </Grid>

      {activeClass && (
        <>
          <Grid container>
            <Grid item md={6} xs={6}>
              <Box sx={displayFlex}>
                <img
                  alt="profile_image"
                  src={profilePictureIcon(activeClass?.klass?.teacher)}
                  style={{
                    border: "6px solid #000",
                    borderRadius: "100px",
                    width: "90px",
                    height: "90px",
                    objectFit: "cover",
                  }}
                />
                <Typography sx={gridItemClassName}>
                  {activeClass.klass.name}
                </Typography>
              </Box>
              <Box sx={borderBottom} />
              <Box sx={displayFlex}>
                {activeClass.klass.meeting_url && (
                  <Button
                    /* eslint-disable-next-line no-return-assign */
                    onClick={() =>
                      openMeetingURL(activeClass.klass.meeting_url.toString())
                    }
                    sx={meetingBtn}
                    variant="contained"
                    endIcon={<VideocamOutlinedIcon />}
                  >
                    Join Class
                  </Button>
                )}
                <Typography
                  sx={
                    activeClass.klass.meeting_url
                      ? gridItemTeacherName
                      : gridItemTeacherName1
                  }
                >
                  {activeClass.klass.teacher.first_name}{" "}
                  {activeClass.klass.teacher.last_name}
                </Typography>
              </Box>
            </Grid>
            <Grid item md={4} xs={4}>
              <Box sx={gridItemBox1}>
                <Typography
                  sx={
                    searchParams.get("type") === "assignments"
                      ? assignmentsActiveTab
                      : assignmentsTab
                  }
                  onClick={() => setType("assignments")}
                >
                  Assignments
                </Typography>
                <Typography
                  sx={
                    searchParams.get("type") === "files"
                      ? filesActiveTab
                      : filesTab
                  }
                  onClick={() => setType("files")}
                >
                  Files
                </Typography>
              </Box>
            </Grid>
            <Grid item md={2} xs={2}>
              <Box sx={gridItemBox2} style={{ display: setDisplay }}>
                <Typography
                  sx={
                    termGradeLetter(activeClass, specialMarks).length > 1
                      ? gradeTag
                      : gradeTag1
                  }
                >
                  {termGradeLetter(activeClass, specialMarks)}
                </Typography>
              </Box>
              <Box sx={setDisplay ? gridItemBox5 : gridItemBox3} />
              <Box sx={gridItemBox4} style={{ display: setDisplay }}>
                <Typography sx={currentGrade}>Current</Typography>
                <Typography sx={currentGrade}>Grade</Typography>
              </Box>
            </Grid>
          </Grid>

          {!showDetailedAssignment ? (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {searchParams.get("type") === "assignments" ? (
                <>
                  <Grid container>
                    <Grid item md={12} xs={12} sx={gridItem1}>
                      <Button
                        onClick={() => setAssignmentsType("all")}
                        sx={
                          searchParams.get("assignments") === "all"
                            ? assignmentsTypeBtn
                            : assignmentsTypeBtnInactive1
                        }
                      >
                        All Assignments
                      </Button>
                      <Button
                        onClick={() => setAssignmentsType("missing")}
                        sx={
                          searchParams.get("assignments") === "missing"
                            ? assignmentsTypeBtn1
                            : assignmentsTypeBtnInactive
                        }
                      >
                        Missing Assignments
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item md={12} xs={12}>
                      <AssignmentsTable
                        assignments={filterAssignments(
                          activeClass.klass.assignments
                        )}
                        type={searchParams.get("assignments")}
                        specialMarks={specialMarks}
                        klassIsPassFail={activeClass.klass.is_pass_fail}
                        klassMarkingCodes={
                          activeClass.klass?.grading_scale?.marking_codes
                        }
                        setActiveAssignment={setActiveAssignment}
                        setShowDetailedAssignment={setShowDetailedAssignment}
                        setActiveAssignmentGrade={setActiveAssignmentGrade}
                      />
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Box sx={filesDiv}>Files coming soon...</Box>
              )}
            </>
          ) : (
            <AssignmentDetail
              setShowDetailedAssignment={setShowDetailedAssignment}
              activeAssignment={activeAssignment}
              activeAssignmentGrade={activeAssignmentGrade}
            />
          )}
        </>
      )}
    </>
  );
}
