import React from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Box, Tooltip } from "@mui/material";
import gradeAlertIconContainer from "./GradeAlertIcon.styles";

export default function GradeAlertIcon({ isGreen, isForSkill, isReportCard }) {
  if (isForSkill) {
    const title = isReportCard
      ? "Gradebook term grade and/or skill changed but not posted"
      : "Skills are entered but not posted";
    return (
      <Tooltip title={title} placement="left-start" arrow>
        <ErrorOutlineIcon color="warning" />
      </Tooltip>
    );
  }

  const color = isGreen ? "success" : "error";

  return (
    <Box sx={gradeAlertIconContainer}>
      <Tooltip title="Grade override" placement="left-start" arrow>
        <ErrorOutlineIcon color={color} />
      </Tooltip>
    </Box>
  );
}
