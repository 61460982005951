import React from "react";
import { Grid, Box, Typography, Link, Container } from "@mui/material";
import { Link as RouterLink, useParams } from "react-router-dom";
import { ArrowCircleRight } from "@mui/icons-material";
import SimpleCard from "../SimpleCard";
import {
  entryScreenBox,
  entryScreenContainer,
  entryScreenLogo,
} from "./EntryScreen.style";

export default function EntryScreen({ teacher }) {
  const params = useParams();
  const schoolId = params.school_id;
  const teacherName = teacher?.first_name;

  return (
    <Grid container spacing={0} alignItems="center" justifyContent="center">
      <Grid item xs={12}>
        <Container style={entryScreenContainer}>
          <img src="/assets/logo-new.png" style={entryScreenLogo} alt="logo" />
        </Container>

        <Typography variant="title" align="center">
          <p>
            Welcome, {teacherName}! Where would you like to go? <br /> &nbsp;
          </p>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Box sx={entryScreenBox}>
          <Box m={2}>
            <Link to={`/school/${schoolId}/attendance`} component={RouterLink}>
              <SimpleCard
                isDark
                marginless
                title={<Typography variant="title">Take Attendance</Typography>}
                icon={<ArrowCircleRight fontSize="large" />}
              />
            </Link>
          </Box>
          <Box m={2}>
            <Link to={`/school/${schoolId}/students`} component={RouterLink}>
              <SimpleCard
                isDark
                marginless
                title={<Typography variant="title">My Students</Typography>}
                icon={<ArrowCircleRight fontSize="large" />}
              />
            </Link>
          </Box>
          <Box m={2}>
            <Link
              to={`/school/${schoolId}/current-subjects`}
              component={RouterLink}
            >
              <SimpleCard
                isDark
                marginless
                title={<Typography variant="title">My Subjects</Typography>}
                icon={<ArrowCircleRight fontSize="large" />}
              />
            </Link>
          </Box>
          <Box m={2}>
            <Link
              to={`/school/${schoolId}/staff/staffs`}
              component={RouterLink}
            >
              <SimpleCard
                isDark
                marginless
                title={<Typography variant="title">Staff Members</Typography>}
                icon={<ArrowCircleRight fontSize="large" />}
              />
            </Link>
          </Box>
        </Box>
      </Grid>

      {/*      <Grid item xs={12}>
        <Container sx={entryScreenCheckboxContainer}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox sx={entryScreenCheckbox} />}
              label="Save my selection as my default"
            />
          </FormGroup>
        </Container>
      </Grid> */}
    </Grid>
  );
}
