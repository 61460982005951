import React, { useEffect, useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Box,
  Stack,
  Typography,
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
  Autocomplete,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useSearchParams } from "react-router-dom";
import {
  recipientsContainer,
  recipientsTitle,
  autocompleteLabel,
} from "./Recipients.styles";
import messagesService from "../../../service/messagesService";
import studentsService from "../../../service/studentsService";
import { fetchGuardian } from "../../../service/guardiansService";
import formatFullName from "../../../utils/formatFullName";

export default function Recipients({
  isAlert,
  setIsAlert,
  schoolId,
  setRecipientsValue,
  recipientsValue,
  studentId,
  parentIds,
}) {
  const {
    watch,
    register,
    control,
    setValue,
    formState: { errors },
  } = useFormContext();
  const [recipients, setRecipients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const getRecipients = async () => {
    setLoading(true);
    if ((studentId || parentIds.length > 0) && recipients.length === 0) {
      const defaultRecipient = [];

      if (parentIds.length > 0) {
        parentIds.map(async (id) => {
          const parent = await fetchGuardian(
            id,
            searchParams.get("student_id")
          );

          if (parent) {
            defaultRecipient.push({
              name: formatFullName(parent.data),
              target: "single_guardian",
              guardian_id: id,
            });
          }
        });
        setTimeout(() => {
          setRecipientsValue(defaultRecipient);
          setRecipients(defaultRecipient);
        }, 500);
      } else {
        const student = await studentsService.fetchStudent(studentId, {
          params: { school_id: schoolId },
        });

        if (student) {
          defaultRecipient.push({
            grade_level: student.grade_level.downcase,
            klass_ids: student.klass_ids,
            name: `${formatFullName(student)} - Student`,
            student_id: student.id,
            target: "single_student",
            teacher_id: student.homeroom_teacher_id,
          });
        }
      }

      setRecipientsValue(defaultRecipient);
      setRecipients(defaultRecipient);
    }

    setLoading(false);

    if (!parentIds.length > 0 && watch("recipients")) {
      const response = await messagesService.fetchRecipients({
        params: {
          school_id: schoolId,
          query: watch("recipients")?.toLowerCase(),
        },
      });
      if (response.data) {
        const responseWithId = response.data.map((obj, index) => ({
          ...obj,
          ui: index + 1,
        }));
        setRecipients(responseWithId);
        setLoading(false);
      } else {
        setRecipients([]);
        setLoading(false);
      }
    }
  };

  const checkIfSelected = (option, value) => {
    // Selecting a guardian
    if (value.target === "single_guardian") {
      return option.guardian_id === value.guardian_id;
    }

    if (value.target === "all_guardians") {
      return option.guardian_id || option.target === "all_guardians";
    }

    // Selecting a student
    if (value.target === "single_student") {
      return option.student_id === value.student_id;
    }

    if (value.target === "all_students") {
      return option.student_id || option.target === "all_students";
    }

    // Selecting a class with students
    if (value.target === "klass_students") {
      // Selects all students for the class
      if (option.klass_ids && option.target === "single_student") {
        return option.klass_ids.find((k) => k === value.klass_id);
      }

      if (option.klass_id && option.target === "klass_students") {
        return option.klass_id === value.klass_id;
      }
    }

    // Selecting a class with guardians
    if (value.target === "klass_guardians") {
      // Selects all students parents for the class
      if (option.klass_ids && option.target === "single_guardian") {
        return option.klass_ids.find((k) => k === value.klass_id);
      }

      if (option.klass_id && option.target === "klass_guardians") {
        return option.klass_id === value.klass_id;
      }
    }

    // Selecting a grade with students
    if (value.grade && value.target === "grade_students") {
      // Selects all students for the selected grade
      if (option.grade_level && option.target === "single_student") {
        return option.grade_level === value.grade;
      }

      if (option.grade && option.target === "grade_students") {
        return option.grade === value.grade;
      }
    }

    // Selecting a grade with guardians
    if (value.grade && value.target === "grade_guardians") {
      // Selects all student's guardians for the selected grade
      if (option.grade_level && option.target === "single_guardian") {
        return option.grade_level === value.grade;
      }

      if (option.grade && option.target === "grade_guardians") {
        return option.grade === value.grade;
      }
    }

    // Selecting all students and guardians for selected grade
    if (value.grade && value.target === "all_grades") {
      if (option.grade_level) {
        return option.grade_level === value.grade;
      }

      if (option.grade && option.target === "all_grades") {
        return option.grade === value.grade;
      }
    }

    // Selecting a teacher
    if (value.target === "single_teacher") {
      return (
        option.target === "single_teacher" &&
        option.teacher_id === value.teacher_id
      );
    }

    if (value.target === "all_teachers") {
      return (
        (option.target === "single_teacher" && option.teacher_id) ||
        option.target === "all_teachers"
      );
    }

    // Selecting my homeroom students
    if (value.teacher_id && value.target === "homeroom_students") {
      // Selects all students for the current user
      if (option.student_id) {
        return option.teacher_id === value.teacher_id;
      }

      return option.target === "homeroom_students";
    }

    // Selecting my homeroom parents
    if (value.teacher_id && value.target === "homeroom_parents") {
      // Selects all parents for the current user
      if (option.guardian_id) {
        return option.teacher_id === value.teacher_id;
      }

      return option.target === "homeroom_parents";
    }

    // Selecting my students
    if (value.teacher_id && value.target === "my_students") {
      // Selects all students that are in the classes the current user teaches
      if (option.klass_ids && option.student_id) {
        return (
          option.klass_ids.filter((klass) =>
            value.teacher_klass_ids.includes(klass)
          ).length > 0
        );
      }

      return option.target === "my_students";
    }

    // Selecting my students parents
    if (value.teacher_id && value.target === "my_students_parents") {
      // Selects all students parents that are in the classes the current user teaches
      if (option.klass_ids && option.guardian_id) {
        return (
          option.klass_ids.filter((klass) =>
            value.teacher_klass_ids.includes(klass)
          ).length > 0
        );
      }

      return option.target === "my_students_parents";
    }

    // Selecting school groups
    if (value.group_id && value.target === "school_groups") {
      // Selects all students and staff that are members of the group
      if (option.student_id && option.target === "single_student") {
        const studentMembers = value.group_members
          .filter((gm) => gm.member_type === "Student")
          .map((gm) => gm.member_id)
          .includes(option.student_id);

        return studentMembers;
      }

      if (option.teacher_id && option.target === "single_teacher") {
        const staffMembers = value.group_members
          .filter((gm) => gm.member_type === "Staff")
          .map((gm) => gm.member_id)
          .includes(option.teacher_id);

        return staffMembers;
      }

      return (
        option.group_id === value.group_id && option.target === "school_groups"
      );
    }

    return false;
  };

  useEffect(() => {
    getRecipients();
    setValue("noReply", isAlert);
  }, [watch("recipients"), isAlert]);

  return (
    <Box>
      <Box sx={recipientsContainer}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography sx={recipientsTitle}>Recipients</Typography>
          <Stack direction="row">
            <FormControl>
              <FormControlLabel
                disabled={isAlert}
                control={
                  <Controller
                    name="broadcast"
                    control={control}
                    defaultValue={false}
                    render={({ field: props }) => (
                      <Checkbox
                        {...props}
                        disabled={isAlert}
                        checked={!!props.value || isAlert}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                }
                label={
                  <Box>
                    <Typography>Broadcast</Typography>
                  </Box>
                }
              />
            </FormControl>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    {...register("isAlert", {
                      onChange: () => setIsAlert(!isAlert),
                    })}
                  />
                }
                label={
                  <Box>
                    <Typography>Is an alert</Typography>
                  </Box>
                }
              />
            </FormControl>
            <FormControl>
              <FormControlLabel
                disabled={isAlert}
                control={
                  <Controller
                    name="noReply"
                    control={control}
                    render={({ field: props }) => (
                      <Checkbox
                        {...props}
                        disabled={isAlert}
                        checked={!!props.value || isAlert}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                }
                label={
                  <Box>
                    <Typography>No reply</Typography>
                  </Box>
                }
              />
            </FormControl>
          </Stack>
        </Stack>
      </Box>

      <Box>
        <Stack direction="row" alignItems="center">
          <Typography sx={autocompleteLabel}>To:</Typography>
          <Controller
            render={({ field }) => (
              <Autocomplete
                {...field}
                onChange={(e, values) => {
                  setRecipientsValue(values);
                }}
                value={recipientsValue}
                openOnFocus
                noOptionsText="Search for recipients..."
                multiple
                fullWidth
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) =>
                  checkIfSelected(option, value)
                }
                options={recipients}
                loading={loading}
                disableCloseOnSelect
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.ui}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    error={errors?.recipients}
                    required={!recipientsValue}
                    {...params}
                    {...register("recipients", {
                      validate: () => recipientsValue.length > 0,
                    })}
                    label="Recipient"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            )}
            name="recipients"
            control={control}
          />
        </Stack>
      </Box>
    </Box>
  );
}
