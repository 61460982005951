const attendanceReportHeader = (isEnrolled) => ({
  borderBottom: "1px solid #D6D6D6",
  mb: "16px",
  "& p": {
    color: isEnrolled ? "black" : "#999999",
  },
});

const attendanceReportDay = {
  borderBottom: "1px solid #D6D6D6",
  textAlign: "center",
};

const attendanceReportInitialContent = {
  textAlign: "center",
  mt: "100px",
};

const attendanceCalendarRow = (isEnrolled) => ({
  mb: "25px",
  "& p": {
    color: isEnrolled ? "black" : "#999999",
  },
});

export {
  attendanceReportHeader,
  attendanceReportDay,
  attendanceReportInitialContent,
  attendanceCalendarRow,
};
