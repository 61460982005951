const reportCardTableRowCell = {
  color: "black",
  borderColor: "black",
  py: 0,
  position: "relative",
};

const reportCardTableRowCellName = (isEnrolled = true) => ({
  ...reportCardTableRowCell,
  minWidth: "220px",
  width: "220px",
  fontSize: "16px",
  padding: "0",
  position: "sticky",
  left: "50px",
  zIndex: "999",
  background: isEnrolled ? "white" : "#D9D9D9",
});

const reportCardTableRowCellGradeLevel = (isEnrolled = true) => ({
  ...reportCardTableRowCell,
  position: "sticky",
  left: "270px",
  zIndex: "999",
  background: isEnrolled ? "white" : "#D9D9D9",
});

const reportCardTableRowCellPublished = (isEnrolled = true) => ({
  ...reportCardTableRowCell,
  position: "sticky",
  left: "390px",
  zIndex: "999",
  background: isEnrolled ? "white" : "#D9D9D9",
});

const reportCardTableRowCellComment = (isEnrolled = true) => ({
  ...reportCardTableRowCell,
  position: "inherit",
  background: isEnrolled ? "white" : "#D9D9D9",
});

const reportCardTableRowCellGrade = {
  ...reportCardTableRowCell,
  px: 0,
  height: "70px",
  backgroundColor: "white",
  "& :hover": {
    cursor: "pointer",
    backgroundColor: "#ededed",
  },
  "& p": {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

const reportCardTableRowCellSquare = {
  height: "100%",
  fontSize: "16px",
  fontWeight: "400",
  textAlign: "center",
  borderBottom: "1px solid black",
  cursor: "pointer",
  padding: 0,
};

const reportCardTableRowBodyCell = {
  borderLeft: "1px solid #ededed",
  borderRight: "1px solid #ededed",
  marginBottom: "10px",
  marginTop: "10px",
  padding: "10px",
  height: "70px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
};

const reportCardIconBox = {
  position: "absolute",
  transform: "translateX(-35px)",
};

const reportCardIconFont = { fontSize: "26px" };

const reportCardDisabledCell = {
  ...reportCardTableRowCellSquare,
  backgroundColor: "#D9D9D9",
};

export {
  reportCardTableRowCell,
  reportCardTableRowCellName,
  reportCardTableRowCellGrade,
  reportCardTableRowCellSquare,
  reportCardTableRowBodyCell,
  reportCardIconBox,
  reportCardIconFont,
  reportCardDisabledCell,
  reportCardTableRowCellGradeLevel,
  reportCardTableRowCellComment,
  reportCardTableRowCellPublished,
};
