import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { Outlet, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GuardianTopNavBar, GuardianFooter } from "../components";
import messagesService from "../service/messagesService";
import notificationsService from "../service/notificationsService";
import {
  fetchGuardianKidInfo,
  fetchGuardianLanguage,
} from "../service/guardiansService";

export default function GuardianLayout({ defaultGuardianKidId, user }) {
  const [kidSchool, setKidSchool] = useState();
  const [hasUnreadMessages, setUnreadMessages] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState();
  const params = useParams();
  const [kidInfo, setKidInfo] = useState(null);
  const { i18n } = useTranslation();

  const getKidInfo = async (id, kidId) => {
    const response = await fetchGuardianKidInfo(id, kidId);
    setKidInfo(response.data);
  };

  const getUnreadMessages = async () => {
    const response = await messagesService.checkForUnreadMessages({
      params: { recipient_type: "Guardian" },
    });
    setUnreadMessages(response.data);
  };

  const getUnreadNotifications = async () => {
    const response = await notificationsService.checkForUnreadNotifications();
    setUnreadNotifications(response);
  };

  const getPreferredLanguage = async () => {
    const response = await fetchGuardianLanguage(params.id);
    i18n.changeLanguage(response.data);
  };

  useEffect(() => {
    if (params.id && params.kid_id) {
      getKidInfo(params.id, params.kid_id);
    }

    getUnreadMessages();
    getUnreadNotifications();
    getPreferredLanguage();
  }, [params]);

  return (
    <div style={{ minWidth: "1400px", backgroundColor: "#E5E5E5" }}>
      <GuardianTopNavBar
        defaultGuardianKidId={defaultGuardianKidId}
        hasUnreadMessages={hasUnreadMessages}
        unreadNotifications={unreadNotifications}
        user={user}
      />
      <Box sx={{ minWidth: "1400px", minHeight: "calc(96vh - 200px)" }}>
        <Outlet context={[setKidSchool, kidInfo]} />
      </Box>
      <GuardianFooter kidSchool={kidSchool} />
    </div>
  );
}
