import {
  Box,
  Button,
  Container,
  Grid,
  Popover,
  Tab,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  preKHeaderContainer,
  preKSelectTermBox,
  tabsBox,
  preKTab,
  preKContainer,
  selectBox,
  tableContainer,
  tableHead,
  tableStyle,
  tableHeadAge,
  tableHeadOptions,
  tableHeadName,
  sortBox,
  tableBorderRight,
  tableHeadCustomOptions,
  nameSort,
} from "./PreKDevelopmentTracker.styles";
import { font22, pageHeaderTitle } from "../components/sharedStyles";
import TermSelect from "../components/ReportCard/TermSelect";
import termService from "../service/termService";
import prekDevelopmentService from "../service/preKDevelopmentService";
import Row from "../components/PreKDevelopmentTracker/Row";
import { QuickBarContext } from "../context/QuickBarContext";
import { SnackbarContext } from "../context/SnackbarContext";
import {
  cellPopover,
  popoverButton,
  popoverHead,
  popoverGradeBox,
} from "../components/PreKDevelopmentTracker/Cell.styles";
import theme from "../utils";
import APP_PERMISSIONS from "../utils/constants/permissions";
import PERMISSION_TYPES from "../utils/constants/permission_types";
import { PermissionsContext } from "../context/PermissionsContext";

export default function PreKDevelopmentTracker() {
  const schoolId = useParams().school_id;
  const [terms, setTerms] = useState([]);
  const [selectedTerm, setSelectedTerm] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [domain, setDomain] = useState(
    searchParams.get("domain") || "Spiritual"
  );
  const [order, setOrder] = useState(searchParams.get("order") || "asc");
  const [domains, setDomains] = useState([]);
  const [domainElements, setDomainElements] = useState([]);
  let domainId = null;
  const [studentsComments, setStudentsComments] = useState([]);
  const [studentsGrades, setStudentsGrades] = useState([]);
  const quickbarContext = useContext(QuickBarContext);
  const snackbarContext = useContext(SnackbarContext);
  const [reloadComment, setReloadComment] = useState(false);
  const [reloadGrade, setReloadGrade] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openPopover = Boolean(anchorEl);
  const popoverId = openPopover ? "prek-popover" : undefined;
  const { hasAnyPermissionType, hasPermission } =
    useContext(PermissionsContext);
  const availableGrades = {
    I: "Independent",
    P: "Progressing",
    N: "Needs more time",
    "N/A": "Not applicable",
  };

  const managePermission = hasPermission(
    APP_PERMISSIONS.PK_TRACKER,
    PERMISSION_TYPES.MANAGE
  );

  const handlePopoverClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleGradeChange = async (event, grade) => {
    await handlePopoverClose();
    const deCells = document.getElementsByClassName(anchorEl.id);
    const deCellIds = [];
    for (let i = 0; i < deCells.length; i += 1) {
      const deCell = document
        .getElementsByClassName(anchorEl.id)
        [i].querySelector("input");
      if (!deCell.value) {
        deCellIds.push(deCell.id);
      }
    }

    if (deCellIds.length > 0) {
      const response =
        await prekDevelopmentService.updateStudentDomainElementGrade(
          deCellIds,
          grade
        );

      if (response) {
        setReloadGrade(true);
        snackbarContext.setSnackbar({
          message: "Grades saved.",
          severity: "success",
          open: true,
        });
      }
    }
  };

  const fetchAllTerms = async () => {
    const response = await termService.fetchAllTermsInSchool({
      params: {
        school_id: schoolId,
      },
    });

    if (response.data.terms) {
      setTerms(response.data.terms);
      setSelectedTerm(
        searchParams.get("term") && searchParams.get("term") !== "select"
          ? response.data.terms.find(
              (term) => term.id && term.id === Number(searchParams.get("term"))
            )
          : null
      );
    }
  };

  const fetchPrekDomains = async () => {
    const response = await prekDevelopmentService.fetchPrekDomains();

    if (response.data) {
      setDomains(response.data);
    }
  };

  const fetchPrekDomainElements = async () => {
    domainId = domains.find((d) => d.name === domain)?.id;
    const response = await prekDevelopmentService.fetchPrekDomainElements(
      domainId
    );

    if (response.data) {
      setDomainElements(response.data);
    }
  };

  const fetchStudentsComments = async () => {
    domainId = domains.find((d) => d.name === domain)?.id;
    const response = await prekDevelopmentService.fetchStudentsComments(
      domainId,
      selectedTerm.id
    );

    if (response.data) {
      setStudentsComments(response.data);
    }
  };

  const fetchStudentsGrades = async () => {
    domainId = domains.find((d) => d.name === domain)?.id;
    const response = await prekDevelopmentService.fetchStudentsGrades(
      domainId,
      selectedTerm.id,
      order
    );

    if (response.data) {
      setStudentsGrades(response.data);
    }
  };

  function findIdsToPost() {
    const idsToPost = [];
    const rows = document.getElementsByClassName("prek-tbody")[0].children;
    let postedCount = 0;

    for (let i = 0; i < rows.length; i += 1) {
      const gradeValues = [];
      const cells = rows[i].children;
      if (!cells[0].querySelector("svg").id) {
        postedCount += 1;
      }
      for (let j = 1; j < cells.length - 1; j += 1) {
        gradeValues.push(cells[j].querySelector("input").value);
      }
      if (!gradeValues.includes("") && rows[i].id) {
        idsToPost.push(rows[i].id);
      }
    }

    if (rows.length === postedCount) {
      return false;
    }

    return idsToPost;
  }

  const updatePostTermGrades = async (ids) => {
    const response = await prekDevelopmentService.postTermGrades(ids);

    if (response) {
      snackbarContext.setSnackbar({
        message: "Grades posted.",
        severity: "success",
        open: true,
      });
    }
  };

  // eslint-disable-next-line consistent-return
  const postTermGrades = async () => {
    const ids = findIdsToPost();

    if (!ids) {
      snackbarContext.setSnackbar({
        message: "All the students grades are already posted.",
        severity: "info",
        open: true,
      });
      return;
    }

    if (ids.length > 0) {
      await updatePostTermGrades(ids);
      await fetchStudentsComments();
    } else {
      snackbarContext.setSnackbar({
        message: "One or more students has missing grades.",
        severity: "info",
        open: true,
      });
    }
  };

  const handleChange = (event, newDomain) => {
    setDomain(newDomain);
    searchParams.set("domain", newDomain);
    setSearchParams(searchParams);
  };

  const cleanFilters = () => {
    setDomain("Spiritual");
    searchParams.set("domain", "Spiritual");
    setSearchParams(searchParams);
  };

  const renderSortChevron = () =>
    order === "asc" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;

  const handleSort = () => {
    let newOrder;
    if (order === "asc") {
      newOrder = "desc";
    } else {
      newOrder = "asc";
    }

    setOrder(newOrder);
    searchParams.set("order", newOrder);
    setSearchParams(searchParams);
  };

  function displayElementName(name) {
    if (["Spiritual", "Cognitive Development"].includes(domain)) {
      return name;
    }
    return name.split(":")[0];
  }

  useEffect(() => {
    (async () => {
      await fetchAllTerms();
      await fetchPrekDomains();
    })();
  }, []);

  useEffect(() => {
    if (reloadComment) {
      (async () => {
        await fetchStudentsComments();
        setReloadComment(false);
      })();
    }
  }, [reloadComment]);

  useEffect(() => {
    if (reloadGrade) {
      (async () => {
        await fetchStudentsGrades();
        setReloadGrade(false);
      })();
    }
  }, [reloadGrade]);

  useEffect(() => {
    if (domain && domains.length > 0 && selectedTerm !== null) {
      (async () => {
        await fetchPrekDomainElements();
        await fetchStudentsComments();
        await fetchStudentsGrades();
      })();
    }
  }, [domains, domain, selectedTerm, order]);

  useEffect(() => {
    (async () => {
      if (quickbarContext.postPKTermGrades === true) {
        await postTermGrades();
        quickbarContext.cleanFirePostPKTermGrades();
      }
    })();
  }, [quickbarContext.postPKTermGrades]);

  if (
    !hasAnyPermissionType(APP_PERMISSIONS.PK_TRACKER) &&
    !hasPermission(APP_PERMISSIONS.ALL_STUDENTS, PERMISSION_TYPES.MANAGE)
  ) {
    window.location.replace("/404");
  }

  return (
    <>
      <Container maxWidth={false} variant="header">
        <Box sx={preKHeaderContainer}>
          <Typography sx={pageHeaderTitle}>
            Pre-K Development Tracker
          </Typography>
          <Box sx={selectBox}>
            <TermSelect
              schoolId={schoolId}
              terms={terms}
              setSelectedTerm={setSelectedTerm}
              selectedTerm={selectedTerm}
              cleanFilters={cleanFilters}
            />
          </Box>
        </Box>

        <Container maxWidth="xl" sx={preKContainer}>
          {selectedTerm === null || !selectedTerm.id ? (
            <Box sx={preKSelectTermBox}>
              <Typography sx={font22}>
                Please select a term above to begin reviewing Pre-K Development.
              </Typography>
            </Box>
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {studentsGrades.length > 0 ? (
                <>
                  <Box sx={tabsBox}>
                    <Tabs value={domain} onChange={handleChange}>
                      {domains.map((d) => (
                        <Tab
                          label={d.name}
                          value={d.name}
                          sx={preKTab}
                          key={d.id}
                        />
                      ))}
                    </Tabs>
                  </Box>

                  <TableContainer sx={tableContainer}>
                    <Table sx={tableStyle} stickyHeader>
                      <TableHead sx={tableHead}>
                        <TableRow>
                          <TableCell sx={[tableHeadName, tableBorderRight]}>
                            <Box sx={sortBox}>
                              <Box sx={nameSort} onClick={handleSort}>
                                <Typography>NAME</Typography>
                                {renderSortChevron()}
                              </Box>
                              <Typography sx={tableHeadAge}>AGE</Typography>
                            </Box>
                          </TableCell>
                          {domainElements.map((de) => (
                            <TableCell
                              sx={tableBorderRight}
                              key={de.id}
                              id={`grading_input_${de.id}`}
                              onClick={(event) => {
                                if (managePermission) {
                                  handlePopoverClick(event);
                                }
                              }}
                            >
                              <Tooltip title={de.name} arrow>
                                <Typography
                                  sx={[
                                    tableHeadOptions,
                                    [
                                      "Spiritual",
                                      "Cognitive Development",
                                    ].includes(domain)
                                      ? null
                                      : tableHeadCustomOptions,
                                  ]}
                                >
                                  {displayElementName(de.name)}
                                </Typography>
                              </Tooltip>
                            </TableCell>
                          ))}
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableHead className="prek-tbody">
                        {studentsGrades.length > 0 &&
                          studentsGrades.map((student) => (
                            <Row
                              key={student[1][0].student.id}
                              student={student}
                              studentsComments={studentsComments}
                              setReloadComment={setReloadComment}
                              availableGrades={availableGrades}
                              isReportCardPublished={
                                student[1][0].student.report_cards.find(
                                  (rc) => rc.term_id === selectedTerm.id
                                )?.is_published
                              }
                              managePermission={managePermission}
                            />
                          ))}
                      </TableHead>
                    </Table>
                  </TableContainer>
                </>
              ) : (
                <Box sx={preKSelectTermBox}>
                  <Typography sx={font22}>
                    There are no students in the selected term.
                  </Typography>
                </Box>
              )}
            </>
          )}
        </Container>
      </Container>

      <Popover
        PaperProps={{
          elevation: 0,
          sx: cellPopover,
        }}
        id={popoverId}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Grid container mt={3}>
          <Grid item xs={12} align="center">
            <Typography
              variant="title"
              sx={{ color: theme.palette.text.black }}
            >
              Input Options
            </Typography>
          </Grid>
          <Grid item xs={12} mt={2} mb={1} align="center">
            <Typography variant="subtitle" sx={popoverHead}>
              The following options are <br /> allowed for this assessment.
            </Typography>
            {Object.keys(availableGrades).map((g) => (
              <Box key={g} sx={popoverGradeBox}>
                <Button
                  variant="outlined"
                  sx={popoverButton}
                  onClick={(event) => handleGradeChange(event, g)}
                >
                  {g}
                </Button>
                <Typography>{availableGrades[g]}</Typography>
              </Box>
            ))}
          </Grid>
        </Grid>
      </Popover>
    </>
  );
}
