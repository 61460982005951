import React, { useEffect, useState } from "react";
import CloudIcon from "@mui/icons-material/Cloud";
import LinkIcon from "@mui/icons-material/Link";
import { useForm } from "react-hook-form";
import { useParams, useOutletContext } from "react-router-dom";
import {
  Container,
  Box,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  CircularProgress,
  Stack,
  TextField,
  Button,
} from "@mui/material";
import {
  klassSelectTitle,
  klassSelectConfirmContainer,
  klassSelectColumnContainer,
  klassSelectCourseContainer,
  klassSelectStudent,
  klassSelectGoogleStudent,
  klassSelectConfirmBtn,
  klassSelectContainer,
  klassSelectErrorMsg,
  klassSelectLinkIcon,
} from "./KlassSelect.styles";
import { mb10, bgWhite, loader, mb25, mb15 } from "../../sharedStyles";
import googleService from "../../../service/googleService";
import klassesStudentsService from "../../../service/klassesStudentsService";
import klassesService from "../../../service/klassesService";
import moment from "../../../utils/constants/momentConfig";
import dateTimeFormats from "../../../utils/constants/dateTimeFormats";

export default function KlassSelect() {
  const [, , teacher] = useOutletContext();
  const { register, watch } = useForm();
  const [googleCourses, setGoogleCourses] = useState([]);
  const [googleStudents, setGoogleStudents] = useState([]);
  const [klassStudents, setKlassStudents] = useState([]);
  const [googleCourseWork, setGoogleCourseWork] = useState([]);
  const [matchers, setMatchers] = useState([]);
  const [isLoadingCourseWorks, setLoadingCourseWorks] = useState(false);
  const params = useParams();

  const selectedCourse = watch("courses");

  const getGoogleKlasses = async () => {
    const response = await googleService.getRequest({
      params: {
        endpoint: "courses",
      },
    });

    if (response.data) {
      setGoogleCourses(response.data);
    }

    if (response.data === "invalid_grant") {
      const redirectParams = {
        klass_id: params.klass_id,
        school_id: params.school_id,
        user_id: teacher.user_id,
      };
      const encodedParams = btoa(JSON.stringify(redirectParams));

      window.location.replace(`/auth/google_oauth2?state=${encodedParams}`);
    }
  };

  const getKlassStudents = async () => {
    const response = await klassesStudentsService.fetchAllKlassesStudents({
      params: { klass_id: params.klass_id },
    });

    if (response.data) {
      setKlassStudents(response.data);
    }
  };

  const getGoogleStudents = async () => {
    const response = await googleService.getRequest({
      params: { endpoint: "students", courseId: selectedCourse },
    });

    if (response.data.students) {
      const students = [];
      const klassStudentsArr = [];

      response.data.students.map((student) =>
        students.push({
          id: student.user_id,
          email: student.profile.email_address,
          name: student.profile.name.full_name,
        })
      );

      const mappedGoogleStudents = students.map((x) => x.email);

      const sortedKlassStudents = klassStudents.students.sort((a, b) => {
        if (mappedGoogleStudents.indexOf(b.primary_email) === -1) return -1;
        if (mappedGoogleStudents.indexOf(a.primary_email) === -1) return 1;
        return (
          mappedGoogleStudents.indexOf(a.primary_email) -
          mappedGoogleStudents.indexOf(b.primary_email)
        );
      });

      sortedKlassStudents.map((sks) =>
        klassStudentsArr.push({
          id: sks.id,
          email: sks.primary_email,
          name: `${sks.first_name} ${sks.last_name}`,
        })
      );

      const mappedKlassStudents = klassStudentsArr.map((x) => x.email);

      const sortedGoogleStudents = students.sort((a, b) => {
        if (mappedKlassStudents.indexOf(b.email) === -1) return -1;
        if (mappedKlassStudents.indexOf(a.email) === -1) return 1;
        return (
          mappedKlassStudents.indexOf(a.email) -
          mappedKlassStudents.indexOf(b.email)
        );
      });

      setMatchers(klassStudentsArr);

      setGoogleStudents(sortedGoogleStudents);
    }
  };

  const getCourseWork = async () => {
    setLoadingCourseWorks(true);
    const response = await googleService.getRequest({
      params: { endpoint: "courseWork", courseId: selectedCourse },
    });
    const responseTopics = await googleService.getRequest({
      params: { endpoint: "topics", courseId: selectedCourse },
    });
    const responseGrades = await googleService.getRequest({
      params: {
        endpoint: "studentSubmissions",
        courseId: selectedCourse,
      },
    });

    if (response.data && responseTopics.data && responseGrades.data) {
      const courseWorks = [];
      const studentScores = [];

      responseGrades.data.student_submissions?.map((ss) =>
        studentScores.push({
          id: ss.id,
          studentId: ss.user_id,
          courseWorkId: ss.course_work_id,
          assignedGrade: ss.assigned_grade,
          state: ss.state,
        })
      );

      response.data.course_work?.map((courseWork) => {
        const topicName = responseTopics.data.topic?.find(
          (topic) => topic.topic_id === courseWork.topic_id
        )?.name;

        const students =
          courseWork.assignee_mode === "ALL_STUDENTS"
            ? courseWork.assignee_mode
            : courseWork.individual_students_options.student_ids;

        const dueDate = courseWork.due_date
          ? moment(courseWork.due_date)
              .subtract(1, "M")
              .format(dateTimeFormats.YYYYMMDD)
          : null;

        const dueTime = courseWork.due_time
          ? moment(courseWork.due_time).format(dateTimeFormats.LT)
          : null;

        return courseWorks.push({
          id: courseWork.id,
          title: courseWork.title,
          description: courseWork.description || null,
          assignedDate: courseWork.creation_time,
          maxScore: courseWork.max_points,
          studentScores: studentScores.filter(
            (ss) => ss.courseWorkId === courseWork.id
          ),
          gcAssignmentLink: courseWork.alternate_link,
          dueDate,
          dueTime,
          topicName,
          students,
        });
      });
      setGoogleCourseWork(courseWorks);
      setLoadingCourseWorks(false);
    }
  };

  const confirmConnection = async () => {
    setLoadingCourseWorks(true);
    const response = await klassesService.connectGoogleClassroom({
      klass_id: params.klass_id,
      gc_course_id: selectedCourse,
      gc_students: googleStudents,
      gc_assignments: googleCourseWork,
      gc_course_link: googleCourses.find((c) => c.id === selectedCourse)
        ?.alternate_link,
    });

    await googleService.getRequest({
      params: { endpoint: "registration", courseId: selectedCourse },
    });

    await googleService.getRequest({
      params: { endpoint: "registration", courseId: selectedCourse },
    });

    if (response.data) {
      window.location.replace(`/school/${params.school_id}/current-classes`);
    }
  };

  const handleStudentChange = (value, index) => {
    const students = [...matchers];
    const targetStudent = students[index];
    targetStudent.email = value;
    setMatchers(students);
  };

  useEffect(() => {
    getKlassStudents();
  }, []);

  useEffect(() => {
    getGoogleKlasses();

    if (selectedCourse) {
      getGoogleStudents();
      getCourseWork();
    }
  }, [selectedCourse]);

  const isAllValueMatched = googleStudents.map(
    (gs, index) => gs.email === matchers[index]?.email
  );

  const isAllMatched = isAllValueMatched.every((isTrue) => isTrue);

  const hasEqualLength = googleStudents.length === matchers.length;

  const isDisabled = !isAllMatched || !hasEqualLength;

  const errorMsg = !hasEqualLength
    ? "Number of students doesn't match."
    : "Please confirm that all students are linked.";

  if (googleCourses.length > 0) {
    return (
      <Container maxWidth={false} variant="header">
        <Container maxWidth="lg">
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={klassSelectContainer}
          >
            <Box sx={klassSelectCourseContainer}>
              <Typography sx={klassSelectTitle}>
                {klassStudents?.klass.name}
              </Typography>

              <Box>
                <Typography sx={mb15}>
                  {`Select the Google Classroom you would like to connect to ${klassStudents?.klass.name}`}
                </Typography>

                <FormControl fullWidth>
                  <InputLabel sx={bgWhite}>
                    Connect to Google Classroom
                  </InputLabel>
                  <Select
                    label="courses"
                    {...register("courses")}
                    defaultValue=""
                  >
                    {googleCourses.map((gc) => (
                      <MenuItem key={gc.id} value={gc.id}>
                        {gc.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            {selectedCourse &&
              (googleStudents.length > 0 ? (
                <>
                  <Box sx={klassSelectColumnContainer}>
                    <Typography sx={mb10}>
                      Match the students from the google roster (left) to the AE
                      Connect roster (right).
                    </Typography>
                    <Stack direction="row" justifyContent="space-between">
                      <Box>
                        <Typography>
                          <b>Google Classroom</b>
                        </Typography>
                        <Typography
                          sx={mb25}
                        >{`${googleStudents.length} students`}</Typography>
                        {googleStudents.map((googleStudent) => (
                          <Stack direction="row" alignItems="center">
                            <TextField
                              key={googleStudent.id}
                              value={googleStudent.name}
                              sx={klassSelectGoogleStudent}
                              label="Google Student"
                              disabled
                            />
                            <LinkIcon sx={klassSelectLinkIcon} />
                          </Stack>
                        ))}
                      </Box>
                      <Box>
                        <Typography>
                          <b>{klassStudents?.klass.name}</b>
                        </Typography>
                        <Typography
                          sx={mb25}
                        >{`${klassStudents.students.length} students`}</Typography>
                        <Box>
                          {matchers.map((klassStudent, index) => (
                            <FormControl
                              fullWidth
                              key={klassStudent.id}
                              sx={klassSelectStudent}
                              error={!isAllValueMatched[index]}
                            >
                              <InputLabel sx={bgWhite}>
                                AE-Connect Student
                              </InputLabel>
                              <Select
                                label="ae-connect-student"
                                defaultValue={
                                  isAllValueMatched[index]
                                    ? klassStudent.email
                                    : 0
                                }
                                fullWidth
                                onChange={(e) =>
                                  handleStudentChange(e.target.value, index)
                                }
                              >
                                <MenuItem value={0}>No Match</MenuItem>
                                {klassStudents.students.map((student) => (
                                  <MenuItem
                                    key={student.id}
                                    value={student.primary_email}
                                  >
                                    {student.first_name} {student.last_name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          ))}
                        </Box>
                      </Box>
                    </Stack>
                  </Box>
                  <Box sx={klassSelectConfirmContainer}>
                    {!klassStudents?.lms_id ? (
                      <>
                        <Typography sx={mb10}>
                          Confirm your Google Classroom connection
                        </Typography>
                        <Button
                          sx={klassSelectConfirmBtn}
                          endIcon={<CloudIcon />}
                          onClick={() => confirmConnection()}
                          disabled={isDisabled || isLoadingCourseWorks}
                        >
                          {isLoadingCourseWorks
                            ? "Please wait..."
                            : "Confirm Google Classroom Link"}
                        </Button>

                        {isDisabled && (
                          <Typography sx={klassSelectErrorMsg}>
                            {errorMsg}
                          </Typography>
                        )}
                      </>
                    ) : (
                      <Button
                        sx={klassSelectConfirmBtn}
                        endIcon={<CloudIcon />}
                        disabled
                      >
                        This class has already been connected.
                      </Button>
                    )}
                  </Box>
                </>
              ) : (
                <CircularProgress sx={loader} size={100} />
              ))}
          </Stack>
        </Container>
      </Container>
    );
  }
  return <CircularProgress sx={loader} size={100} />;
}
