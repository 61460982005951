import {
  Box,
  Button,
  Stack,
  Typography,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  Link,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  useParams,
  Link as RouterLink,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import studentsService from "../../../service/studentsService";
import {
  formButtons,
  formHeader,
  formTitle,
  loader,
  font16,
  font14,
  tableAvatar,
  font20,
  submitButton,
} from "../../sharedStyles";
import {
  borderRight,
  filtersBorder,
  formStudentsAvatar,
  formStudentsCheckbox,
  formStudentsTable,
  assignmentsFilterButton,
} from "./reEnrollStudents.style";
import admissionProcessService from "../../../service/admissionProcessService";
import { SnackbarContext } from "../../../context/SnackbarContext";

export default function ReEnrollStudents({ setReEnrollDrawerOpen }) {
  const snackbarContext = useContext(SnackbarContext);
  const methods = useForm();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const [students, setStudents] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [selectedProcess, setSelectedProcess] = useState(null);
  const [gradeLevel, setGradeLevel] = useState("all");
  const [loading, setLoading] = useState(false);
  const [processes, setProcesses] = useState([]);
  const navigate = useNavigate();
  const levels = [
    "TK",
    "K",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
  ];
  const schoolId = params.school_id;

  const loadStudents = () => {
    setLoading(true);
    studentsService
      .enrolledInPreviousYears(searchParams.get("school_year"), gradeLevel)
      .then((response) => {
        setLoading(false);
        if (response.data) {
          setStudents(response.data);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleCheckboxClick = (_event, id, selectedState, setSelectedState) => {
    if (id === "all" && _event.target.checked) {
      const allSelected = students.map((s) => s.id);
      setSelectedState(allSelected);
    } else if (id === "all" && !_event.target.checked) {
      setSelectedState([]);
    } else {
      const selected = new Set(selectedState);

      if (selected.has(id)) {
        selected.delete(id);
      } else {
        selected.add(id);
      }

      if (selected.size !== selectedState.size) {
        setSelectedState(Array.from(selected));
      }
    }
  };

  const isSelected = (id, selectedState) => selectedState.indexOf(id) !== -1;
  const isAllSelected = students.length === selectedStudents.length;

  const createProcessInstances = () => {
    admissionProcessService
      .createMultipleProcessInstance(selectedProcess, selectedStudents)
      .then(() => {
        snackbarContext.setSnackbar({
          open: true,
          message: "Process started.",
          severity: "success",
        });
        navigate(
          `/school/${schoolId}/admissions/setup/in-process?school_year=${searchParams.get(
            "school_year"
          )}`
        );
        setReEnrollDrawerOpen(false);
      })
      .catch(() => {
        snackbarContext.setSnackbar({
          open: true,
          message: "Failed to start process.",
          severity: "error",
        });
      });
  };

  const onSubmit = async () => {
    if (!selectedProcess) {
      snackbarContext.setSnackbar({
        open: true,
        message: "Please select a process.",
        severity: "error",
      });
      return;
    }
    if (selectedStudents.length === 0) {
      snackbarContext.setSnackbar({
        open: true,
        message: "Please select a student.",
        severity: "error",
      });
      return;
    }
    createProcessInstances();
  };

  const handleStudentFilterClick = (value) => {
    setGradeLevel(value);
    setSelectedStudents([]);
  };

  useEffect(() => {
    admissionProcessService
      .fetchAllProcesses(searchParams.get("school_year"))
      .then((response) => {
        setProcesses(response.data);
      });
  }, []);

  useEffect(() => {
    loadStudents();
  }, [gradeLevel]);

  function handleProfilePictureSrc(student) {
    if (student?.image_url) {
      return (
        <img
          alt="profile_image"
          src={student.image_url.url}
          style={{
            borderRadius: "100px",
            width: "40px",
            height: "40px",
            objectFit: "cover",
          }}
        />
      );
    }
    return (
      <Avatar sx={tableAvatar}>
        {student.first_name.charAt(0).toUpperCase()}
      </Avatar>
    );
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {loading ? (
          <CircularProgress color="inherit" size={100} sx={loader} />
        ) : (
          <>
            <Stack direction="row" sx={formHeader}>
              <Typography sx={formTitle}>Re-Enroll Students</Typography>
              <Stack direction="row">
                <Button
                  sx={formButtons}
                  onClick={() => setReEnrollDrawerOpen(false)}
                >
                  Cancel
                </Button>
              </Stack>
            </Stack>
            <Grid container>
              <Grid item xs={12} sx={filtersBorder}>
                <Button
                  sx={assignmentsFilterButton(gradeLevel === "all")}
                  size="small"
                  onClick={() => handleStudentFilterClick("all")}
                >
                  All
                </Button>
                {levels.map((level) => (
                  <Button
                    sx={assignmentsFilterButton(gradeLevel === level)}
                    size="small"
                    key={level}
                    onClick={() => handleStudentFilterClick(level)}
                  >
                    {level}
                  </Button>
                ))}
              </Grid>
            </Grid>
            <Grid container spacing={2} p={2} mt={2}>
              <Grid item xs={7} pr={2} sx={borderRight}>
                {students.length > 0 ? (
                  <TableContainer sx={{ height: "70vh" }}>
                    <Table stickyHeader size="medium" sx={formStudentsTable}>
                      <TableHead>
                        <TableRow>
                          <TableCell padding="none" sx={formStudentsCheckbox}>
                            <Checkbox
                              onClick={(e) =>
                                handleCheckboxClick(
                                  e,
                                  "all",
                                  selectedStudents,
                                  setSelectedStudents
                                )
                              }
                              color="primary"
                              checked={isAllSelected}
                            />
                          </TableCell>
                          <TableCell />
                          <TableCell padding="none">
                            <Typography sx={font14}>NAME</Typography>
                          </TableCell>
                          <TableCell align="center" padding="none">
                            <Typography sx={font14}>GRADE</Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {students.map((s) => (
                          <TableRow hover tabIndex={-1} key={s.id}>
                            <TableCell padding="none" sx={formStudentsCheckbox}>
                              <Checkbox
                                onClick={(e) =>
                                  handleCheckboxClick(
                                    e,
                                    s.id,
                                    selectedStudents,
                                    setSelectedStudents
                                  )
                                }
                                color="primary"
                                checked={isSelected(s.id, selectedStudents)}
                              />
                            </TableCell>

                            <TableCell padding="none" sx={formStudentsAvatar}>
                              <Link
                                to={`/school/${schoolId}/students/${s.slug}/account`}
                                underline="none"
                                component={RouterLink}
                                target="_blank"
                              >
                                {handleProfilePictureSrc(s)}
                              </Link>
                            </TableCell>

                            <TableCell padding="none">
                              <Link
                                to={`/school/${schoolId}/students/${s.slug}/account`}
                                underline="none"
                                component={RouterLink}
                                target="_blank"
                              >
                                <Typography sx={font20}>
                                  {`${s.last_name}, ${s.first_name}`}
                                </Typography>
                              </Link>
                            </TableCell>
                            <TableCell align="center" padding="none">
                              <Typography sx={font16}>
                                {s.grade_level}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Box>No students found.</Box>
                )}
              </Grid>
              <Grid item xs={5} mt={3} container>
                <Box>
                  <Grid container rowSpacing={4}>
                    <Grid item xs={12}>
                      <Typography>Select a process</Typography>
                    </Grid>
                    <Grid item xs={12} pr={2}>
                      <FormControl fullWidth>
                        <InputLabel id="processLabel">Process</InputLabel>
                        <Select
                          labelId="processLabel"
                          id="process"
                          label="Process"
                          defaultValue=""
                          onChange={(e) => {
                            setSelectedProcess(e.target.value);
                          }}
                        >
                          {processes.map((process) => (
                            <MenuItem key={process.id} value={process.id}>
                              <Typography textAlign="left">
                                {process.name}
                              </Typography>
                            </MenuItem>
                          ))}
                          {!processes.length && (
                            <MenuItem key="select process" disabled>
                              <Typography textAlign="left">
                                Please create process
                              </Typography>
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Button sx={submitButton} type="submit">
                        <Typography>Initiate Process</Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </>
        )}
      </form>
    </FormProvider>
  );
}
