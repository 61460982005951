const navigationStructure = {
  tasks: {
    header: "TASKS",
    menu: [
      { label: "Add a student" },
      { label: "Add a staff member" },
      { label: "Take attendance" },
      { label: "Enter an assignment" },
      { label: "Grade an assignment" },
      { label: "Create a report card" },
      { label: "Create a transcript" },
      { label: "more.." },
    ],
  },
  controls: {
    header: "CONTROLS",
    menu: [
      { label: "System Status" },
      { label: "Global Notifications" },
      { label: "Organizations" },
      { label: "API Integrations" },
      { label: "eAdventist Sync" },
      { label: "Administrative Users" },
      { label: "Global List and Templates" },
      { label: "School Years" },
    ],
  },
  organizations: {
    header: "ORGANIZATIONS",
    menu: [
      { label: "Profile" },
      { label: "Staff Members" },
      { label: "DefinedLists" },
      { label: "Settings" },
      { label: "Years and Terms" },
      { label: "Bells Schedules" },
      { label: "Global List and Templates" },
      { label: "School Years" },
    ],
  },
  staff: {
    header: "STAFF",
    menu: [
      { label: "Staff Members" },
      { label: "Roles" },
      { label: "Groups" },
      { label: "Schedule" },
    ],
  },
  students: {
    header: "STUDENTS",
    menu: [
      { label: "Students" },
      { label: "Families" },
      { label: "Family Portal Configuration" },
      { label: "Groups" },
    ],
  },
  academics: {
    header: "ACADEMICS",
    menu: [
      { label: "Subjects" },
      { label: "Sections" },
      { label: "Schedule" },
      { label: "Grading options" },
      { label: "Standards" },
      { label: "Lesson Plans" },
      { label: "Assignments" },
      { label: "Report Cards" },
      { label: "Transcripts" },
    ],
  },
};

export default navigationStructure;
