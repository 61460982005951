import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import dayTemplateService from "../../../service/dayTemplateService";
import schoolDayService from "../../../service/schoolDayService";
import {
  font22,
  formButtons,
  formHeader,
  formTitle,
  danger,
} from "../../sharedStyles";
import { checkbox, form } from "../Terms/TermsForm.styles";
import { formControlStyle } from "./SchoolDays.styles";
import { requiredMessage } from "../../../utils/constants/forms";
import GenericSubmitButton from "../../GenericSubmitButton";
import schoolDayDesignationsService from "../../../service/schoolDayDesignationsService";

export default function SchoolDaysForm({
  setSnackBarOpen,
  handleSchoolDaysDrawer,
  schoolYearId,
}) {
  const [options, setOptions] = useState([]);
  const [weekDays, setWeekDays] = useState([]);
  const [dayTemplates, setDayTemplates] = useState([]);
  const [designation, setDesignation] = useState("");
  const [overwrite, setOverwrite] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  const onSubmit = async () => {
    if (weekDays.length === 0 || designation === "") {
      setShowErrors(true);
      return;
    }
    setShowErrors(false);
    let params = {};
    const isDesignation = options.some(
      (option) => `${option.id}-${option.created_at}` === designation
    );
    if (isDesignation) {
      params = {
        week_days: weekDays,
        school_day_designation_id: designation.split("-")[0],
        day_template_id: null,
        overwrite,
        school_year_id: schoolYearId,
      };
    } else {
      params = {
        week_days: weekDays,
        school_day_designation_id: null,
        day_template_id: designation.split("-")[0],
        overwrite,
        school_year_id: schoolYearId,
      };
    }
    const response = await schoolDayService.bulkUpdate(params);

    if (response.data) {
      setSnackBarOpen({
        open: true,
        message: "School days updated",
      });
      handleSchoolDaysDrawer(false);
    }
  };

  const getDayTemplates = async () => {
    const response = await dayTemplateService.fetchAllDayTemplates({
      params: {
        school_year_id: schoolYearId,
        order: "desc",
        order_by: "name",
      },
    });
    if (response.data) {
      setDayTemplates(response.data);
    }
  };

  const getSchoolDayDesignations = async () => {
    const response = await schoolDayDesignationsService.fetchAll();
    if (response.data) {
      setOptions(response.data);
    }
  };

  useEffect(() => {
    getDayTemplates();
    getSchoolDayDesignations();
  }, []);

  const handleCheckBoxChange = (event, day) => {
    if (event.target.checked) {
      setWeekDays([...weekDays, day]);
    } else {
      let days = weekDays;
      days = days.filter((_day) => _day !== day);
      setWeekDays(days);
    }
  };

  const handleOverwriteCheckBoxChange = (event) => {
    setOverwrite(event.target.checked);
  };

  const handleChange = (event) => {
    setDesignation(event.target.value);
  };

  return (
    <>
      <Stack direction="row" sx={formHeader}>
        <Typography sx={formTitle}>Bulk Edit School Days</Typography>
        <Stack direction="row">
          <Button
            sx={formButtons}
            onClick={() => handleSchoolDaysDrawer(false)}
          >
            Cancel
          </Button>
          <GenericSubmitButton
            text="Save"
            submittingText="Saving..."
            onClick={() => onSubmit()}
          />
        </Stack>
      </Stack>

      <Box sx={form}>
        <Stack mt={3}>
          <Typography sx={font22}>Set the following days:</Typography>
        </Stack>
        <Stack mt={2}>
          <Stack direction="row">
            {moment.weekdays().map((day) => (
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => handleCheckBoxChange(e, day)}
                    size="small"
                  />
                }
                label={day}
                key={day}
                sx={{ marginRight: 1 }}
              />
            ))}
          </Stack>
          {showErrors && weekDays.length === 0 && (
            <Typography sx={danger}>Select at least one day</Typography>
          )}
        </Stack>
        <Stack direction="row" mt={3} mb={3}>
          <Typography sx={font22} mr={2}>
            To:
          </Typography>
          <FormControl sx={{ ...formControlStyle, minWidth: 200 }} size="small">
            <InputLabel id="dayDesignation">Day Designation</InputLabel>
            <Select
              value={designation}
              onChange={handleChange}
              label="Day Designation*"
              labelId="dayDesignation"
            >
              {options.map((option) => (
                <MenuItem
                  key={option.category}
                  value={`${option.id}-${option.created_at}`}
                >
                  {option.name}
                </MenuItem>
              ))}
              <MenuItem disabled value="-1">
                Day Templates
              </MenuItem>
              {dayTemplates.map((dayTemplate) => (
                <MenuItem
                  key={dayTemplate.id}
                  value={`${dayTemplate.id}-${dayTemplate.created_at}`}
                  sx={{
                    paddingLeft: 4,
                  }}
                >
                  {dayTemplate.name}
                </MenuItem>
              ))}
            </Select>
            {showErrors && designation === "" && (
              <Typography sx={danger}>{requiredMessage}</Typography>
            )}
          </FormControl>
        </Stack>
        <Divider />
        <FormControlLabel
          sx={checkbox}
          control={
            <Checkbox
              checked={overwrite}
              onChange={handleOverwriteCheckBoxChange}
            />
          }
          label={
            <Box>
              <Typography>Overwrite existing day designations</Typography>
            </Box>
          }
        />
      </Box>
    </>
  );
}
