import React, { useState, useEffect, useContext, useRef } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import {
  Container,
  Box,
  Typography,
  Tabs,
  Tab,
  TableContainer,
  Table,
  TableBody,
  Stack,
} from "@mui/material";
import { pageHeaderTitle, font22 } from "../components/sharedStyles";
import {
  tabsBox,
  container,
  skillGrades,
  tableContainer,
  tableStyle,
} from "./SocialDevelopment.styles";
import SocialDevelopmentRow from "./SocialDevelopmentRow";
import SocialDevelopmentHead from "./SocialDevelopmentHead";
import TermSelect from "../components/TermSelect";
import gradingScaleService from "../service/gradingScaleService";
import socialDevelopmentScoreService from "../service/socialDevelopmentScoreService";
import socialDevelopmentCommentService from "../service/socialDevelopmentCommentService";
import { QuickBarContext } from "../context/QuickBarContext";
import { SnackbarContext } from "../context/SnackbarContext";
import Authorize from "../components/Authorize";
import { PermissionsContext } from "../context/PermissionsContext";
import APP_PERMISSIONS from "../utils/constants/permissions";
import PopoverComponent from "../components/ToolTip/ToolTip";
import { reportCardSelectTermBox } from "../components/ReportCard/ReportCardTable.styles";

export default function SocialDevelopment() {
  const params = useParams();
  const schoolId = params.school_id;
  const [searchParams, setSearchParams] = useSearchParams();
  const termId = searchParams.get("term") || null;
  const [level, setLevel] = useState(searchParams.get("level") || "TK-K");
  const [gradingScales, setGradingScales] = useState([]);
  const [studentsInCurrentLevel, setStudentsInCurrentLevel] = useState([]);
  const [socialDevelopmentScores, setSocialDevelopmentScores] = useState([]);
  const [reportCards, setReportCards] = useState([]);
  const [comments, setComments] = useState([]);
  const quickbarContext = useContext(QuickBarContext);
  const snackbarContext = useRef(useContext(SnackbarContext));
  const { hasAnyPermissionType } = useContext(PermissionsContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentTarget, setCurrentTarget] = useState(null);
  const [currentGradingScale, setCurrentGradingScale] = useState(null);
  const timeout = useRef();
  const createSocialDevelopmentScore = (studentSkillsArray) => {
    clearTimeout(timeout.current);
    snackbarContext.current.setSnackbar({
      message: "Saving score...",
      severity: "info",
      open: true,
    });
    timeout.current = setTimeout(async () => {
      const postSkill = await socialDevelopmentScoreService
        .createMultiple({
          student_skills: studentSkillsArray,
        })
        .catch(() =>
          snackbarContext.current.setSnackbar({
            message: "Error while saving scores.",
            severity: "error",
            open: true,
          })
        );

      if (postSkill) {
        snackbarContext.current.setSnackbar({
          message: "Saved successfully",
          severity: "success",
          open: true,
        });
      }
    }, 2000);
  };

  const handleGradeChange = (anchor, targetClass, gs) => {
    setAnchorEl(anchor);
    setCurrentTarget(targetClass);
    setCurrentGradingScale(gs);
  };

  const handleTooltipInput = (input) => {
    setAnchorEl(null);
    const inputs = document.getElementsByClassName(currentTarget);
    const studentSkillsArray = [];
    for (let i = 0; i < inputs.length; i += 1) {
      if (!inputs[i].value) {
        inputs[i].value = input.toString();
        const splitIds = inputs[i].id.split("_");
        const studentId = Number(splitIds[0]);
        const skillId = splitIds[1];
        studentSkillsArray.push({
          term_id: Number(termId),
          student_id: studentId,
          social_development_skill_id: Number(skillId),
          score: input.toString(),
        });
      }
    }
    setSocialDevelopmentScores((prevScores) => [
      ...prevScores,
      ...studentSkillsArray,
    ]);
    createSocialDevelopmentScore(studentSkillsArray);
  };

  const getStudentsByLevel = (_gradingScales) =>
    _gradingScales.find((_gradingScale) => _gradingScale.name === level)
      ?.students;

  const numberOfSkillsInCurrentLevel = gradingScales.find(
    (_gradingScale) => _gradingScale.name === level
  )?.social_development_skills.length;

  const getSdComments = async () => {
    const response = await socialDevelopmentCommentService.fetchAll({
      params: {
        term_id: termId,
      },
    });
    if (response.data) {
      setComments(response.data);
    }
  };

  const getSdScores = async (loadComments = true) => {
    if (termId && termId !== "null") {
      const response = await socialDevelopmentScoreService.fetchAll({
        params: {
          term_id: termId,
        },
      });
      if (response.data) {
        setSocialDevelopmentScores(response.data.filter((item) => item.score));
      }
      if (loadComments) {
        getSdComments();
      }
    }
  };

  const getSdSkillsAndStudents = async () => {
    if (termId !== null && termId !== "null") {
      const response =
        await gradingScaleService.fetchAllWithSocialDevelopmentSkillsAndStudents(
          {
            params: {
              school_id: schoolId,
              term_id: termId,
            },
          }
        );
      if (response.data) {
        setReportCards(response.report_cards);
        setGradingScales(response.data);
        setStudentsInCurrentLevel(getStudentsByLevel(response.data));
      }
      getSdScores();
    }
  };

  useEffect(() => {
    (async () => {
      if (quickbarContext.postSdSkills === true) {
        const studentScoresMap = new Map();

        const scoresInCurrentLevel = socialDevelopmentScores.filter(
          (score) =>
            score.score !== "" &&
            studentsInCurrentLevel
              .map((student) => student.id)
              .includes(score.student_id)
        );

        scoresInCurrentLevel.forEach((sdScore) => {
          const studentIdAlreadyInMap = studentScoresMap.get(
            sdScore.student_id
          );
          if (studentIdAlreadyInMap) {
            studentIdAlreadyInMap.push(sdScore.score);
          } else {
            studentScoresMap.set(sdScore.student_id, [sdScore.score]);
          }
        });
        const ids = [];
        // eslint-disable-next-line no-unused-vars,no-restricted-syntax
        for (const [
          studentId,
          studentScoresArray,
        ] of studentScoresMap.entries()) {
          if (studentScoresArray.length === numberOfSkillsInCurrentLevel) {
            ids.push(studentId);
          }
        }

        if (ids.length) {
          await socialDevelopmentScoreService.postSkills({
            ids,
          });

          await getSdScores();
        }
        quickbarContext.cleanFirePostSdSkills();

        const allStudentsPosted = studentsInCurrentLevel.length === ids.length;
        if (!allStudentsPosted) {
          snackbarContext.current.setSnackbar({
            message: "One or more students didn't post.",
            severity: "info",
            open: true,
          });
        }
      }
    })();
  }, [quickbarContext.postSdSkills]);

  useEffect(() => {
    if (termId && termId !== "null") {
      getSdSkillsAndStudents();
      searchParams.set("level", level);
      setSearchParams(searchParams);
    }
  }, [termId]);

  useEffect(() => {
    setStudentsInCurrentLevel(getStudentsByLevel(gradingScales));
    searchParams.set("level", level);
    setSearchParams(searchParams);
  }, [level]);

  const handleChange = (event, newLevel) => {
    setLevel(newLevel);
  };

  const getComment = (student) => {
    const socialDevelopmentComment = comments.find(
      (_comment) =>
        _comment.student_id === student.id &&
        _comment.term_id === Number(termId)
    );

    return socialDevelopmentComment?.comment || null;
  };

  return (
    <Authorize
      permission={
        hasAnyPermissionType(APP_PERMISSIONS.SOCIAL_DEVELOPMENT_WORK_ETHIC) ||
        hasAnyPermissionType(APP_PERMISSIONS.ALL_STUDENTS)
      }
    >
      <Container maxWidth={false} variant="header">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography sx={pageHeaderTitle}>
            Social Development/Work Ethic
          </Typography>
          <TermSelect schoolId={schoolId} defaultNull />
        </Stack>
        <Container maxWidth="xl">
          {termId && termId === "null" ? (
            <Box sx={reportCardSelectTermBox}>
              <Typography sx={font22}>
                Please select a term above to begin reviewing social
                development.
              </Typography>
            </Box>
          ) : (
            <>
              <Box sx={tabsBox}>
                <Tabs value={level} onChange={handleChange}>
                  <Tab label="TK-K" value="TK-K" sx={font22} />
                  <Tab label="1-2" value="1-2" sx={font22} />
                  <Tab label="3-8" value="3-8" sx={font22} />
                </Tabs>
                <Typography sx={skillGrades}>Skill Grades</Typography>
              </Box>

              <Box sx={container}>
                <TableContainer sx={tableContainer}>
                  <Table sx={tableStyle} stickyHeader>
                    <SocialDevelopmentHead
                      gradingScales={gradingScales}
                      level={level}
                      handleGradeChange={handleGradeChange}
                    />
                    <TableBody>
                      {gradingScales &&
                        studentsInCurrentLevel?.map((student) => (
                          <SocialDevelopmentRow
                            key={student.id}
                            student={student}
                            gradingScales={gradingScales}
                            level={level}
                            socialDevelopmentScores={socialDevelopmentScores}
                            termId={termId}
                            numberOfSkillsInCurrentLevel={
                              numberOfSkillsInCurrentLevel
                            }
                            comment={getComment(student)}
                            getSdScores={getSdScores}
                            getSdComments={getSdComments}
                            reportCards={reportCards}
                          />
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </>
          )}
        </Container>
      </Container>
      <PopoverComponent
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        handleClick={handleTooltipInput}
        currentTarget={currentTarget}
        showSpecialMarks={false}
        specialMarks={null}
        skill={null}
        markingCodes={currentGradingScale?.marking_codes}
        gradingScale={currentGradingScale}
        render
        isLetterGrade
      />
    </Authorize>
  );
}
