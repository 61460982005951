import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Container,
  Divider,
  Drawer,
  Grid,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useOutletContext, useSearchParams, useParams } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import {
  cursorPointer,
  font22,
  loader,
  mr15,
  pageHeaderTitle,
  py30,
  rightDrawerContainer,
  switchBtn,
} from "../components/sharedStyles";
import {
  nameHeader,
  descHeader,
  divider,
  coursesListHead,
  coursesListTable,
  tableContainer,
  emptyCourseBox,
  settingsCell,
} from "./Courses.styles";
import courseService from "../service/courseService";
import { SnackbarContext } from "../context/SnackbarContext";
import CourseForm from "../components/Courses/CourseForm/CourseForm";
import subjectService from "../service/subjectService";
import departmentsService from "../service/departmentsService";
import truncateString from "../utils/truncateString";
import gpaScaleService from "../service/gpaScaleService";
import APP_PERMISSIONS from "../utils/constants/permissions";
import PERMISSION_TYPES from "../utils/constants/permission_types";
import { PermissionsContext } from "../context/PermissionsContext";

export default function Courses() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [order, setOrder] = useState(searchParams.get("direction") || "desc");
  const [orderBy, setOrderBy] = useState(searchParams.get("sort"));
  const [courses, setCourses] = useState([]);
  const snackbarContext = useContext(SnackbarContext);
  const [courseDrawerStatusEdit, setCourseDrawerStatusEdit] = useState(false);
  const [activeCourse, setActiveCourse] = useState(null);
  const [
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    currentSchool,
    courseDrawerStatus,
    courseDrawerAction,
    setCourseDrawerAction,
    subjects,
    setSubjects,
    predefinedCourses,
    setPredefinedCourses,
    departments,
    setDepartments,
    gpaScales,
    setGpaScales,
  ] = useOutletContext();
  const [courseDrawerActionEdit, setCourseDrawerActionEdit] = useState(null);
  const params = useParams();
  const { schoolId } = params;
  const [loading, setLoading] = useState(false);

  const handleCourseDrawer = (status, course, action) => {
    setCourseDrawerStatusEdit(status);
    setCourseDrawerActionEdit(action);
    if (status === false) {
      setActiveCourse(null);
    } else {
      setActiveCourse(course);
    }
  };

  // eslint-disable-next-line no-unused-vars,consistent-return
  const renderSortChevron = (_label) => {
    if (searchParams.get("sort") !== null) {
      return order === "asc" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    const direction = isAsc ? "desc" : "asc";
    setOrder(direction);
    setOrderBy(property);

    searchParams.set("sort", property);
    searchParams.set("direction", direction);
    setSearchParams(searchParams);
  };

  const loadCourses = () => {
    (async () => {
      setLoading(true);
      const response = await courseService.fetchAllCourses({
        params: {
          order,
          orderBy,
        },
      });
      if (response.data) {
        setCourses(response.data);
      }
      setLoading(false);
    })();
  };

  const handleCourseStatus = async (event, course) => {
    const { checked } = event.target;
    const response = await courseService
      .updateCourseStatus({
        course_id: course.id,
        active: checked,
      })
      .catch((err) =>
        snackbarContext.setSnackbar({
          message: err.response.data.message,
          severity: "error",
          open: true,
        })
      );

    if (response) {
      // eslint-disable-next-line no-param-reassign
      course.active = checked;
      snackbarContext.setSnackbar({
        message: "Saved successfully.",
        severity: "success",
        open: true,
      });
    }
  };

  const loadSubjects = () => {
    (async () => {
      const response = await subjectService.fetchAllSubjects({
        params: {
          school_id: schoolId,
        },
      });
      if (response.data) {
        setSubjects(response.data);
      }
    })();
  };

  const loadPredefinedCourses = () => {
    (async () => {
      const response = await courseService.fetchAllPredefinedCourses();
      if (response.data) {
        setPredefinedCourses(response.data);
      }
    })();
  };

  const loadDepartments = () => {
    (async () => {
      const response = await departmentsService.fetchAll();
      if (response.data) {
        setDepartments(response.data.filter((f) => f.active === true));
      }
    })();
  };

  const loadGpaScales = () => {
    (async () => {
      const response = await gpaScaleService.fetchAll();
      if (response.data) {
        setGpaScales(response.data);
      }
    })();
  };

  const { hasAnyPermissionType, hasPermission } =
    useContext(PermissionsContext);
  const managePermission = hasPermission(
    APP_PERMISSIONS.COURSES,
    PERMISSION_TYPES.MANAGE
  );

  useEffect(() => {
    if (
      !courseDrawerStatus &&
      !courseDrawerStatusEdit &&
      !courseDrawerAction &&
      !courseDrawerActionEdit
    ) {
      loadCourses();
    } else {
      setCourseDrawerAction(null);
      setCourseDrawerActionEdit(null);
    }
  }, [courseDrawerStatus, courseDrawerStatusEdit, order, orderBy]);

  useEffect(() => {
    loadSubjects();
    loadPredefinedCourses();
    loadDepartments();
    loadGpaScales();
  }, []);

  if (loading) {
    return <CircularProgress sx={loader} size={100} />;
  }

  if (
    !hasAnyPermissionType(APP_PERMISSIONS.COURSES) ||
    (!currentSchool.has_secondary_students &&
      !currentSchool.has_elementary_courses)
  ) {
    window.location.replace("/404");
  }

  return (
    <Box sx={tableContainer}>
      <Typography sx={pageHeaderTitle}>Courses</Typography>
      <Divider sx={divider} />
      <Container maxWidth={false} variant="header">
        <Container maxWidth="lg">
          <Grid container>
            <Grid item sm={12}>
              {courses.length > 0 ? (
                <TableContainer sx={py30}>
                  <Table sx={coursesListTable}>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={[
                            coursesListHead(false, orderBy === "name"),
                            nameHeader,
                          ]}
                          onClick={() => handleRequestSort("name")}
                        >
                          <Typography>
                            NAME {renderSortChevron("name")}
                          </Typography>
                        </TableCell>
                        <TableCell sx={[coursesListHead(false), descHeader]}>
                          <Typography>DESCRIPTION</Typography>
                        </TableCell>
                        <TableCell sx={coursesListHead(false)}>
                          <Typography>COURSE NO.</Typography>
                        </TableCell>
                        <TableCell sx={coursesListHead(true)}>
                          <Typography>CLASSES</Typography>
                        </TableCell>
                        <TableCell sx={coursesListHead} />
                        <TableCell sx={coursesListHead} />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {courses.map((course) => (
                        <TableRow
                          hover
                          key={course.id}
                          sx={cursorPointer}
                          onClick={() => handleCourseDrawer(true, course)}
                        >
                          <TableCell>
                            <Typography>{course.name}</Typography>
                          </TableCell>
                          <TableCell>
                            <Tooltip
                              placement="top-start"
                              title={
                                course.description &&
                                course.description.length > 50
                                  ? course.description
                                  : ""
                              }
                              key={course.id}
                            >
                              <Typography>
                                {truncateString(course.description, 50)}
                              </Typography>
                            </Tooltip>
                          </TableCell>
                          <TableCell>
                            <Typography>{course.course_id_sda}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography sx={mr15}>
                              {course.klasses_count}
                            </Typography>
                          </TableCell>
                          <TableCell
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <Switch
                              disabled={!managePermission}
                              sx={switchBtn}
                              checked={course.active}
                              onChange={(event) =>
                                handleCourseStatus(event, course)
                              }
                            />
                          </TableCell>
                          <TableCell
                            sx={settingsCell}
                            onClick={() => handleCourseDrawer(true, course)}
                          >
                            <SettingsOutlinedIcon />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Box sx={emptyCourseBox}>
                  <Typography sx={font22}>
                    There are no courses at the moment.
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
        </Container>
      </Container>

      <Drawer anchor="right" open={courseDrawerStatusEdit}>
        <Box sx={rightDrawerContainer}>
          <CourseForm
            handleCourseDrawer={handleCourseDrawer}
            activeCourse={activeCourse}
            subjects={subjects}
            predefinedCourses={predefinedCourses}
            departments={departments}
            gpaScales={gpaScales}
          />
        </Box>
      </Drawer>
    </Box>
  );
}
