import { Box, TextField } from "@mui/material";
import React, { useContext } from "react";
import { FormBuilderContext } from "../../FormBuilderContext";

export default function SubHeading({ field }) {
  const formBuilderContext = useContext(FormBuilderContext);
  const { form, currentSectionIndex, currentPageIndex, updateForm } =
    formBuilderContext;

  const onsChange = (e) => {
    const data = e.target.value;
    if (field.properties.subHeadingText === data) return;
    const formUpdate = { ...form };
    const fieldIndex = formUpdate.formPages[currentPageIndex].sections[
      currentSectionIndex
    ].fields.findIndex((f) => f.fieldId === field.fieldId);
    if (
      formUpdate.formPages[currentPageIndex].sections[currentSectionIndex]
        .fields[fieldIndex]
    )
      formUpdate.formPages[currentPageIndex].sections[
        currentSectionIndex
      ].fields[fieldIndex].properties.subHeadingText = data;
    updateForm(formUpdate);
  };

  return (
    <Box>
      <TextField
        fullWidth
        sx={{
          ".MuiInputBase-input": { fontSize: "1.45rem" },
          ".MuiInputBase-root": {
            "&:before": { border: 0 },
            "&:after": { border: 0 },
          },
        }}
        key={field.fieldId}
        onChange={onsChange}
        defaultValue={field.properties.subHeadingText}
        variant="standard"
      />
    </Box>
  );
}
