import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";

const breadCrumbStyle = {
  backgroundColor: "palette.background",
  paddingLeft: 70,
  paddingRight: 70,
  paddingTop: 25,
  paddingBottom: 25,
  borderTop: "2px solid #D3D4D5",
};

export default function Breadcrumb() {
  return (
    <Breadcrumbs
      style={breadCrumbStyle}
      separator="›"
      aria-label="breadcrumb"
    />
  );
}
