import {
  Box,
  Button,
  Drawer,
  Stack,
  TextField,
  Typography,
  Grid,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { DatePicker } from "@mui/x-date-pickers";
import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import schoolYearService from "../service/schoolYearService";
import { renderError, requiredMessage } from "../utils/constants/forms";
import { form, helperText } from "./SchoolYearSelect.styles";
import {
  formButtons,
  formHeader,
  formTitle,
  loader,
  rightDrawerContainer,
} from "./sharedStyles";
import GenericSubmitButton from "./GenericSubmitButton";

export default function SchoolYearDrawer({
  schoolId,
  setSnackBarOpen,
  getSchoolYears,
  drawerStatus,
  setDrawerStatus,
  selectedSchoolYear,
  setSelectedSchoolYear,
}) {
  const [fieldError, setFieldError] = useState([]);
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      start_date: "",
      end_date: "",
    },
  });

  const handleDrawer = (status) => {
    setDrawerStatus(status);
    if (!status) {
      reset({});
      setFieldError([]);
      setLoading(false);
    }
  };

  const onSubmit = async (formData) => {
    if (selectedSchoolYear) {
      try {
        const updatedSchoolYear = { ...selectedSchoolYear, ...formData };
        const response = await schoolYearService.update({
          ...updatedSchoolYear,
        });

        if (response.data) {
          setSnackBarOpen({
            open: true,
            message: "School Year updated.",
          });
          setSelectedSchoolYear(response.data);
          handleDrawer(false);
        }
      } catch (error) {
        setFieldError(error.response.data.errors);
      }
    } else {
      try {
        const response = await schoolYearService.create({
          ...formData,
          school_id: schoolId,
        });

        if (response.data) {
          setSnackBarOpen({
            open: true,
            message: "School Year created.",
          });
          if (getSchoolYears) {
            getSchoolYears();
          }
          handleDrawer(false);
        }
      } catch (error) {
        setFieldError(error.response.data.errors);
      }
    }
    setLoading(false);
  };

  const errorMessage = () =>
    renderError(fieldError?.name ? Object.values(fieldError.name)[0] : "");

  useEffect(() => {
    reset({
      start_date: selectedSchoolYear?.start_date,
      end_date: selectedSchoolYear?.end_date,
    });
  }, [selectedSchoolYear, reset]);

  useEffect(() => {
    reset({
      start_date: selectedSchoolYear?.start_date,
      end_date: selectedSchoolYear?.end_date,
    });
  }, []);

  return (
    <Drawer anchor="right" open={drawerStatus}>
      <Box sx={rightDrawerContainer}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction="row" sx={formHeader}>
            <Typography sx={formTitle}>
              {selectedSchoolYear ? "Edit" : "Add New"} School Year
            </Typography>
            <Stack direction="row">
              <Button sx={formButtons} onClick={() => handleDrawer(false)}>
                Cancel
              </Button>
              <GenericSubmitButton
                text="Save and exit"
                submittingText="Saving..."
              />
            </Stack>
          </Stack>

          <Box sx={form}>
            {loading ? (
              <CircularProgress color="inherit" size={100} sx={loader} />
            ) : (
              <Grid container>
                <Grid item sm={12}>
                  <TextField
                    disabled
                    label="School Year"
                    defaultValue={selectedSchoolYear?.name}
                  />
                </Grid>
                <Grid container item sm={12} mt={3}>
                  <Grid item sm={5}>
                    <Controller
                      name="start_date"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value }, fieldState }) => (
                        <DatePicker
                          label="Beginning Date"
                          id="start_date"
                          value={value || ""}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={
                                !!fieldError?.start_date || !!fieldState.error
                              }
                              helperText={
                                fieldState.error
                                  ? requiredMessage
                                  : fieldError?.start_date
                              }
                              sx={helperText}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item sm={5}>
                    <Controller
                      name="end_date"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value }, fieldState }) => (
                        <DatePicker
                          label="Ending Date"
                          id="end_date"
                          value={value || ""}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={
                                !!fieldError?.end_date || !!fieldState.error
                              }
                              helperText={
                                fieldState.error
                                  ? requiredMessage
                                  : fieldError?.end_date
                              }
                              sx={helperText}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
            {errorMessage()}
          </Box>
        </form>
      </Box>
    </Drawer>
  );
}
