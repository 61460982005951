const klassesListName = {
  width: "250px",
};

const klassesListSubject = {
  width: "250px",
};

const klassesListDescription = {
  width: "300px",
};

const klassesSettingsCell = {
  color: "#808080",
  cursor: "pointer",
};

const tabsBox = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: 0,
};

const container = {
  borderTop: "1px solid black",
};

const socialDevelopmentCell = {
  color: "black",
  borderColor: "black",
};

const stickyCell = {
  position: "sticky",
  left: 0,
  backgroundColor: "white",
  zIndex: 98,
  pt: 4,
  width: "100vw",
  display: "table-cell",
};

const socialDevelopmentHead = {
  ...socialDevelopmentCell,
  height: "80px",
  "& p": {
    fontSize: "14px",
    fontWeight: "normal",
    height: "100%",
    display: "flex",
    alignItems: "end",
  },
  "&:not(:last-child)": {
    "& p": {
      justifyContent: "center",
    },
  },
};

const socialDevelopmentLevelsHead = {
  ...socialDevelopmentHead,
  borderBottom: "0px",
};

const socialDevelopmentTermsHead = {
  ...socialDevelopmentLevelsHead,
  pt: "50px",
  pl: "0px",
  fontSize: "26px",
  fontWeight: "400",
  height: "40px",
  width: "350px",
  display: "block",
  "& p": {
    fontWeight: "normal",
    height: "80px",
    display: "flex",
    alignItems: "end",
    pb: "10px",
  },
};

const socialDevelopmentsHeadTerm = {
  color: "black",
  // borderLeft: "1px solid black",
  borderBottom: "1px solid black",
  borderRight: "1px solid black",
  fontSize: "12px",
  height: "80px",
  textAlign: "left",
  "& p": {
    height: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
  },
};

const socialDevelopmentLevelsBorderedHead = {
  ...socialDevelopmentHead,
  // borderLeft: "1px solid black",
  borderRight: "1px solid black",
  borderBottom: "0px",
  "& p": {
    fontWeight: "600",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
  },
};

const skillGrades = {
  color: "black",
  fontSize: "20px",
};

const tableContainer = {
  overflowX: "auto",
  width: "100%",
  maxHeight: "1000px",
  minHeight: "340px",
};

const tableStyle = {
  width: "auto",
};

const tableHead = {
  backgroundColor: "white",
  position: "sticky",
  top: 0,
  zIndex: 99,
};

const noBorderBottom = { borderBottom: "none" };

const boxTop = {
  display: "table-cell",
  ...stickyCell,
  borderRight: "1px solid black",
};

const boxRow = { ...stickyCell, borderRight: "1px solid black" };

const emptyCell = { ...socialDevelopmentHead, ...stickyCell };

const studentCell = {
  ...socialDevelopmentHead,
  ...stickyCell,
  height: "90px !important",
  width: "0px",
  textAlign: "right",
};

export {
  klassesListName,
  klassesListSubject,
  klassesListDescription,
  klassesSettingsCell,
  tabsBox,
  container,
  socialDevelopmentTermsHead,
  socialDevelopmentLevelsHead,
  socialDevelopmentHead,
  socialDevelopmentCell,
  socialDevelopmentsHeadTerm,
  socialDevelopmentLevelsBorderedHead,
  skillGrades,
  tableContainer,
  tableStyle,
  tableHead,
  noBorderBottom,
  stickyCell,
  boxTop,
  boxRow,
  emptyCell,
  studentCell,
};
