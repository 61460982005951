import { Box, Container } from "@mui/material";
import { Outlet } from "react-router-dom";
import React from "react";
import ProcessFieldSetTabs from "./ProcessFieldSetTabs";
import {
  fieldSetContainer,
  tabsContainer,
} from "../../FormBuilder/Components/FieldSet/FieldSet.style";

export default function ProcessFieldSet() {
  return (
    <Container maxWidth={false} variant="header" sx={fieldSetContainer}>
      <Box sx={tabsContainer}>
        <ProcessFieldSetTabs />
      </Box>
      <Box
        py={2}
        px={2}
        display="flex"
        justifyContent="left"
        flexDirection="column"
      >
        <Outlet />
      </Box>
    </Container>
  );
}
