import moment from "moment";
import dateTimeFormats from "../../../../../utils/constants/dateTimeFormats";
import StageElementType from "./constants";
import { generateGUID } from "../../../../../utils/constants/utils";

export default class StageElementProperties {
  constructor(_properties, stageElement) {
    const elementType = stageElement.type;
    const properties = _properties || {};
    switch (elementType) {
      case StageElementType.FORM:
        this.id = properties.id || stageElement.formId;
        this.require = false;
        this.personsResponsible = properties.personsResponsible || [];
        this.notifyPersonsResponsible =
          properties.notifyPersonsResponsible || false;
        this.markApproved = properties.markApproved || false;
        break;
      case StageElementType.EVENT:
        break;
      case StageElementType.FEE:
        this.earlyFeeName = properties.earlyFeeName || "";
        this.earlyFeeCost = properties.earlyFeeCost || "";
        this.earlyFeeDescription = properties.earlyFeeDescription || "";
        this.earlyFeePaymentUrl = properties.earlyFeePaymentUrl || "";
        this.lateFeePaymentUrl = properties.lateFeePaymentUrl || "";
        this.earlyFeeIncludeInTimeline =
          properties.earlyFeeIncludeInTimeline || false;
        this.deadline =
          (properties.deadline &&
            moment(properties.deadline).format(dateTimeFormats.MMDDYYYY)) ||
          null;
        this.lateFeeName = properties.lateFeeName || "";
        this.lateFeeCost = properties.lateFeeCost || "";
        this.lateFeeDescription = properties.lateFeeDescription || "";
        this.lateFeeIncludeInTimeline =
          properties.lateFeeIncludeInTimeline || false;
        break;
      case StageElementType.FILE:
        this.files = properties.files || [
          {
            id: generateGUID(),
            type: "",
            name: "",
            description: "",
            require: "",
            includeInTimeline: "",
          },
        ];
        this.notifyPersonsResponsible =
          properties.notifyPersonsResponsible || false;
        break;
      case StageElementType.ALERT:
        break;
      case StageElementType.TASK:
        this.id = properties.id || null;
        this.name = properties.name || stageElement.name || "";
        this.description = properties.description || "";
        this.personsResponsible = properties.personsResponsible || [];
        this.deadline = properties.deadline || null;
        this.notifyPersonsResponsible =
          properties.notifyPersonsResponsible ||
          properties.notify_persons_responsible ||
          false;
        this.includeInTimeline =
          properties.includeInTimeline ||
          properties.include_in_timeline ||
          false;
        break;
      default:
    }
    this.order = properties.order || 0;
  }
}
