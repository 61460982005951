import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import { divider, tableStyles, title } from "./Profile.styles";
import { formatPhoneNumber } from "../../../../utils/constants/utils";
import { pt30, textCenter } from "../../../sharedStyles";

export default function EmergencyContactSection({ emergencyContacts }) {
  const { t } = useTranslation("guardian", { keyPrefix: "studentProfile" });

  return (
    <>
      <hr style={divider} />
      <Typography sx={title}>{t("emergencyContactTransportation")}</Typography>
      {emergencyContacts.length > 0 ? (
        <TableContainer sx={pt30}>
          <Table sx={tableStyles} aria-label="emergency contact table">
            <TableHead>
              <TableRow>
                <TableCell>{t("name")}</TableCell>
                <TableCell>{t("relationship")}</TableCell>
                <TableCell>{t("email")}</TableCell>
                <TableCell>{t("phone")}</TableCell>
                <TableCell sx={textCenter}>{t("emergency")}</TableCell>
                <TableCell sx={textCenter}>{t("transport")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {emergencyContacts.map((emergencyContact) => (
                <TableRow key={emergencyContact.id} hover>
                  <TableCell component="th" scope="emergency contact">
                    <Typography>
                      {`${emergencyContact.first_name} ${emergencyContact.last_name}`}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{emergencyContact.relationship}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{emergencyContact.email}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {formatPhoneNumber(emergencyContact.cell_phone)}
                    </Typography>
                  </TableCell>
                  <TableCell sx={textCenter}>
                    {!emergencyContact.has_emergency_notifications ? (
                      <CheckBoxOutlinedIcon />
                    ) : (
                      <CheckBoxOutlineBlankOutlinedIcon />
                    )}
                  </TableCell>
                  <TableCell sx={textCenter}>
                    {emergencyContact.is_authorized_pickup ? (
                      <CheckBoxOutlinedIcon />
                    ) : (
                      <CheckBoxOutlineBlankOutlinedIcon />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography sx={[textCenter, pt30]}>
          {t("noEmergencyContacts")}
        </Typography>
      )}
    </>
  );
}
