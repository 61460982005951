import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Container,
  Drawer,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { useOutletContext, useParams } from "react-router-dom";
import {
  gTStudentRow,
  gTTableHead,
  gTTableKlassName,
  gTTableRow,
} from "../../Subject/NewGradebook/NewGradebookTable.styles";
import {
  cursorPointer,
  font11,
  font14,
  font28,
  rightDrawerContainer,
  textCenter,
} from "../../sharedStyles";
import {
  transcriptTable,
  transcriptTableContainer,
} from "./AddTranscriptRecord.style";
import AddTranscriptRecord from "./AddTranscriptRecord";
import SnackBarNotification from "../../SnackBarNotification";
import { QuickBarContext } from "../../../context/QuickBarContext";
import { studentTranscriptContainer } from "./StudentTranscript.style";
import TranscriptService from "../../../service/transcriptService";
import APP_PERMISSIONS from "../../../utils/constants/permissions";
import { PermissionsContext } from "../../../context/PermissionsContext";

function StudentTranscript() {
  const [transcripts, setTranscripts] = useState([]);
  const [activeTranscriptRecord, setActiveTranscriptRecord] = useState(null);
  const [snackBarOpen, setSnackBarOpen] = useState({
    open: false,
    message: null,
  });
  const quickBarContext = useContext(QuickBarContext);
  const params = useParams();
  const [, , , currentSchool] = useOutletContext();

  const loadTranscripts = async () => {
    const response = await TranscriptService.fetchAll({
      student_id: params.student_slug,
    });

    if (response.data) {
      setTranscripts(response.data);
    }
  };

  useEffect(() => {
    (async () => {
      if (quickBarContext.loadTranscripts) {
        await loadTranscripts();
      }

      quickBarContext.cleanLoadTranscripts();
    })();
  }, [quickBarContext.loadTranscripts]);

  useEffect(() => {
    loadTranscripts();
  }, []);

  const getActiveTranscriptRecord = async (transcriptRecordID) => {
    const response = await TranscriptService.fetchTranscriptRecord(
      transcriptRecordID
    );
    if (response.data) {
      setActiveTranscriptRecord(response.data);
    }
  };

  const handleTranscriptDrawer = async (status, transcriptRecordID) => {
    if (status === false) {
      setActiveTranscriptRecord(null);
    } else {
      await getActiveTranscriptRecord(transcriptRecordID);
    }
  };

  const { hasAnyPermissionType } = useContext(PermissionsContext);
  if (
    !hasAnyPermissionType(APP_PERMISSIONS.STUDENT_TRANSCRIPTS) ||
    !currentSchool.has_secondary_students
  ) {
    window.location.replace("/404");
  }

  return (
    <Container sx={studentTranscriptContainer}>
      {transcripts && transcripts.length > 0 ? (
        transcripts.map(
          (transcript) =>
            transcript.transcript_records &&
            transcript.transcript_records.length > 0 && (
              <Fragment key={transcript.id}>
                <Typography sx={{ ...font28, mt: 5 }}>
                  {transcript.school_year} {transcript.grade_level}th grade
                </Typography>

                <TableContainer
                  sx={transcriptTableContainer}
                  id="gTTableContainer"
                >
                  <Table sx={transcriptTable} stickyHeader>
                    <TableHead sx={gTTableHead}>
                      <TableRow sx={gTStudentRow}>
                        <TableCell
                          sx={{ ...gTTableKlassName, pr: 10, py: 1 }}
                          align="left"
                        >
                          <Typography sx={font11}>COURSE</Typography>
                        </TableCell>
                        <TableCell
                          sx={{ ...gTTableKlassName, px: 5, py: 1 }}
                          align="left"
                        >
                          <Typography sx={font11}>COURSE ID</Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            ...gTTableKlassName,
                            px: 2,
                            py: 1,
                            maxWidth: "100px",
                            minWidth: "100px",
                          }}
                          align="center"
                        >
                          <Typography sx={font11}>S1 GRADE</Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            ...gTTableKlassName,
                            px: 2,
                            py: 1,
                            maxWidth: "100px",
                            minWidth: "100px",
                          }}
                          align="center"
                        >
                          <Typography sx={font11}>S1%</Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            ...gTTableKlassName,
                            px: 2,
                            py: 1,
                            maxWidth: "100px",
                            minWidth: "100px",
                          }}
                          align="center"
                        >
                          <Typography sx={font11}>
                            S1 CREDITS AWARDED
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            ...gTTableKlassName,
                            px: 2,
                            py: 1,
                            maxWidth: "100px",
                            minWidth: "100px",
                          }}
                          align="center"
                        >
                          <Typography sx={font11}>S1 CREDITS POSS.</Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            ...gTTableKlassName,
                            px: 2,
                            py: 1,
                            maxWidth: "100px",
                            minWidth: "100px",
                          }}
                          align="center"
                        >
                          <Typography sx={font11}>S2 GRADE</Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            ...gTTableKlassName,
                            px: 2,
                            py: 1,
                            maxWidth: "100px",
                            minWidth: "100px",
                          }}
                          align="center"
                        >
                          <Typography sx={font11}>S2 %</Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            ...gTTableKlassName,
                            px: 2,
                            py: 1,
                            maxWidth: "100px",
                            minWidth: "100px",
                          }}
                          align="center"
                        >
                          <Typography sx={font11}>
                            S2 CREDITS AWARDED
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            ...gTTableKlassName,
                            px: 2,
                            py: 1,
                            maxWidth: "100px",
                            minWidth: "100px",
                          }}
                          align="center"
                        >
                          <Typography sx={{ ...font11 }}>
                            S2 CREDITS POSS.
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{ ...gTTableKlassName, py: 1, pr: 5 }}
                          align="center"
                        >
                          <Typography sx={font11}>SCHOOL</Typography>
                        </TableCell>
                        <TableCell
                          sx={{ ...gTTableKlassName, py: 1 }}
                          align="center"
                        >
                          <Typography sx={font11}>TRANSFER CREDIT</Typography>
                        </TableCell>
                        <TableCell
                          sx={{ ...gTTableKlassName, py: 1 }}
                          align="center"
                        >
                          <Typography sx={font11} />
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {transcript.transcript_records.map((transcriptRecord) => (
                        <TableRow
                          sx={{ ...gTTableRow, py: 2 }}
                          key={transcriptRecord.id}
                        >
                          <TableCell
                            sx={{
                              ...gTTableKlassName,
                              paddingY: 0.2,
                              borderBottom: "1px solid #B5B5B5 !important",
                            }}
                            align="left"
                          >
                            <Typography sx={font14}>
                              {transcriptRecord.not_nad_course
                                ? `${transcript.grade_level}th Grade,  ${transcriptRecord.course_name}`
                                : `${transcript.grade_level}th Grade,  ${transcriptRecord.course?.name}`}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              ...gTTableKlassName,
                              paddingY: 0.2,
                              borderBottom: "1px solid #B5B5B5 !important",
                            }}
                            align="center"
                          >
                            <Typography sx={font14}>
                              {transcriptRecord.course?.course_id_sda}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              ...gTTableKlassName,
                              paddingY: 0.2,
                              borderBottom: "1px solid #B5B5B5 !important",
                            }}
                            align="center"
                          >
                            <Typography
                              sx={{
                                ...font14,
                                borderRight: "1px solid black",
                                borderLeft: "1px solid black",
                                paddingX: 1,
                                paddingY: 2,
                              }}
                            >
                              {transcriptRecord.s1_grade}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              ...gTTableKlassName,
                              paddingY: 0.2,
                              borderBottom: "1px solid #B5B5B5 !important",
                            }}
                            align="center"
                          >
                            <Typography
                              sx={{
                                ...font14,
                                borderRight: "1px solid black",
                                paddingX: 1,
                                paddingY: 2,
                              }}
                            >
                              {transcriptRecord.s1_percent
                                ? `${transcriptRecord.s1_percent}%`
                                : ""}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              ...gTTableKlassName,
                              paddingY: 0.2,
                              borderBottom: "1px solid #B5B5B5 !important",
                            }}
                            align="center"
                          >
                            <Typography
                              sx={{
                                ...font14,
                                borderRight: "1px solid black",
                                paddingX: 1,
                                paddingY: 2,
                              }}
                            >
                              {transcriptRecord.s1_credits_earned}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              ...gTTableKlassName,
                              paddingY: 0.2,
                              borderBottom: "1px solid #B5B5B5 !important",
                            }}
                            align="center"
                          >
                            <Typography
                              sx={{
                                ...font14,
                                borderRight: "1px solid black",
                                paddingX: 1,
                                paddingY: 2,
                              }}
                            >
                              {transcriptRecord.s1_credits_possible}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              ...gTTableKlassName,
                              paddingY: 0.2,
                              borderBottom: "1px solid #B5B5B5 !important",
                            }}
                            align="center"
                          >
                            <Typography
                              sx={{
                                ...font14,
                                borderRight: "1px solid black",
                                paddingX: 1,
                                paddingY: 2,
                              }}
                            >
                              {transcriptRecord.s2_grade &&
                                transcriptRecord.s2_grade}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              ...gTTableKlassName,
                              paddingY: 0.2,
                              borderBottom: "1px solid #B5B5B5 !important",
                            }}
                            align="center"
                          >
                            <Typography
                              sx={{
                                ...font14,
                                borderRight: "1px solid black",
                                paddingX: 1,
                                paddingY: 2,
                              }}
                            >
                              {transcriptRecord.s2_percent
                                ? `${transcriptRecord.s2_percent}%`
                                : ""}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              ...gTTableKlassName,
                              paddingY: 0.2,
                              borderBottom: "1px solid #B5B5B5 !important",
                            }}
                            align="center"
                          >
                            <Typography
                              sx={{
                                ...font14,
                                borderRight: "1px solid black",
                                paddingX: 1,
                                paddingY: 2,
                              }}
                            >
                              {transcriptRecord.s2_credits_earned &&
                                transcriptRecord.s2_credits_earned}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              ...gTTableKlassName,
                              pr: 5,
                              borderBottom: "1px solid #B5B5B5 !important",
                              my: 2,
                            }}
                            align="center"
                          >
                            <Typography sx={font14}>
                              {transcriptRecord.s2_credits_possible &&
                                transcriptRecord.s2_credits_possible}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              ...gTTableKlassName,
                              pr: 5,
                              borderBottom: "1px solid #B5B5B5 !important",
                              my: 2,
                            }}
                            align="center"
                          >
                            <Typography sx={font14}>
                              {transcriptRecord.school_granting_credit}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              ...gTTableKlassName,
                              borderBottom: "1px solid #B5B5B5 !important",
                              my: 2,
                            }}
                            align="center"
                          >
                            <Checkbox
                              sx={{ py: 0, cursor: "text" }}
                              checked={transcriptRecord.transfer_credit}
                            />
                          </TableCell>
                          <TableCell
                            sx={{
                              ...gTTableKlassName,
                              ...cursorPointer,
                              borderBottom: "1px solid #B5B5B5 !important",
                              my: 2,
                            }}
                            align="center"
                          >
                            <SettingsOutlinedIcon
                              onClick={async () => {
                                await handleTranscriptDrawer(
                                  true,
                                  transcriptRecord.id
                                );
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Fragment>
            )
        )
      ) : (
        <Box sx={textCenter}>
          <Typography>No transcripts available.</Typography>
        </Box>
      )}
      <Drawer anchor="right" open={!!activeTranscriptRecord}>
        <Box sx={rightDrawerContainer}>
          <AddTranscriptRecord
            handleTranscriptDrawer={handleTranscriptDrawer}
            activeTranscriptRecord={activeTranscriptRecord}
            setSnackBarOpen={setSnackBarOpen}
          />
        </Box>
      </Drawer>
      <SnackBarNotification
        message={snackBarOpen.message}
        open={snackBarOpen.open}
        severity={snackBarOpen.severity}
        handleClose={() => setSnackBarOpen(false)}
      />
    </Container>
  );
}

export default StudentTranscript;
