import React, { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { rowContainer, rowTitle } from "./GroupNameRow.styles";
import groupService from "../../../service/groupService";
import AlertDialog from "../../AlertDialog";

export default function GroupNameRow({
  group,
  selectedGroup,
  setSelectedGroup,
  setSnackBarOpen,
  isDeleteDialogOpen,
  setDeleteDialogOpen,
}) {
  const [groupToDelete, setGroupToDelete] = useState(null);

  const handleCloseDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleOpenDialog = (groupId) => {
    setDeleteDialogOpen(true);
    setGroupToDelete(groupId);
  };

  const handleDelete = async () => {
    const response = await groupService.deleteGroup(groupToDelete);

    if (response.data) {
      setSnackBarOpen({
        open: true,
        message: "Group deleted.",
      });

      setGroupToDelete(null);
      setDeleteDialogOpen(false);
    }
  };

  const handleGroupChange = (selected) => {
    setSelectedGroup(selected);
  };

  return (
    <Stack
      sx={rowContainer(group.id === selectedGroup.id)}
      direction="row"
      justifyContent="space-between"
      onClick={() => handleGroupChange(group)}
    >
      <Typography sx={rowTitle}>{group.name}</Typography>
      <Box>
        <CopyAllIcon sx={{ marginRight: "25px", cursor: "pointer" }} />
        <DeleteOutlineOutlinedIcon
          onClick={() => handleOpenDialog(group.id)}
          sx={{ marginRight: "25px", cursor: "pointer" }}
        />
      </Box>

      <AlertDialog
        isOpen={isDeleteDialogOpen}
        handleClose={handleCloseDialog}
        handleConfirm={() => handleDelete(group.id)}
      />
    </Stack>
  );
}
