export default {
  a: {
    textDecoration: "none",
    color: "inherit",
  },
  html: {
    fontSize: "16px", // Default font size
  },
  "@media screen and (max-width: 480px)": {
    html: {
      fontSize: "10px", // Adjust font size for smaller screens
    },
  },
  "@media screen and (min-width: 481px) and (max-width: 768px)": {
    html: {
      fontSize: "12px", // Adjust font size for smaller screens
    },
  },
  "@media screen and (min-width: 769px) and (max-width: 1024px)": {
    html: {
      fontSize: "14px", // Adjust font size for smaller screens
    },
  },
  "@media screen and (min-width: 1025px) and (max-width: 1440px)": {
    html: {
      fontSize: "16px", // Adjust font size for smaller screens
    },
  },
  "@media screen and (min-width: 1441px)": {
    html: {
      fontSize: "16px", // Adjust font size for smaller screens
    },
  },
};
