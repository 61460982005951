import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import React, { useState, useEffect, useContext } from "react";
import {
  termGradeKlassCell,
  termGradeKlassCellName,
  termGradeKlassCellGrade,
  termGradeKlassCellComment,
  termGradeKlassCellGradeDisabled,
} from "./TermGradeKlassRow.styles";
import termGradeService from "../../../service/termGradeService";
import AutoSave from "../../AutoSave";
import formattedGrade from "../../../utils/formattedGrade";
import { PermissionsContext } from "../../../context/PermissionsContext";
import APP_PERMISSIONS from "../../../utils/constants/permissions";
import PERMISSION_TYPES from "../../../utils/constants/permission_types";
import { QuickBarContext } from "../../../context/QuickBarContext";
import { SchedulerContext } from "../../../context/SchedulerContext";
import GradeLockIcon from "../../GradeLockIcon";
import GradeCheckIcon from "../../GradeCheckIcon";
import GradeAlertIcon from "../../GradeAlertIcon";

export default function TermGradeKlassRow({
  termGrade,
  handleTermGradeDrawer,
  specialMarks,
  reportCards,
  termGrades,
  setTermGrades,
}) {
  const [rowComment, setRowComment] = useState(termGrade?.comment);
  const [rowCommentHasChanged, setRowCommentHasChanged] = useState(false);
  const quickbarContext = useContext(QuickBarContext);
  const schedulerContext = useContext(SchedulerContext);

  const [instructionalLevel, setInstructionalLevel] = useState(
    termGrade?.instructional_level
  );
  const [instructionalLevelChanged, setInstructionalLevelChanged] =
    useState(false);
  const { control } = useForm();
  const { hasPermission } = useContext(PermissionsContext);

  const managePermission = hasPermission(
    APP_PERMISSIONS.KLASSES_TERM_GRADES,
    PERMISSION_TYPES.MANAGE
  );

  const termGradeLetter = () => {
    let grade = termGrade.calculated_grade;

    if (termGrade.posted_grade) {
      grade = termGrade.posted_grade;
    }

    if (grade === null) {
      return "";
    }

    if (termGrade?.klass?.is_pass_fail) {
      return grade >=
        Number(
          termGrade?.klass?.grading_scale?.marking_codes?.find(
            (markingCode) => markingCode.code === "P"
          )?.value
        )
        ? "P"
        : "F";
    }

    const letterGrade = formattedGrade(
      grade,
      100,
      termGrade?.klass?.grading_scale?.marking_codes,
      specialMarks
    );
    return letterGrade;
  };

  const calculatedTermGrade = () => {
    if (termGrade.calculated_grade !== null) {
      let grade = termGrade.calculated_grade;

      if (termGrade.posted_grade) {
        grade = termGrade.posted_grade;
      }

      return grade;
    }

    return "";
  };

  const handleCommentChange = (event) => {
    setRowComment(event.target.value);
    setRowCommentHasChanged(true);
  };

  const handleInstructionalLevelChange = (value) => {
    setInstructionalLevel(value);
    setInstructionalLevelChanged(true);
  };

  const hasPostedGrade = () => termGrade.posted_grade !== null;
  const isOverridden = termGrade.overridden;

  const calculatedGradeAndPostedGradeAreDifferent = () => {
    const calculatedAt = new Date(termGrade.calculated_at).getTime();
    const postedAt = new Date(termGrade.posted_at).getTime();

    if (calculatedAt > postedAt) {
      return termGrade.posted_grade === termGrade.calculated_grade;
    }

    return true;
  };

  const comment = rowComment;

  const isDisabled = () => !termGrade.calculated_grade;

  const isPublished = () => {
    let reportCard = false;
    if (reportCards) {
      reportCard = reportCards[termGrade.student_id];
    }

    return reportCard && reportCard.is_published;
  };

  const handleAfterSaving = () => {
    setInstructionalLevelChanged(false);
    setRowCommentHasChanged(false);
    if (
      schedulerContext.taskCount.current === 0 &&
      !schedulerContext.savingCurrent.current
    ) {
      quickbarContext.cleanAutoSaving();
    }
  };

  const handleTermGradeUpdate = () => {
    const updatedTermGrades = [...termGrades];
    const termGradesToUpdate = updatedTermGrades.find(
      (item) => item.id === termGrade.id
    );

    if (termGradesToUpdate) {
      termGradesToUpdate.comment = rowComment;
      termGradesToUpdate.instructional_level = instructionalLevel;
      setTermGrades(updatedTermGrades);
    }
  };

  useEffect(() => {
    if (!rowCommentHasChanged && !instructionalLevelChanged) {
      handleTermGradeUpdate();
    } else {
      quickbarContext.fireAutoSaving();
    }
  }, [rowCommentHasChanged, instructionalLevelChanged]);

  return (
    <TableRow>
      <TableCell sx={termGradeKlassCellName}>
        {`${termGrade.student.last_name}, ${termGrade.student.first_name}`}
      </TableCell>
      <TableCell sx={termGradeKlassCell} align="center">
        {termGrade.klass.abbreviation}
      </TableCell>
      <TableCell sx={termGradeKlassCell} align="center">
        {termGrade.student.grade_level}
      </TableCell>
      <TableCell
        key={`${termGrade?.term?.id}-${termGrade?.calculated_grade}`}
        sx={
          isDisabled()
            ? termGradeKlassCellGradeDisabled
            : termGradeKlassCellGrade
        }
        align="center"
        {...(!isDisabled() && {
          onClick: () => {
            if (!managePermission) return;

            handleTermGradeDrawer(
              termGrade.student,
              {
                ...termGrade,
                instructional_level: instructionalLevel,
                comment: rowComment,
              },
              termGrade.klass,
              isPublished() || false
            );
          },
        })}
      >
        <Typography>
          {termGradeLetter()} <br /> {calculatedTermGrade()}
          {hasPostedGrade() ? (
            <GradeCheckIcon
              isGreen={
                calculatedGradeAndPostedGradeAreDifferent() || isOverridden
              }
            />
          ) : null}
          {isOverridden ? (
            <GradeAlertIcon
              isGreen={calculatedGradeAndPostedGradeAreDifferent()}
            />
          ) : null}
          {isPublished() ? <GradeLockIcon /> : null}
        </Typography>
      </TableCell>
      <TableCell sx={termGradeKlassCellComment}>
        <TextField
          fullWidth
          placeholder="Comment"
          value={rowComment}
          onChange={handleCommentChange}
          disabled={!managePermission || isPublished()}
        />
        {rowCommentHasChanged && (
          <AutoSave
            watcher={{
              student_id: termGrade.student.id,
              klass_id: termGrade.klass.id,
              term_id: termGrade.term.id,
              comment,
              instructionalLevel,
            }}
            saveMethod={termGradeService.save}
            successMessage="Saved"
            progressMessage="Saving..."
            afterSaving={() => handleAfterSaving()}
          />
        )}
      </TableCell>
      <TableCell sx={termGradeKlassCell}>
        {termGrade?.klass?.subject?.needs_instructional_level && (
          <Controller
            name="instructionalLevel"
            defaultValue={termGrade?.instructional_level}
            control={control}
            render={({ field }) => (
              <FormControl sx={termGradeKlassCell}>
                <RadioGroup
                  row
                  {...field}
                  onChange={(event, value) => {
                    field.onChange(value);
                    handleInstructionalLevelChange(value);
                  }}
                  value={instructionalLevel}
                >
                  <FormControlLabel
                    value="_none"
                    control={
                      <Radio disabled={!managePermission || isPublished()} />
                    }
                    label="None"
                  />
                  <FormControlLabel
                    value="x"
                    control={
                      <Radio disabled={!managePermission || isPublished()} />
                    }
                    label="X"
                  />
                  <FormControlLabel
                    value="y"
                    control={
                      <Radio disabled={!managePermission || isPublished()} />
                    }
                    label="Y"
                  />
                  <FormControlLabel
                    value="ya"
                    control={
                      <Radio disabled={!managePermission || isPublished()} />
                    }
                    label="YA"
                  />
                  <FormControlLabel
                    value="z"
                    control={
                      <Radio disabled={!managePermission || isPublished()} />
                    }
                    label="Z"
                  />
                </RadioGroup>
              </FormControl>
            )}
          />
        )}

        {instructionalLevelChanged && (
          <AutoSave
            watcher={{
              student_id: termGrade.student.id,
              klass_id: termGrade.klass.id,
              term_id: termGrade.term.id,
              instructionalLevel,
              comment,
            }}
            saveMethod={termGradeService.save}
            successMessage="Saved"
            progressMessage="Saving..."
            afterSaving={() => handleAfterSaving()}
          />
        )}
      </TableCell>
    </TableRow>
  );
}
