import { ButtonBase, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  FilePresentOutlined,
  SimCardDownloadOutlined,
} from "@mui/icons-material";
import admissionProcessService from "../../../../service/admissionProcessService";
import StageElementType from "../../Setup/AdmissionProcessBuilder/Models/constants";
import { formatDateTime } from "../../../../utils/constants/utils";
import QontoStepIcon from "../../../QontoStepIcon";
import fileService from "../../../../service/fileService";

export default function Files() {
  const [searchParams] = useSearchParams();
  const [elementInstances, setElementInstances] = useState([]);

  useEffect(() => {
    admissionProcessService
      .elementInstances(searchParams.get("student_id"), StageElementType.FILE)
      .then((response) => {
        setElementInstances(response.data);
      });
  }, []);

  const getDocuments = (documents, id) => documents.filter((d) => d.id === id);

  const downloadFile = (elementInstance, documentIndex) => {
    fileService.fetchStageElementFile({
      element_instance_id: elementInstance.id,
      document_id: elementInstance.documents_details[documentIndex].id,
      filename: elementInstance.documents_details[documentIndex].filename,
    });
  };

  return elementInstances.map((elementInstance) => (
    <Grid item md={12} container mt={2.5}>
      <Grid item md={0.5} container alignItems="center">
        {/* <QontoStepIcon /> */}
      </Grid>
      <Grid item md={4.5} container alignItems="center">
        <QontoStepIcon checked={elementInstance.status === "completed"} />
        <Typography color="black" ml={1}>
          {elementInstance.stage_element.name}
        </Typography>
      </Grid>
      <Grid item md={2.33} container alignItems="center">
        <Typography color="black" fontWeight={500}>
          DUE
        </Typography>
      </Grid>
      <Grid item md={2.33} container alignItems="center">
        <Typography color="black" fontWeight={500}>
          COMPLETED
        </Typography>
      </Grid>
      <Grid item md={2.33} />

      {elementInstance.properties.files.map((file) => (
        <Grid container item md={12} mt={2.5}>
          <Grid item md={1} container alignItems="center">
            {/* <QontoStepIcon /> */}
          </Grid>
          <Grid item md={4} container alignItems="center">
            <QontoStepIcon
              checked={
                getDocuments(elementInstance.documents_details, file.file_id)
                  .length
              }
            />
            <Typography color="black" ml={1}>
              {file.name}
            </Typography>
          </Grid>
          <Grid item md={2.33} container alignItems="center">
            <Typography color="black">
              {elementInstance.deadline
                ? formatDateTime(elementInstance.deadline)
                : "-"}
            </Typography>
          </Grid>
          <Grid item md={2.33} container alignItems="center">
            <Typography color="black">
              {getDocuments(elementInstance.documents_details, file.file_id)
                .length
                ? formatDateTime(
                    getDocuments(
                      elementInstance.documents_details,
                      file.file_id
                    )[0].created_at
                  )
                : "-"}
            </Typography>
          </Grid>
          <Grid item md={2.33} />

          {getDocuments(elementInstance.documents_details, file.file_id).map(
            (document, documentIndex) => (
              <Grid container item md={12} my={2}>
                <Grid item md={1.5} />
                <Grid
                  item
                  md={6.5}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    borderBottom: "1px solid #B6B6B6",
                    pb: 1,
                  }}
                >
                  <FilePresentOutlined htmlColor="rgba(0, 0, 0, 0.54)" />{" "}
                  <Typography fontSize={14} ml={1}>
                    {document.filename}
                    <ButtonBase
                      onClick={() =>
                        downloadFile(elementInstance, documentIndex)
                      }
                    >
                      <SimCardDownloadOutlined
                        sx={{ ml: 1 }}
                        fontSize="small"
                      />
                    </ButtonBase>
                  </Typography>
                  {/* <ButtonBase
                    sx={{ position: "absolute", right: 0 }}
                    onClick={() =>
                      deleteDocument(
                        document.id,
                        elementInstance.id,
                        stageInstanceIndex,
                        elementInstanceIndex
                      )
                    }
                  >
                    <Clear htmlColor="rgba(0, 0, 0, 0.54)" />
                  </ButtonBase> */}
                </Grid>
              </Grid>
            )
          )}

          <Grid container item md={12}>
            <Grid item md={1.5} />
            <Grid item md={7}>
              <Typography fontSize={14}>{file.description}</Typography>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  ));
}
