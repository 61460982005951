import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { tableStyles } from "./Enrollment.style";

export default function GradeStudents({ students }) {
  return (
    <TableContainer>
      <Table sx={tableStyles} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Last Name</TableCell>
            <TableCell align="left">First Name</TableCell>
            <TableCell align="left">STUDENT ID</TableCell>
            <TableCell align="center">CLASSES</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {students.map((student) => (
            <TableRow key={student.id} hover>
              <TableCell align="left" scope="student">
                <Typography>{student.last_name}</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>{student.first_name}</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>{student.ae_id}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography>{student.klass_count}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
