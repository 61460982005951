import React, { useContext, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useOutletContext } from "react-router-dom";
import {
  SecurityGroupContainer,
  SecurityGroupHeader,
  SecurityGroupUser,
} from "./SecurityGroup.style";
import SecurityGroupRow from "./SecurityGroupRow";
import SecurityGroupPermissions from "./SecurityGroupPermissions";
import securityGroupsService from "../../service/securityGroupsService";
import permissionsService from "../../service/permissionsService";
import securitygGroupPermissionsService from "../../service/securitygGroupPermissionsService";
import usersService from "../../service/usersService";
import SecurityGroupModal from "./SecurityGroupModal";
import { QuickBarContext } from "../../context/QuickBarContext";
import SnackBarNotification from "../SnackBarNotification";
import { PermissionsContext } from "../../context/PermissionsContext";
import APP_PERMISSIONS from "../../utils/constants/permissions";
import Authorize from "../Authorize";

function SecurityGroup() {
  const [schoolId] = useOutletContext();
  const quickBarContext = useContext(QuickBarContext);
  const [securityGroups, setSecurityGroups] = useState([]);
  const [selectedSecurityGroup, setSelectedSecurityGroup] = useState(null);
  const [permissionsByCategory, setPermissionsByCategory] = useState(new Map());
  const [securityGroupPermissions, setSecurityGroupPermissions] = useState(
    new Map()
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [sgName, setSgName] = useState("");
  const [operation, setOperation] = useState("");
  const [sgClone, setSgClone] = useState(null);
  const [snackBarOpen, setSnackBarOpen] = useState({
    open: false,
    message: null,
  });
  const { hasAnyPermissionType } = useContext(PermissionsContext);

  const getSecurityGroupPermissions = async (securityGroup) => {
    if (schoolId && selectedSecurityGroup) {
      const response = await securitygGroupPermissionsService.fetchAll({
        securityGroupId: securityGroup.id,
      });

      if (response.data) {
        const sgPermissionsMap = new Map();

        response.data.forEach((sgPermission) => {
          sgPermissionsMap.set(sgPermission.permission_id, sgPermission);
        });

        setSecurityGroupPermissions(sgPermissionsMap);
      }
    }
  };

  const getUsers = async (securityGroup) => {
    if (schoolId && selectedSecurityGroup) {
      const response = await usersService.fetchAll({
        securityGroupId: securityGroup.id,
      });

      if (response.data) {
        setUsers(response.data);
      }
    }
  };

  const getSecurityGroups = async () => {
    if (schoolId) {
      const response = await securityGroupsService.fetchAll({ schoolId });

      if (response.data) {
        setSecurityGroups(response.data);
        if (response.data.length > 0) {
          setSelectedSecurityGroup(response.data[0]);
        }
      }
    }
  };

  const getPermissions = async () => {
    if (schoolId) {
      const response = await permissionsService.fetchAll({ schoolId });

      if (response.data) {
        let permissionsMap = new Map();

        response.data.forEach((permission) => {
          if (permissionsMap.has(permission.category)) {
            const pc = permissionsMap.get(permission.category);
            pc.push(permission);
            return;
          }
          permissionsMap.set(permission.category, [permission]);
        });

        // Move "App Settings" category on top
        if (permissionsMap.has("App Settings")) {
          const entries = Array.from(permissionsMap.entries());
          const entryToMove = entries.find(([key]) => key === "App Settings");
          if (entryToMove) {
            entries.splice(entries.indexOf(entryToMove), 1);
            entries.unshift(entryToMove);
          }
          permissionsMap = new Map(entries);
        }
        setPermissionsByCategory(permissionsMap);
      }
    }
  };

  const handleClone = async (sg) => {
    setOperation("clone");
    setModalOpen(true);
    setSgClone(sg);
  };

  const handleCreate = async () => {
    setOperation("create");
    setModalOpen(true);
  };

  useEffect(() => {
    (async () => {
      await getSecurityGroups();
      await getPermissions();
    })();
  }, [snackBarOpen]);

  useEffect(() => {
    (async () => {
      await getSecurityGroupPermissions(selectedSecurityGroup);
      await getUsers(selectedSecurityGroup);
    })();
  }, [selectedSecurityGroup]);

  useEffect(() => {
    (async () => {
      if (quickBarContext.addSecurityGroups) {
        await handleCreate();
      }

      return () => {
        quickBarContext.hideAddSecurityGroups();
      };
    })();
  }, [quickBarContext.addSecurityGroups]);

  return (
    <Authorize permission={hasAnyPermissionType(APP_PERMISSIONS.SECURITY)}>
      <Box sx={SecurityGroupContainer}>
        <Grid sx={{ mt: "30px" }} container columnSpacing={2}>
          <Grid item sm={4} sx={{ borderRight: "1px solid black" }}>
            <Box sx={SecurityGroupHeader}>Security Groups</Box>
            {securityGroups.length > 0 &&
              securityGroups.map((sg) => (
                <SecurityGroupRow
                  key={sg.id}
                  securityGroup={sg}
                  selectedSecurityGroup={selectedSecurityGroup}
                  setSelectedSecurityGroup={setSelectedSecurityGroup}
                  getSecurityGroups={getSecurityGroups}
                  handleClone={handleClone}
                  setSnackBarOpen={setSnackBarOpen}
                />
              ))}
          </Grid>
          <Grid item sm={4} sx={{ borderRight: "1px solid #D8D8D8" }}>
            <Box sx={SecurityGroupHeader}>Permissions</Box>
            <SecurityGroupPermissions
              permissionsByCategory={permissionsByCategory}
              securityGroupPermissions={securityGroupPermissions}
              selectedSecurityGroup={selectedSecurityGroup}
              getSecurityGroupPermissions={getSecurityGroupPermissions}
            />
          </Grid>
          <Grid item sm={4}>
            <Box sx={SecurityGroupHeader}>Individuals Assigned</Box>
            {users.length > 0 &&
              users.map((user) => (
                <Box sx={SecurityGroupUser} key={user.id}>
                  {user.staff?.first_name} {user.staff?.last_name}
                </Box>
              ))}
          </Grid>
        </Grid>
        <SecurityGroupModal
          open={modalOpen}
          handleClose={() => {
            setOperation("");
            setModalOpen(false);
            setSgClone(null);
            setSgName("");
            quickBarContext.hideAddSecurityGroups();
          }}
          sgName={sgName}
          setSgName={setSgName}
          handleClone={handleClone}
          sgClone={sgClone}
          getSecurityGroups={getSecurityGroups}
          operation={operation}
          schoolId={schoolId}
        />

        <SnackBarNotification
          message={snackBarOpen.message}
          open={snackBarOpen.open}
          severity={snackBarOpen.severity}
          handleClose={() => setSnackBarOpen(false)}
        />
      </Box>
    </Authorize>
  );
}

export default SecurityGroup;
