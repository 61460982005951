import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Card, CardContent, Grid, Link, Typography } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useParams } from "react-router-dom";
import {
  gradeCard,
  gradeCardContent,
  gradeCardContentRow1,
  gradeCardContentRow2,
  gradeCardContentRow3,
  gradeBox,
} from "./Grades.style";
import { fetchGuardianKidGrades } from "../../../../service/guardiansService";
import termGradeLetter from "../../../../utils/termGradeLetter";

export default function GuardianKidsInfoHomeGrades() {
  const { t } = useTranslation("guardian", { keyPrefix: "homeGrades" });
  const params = useParams();
  const guardianId = params.id;
  const kidId = params.kid_id;
  const [kidGrades, setKidGrades] = useState([]);
  const [specialMarks, setSpecialMarks] = useState([]);

  useEffect(() => {
    (async () => {
      if (guardianId && kidId) {
        try {
          const response = await fetchGuardianKidGrades(guardianId, kidId);
          setKidGrades(response.data.kid_grades);
          setSpecialMarks(response.data.special_marks);
        } catch (e) {
          // handle catch
        }
      }
    })();
  }, [kidId]);

  const card = (grade) => (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link underline="none" key={grade.id} sx={{ mb: "10px" }}>
      <Card variant="outlined" sx={[gradeCard, { background: "#FFFFFF" }]}>
        <CardContent sx={gradeCardContent}>
          <Typography sx={gradeCardContentRow1}>
            {grade?.klass?.name}
          </Typography>
          <Typography sx={[gradeCardContentRow2, { fontSize: "68.0244px" }]}>
            {termGradeLetter(grade, specialMarks)}
          </Typography>

          <Typography sx={gradeCardContentRow3}>
            <OpenInNewIcon color="disabled" />
          </Typography>
        </CardContent>
      </Card>
    </Link>
  );

  return (
    <>
      <Box sx={gradeBox}>{t("currentGrades")}</Box>

      {kidGrades.length > 0 ? (
        <Grid container>{kidGrades.map((grade) => card(grade))}</Grid>
      ) : (
        <Typography>{t("noGrades")}</Typography>
      )}
    </>
  );
}
