import axios from "../utils/axios-util";

const collectionsEndpoint = "/api/portfolio_collections";

const fetchAll = async () => {
  const { data } = await axios.get(collectionsEndpoint);

  if (!data.data) {
    console.error("Error fetching collections files from API");
    return [];
  }
  return data;
};

const createCollection = async (params) => {
  const { data } = await axios.post(`${collectionsEndpoint}`, params);
  if (!data.data) {
    console.error("Error posting collection to the API");
    return [];
  }
  return data;
};

const deleteCollection = async (params) => {
  const { data } = await axios.delete(
    `${collectionsEndpoint}/delete-collections`,
    params
  );
  if (!data.data) {
    console.error("Error deleting collection from the API");
    return [];
  }
  return data;
};

export default { createCollection, fetchAll, deleteCollection };
