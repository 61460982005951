import React from "react";
import { Link, useParams } from "react-router-dom";
import { Box, Stack, Avatar, Typography } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CircleIcon from "@mui/icons-material/Circle";
import {
  messagesRow,
  messagesRowTimestamp,
  messagesRowDescription,
  messagesAttachIcon,
  messagesUnreadIcon,
} from "../../views/Messages.styles";
import inboxRowCount from "./InboxRow.styles";
import { font14, mx25, tableAvatar, mr15 } from "../sharedStyles";
import dateTimeFormats from "../../utils/constants/dateTimeFormats";
import moment from "../../utils/constants/momentConfig";

export default function InboxRow({ message }) {
  const params = useParams();
  const schoolId = params.school_id;
  const conversationKey =
    message.conversation_key || message.original_message_conversation_key;

  const formattedBody = message.description.replace(/(<([^>]+)>)/gi, "");

  function handleProfilePictureSrc() {
    if (message?.sender?.image_url) {
      return (
        <img
          alt="profile_image"
          src={message.sender.image_url.url}
          style={{
            borderRadius: "100px",
            width: "56px",
            height: "56px",
            objectFit: "cover",
          }}
        />
      );
    }
    return (
      <Avatar sx={{ ...tableAvatar, width: 56, height: 56 }}>
        {message.sender.first_name.charAt(0).toUpperCase()}
      </Avatar>
    );
  }

  return (
    <Box sx={messagesRow}>
      <Link to={`/school/${schoolId}/messages/conversation/${conversationKey}`}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" alignItems="center">
            {!message.read_at ? (
              <CircleIcon sx={messagesUnreadIcon} />
            ) : (
              <Box sx={mr15} />
            )}
            {handleProfilePictureSrc()}
            <Box sx={mx25}>
              <Typography sx={font14}>
                <strong>{`${message.sender.first_name} ${message.sender.last_name}`}</strong>
              </Typography>
              <Box sx={messagesRowDescription}>
                <Typography>{formattedBody}</Typography>
              </Box>
              <Typography sx={messagesRowTimestamp}>
                {moment(message.created_at).format(dateTimeFormats.LLL)}
              </Typography>
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center">
            {message.has_attachments && (
              <AttachFileIcon sx={messagesAttachIcon} />
            )}
            <Avatar sx={inboxRowCount}>{message.replies_count + 1}</Avatar>
          </Stack>
        </Stack>
      </Link>
    </Box>
  );
}
