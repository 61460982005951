import React from "react";
import { useFieldArray } from "react-hook-form";
import {
  Box,
  Button,
  TextField,
  Checkbox,
  Stack,
  FormControl,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  gpaCalcWidth,
  gpaCodeWidth,
  gpaPassWidth,
  gpaUgpaWidth,
  gpaWidth,
} from "./GPA.styles";
import { cursorPointer, mb6, py10, textCenter } from "../sharedStyles";

export default function GPAScaleRows({
  nestedIndex,
  control,
  register,
  setValue,
  setInitialState,
  managePermission,
}) {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `collections[${nestedIndex}].gpa_scale_codes`,
  });

  const handleAddRow = () => {
    append();
    setInitialState(false);
  };

  return (
    <Box sx={[py10, textCenter]}>
      {fields.map((field, index) => (
        <Stack key={field.id} direction="row" alignItems="center" sx={mb6}>
          <FormControl sx={gpaCodeWidth}>
            <TextField
              disabled={!managePermission}
              {...register(
                `collections[${nestedIndex}].gpa_scale_codes[${index}].code`
              )}
              size="small"
              placeholder="A+"
              onChange={() => setInitialState(false)}
            />
          </FormControl>
          <FormControl sx={gpaWidth}>
            <TextField
              disabled={!managePermission}
              {...register(
                `collections[${nestedIndex}].gpa_scale_codes[${index}].gpa`
              )}
              size="small"
              placeholder="0.000"
              onChange={() => setInitialState(false)}
            />
          </FormControl>
          <FormControl sx={gpaUgpaWidth}>
            <TextField
              disabled={!managePermission}
              {...register(
                `collections[${nestedIndex}].gpa_scale_codes[${index}].ugpa`
              )}
              size="small"
              placeholder="0.000"
              onChange={() => setInitialState(false)}
            />
          </FormControl>
          <FormControl sx={gpaPassWidth}>
            <Checkbox
              disabled={!managePermission}
              defaultChecked={field.pass}
              {...register(
                `collections[${nestedIndex}].gpa_scale_codes[${index}].pass`
              )}
              onChange={(e) => {
                setValue(
                  `collections[${nestedIndex}].gpa_scale_codes[${index}].pass`,
                  e.target.checked,
                  {
                    shouldDirty: true,
                  }
                );
                setInitialState(false);
              }}
            />
          </FormControl>
          <FormControl sx={gpaCalcWidth}>
            <Checkbox
              disabled={!managePermission}
              defaultChecked={field.calculate}
              {...register(
                `collections[${nestedIndex}].gpa_scale_codes[${index}].calculate`
              )}
              onChange={(e) => {
                setValue(
                  `collections[${nestedIndex}].gpa_scale_codes[${index}].calculate`,
                  e.target.checked,
                  {
                    shouldDirty: true,
                  }
                );
                setInitialState(false);
              }}
            />
          </FormControl>
          {managePermission && (
            <DeleteIcon
              sx={cursorPointer}
              onClick={() => {
                remove(index);
                setInitialState(false);
              }}
            />
          )}
        </Stack>
      ))}

      {managePermission && (
        <Button
          sx={{
            color: "#2196F3",
            borderColor: "#2196F3",
            "&:hover": {
              borderColor: "#2196F3",
            },
          }}
          variant="outlined"
          onClick={() => handleAddRow()}
        >
          Add Row
        </Button>
      )}
    </Box>
  );
}
