import axios from "../utils/axios-util";

const eventsEndpoint = "/api/events";

const fetchAllEvents = async (
  studentSlug,
  page,
  count,
  order,
  orderBy,
  query,
  selectedCategory
) => {
  const { data } = await axios.get(
    `${eventsEndpoint}?student_slug=${studentSlug}&page=${page}&count=${count}&orderBy=${orderBy}&order=${order}&query=${query}&category=${selectedCategory}`
  );
  if (!data.data) {
    console.error("Error fetching events from the API");
    return [];
  }
  return data;
};

const createEvent = async (studentSlug, params) => {
  const { data } = await axios.post(
    `${eventsEndpoint}?student_slug=${studentSlug}`,
    params
  );
  if (!data.data) {
    console.error("Error fetching event from the API");
    return [];
  }
  return data;
};

const updateEvent = async (studentId, eventId, params) => {
  const { data } = await axios.patch(
    `${eventsEndpoint}/${eventId}?student_slug=${studentId}`,
    params
  );

  if (!data.data) {
    console.error("Error fetching event from the API");
    return [];
  }
  return data;
};

const fetchEvent = async (studentId, eventId) => {
  const { data } = await axios.get(
    `${eventsEndpoint}/${eventId}?student_slug=${studentId}`
  );
  if (!data.data) {
    console.error("Error fetching event from the API");
    return [];
  }
  return data;
};

const deleteEvent = async (studentId, eventId) => {
  const { data } = await axios.delete(
    `${eventsEndpoint}/${eventId}?student_slug=${studentId}`
  );
  if (!data.data) {
    console.error("Error fetching event from the API");
    return [];
  }
  return data;
};

export default {
  fetchAllEvents,
  createEvent,
  fetchEvent,
  updateEvent,
  deleteEvent,
};
