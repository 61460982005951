const reportCardDrawer = { width: "800px" };

const reportCardBox = {
  mt: "40px",
  ml: "75px",
  mr: "75px",
};

const mainTitle = {
  color: "black",
  fontSize: "34px",
};

const downloadTitle = {
  color: "black",
  fontSize: "16px",
  fontWeight: "500",
};

const checkbox = {
  position: "relative",
  borderRadius: "50%",
  width: 45,
  height: 45,
  border: "1px solid",
  mr: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const box = {
  mt: "30px",
  pb: "10px",
  display: "flex",
};

const schoolBox = {
  borderBottom: "1px #BFC7CA solid",
};

const title = {
  pt: "10px",
  color: "black",
  fontSize: "16px",
};

const studentDownloadBtn = {
  background: "#2196F3",
  "&:hover": { background: "#2196F3" },
  ml: "426px !important",
  textTransform: "none",
  margin: "4px",
  "& .MuiCircularProgress-root": {
    color: "white",
  },
};

const gradeDownloadBtn = {
  ...studentDownloadBtn,
  ml: "394px !important",
};

const schoolZipDownloadBtn = {
  ...studentDownloadBtn,
  ml: "76px !important",
};

const schoolPdfDownloadBtn = {
  ...studentDownloadBtn,
  ml: "130px !important",
};

const selectBox = {
  borderBottom: "1px #BFC7CA solid",
  pb: "10px",
};

const selectForm = {
  ml: "50px",
  width: "400px",
};

export {
  reportCardDrawer,
  reportCardBox,
  mainTitle,
  downloadTitle,
  checkbox,
  box,
  schoolBox,
  title,
  studentDownloadBtn,
  gradeDownloadBtn,
  schoolZipDownloadBtn,
  schoolPdfDownloadBtn,
  selectBox,
  selectForm,
};
