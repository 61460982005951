import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  CircularProgress,
  Container,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";
import {
  Link,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { loader, paginationContent, textCenter } from "../../sharedStyles";
import {
  messagesAttachIcon,
  messagesRow,
  messagesRowDescription,
  messagesRowTimestamp,
} from "../../../views/Messages.styles";
import { messagesRowName, inboxRowCount } from "./StudentInfoMessages.styles";
import moment from "../../../utils/constants/momentConfig";
import dateTimeFormats from "../../../utils/constants/dateTimeFormats";
import studentsService from "../../../service/studentsService";
import profilePictureIcon from "../../../utils/profilePictureIcon";

export default function StudentInfoMessages() {
  const params = useParams();
  const guardianId = params.id;
  const kidId = params.id;
  const [kidMessages, setKidMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(searchParams.get("page") || 1);
  const [totalPages, setTotalPages] = useState(0);
  const [, , messageDrawerStatus] = useOutletContext();

  const setStudentMessages = async () => {
    try {
      setLoading(true);
      const response = await studentsService.fetchStudentMessages({
        params: {
          guardianId,
          kidId,
          count: 10,
          page,
          studentSlug: params.student_slug,
        },
      });
      if (response.data) {
        setLoading(false);
        setKidMessages(response.data.data);

        const lastPage = response.data.pagination.total_pages;
        setTotalPages(lastPage);
      }
    } catch (e) {
      // handle catch
    }
  };
  useEffect(() => {
    (async () => {
      if (params.student_slug && page && !messageDrawerStatus) {
        setStudentMessages();
      }
    })();
  }, [params.student_slug, page, messageDrawerStatus]);

  const handleChangePage = (event, value) => {
    setPage(value);
    searchParams.set("page", value);
    setSearchParams(searchParams);
  };

  function truncate(str) {
    const newStr = str.length > 125 ? `${str.substring(0, 125)}...` : str;
    return newStr.replace(/(<([^>]+)>)/gi, "").replaceAll("&nbsp;", "");
  }

  function formatDateTime(str) {
    return moment(str).format(dateTimeFormats.LLL);
  }

  function displayName(sender) {
    return `${sender.first_name} ${sender.last_name}`;
  }

  const conversationKey = (message) =>
    message.conversation_key || message.original_message_conversation_key;

  if (loading) {
    return <CircularProgress sx={loader} size={100} />;
  }

  return (
    <Container maxWidth={false} variant="header">
      <Container maxWidth="xl">
        {kidMessages.length > 0 ? (
          <>
            <Box>
              {kidMessages.map((message) => (
                <Box sx={messagesRow} key={message.id}>
                  <Link
                    to={`/school/${params.school_id}/students/${
                      params.student_slug
                    }/messages/${conversationKey(message)}`}
                  >
                    <Stack direction="row" justifyContent="space-between">
                      <Stack direction="row">
                        <img
                          alt="profile_image"
                          src={profilePictureIcon(
                            message?.recent_message?.sender
                          )}
                          style={{
                            borderRadius: "100px",
                            width: "90px",
                            height: "90px",
                            objectFit: "cover",
                          }}
                        />
                        <Box mx="25px" display="inline-grid">
                          <Typography sx={messagesRowName}>
                            {displayName(message.recent_message?.sender)}
                          </Typography>
                          <Typography sx={messagesRowDescription}>
                            {truncate(message.recent_message.description)}
                          </Typography>
                          <Typography sx={messagesRowTimestamp}>
                            {formatDateTime(message.recent_message.created_at)}
                          </Typography>
                        </Box>
                      </Stack>
                      <Stack direction="row" alignItems="center">
                        {message.has_attachments_in_thread && (
                          <AttachFileIcon sx={messagesAttachIcon} />
                        )}
                        <Avatar sx={inboxRowCount}>
                          {message.replies_count + 1}
                        </Avatar>
                      </Stack>
                    </Stack>
                  </Link>
                </Box>
              ))}
            </Box>

            <Pagination
              count={totalPages}
              page={parseInt(page, 10)}
              onChange={handleChangePage}
              sx={paginationContent}
            />
          </>
        ) : (
          <Box sx={textCenter}>
            <Typography>No messages.</Typography>
          </Box>
        )}
      </Container>
    </Container>
  );
}
