import CustomFieldBase from "./CustomFieldBase";

export default class CustomDropdownField extends CustomFieldBase {
  constructor(field) {
    super(field);
    this.dataType = "value_text";
    this.type = "dropdown-list";
    this.prompt = "DROPDOWN LIST";
    this.properties.options = field?.properties?.options || [];
    this.propertyComponents = ["options", "isVisible", "isRequired"];
  }
}
