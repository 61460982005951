import fileService from "../service/fileService";

const messageFileDownload = async (file, conversationKey) => {
  await fileService.fetchMessageFile({
    id: file?.id,
    filename: file?.filename,
    conversationKey,
  });
};

export default messageFileDownload;
