import React from "react";
import { Box, Container, Tab } from "@mui/material";
import {
  Outlet,
  useNavigate,
  useLocation,
  useParams,
  useOutletContext,
} from "react-router-dom";
import {
  CssTabs,
  studentInfoContainer,
  studentInfoContainer2,
} from "./Info.styles";

export default function StudentPortalInfo() {
  const [student] = useOutletContext();
  const params = useParams();
  const studentId = params.id;
  const schoolId = params.school_id;
  const navigate = useNavigate();
  const location = useLocation();

  const tabs = [
    {
      index: 0,
      label: "Home",
      url: `/student/${studentId}/schools/${schoolId}/home`,
    },
    {
      index: 1,
      label: "Schedule",
      url: `/student/${studentId}/schools/${schoolId}/schedule`,
    },
    {
      index: 2,
      label: "Classes",
      url: `/student/${studentId}/schools/${schoolId}/classes`,
    },
  ];

  const getIndexFromLocation = () => {
    // eslint-disable-next-line array-callback-return,consistent-return
    const index = tabs.findIndex((tab) => {
      if (location.pathname.includes(tab.url)) return true;
    });

    if (location.pathname.includes("messages")) {
      return false;
    }
    // handle an unhandled path with a sane default.
    if (index < 0) return tabs[0].index;
    return tabs[index].index;
  };

  return (
    <>
      <Container maxWidth={false} sx={studentInfoContainer}>
        <Box>
          <CssTabs value={getIndexFromLocation()}>
            {tabs.map((tab) => (
              <Tab
                key={tab.index}
                label={tab.label}
                onClick={() => navigate(tab.url)}
              />
            ))}
          </CssTabs>
        </Box>
      </Container>
      <Container maxWidth={false} sx={studentInfoContainer2}>
        <Outlet context={[student]} />
      </Container>
    </>
  );
}
