import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Button, Grid, Typography, Box, Tooltip } from "@mui/material";
import { useParams } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { formHeader, formButtons, danger, pb15 } from "../../../sharedStyles";
import {
  guardianActions,
  formGrid,
  guardianSearchButton,
  guardianSelectButton,
  guardianCreateButton,
  guardianTable,
  guardianCreateContainer,
  boxText,
  disabledRow,
} from "../../Account/Guardian/Guardian.styles";
import {
  searchGuardians,
  addGuardianToStudent,
} from "../../../../service/guardiansService";
import ErasableTextField from "../../../ErasableTextField";
import { QuickBarContext } from "../../../../context/QuickBarContext";
import studentsService from "../../../../service/studentsService";
import { SnackbarContext } from "../../../../context/SnackbarContext";
import { StudentActionsContext } from "../../../../context/StudentActionsContext";

function SearchGuardian({
  setIsGuardianDrawerOpen,
  setShowGuardianCreateForm,
  setShowGuardianSearchForm,
}) {
  const params = useParams();
  const [guardians, setGuardians] = useState();
  const [studentGuardians, setStudentGuardians] = useState([]);
  const quickBarContext = useContext(QuickBarContext);
  const methods = useForm();
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showGuardianCreate, setShowGuardianCreate] = useState(false);
  const snackbarContext = useRef(useContext(SnackbarContext));
  const studentActionsContext = useContext(StudentActionsContext);

  useEffect(() => {
    (async () => {
      const response = await studentsService.fetchGuardiansByStudent(
        params.student_slug
      );
      setStudentGuardians(response);
    })();
  }, []);
  useEffect(() => {
    (async () => {
      if (quickBarContext.addParents) {
        setIsGuardianDrawerOpen(true);
        quickBarContext.hideAddParents();
      }
    })();
  }, [quickBarContext.addParents]);

  const cleanForm = () => {
    setIsGuardianDrawerOpen(false);
    studentActionsContext.fireParentsUpdated(true);
  };

  const handleCancel = () => {
    cleanForm();
  };

  const onSubmit = async () => {
    await setError(null);
    setLoading(true);
    setShowGuardianCreate(true);

    if (firstName || lastName || email) {
      try {
        const response = await searchGuardians(firstName, lastName, email);
        await setGuardians(response.data);
        if (response.data <= 0) {
          setError("No guardians found.");
        }
      } catch (e) {
        // handle catch
      }
    } else {
      setError("Please complete one of the fields above to search.");
    }
    setLoading(false);
  };

  const handleSelect = async (guardianId) => {
    const body = {
      student_id: params.student_slug,
      guardian_id: guardianId,
    };
    try {
      const response = await addGuardianToStudent(body);
      if (response.status === 200) {
        snackbarContext.current.setSnackbar({
          open: true,
          message: "Guardian added.",
        });
        cleanForm();
      }
    } catch (e) {
      // handle catch
    }
  };

  const handleCreateNewGuardian = async () => {
    setShowGuardianCreateForm(true);
    setShowGuardianSearchForm(false);
  };

  const renderForm = () => (
    <Grid container item sx={formGrid} columnSpacing={2} rowSpacing={2}>
      <Grid item sm={12} sx={pb15}>
        <Box sx={boxText}>
          Enter guardian First name or Last name or Email to search.
        </Box>
      </Grid>

      <Grid item sm={6}>
        <ErasableTextField
          id="first_name"
          label="First Name"
          fullWidth
          onChange={(event) => {
            setFirstName(event.target.value);
          }}
        />
      </Grid>
      <Grid item sm={6}>
        <ErasableTextField
          id="last_name"
          label="Last Name"
          fullWidth
          onChange={(event) => {
            setLastName(event.target.value);
          }}
        />
      </Grid>

      <Grid item sm={12} sx={pb15}>
        <ErasableTextField
          id="email"
          label="Email Address"
          fullWidth
          type="email"
          onChange={(event) => {
            setEmail(event.target.value);
          }}
        />
      </Grid>

      <Grid item sm={12}>
        <Button
          sx={guardianSearchButton}
          type="submit"
          startIcon={<SearchIcon />}
          disabled={loading}
        >
          {loading ? "Searching..." : "Search"}
        </Button>
      </Grid>

      {error && (
        <Grid item sm={12}>
          {error && <Typography sx={danger}>{error}</Typography>}
        </Grid>
      )}
    </Grid>
  );

  const listMatches = () => (
    <TableContainer sx={guardianTable}>
      <Box sx={boxText}>Possible matches</Box>
      <hr />
      <Table>
        <TableBody>
          {guardians &&
            guardians.map((guardian) => {
              const isExistingGuardian = studentGuardians.some(
                (g) => g.id === guardian.id
              );
              return (
                <Tooltip
                  placement="top"
                  title={isExistingGuardian ? "Guardian already added." : ""}
                  key={guardian.id}
                >
                  <TableRow
                    key={guardian.id}
                    sx={isExistingGuardian === true ? disabledRow : null}
                  >
                    <TableCell>
                      <Typography>
                        {`${guardian.first_name} ${guardian.last_name}`}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{guardian.email}</Typography>
                    </TableCell>
                    <TableCell>
                      <Button
                        sx={guardianSelectButton}
                        onClick={() => handleSelect(guardian.id)}
                        disabled={isExistingGuardian}
                      >
                        Select
                      </Button>
                    </TableCell>
                  </TableRow>
                </Tooltip>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Grid container>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          style={{ width: "100%" }}
        >
          <Grid
            container
            item
            sx={formHeader}
            direction="row"
            columnSpacing={4}
            rowSpacing={0}
          >
            <Grid item sm={7}>
              <Grid item>
                <Typography variant="h2">Add new guardian</Typography>
              </Grid>
            </Grid>
            <Grid item container sx={guardianActions} sm={5}>
              <Button
                variant="outlined"
                sx={formButtons}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>

          {renderForm()}
        </form>
      </FormProvider>

      {guardians && guardians.length > 0 && listMatches()}

      {showGuardianCreate && (
        <Grid item container sx={guardianCreateContainer}>
          <Button
            sx={guardianCreateButton}
            onClick={handleCreateNewGuardian}
            startIcon={<AddIcon />}
          >
            Create New Guardian
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

export default SearchGuardian;
