import {
  Box,
  Container,
  Stack,
  Typography,
  Drawer,
  Button,
  Grid,
  FormControl,
  Switch,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";
import AdmissionsTabs from "../components/Admissions/AdmissionsTabs";
import {
  pageHeaderTitle,
  pageTabsContainer,
  pb0,
  switchBtn,
} from "../components/sharedStyles";
import {
  admissionsContainer,
  approvalsButtonStyle,
  searchAndSelectContainer,
} from "./Admissions.styles";
import ReEnrollStudents from "../components/Admissions/Drawers/ReEnrollStudents";
import { QuickBarContext } from "../context/QuickBarContext";
import admissionProcessService from "../service/admissionProcessService";
import AddNewInquiry from "../components/Admissions/Drawers/AddNewInquiry";
import { FormBuilderContextProvider } from "../components/Admissions/Setup/FormBuilder/FormBuilderContext";
import SchoolYearSelect from "../components/SchoolYearSelect";
import { CommonContext } from "../context/CommonContext";
import schoolYearService from "../service/schoolYearService";
import { SnackbarContext } from "../context/SnackbarContext";

export default function Admissions() {
  const schoolId = useParams().school_id;
  const [searchParams] = useSearchParams();
  const [, setSnackBarOpen, , , , , , , , , , currentSchool] =
    useOutletContext();

  const [tabChanged, setTabChanged] = useState();
  const [reEnrollDrawerOpen, setReEnrollDrawerOpen] = useState(false);
  const [addNewInquiryDrawerOpen, setAddNewInquiryDrawerOpen] = useState(false);
  const [approvalsAndActionsCount, setApprovalsAndActionsCount] = useState({});

  const quickBarContext = useContext(QuickBarContext);
  const commonContext = useContext(CommonContext);
  const snackbarContext = useContext(SnackbarContext);
  const [selectedSchoolYear, setSelectedSchoolYear] = useState(null);
  const [schoolYearActivated, setSchoolYearActivated] = useState(false);

  const navigate = useNavigate();

  const location = useLocation();

  const useAdvanceAdmissions = currentSchool?.admission_version_advanced;

  const formattedParams = () => {
    let paramsUrl = "?";
    const schoolYearId = searchParams.get("school_year");
    if (schoolYearId) {
      paramsUrl = paramsUrl.concat(`&school_year=${schoolYearId}`);
    }

    return paramsUrl;
  };

  // const navigateToApprovals = () => {
  //   navigate(`/school/${schoolId}/admissions/approvals${formattedParams()}`);
  // };

  const navigateToActions = () => {
    navigate(`/school/${schoolId}/admissions/actions${formattedParams()}`);
  };

  useEffect(() => {
    if (tabChanged) {
      setTabChanged(false);
    }
  }, [tabChanged]);

  useEffect(() => {
    if (quickBarContext.reEnrollStudents) {
      setReEnrollDrawerOpen(true);
      quickBarContext.cleanReEnrollStudents();
    }
  }, [quickBarContext.reEnrollStudents]);

  useEffect(() => {
    if (quickBarContext.addNewInquiry) {
      setAddNewInquiryDrawerOpen(true);
      quickBarContext.cleanAddNewInquiry();
    }
  }, [quickBarContext.addNewInquiry]);

  const fetchAndSetActionsApprovalsCount = () => {
    admissionProcessService
      .pendingApprovalsAndActionCount(searchParams.get("school_year"))
      .then((response) => {
        setApprovalsAndActionsCount(response.data);
      });
  };

  useEffect(() => {
    if (searchParams.get("school_year")) {
      fetchAndSetActionsApprovalsCount();
    }
  }, [searchParams.get("school_year")]);

  useEffect(() => {
    if (commonContext.processStarted) {
      fetchAndSetActionsApprovalsCount();
      commonContext.cleanFireProcessStarted();
    }
  }, [commonContext.processStarted]);

  const schoolYearAdmissionsActive = (status) => {
    setSelectedSchoolYear({ ...selectedSchoolYear, admissions_active: status });
    schoolYearService
      .updateAdmissionsActiveStatus({
        id: selectedSchoolYear.id,
        status,
      })
      .then(() => {
        snackbarContext.setSnackbar({
          open: true,
          message: "Status updated.",
          severity: "success",
        });
        setSchoolYearActivated(true);
      })
      .catch(() => {
        setSelectedSchoolYear({
          ...selectedSchoolYear,
          admissions_active: !status,
        });
        snackbarContext.setSnackbar({
          open: true,
          message: "Unable to update status",
          severity: "error",
        });
      });
  };

  return (
    <>
      <Container maxWidth={false} variant="header" sx={pb0}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Stack direction="row" alignItems="baseline">
              <Typography sx={pageHeaderTitle}>Admissions</Typography>
            </Stack>
          </Box>

          <Grid container justifyContent="center">
            {/* <Grid item md={2.5}>
              <Button
                sx={approvalsButtonStyle(
                  location.pathname.includes("approvals")
                )}
                onClick={() => navigateToApprovals()}
              >
                <Typography
                  className="approvals-text"
                  borderRight="1px solid black"
                >
                  APPROVALS
                </Typography>
                <Typography className="count-text" fontSize={20}>
                  {approvalsAndActionsCount.approvals_count}
                </Typography>
              </Button>
            </Grid> */}
            {useAdvanceAdmissions && (
              <Grid item md={2.5}>
                <Button
                  sx={approvalsButtonStyle(
                    location.pathname.includes("actions")
                  )}
                  onClick={() => navigateToActions()}
                >
                  <Typography className="approvals-text">ACTIONS</Typography>
                  <Typography className="count-text">
                    {approvalsAndActionsCount.actions_count}
                  </Typography>
                </Button>
              </Grid>
            )}
          </Grid>
          <Box sx={searchAndSelectContainer}>
            <Box display="flex" alignItems="center" mr={2}>
              <Typography fontSize={18} whiteSpace="nowrap">
                Admissions Active
              </Typography>
              <Switch
                checked={selectedSchoolYear?.admissions_active === true}
                sx={switchBtn}
                onChange={(e) => schoolYearAdmissionsActive(e.target.checked)}
              />
            </Box>
            <FormControl fullWidth>
              <SchoolYearSelect
                schoolId={schoolId}
                setSnackBarOpen={setSnackBarOpen}
                setSelectedSchoolYear={setSelectedSchoolYear}
                setSchoolYearActivated={setSchoolYearActivated}
                schoolYearActivated={schoolYearActivated}
              />
            </FormControl>
          </Box>
        </Stack>

        <Box sx={pageTabsContainer}>
          <AdmissionsTabs
            setTabChanged={setTabChanged}
            useAdvanceAdmissions={useAdvanceAdmissions}
          />
        </Box>
      </Container>

      <Box sx={admissionsContainer}>
        <Outlet context={[schoolId, useAdvanceAdmissions]} />
      </Box>

      <Drawer anchor="right" open={reEnrollDrawerOpen}>
        <Box sx={{ width: "1000px", overflow: "hidden" }}>
          <ReEnrollStudents setReEnrollDrawerOpen={setReEnrollDrawerOpen} />
        </Box>
      </Drawer>
      <Drawer anchor="right" open={addNewInquiryDrawerOpen}>
        <Box sx={{ width: "1000px" }}>
          <FormBuilderContextProvider>
            <AddNewInquiry
              setAddNewInquiryDrawerOpen={setAddNewInquiryDrawerOpen}
            />
          </FormBuilderContextProvider>
        </Box>
      </Drawer>
    </>
  );
}
