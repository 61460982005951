import React, { useContext, useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { NavigationContext } from "../context/NavigationContext";
import { useUnsavedChanges } from "../context/UnsavedChangesContext";

export default function NavigationAlertDialog() {
  const navigationContext = useContext(NavigationContext);
  const navigate = useNavigate();
  const { setUnsavedChanges } = useUnsavedChanges();

  const [showAlert, setShowAlert] = useState(false);
  const handleClose = () => {
    setShowAlert(false);
  };

  const handleConfirm = () => {
    navigate(navigationContext.navigationPath);
    setShowAlert(false);
    setUnsavedChanges(false);
  };

  useEffect(() => {
    if (navigationContext.navigationAlert) {
      setShowAlert(true);
      navigationContext.cleanNavigationAlert();
    }
  }, [navigationContext.navigationAlert]);

  return (
    <Dialog
      open={showAlert}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Unsaved changes!</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to leave?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleConfirm} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
