import { useNavigate } from "react-router-dom";
import Error401 from "../views/401";

function Authorize({ children, permission }) {
  const navigate = useNavigate();

  if (!permission) {
    navigate("/401");
  }

  return permission ? children : Error401;
}

export default Authorize;
