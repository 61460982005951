import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
  Box,
  Grid,
  Switch,
  Tooltip,
} from "@mui/material";
import { m0, mt15, mx5 } from "../../../sharedStyles";

const availablePreferences = [
  {
    name: "academic",
    events: [
      // "progress_report",
      // "assignments_graded",
      "assignment_missing_guardian",
      // "assignment_incomplete",
    ],
  },
  // {
  //   name: "attendance",
  //   events: [
  //     "attendance_marked_absent",
  //     "attendance_marked_tardy",
  //   ],
  // },
  // {
  //   name: "behavior",
  //   events: [
  //     "behavior_merit_recorded",
  //     "behavior_demerit_recorded",
  //   ],
  // },
];

function NotificationsSettings({ setting, enabled }) {
  const { t } = useTranslation("notifications");
  const { setValue } = useFormContext();
  const [data, setData] = useState({ enabled: setting.enabled });
  const [events, setEvents] = useState(setting.preferences || []);

  const getEventSet = (eventName) =>
    events.filter((e) => e.event_name === eventName)[0];

  const isCheckedEvent = (name) =>
    events.filter((e) => e.event_name === name).length > 0 || false;

  const isCheckedChannel = (name, channel) =>
    getEventSet(name)[channel] || false;

  const handleSettingChange = (e) => {
    const { checked } = e.target;
    setData({ enabled: checked });
  };

  const handleEventChange = (e) => {
    const { checked, value } = e.target;
    const currentSets = events.filter((c) => c.event_name !== value);

    if (checked) {
      setEvents([
        ...currentSets,
        { event_name: value, email: true, text: false },
      ]);
    } else {
      setEvents(currentSets);
    }
  };

  const handleChannelChange = (e, eventName) => {
    const { checked, value } = e.target;
    const currentSets = events.filter((c) => c.event_name !== eventName);
    const targetSet = getEventSet(eventName);
    setEvents([...currentSets, { ...targetSet, [value]: checked }]);
  };

  useEffect(() => {
    setValue("notifications.preferences", events);
  }, [events]);

  useEffect(() => {
    setValue("notifications.settings", data);
  }, [data]);

  return (
    <Box>
      <Grid
        sx={mt15}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid>
          <Typography>
            {t("receiveNotifications", { keyPrefix: "settings" })}
          </Typography>
        </Grid>

        <Grid>
          {enabled ? (
            <Switch
              color="info"
              checked={data?.enabled || false}
              onChange={handleSettingChange}
            />
          ) : (
            <Tooltip
              title="Portal Access should be enabled"
              placement="bottom-start"
              arrow
            >
              <span>
                <Switch disabled />
              </span>
            </Tooltip>
          )}
        </Grid>
      </Grid>

      {availablePreferences.map((option) => (
        <Box key={option.name} className="eventOption">
          <Grid container>
            <Grid item xs={8}>
              <Box className="optionsTitle">
                {t(option.name, { keyPrefix: "categories" })}
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Grid container className="optionsHeaders">
                <Grid item xs={6}>
                  <Typography align="center">
                    {t("email", { keyPrefix: "settings" })}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography align="center">
                    {t("text", { keyPrefix: "settings" })}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {option.events.map((event) => (
            <Grid container key={event}>
              <Grid item xs={8}>
                <FormGroup sx={mx5} className="eventCheckmarkOption">
                  <FormControlLabel
                    label={t("settingsLabel", { keyPrefix: `events.${event}` })}
                    sx={m0}
                    control={
                      <Checkbox
                        size="small"
                        disabled={!enabled || !data?.enabled}
                        value={event}
                        checked={isCheckedEvent(event)}
                        onChange={handleEventChange}
                      />
                    }
                  />
                </FormGroup>
              </Grid>

              {isCheckedEvent(event) && (
                <Grid item xs={4} className="emailTextOptions">
                  <Grid container>
                    <Grid item xs={6}>
                      <FormGroup>
                        <FormControlLabel
                          sx={m0}
                          control={
                            <Checkbox
                              size="small"
                              disabled={!enabled || !data?.enabled}
                              value="email"
                              checked={isCheckedChannel(event, "email")}
                              onChange={(e) => handleChannelChange(e, event)}
                            />
                          }
                        />
                      </FormGroup>
                    </Grid>

                    <Grid item xs={6}>
                      <FormGroup>
                        <FormControlLabel
                          sx={m0}
                          control={
                            <Checkbox
                              size="small"
                              disabled={true || !enabled || !data?.enabled}
                              value="text"
                              checked={isCheckedChannel(event, "text")}
                              onChange={(e) => handleChannelChange(e, event)}
                            />
                          }
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          ))}
        </Box>
      ))}
    </Box>
  );
}

export default NotificationsSettings;
