import React from "react";
import { CssBaseline, Box, Grid, Button } from "@mui/material";
import {
  footerStyle,
  footerGridContainer,
  textGridItem,
  launchGridItem,
  launchBtn,
  poweredByLogoGridItem,
  poweredByTxt,
  poweredByLogo,
  poweredByLogoTagLine,
  aeLogoGridItem,
  aeLogo,
} from "./Footer.styles";

const openSchoolWebsite = (url) => {
  // eslint-disable-next-line no-param-reassign
  url = url.match(/^https?:/) ? url : `//${url}`;
  window.open(url, "_blank", "noreferrer");
};

export default function Footer({ studentSchool }) {
  return (
    <footer style={footerStyle}>
      <CssBaseline />
      <Grid container alignItems="stretch" sx={footerGridContainer}>
        <Grid item md={6} container sx={textGridItem}>
          {studentSchool?.description && (
            <Box>{studentSchool?.description}</Box>
          )}
        </Grid>
        <Grid item md={6} container sx={launchGridItem}>
          <Box>
            {studentSchool?.website && (
              <Button
                sx={launchBtn}
                onClick={() =>
                  openSchoolWebsite(studentSchool?.website?.toString())
                }
              >
                Launch School Website
              </Button>
            )}
          </Box>
        </Grid>

        <Grid item xs={6} container sx={poweredByLogoGridItem}>
          <div>
            <div style={poweredByTxt}> Powered by </div>
            <img
              src="/assets/logo-new.png"
              style={poweredByLogo}
              alt="ae logo"
            />
            <div style={poweredByLogoTagLine}>
              Adventist Education Student Information System
            </div>
          </div>
        </Grid>
        <Grid item xs={6} container sx={aeLogoGridItem}>
          <div>
            <img
              src="/assets/ae-footer-logo.png"
              style={aeLogo}
              alt="ae logo"
            />
          </div>
        </Grid>
      </Grid>
    </footer>
  );
}
