import theme from "../../utils";

const appBarBox = (openPopover) => ({
  overflowX: openPopover ? "unset" : "hidden",
});

const menuDefaultColor = {
  color: theme.palette.background.default,
};

const navBarAccountIcon = {
  width: "100%",
  height: "100%",
  color: "primary.main",
};

const navBarAvatar = { bgcolor: "#ffffff" };

const navBarIconButton = {
  mr: 2,
  pt: "8px",
  transform: "scaleX(-1)",
};

const navBarMenu = {
  overflow: "visible",
  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
  mt: 1.5,
  backgroundColor: theme.palette.primary.main,
  "& .MuiAvatar-root": {
    width: 32,
    height: 32,
    ml: -0.5,
    mr: 1,
  },
  "&:before": {
    content: '""',
    display: "block",
    position: "absolute",
    top: 0,
    right: 22,
    width: 10,
    height: 10,
    bgcolor: theme.palette.primary.main,
    transform: "translateY(-50%) rotate(45deg)",
  },
};

const navBarTypography = {
  flexGrow: 1,
  alignSelf: "center",
  fontSize: "24px",
  fontWeight: 500,
  pt: "2px",
};

const styledAppBar = {
  minWidth: "1400px",
  width: "100vw",
  "& .MuiAppBar-root": {
    zIndex: "1400",
  },
};

const navBarSchoolLink = {
  color: "white",
};

const iconButtonLink = {
  pt: "6px",
  display: "inline-block",

  "+ .BaseBadge-badge": {
    mt: "12px",
    mr: "5px",
    borderRadius: "6px",
    minWidth: "10px",
    height: "10px",
  },
};

const notificationsIconButton = {
  pt: "6px",
  display: "inline-block",

  "+ .BaseBadge-badge": {
    mt: "12px",
    mr: "5px",
  },
};

export {
  appBarBox,
  menuDefaultColor,
  navBarAccountIcon,
  navBarAvatar,
  navBarIconButton,
  navBarMenu,
  navBarTypography,
  styledAppBar,
  navBarSchoolLink,
  iconButtonLink,
  notificationsIconButton,
};
