import { useEffect, useState } from "react";
import permissionsService from "../service/permissionsService";

export default function usePermissions(schoolId, teacher) {
  const [permissions, setPermissions] = useState([]);

  const fetchData = async () => {
    const result = await permissionsService.fetchCurrentUserPermission({
      school_id: schoolId,
    });

    setPermissions(result);
  };
  useEffect(() => {
    (async () => {
      if (schoolId && teacher) {
        await fetchData();
      }
    })();
  }, [schoolId, teacher]);

  return permissions;
}
