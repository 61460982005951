import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { Link as RouterLink } from "react-router-dom";
import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  Link,
  Checkbox,
  IconButton,
} from "@mui/material";
import { font14, tableAvatar, font20, font16 } from "./sharedStyles";
import {
  klassFormStudentsAvatar,
  klassFormStudentsCheckbox,
  klassFormStudentsTable,
  rowStyle,
} from "./Klass/Students.styles";

export default function StudentListForm({
  schoolId,
  students,
  activeKlass,
  remove,
  isSelected,
  handleCheckboxClick,
  removedStudents,
  assignmentStudents,
  isGoogleAssignment,
  isAssignment,
  isUnenrolledList,
  studentsToEnroll,
  enroll,
}) {
  function handleProfilePictureSrc(student) {
    if (student?.image_url) {
      return (
        <img
          alt="profile_image"
          src={student.image_url.url}
          style={{
            borderRadius: "100px",
            width: "40px",
            height: "40px",
            objectFit: "cover",
          }}
        />
      );
    }
    return (
      <Avatar sx={tableAvatar}>
        {student.first_name.charAt(0).toUpperCase()}
      </Avatar>
    );
  }

  const renderButton = (student) => {
    if (isUnenrolledList) {
      return (
        <TableCell align="center" padding="none">
          <IconButton
            size="large"
            onClick={() => enroll(student.id)}
            disabled={studentsToEnroll.includes(student.id)}
          >
            <AddIcon />
          </IconButton>
        </TableCell>
      );
    }
    return (
      <TableCell align="center" padding="none">
        <IconButton
          size="large"
          disabled={removedStudents.includes(student.id)}
          onClick={() => remove(student.id)}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    );
  };

  return (
    <TableContainer>
      <Table size="medium" sx={klassFormStudentsTable}>
        <TableHead>
          <TableRow>
            <TableCell padding="none">
              <Typography sx={font14}>NAME</Typography>
            </TableCell>
            <TableCell />
            <TableCell />
            <TableCell align="center" padding="none">
              <Typography sx={font14}>GRADE</Typography>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {students.map((student) => {
            const studentId = student.student_id
              ? student.student_id
              : student.id;
            const studentScore = assignmentStudents?.find(
              (as) => as.student_id === studentId
            )?.score;

            return (
              <TableRow hover tabIndex={-1} key={student.id}>
                {!activeKlass && (
                  <TableCell padding="none" sx={klassFormStudentsCheckbox}>
                    <Checkbox
                      onClick={(e) => handleCheckboxClick(e, studentId)}
                      color="primary"
                      checked={isSelected(studentId)}
                      disabled={!!studentScore || isGoogleAssignment}
                    />
                  </TableCell>
                )}

                {!isAssignment && (
                  <TableCell padding="none" sx={klassFormStudentsAvatar}>
                    <Link
                      to={`/school/${schoolId}/students/${student.slug}/account`}
                      underline="none"
                      component={RouterLink}
                      target="_blank"
                    >
                      {handleProfilePictureSrc(student)}
                    </Link>
                  </TableCell>
                )}

                <TableCell padding="none">
                  <Link
                    to={`/school/${schoolId}/students/${student.slug}/account`}
                    underline="none"
                    component={RouterLink}
                    target="_blank"
                  >
                    <Typography
                      sx={{
                        ...font20,
                        ...rowStyle(removedStudents?.includes(student.id)),
                      }}
                    >
                      {`${student.last_name}, ${student.first_name}`}
                    </Typography>
                  </Link>
                </TableCell>
                {activeKlass && <TableCell />}
                {isAssignment && <TableCell />}
                <TableCell align="center" padding="none">
                  <Typography
                    sx={{
                      ...font16,
                      ...rowStyle(removedStudents?.includes(student.id)),
                    }}
                  >
                    {student.grade_level}
                  </Typography>
                </TableCell>
                {activeKlass && renderButton(student)}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
