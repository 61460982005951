import React, { useState, useEffect } from "react";
import { Box, Stack, CircularProgress, Button } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import reportCardsService from "../../service/reportCardsService";
import { loader } from "../sharedStyles";
import {
  reportCardPreviewArrowContainer,
  reportCardPreviewPrevArrow,
  reportCardPreviewNxtArrow,
  reportCardPreviewClose,
  previewText,
  previewBox,
} from "./ReportCardPreview.styles";

export default function ReportCardPreview({
  studentIds,
  activeStudentPreview,
  handleClose,
  selectedStudentGradeFilter,
}) {
  const [searchParams] = useSearchParams();
  const [activeIndex, setActiveIndex] = useState(
    studentIds.indexOf(activeStudentPreview)
  );
  const [previewHtml, setPreviewHtml] = useState(null);
  const [loading, setLoading] = useState(false);

  const getPreview = async () => {
    setLoading(true);
    const response = await reportCardsService.fetchPreview({
      student_id: studentIds[activeIndex],
      term_id: searchParams.get("term"),
      grade_level: selectedStudentGradeFilter,
    });

    setPreviewHtml(response.html_content);
    setLoading(false);
  };

  // Function to navigate to the next ID
  const nextID = () => {
    if (activeIndex < studentIds.length - 1) {
      setActiveIndex(activeIndex + 1);
    }
  };

  // Function to navigate to the previous ID
  const prevID = () => {
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
    }
  };

  useEffect(() => {
    getPreview();
  }, [activeIndex]);

  if (loading) {
    return <CircularProgress sx={loader} size={100} />;
  }

  return (
    <Box sx={previewBox}>
      <Box sx={previewText}>PREVIEW</Box>
      <Button
        variant="outlined"
        sx={reportCardPreviewClose}
        onClick={() => handleClose()}
      >
        Close
      </Button>
      <Stack
        sx={reportCardPreviewArrowContainer}
        direction="row"
        justifyContent="center"
      >
        <Box sx={reportCardPreviewPrevArrow} onClick={() => prevID()}>
          <ArrowCircleLeftOutlinedIcon fontSize="large" />
        </Box>
        <Box sx={reportCardPreviewNxtArrow} onClick={() => nextID()}>
          <ArrowCircleRightOutlinedIcon fontSize="large" />
        </Box>
      </Stack>
      <Box dangerouslySetInnerHTML={{ __html: previewHtml }} />
    </Box>
  );
}
