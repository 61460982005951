import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ArrowCircleRight } from "@mui/icons-material";
import {
  Typography,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Box,
} from "@mui/material";
import {
  CssTextField,
  loginScreenArrowCircle,
  loginScreenContainer,
  loginScreenForm,
  loginScreenGrid,
  loginScreenIconButton,
  loginScreenLogo,
  loginScreenPasswordSpacing,
  forgotPasswordSuccessText,
} from "./LoginScreen.style";
import usersService from "../../service/usersService";

export default function ForgotPassword() {
  const { t, i18n } = useTranslation("common", { keyPrefix: "password" });
  const [emailSent, setEmailSent] = useState(false);
  const [searchParams] = useSearchParams();

  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    await usersService.sendResetPasswordInstructions({
      locale: i18n.language,
      ...data,
    });

    setEmailSent(true);
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={loginScreenGrid}
    >
      <Grid item xs={3}>
        <Container style={loginScreenContainer}>
          <img src="assets/logo-new.png" style={loginScreenLogo} alt="logo" />
        </Container>

        {!emailSent && !searchParams.get("isSent") ? (
          <Box>
            <Typography variant="title" align="center">
              <p>{t("enterEmailInstructions")}</p>
            </Typography>

            <form onSubmit={handleSubmit(onSubmit)} style={loginScreenForm}>
              <CssTextField
                required
                type="email"
                id="email"
                label={t("enterEmail")}
                size="small"
                variant="filled"
                color="text"
                margin="dense"
                fullWidth
                {...register("email", { required: true })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton type="submit" sx={loginScreenIconButton}>
                        <ArrowCircleRight
                          color="success"
                          sx={loginScreenArrowCircle}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </form>
          </Box>
        ) : (
          <Box sx={loginScreenPasswordSpacing}>
            <Typography sx={forgotPasswordSuccessText} align="center">
              {t("forgotPasswordSuccessText")}
            </Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}
