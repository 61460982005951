import { extendMoment } from "moment-range";

const momentBusinessDays = require("moment-business-days");

momentBusinessDays.updateLocale("us", {
  workingWeekdays: [1, 2, 3, 4, 5],
});

const moment = extendMoment(momentBusinessDays);

export default moment;
