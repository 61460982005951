import React from "react";
import { Route } from "react-router-dom";
import Messages from "../views/Messages";
import Inbox from "../components/Messages/Inbox";
import SentMessages from "../components/Messages/SentMessages";
import Conversation from "../components/Messages/Conversation/Conversation";

export default [
  <Route path="/school/:school_id/messages" element={<Messages />} key={0}>
    <Route
      path="/school/:school_id/messages/inbox"
      element={<Inbox />}
      key={1}
    />
    ,
    <Route
      path="/school/:school_id/messages/sent"
      element={<SentMessages />}
      key={2}
    />
    <Route
      path="/school/:school_id/messages/conversation/:conversation_key"
      element={<Conversation />}
      key={3}
    />
  </Route>,
];
