import axios from "../utils/axios-util";

const schoolYearEndpoint = "/api/school_years";

const fetchAll = async (params) => {
  const { data } = await axios.get(schoolYearEndpoint, params);

  if (!data.data) {
    console.error("Error fetching school years from API");
    return [];
  }
  return data;
};

const fetchById = async (id) => {
  const { data } = await axios.get(`${schoolYearEndpoint}/${id}`);

  if (!data.data) {
    console.error("Error fetching school year from API");
    return [];
  }
  return data;
};

const create = async (params) => {
  const { data } = await axios.post(schoolYearEndpoint, params);
  if (!data.data) {
    console.error("Error creating new school year");
    return [];
  }
  return data;
};

const update = async (schoolYear) => {
  const { data } = await axios.put(
    `${schoolYearEndpoint}/${schoolYear.id}`,
    schoolYear
  );
  if (!data.data) {
    console.error("Error updating school year");
    return [];
  }
  return data;
};

const updateMessage = async (params) => {
  const { data } = await axios.put(
    `${schoolYearEndpoint}/${params.params.school_year_id}/update_message`,
    params.params
  );
  if (!data.data) {
    console.error("Error updating welcome message");
    return [];
  }
  return data;
};

const updateAdmissionsActiveStatus = async (params) => {
  const { data } = await axios.put(
    `${schoolYearEndpoint}/update_admissions_active_status`,
    params
  );
  if (!data.data) {
    console.error("Error updating welcome message");
    return [];
  }
  return data;
};

const activeSchoolYear = () =>
  axios.get(`${schoolYearEndpoint}/active_school_year`);

export default {
  fetchAll,
  fetchById,
  create,
  update,
  activeSchoolYear,
  updateMessage,
  updateAdmissionsActiveStatus,
};
