import { styled } from "@mui/material/styles";
import { Tabs } from "@mui/material";

const CssTabs = styled(Tabs)({
  "& .MuiTab-root": {
    padding: 0,
    minWidth: 0,
    marginRight: 24,
    textTransform: "none",
    fontWeight: 400,
    fontSize: "18px",
    color: "#000000",
  },
});

const studentInfoContainer = {
  width: "100%",
  px: "70px !important",
  backgroundColor: "#EBEBEB",
  borderBottom: 1,
  borderColor: "primary.main",
};

const studentInfoContainer2 = {
  width: "100%",
  px: "130px !important",
  py: "30px !important",
};

export { CssTabs, studentInfoContainer, studentInfoContainer2 };
