import React from "react";
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableBody,
} from "@mui/material";
import { assignmentFormTitle } from "./AssignmentForm.styles";
import {
  assignmentsFormKlassesTable,
  assignmentsTableBox,
} from "./Klasses.styles";
import KlassRow from "./KlassRow";
import { renderError } from "../../utils/constants/forms";

export default function Klasses({
  klasses,
  selectedKlasses,
  setSelectedKlasses,
  activeAssignment,
  isGoogleAssignment,
  assignmentStudents,
  isSubmitted,
}) {
  const activeKlasses = activeAssignment
    ? klasses.filter((klass) => klass.id === activeAssignment.klass_id)
    : klasses;

  return (
    <Box sx={assignmentsTableBox}>
      <Typography sx={assignmentFormTitle}>
        {activeAssignment ? "" : "Choose class or classes"}
      </Typography>
      {isSubmitted &&
        !activeAssignment &&
        selectedKlasses.length === 0 &&
        renderError("Select at least one class")}
      <TableContainer>
        <Table size="medium" sx={assignmentsFormKlassesTable}>
          <TableBody>
            {activeKlasses.map((klass) => (
              <KlassRow
                key={klass.id}
                setSelectedKlasses={setSelectedKlasses}
                selectedKlasses={selectedKlasses}
                klass={klass}
                activeAssignment={activeAssignment}
                isGoogleAssignment={isGoogleAssignment}
                assignmentStudents={assignmentStudents}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
