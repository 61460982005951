import React, { useEffect, useState, useRef, useContext } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { medicalRecordActions } from "../../Medical/MedicalRecord/AddMedicalRecord.style";
import { loader, formHeader, formButtons } from "../../../sharedStyles";
import GeneralInfo from "../../Medical/MedicalRecord/GeneralInfo";
import Documents from "../../Medical/MedicalRecord/Documents";
import studentsService from "../../../../service/studentsService";
import { SnackbarContext } from "../../../../context/SnackbarContext";
import AlertDialog from "../../../AlertDialog";
import { StudentActionsContext } from "../../../../context/StudentActionsContext";
import { PermissionsContext } from "../../../../context/PermissionsContext";
import APP_PERMISSIONS from "../../../../utils/constants/permissions";
import PERMISSION_TYPES from "../../../../utils/constants/permission_types";
import GenericSubmitButton from "../../../GenericSubmitButton";

function AddMedicalRecord({
  setMedicalRecordDrawerOpen,
  loadMedicalRecords,
  medicalRecordId,
}) {
  const params = useParams();
  const [medicalRecord, setMedicalRecord] = useState();
  const methods = useForm({ mode: "onSubmit" });
  const snackbarContext = useRef(useContext(SnackbarContext));
  const [isDialogOpen, setDialogState] = React.useState(false);
  const studentActionsContext = useContext(StudentActionsContext);

  const cleanForm = () => {
    setMedicalRecordDrawerOpen(false);
    if (loadMedicalRecords) {
      loadMedicalRecords();
    }
  };

  const handleCancel = () => {
    cleanForm();
  };
  const handleClose = () => {
    setDialogState(false);
  };

  const deleteMedicalRecordAlert = () => {
    setDialogState(true);
  };

  const loadMedicalRecord = () => {
    studentsService
      .getMedicalRecord(params.student_slug, medicalRecordId)
      .then((response) => {
        setMedicalRecord(response.data.data);
      });
  };

  useEffect(() => {
    if (medicalRecordId) loadMedicalRecord();
  }, []);

  const addMedicalRecord = (data) => {
    studentsService
      .addMedicalRecords(params.student_slug, data)
      .then(() => {
        snackbarContext.current.setSnackbar({
          message: "Medical record saved.",
          severity: "success",
          open: true,
        });
        studentActionsContext.fireMedicalRecordsUpdated(true);
        cleanForm();
      })
      .catch(() => {
        snackbarContext.current.setSnackbar({
          message: "Error saving medical record",
          severity: "error",
          open: true,
        });
      });
  };
  const updateMedicalRecord = (data) => {
    studentsService
      .updateMedicalRecord(params.student_slug, medicalRecordId, data)
      .then(() => {
        snackbarContext.current.setSnackbar({
          message: "Medical record saved.",
          severity: "success",
          open: true,
        });
        cleanForm();
        studentActionsContext.fireMedicalRecordsUpdated(true);
      })
      .catch(() => {
        snackbarContext.current.setSnackbar({
          message: "Error saving medical record",
          severity: "error",
          open: true,
        });
      });
  };

  const deleteMedicalRecord = () => {
    studentsService
      .deleteMedicalRecord(params.student_slug, medicalRecordId)
      .then(() => {
        cleanForm();
        studentActionsContext.fireMedicalRecordsUpdated(true);
      });
  };
  const onSubmit = async (data) => {
    if (medicalRecordId) updateMedicalRecord(data);
    else addMedicalRecord(data);
  };

  const { hasPermission } = useContext(PermissionsContext);

  const managePermission = hasPermission(
    APP_PERMISSIONS.STUDENT_MEDICAL,
    PERMISSION_TYPES.MANAGE
  );

  return (
    <Grid container>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          style={{ width: "100%" }}
        >
          {!medicalRecordId || medicalRecord ? (
            <>
              <Grid
                container
                item
                sx={formHeader}
                direction="row"
                columnSpacing={4}
                rowSpacing={0}
              >
                <Grid item sm={7}>
                  <Grid item>
                    <Typography variant="h3">
                      {medicalRecordId
                        ? "Edit Medical Record"
                        : "Add Medical Record"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container sx={medicalRecordActions} sm={5}>
                  <Button
                    variant="outlined"
                    sx={formButtons}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                  {Boolean(medicalRecordId) && managePermission && (
                    <GenericSubmitButton
                      text="Delete"
                      submittingText="Deleting..."
                      type="button"
                      onClick={() => deleteMedicalRecordAlert()}
                    />
                  )}
                  {managePermission && (
                    <GenericSubmitButton
                      text="Save"
                      submittingText="Saving..."
                    />
                  )}
                </Grid>
              </Grid>
              <Grid container p={5}>
                <GeneralInfo medicalRecord={medicalRecord} />
                <Documents
                  document={medicalRecord?.document_file}
                  studentId={medicalRecord?.student_id}
                />
              </Grid>
            </>
          ) : (
            <CircularProgress sx={loader} size={100} />
          )}
        </form>
      </FormProvider>
      <AlertDialog
        isOpen={isDialogOpen}
        handleClose={handleClose}
        handleConfirm={deleteMedicalRecord}
      />
    </Grid>
  );
}

export default AddMedicalRecord;
