import React, { useContext, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { CircularProgress, Divider, Grid } from "@mui/material";
import { useOutletContext, useParams } from "react-router-dom";
import EmergencyContacts from "./EmergencyContacts/EmergencyContacts";
import {
  accountHorizontalContainer,
  accountSecondarySections,
} from "./StaffInfoAccount.style";
import GeneralInfoAccount from "./GeneralInfoAccount";
import { loader } from "../../../sharedStyles";
import staffsService from "../../../../service/staffsService";
import AutoSave from "../../../AutoSave";
import Notes from "./Notes";
import Permissions from "./Permissions";
import { PermissionsContext } from "../../../../context/PermissionsContext";
import APP_PERMISSIONS from "../../../../utils/constants/permissions";
import PERMISSION_TYPES from "../../../../utils/constants/permission_types";
import Authorize from "../../../Authorize";
import CustomFieldRenderer from "../../../School/CustomFields/CustomFieldRenderer/CustomFieldRenderer";

function StaffInfoAccount() {
  const { hasPermission, hasAnyPermissionType } =
    useContext(PermissionsContext);
  const managePermission = hasPermission(
    APP_PERMISSIONS.STAFF_ACCOUNT,
    PERMISSION_TYPES.MANAGE
  );
  const methods = useForm({ mode: "onChange" });
  const [selectedTeacher, , teacher] = useOutletContext();
  const params = useParams();

  useEffect(() => {
    if (managePermission) methods.trigger();
  }, [selectedTeacher]);

  return (
    <Authorize permission={hasAnyPermissionType(APP_PERMISSIONS.STAFF_ACCOUNT)}>
      <FormProvider {...methods}>
        {selectedTeacher ? (
          <fieldset disabled={!managePermission} style={{ border: "none" }}>
            <Grid
              container
              justifyContent="space-between"
              sx={accountHorizontalContainer}
            >
              <Grid container item marginY={5} xs={12} sm={12} md={12}>
                <GeneralInfoAccount
                  teacher={selectedTeacher}
                  managePermission={managePermission}
                />
              </Grid>
              <Divider sx={{ width: "100%", borderColor: "black" }} />
              <Grid container spacing={2} mx={1} mt={2}>
                <Grid item container spacing={2} xs={12} md={12} pr={2}>
                  <Grid container item>
                    <Grid item xs={12} sm={12} md={12} mb={2} px={3}>
                      <CustomFieldRenderer
                        sectionType="staff_account"
                        customFieldResponses={
                          selectedTeacher.custom_field_responses || []
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                sx={accountSecondarySections}
                managePermission={managePermission}
              >
                <EmergencyContacts
                  teacher={selectedTeacher}
                  managePermission={managePermission}
                />
              </Grid>
              <Grid item sm={12} sx={accountSecondarySections}>
                <Permissions
                  selectedTeacher={selectedTeacher}
                  teacher={teacher}
                  managePermission={managePermission}
                />
              </Grid>
              <Grid item sm={12} sx={accountSecondarySections}>
                <Notes teacher={selectedTeacher} disabled={!managePermission} />
              </Grid>
              {methods.formState.isDirty && managePermission && (
                <AutoSave
                  saveMethod={staffsService.update}
                  params={[params.id]}
                  disabled={!managePermission}
                  successMessage="Account saved"
                  progressMessage="Saving account..."
                />
              )}
            </Grid>
          </fieldset>
        ) : (
          <CircularProgress sx={loader} size={100} />
        )}
      </FormProvider>
    </Authorize>
  );
}

export default StaffInfoAccount;
