const gridContainer = {
  marginTop: "20px",
  marginBottom: "20px",
  marginRight: "80px",
  marginLeft: "80px",
  borderBottom: "1px solid",
  width: "auto",
};

const gridItemMessages = {
  fontSize: "34px",
  color: "#000000",
};

const gridItemNewMessage = {
  paddingRight: "16px",
};

const gridItemNewMessageButton = {
  float: "right",
  backgroundColor: "#2196F3",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#2196F3",
  },
};

const gridItemSearchBox = {
  float: "right",
  marginTop: "-10px",
};

const formTextField = {
  "& label": {
    fontSize: "18px",
    color: "#000000",
  },
};

const iconButtonSearch = {
  width: "1.3em",
  height: "1.3em",
};

export {
  gridContainer,
  gridItemMessages,
  gridItemNewMessage,
  gridItemNewMessageButton,
  gridItemSearchBox,
  formTextField,
  iconButtonSearch,
};
