const guardianKidsGrid = {
  width: "100%",
  backgroundColor: "white",
  paddingLeft: "90px",
};

const guardianKidLink = {
  fontSize: "28px",
  lineHeight: "36px",
  letterSpacing: "-0.05em",
  color: "#000000",
};

const guardianKidEnrollmentLink = {
  fontSize: "18px",
  lineHeight: "36px",
  letterSpacing: "-0.05em",
  color: "#2196F3",
};

const guardianInactiveKidLink = {
  ...guardianKidLink,
  color: "gray",
};

const guardianKidActiveBox = {
  alignItems: "baseline",
  borderBottom: "7px solid #000000",
  display: "flex",
};

const guardianKidBox = {
  alignItems: "baseline",
  display: "flex",
};

export {
  guardianKidsGrid,
  guardianKidLink,
  guardianKidEnrollmentLink,
  guardianKidActiveBox,
  guardianKidBox,
  guardianInactiveKidLink,
};
