import Card from "@mui/material/Card";
import * as React from "react";
import theme from "../utils";

function calculateWidth(fullWidth, compact) {
  if (fullWidth) return "100%";
  return compact ? "200px" : "215px";
}

export default function SimpleCard({
  title,
  isDark = false,
  isSelected = false,
  marginless = false,
  compact = false,
  fullWidth = false,
  content,
  icon,
  horizontal = false,
}) {
  const darkColor = isDark ? "#ffffff" : "#000000";
  const darkBg = isDark ? theme.palette.primary.main : "#ffffff";

  const getBorder = () => {
    if (isSelected) return "1px solid #C4C4C4";

    return `1px solid ${isDark ? "#8F8F8F" : theme.palette.primary.disabled}`;
  };

  const cardStyle = {
    border: getBorder(),
    width: calculateWidth(fullWidth, compact),
    minHeight: compact ? "120px" : "136px",
    height: "100%",
    borderRadius: "6px",
    display: "inline-flex",
    flexDirection: "column",
    justifyContent: content ? "space-around" : "end",
    alignItems: "center",
    backgroundColor: darkBg,
    lineHeight: "31px",
    letterSpacing: "-0.04em",
    margin: marginless ? 0 : "10px !important",
    color: darkColor,
    padding: "0 30px",
    textAlign: "center",
    boxShadow: isSelected
      ? "0px 1px 8px rgb(0 0 0 / 12%), 0px 3px 4px rgb(0 0 0 / 14%), 0px 3px 3px -2px rgb(0 0 0 / 20%)"
      : "",
  };

  const horizontalCardStyles = {
    border: `1px solid ${isDark ? "#8F8F8F" : theme.palette.primary.disabled}`,
    height: "100%",
    minHeight: compact ? "120px" : "136px",
    display: "flex",
    justifyContent: "space-between",
    lineHeight: "31px",
    letterSpacing: "-0.04em",
    margin: marginless ? 0 : "10px !important",
    padding: "0 30px",
    alignItems: "center",
    color: darkColor,
    backgroundColor: darkBg,
  };

  if (horizontal) {
    return (
      <Card variant="outlined" sx={horizontalCardStyles} mt={5}>
        <div style={{ marginRight: 30 }}>{title}</div>

        <div>{content}</div>

        {icon && <div style={{ marginTop: content ? 0 : 10 }}>{icon}</div>}
      </Card>
    );
  }

  return (
    <Card variant="outlined" sx={cardStyle} mt={5}>
      <div style={{ marginTop: 10 }}>{title}</div>

      <div>{content}</div>

      <div style={{ marginTop: content ? 0 : 10 }}>{icon}</div>
    </Card>
  );
}
