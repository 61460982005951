import CustomFieldBase from "./CustomFieldBase";

export default class CustomDateField extends CustomFieldBase {
  constructor(field) {
    super(field);
    this.dataType = "value_date";
    this.type = "date-field";
    this.prompt = "TEXT";
    this.propertyComponents = ["dateFormats", "isVisible", "isRequired"];
  }
}
