import {
  Box,
  Button,
  ButtonBase,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { Add, Delete } from "@mui/icons-material";
import { useFormContext } from "react-hook-form";
import { generateGUID } from "../../../../utils/constants/utils";

export default function Options({ fieldProp }) {
  const { setValue } = useFormContext();
  const [options, setOptions] = React.useState(
    fieldProp.properties.options || []
  );

  const addOptions = () => {
    const updatedOptions = [...options];
    updatedOptions.push({ id: generateGUID(), value: "" });
    setOptions(updatedOptions);
  };

  const deleteOption = (index) => {
    const updatedOptions = [...options];
    updatedOptions.splice(index, 1);
    setOptions(updatedOptions);
    setValue("properties.options", updatedOptions, { shouldDirty: true });
  };

  return (
    <Box>
      <Typography ml={1.5}>Options</Typography>
      <Box mb={2}>
        {options.map((option, index) => (
          <Grid container mt={3} key={option.id}>
            <Grid
              item
              md={1.5}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <MenuIcon />
            </Grid>
            <Grid item md={9}>
              <TextField
                label="Option name"
                fullWidth
                size="small"
                defaultValue={option.value}
                onInput={(e) => {
                  const updatedOptions = [...options];
                  updatedOptions[index].value = e.target.value;
                  setValue("properties.options", updatedOptions, {
                    shouldDirty: true,
                  });
                }}
              />
            </Grid>
            <Grid
              item
              md={1.5}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <ButtonBase onClick={() => deleteOption(index)}>
                <Delete />
              </ButtonBase>
            </Grid>
          </Grid>
        ))}
        <Box display="flex" justifyContent="center" mt={2}>
          <Button
            sx={{
              textTransform: "none",
              color: "#2196F3",
            }}
            startIcon={<Add />}
            onClick={addOptions}
          >
            Add Option
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
