import React, { useState } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@mui/material";
import { useFormContext } from "react-hook-form";

export default function CheckboxListRenderer({
  field,
  properties,
  defaultValue = "",
}) {
  const [selectedOptions, setSelectedOptions] = useState(
    defaultValue?.split(",") || []
  );
  const { setValue } = useFormContext();
  return (
    <Box padding={1}>
      <FormControl component="fieldset" variant="standard">
        <FormLabel component="legend">{field.label}</FormLabel>
        <FormGroup>
          {properties.options.map((option) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedOptions.includes(option.value)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      const updateSelectedOptions = [...selectedOptions];
                      updateSelectedOptions.push(option.value);
                      setSelectedOptions(updateSelectedOptions);
                      setValue(
                        `custom_field_responses.${field.field_id}`,
                        updateSelectedOptions.join(",")
                      );
                    } else {
                      const optionIndex = selectedOptions.findIndex(
                        (o) => o === option.value
                      );
                      if (optionIndex !== -1) {
                        const updateSelectedOptions = [...selectedOptions];
                        updateSelectedOptions.splice(optionIndex, 1);
                        setSelectedOptions(updateSelectedOptions);
                        setValue(
                          `custom_field_responses.${field.field_id}`,
                          updateSelectedOptions.join(",")
                        );
                      }
                    }
                  }}
                />
              }
              label={option.value}
            />
          ))}
        </FormGroup>
      </FormControl>
    </Box>
  );
}
