const messagesTitle = {
  color: "black",
  fontSize: "52px",
};

const messagesTopContainer = {
  borderBottom: "1px solid black",
};

const messagesSearchContainer = {
  display: "flex",
  alignItems: "end",
};

const messagesRow = {
  borderBottom: "1px solid #BFC7CA",
  p: "15px",
  color: "black",
  "&:hover": {
    backgroundColor: "#0000000",
    cursor: "pointer",
  },
};

const messagesRowTimestamp = {
  fontSize: "11px",
  color: "#336898",
};

const messagesRowDescription = {
  my: "auto",
  ml: "10px",
  fontSize: "14px",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
  width: "1020px",
  display: "block",
  "& p": {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
};

const messagesAttachIcon = {
  transform: "rotate(45deg)",
};

const messagesInfoContainer = {
  width: "300px",
  "& p": {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
};

const messagesUnreadIcon = {
  fontSize: "11px",
  mr: "5px",
  color: "#5077A4",
};

export {
  messagesTitle,
  messagesTopContainer,
  messagesSearchContainer,
  messagesRow,
  messagesRowTimestamp,
  messagesAttachIcon,
  messagesInfoContainer,
  messagesRowDescription,
  messagesUnreadIcon,
};
