import React from "react";
import { Typography } from "@mui/material";

export default function DescriptionAreaRenderer(props) {
  const { properties } = props;
  return (
    <Typography
      fontSize={14}
      mt={1}
      dangerouslySetInnerHTML={{ __html: properties.descriptionText }}
    />
  );
}
