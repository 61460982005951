import { font16 } from "../sharedStyles";

const assignmentKlassRow = (borders) => ({
  "& th": {
    color: "black",
  },
  ...(borders && {
    "> td": {
      borderBottom: "none",
    },
  }),
});

const assignmentEditKlassRow = (borders) => ({
  ...assignmentKlassRow(borders),
  mt: "15px",
});

const assignmentCategoryRow = (hasErrors) => ({
  width: "260px",
  ...(hasErrors && {
    "> td": {
      borderBottom: "none",
    },
  }),
});

const assignmentInputRow = {
  width: "286px",
};

const assignmentMaxFormControl = { width: "110px" };

const assignmentInputFormControl = {
  width: "143px",
  mx: "10px",
};

const assignmentInputErrorColumn = {
  width: "153px",
  display: "inline-block",
  ml: "15px",
};

const assignmentMaxErrorColumn = { width: "110px", display: "inline-block" };
const assignmentCategoryErrorColumn = {
  width: "110px",
  display: "inline-block",
};

const assignmentDisabledText = {
  ...font16,
  color: "gray",
};

export {
  assignmentKlassRow,
  assignmentCategoryRow,
  assignmentEditKlassRow,
  assignmentInputRow,
  assignmentMaxFormControl,
  assignmentInputFormControl,
  assignmentInputErrorColumn,
  assignmentMaxErrorColumn,
  assignmentDisabledText,
  assignmentCategoryErrorColumn,
};
