import React from "react";
import { Stack } from "@mui/material";
import { filterContainer } from "./Filters.styles";
import Search from "./Search";
import Columns from "./Columns";
import ColumnFilters from "./ColumnFilters";

export default function Filter({
  tableColumns,
  setTableColumns,
  searchParams,
  setSearchParams,
}) {
  return (
    <Stack sx={filterContainer} direction="row" justifyContent="space-between">
      <Stack direction="row">
        <Columns
          tableColumns={tableColumns}
          setTableColumns={setTableColumns}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
        <ColumnFilters tableColumns={tableColumns} />
      </Stack>
      <Stack direction="row" spacing={1}>
        <Search />
      </Stack>
    </Stack>
  );
}
