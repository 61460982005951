import { tableCellClasses, tableRowClasses } from "@mui/material";

const title = {
  fontSize: "28px",
  lineHeight: "36px",
  letterSpacing: "-0.04em",
  color: "#000000",
};

const boxContainer = {
  margin: "80px",
};

const divider = {
  border: "0.5px solid #000000",
  marginTop: "50px",
  marginBottom: "50px",
};

const tableStyles = {
  [`& .${tableCellClasses.root}`]: {
    borderBottom: "none",
  },
  [`& .${tableRowClasses.root}:not(:last-child)`]: {
    borderBottom: "1px solid #d9d9d9",
  },
  "& .MuiTableCell-head": {
    color: "#8f8e8e",
    textTransform: "uppercase",
  },
  "& .MuiTableCell-body": {
    color: "#000000",
    fontSize: 20,
  },
};

const gridItemBorder = {
  borderRight: "1px solid #BFC7CA",
};

const disabledTextField = {
  "& .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled": {
    WebkitTextFillColor: "#000000",
  },
};

const datePicker = {
  "& .MuiFormControl-root": {
    width: "100%",
  },
};

export {
  title,
  boxContainer,
  divider,
  tableStyles,
  gridItemBorder,
  disabledTextField,
  datePicker,
};
