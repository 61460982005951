import React, { useContext } from "react";
import { Button, ButtonBase, Grid, Typography } from "@mui/material";
import { useOutletContext } from "react-router-dom";
import { Clear, FilePresentOutlined } from "@mui/icons-material";
import QontoStepIcon from "../../QontoStepIcon";
import { submitButton } from "../../Admissions/Setup/RegistrationForm.style";
import admissionProcessService from "../../../service/admissionProcessService";
import { SnackbarContext } from "../../../context/SnackbarContext";
import { formatDateTime } from "../../../utils/constants/utils";

export default function Documents() {
  const snackbarContext = useContext(SnackbarContext);
  const { stageInstances } = useOutletContext();

  const isStageInstanceCompleted = (stageInstace) => {
    let isCompleted = true;
    stageInstace.element_instances.forEach((elementInstance) => {
      if (elementInstance.status !== "completed") isCompleted = false;
    });
    return isCompleted;
  };

  const uploadFile = (
    stageInstanceIndex,
    elementInstanceIndex,
    fileId,
    file
  ) => {
    snackbarContext.setSnackbar({
      message: "Uploading file...",
      severity: "info",
      open: true,
    });
    const formData = new FormData();
    formData.append("attachment", file);
    formData.append(
      "element_instance_id",
      stageInstances[stageInstanceIndex].element_instances[elementInstanceIndex]
        .id
    );
    formData.append("file_id", fileId);

    admissionProcessService
      .uploadElementInstanceFile(formData)
      .then((response) => {
        stageInstances[stageInstanceIndex].element_instances[
          elementInstanceIndex
        ] = response.data;
        snackbarContext.setSnackbar({
          message: "File uploaded.",
          severity: "success",
          open: true,
        });
      });
  };

  const getDocuments = (documents, id) => documents.filter((d) => d.id === id);

  const deleteDocument = (
    documentId,
    elementInstanceId,
    stageInstanceIndex,
    elementInstanceIndex
  ) => {
    snackbarContext.setSnackbar({
      message: "Deleting file...",
      severity: "info",
      open: true,
    });
    admissionProcessService
      .deleteElementInstanceFile({
        file_id: documentId,
        element_instance_id: elementInstanceId,
      })
      .then((response) => {
        stageInstances[stageInstanceIndex].element_instances[
          elementInstanceIndex
        ] = response.data;
        snackbarContext.setSnackbar({
          message: "File deleted.",
          severity: "success",
          open: true,
        });
      })
      .catch(() => {
        snackbarContext.setSnackbar({
          message: "unable to delete file.",
          severity: "error",
          open: true,
        });
      });
  };

  return stageInstances.map((stageInstance, stageInstanceIndex) => (
    <Grid container item md={12} key={stageInstance.id}>
      <Grid item md={12} container mt={5}>
        <Grid item md={0.5} container alignItems="center">
          <QontoStepIcon checked={isStageInstanceCompleted(stageInstance)} />
        </Grid>
        <Grid item md={4.5} container alignItems="center">
          <Typography color="black" fontSize={20} fontWeight={500}>
            {stageInstance.admission_process_stage.name}
          </Typography>
        </Grid>
      </Grid>
      {stageInstance.element_instances.map(
        (elementInstance, elementInstanceIndex) => (
          <Grid item md={12} container mt={2.5}>
            <Grid item md={0.5} container alignItems="center">
              {/* <QontoStepIcon /> */}
            </Grid>
            <Grid item md={4.5} container alignItems="center">
              <QontoStepIcon checked={elementInstance.status === "completed"} />
              <Typography color="black" ml={1}>
                {elementInstance.stage_element.name}
              </Typography>
            </Grid>
            <Grid item md={2.33} container alignItems="center">
              <Typography color="black" fontWeight={500}>
                DUE
              </Typography>
            </Grid>
            <Grid item md={2.33} container alignItems="center">
              <Typography color="black" fontWeight={500}>
                COMPLETED
              </Typography>
            </Grid>
            <Grid item md={2.33} />

            {elementInstance.properties.files.map((file) => (
              <Grid container item md={12} mt={2.5}>
                <Grid item md={1} container alignItems="center">
                  {/* <QontoStepIcon /> */}
                </Grid>
                <Grid item md={4} container alignItems="center">
                  <QontoStepIcon
                    checked={
                      getDocuments(
                        elementInstance.documents_details,
                        file.file_id
                      ).length
                    }
                  />
                  <Typography color="black" ml={1}>
                    {file.name}
                  </Typography>
                </Grid>
                <Grid item md={2.33} container alignItems="center">
                  <Typography color="black">
                    {stageInstance.admission_process_stage.deadline
                      ? formatDateTime(
                          stageInstance.admission_process_stage.deadline
                        )
                      : "-"}
                  </Typography>
                </Grid>
                <Grid item md={2.33} container alignItems="center">
                  <Typography color="black">
                    {getDocuments(
                      elementInstance.documents_details,
                      file.file_id
                    ).length
                      ? formatDateTime(
                          getDocuments(
                            elementInstance.documents_details,
                            file.file_id
                          )[0].created_at
                        )
                      : "-"}
                  </Typography>
                </Grid>
                <Grid item md={2.33} container alignItems="center">
                  {!getDocuments(
                    elementInstance.documents_details,
                    file.file_id
                  ).length && (
                    <Button
                      component="label"
                      sx={{
                        ...submitButton,
                        width: "100%",
                        maxWidth: 100,
                      }}
                    >
                      <Typography>Upload</Typography>
                      <input
                        hidden
                        accept={`.${file.type.toLowerCase()}`}
                        type="file"
                        onChange={(e) =>
                          uploadFile(
                            stageInstanceIndex,
                            elementInstanceIndex,
                            file.id,
                            e.target.files[0]
                          )
                        }
                      />
                    </Button>
                  )}
                </Grid>

                {getDocuments(
                  elementInstance.documents_details,
                  file.file_id
                ).map((document) => (
                  <Grid container item md={12} my={2}>
                    <Grid item md={1.5} />
                    <Grid
                      item
                      md={6.5}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        position: "relative",
                        borderBottom: "1px solid #B6B6B6",
                        pb: 1,
                      }}
                    >
                      <FilePresentOutlined htmlColor="rgba(0, 0, 0, 0.54)" />{" "}
                      <Typography fontSize={14} ml={1}>
                        {document.filename}
                      </Typography>
                      <ButtonBase
                        sx={{ position: "absolute", right: 0 }}
                        onClick={() =>
                          deleteDocument(
                            document.id,
                            elementInstance.id,
                            stageInstanceIndex,
                            elementInstanceIndex
                          )
                        }
                      >
                        <Clear htmlColor="rgba(0, 0, 0, 0.54)" />
                      </ButtonBase>
                    </Grid>
                  </Grid>
                ))}

                <Grid container item md={12}>
                  <Grid item md={1.5} />
                  <Grid item md={7}>
                    <Typography fontSize={14}>{file.description}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        )
      )}
    </Grid>
  ));
}
