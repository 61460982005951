import axios from "../utils/axios-util";

const klassCategoriesEndpoint = "/api/klass_categories";

const fetchKlassCategories = async (params) => {
  const { data } = await axios.get(klassCategoriesEndpoint, params);

  if (!data) {
    console.error("Error fetching klass categories from the API");
    return [];
  }
  return data;
};

export default {
  fetchKlassCategories,
};
