import formattedGrade from "./formattedGrade";

const termGradeLetter = (termGrade, specialMarks) => {
  const grade = termGrade.calculated_grade;

  if (grade === null) {
    return "-";
  }

  if (termGrade?.klass?.is_pass_fail) {
    return grade >
      Number(
        termGrade?.klass?.grading_scale?.marking_codes.find(
          (markingCode) => markingCode.code === "P"
        )?.value
      )
      ? "P"
      : "F";
  }

  return formattedGrade(
    grade,
    100,
    termGrade?.klass?.grading_scale?.marking_codes,
    specialMarks
  );
};

export default termGradeLetter;
