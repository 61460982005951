import {
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import { ProcessBuilderContext } from "../ProcessBuilderContext";

export default function ProcessFormProperties() {
  const { process, updateProcess, selectedElement } = useContext(
    ProcessBuilderContext
  );
  const methods = useForm();
  const { handleSubmit, control, setValue, reset } = methods;
  const formWatcher = useWatch({
    control,
  });
  const [require, setRequire] = useState(selectedElement.properties.require);
  const [includeInTimeline, setIncludeInTimeline] = useState(false);
  // const [notifyPersonsResponsible, setNotifyPersonsResponsible] =
  //   useState(false);
  // const [selectedOptions, setSelectedOption] = useState(
  //   selectedElement.properties.personsResponsible || []
  // );
  // const [personsResponsible, setPersonsResponsible] = useState([]);

  // const getNameOfSelectedPerson = (value) =>
  //   personsResponsible.find((item) => item.id === value)
  //     ? `${personsResponsible.find((item) => item.id === value).first_name} ${
  //         personsResponsible.find((item) => item.id === value).last_name
  //       }`
  //     : "";

  const onSubmit = (data) => {
    const updatedStages = [...process.stages];
    let stageIndex = null;

    // find the stage index the element was dropped to
    updatedStages.some((stage, index) => {
      const foundElementIndex = stage.elements.findIndex(
        (element) => element.elementId === selectedElement.elementId
      );
      if (foundElementIndex !== -1) {
        stageIndex = index;
        return true; // exit the loop once the element is found
      }
      return false;
    });

    const updateElements = [...updatedStages[stageIndex].elements];
    const elementIndex = updateElements.findIndex(
      (el) => el.elementId === selectedElement.elementId
    );

    updateElements[elementIndex].properties = {
      ...updateElements[elementIndex].properties,
      ...data,
    };
    updatedStages[stageIndex] = {
      ...updatedStages[stageIndex],
      elements: updateElements,
    };

    updateProcess({ ...process, stages: updatedStages });
  };

  // useEffect(() => {
  //   staffsService
  //     .fetchAllStaffs({ staff_type: [StaffType.TEACHER, StaffType.CONFERENCE] })
  //     .then((response) => {
  //       setPersonsResponsible(response);
  //     });
  // }, []);

  useEffect(() => {
    reset(
      {
        personsResponsible: selectedElement.properties.personsResponsible,
        description: selectedElement.properties.description,
        require: selectedElement.properties.require,
        includeInTimeline: selectedElement.properties.includeInTimeline,
        notifyPersonsResponsible:
          selectedElement.properties.notifyPersonsResponsible,
      },
      { keepTouched: true, keepDirty: true }
    );
    // setSelectedOption(selectedElement.properties.personsResponsible);
    setRequire(selectedElement.properties.require);
    setIncludeInTimeline(selectedElement.properties.includeInTimeline);
    // setNotifyPersonsResponsible(
    //   selectedElement.properties.notifyPersonsResponsible
    // );
  }, [selectedElement]);

  useEffect(() => {
    if (Object.keys(formWatcher).length) onSubmit(formWatcher);
  }, [formWatcher]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          rowGap={4}
          boxShadow="0px 4px 50px 0px rgba(29, 182, 231, 0.12)"
          borderRadius={1}
          padding={2.5}
        >
          <Grid item md={12}>
            <Typography align="center" variant="body1">
              Form Options
            </Typography>
          </Grid>
          {/* <Grid item md={12}>
            <FormControlLabel
              sx={{ width: "100%" }}
              control={
                <Checkbox
                  checked={require}
                  onChange={(e) => {
                    setValue("require", e.target.checked, {
                      shouldDirty: true,
                    });
                    setRequire(e.target.checked);
                  }}
                />
              }
              label="Require Validation"
            />
          </Grid>
          <Grid item md={12}>
            <FormControl fullWidth>
              <InputLabel id="personsResponsible">
                Persons Responsible
              </InputLabel>
              <Select
                labelId="personsResponsible"
                label="Persons Responsible"
                value={selectedOptions}
                disabled={!require}
                placeholder="Select"
                multiple
                {...register(`personsResponsible`, {
                  onChange: (event) => {
                    setSelectedOption(event.target.value);
                    setValue(`personsResponsible`, event.target.value, {
                      shouldDirty: true,
                    });
                  },
                })}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value, index) => (
                      <Chip
                        key={value}
                        sx={chipStyles}
                        label={getNameOfSelectedPerson(value)}
                        deleteIcon={
                          <CancelOutlined
                            sx={{ color: "black !important" }}
                            onMouseDown={(event) => event.stopPropagation()}
                          />
                        }
                        onDelete={() => {
                          const newValues = [...selectedOptions];
                          newValues.splice(index, 1);
                          setSelectedOption(newValues);
                          setValue("personsResponsible", newValues, {
                            shouldDirty: true,
                          });
                        }}
                      />
                    ))}
                  </Box>
                )}
              >
                {personsResponsible.map((person) => (
                  <MenuItem key={person.id} value={person.id}>
                    {`${person.first_name} ${person.last_name}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid> */}
          <Grid item md={12}>
            <Controller
              name="description"
              control={control}
              disabled={!require}
              defaultValue=""
              render={({ field }) => (
                <TextField {...field} fullWidth label="DESCRIPTION" />
              )}
            />
          </Grid>
          <Grid item md={12}>
            <Typography variant="body1">Options</Typography>
            {/* <FormControlLabel
              sx={{ width: "100%" }}
              control={
                <Checkbox
                  checked={notifyPersonsResponsible}
                  onChange={(e) => {
                    setValue("notifyPersonsResponsible", e.target.checked, {
                      shouldDirty: true,
                    });
                    setNotifyPersonsResponsible(e.target.checked);
                  }}
                />
              }
              label="Notify persons responsible"
            /> */}
            <FormControlLabel
              sx={{ width: "100%" }}
              control={
                <Checkbox
                  checked={includeInTimeline}
                  onChange={(e) => {
                    setValue("includeInTimeline", e.target.checked, {
                      shouldDirty: true,
                    });
                    setIncludeInTimeline(e.target.checked);
                  }}
                />
              }
              label="Include in timeline"
            />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}
