import {
  FormControl,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  TextField,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useFormContext } from "react-hook-form";
import moment from "moment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useContext } from "react";
import ErasableTextField from "../../../ErasableTextField";
import generalInfoContainer from "./GeneralInfo.style";
import { danger } from "../../../sharedStyles";
import lookupService from "../../../../service/lookupService";
import { PermissionsContext } from "../../../../context/PermissionsContext";
import APP_PERMISSIONS from "../../../../utils/constants/permissions";
import PERMISSION_TYPES from "../../../../utils/constants/permission_types";

function GeneralInfo({ medicalRecord }) {
  const {
    register,
    formState: { errors },
    setValue,
    getValues,
    trigger,
  } = useFormContext();
  const [categories, setCategories] = React.useState([]);
  const [currentCategory, setCurrentCategory] = React.useState(
    medicalRecord?.category
  );
  const [reportedDate, setReportedDate] = React.useState(
    medicalRecord?.reported_date?.replace("Z", "") || new Date()
  );
  const [endingDate, setEndingDate] = React.useState(
    medicalRecord?.ending_date?.replace("Z", "") || null
  );
  React.useEffect(() => {
    lookupService.fetchMedicalRecordsCategories().then((response) => {
      setCategories(response.data.data);
    });
  }, []);
  const onCategoryChange = (event) => setCurrentCategory(event.target.value);
  const validateEndingDate = () =>
    !getValues("ending_date") ||
    moment(getValues("ending_date"), "MM/DD/YYYY").isSameOrAfter(
      moment(getValues("reported_date"), "MM/DD/YYYY")
    ) ||
    "Ending date should be after beginning date.";

  const { hasPermission } = useContext(PermissionsContext);

  const managePermission = hasPermission(
    APP_PERMISSIONS.STUDENT_MEDICAL,
    PERMISSION_TYPES.MANAGE
  );

  return (
    <Grid container item xs={12} spacing={2}>
      <Grid container item xs={12} sx={generalInfoContainer}>
        <Grid item md={5}>
          <FormControl fullWidth error={errors.category}>
            <InputLabel id="categoryLabel" sx={{ backgroundColor: "white" }}>
              Select Category
            </InputLabel>
            <Select
              labelId="categoryLabel"
              id="category"
              label="Select Category"
              defaultValue={medicalRecord?.category}
              {...register("category", {
                onChange: (e) => {
                  onCategoryChange(e);
                },
              })}
              disabled={!managePermission}
            >
              {categories.map((category) => (
                <MenuItem key={category.id} value={category.name}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item container justifyContent="flex-end" md={5}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={medicalRecord?.display_alerts_in_application}
                {...register("display_alerts_in_application")}
                disabled={!managePermission}
              />
            }
            label="Display alert in application"
          />
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <ErasableTextField
          fullWidth
          id="name"
          label="Name *"
          isRequired
          hasError={errors.name}
          defaultValue={medicalRecord?.name}
          disabled={!managePermission}
        />
        {errors.name && (
          <Typography sx={danger}>{errors.name.message}</Typography>
        )}
      </Grid>
      <Grid container item xs={12}>
        <ErasableTextField
          fullWidth
          id="comment_or_instruction"
          label="Comment/Instruction"
          isMultiline
          lines={5}
          defaultValue={medicalRecord?.comment_or_instruction}
          disabled={!managePermission}
        />
      </Grid>
      {currentCategory !== "Medical Accommodation" && (
        <Grid container item md={3}>
          <DesktopDatePicker
            id="reported_date"
            label="Reported Date"
            inputFormat="MM/DD/YYYY"
            onChange={(date) => {
              if (date) {
                setReportedDate(date);
                setValue("reported_date", date.format("MM/DD/YYYY"), {
                  shouldDirty: true,
                });
              }
              trigger("reported_date");
            }}
            value={reportedDate}
            renderInput={(params) => (
              <TextField
                {...params}
                error={Boolean(errors.reported_date)}
                {...register("reported_date", {
                  pattern: {
                    value:
                      /(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/(19|20)\d{2}/,
                    message: "Invalid date format.",
                  },
                })}
              />
            )}
            disabled={!managePermission}
          />
          {errors.reported_date && (
            <Typography sx={danger}>{errors.reported_date.message}</Typography>
          )}
        </Grid>
      )}
      {currentCategory === "Medical Accommodation" && (
        <Grid container item md={12} columnSpacing={3}>
          <Grid container item md={6}>
            <DesktopDatePicker
              id="reported_date"
              label="Beginning Date *"
              onChange={(date) => {
                if (date) {
                  setReportedDate(date);
                  setValue("reported_date", date.format("MM/DD/YYYY"), {
                    shouldDirty: true,
                  });
                }
                trigger("reported_date");
              }}
              inputFormat="MM/DD/YYYY"
              value={reportedDate}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  error={Boolean(errors.reported_date)}
                  {...register("reported_date", {
                    required: "This field is required.",
                    pattern: {
                      value:
                        /(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/(19|20)\d{2}/,
                      message: "Invalid date format.",
                    },
                  })}
                />
              )}
              disabled={!managePermission}
            />
            {errors.reported_date && (
              <Typography sx={danger}>
                {errors.reported_date.message}
              </Typography>
            )}
          </Grid>
          <Grid container item md={6}>
            <DesktopDatePicker
              fullWidth
              id="ending_date"
              label="Ending Date"
              onChange={(date) => {
                if (date) {
                  setEndingDate(date);
                  setValue("ending_date", date.format("MM/DD/YYYY"), {
                    shouldDirty: true,
                  });
                  trigger("ending_date");
                }
              }}
              inputFormat="MM/DD/YYYY"
              value={endingDate}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  error={Boolean(errors.ending_date)}
                  {...register("ending_date", {
                    pattern: {
                      value:
                        /(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/(19|20)\d{2}/,
                      message: "Invalid date format.",
                    },
                    validate: validateEndingDate,
                  })}
                />
              )}
              disabled={!managePermission}
            />
            {errors.ending_date && (
              <Typography sx={danger}>{errors.ending_date.message}</Typography>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default GeneralInfo;
