const klassSelectContainer = {
  mt: "40px",
  color: "black",
};

const klassSelectTitle = {
  fontSize: "32px",
  mb: "20px",
};

const klassSelectConfirmContainer = {
  mt: "68px",
};

const klassSelectColumnContainer = {
  mx: "10px",
  width: "500px",
  mt: "68px",
};

const klassSelectStudent = {
  mb: "15px",
  width: "245px",
  display: "block",
};

const klassSelectCourseContainer = {
  maxWidth: "388px",
  width: "100%",
};

const klassSelectGoogleStudent = {
  ...klassSelectStudent,
  pointerEvents: "none",
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "#000000",
  },
  "& .MuiInputLabel-root.Mui-disabled": {
    color: "rgba(0, 0, 0, 0.6)",
  },
};

const klassSelectConfirmBtn = {
  backgroundColor: "#2196F3",
  fontWeight: "normal",
  color: "white",
  fontSize: "14px",
  borderRadius: "100px",
  textTransform: "none",
  px: "20px",
  "&:hover": {
    backgroundColor: "#42a7f7",
  },
  "&:disabled": {
    backgroundColor: "#efefef",
  },
};

const klassSelectErrorMsg = {
  color: "red",
  mt: "10px",
};

const klassSelectLinkIcon = {
  mb: "15px",
  mr: "5px",
  color: "rgba(0, 0, 0, 0.6)",
};

export {
  klassSelectTitle,
  klassSelectConfirmContainer,
  klassSelectColumnContainer,
  klassSelectStudent,
  klassSelectCourseContainer,
  klassSelectGoogleStudent,
  klassSelectConfirmBtn,
  klassSelectContainer,
  klassSelectErrorMsg,
  klassSelectLinkIcon,
};
