import React, { useContext, useEffect, useState } from "react";
import { useSearchParams, useParams, useOutletContext } from "react-router-dom";
import { CircularProgress, Typography } from "@mui/material";
import StudentsTable from "./StudentsTable";
import { loader, textCenter, pt30 } from "../../sharedStyles";
import studentsService from "../../../service/studentsService";
import { QuickBarContext } from "../../../context/QuickBarContext";
import schoolYearApplicationService from "../../../service/schoolYearApplicationService";
import schoolsService from "../../../service/schoolsService";

export default function UnenrolledStudents({ isCompact = false }) {
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [order, setOrder] = React.useState(
    searchParams.get("direction") || "asc"
  );
  const [orderBy, setOrderBy] = React.useState(
    searchParams.get("sort") || "student"
  );
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [students, setStudents] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [count, setCount] = useState(25);
  const quickBarContext = useContext(QuickBarContext);
  const [, selectedSchoolYear, query, setQuery] = useOutletContext();
  const [registeredStudents, setRegisteredStudents] = useState([]);
  const [currentSchool, setCurrentSchool] = useState({});
  const params = useParams();
  const schoolId = params.school_id;

  const getRegistrations = async () => {
    const response = await schoolYearApplicationService.fetchAll({
      params: {
        school_year_id: selectedSchoolYear?.id,
        order,
        order_by: orderBy,
        query,
      },
    });
    if (response.data) {
      setRegisteredStudents(response.data.school_year_applications);
    }
  };

  const getSchool = async () => {
    const response = await schoolsService.fetchSchool(params.school_id);

    setCurrentSchool(response.data);
  };

  useEffect(() => {
    setPage(1);
    searchParams.set("page", 1);
    setSearchParams(searchParams);
    if (selectedSchoolYear) {
      getRegistrations();
    }
  }, [selectedSchoolYear, query]);

  const fetchStudentList = async () => {
    setLoading(true);
    const response = await studentsService.enrollments({
      params: {
        school_id: schoolId,
        page,
        orderBy,
        order,
        query,
        count,
        schoolYearId: searchParams.get("school_year"),
        enrolledStatus: false,
      },
    });
    const queryPage = searchParams.get("page") || 1;
    const lastPage = response.pagination.total_pages;
    const currentPage = queryPage > lastPage ? lastPage : queryPage;
    const countPerPage = searchParams.get("count") || 25;
    setStudents(response.data);
    setTotalPages(lastPage);
    setPage(currentPage);
    setCount(countPerPage);
    setLoading(false);
  };

  const getStudents = () => {
    fetchStudentList();
    setOrder(searchParams.get("direction") || "asc");
    setOrderBy(searchParams.get("sort") || "student");
    setQuery(searchParams.get("query"));
  };

  useEffect(() => {
    if (searchParams.get("school_year")) {
      getSchool();
      getStudents();
    }
  }, [
    searchParams.get("page"),
    searchParams.get("direction"),
    searchParams.get("sort"),
    searchParams.get("query"),
    searchParams.get("count"),
    searchParams.get("school_year"),
  ]);

  const syncStudents = () => {
    studentsService
      .syncStudents({
        school_id: schoolId,
        school_year_id: selectedSchoolYear.id,
      })
      .then(() => {
        getStudents();
        quickBarContext.cleanSyncStudents();
      })
      .catch(() => {
        quickBarContext.cleanSyncStudents();
      });
  };

  useEffect(() => {
    if (quickBarContext.syncStudents) {
      syncStudents();
    }
  }, [quickBarContext.syncStudents]);

  if (loading) {
    return <CircularProgress sx={loader} size={100} />;
  }

  if (students.length > 0) {
    return (
      <div>
        <StudentsTable
          isCompact={isCompact}
          students={students}
          setStudents={setStudents}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
          selected={selected}
          order={order}
          orderBy={orderBy}
          setSelected={setSelected}
          setPage={setPage}
          totalPages={totalPages}
          setOrderBy={setOrderBy}
          setOrder={setOrder}
          page={page}
          registeredStudents={registeredStudents}
          currentSchool={currentSchool}
        />
      </div>
    );
  }
  return <Typography sx={[textCenter, pt30]}>No students found.</Typography>;
}
