import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { fetchGuardianKidMessages } from "../../../../service/guardiansService";
import { textCenter } from "../../../sharedStyles";
import {
  messagesBox,
  messagesCard,
  tableBodyImage,
  tableBodyContent,
  tableBodyContentName,
  tableBodyContentDesc,
  tableBodyContentTime,
} from "./Messages.styles";
import profilePictureIcon from "../../../../utils/profilePictureIcon";
import { longShortDateTime } from "../../../../utils/dateTimePresets";

function truncate(str) {
  const newStr = str.length > 350 ? `${str.substring(0, 350)}...` : str;
  return newStr.replace(/(<([^>]+)>)/gi, "").replaceAll("&nbsp;", "");
}

function displayName(sender) {
  return `${sender.first_name} ${sender.last_name}`;
}

const conversationKey = (message) =>
  message.conversation_key || message.original_message_conversation_key;

export default function GuardianKidsInfoHomeMessages() {
  const { t, i18n } = useTranslation(["guardian", "common"]);
  const params = useParams();
  const guardianId = params.id;
  const kidId = params.kid_id;
  const [kidMessages, setKidMessages] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (guardianId && kidId) {
        try {
          setLoading(true);
          const response = await fetchGuardianKidMessages({
            params: {
              guardianId,
              kidId,
              count: 20,
              page: 1,
            },
          });

          if (response.data) {
            setKidMessages(response.data);
            setLoading(false);
          }
        } catch (e) {
          // handle catch
        }
      }
    })();
  }, [kidId]);

  return (
    <>
      <Box sx={messagesBox}>
        {t("studentsMessages", { keyPrefix: "homeMessages" })}
      </Box>

      <Card sx={messagesCard}>
        <CardContent>
          <TableContainer>
            <Table aria-label="messages">
              <TableBody>
                {kidMessages.length > 0 ? (
                  kidMessages.map((message) => (
                    <TableRow
                      key={message.id}
                      hover
                      sx={{ cursor: "pointer" }}
                      /* eslint-disable-next-line no-return-assign */
                      onClick={() =>
                        (window.location.href = `/guardian/${guardianId}/students/${kidId}/messages/${conversationKey(
                          message
                        )}`)
                      }
                    >
                      <TableCell sx={tableBodyImage}>
                        <img
                          alt="profile_image"
                          src={profilePictureIcon(
                            message?.recent_message?.sender
                          )}
                          style={{
                            borderRadius: "100px",
                            width: "90px",
                            height: "90px",
                            objectFit: "cover",
                          }}
                        />
                      </TableCell>
                      <TableCell sx={tableBodyContent}>
                        <Typography sx={tableBodyContentName}>
                          {displayName(message.recent_message?.sender)}
                        </Typography>
                        <Typography sx={tableBodyContentDesc}>
                          {truncate(message.recent_message.description)}
                        </Typography>
                        <Typography sx={tableBodyContentTime}>
                          {longShortDateTime(
                            message.recent_message.created_at,
                            { language: i18n.language }
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow sx={textCenter}>
                    <TableCell style={{ borderBottom: "none" }} sx={textCenter}>
                      {loading
                        ? t("loading", { ns: "common" })
                        : t("noMessages", { keyPrefix: "homeMessages" })}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </>
  );
}
