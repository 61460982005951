import { Controller, useFormContext } from "react-hook-form";
import {
  ButtonBase,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Clear } from "@mui/icons-material";

export default function FileDetail({ file, index, deleteFileFrom }) {
  const [selectedOptions, setSelectedOption] = useState(file.type);
  const [require, setRequire] = useState(file.require);
  const [includeInTimeline, setIncludeInTimeline] = useState(
    file.includeInTimeline
  );

  const methods = useFormContext();
  const { register, setValue, control, trigger } = methods;

  useEffect(() => {
    setValue(`files[${index}].id`, file.id, {
      shouldDirty: true,
    });
    setValue(`files[${index}].description`, file.description, {
      shouldDirty: true,
    });
    setValue(`files[${index}].name`, file.name, { shouldDirty: true });
    setValue(`files[${index}].type`, file.type, { shouldDirty: true });
    setValue(`files[${index}].require`, file.require, { shouldDirty: true });
    setValue(
      `files[${index}].includeInTimeline`,
      Boolean(file.includeInTimeline),
      {
        shouldDirty: true,
      }
    );
    setSelectedOption(file.type);
    setRequire(file.require);
    setIncludeInTimeline(file.includeInTimeline);
  }, [file]);

  return (
    <Grid
      item
      md={12}
      container
      sx={{ border: "1px solid #A2E6F5", p: 2, position: "relative" }}
      rowGap={2.5}
      key={file.id}
    >
      <ButtonBase
        sx={{ position: "absolute", right: 1, top: 1 }}
        onClick={() => deleteFileFrom(index)}
      >
        <Clear />
      </ButtonBase>
      <Grid item md={12} textAlign="center">
        <Typography variant="caption">FILE {index + 1}</Typography>
      </Grid>
      <Grid item md={12}>
        <FormControl fullWidth>
          <InputLabel id="typeDropdownId">Type</InputLabel>
          <Select
            labelId="typeDropdownId"
            label="Type"
            value={selectedOptions}
            placeholder="Select"
            {...register(`files[${index}].type`, {
              onChange: (event) => {
                setSelectedOption(event.target.value);
                setValue(`files[${index}].type`, event.target.value, {
                  shouldDirty: true,
                });
                trigger();
              },
            })}
          >
            <MenuItem key="PDF" value="PDF">
              <Typography textAlign="left">PDF</Typography>
            </MenuItem>
            <MenuItem key="CSV" value="CSV">
              <Typography textAlign="left">CSV</Typography>
            </MenuItem>
            <MenuItem key="XLS" value="XLS">
              <Typography textAlign="left">XLS</Typography>
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={12}>
        <Controller
          name={`files[${index}].name`}
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField {...field} fullWidth label="Name of file" />
          )}
        />
      </Grid>
      <Grid item md={12}>
        <Controller
          name={`files[${index}].description`}
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              defaultValue={file.description}
              {...field}
              fullWidth
              label="DESCRIPTION"
            />
          )}
        />
      </Grid>
      <Grid item md={6}>
        <FormControlLabel
          sx={{ width: "100%", justifyContent: "center" }}
          control={
            <Checkbox
              checked={require}
              onChange={(e) => {
                setValue(`files[${index}].require`, e.target.checked, {
                  shouldDirty: true,
                });
                setRequire(e.target.checked);
              }}
            />
          }
          label="Require"
        />
      </Grid>
      <Grid item md={6}>
        <FormControlLabel
          sx={{ width: "100%", justifyContent: "center" }}
          control={
            <Checkbox
              checked={includeInTimeline}
              onChange={(e) => {
                setValue(
                  `files[${index}].includeInTimeline`,
                  e.target.checked,
                  {
                    shouldDirty: true,
                  }
                );
                setIncludeInTimeline(e.target.checked);
              }}
            />
          }
          label="Include in timeline"
        />
      </Grid>
    </Grid>
  );
}
