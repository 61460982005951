import React, { createContext, useMemo } from "react";

export const PermissionsContext = createContext(null);

export function PermissionsProvider({ children, permissions }) {
  const hasPermission = useMemo(
    () => (name, type) => {
      if (!permissions || permissions.length <= 0) return false;

      for (let i = 0; i < permissions.length; i += 1) {
        const permission = permissions[i];
        if (permission.name === name && permission[type] === true) {
          return true;
        }
      }

      return false;
    },
    [permissions]
  );

  const hasAnyPermissionType = useMemo(
    () => (name) => {
      if (!permissions || permissions.length <= 0) return false;

      for (let i = 0; i < permissions.length; i += 1) {
        if (
          permissions[i].name === name &&
          (permissions[i].view || permissions[i].manage)
        ) {
          return true;
        }
      }

      return false;
    },
    [permissions]
  );

  const hasAnyPermission = useMemo(
    () =>
      (...permissionsToCheck) => {
        if (!permissions || permissions.length <= 0) return false;

        for (let i = 0; i < permissions.length; i += 1) {
          const permission = permissions[i];
          const matchesAnyPermission = permissionsToCheck.some(
            ({ name, type }) =>
              permission.name === name && permission[type] === true
          );

          if (matchesAnyPermission) {
            return true;
          }
        }

        return false;
      },
    [permissions]
  );

  const value = {
    permissions,
    hasPermission,
    hasAnyPermission,
    hasAnyPermissionType,
  };

  return (
    <PermissionsContext.Provider value={useMemo(() => value, [value])}>
      {children}
    </PermissionsContext.Provider>
  );
}
