import { tableCellClasses, tableRowClasses } from "@mui/material";

const contactsTableStyles = {
  [`& .${tableCellClasses.root}`]: {
    borderBottom: "none",
  },
  [`& .${tableRowClasses.root}:not(:last-child)`]: {
    borderBottom: "1px solid #d9d9d9",
  },
  "& .MuiTableCell-head": {
    color: "#8f8e8e",
    fontWeight: "500 !important",
    textTransform: "uppercase",
  },
  "& .MuiTableCell-body": {
    color: "black",
    fontSize: 14,
  },
};

const userCardAvatar = {
  width: "8rem",
  height: "8rem",
  borderRadius: "50%",
  border: "1px solid",
  display: "flex",
  overflow: "hidden",
  justifyContent: "center",
  backgroundColor: "#ffffff",
};

const circularProgressContainer = {
  minWidth: 450,
  minHeight: 450,
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const userCardActionButton = {
  fontSize: 13,
  textTransform: "none",
  backgroundColor: "#2196F3",
};

export {
  userCardAvatar,
  contactsTableStyles,
  circularProgressContainer,
  userCardActionButton,
};
