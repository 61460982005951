import {
  Box,
  FormControl,
  InputLabel,
  Select,
  Typography,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { renderError } from "../../../../../utils/constants/forms";

export default function DropdownFieldRenderer({
  field,
  properties,
  defaultValue,
}) {
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();
  const [selectedOptions, setSelectedOption] = useState(defaultValue || []);

  return (
    <Box padding={1}>
      <FormControl error={errors[field.fieldId]} fullWidth>
        <InputLabel id={field.fieldId} required={properties.isRequired}>
          {field.label}
        </InputLabel>
        <Select
          labelId={field.fieldId}
          label={field.label}
          value={selectedOptions}
          placeholder="Select"
          {...register(`custom_field_responses.${field.field_id}`, {
            onChange: (event) => {
              setSelectedOption(event.target.value);
              setValue(
                `custom_field_responses.${field.field_id}`,
                event.target.value,
                {
                  shouldDirty: true,
                }
              );
            },
            required: properties.isRequired && "This field is required.",
          })}
        >
          {properties.options.map((option) => (
            <MenuItem value={option.value} key={option.id}>
              <Typography textAlign="left">{option.value}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {errors.custom_field_responses &&
        errors.custom_field_responses[`${field.field_id}`] &&
        renderError(errors.custom_field_responses[`${field.field_id}`].message)}
    </Box>
  );
}
