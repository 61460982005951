import axios from "../utils/axios-util";

const endpoint = "/api/withdrawal_reasons";

const bulkUpdate = async (params) => {
  const { data } = await axios.put(`${endpoint}/bulk_update`, params);
  if (!data.data) {
    console.error("Error updating school day");
    return [];
  }
  return data;
};

const bulkCreate = async (params) => {
  const { data } = await axios.post(`${endpoint}/bulk_create`, params);
  if (!data.data) {
    console.error("Error updating school day");
    return [];
  }
  return data;
};

const fetchAll = async () => {
  const { data } = await axios.get(endpoint);
  if (!data.data) {
    console.error("Error fetching withdrawal reasons");
    return [];
  }
  return data;
};

const bulkDelete = async (params) => {
  const { data } = await axios.delete(`${endpoint}/bulk_delete`, params);
  if (!data.data) {
    console.error("Error deleting collection from the API");
    return [];
  }
  return data;
};

export default {
  bulkUpdate,
  fetchAll,
  bulkCreate,
  bulkDelete,
};
