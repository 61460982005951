export default class FormProperties {
  constructor(_properties) {
    const properties = _properties || {};
    this.submissionTimezone = properties.submissionTimezone || null;
    this.sendConfirmationEmail = properties.sendConfirmationEmail || null;
    this.sendNotificationReceiptEmail =
      properties.sendNotificationReceiptEmail || null;
    this.staffSendNotificationReceiptEmail =
      properties.staffSendNotificationReceiptEmail || null;
    this.emailBody = properties.emailBody || null;
  }
}
