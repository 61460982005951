import { tableCellClasses, tableRowClasses } from "@mui/material";

const tableStyles = {
  [`& .${tableCellClasses.root}`]: {
    borderBottom: "none",
  },
  [`& .${tableRowClasses.root}`]: {
    borderBottom: "1px solid #d9d9d9",
  },
  "& .MuiTableCell-body": {
    color: "black",
    fontSize: 14,
  },
  "& .MuiTableCell-root:nth-child(1)": {
    width: "10%",
  },
  "& .MuiTableCell-root:nth-child(2)": {
    width: "75%%",
  },
  "& .MuiTableCell-root:nth-child(3)": {
    width: "15%",
  },
};

export default tableStyles;
