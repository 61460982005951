const reportCardDetailRadioContainer = {
  alignItems: "start",
};

const reportCardDetailLabel = {
  mt: "9px",
};

const reportCardCalculationsTableCell = {
  color: "black",
  px: 0,
  fontSize: "16px",
};

const reportCardCalculationsLastRow = {
  borderBottom: "2px solid black",
};

const reportCardCalculationsTableCellBold = {
  ...reportCardCalculationsTableCell,
  border: "none",
  fontWeight: "bold",
};

export {
  reportCardDetailRadioContainer,
  reportCardDetailLabel,
  reportCardCalculationsTableCell,
  reportCardCalculationsLastRow,
  reportCardCalculationsTableCellBold,
};
