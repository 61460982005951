import React from "react";
import { Box, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useSearchParams } from "react-router-dom";
import {
  classSchedulesCheckbox,
  classSchedulesContainer,
  classSchedulesLabel,
  classSchedulesSelect,
} from "./ReportCardSelect.styles";

export default function ReportCardSelect({
  label,
  values,
  isActive,
  setIsActive,
  value,
  setValue,
  urlParam,
  urlEnabledParam,
  hasCheckmark,
  allEnabled,
  setIsCheck,
  setIsCheckAll,
}) {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleChange = (e) => {
    const params = {};
    searchParams.forEach((urlValue, key) => {
      params[key] = urlValue;
    });
    params[urlParam] = e.target.value;
    setValue(e.target.value);
    setSearchParams(params);
    setIsCheckAll(false);
    setIsCheck([]);
  };

  const handleFilterBoxClick = () => {
    const params = {};
    searchParams.forEach((urlValue, key) => {
      params[key] = urlValue;
    });

    params[urlEnabledParam] = !isActive;
    setSearchParams(params);
    setIsActive(!isActive);
  };

  return (
    <Box sx={classSchedulesContainer(isActive)}>
      {hasCheckmark ? (
        <Box sx={classSchedulesCheckbox} onClick={() => handleFilterBoxClick()}>
          {isActive && <CheckIcon />}
        </Box>
      ) : null}
      <FormControl sx={classSchedulesSelect(isActive)}>
        <InputLabel id="class" shrink sx={classSchedulesLabel(isActive)}>
          {label}
        </InputLabel>
        <Select
          labelId="class"
          id="class"
          displayEmpty
          disabled={!isActive}
          onChange={handleChange}
          value={value}
        >
          {allEnabled ? (
            <MenuItem value="">All</MenuItem>
          ) : (
            <MenuItem disabled value="" />
          )}
          {values &&
            values.map((menuValue) => (
              <MenuItem key={menuValue.id} value={menuValue.id}>
                {menuValue.value}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
}
