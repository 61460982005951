import React, { useMemo, useRef } from "react";
import { Box } from "@mui/material";
import CssTextField from "./OtpInput.style";

function OtpInput({ otpCode, onChange }) {
  const DIGIT_REGEX = /^\d+$/;
  const inputsRef = useRef();

  const valueItems = useMemo(() => {
    const valueArray = otpCode.split("");
    const items = [];

    for (let index = 0; index < 6; index += 1) {
      const char = valueArray[index];
      const isNumber = DIGIT_REGEX.test(char);

      items.push({ id: index, value: isNumber ? char : "" });
    }

    return items;
  }, [otpCode]);

  const getInputsRefMap = () => {
    if (!inputsRef.current) {
      inputsRef.current = new Map();
    }
    return inputsRef.current;
  };

  const inputOnChange = (event, obj, index) => {
    let targetValue = event.target.value.trim();
    const isTargetValueDigit = DIGIT_REGEX.test(targetValue);

    if (!isTargetValueDigit && targetValue !== "") {
      return;
    }

    targetValue = isTargetValueDigit ? targetValue : " ";

    if (targetValue.length === 1) {
      const newValue =
        otpCode.substring(0, index) +
        targetValue +
        otpCode.substring(index + 1);

      onChange(newValue);
      if (!isTargetValueDigit) {
        return;
      }
      if (obj.id < 5) {
        inputsRef.current.get(obj.id + 1).focus();
      }
    } else if (targetValue.length === 6) {
      onChange(targetValue);
      event.target.blur();
    }
  };

  const inputOnKeyDown = (event, obj) => {
    event.target.setSelectionRange(0, event.target.value.length);
    if (event.key === "Backspace" && obj.id > 0) {
      setTimeout(() => {
        inputsRef.current.get(obj.id - 1).focus();
      }, 0);
    }
  };

  const inputOnFocus = (event) => {
    event.target.setSelectionRange(0, event.target.value.length);
  };

  return (
    <Box sx={{ width: "100%", textAlign: "center" }} className="otp-group">
      {valueItems.map((obj, index) => (
        <CssTextField
          key={obj.id}
          type="text"
          inputMode="numeric"
          autoComplete="one-time-code"
          pattern="\d{1}"
          maxLength={6}
          variant="filled"
          color="text"
          className="otp-input"
          value={obj.value}
          onChange={(e) => inputOnChange(e, obj, index)}
          onKeyDown={(e) => inputOnKeyDown(e, obj)}
          onFocus={inputOnFocus}
          inputRef={(node) => {
            if (node) {
              const map = getInputsRefMap();
              map.set(obj.id, node);
            }
          }}
          sx={{ width: "50px", marginRight: 2 }}
        />
      ))}
    </Box>
  );
}

export default OtpInput;
