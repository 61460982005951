const gridContainer = {
  width: "auto",
};

const gridItemMessages = {
  fontSize: "34px",
  color: "#000000",
};

const gridItemNewMessageButton = {
  float: "right",
  backgroundColor: "#2CB6D8",
  textTransform: "none",
  borderRadius: "3px",
  "&:hover": {
    backgroundColor: "#2CB6D8",
  },
};

const newMessageIcon = {
  "&.MuiSvgIcon-root": {
    marginBottom: "4px",
    transform: "rotate(320deg)",
  },
};

const gridItemNewMessage = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const gridItemSearchBox = {
  float: "right",
  paddingBottom: "10px",
  borderBottom: "1px solid #BFC7CA",
};

const formTextField = {
  "& label": {
    fontSize: "18px",
    color: "#000000",
  },
};

const iconButtonSearch = {
  width: "1.3em",
  height: "1.3em",
};

const inboxRowCount = {
  backgroundColor: "black",
  ml: "50px ",
  fontSize: "16px",
  width: "35px",
  height: "35px",
};

const messagesAttachIcon = {
  transform: "rotate(45deg)",
};

const messagesRow = {
  p: "15px",
  color: "black",
  borderBottom: "1px solid #BFC7CA",
  "&:hover": {
    backgroundColor: "#0000000",
    cursor: "pointer",
  },
};

const messagesRowDescription = {
  my: "auto",
};

const messagesRowName = {
  fontWeight: 500,
  lineHeight: "20px",
};

const messagesRowTimestamp = {
  fontSize: "11px",
  color: "#336898",
};

export {
  gridContainer,
  gridItemMessages,
  gridItemNewMessage,
  gridItemNewMessageButton,
  gridItemSearchBox,
  formTextField,
  iconButtonSearch,
  inboxRowCount,
  messagesAttachIcon,
  messagesRow,
  messagesRowDescription,
  messagesRowName,
  messagesRowTimestamp,
  newMessageIcon,
};
