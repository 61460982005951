import axios from "../utils/axios-util";

const studentsEndpoint = "/api/students";

const fetchAllStudents = async (
  params,
  page = 1,
  orderBy = "student",
  order = "asc",
  query = "",
  count = 25,
  schoolYearId = "",
  enrolledStatus = true
) => {
  const { data } = await axios.get(
    `${studentsEndpoint}?page=${page}&orderBy=${orderBy}&order=${order}&query=${query}&count=${count}&schoolYearId=${schoolYearId}&enrolledStatus=${enrolledStatus}`,
    params
  );
  if (!data.data) {
    console.error("Error fetching users from the API");
    return [];
  }
  return data;
};

const enrollments = async (
  params,
  page = 1,
  orderBy = "student",
  order = "asc",
  query = "",
  count = 25,
  schoolYearId = "",
  enrolledStatus = true
) => {
  const { data } = await axios.get(
    `${studentsEndpoint}/enrollments?page=${page}&orderBy=${orderBy}&order=${order}&query=${query}&count=${count}&schoolYearId=${schoolYearId}&enrolledStatus=${enrolledStatus}`,
    params
  );
  if (!data.data) {
    console.error("Error fetching students enrollments from the API");
    return [];
  }
  return data;
};

const fetchAll = async (
  params,
  page = 1,
  orderBy = "student",
  order = "asc",
  query = "",
  count = 25,
  schoolYearId = "",
  enrolledStatus = true
) => {
  const { data } = await axios.get(
    `${studentsEndpoint}/all?page=${page}&orderBy=${orderBy}&order=${order}&query=${query}&count=${count}&schoolYearId=${schoolYearId}&enrolledStatus=${enrolledStatus}`,
    params
  );
  if (!data.data) {
    console.error("Error fetching users from the API");
    return [];
  }
  return data;
};

const fetchAllStudentsInSchool = async (
  params,
  page = 1,
  orderBy = "student",
  order = "asc",
  query = "",
  count = 25,
  filterGradeLevel = "all"
) => {
  const { data } = await axios.get(
    `/api/students_in_school?page=${page}&orderBy=${orderBy}&order=${order}&query=${query}&count=${count}&filterGradeLevel=${filterGradeLevel}`,
    params
  );
  if (!data.data) {
    console.error("Error fetching students from the API");
    return [];
  }
  return data;
};

const fetchStudent = async (slug, params) => {
  const { data } = await axios.get(`${studentsEndpoint}/${slug}`, params);
  if (!data) {
    console.error("Error fetching user from the API");
    return [];
  }
  return data;
};

const fetchAccountDetails = async (slug, params) => {
  const { data } = await axios.get(
    `${studentsEndpoint}/${slug}/account_details`,
    params
  );
  if (!data) {
    console.error("Error fetching student account details from the API");
    return [];
  }
  return data;
};

const fetchProfileDetails = async (id, params) => {
  const { data } = await axios.get(
    `${studentsEndpoint}/${id}/profile_details`,
    params
  );
  if (!data) {
    console.error("Error fetching student profile details from the API");
    return [];
  }
  return data;
};

const fetchMedicalDetails = async (id, params) => {
  const { data } = await axios.get(
    `${studentsEndpoint}/${id}/medical_details`,
    params
  );
  if (!data) {
    console.error("Error fetching student medical details from the API");
    return [];
  }
  return data;
};
const fetchStudentCard = async (id, params) => {
  const { data } = await axios.get(
    `${studentsEndpoint}/${id}/student_card_details`,
    params
  );
  if (!data) {
    console.error("Error fetching student card details from the API");
    return [];
  }
  return data;
};

const fetchGuardiansByStudent = async (slug) => {
  const { data } = await axios.get(`${studentsEndpoint}/${slug}/guardians`);
  if (!data) {
    console.error("Error fetching user from the API");
    return [];
  }
  return data;
};

const postStudentFiles = async (id, body) => {
  await axios.post(`${studentsEndpoint}/${id}/files`, body);
};

const fetchStudentFiles = async (id) => {
  const { data } = await axios.get(`${studentsEndpoint}/${id}/files`);
  if (!data) {
    console.error("Error fetching user from the API");
    return [];
  }
  return data;
};

const deleteStudentFile = async (id, fileId) => {
  await axios.delete(`${studentsEndpoint}/${id}/delete_file/${fileId}`);
};

const fetchResidesWithByStudentId = async (id) => {
  const { data } = await axios.get(`${studentsEndpoint}/${id}/resides_with`);
  if (!data.data) {
    console.error("Error fetching data from the API");
    return [];
  }
  return data;
};

const fetchAllStudentsWithTermGrades = async (params) => {
  const { data } = await axios.get(`${studentsEndpoint}/term_grades`, params);
  if (!data.data) {
    console.error("Error fetching data from the API");
    return [];
  }
  return data;
};

const update = async (student, data) =>
  axios.put(`${studentsEndpoint}/${student}`, data);

const updateProfile = async (student, data, queryParams) =>
  axios.put(`${studentsEndpoint}/${student}/profile`, data, {
    params: queryParams,
  });

const updateMedicalDetails = async (student, data) =>
  axios.put(`${studentsEndpoint}/${student}/medical_details`, data);

const addMedicalRecords = async (student, data) =>
  axios.post(`${studentsEndpoint}/${student}/medical_records`, data);
const getMedicalRecords = async (student) =>
  axios.get(`${studentsEndpoint}/${student}/medical_records`);
const getMedicalRecord = async (studentId, medicalRecordId) =>
  axios.get(
    `${studentsEndpoint}/${studentId}/medical_record/${medicalRecordId}`
  );
const updateMedicalRecord = async (studentId, medicalRecordId, data) =>
  axios.put(
    `${studentsEndpoint}/${studentId}/medical_record/${medicalRecordId}`,
    data
  );
const deleteMedicalRecord = async (studentId, medicalRecordId) =>
  axios.delete(
    `${studentsEndpoint}/${studentId}/medical_record/${medicalRecordId}`
  );

const fetchMedicalEvents = async (studentId) =>
  axios.get(`${studentsEndpoint}/${studentId}/medical_events`);

const syncStudents = async (params) => {
  const { data } = await axios.get(`${studentsEndpoint}/sync_student`, {
    params,
  });
  if (!data) {
    console.error("Error fetching student from the API");
    return [];
  }
  return data;
};

const resetPassword = async (studentId, password) =>
  axios.put(`${studentsEndpoint}/${studentId}/reset_password`, {
    password,
  });

const sendPortalLink = async (studentId) =>
  axios.put(`${studentsEndpoint}/${studentId}/send_portal_link`);

const copyStudentData = async (student, data) =>
  axios.put(`${studentsEndpoint}/${student}/copy_from_registration`, data);

const fetchStudentMessages = (params) =>
  axios.get(
    `${studentsEndpoint}/${params.params.studentSlug}/messages`,
    params
  );

const fetchStudentDetails = async (studentId) => {
  const { data } = await axios.get(`${studentsEndpoint}/student_details`, {
    params: { id: studentId },
  });
  if (!data) {
    console.error("Error fetching process");
    return {};
  }
  return data;
};

const enrolledInPreviousYears = async (schoolYearId, gradeLevel) => {
  const { data } = await axios.get(
    `${studentsEndpoint}/previously_enrolled_students`,
    {
      params: { school_year_id: schoolYearId, grade_level: gradeLevel },
    }
  );
  if (!data) {
    console.error("Error fetching previously enrolled students.");
    return [];
  }
  return data;
};

const updateStudentDashboard = async (data) =>
  axios.put(`${studentsEndpoint}/update_student_dashboard`, data);

const admissionProcessStudents = async (schoolYearId) => {
  const { data } = await axios.get(
    `${studentsEndpoint}/admission_process_students`,
    {
      params: { school_year_id: schoolYearId },
    }
  );
  if (!data) {
    console.error("Error fetching previously enrolled students.");
    return [];
  }
  return data;
};

const importStudentsData = async (targetStudentId, studentId) =>
  axios.post(`${studentsEndpoint}/import_student_form_responses`, {
    source_student_id: studentId,
    target_student_id: targetStudentId,
  });

export default {
  fetchAll,
  fetchAllStudents,
  fetchAllStudentsInSchool,
  fetchStudent,
  fetchGuardiansByStudent,
  postStudentFiles,
  fetchStudentFiles,
  fetchResidesWithByStudentId,
  fetchAllStudentsWithTermGrades,
  update,
  updateProfile,
  deleteStudentFile,
  updateMedicalDetails,
  addMedicalRecords,
  getMedicalRecords,
  getMedicalRecord,
  updateMedicalRecord,
  deleteMedicalRecord,
  fetchMedicalEvents,
  fetchAccountDetails,
  fetchProfileDetails,
  fetchMedicalDetails,
  fetchStudentCard,
  syncStudents,
  resetPassword,
  sendPortalLink,
  copyStudentData,
  fetchStudentMessages,
  fetchStudentDetails,
  enrolledInPreviousYears,
  updateStudentDashboard,
  importStudentsData,
  admissionProcessStudents,
  enrollments,
};
