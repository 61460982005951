import * as React from "react";
import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg">
    <g
      transform="translate(0.000000,24.000000) scale(0.005381,-0.005381)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M1875 4420 c-974 -176 -1703 -952 -1816 -1933 -15 -132 -6 -484 15
                -602 41 -220 101 -404 199 -608 l61 -129 -142 -427 c-105 -314 -142 -439 -142
                -475 0 -100 78 -187 180 -201 45 -6 85 6 489 140 l441 146 53 -29 c192 -110
                500 -207 767 -243 137 -19 430 -16 575 5 899 130 1618 778 1832 1651 19 77 36
                151 39 163 4 20 7 22 19 12 12 -10 15 -9 15 4 0 9 -6 16 -14 16 -12 0 -13 14
                -9 73 4 46 1 88 -7 111 -51 154 -257 186 -362 55 -25 -31 -33 -57 -53 -175
                -13 -76 -36 -178 -50 -226 -164 -563 -608 -1021 -1160 -1198 -246 -78 -495
                -105 -733 -79 -271 29 -480 100 -770 259 -52 28 -85 40 -114 40 -25 0 -149
                -36 -315 -91 -151 -50 -276 -89 -279 -87 -2 3 37 125 86 273 65 195 90 283 90
                319 0 43 -10 67 -69 175 -141 255 -207 471 -231 760 -26 304 38 629 180 916
                272 550 785 909 1413 990 90 12 162 91 173 191 5 41 1 58 -23 104 -58 112
                -137 136 -338 100z"
      />
      <path
        d="M2846 4326 c-46 -17 -99 -66 -121 -112 -35 -74 -7 -199 55 -246 14
                -10 74 -38 134 -63 471 -194 834 -566 1011 -1033 49 -131 110 -177 226 -170
                58 3 74 8 106 34 118 96 112 196 -32 489 -237 482 -663 874 -1170 1075 -95 38
                -156 45 -209 26z"
      />
    </g>
    <ellipse cx="12" cy="12" rx="5" ry="5" fill="#95D122" />
  </svg>
);
