import CustomFieldBase from "./CustomFieldBase";

export default class CustomCheckboxListField extends CustomFieldBase {
  constructor(field) {
    super(field);
    this.dataType = "value_text";
    this.type = "checkbox-field";
    this.prompt = "CHECKBOX";
    this.properties.options = field?.properties?.options || [];
    this.propertyComponents = ["options", "isVisible"];
  }
}
