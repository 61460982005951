import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// To-Do: Lazy load the translations files.
const commonEn = require("./locales/en/common.json");
const commonEs = require("./locales/es/common.json");
const commonPt = require("./locales/pt/common.json");

const notificationsEn = require("./locales/en/notifications.json");
const notificationsEs = require("./locales/es/notifications.json");
const notificationsPt = require("./locales/pt/notifications.json");

const messagesEn = require("./locales/en/messages.json");
const messagesEs = require("./locales/es/messages.json");
const messagesPt = require("./locales/pt/messages.json");

const guardianEn = require("./locales/en/guardian.json");
const guardianEs = require("./locales/es/guardian.json");
const guardianPt = require("./locales/pt/guardian.json");

const resources = {
  en: {
    common: commonEn,
    notifications: notificationsEn,
    messages: messagesEn,
    guardian: guardianEn,
  },
  es: {
    common: commonEs,
    notifications: notificationsEs,
    messages: messagesEs,
    guardian: guardianEs,
  },
  pt: {
    common: commonPt,
    notifications: notificationsPt,
    messages: messagesPt,
    guardian: guardianPt,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    supportedLngs: ["en", "es", "pt"],
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    detection: {
      lookupQuerystring: "locale",
    },
  });

export default i18n;
