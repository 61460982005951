import CustomCheckboxListField from "./CustomCheckboxListField";
import CustomDateField from "./CustomDateField";
import CustomDropdownField from "./CustomDropdownField";
import CustomNumberField from "./CustomNumberField";
import CustomRadioGroupField from "./CustomRadioGroupField";
import CustomTextField from "./CustomTextField";

const customFieldsModelMap = {
  "short-text": CustomTextField,
  "number-field": CustomNumberField,
  "dropdown-list": CustomDropdownField,
  "radio-button-list": CustomRadioGroupField,
  "checkbox-field": CustomCheckboxListField,
  "date-field": CustomDateField,
};

export default customFieldsModelMap;
