const entryScreenContainer = {
  maxWidth: 645,
  marginLeft: "auto",
  marginRight: "auto",
};

const entryScreenLogo = { width: "100%" };

const entryScreenBox = {
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
};

const entryScreenCheckbox = {
  color: "rgba(245, 243, 240, 0.9)",
  "&.Mui-checked": {
    color: "rgba(245, 243, 240, 0.9)",
  },
};

const entryScreenCheckboxContainer = { textAlign: "center", marginTop: "20px" };

export {
  entryScreenContainer,
  entryScreenLogo,
  entryScreenBox,
  entryScreenCheckbox,
  entryScreenCheckboxContainer,
};
