const assignmentDetailCellSmall = {
  width: "20px",
};
const assignmentDetailLast = {
  "& td": {
    borderBottom: "none",
  },
};

const assignmentDetailCellName = {
  width: "200px",
  px: 0,
  "& p": {
    fontSize: "20px",
    color: "black",
  },
};

const assignmentDetailScore = {
  borderLeft: "1px solid black",
  borderRight: "1px solid black",
  width: "130px",
  px: "10px",
};

const assignmentDetailScoreInput = {
  backgroundColor: "#EBF0F1",
  "& fieldset": { border: "none" },
};

const assignmentDetailCellCheckBox = {
  width: "75px",
};

const assignmentDetailGoogleScore = { width: "75px" };

const assignmentDetailTurnedIn = {
  width: "55px",
};

export {
  assignmentDetailCellName,
  assignmentDetailCellSmall,
  assignmentDetailLast,
  assignmentDetailScore,
  assignmentDetailScoreInput,
  assignmentDetailCellCheckBox,
  assignmentDetailGoogleScore,
  assignmentDetailTurnedIn,
};
