import {
  Box,
  Checkbox,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PreviewIcon from "@mui/icons-material/Preview";
import {
  reportCardTableRowBodyCell,
  reportCardTableRowCellGradeLevel,
  reportCardTableRowCellName,
  reportCardTableRowCellPublished,
  reportCardTableRowCellSquare,
} from "./ReportCardRow.style";
import {
  reportCardCheckboxCell,
  reportCardWithdrawnRow,
} from "./ReportCardTable.styles";
import PrekGradePostedIcon from "../PrekGradePostedIcon";
import PrekGradeNotPostedIcon from "../PrekGradeNotPostedIcon";
import moment from "../../utils/constants/momentConfig";
import dateTimeFormats from "../../utils/constants/dateTimeFormats";

export default function ReportCardPrekRow({
  student,
  isCheck,
  setIsCheck,
  handleDrawerOpen,
  setPrekDrawer,
  setSelectedPrekStudent,
  setSelectedPrekStudentDomain,
  selectedTerm,
  handleOpenPreview,
}) {
  const [isEnrolled, setIsEnrolled] = useState(false);

  const handleCheckbox = (e, id) => {
    const { checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  const publishedAtTitle = (publishedAt) =>
    `Published ${moment(publishedAt).format(dateTimeFormats.LLL)}`;

  useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    setIsEnrolled(student.is_enrolled_for_current_term);
  });

  return (
    <TableRow sx={reportCardWithdrawnRow(isEnrolled)}>
      <TableCell sx={reportCardCheckboxCell(isEnrolled)} id={student.id}>
        <Box sx={{ maxWidth: "60px" }}>
          <Checkbox
            checked={isCheck.includes(student.id)}
            onChange={(e) => handleCheckbox(e, student.id)}
          />
        </Box>
      </TableCell>
      <TableCell sx={reportCardTableRowCellName(isEnrolled)} align="left">
        <Box sx={{ width: "200px", marginLeft: 2 }}>
          {`${student.last_name}, ${student.first_name}`}
        </Box>
      </TableCell>
      <TableCell
        sx={reportCardTableRowCellGradeLevel(isEnrolled)}
        align="center"
      >
        <Box sx={{ width: "100px" }}>
          <Typography> {student.grade_level} </Typography>
        </Box>
      </TableCell>
      <TableCell
        sx={reportCardTableRowCellPublished(isEnrolled)}
        align="center"
      >
        {student.report_cards.length > 0 && (
          <Box>
            {student.report_cards.find((rc) => rc.term_id === selectedTerm.id)
              ?.is_published && (
              <Tooltip
                title={publishedAtTitle(
                  student.report_cards.find(
                    (rc) => rc.term_id === selectedTerm.id
                  )?.published_at
                )}
                arrow
              >
                <CheckCircleIcon color="success" />
              </Tooltip>
            )}
          </Box>
        )}
      </TableCell>
      {student.students_prek_domains.map((domain) => (
        <TableCell
          key={domain.id}
          sx={reportCardTableRowCellSquare}
          align="center"
        >
          <Box
            onClick={() => {
              setSelectedPrekStudent(student);
              setSelectedPrekStudentDomain(domain);
              handleDrawerOpen(null, null, null);
              setPrekDrawer(true);
            }}
            sx={reportCardTableRowBodyCell}
            className={domain.posted_at ? "posted" : "not-posted"}
          >
            {domain.posted_at ? (
              <PrekGradePostedIcon />
            ) : (
              <PrekGradeNotPostedIcon />
            )}
          </Box>
        </TableCell>
      ))}
      <TableCell sx={reportCardTableRowCellSquare} align="center">
        <Box
          sx={reportCardTableRowBodyCell}
          onClick={() => handleOpenPreview(student.id)}
        >
          <Tooltip title="Preview" arrow>
            <PreviewIcon color="primary" />
          </Tooltip>
        </Box>
      </TableCell>
    </TableRow>
  );
}
