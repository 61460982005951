import React from "react";
import { useFormContext } from "react-hook-form";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { guardianNotification } from "./NotificationCheckmarkSelect.styles";
import { font12, m0, mx5 } from "../../../../../sharedStyles";

function NotificationCheckmarkSelect({ label, currentContact }) {
  const { register } = useFormContext();

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Typography sx={guardianNotification}>{label}</Typography>
      <Stack direction="row">
        <FormGroup sx={mx5}>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <Typography align="center" sx={font12}>
                EMAIL
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                sx={m0}
                control={
                  <Checkbox
                    defaultChecked={currentContact?.email_notifications}
                    {...register("email_notifications")}
                  />
                }
              />
            </Grid>
          </Grid>
        </FormGroup>

        <FormGroup sx={mx5}>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <Typography align="center" sx={font12}>
                TEXT
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                sx={m0}
                control={
                  <Checkbox
                    defaultChecked={currentContact?.text_notifications}
                    {...register("text_notifications")}
                  />
                }
              />
            </Grid>
          </Grid>
        </FormGroup>
      </Stack>
    </Stack>
  );
}

export default NotificationCheckmarkSelect;
