import React, { useState, useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Stack,
  TextField,
  Autocomplete,
  Chip,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useSearchParams } from "react-router-dom";
import { filterButton } from "./Filters.styles";
import { cursorPointer, px10 } from "../../../sharedStyles";

export default function ColumnFilters({ tableColumns }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [filterBy, setFilterBy] = useState(
    searchParams.get("filterBy") || null
  );
  const [operator, setOperator] = useState(
    searchParams.get("operator") || null
  );
  const [value, setValue] = useState(searchParams.get("value") || "");
  const [isAnyOfValues, setIsAnyOfValues] = useState(
    searchParams.get("value")?.split(",") || []
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const timeout = useRef();

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClear = () => {
    setAnchorEl(null);
    setFilterBy(null);
    setOperator(null);
    setValue(null);
    searchParams.delete("filterBy");
    searchParams.delete("operator");
    searchParams.delete("value");
    searchParams.set("filtersCleared", true);
    setSearchParams(searchParams);
  };

  const handleFilterChange = (e) => {
    setFilterBy(e.target.value);
    searchParams.set("filterBy", e.target.value);
    setSearchParams(searchParams);
  };

  const handleOperatorChange = (e) => {
    if (e.target.value === "is-empty" || e.target.value === "is-not-empty") {
      setValue("");
      searchParams.set("value", "");
    }

    setOperator(e.target.value);
    searchParams.set("operator", e.target.value);
    setSearchParams(searchParams);
  };

  const handleValueChange = (e) => {
    clearTimeout(timeout.current);
    setValue(e.target.value);

    timeout.current = setTimeout(async () => {
      searchParams.set("value", e.target.value);
      setSearchParams(searchParams);
    }, 500);
  };

  const handleAnyOfValueChange = (values) => {
    setIsAnyOfValues(values);
    searchParams.set("value", values);
    setSearchParams(searchParams);
  };

  return (
    <>
      <Button
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant="text"
        sx={filterButton}
        startIcon={<FilterListIcon />}
      >
        Filters
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Stack direction="row" alignItems="center" sx={px10}>
          <CloseIcon onClick={handleClear} sx={cursorPointer} />
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel shrink>Columns</InputLabel>
            <Select
              label="columns"
              onChange={(e) => handleFilterChange(e)}
              value={filterBy}
            >
              {tableColumns.map((column) => (
                <MenuItem key={column.id} value={column.id}>
                  {column.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel shrink>Operator</InputLabel>
            <Select
              label="operator"
              onChange={(e) => handleOperatorChange(e)}
              value={operator}
            >
              <MenuItem value="contains">contains</MenuItem>
              <MenuItem value="equals">equals</MenuItem>
              <MenuItem value="is-empty">is empty</MenuItem>
              <MenuItem value="is-not-empty">is not empty</MenuItem>
              <MenuItem value="is-any-of">is any of</MenuItem>
            </Select>
          </FormControl>
          {searchParams.get("operator") !== "is-empty" &&
            searchParams.get("operator") !== "is-not-empty" && (
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                {searchParams.get("operator") === "is-any-of" ? (
                  <Autocomplete
                    sx={{ minWidth: "200px" }}
                    multiple
                    options={[]}
                    defaultValue={isAnyOfValues}
                    freeSolo
                    onChange={(e, values) => handleAnyOfValueChange(values)}
                    renderTags={(values, getTagProps) =>
                      values.map((option, index) => (
                        <Chip
                          /* eslint-disable-next-line react/no-array-index-key */
                          key={index}
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Value"
                        placeholder="Search for multiple values"
                      />
                    )}
                  />
                ) : (
                  <>
                    <InputLabel shrink>Value</InputLabel>
                    <TextField
                      variant="standard"
                      sx={{ mt: "16px" }}
                      onChange={(e) => handleValueChange(e)}
                      value={value}
                    />
                  </>
                )}
              </FormControl>
            )}
        </Stack>
      </Menu>
    </>
  );
}
