import React from "react";
import { Grid, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import AddressSection from "./AddressSection";
import ErasableTextField from "../../../ErasableTextField";
import { renderError } from "../../../../utils/constants/forms";
import { formatPhoneNumber } from "../../../../utils/constants/utils";

function ContactInformation({ teacher, managePermission }) {
  const { formState, setValue, trigger } = useFormContext();
  const { errors } = formState;

  const handlePhoneNumberChange = (control, event) => {
    setValue(control, formatPhoneNumber(event.target.value));
    trigger(control);
  };

  return (
    <Grid container item>
      <Grid item sm={11} my={4}>
        <Typography variant="profileTitle">Contact Information</Typography>
      </Grid>
      <Grid container item rowSpacing={5} pl={3}>
        <Grid
          container
          item
          sm={12}
          xs={12}
          md={12}
          spacing={2}
          paddingRight={3}
        >
          <Grid item sm={6}>
            <ErasableTextField
              id="school_email"
              label="School Email"
              type="email"
              hasError={errors?.school_email}
              defaultValue={teacher.school_email}
              fullWidth
              disabled={!managePermission}
            />
          </Grid>
          <Grid item sm={6}>
            <ErasableTextField
              id="personal_email"
              type="email"
              label="Personal Email"
              defaultValue={teacher.personal_email}
              fullWidth
              disabled={!managePermission}
            />
          </Grid>
          <Grid item sm={6}>
            <ErasableTextField
              id="mobile_phone"
              label="Mobile Phone"
              defaultValue={teacher.mobile_phone}
              onChange={(e) => {
                handlePhoneNumberChange("mobile_phone", e);
              }}
              hasError={errors?.mobile_phone}
              pattern={/^\(\d{3}\) \d{3}-\d{4}$/}
              eraseCallback={() => {
                trigger("mobile_phone");
              }}
              fullWidth
              disabled={!managePermission}
            />
            {errors?.mobile_phone && renderError(errors?.mobile_phone.message)}
          </Grid>
          <Grid item sm={6}>
            <ErasableTextField
              id="work_phone"
              label="Work Phone"
              defaultValue={teacher.work_phone}
              onChange={(e) => {
                handlePhoneNumberChange("work_phone", e);
              }}
              hasError={errors?.work_phone}
              pattern={/^\(\d{3}\) \d{3}-\d{4}$/}
              eraseCallback={() => {
                trigger("work_phone");
              }}
              fullWidth
              disabled={!managePermission}
            />
            {errors?.work_phone && renderError(errors?.work_phone.message)}
          </Grid>
          <Grid item sm={6}>
            <ErasableTextField
              id="home_phone"
              label="Home Phone"
              defaultValue={teacher.home_phone}
              onChange={(e) => {
                handlePhoneNumberChange("home_phone", e);
              }}
              hasError={errors.home_phone}
              pattern={/^\(\d{3}\) \d{3}-\d{4}$/}
              eraseCallback={() => {
                trigger("home_phone");
              }}
              fullWidth
              disabled={!managePermission}
            />
            {errors?.home_phone && renderError(errors?.home_phone.message)}
          </Grid>
        </Grid>
        <Grid container item sm={12} xs={12} md={12} spacing={2}>
          {true && (
            <AddressSection teacher={teacher} disabled={!managePermission} />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ContactInformation;
