import React from "react";
import { Grid, TextField, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

export default function CharacterLimit({ fieldProp }) {
  const { control } = useFormContext();
  return (
    <Grid container mb={2}>
      <Grid item md={9} display="flex" alignItems="center">
        <Typography ml={2}>Character Limit</Typography>
      </Grid>
      <Grid item md={3}>
        <Controller
          name="properties.maxLength"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              defaultValue={fieldProp.properties.maxLength}
              size="small"
              {...field}
              fullWidth
            />
          )}
        />
      </Grid>
    </Grid>
  );
}
