// Local storage
const getItemFromStore = (key, defaultValue, store = localStorage) => {
  try {
    return JSON.parse(store.getItem(key)) || defaultValue;
  } catch (e) {
    localStorage.removeItem(key);
    return false;
  }
};

const setItemToStore = (key, payload, store = localStorage) =>
  store.setItem(key, JSON.stringify(payload));

// Token handling
export const tokenKey = "tk";
export const gTKey = "gTKey";
const rememberMeToken = "rmbTk";
export const storedToken = getItemFromStore(tokenKey);
export const getRememberMeToken = getItemFromStore(rememberMeToken);
export const removeRememberMeToken = () =>
  localStorage.removeItem(rememberMeToken);

export const setToken = (newToken) => {
  setItemToStore(tokenKey, newToken);
};

export const removeToken = () => {
  localStorage.removeItem(tokenKey);
  localStorage.removeItem("current_school_slug");
};

export const parseJwt = (token) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const setGoogleToken = (newToken) => {
  setItemToStore(gTKey, newToken);
};

export const storedGToken = getItemFromStore(gTKey);

export const setRememberMeToken = (newToken) => {
  setItemToStore(rememberMeToken, newToken);
};
