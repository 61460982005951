import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import React from "react";

export default function CheckboxField({ field }) {
  return (
    <Box>
      <FormControl fullWidth required={field.properties.isRequired}>
        <FormControlLabel
          control={
            <Checkbox
              required={field.properties.required}
              disabled
              defaultChecked={field.properties.defaultChecked}
            />
          }
          label={
            (field.label?.length || 0) / field.w > 17
              ? `${field.label.slice(0, 200)}...`
              : field.label
          }
          defaultChecked={field.properties.defaultChecked}
        />
        <FormHelperText>{field.properties.supportingText}</FormHelperText>
      </FormControl>
    </Box>
  );
}
