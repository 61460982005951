export const sortSectionsAndFields = (sections) =>
  sections
    .sort((a, b) => a.order - b.order)
    .map((section) => {
      // eslint-disable-next-line no-param-reassign
      section.form_fields = section.form_fields.sort((a, b) => {
        // First, sort by 'y' value (column number)
        if (a.y !== b.y) {
          return a.y - b.y;
        }
        // If 'y' values are equal, sort by 'x' value (row number)
        return a.x - b.x;
      });
      return section;
    });

export const admissionFormUnsavedKey = "admissionFormUnsaved";
