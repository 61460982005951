import React from "react";
import { Accordion, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import theme from "../../utils";

const messageAccordion = {
  borderRadius: "3%",
  "&.MuiAccordion-root": {
    backgroundColor: theme.palette.background.default,
  },
  "&.MuiAccordion-gutters": {
    marginBottom: "2%",
  },
};

const messageImageTypography = { width: "33%", flexShrink: 0 };

const messageAccountCircle = { fontSize: 45, verticalAlign: "middle" };

const messageSenderTypography = { alignSelf: "center" };

const messageTypography = { fontSize: "14px" };

export default function Message(props) {
  const originalMessage =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget";
  const [message, setMessage] = React.useState(
    originalMessage.substring(0, 40)
  );

  const handleChange = (_event, expanded) => {
    if (expanded) {
      setMessage(originalMessage);
    } else {
      setMessage(message.substring(0, 40));
    }
  };

  return (
    <Accordion {...props} onChange={handleChange} sx={messageAccordion}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={messageImageTypography}>
          <AccountCircleIcon sx={messageAccountCircle} />
        </Typography>
        <Typography sx={messageSenderTypography}>
          <b>Rebeca Parks</b> <br />
          <Typography sx={messageTypography}>{message}</Typography>
        </Typography>
      </AccordionSummary>
    </Accordion>
  );
}
