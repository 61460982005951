import React, { createContext, useContext, useMemo, useState } from "react";

const SubmittingContext = createContext({
  submitting: false,
  setSubmitting: null,
});

export function SubmittingProvider({ children }) {
  const [submitting, setSubmitting] = useState(false);
  const value = useMemo(() => ({ submitting, setSubmitting }), [submitting]);
  return (
    <SubmittingContext.Provider value={value}>
      {children}
    </SubmittingContext.Provider>
  );
}

export function useSubmitting() {
  const context = useContext(SubmittingContext);
  if (!context) {
    throw new Error("useSubmitting must be used within SubmittingProvider");
  }
  return context;
}
