import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { alignIcon } from "./DayTemplatesForm.styles";
import { requiredMessage, renderError } from "../../../utils/constants/forms";

export default function Periods({
  index,
  nestedIndex,
  periodValue,
  remove,
  errors,
  validationError,
}) {
  const [range, setRange] = useState("___ minutes");
  const { register, control, getValues, setValue } = useFormContext({});
  const startTimeFormName = `tracks[${index}].periods[${nestedIndex}].start_time`;
  const endTimeFormName = `tracks[${index}].periods[${nestedIndex}].end_time`;

  const getRange = () => {
    const startTime = getValues(startTimeFormName);
    const endTime = getValues(endTimeFormName);
    if (
      startTime !== undefined &&
      startTime !== null &&
      endTime !== undefined &&
      endTime !== null &&
      moment(endTime).isAfter(moment(startTime))
    ) {
      setRange(`${moment(endTime).diff(moment(startTime), "minutes")} minutes`);
    } else {
      setRange("___ minutes");
    }
  };

  const handleInput = (event) => {
    event.target.value.toUpperCase();
  };

  const displayError = (formField) => {
    const errorInRequiredFields =
      errors?.tracks?.[`${index}`]?.periods?.[`${nestedIndex}`]?.[formField];

    const fieldIndex = Array.isArray(validationError)
      ? validationError?.findIndex((error) => error?.model === "period")
      : [0];
    const errorInModelValidation =
      validationError[fieldIndex]?.model === "period";
    const errorInTrack =
      validationError[fieldIndex]?.object?.track ===
      getValues(`tracks[${index}].name`);
    if (errorInRequiredFields) {
      return renderError(errorInRequiredFields?.message || requiredMessage);
    }
    if (
      errorInModelValidation &&
      errorInTrack &&
      validationError[fieldIndex]?.object?.name ===
        getValues(`tracks[${index}].periods[${nestedIndex}].name`)
    ) {
      return renderError(validationError[fieldIndex]?.errors?.[formField]);
    }
    return "";
  };

  useEffect(() => {
    const startTime = getValues(startTimeFormName);
    const endTime = getValues(endTimeFormName);
    setValue(startTimeFormName, startTime.slice(0, -1));
    setValue(endTimeFormName, endTime.slice(0, -1));
    getRange();
  }, []);

  return (
    <Box>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={3} mx={0}>
          <TextField
            label="Period*"
            {...register(`tracks[${index}].periods[${nestedIndex}].name`, {
              required: requiredMessage,
            })}
            error={
              !!errors?.tracks?.[`${index}`]?.periods?.[`${nestedIndex}`]?.name
            }
            fullWidth
            autoComplete="off"
            defaultValue={periodValue?.name}
          />
          {displayError("name")}
        </Grid>
        <Grid item xs={3}>
          <Controller
            name={`tracks[${index}].periods[${nestedIndex}].start_time`}
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <TimePicker
                label="Start*"
                id={`tracks[${index}].periods[${nestedIndex}].start_time`}
                value={value}
                required
                onChange={(e) => {
                  onChange(e);
                  getRange();
                }}
                disableOpenPicker
                renderInput={(params) => (
                  <>
                    <TextField
                      {...params}
                      error={
                        !!errors?.tracks?.[`${index}`]?.periods?.[
                          `${nestedIndex}`
                        ]?.start_time
                      }
                      helperText={
                        errors?.tracks?.[`${index}`]?.periods?.[
                          `${nestedIndex}`
                        ]?.start_time?.message ?? ""
                      }
                      sx={{ maxWidth: "250px" }}
                      onChange={handleInput}
                      autoComplete="off"
                    />
                    {errors?.tracks?.[`${index}`]?.periods?.[`${nestedIndex}`]
                      ?.start_time && renderError(requiredMessage)}
                  </>
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            name={`tracks[${index}].periods[${nestedIndex}].end_time`}
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <TimePicker
                label="End*"
                id={`tracks[${index}].periods[${nestedIndex}].end_time`}
                value={value}
                required
                minTime={moment(
                  getValues(
                    `tracks[${index}].periods[${nestedIndex}].start_time`
                  )
                )}
                onChange={(e) => {
                  onChange(e);
                  getRange();
                }}
                disableOpenPicker
                renderInput={(params) => (
                  <>
                    <TextField
                      {...params}
                      error={
                        !!errors?.tracks?.[`${index}`]?.periods?.[
                          `${nestedIndex}`
                        ]?.end_time
                      }
                      sx={{ maxWidth: "250px" }}
                      autoComplete="off"
                    />
                    {displayError("end_time")}
                  </>
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={2} sx={alignIcon}>
          <Typography>{range}</Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            size="large"
            disabled={
              getValues(
                `tracks[${index}].periods[${nestedIndex}].klass_schedule_details.length`
              ) > 0
            }
            onClick={() => {
              remove(nestedIndex);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Box my={2}>
        <Divider />
      </Box>
    </Box>
  );
}
