import React from "react";
import { Route } from "react-router-dom";
import StudentPortal from "../views/StudentPortal";
import StudentPortalInfo from "../components/StudentPortal/Info";
import StudentPortalHome from "../components/StudentPortal/Home/Home";
import StudentPortalSchedule from "../components/StudentPortal/Schedule/Schedule";
import StudentPortalClasses from "../components/StudentPortal/Classes/Classes";
import StudentPortalMessages from "../components/StudentPortal/Messages/Messages";
import StudentPortalClassDetails from "../components/StudentPortal/ClassDetails/ClassDetails";
import StudentPortalConversationMessages from "../components/StudentPortal/Messages/Conversation";
import Notifications from "../views/Notifications";
import NotificationsList from "../components/Notifications/List";
import NotificationDetails from "../components/Notifications/Details";

export default [
  <Route path="/student/:id" element={<StudentPortal />} key={0}>
    <Route
      path="/student/:id/schools/:school_id"
      element={<StudentPortalInfo />}
      key={1}
    >
      <Route
        path="/student/:id/schools/:school_id/home"
        element={<StudentPortalHome />}
        key={2}
      />
      <Route
        path="/student/:id/schools/:school_id/schedule"
        element={<StudentPortalSchedule />}
        key={3}
      />
      <Route
        path="/student/:id/schools/:school_id/classes"
        element={<StudentPortalClasses />}
        key={4}
      />
      <Route
        path="/student/:id/schools/:school_id/messages"
        element={<StudentPortalMessages />}
        key={5}
      />
      <Route
        path="/student/:id/schools/:school_id/classes/:class_id"
        element={<StudentPortalClassDetails />}
        key={6}
      />
      <Route
        path="/student/:id/schools/:school_id/messages/:conversation_key"
        element={<StudentPortalConversationMessages />}
        key={7}
      />
      <Route
        path="/student/:id/schools/:school_id/notifications"
        element={<Notifications />}
        key={8}
      >
        <Route index element={<NotificationsList />} key={8.1} />

        <Route
          path="/student/:id/schools/:school_id/notifications/:notification_id/details"
          element={<NotificationDetails />}
          key={8.2}
        />
      </Route>
    </Route>
  </Route>,
];
