import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { renderError } from "../../../../../utils/constants/forms";

export default function RadioGroupFieldRenderer({
  field,
  properties,
  defaultValue,
}) {
  const {
    control,
    setValue,
    formState: { errors },
    trigger,
  } = useFormContext();

  return (
    <Box padding={1}>
      <FormControl
        sx={{ minWidth: "fit-content" }}
        fullWidth
        error={Boolean(
          errors.custom_field_responses &&
            errors.custom_field_responses[`${field.field_id}`]
        )}
      >
        <FormLabel required={properties.isRequired}>{field.label}</FormLabel>
        <Controller
          name={`custom_field_responses.${field.field_id}`}
          defaultValue={defaultValue}
          control={control}
          rules={{
            required: properties.isRequired && "This field is required",
          }}
          render={({ field1 }) => (
            <RadioGroup
              {...field1}
              onChange={(event, value) => {
                setValue(`custom_field_responses.${field.field_id}`, value, {
                  shouldDirty: true,
                });
                trigger(`custom_field_responses.${field.field_id}`);
              }}
              defaultValue={defaultValue}
            >
              {properties.options.map((option) => (
                <FormControlLabel
                  value={option.value}
                  control={<Radio />}
                  sx={{ alignItems: "start" }}
                  label={
                    <Box mt={1}>
                      <Typography>{option.value}</Typography>
                    </Box>
                  }
                />
              ))}
            </RadioGroup>
          )}
        />
      </FormControl>
      {errors.custom_field_responses &&
        errors.custom_field_responses[`${field.field_id}`] &&
        renderError(errors.custom_field_responses[`${field.field_id}`].message)}
    </Box>
  );
}
