import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useOutletContext } from "react-router-dom";
import { SimpleCard } from "../index";
import { font22, ml5, mt70 } from "../sharedStyles";
import reportCardsService from "../../service/reportCardsService";
import teacherService from "../../service/staffsService";
import {
  reportCardDashboardCardsContainer,
  reportCardDashboardGradeCell,
  reportCardDashboardTableCell,
  reportCardDashboardTableHeader,
} from "./ReportCardDashboard.styles";
import specialMarkService from "../../service/specialMarkService";
import formattedGrade from "../../utils/formattedGrade";
import ReportCardDashboardTableHeader from "./ReportCardDashboardTableHeader";
import ReportCardDashboardTableRow from "./ReportCardDashboardTableRow";
import { reportCardSelectTermBox } from "./ReportCardTable.styles";
import StaffType from "../../utils/constants/staffTypeEnum";

export default function ReportCardDashboard() {
  const [schoolId, selectedTerm] = useOutletContext();

  const [students, setStudents] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [termGrades, setTermGrades] = useState([]);
  const [publishedStudents, setPublishedStudents] = useState([]);
  const [distributedStudents, setDistributedStudents] = useState([]);
  const [studentsMissingSkills, setStudentsMissingSkills] = useState([]);
  const [socialDevelopment, setSocialDevelopment] = useState({});
  const [specialMarks, setSpecialMarks] = useState([]);

  const unpostedGrades = Object.values(termGrades)
    .map((termGradeStudent) =>
      Object.values(termGradeStudent)
        .map((termGrade) => {
          if (!termGrade.posted_grade) {
            return termGrade;
          }

          return null;
        })
        .filter((termGrade) => termGrade !== null)
    )
    .flat();

  const getStudentGradingScale = (student) => {
    if (
      student.grade_level.toUpperCase() === "TK" ||
      student.grade_level.toUpperCase() === "K"
    ) {
      return "TK-K";
    }
    if (Number(student.grade_level) <= 2 && Number(student.grade_level) >= 1) {
      return "1-2";
    }
    if (Number(student.grade_level) <= 8 && Number(student.grade_level) >= 3) {
      return "3-8";
    }
    return "";
  };

  let studentsMissingSocialDevelopment = [];

  students.forEach((student) => {
    const studentSocialDevelopmentScores = socialDevelopment.scores_by_student;
    const socialDevelopmentSkills =
      socialDevelopment && socialDevelopment.skills_by_grading_scale
        ? socialDevelopment.skills_by_grading_scale[
            getStudentGradingScale(student)
          ]
        : false;

    if (!socialDevelopmentSkills || !studentSocialDevelopmentScores) {
      return studentsMissingSocialDevelopment.push(student);
    }

    Object.values(socialDevelopmentSkills).forEach((sds) => {
      if (!studentSocialDevelopmentScores?.[student.id]?.[sds.id]?.posted_at) {
        return studentsMissingSocialDevelopment.push(student);
      }

      return null;
    });

    return null;
  });

  studentsMissingSocialDevelopment = studentsMissingSocialDevelopment.filter(
    (student, index, self) =>
      student !== null && index === self.findIndex((s) => s.id === student.id)
  );

  const getStudents = async () => {
    if (selectedTerm !== null && selectedTerm.id) {
      const response = await reportCardsService.fetchDashboard({
        params: {
          school_id: schoolId,
          term_id: selectedTerm.id,
        },
      });

      if (response.data) {
        setStudents(response.data.all_students);
        setPublishedStudents(response.data.published);
        setDistributedStudents(response.data.distributed);
        setStudentsMissingSkills(response.data.missing_skills);
      }
    }
  };

  const teachersByID = teachers
    .map((teacher) => ({ [teacher.id]: teacher }))
    .reduce((acc, teacher) => {
      const teacherID = Object.keys(teacher)[0];
      acc[teacherID] = teacher[teacherID];
      return acc;
    }, {});

  const getTeachers = async () => {
    if (selectedTerm !== null && selectedTerm.id) {
      const response = await teacherService.fetchAllStaffs({
        params: { school_id: schoolId, staff_type: StaffType.TEACHER },
      });

      if (response) {
        setTeachers(response);
      }
    }
  };

  const getSocialDevelopment = async () => {
    if (selectedTerm !== null && selectedTerm.id) {
      const response = await reportCardsService.fetchSocialDevelopment({
        school_id: schoolId,
        term_id: selectedTerm.id,
      });

      if (response.data) {
        setSocialDevelopment(response.data);
      }
    }
  };

  const getTermGrades = async () => {
    if (selectedTerm !== null && selectedTerm.id) {
      const response = await reportCardsService.fetchTermGrades({
        params: {
          school_id: schoolId,
          term_id: selectedTerm.id,
          prek: false,
        },
      });

      if (response.data) {
        setTermGrades(response.data);
      }
    }
  };

  useEffect(() => {
    (async () => {
      await getStudents();
      await getTermGrades();
      await getSocialDevelopment();
      await getTeachers();
    })();
  }, [selectedTerm]);

  useEffect(() => {
    (async () => {
      const response = await specialMarkService.fetchSchoolSpecialMarks({
        params: {
          school_id: schoolId,
        },
      });
      if (response.data) {
        setSpecialMarks(response.data.special_marks);
      }
    })();
  }, []);

  const gradeCell = (grade) => {
    if (grade.calculated_grade) {
      const letterGrade = formattedGrade(
        grade.calculated_grade,
        100,
        grade?.klass?.grading_scale?.marking_codes,
        specialMarks
      );

      return letterGrade;
    }
    return "-";
  };

  if (selectedTerm === null || !selectedTerm.id) {
    return (
      <Box sx={reportCardSelectTermBox}>
        <Typography sx={font22}>
          Please select a term above to begin reviewing report cards.
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Box sx={reportCardDashboardCardsContainer}>
        <SimpleCard
          horizontal
          marginless
          compact
          title={
            <Typography variant="h4">
              PUBLISHED <br /> REPORT CARDS
            </Typography>
          }
          content={
            <Typography variant="h1">
              {publishedStudents.length}/{students.length}
            </Typography>
          }
        />
        <SimpleCard
          horizontal
          marginless
          compact
          title={
            <Typography variant="h4">
              DISTRIBUTED <br /> REPORT CARDS
            </Typography>
          }
          content={
            <Typography variant="h1">
              {distributedStudents.length}/{students.length}
            </Typography>
          }
        />
      </Box>

      <Box sx={mt70}>
        {unpostedGrades && unpostedGrades.length > 0 && (
          <>
            <Typography sx={reportCardDashboardTableHeader}>
              Unposted grades
            </Typography>
            <ReportCardDashboardTableHeader
              headers={[
                { title: "Name" },
                { title: "Class" },
                { title: "Teacher" },
                { title: "Current Grade", fullWidth: true },
              ]}
              sx={ml5}
            />
          </>
        )}
        {unpostedGrades &&
          unpostedGrades.length > 0 &&
          unpostedGrades.map((grade) => (
            <ReportCardDashboardTableRow
              key={`${grade.student.first_name}${grade.student.last_name}${grade.klass.name}`}
              sx={ml5}
            >
              <Grid item sx={reportCardDashboardTableCell}>
                <Typography>
                  {`${grade.student.last_name}, ${grade.student.first_name}`}
                </Typography>
              </Grid>
              <Grid item sx={reportCardDashboardTableCell}>
                <Typography>{grade.klass.name}</Typography>
              </Grid>
              <Grid item sx={reportCardDashboardTableCell}>
                <Typography>
                  {`${grade.klass.teacher.first_name} ${grade.klass.teacher.last_name}`}
                </Typography>
              </Grid>
              <Grid item sm sx={reportCardDashboardGradeCell}>
                <Box sx={{ width: "200px" }}>
                  <Typography>{gradeCell(grade)}</Typography>
                </Box>
              </Grid>
            </ReportCardDashboardTableRow>
          ))}
      </Box>
      <Box sx={mt70}>
        {studentsMissingSkills && studentsMissingSkills.length > 0 && (
          <>
            <Typography sx={reportCardDashboardTableHeader}>
              Missing Skills
            </Typography>
            <ReportCardDashboardTableHeader
              headers={[
                { title: "Name" },
                { title: "Teacher" },
                { title: "Subject" },
              ]}
            />
          </>
        )}
        {studentsMissingSkills &&
          studentsMissingSkills.length > 0 &&
          studentsMissingSkills.map((student) => (
            <ReportCardDashboardTableRow
              key={`${student.first_name}${student.last_name}${student.teacher.first_name}${student.teacher.last_name}${student.subject}`}
            >
              <Grid item sx={reportCardDashboardTableCell}>
                <Typography>
                  {`${student.last_name}, ${student.first_name}`}
                </Typography>
              </Grid>
              <Grid item sx={reportCardDashboardTableCell}>
                <Typography>
                  {`${student.teacher.first_name} ${student.teacher.last_name}`}
                </Typography>
              </Grid>
              <Grid item sx={reportCardDashboardTableCell}>
                <Typography>{student.subject}</Typography>
              </Grid>
            </ReportCardDashboardTableRow>
          ))}
      </Box>
      <Box sx={mt70}>
        {studentsMissingSocialDevelopment &&
          studentsMissingSocialDevelopment.length > 0 && (
            <>
              <Typography sx={reportCardDashboardTableHeader}>
                Missing Social Development/Work Ethic
              </Typography>
              <ReportCardDashboardTableHeader
                headers={[{ title: "Name" }, { title: "Teacher" }]}
              />
            </>
          )}
        {studentsMissingSocialDevelopment &&
          studentsMissingSocialDevelopment.length > 0 &&
          studentsMissingSocialDevelopment.map((student) => (
            <ReportCardDashboardTableRow
              key={`${student.first_name}${student.last_name}`}
            >
              <Grid item sx={reportCardDashboardTableCell}>
                <Typography>
                  {`${student.last_name}, ${student.first_name}`}
                </Typography>
              </Grid>
              <Grid item sx={reportCardDashboardTableCell}>
                <Typography>
                  {teachersByID[student.homeroom_teacher_id]
                    ? teachersByID[student.homeroom_teacher_id].first_name
                    : null}{" "}
                  {teachersByID[student.homeroom_teacher_id]
                    ? teachersByID[student.homeroom_teacher_id].last_name
                    : null}
                </Typography>
              </Grid>
            </ReportCardDashboardTableRow>
          ))}
      </Box>
    </>
  );
}
