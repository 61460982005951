export function format(strDate, options) {
  try {
    return new Intl.DateTimeFormat(options.language, {
      ...options,
    }).format(new Date(strDate));
  } catch (error) {
    console.error(error);
    return "Invalid Date";
  }
}

// Sample output: April 29, 2024 at 3:51 PM
export function longShortDateTime(strDate, options = {}) {
  return format(strDate, {
    ...options,
    dateStyle: "long",
    timeStyle: "short",
  });
}

// Sample output: 6/11/24, 7:04 PM
export function shortDateTime(strDate, options = {}) {
  return format(strDate, {
    ...options,
    dateStyle: "short",
    timeStyle: "short",
  });
}

// Sample output: Jun 29, 2024
export function mediumDate(strDate, options = {}) {
  return format(strDate, {
    ...options,
    dateStyle: "medium",
  });
}

// Sample output: Tuesday, Jun 11, 2024
export function weekLongDate(strDate, options = {}) {
  return format(strDate, {
    ...options,
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}

// Sample output: Wednesday, May 29
export function weekShortDate(strDate, options = {}) {
  return format(strDate, {
    ...options,
    weekday: "long",
    month: "short",
    day: "numeric",
  });
}

// Sample output: 8:59 PM
export function onlyTime(strDate, options = {}) {
  return format(strDate, {
    ...options,
    timeStyle: "short",
  });
}

export default {
  longShortDateTime,
  shortDateTime,
  mediumDate,
  weekLongDate,
  onlyTime,
};
