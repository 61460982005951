import React, { useContext } from "react";
import StarOutlineOutlinedIcon from "@mui/icons-material/StarOutlineOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Check } from "@mui/icons-material";
import { textCenter, cursorPointer } from "../../../sharedStyles";
import { tableStyles, primaryIcon } from "./EmergencyContacts.styles";
import { formatPhoneNumber } from "../../../../utils/constants/utils";
import { PermissionsContext } from "../../../../context/PermissionsContext";
import APP_PERMISSIONS from "../../../../utils/constants/permissions";
import PERMISSION_TYPES from "../../../../utils/constants/permission_types";

export default function EmergencyContactTable({
  emergencyContacts,
  guardians,
  handleTableClick,
  editGuardianContact,
}) {
  const { hasPermission } = useContext(PermissionsContext);

  const managePermission = hasPermission(
    APP_PERMISSIONS.STUDENT_ACCOUNT,
    PERMISSION_TYPES.MANAGE
  );
  return (
    <TableContainer>
      <Table sx={tableStyles} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="left">Relationship</TableCell>
            <TableCell align="left">Email</TableCell>
            <TableCell align="left">Phone Number</TableCell>
            <TableCell align="center">Emergency</TableCell>
            <TableCell align="center">Transport</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {guardians.map((guardian) => (
            <TableRow key={guardian.id} hover>
              <TableCell component="th" scope="guardian">
                <Typography variant="h6">
                  {guardian.first_name} {guardian.last_name}
                  {guardian.is_primary_emergency_contact && (
                    <StarOutlineOutlinedIcon sx={primaryIcon} />
                  )}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>{guardian.relationship}</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>{guardian.email}</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>
                  {formatPhoneNumber(guardian.cell_phone)}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
              <TableCell align="center">
                {guardian.authorized_pickup ? <Check /> : "-"}
              </TableCell>
              {managePermission && (
                <TableCell sx={textCenter}>
                  <SettingsOutlinedIcon
                    sx={cursorPointer}
                    onClick={() => editGuardianContact(guardian.id)}
                  />
                </TableCell>
              )}
            </TableRow>
          ))}
          {emergencyContacts.map((contact) => (
            <TableRow key={contact.id} hover>
              <TableCell
                component="th"
                scope="contact"
                sx={managePermission ? cursorPointer : {}}
                onClick={() => {
                  if (!managePermission) return;
                  handleTableClick(contact.id);
                }}
              >
                <Typography variant="h6">
                  {contact.first_name} {contact.last_name}
                  {contact.is_primary && (
                    <StarOutlineOutlinedIcon sx={primaryIcon} />
                  )}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>{contact.relationship}</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>{contact.email}</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>{formatPhoneNumber(contact.cell_phone)}</Typography>
              </TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
              <TableCell align="center">
                {contact.is_authorized_pickup ? <Check /> : "-"}
              </TableCell>
              {managePermission && (
                <TableCell sx={textCenter}>
                  <SettingsOutlinedIcon
                    sx={cursorPointer}
                    onClick={() => handleTableClick(contact.id)}
                  />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
