const Container = {
  borderTop: "1px solid black",
  height: "100%",
};

const gradingScaleName = {
  fontSize: "24px",
  color: "#79747E",
  mb: 3,
};

const codeFieldStyle = {
  width: "50px",
  mr: 2,
};

const valueFieldStyle = {
  width: "80px",
};

const boxStyle = {
  margin: "10px",
};

const minValueStyle = {
  fontSize: "12px",
};

const dividerStyle = { mt: 2, mb: 2, width: "150px", borderWidth: "1px" };

export {
  Container,
  gradingScaleName,
  codeFieldStyle,
  valueFieldStyle,
  boxStyle,
  minValueStyle,
  dividerStyle,
};
