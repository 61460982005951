const collectionLabelContainer = {
  mt: "25px",
  mb: "15px",
};

const collectionInputField = {
  width: "510px",
};

const collectionNameLabel = {
  width: "510px",
  fontSize: "11px",
};

const collectionItemsContainer = {
  width: "100px",
  textAlign: "center",
  textWrap: "nowrap",
};

const collectionSubmitBtn = {
  color: "#2196F3",
  textTransform: "none",
  fontSize: "15px",
};

const conllectionBtnContainer = {
  width: "510px",
};

export {
  collectionLabelContainer,
  collectionInputField,
  collectionNameLabel,
  collectionItemsContainer,
  collectionSubmitBtn,
  conllectionBtnContainer,
};
