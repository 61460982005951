import React from "react";
import { Route } from "react-router-dom";
import StaffInfoAccount from "../components/Staff/StaffInfo/Account/StaffInfoAccount";
import StaffInfo from "../views/StaffInfo";

export default [
  <Route path="/school/:school_id/staff/:id" element={<StaffInfo />} key={0}>
    <Route
      path="/school/:school_id/staff/:id/account"
      element={<StaffInfoAccount />}
      key={0}
    />
  </Route>,
];
