import React, { useState, useRef } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box, Stack, Button, Typography } from "@mui/material";
import {
  formHeader,
  formButtons,
  formTitle,
  formContainer,
} from "../../sharedStyles";
import Recipients from "./Recipients";
import Content from "./Content";
import Attachments from "./Attachments";
import messagesService from "../../../service/messagesService";

export default function New({
  handleMessagesDrawer,
  setSnackBarOpen,
  setReloadPage,
}) {
  const { t } = useTranslation(["messages", "common"]);
  const [recipientsValue, setRecipientsValue] = useState("");
  const [attachments, setAttachments] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [descriptionError, setDescriptionError] = useState(false);
  const [loading, setLoading] = useState(false);
  const editorRef = useRef(null);
  const methods = useForm();
  const newMessageFormHeader = formHeader;
  const newMessageFormTitle = formTitle;
  const newMessageFormButtons = formButtons;
  const maxFileSize = 20000000;

  function SizeException(message) {
    setSnackBarOpen({
      message,
      open: true,
      severity: "error",
    });
    setLoading(false);
    return {
      message,
      error: "sizeException",
    };
  }

  const handleFileUploads = (e) => {
    const files = [...uploadedFiles];
    Array.prototype.forEach.call(e.target.files, (file, index) => {
      const fileWithId = file;
      const fileId =
        uploadedFiles.length > 0 ? index + uploadedFiles.at(-1).id + 1 : index;
      fileWithId.id = fileId;
      files.push(fileWithId);
    });

    try {
      if (files) {
        setUploadedFiles(files);
      }
    } catch (err) {
      if (err.error === "sizeException") {
        setSnackBarOpen({
          message: err.message,
          open: true,
          severity: "error",
        });
      }
    }
  };

  const handleBodyValidation = (hasText) =>
    hasText ? setDescriptionError(false) : setDescriptionError(true);

  const onSubmit = async (data) => {
    if (!descriptionError) {
      setLoading(true);
      const formData = new FormData();

      const formParams = {
        ...data,
        school_id: recipientsValue[0].school_id,
        sender_type: "Guardian",
      };

      Object.keys(formParams).map((keyName) =>
        formData.append(keyName, formParams[keyName])
      );

      formData.append("recipients", JSON.stringify(recipientsValue));

      Array.prototype.forEach.call(uploadedFiles, (file, index) => {
        if (uploadedFiles.length > 10)
          throw new SizeException("Maximum of 10 files are permitted.");
        if (file.size > maxFileSize)
          throw new SizeException(`The file ${file.name} is bigger than 20Mb.`);
        formData.append(`attachments[]`, uploadedFiles[index]);
      });

      const response = await messagesService.createMessage(formData);

      if (response.data) {
        setSnackBarOpen({
          open: true,
          message: t("messageSent"),
        });

        handleMessagesDrawer(false);
        setLoading(false);
        setReloadPage(true);
      }
    } else {
      editorRef?.current?.focus();
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack direction="row" sx={newMessageFormHeader}>
          <Typography sx={newMessageFormTitle}>
            {t("newMessageTitle")}
          </Typography>
          <Stack direction="row">
            <Button
              sx={newMessageFormButtons}
              onClick={() => handleMessagesDrawer(false)}
            >
              {t("cancel", { ns: "common" })}
            </Button>
            <Button sx={newMessageFormButtons} type="submit" disabled={loading}>
              {loading ? t("sendingMessage") : t("send")}
            </Button>
          </Stack>
        </Stack>

        <Box sx={formContainer}>
          <Recipients setRecipientsValue={setRecipientsValue} />
          <Content
            handleBodyValidation={handleBodyValidation}
            descriptionError={descriptionError}
            editorRef={editorRef}
          />
          <Attachments
            handleFileUploads={handleFileUploads}
            attachments={attachments}
            setAttachments={setAttachments}
            uploadedFiles={uploadedFiles}
            setUploadedFiles={setUploadedFiles}
          />
        </Box>
      </form>
    </FormProvider>
  );
}
