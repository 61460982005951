import axios from "../utils/axios-util";

const attendanceEndpoint = "/api/attendance";

const getAttendanceMarkingCodes = async (params) => {
  const data = await axios.get(`${attendanceEndpoint}/codes`, params);
  return data.data;
};

const recordAttendance = async (attendance) => {
  await axios.post(`${attendanceEndpoint}`, attendance);
};

const getAttendance = async (date, params) => {
  const data = await axios.get(`${attendanceEndpoint}/${date}`, params);
  return data.data;
};

const quickfill = async (body) => {
  await axios.post(`${attendanceEndpoint}/quickfill`, body);
};

const getKlasses = async (params) => {
  const { data } = await axios.get(`${attendanceEndpoint}/classes`, params);
  return data;
};

export default {
  getAttendanceMarkingCodes,
  recordAttendance,
  getAttendance,
  quickfill,
  getKlasses,
};
