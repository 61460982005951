import { Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { cursorPointer } from "../../sharedStyles";
import { formatDateTime } from "../../../utils/constants/utils";
import messagesService from "../../../service/messagesService";

export default function ParentMessages({ parents }) {
  const [messages, setMessages] = useState([]);
  const schoolId = useParams().school_id;
  useEffect(() => {
    if (parents && parents.length)
      messagesService
        .recipientsMessages(parents.map((p) => p.id))
        .then((response) => {
          setMessages(response.data.filter((f) => f.conversation_key !== null));
        });
    else setMessages([]);
  }, [parents]);

  const guardianName = (recipientId) => {
    const parent = parents.find((p) => p.id === recipientId);
    return `${parent?.first_name} ${parent?.last_name}`;
  };

  return (
    <Grid item md={12} container mt={3}>
      {messages.map((message, index) => (
        <>
          <Grid
            item
            md={12}
            p={1}
            border="1px solid #F6F6F6"
            sx={cursorPointer}
            onClick={() =>
              window.open(
                `/school/${schoolId}/messages/conversation/${message.conversation_key}`,
                "_blank"
              )
            }
          >
            <Typography fontSize={14}>
              {guardianName(message.recipient_id)}
            </Typography>
            <Typography fontSize={14}>
              <strong>{message.subject}</strong>
            </Typography>
            <Typography fontSize={14}>
              <strong>Date:</strong>{" "}
              {formatDateTime(message?.recent_message?.created_at)}
            </Typography>
          </Grid>
          {index !== messages.length - 1 && (
            <Grid item container md={12}>
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  height: "1rem",
                  width: "1px",
                  ml: "0.85rem",
                }}
              />
            </Grid>
          )}
        </>
      ))}
    </Grid>
  );
}
