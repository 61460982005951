import React, { useState, useRef, useContext, useEffect } from "react";
import { TableCell, TextField } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import PopoverComponent from "../components/ToolTip/ToolTip";
import {
  socialDevelopmentRowCellGrade,
  socialDevelopmentSquare,
  socialDevelopmentInput,
} from "./SocialDevelopmentRow.styles";
import PartialValidCodes from "../components/ToolTip/PartialValidCodes";
import ValidInputCodes from "../components/ToolTip/ValidInputCodes";
import { SnackbarContext } from "../context/SnackbarContext";
import socialDevelopmentScoreService from "../service/socialDevelopmentScoreService";
import { isNumber } from "../utils/constants/utils";
import useFormattedMarkingCodes from "../hooks/useFormattedMarkingCodes";
import { PermissionsContext } from "../context/PermissionsContext";
import APP_PERMISSIONS from "../utils/constants/permissions";
import PERMISSION_TYPES from "../utils/constants/permission_types";
import GradeLockIcon from "../components/GradeLockIcon";

export default function SocialDevelopmentCell({
  skill,
  gradingScale,
  student,
  score,
  getSdScores,
  isReportCardPublished,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentTarget, setCurrentTarget] = useState(null);
  const [isBackspace, setBackspace] = useState(false);
  const [scoreValue, setScoreValue] = useState("");
  const [searchParams] = useSearchParams();
  const timeout = useRef();
  const snackbarContext = useContext(SnackbarContext);
  const termId = searchParams.get("term") || null;
  const { hasPermission } = useContext(PermissionsContext);
  const hasManagePermission =
    hasPermission(
      APP_PERMISSIONS.SOCIAL_DEVELOPMENT_WORK_ETHIC,
      PERMISSION_TYPES.MANAGE
    ) || hasPermission(APP_PERMISSIONS.ALL_STUDENTS, PERMISSION_TYPES.MANAGE);

  const isLetterGrade = gradingScale.name === "TK-K";
  const formattedMarkingCodes = useFormattedMarkingCodes(
    gradingScale?.marking_codes
  );
  const partialCode = PartialValidCodes(
    formattedMarkingCodes,
    null,
    isLetterGrade,
    false,
    true
  );
  const validCodes = ValidInputCodes(
    formattedMarkingCodes,
    null,
    isLetterGrade,
    false,
    true
  ).concat(partialCode);

  const shouldShowToolTip = (value) => {
    if (
      !isLetterGrade &&
      !validCodes.filter((a) => a === value.toLowerCase()).length > 0
    ) {
      return true;
    }

    if (
      isLetterGrade &&
      (isNumber(value) ||
        !validCodes.filter((a) => a === value.toLowerCase()).length > 0)
    ) {
      return true;
    }

    return false;
  };

  const handleChange = (target, _score) => {
    const cellTarget = target;
    setCurrentTarget(cellTarget);

    if (!isBackspace && shouldShowToolTip(_score)) {
      cellTarget.value = "";
      setAnchorEl(cellTarget);
    }

    setScoreValue(_score);
    if (!shouldShowToolTip(_score) || isBackspace) {
      clearTimeout(timeout.current);
      snackbarContext.setSnackbar({
        message: "Saving...",
        severity: "info",
        open: true,
      });

      timeout.current = setTimeout(async () => {
        const enteredPartialCode = partialCode.includes(_score.toLowerCase());

        if (enteredPartialCode) {
          setAnchorEl(cellTarget);
        } else {
          const postedScore = await socialDevelopmentScoreService
            .create({
              term_id: termId,
              student_id: student.id,
              social_development_skill_id: skill.id,
              score: _score,
            })
            .catch((err) =>
              snackbarContext.setSnackbar({
                message: `Value ${err.response.data.errors.score[0]}`,
                severity: "error",
                open: true,
              })
            );

          if (postedScore) {
            snackbarContext.setSnackbar({
              message: "Saved successfully",
              severity: "success",
              open: true,
            });
            const loadComments = false;
            getSdScores(loadComments);
          }
        }
      }, 2000);
    }
  };

  const handleTooltipInput = (input) => {
    currentTarget.value = input;
    setAnchorEl(null);
    handleChange(currentTarget, input.toString());
  };

  const handleKeyDown = (event) => {
    if (event.key === "Backspace") {
      setBackspace(true);
    }
  };

  useEffect(() => {
    setScoreValue(score || "");
  }, [score]);

  return (
    <TableCell key={skill} sx={socialDevelopmentRowCellGrade} align="center">
      <TextField
        variant="standard"
        fullWidth
        sx={socialDevelopmentSquare}
        disabled={!hasManagePermission || isReportCardPublished}
        inputProps={{
          min: 0,
          sx: {
            ...socialDevelopmentInput,
          },
          className: `grading_input_${gradingScale.id} skill_input_${skill.id}`,
          value: scoreValue,
        }}
        /* eslint-disable-next-line react/jsx-no-duplicate-props */
        InputProps={{
          sx: {
            ...socialDevelopmentInput,
          },
          disableUnderline: true,
          id: `${student.id}_${skill.id}`,
        }}
        onChange={(e) => handleChange(e.target, e.target.value)}
        onKeyDown={(e) => {
          handleKeyDown(e);
        }}
        onKeyUp={() => setBackspace(false)}
      />
      <PopoverComponent
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        handleClick={handleTooltipInput}
        currentTarget={currentTarget}
        showSpecialMarks={false}
        specialMarks={null}
        skill={null}
        markingCodes={gradingScale.marking_codes}
        gradingScale={gradingScale}
        render
        isLetterGrade
      />
      {isReportCardPublished && <GradeLockIcon />}
    </TableCell>
  );
}
