import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import PrintLayout from "../PrintLayoutComponents/PrintLayout";
import reportsService from "../../../service/reportsService";
import ParentContactsTable from "./ParentContactsTable";

export default function ParentContacts() {
  const [schoolName] = useOutletContext();
  const params = useParams();

  const [loading, setLoading] = useState(false);
  const [parentContacts, setParentContacts] = useState([]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      reportsService
        .parentContacts({
          school_id: params.school_id,
        })
        .then((response) => {
          setParentContacts(response);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    })();
  }, []);

  const generateBodyContent = () => {
    if (loading) {
      return (
        <Box display="flex" justifyContent="center">
          <Typography color="black" fontSize="20px" mt={5}>
            Loading...
          </Typography>
        </Box>
      );
    }

    if (parentContacts.length === 0) {
      return (
        <tr>
          <td>
            <Box textAlign="center" mt={3}>
              No emergency contacts found.
            </Box>
          </td>
        </tr>
      );
    }

    return (
      <tr className="report-data">
        <td
          className="report-content-cell"
          style={{ padding: "2rem 1rem 0 2rem" }}
        >
          <ParentContactsTable parentContacts={parentContacts} />
        </td>
      </tr>
    );
  };

  return (
    <PrintLayout
      headerContent="Parent Contacts"
      bodyContent={generateBodyContent()}
      footerContent={schoolName}
    />
  );
}
