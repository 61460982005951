import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import EmojiObjectsOutlinedIcon from "@mui/icons-material/EmojiObjectsOutlined";
import { useForm, FormProvider } from "react-hook-form";
import DirectionsWalkOutlinedIcon from "@mui/icons-material/DirectionsWalkOutlined";
import { reportCardDrawer } from "./ReportCardTKKDrawer.styles";
import {
  danger,
  font16,
  font40,
  formButtons,
  formHeader,
  formTitle,
  mb10,
  mb25,
  mr10,
  mt25,
} from "../sharedStyles";
import {
  reportCardDistributeContainer,
  reportCardExplainFeature,
  reportCardExplainFeatureBox,
  reportCardExplainFeatureOpen,
  reportCardLinksContainer,
  reportCardLinkText,
  directionsIcon,
} from "./ReportCardDistributeDrawer.styles";
import ErasableTextField from "../ErasableTextField";
import { reportCardTable } from "./ReportCardTable.styles";
import reportCardsService from "../../service/reportCardsService";
import GenericSubmitButton from "../GenericSubmitButton";

export default function ReportCardDistributeDrawer({
  handleDrawerClose,
  students,
  studentsFailed,
  term,
}) {
  const methods = useForm();
  const [helpOpened, setHelpOpened] = useState(false);

  const onSubmit = async (data) => {
    const body = {
      students: students.map((s) => s.id),
      message: data.message,
      subject: data.subject,
      term_id: term.id,
      url: window.location.href,
    };

    try {
      await reportCardsService.distributeTermGrades(body);
      handleDrawerClose();
    } catch (e) {
      console.log(e);
    }
  };

  const handleHelpTriggered = () => {
    setHelpOpened(!helpOpened);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Box sx={reportCardDrawer}>
          <Stack direction="row" sx={formHeader}>
            <Typography sx={formTitle}>Distribute Report Cards</Typography>
            <Stack direction="row">
              <Button sx={formButtons} onClick={() => handleDrawerClose()}>
                Close
              </Button>
              <GenericSubmitButton text="Send" submittingText="Sending..." />
            </Stack>
          </Stack>
          <Box sx={reportCardDistributeContainer}>
            <Box sx={reportCardExplainFeatureBox} onClick={handleHelpTriggered}>
              {helpOpened ? (
                <>
                  <Box sx={reportCardExplainFeatureOpen}>
                    <EmojiObjectsOutlinedIcon
                      sx={{ ...font40, ...mr10 }}
                      size="inherit"
                    />
                    <Box sx={{ color: "black" }}>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aliquam at autem dolore, dolores eligendi enim facere
                      illum ipsam laboriosam mollitia nam officia officiis
                      perferendis, praesentium, sunt unde veniam vitae voluptas.
                    </Box>
                  </Box>
                  <Box sx={reportCardLinksContainer}>
                    <Typography sx={reportCardLinkText}>
                      Knowledge link
                    </Typography>
                    <Typography sx={reportCardLinkText}>
                      Knowledge link
                    </Typography>
                    <Typography sx={reportCardLinkText}>
                      Knowledge link
                    </Typography>
                    <Typography sx={reportCardLinkText}>
                      Knowledge link
                    </Typography>
                    <DirectionsWalkOutlinedIcon sx={directionsIcon} />
                  </Box>
                </>
              ) : (
                <Box sx={reportCardExplainFeature}>
                  <EmojiObjectsOutlinedIcon
                    sx={{ ...font40, ...mr10 }}
                    size="inherit"
                  />
                  <Box>Explore how this feature works</Box>
                </Box>
              )}
            </Box>
            <Box sx={mt25}>
              Notify recipients that report cards are available to be viewed in
              the student and parents portals.
            </Box>
            <Box sx={mb25}>
              <FormControl
                sx={mt25}
                fullWidth
                error={methods.formState.errors.subject && true}
              >
                <ErasableTextField
                  id="subject"
                  label="Subject"
                  fullWidth
                  type="text"
                  defaultValue="Report Cards"
                  hasError={methods.formState.errors.subject && true}
                  isRequired
                />

                {methods.formState.errors.subject && true ? (
                  <Typography sx={danger}>
                    {methods.formState.errors.subject.message}
                  </Typography>
                ) : null}
              </FormControl>
              <Box sx={mt25}>
                <ErasableTextField
                  multiline
                  lines={4}
                  id="message"
                  label="Message"
                  fullWidth
                  type="text"
                  hasError={methods.formState.errors.message && true}
                  isMultiline
                  isRequired
                />
                {methods.formState.errors.message && true ? (
                  <Typography sx={danger}>
                    {methods.formState.errors.message.message}
                  </Typography>
                ) : null}
              </Box>
            </Box>
            <Box>
              <Typography sx={{ ...font16, ...mb10 }}>
                You have selected <strong>{students.length}</strong> report
                cards to deliver
              </Typography>
              <Table sx={reportCardTable}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      <Box>
                        <Typography>Students</Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      <Box>
                        <Typography>Grade</Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {students.map((student) => (
                    <TableRow key={student.id}>
                      <TableCell align="left">
                        <Box>
                          {student.first_name} {student.last_name}
                        </Box>
                      </TableCell>
                      <TableCell align="right">
                        <Box>{student.grade_level}</Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
            {studentsFailed.length > 0 ? (
              <Box sx={mt25}>
                <Typography sx={{ ...font16, ...mb10 }}>
                  <strong>{studentsFailed.length}</strong> are not going to be
                  delivered (Not published or already delivered)
                </Typography>
                <Table sx={reportCardTable}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">
                        <Box>
                          <Typography>Students</Typography>
                        </Box>
                      </TableCell>
                      <TableCell align="right">
                        <Box>
                          <Typography>Grade</Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {studentsFailed.map((student) => (
                      <TableRow key={student.id}>
                        <TableCell align="left">
                          <Box>
                            {student.first_name} {student.last_name}
                          </Box>
                        </TableCell>
                        <TableCell align="right">
                          <Box>{student.grade_level}</Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            ) : null}
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
}
