import theme from "../../../utils/index";

const gTTableBorderless = {
  borderBottom: "none !important",
};

const gTTableBorderlessHeader = {
  ...gTTableBorderless,
  px: 0,
  pb: 0,
  height: "90px",
  "& div": {
    height: "47px",
    borderRight: "2px solid black",
  },
};

const gTAssignmentContainer = {
  "&:not(:last-of-type)": {
    borderRight: "1px solid #BFC7CA",
  },
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  height: "175px",
  width: "100px",
  pb: "10px",
  color: "black",
  alignItems: "center",
  justifyContent: "space-between",
};

const gTTableRow = {
  "& td": {
    borderBottom: "1px solid black",
    color: "black",
  },
};

const gTAssignmentName = {
  mb: "10px",
  mx: "auto",
  overflow: "hidden",
  writingMode: "vertical-rl",
  transform: "rotate(180deg)",
  textAlign: "left",
  textDecoration: "none",
  "& p": {
    fontWeight: "500",
  },
};

const gTHeader = {
  fontSize: "14px",
};

const gTDayHead = {
  "&:not(:first-of-type)": {
    borderRight: "2px solid black",
  },
  borderBottom: "0",
  px: 0,
  color: "black",
  maxWidth: "100px",
};

const gTAssignmentCell = {
  height: "70px",
  "&:not(:second-of-type)": {
    borderLeft: "1px solid black",
  },
  borderRight: "2px solid black",
  p: 0,
  alignItems: "center",
};

const gTStudentRow = {
  position: "sticky",
  zIndex: 99,
  verticalAlign: "bottom",
  "& th": {
    borderBottom: "1px solid black",
  },
};

const gTGradeStack = {
  height: "100%",
  display: "flex",
  alignItems: "center",
};

const gTAssignmentSquare = {
  "&:not(:last-of-type)": {
    borderRight: "1px solid #BFC7CA",
  },
  "&:focus-within": {
    border: `6px solid ${theme.palette.cell.selected}`,
  },
  height: "100%",
  width: "100px",
  fontSize: "20px",
  fontWeight: "400",
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
};

const gTAssignmentSquareGray = {
  ...gTAssignmentSquare,
  backgroundColor: "#D9D9D9",
  cursor: "default",
  height: "100%",
  padding: 0,
};

const gTTableCells = {
  px: 0,
  pb: "10px",
  color: "black",
};

const gTTableCellsSm = {
  minWidth: "100px",
  backgroundColor: "white",
  ...gTTableCells,
  ...gTTableBorderless,
};

const gTTableCellsName = {
  p: 0,
  width: "180px",
  ...gTTableBorderless,
};

const gTClassWork = {
  px: 0,
  pb: 0,
  color: "black",
  width: "100px",
};

const gTClassWorkContainer = {
  mr: "-1px",
  p: "0px",
  width: "100px",
  borderRight: "2px solid black",
  "& p": {
    fontWeight: 500,
  },
};

const gTReportedGrade = {
  px: 0,
  pb: 0,
  color: "black",
  width: "100px",
};

const gTReportedGradeContainer = {
  pb: "5px",
  borderRight: "2px solid black",
  "& p": {
    fontWeight: 500,
  },
};

const gTClassWorkCell = {
  height: "90px",
  borderRight: "1px solid black",
  width: "100px",
  p: 0,
};

const gTClassWorkCellContainer = {
  backgroundColor: "#C4C4C421",
  height: "100%",
  fontSize: "20px",
  fontWeight: "400",
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  ...gTClassWorkContainer,
};

const gTReportedGradeCellContainer = {
  ...gTReportedGradeContainer,
  height: "70px",
  backgroundColor: "#C4C4C421",
  fontSize: "20px",
  fontWeight: "400",
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const gTMax = {
  fontSize: "12px",
  fontWeight: 500,
  maxHeight: "25px",
};

const gTTableContainer = {
  overflowX: "auto",
  width: "100%",
  maxHeight: "1000px",
  minHeight: "340px",
};

const gTTable = {
  width: "auto",
};

const gTTableEmptyName = {
  ...gTTableBorderless,
  minWidth: "180px",
};

const gTTableKlassName = {
  minWidth: "100px",
  ...gTTableBorderless,
};

const gTTableStickyCell = {
  minWidth: "350px",
  position: "sticky",
  left: 0,
  backgroundColor: "white",
  zIndex: 98,
  py: 0,
  borderRight: "2px solid black",
};

const gTTableHead = {
  backgroundColor: "white",
  position: "sticky",
  top: 0,
  zIndex: 99,
};

const gTTableInput = {
  textAlign: "center",
  color: "black",
};

const emptyAssignmentCell = {
  width: "350px",
  borderBottom: "none !important",
  borderRight: "2px solid black",
};

const emptyAssignmentMessageContainer = {
  position: "absolute",
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  width: "700px",
  "& p": {
    color: "black",
    fontSize: "20px",
    ml: "385px",
  },
};

export {
  emptyAssignmentMessageContainer,
  emptyAssignmentCell,
  gTTableInput,
  gTTableRow,
  gTClassWorkCellContainer,
  gTClassWorkCell,
  gTReportedGradeContainer,
  gTReportedGrade,
  gTHeader,
  gTGradeStack,
  gTAssignmentName,
  gTAssignmentContainer,
  gTDayHead,
  gTAssignmentCell,
  gTStudentRow,
  gTAssignmentSquare,
  gTTableCells,
  gTClassWork,
  gTTableCellsSm,
  gTTableCellsName,
  gTClassWorkContainer,
  gTReportedGradeCellContainer,
  gTAssignmentSquareGray,
  gTMax,
  gTTableContainer,
  gTTable,
  gTTableEmptyName,
  gTTableBorderless,
  gTTableKlassName,
  gTTableStickyCell,
  gTTableHead,
  gTTableBorderlessHeader,
};
