import React, { useState, useEffect, useRef, useContext } from "react";
import { Box, TableCell, TableRow, TextField } from "@mui/material";
import { PrivacyTipOutlined } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  socialDevelopmentCell,
  socialDevelopmentCellName,
  socialDevelopmentCellComment,
  socialDevelopmentCellNameContainer,
  stickyCell,
} from "./SocialDevelopmentRow.styles";
import SocialDevelopmentCell from "./SocialDevelopmentCell";
import socialDevelopmentCommentService from "../service/socialDevelopmentCommentService";
import { SnackbarContext } from "../context/SnackbarContext";
import { PermissionsContext } from "../context/PermissionsContext";
import APP_PERMISSIONS from "../utils/constants/permissions";
import PERMISSION_TYPES from "../utils/constants/permission_types";
import { renderError } from "../utils/constants/forms";

export default function SocialDevelopmentRow({
  student,
  gradingScales,
  level,
  socialDevelopmentScores,
  termId,
  numberOfSkillsInCurrentLevel,
  getSdScores,
  getSdComments,
  comment,
  reportCards,
}) {
  function score(skillId) {
    const socialDevelopmentScore = socialDevelopmentScores.find(
      (item) =>
        item.student_id === student.id &&
        item.term_id === Number(termId) &&
        item.social_development_skill_id === skillId
    );

    return socialDevelopmentScore?.score || null;
  }

  const [rowComment, setRowComment] = useState("");
  const [commentError, setCommentError] = useState(false);
  const timeout = useRef();
  const snackbarContext = useContext(SnackbarContext);
  const { hasPermission } = useContext(PermissionsContext);
  const hasManagePermission =
    hasPermission(
      APP_PERMISSIONS.SOCIAL_DEVELOPMENT_WORK_ETHIC,
      PERMISSION_TYPES.MANAGE
    ) || hasPermission(APP_PERMISSIONS.ALL_STUDENTS, PERMISSION_TYPES.MANAGE);

  const handleCommentChange = (event) => {
    const isCommentTooLong = event.target.value.length > 500;
    setCommentError(isCommentTooLong);
    setRowComment(event.target.value);

    if (isCommentTooLong) {
      clearTimeout(timeout.current);
      return;
    }

    clearTimeout(timeout.current);
    snackbarContext.setSnackbar({
      message: "Saving...",
      severity: "info",
      open: true,
    });

    timeout.current = setTimeout(async () => {
      const commentSaved = await socialDevelopmentCommentService
        .save({
          student_id: student.id,
          term_id: termId,
          comment: event.target.value,
        })
        .catch((err) =>
          snackbarContext.setSnackbar({
            message: err.response.data.message,
            severity: "error",
            open: true,
          })
        );

      if (commentSaved) {
        snackbarContext.setSnackbar({
          message: "Saved successfully",
          severity: "success",
          open: true,
        });
        getSdComments();
      }
    }, 2000);
  };

  const isReportCardPublished =
    reportCards.find((reportCard) => reportCard.student_id === student.id)
      ?.is_published || false;

  useEffect(() => {
    setRowComment(comment || "");
  }, [comment]);

  const rowStatus = () => {
    let status = null;

    const allSkillsScored =
      socialDevelopmentScores.filter(
        (_score) => student.id === _score.student_id
      ).length === numberOfSkillsInCurrentLevel;

    if (allSkillsScored) {
      const postedScores = socialDevelopmentScores.filter(
        (_score) => student.id === _score.student_id && _score.posted_at
      );
      if (postedScores.length) {
        const anyScoreUpdatedAfterBeingPosted = postedScores.some(
          (_score) =>
            new Date(_score.updated_at).getTime() >
            new Date(_score.posted_at).getTime()
        );

        status = !anyScoreUpdatedAfterBeingPosted;
        return status;
      }
      const allScoresPosted = socialDevelopmentScores
        .filter((_score) => student.id === _score.student_id)
        .every((_score) => _score.posted_at);
      if (allScoresPosted) {
        status = allScoresPosted;
      }
    }
    return status;
  };

  const displayIcon = rowStatus();

  return (
    <TableRow>
      <Box sx={stickyCell}>
        <TableCell sx={{ ...socialDevelopmentCell, ...stickyCell }}>
          {displayIcon !== null && displayIcon ? (
            <CheckCircleIcon color="success" />
          ) : null}
          {displayIcon !== null && !displayIcon ? (
            <ErrorOutlineIcon color="alert" />
          ) : null}
        </TableCell>
        <TableCell
          sx={{
            ...socialDevelopmentCellName,
            ...stickyCell,
            verticalAlign: "middle",
          }}
        >
          <Box
            sx={socialDevelopmentCellNameContainer}
          >{`${student.last_name}, ${student.first_name}`}</Box>
        </TableCell>
        <TableCell sx={{ ...socialDevelopmentCell, ...stickyCell }} width="5%">
          {student.medical_badge && <PrivacyTipOutlined />}
        </TableCell>
        <TableCell
          sx={{
            ...socialDevelopmentCell,
            ...stickyCell,
            borderRight: "1px solid black",
            verticalAlign: "middle",
          }}
          align="center"
        >
          {student.grade_level}
        </TableCell>
      </Box>
      {gradingScales &&
        gradingScales.map(
          (gradingScale) =>
            level === gradingScale.name &&
            gradingScale.social_development_skills.map((skill) => (
              <SocialDevelopmentCell
                key={skill.id}
                skill={skill}
                gradingScale={gradingScale}
                student={student}
                score={score(skill.id)}
                getSdScores={getSdScores}
                isReportCardPublished={isReportCardPublished}
              />
            ))
        )}
      <TableCell sx={socialDevelopmentCellComment}>
        <TextField
          fullWidth
          placeholder="Comment"
          value={rowComment}
          disabled={!hasManagePermission || isReportCardPublished}
          onChange={handleCommentChange}
        />
        {commentError && renderError("Comment cannot exceed 500 characters")}
      </TableCell>
    </TableRow>
  );
}
