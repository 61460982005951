import useMediaQuery from "@mui/material/useMediaQuery";
import theme from "../../utils";

const footerStyle = {
  backgroundColor: theme.palette.primary.main,
  color: "#ffffff",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
};

const footerGridContainer = {
  pt: "36px",
  pl: "151px",
  pr: "109px",
  mb: "16px",
  justifyContent: () => useMediaQuery(theme.breakpoints.down("md")) && "center",
};

const textGridItem = {
  fontSize: 16,
  letterSpacing: -0.5,
  paddingBottom: "30px",
  justifyContent: () =>
    useMediaQuery(theme.breakpoints.up("md")) ? "space-between" : "center",
};

const launchGridItem = {
  justifyContent: "flex-start",
  alignItems: "center",
  paddingLeft: "37px",
  paddingBottom: "30px",
};

const launchBtn = {
  textTransform: "none",
  color: "#000000",
  height: 30,
  width: 218,
  fontWeight: 400,
  fontSize: 17,
  letterSpacing: -0.05,
  backgroundColor: "#FFFFFF",
  "&:hover": {
    backgroundColor: "#FFFFFF",
  },
};

const poweredByLogoGridItem = {
  alignItems: "end",
};

const poweredByTxt = {
  display: "inline",
  paddingLeft: 10,
  fontStyle: "italic",
  fontWeight: 500,
  fontSize: 12,
};

const poweredByLogo = {
  width: 189,
  height: 26,
  objectFit: "contain",
};

const poweredByLogoTagLine = {
  fontWeight: 700,
  fontSize: 12,
  textTransform: "uppercase",
  paddingLeft: 125,
};

const aeLogoGridItem = {
  justifyContent: "flex-end",
};

const aeLogo = {
  width: "inherit",
  height: 90,
  objectFit: "contain",
};

export {
  footerStyle,
  footerGridContainer,
  textGridItem,
  launchGridItem,
  launchBtn,
  poweredByLogoGridItem,
  poweredByTxt,
  poweredByLogo,
  poweredByLogoTagLine,
  aeLogoGridItem,
  aeLogo,
};
