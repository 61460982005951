import ValidInputCodes from "./ValidInputCodes";
import GetAllSubstrings from "./GetAllSubstrings";
import {
  proficiencyLevels,
  ipntOptionsWithNa,
  osnOptions,
} from "../../utils/constants/toolTipOptions";

export default function PartialValidCodes(
  markingCodes,
  specialMarks,
  isLetterGrade,
  isSkill = false,
  isSocialDevelopment = false
) {
  let partialValidCode = [];
  let validCodes = [];
  if (isSkill) {
    if (isLetterGrade) {
      validCodes = ipntOptionsWithNa(markingCodes)
        .flatMap((obj) => Object.values(obj))
        .map((obj) => obj.code?.toLowerCase());
    } else {
      validCodes = proficiencyLevels
        .flatMap((obj) => obj)
        .map((obj) => obj.value.toString().toLowerCase());
    }
  }

  if (isSocialDevelopment) {
    if (isLetterGrade) {
      validCodes = markingCodes
        .flatMap((obj) => Object.values(obj))
        .map((obj) => obj.code.toLowerCase());
    } else {
      validCodes = osnOptions
        .flatMap((obj) => Object.values(obj))
        .map((obj) => obj.toLowerCase());
    }
  }

  if (!isSkill && !isSocialDevelopment) {
    validCodes = ValidInputCodes(markingCodes, specialMarks, isLetterGrade);
  }

  validCodes.forEach((value) => {
    if (value?.length > 1) {
      partialValidCode.push(GetAllSubstrings(value.slice(0, -1)));
    }
  });

  partialValidCode = partialValidCode
    .flatMap((value) => value)
    .filter((value) => !validCodes.includes(value));
  return [...new Set(partialValidCode)];
}
