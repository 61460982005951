import { Button, Grid, Typography, Box } from "@mui/material";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useOutletContext, useParams, useSearchParams } from "react-router-dom";
import schoolDayService from "../../../service/schoolDayService";
import dateTimeFormats from "../../../utils/constants/dateTimeFormats";
import SchoolYearDrawer from "../../SchoolYearDrawer";
import { font14, formTitle } from "../../sharedStyles";
import {
  alignCenter,
  editSchoolYear,
  monthTitle,
  emptyMessageContainer,
} from "./SchoolDays.styles";
import SchoolDaysItem from "./SchoolDaysItem";
import { PermissionsContext } from "../../../context/PermissionsContext";
import APP_PERMISSIONS from "../../../utils/constants/permissions";
import PERMISSION_TYPES from "../../../utils/constants/permission_types";
import Authorize from "../../Authorize";
import schoolDayDesignationsService from "../../../service/schoolDayDesignationsService";

export default function SchoolDays() {
  const [drawerStatus, setDrawerStatus] = useState(false);
  const [schoolDaysByMonth, setSchoolDaysByMonth] = useState([]);
  const [dayTemplates, setDayTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [availableDays, setAvailableDays] = useState(0);
  const [schoolDaysCount, setSchoolDaysCount] = useState(0);
  const [nonSchoolDaysCount, setNonSchoolDaysCount] = useState(0);
  const [schoolDayDesignations, setSchoolDayDesignations] = useState([]);
  const [
    selectedSchoolYear,
    setSelectedSchoolYear,
    // eslint-disable-next-line no-unused-vars
    termsDrawerStatus,
    setSnackBarOpen,
    // eslint-disable-next-line no-unused-vars
    dayTemplateDrawerStatus,
    schoolDaysDrawerStatus,
  ] = useOutletContext();
  const params = useParams();
  const schoolId = params.school_id;
  const [searchParams] = useSearchParams();
  const schoolYearIdParam = searchParams.get("school_year");
  const { hasPermission, hasAnyPermissionType } =
    useContext(PermissionsContext);

  const managePermission = hasPermission(
    APP_PERMISSIONS.SCHOOL_DAYS,
    PERMISSION_TYPES.MANAGE
  );

  const getSchoolDaysByMonth = async (onlyStats = false) => {
    const response = await schoolDayService.byMonth({
      params: {
        school_year_id: schoolYearIdParam,
      },
    });
    if (response.data) {
      const list = [];
      Object.values(response.data.school_days).forEach((_months) => {
        let date = _months[0].date.split("-");
        date = new Date(date[0], date[1] - 1, date[2]);
        const firstDayOfSchoolDays = date.getDate() - 1;
        const firstDayOfMonth =
          Number(moment(date).startOf("month").format("d")) +
          firstDayOfSchoolDays;
        const paddedSchoolDays = _months;
        for (let i = 0; i < firstDayOfMonth; i += 1) {
          paddedSchoolDays.unshift(i);
        }
        list.push({
          monthYear: date,
          schoolDays: paddedSchoolDays,
        });
      });
      if (!onlyStats) {
        setSchoolDaysByMonth(list);
      }
      setAvailableDays(response.data.available_days);
      setSchoolDaysCount(response.data.school_days_count);
      setNonSchoolDaysCount(response.data.non_school_days_count);
      setDayTemplates(response.data.day_templates);
    }
    setLoading(false);
  };

  const getSchoolDayDesignations = async () => {
    const response = await schoolDayDesignationsService.fetchAll();
    if (response.data) {
      setSchoolDayDesignations(response.data);
    }
  };

  useEffect(() => {
    if (!loading) {
      setLoading(true);
      setAvailableDays("");
      setSchoolDaysCount("");
      setNonSchoolDaysCount("");
      getSchoolDaysByMonth();
      getSchoolDayDesignations();
    }
  }, []);

  useEffect(() => {
    if (!loading && !schoolDaysDrawerStatus) {
      setLoading(true);
      setAvailableDays("");
      setSchoolDaysCount("");
      setNonSchoolDaysCount("");
      getSchoolDaysByMonth();
    }
  }, [selectedSchoolYear, schoolDaysDrawerStatus]);

  return (
    <Authorize permission={hasAnyPermissionType(APP_PERMISSIONS.SCHOOL_DAYS)}>
      <Grid container alignItems="center">
        <Grid item xs={2} mt={2}>
          <Typography sx={formTitle}>{selectedSchoolYear?.name}</Typography>
        </Grid>
        <Grid item xs={1.5} mt={2}>
          <Typography sx={font14}>Available Days: {availableDays}</Typography>
        </Grid>
        <Grid item xs={1.5} mt={2}>
          <Typography sx={font14}>School Days: {schoolDaysCount}</Typography>
        </Grid>
        <Grid item xs={1.5} mt={2}>
          <Typography sx={font14}>
            Non-School Days: {nonSchoolDaysCount}
          </Typography>
        </Grid>
        <Grid item xs={5.5} mt={2}>
          {managePermission && (
            <Button
              variant="text"
              sx={editSchoolYear}
              onClick={() => setDrawerStatus(true)}
            >
              Edit School Year
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid container alignItems="center" px={3}>
        {moment.weekdays().map((day) => (
          <Grid item xs sx={alignCenter} mt={2} key={day}>
            <Typography>{day.toUpperCase()}</Typography>
          </Grid>
        ))}
      </Grid>
      {!loading ? (
        schoolDaysByMonth.map((month) => (
          <Grid container alignItems="center" px={3} key={month.monthYear}>
            <Grid container px={3}>
              <Typography sx={monthTitle}>
                {moment(month.monthYear).format(dateTimeFormats.MMMMYYYY)}
              </Typography>
            </Grid>
            {month.schoolDays.map((schoolDay) => (
              <SchoolDaysItem
                schoolDay={schoolDay}
                key={schoolDay?.id || schoolDay}
                setSnackBarOpen={setSnackBarOpen}
                dayTemplates={dayTemplates}
                getSchoolDaysByMonth={getSchoolDaysByMonth}
                options={schoolDayDesignations}
              />
            ))}
          </Grid>
        ))
      ) : (
        <Box sx={emptyMessageContainer}>
          <Typography>Loading...</Typography>
        </Box>
      )}
      <SchoolYearDrawer
        schoolId={schoolId}
        setSnackBarOpen={setSnackBarOpen}
        drawerStatus={drawerStatus}
        setDrawerStatus={setDrawerStatus}
        selectedSchoolYear={selectedSchoolYear}
        setSelectedSchoolYear={setSelectedSchoolYear}
      />
    </Authorize>
  );
}
