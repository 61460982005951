import React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { title } from "./Profile.styles";
import { pt50, pl20, pt30 } from "../../../sharedStyles";

export default function ContactInformation({ kidInfo }) {
  const { t } = useTranslation("guardian", { keyPrefix: "studentProfile" });

  const residesWithSelected = (residesWith) =>
    residesWith.map((rw) => `${rw.first_name} ${rw.last_name}`);

  const handleDelete = () => {
    // handle delete
  };

  return (
    <>
      <Typography sx={[title, pt50]}>
        {t("studentContactInformation")}
      </Typography>
      <Grid container spacing={2} sx={[pl20, pt30]}>
        <Grid item md={5.5}>
          <TextField
            id="primaryEmail"
            label={t("primaryEmail")}
            fullWidth
            defaultValue={kidInfo.primary_email}
            inputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item md={5.5}>
          <TextField
            id="secondaryEmail"
            label={t("secondaryEmail")}
            fullWidth
            defaultValue={kidInfo.secondary_email}
            inputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item md={1} />
        <Grid item md={5.5}>
          <TextField
            id="mobilePhone"
            label={t("mobilePhone")}
            fullWidth
            defaultValue={kidInfo.phone_number}
            inputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item md={6.5} />
        <Grid item md={11}>
          <FormControl fullWidth>
            <InputLabel id="resides_with_label">{t("residesWith")}</InputLabel>
            <Select
              disabled
              labelId="resides_with_label"
              id="resides_with"
              multiple
              value={residesWithSelected(kidInfo.resides_with)}
              input={
                <OutlinedInput
                  id="select-multiple-chip"
                  label={t("residesWith")}
                />
              }
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} onDelete={handleDelete} />
                  ))}
                </Box>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item md={1} />
      </Grid>
    </>
  );
}
