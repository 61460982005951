const eventTableBodyCell = (isPinned) =>
  isPinned ? { borderBottom: "none", pb: 5 } : { borderBottom: "none" };

const eventActionText = {
  display: "inline",
  fontWeight: 700,
  textTransform: "capitalize",
};

const eventActionTextContent = {
  display: "inline",
};

const eventHeaderRow = {
  fontSize: 11,
};

const eventHeaderCell = { width: "65%", borderBottom: "none", py: 0 };

const eventHeaderCellWithCursor = {
  borderBottom: "none",
  py: 0,
  cursor: "pointer",
};

const eventBodyText = {
  fontSize: 16,
  whiteSpace: "nowrap",
};

const eventTablePinned = {
  fontSize: 11,
  fontWeight: 500,
  background: "#fff",
  padding: "0 5px",
};

const eventTablePinnedRow = { borderBottom: "none", p: 0 };

const eventTableStrike = {
  width: "100%",
  textAlign: "left",
  borderBottom: "1px solid #000",
  lineHeight: " 0.1em",
  margin: "10px 0 20px",
};

const row = (cursor) => ({
  ...(cursor && { cursor: "pointer" }),
  "&:not(:last-child) td, &:not(:last-of-type) th": {
    borderBottom: "1px solid #D3D4D5",
  },
});

const pinnedRow = (cursor) => ({
  ...(cursor && { cursor: "pointer" }),
  borderBottom: "1px solid black",
});

const paginationContainer = {
  mt: "35px",
  display: "flex",
  alignItems: "center",
};

const eventPagination = {
  mr: "35px",
};

export {
  eventBodyText,
  pinnedRow,
  row,
  eventTableBodyCell,
  eventTableStrike,
  eventTablePinned,
  eventActionText,
  eventActionTextContent,
  eventHeaderRow,
  eventHeaderCell,
  eventTablePinnedRow,
  eventHeaderCellWithCursor,
  paginationContainer,
  eventPagination,
};
