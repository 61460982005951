const studentListTable = {
  maxWidth: "850px",
  mx: "auto",
  mt: "30px",
};

const studentListTableHeader = {
  color: "black",
  fontSize: "11px",
  fontWeight: "bold",
  borderBottom: "none",
  p: 0,
};

const studentListTableCell = {
  borderBottom: "none",
  p: "5px",
};

export { studentListTable, studentListTableHeader, studentListTableCell };
