// import React from 'react';

// function RequiredComponent({ required }) {
//   return required ? <span>*</span> : null;
// }

// export default RequiredComponent;
import { Checkbox, Grid, FormControlLabel } from "@mui/material";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

export default function RequiredComponent({ selectedField }) {
  const { getValues, setValue } = useFormContext();

  useEffect(() => {
    setValue("isRequired", selectedField.properties.isRequired, {
      shouldDirty: true,
    });
  }, [selectedField]);

  return (
    <Grid item md={12}>
      <FormControlLabel
        sx={{ width: "100%" }}
        control={
          <Checkbox
            checked={getValues("isRequired")}
            onChange={(e) => {
              setValue("isRequired", e.target.checked, {
                shouldDirty: true,
              });
            }}
          />
        }
        label="Required"
        disabled={
          selectedField.source_field_id ||
          selectedField.properties.dbRequiredField
        }
      />
    </Grid>
  );
}
