import { tableCellClasses, tableRowClasses } from "@mui/material";

const tableStyles = {
  [`& .${tableCellClasses.root}`]: {
    borderBottom: "none",
  },
  [`& .${tableRowClasses.root}`]: {
    borderBottom: "1px solid #d9d9d9",
  },
  [`& .${tableCellClasses.root}:not(:first-child)`]: {
    maxWidth: 100,
    width: 100,
  },
  "& .MuiTableRow-head": {
    borderBottom: "none",
    "& .MuiTableCell-head": {
      color: "#8f8e8e",
      textTransform: "uppercase",
      fontWeight: 400,
      fontSize: 11,
    },
  },
  "& .MuiTableCell-body": {
    color: "black",
    fontSize: 14,
  },
  "& .MuiTableCell-root:nth-child(1)": {
    width: "10%",
  },
  "& .MuiTableCell-root:nth-child(2)": {
    width: "5%",
  },
  "& .MuiTableCell-root:nth-child(3)": {
    width: "10%",
  },
  "& .MuiTableCell-root:nth-child(4)": {
    width: "9%",
  },
  "& .MuiTableCell-root:nth-child(5)": {
    width: "9%",
  },
  "& .MuiTableCell-root:nth-child(6)": {
    width: "9%",
  },
  "& .MuiTableCell-root:nth-child(7)": {
    width: "9%",
  },
  "& .MuiTableCell-root:nth-child(8)": {
    width: "9%",
  },
  "& .MuiTableCell-root:nth-child(9)": {
    width: "5%",
  },
};

const dashboardContainer = {
  minHeight: "75vh",
  mx: 4,
};

const leftContainer = {
  borderRight: "1px solid #CBCBCB",
  py: 3.5,
};

const chartContainer = {
  my: 2,
  minHeight: "30vh",
};

const studentsTableContainer = {
  pt: 2,
};

export {
  tableStyles,
  leftContainer,
  dashboardContainer,
  chartContainer,
  studentsTableContainer,
};
