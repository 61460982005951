import axios from "../utils/axios-util";

const skillsEndpoint = "/api/student_skills";

const fetchAllStudentSkills = async (params) => {
  const { data } = await axios.get(skillsEndpoint, params);
  if (!data.data) {
    console.error("Error fetching student skills from the API");
    return [];
  }
  return data;
};

const createStudentSkill = async (params) => {
  const { data } = await axios.post(skillsEndpoint, params);
  if (!data.data) {
    console.error("Error fetching student skills from the API");
    return [];
  }
  return data;
};

const createMultipleStudentSkills = async (params) => {
  const { data } = await axios.post(
    `${skillsEndpoint}/create_multiple_skills`,
    params
  );
  if (!data.success) {
    console.error("Error fetching student skills from the API");
  }
  return data;
};

const postGrades = async (params) => {
  const { data } = await axios.post(`${skillsEndpoint}/post`, params);
  if (!data.data) {
    console.error("Error posting student skills to the API");
    return [];
  }
  return data;
};

export default {
  fetchAllStudentSkills,
  createStudentSkill,
  postGrades,
  createMultipleStudentSkills,
};
