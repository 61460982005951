import { styled } from "@mui/material/styles";
import { Switch } from "@mui/material";

const StyledSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase": {
    "&.Mui-checked": {
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.alert.secondary,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    borderRadius: 100,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.disabled.main,
  },
}));

const pinCheckBox = {
  alignItems: "flex-end",
};

const displayOptionsContainer = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  my: "35px",
  mr: "55px",
};

const displayOptionsTitle = {
  fontSize: "26px",
  mr: "60px",
};

const accountActive = {
  justifyContent: "start",
  margin: 0,
};

const formGrid = {
  px: 0,
  justifyContent: "space-between",
  alignItems: "flex-start",
};

const generalInfoGrid = {
  px: 0,
  justifyContent: "space-between",
  alignItems: "center",
};

const imageContainer = {
  margin: "25px 0 25px 0",
  justifyContent: "flex-end",
};

const boxContainer = {
  position: "relative",
};

const profileImage = {
  maxHeight: "435px",
  width: "320px",
  overflow: "hidden",
};

const profileImageButton = {
  margin: "0",
  position: "absolute",
  top: "85%",
  left: "50%",
  msTransform: "translate(-50%, -50%)",
  transform: "translate(-50%, -50%)",
  color: "white",
  border: "1px solid white",
  textTransform: "capitalize",
  "&:hover": {
    border: "1px solid white",
  },
};

const relationshipRow = {
  mt: "100px",
  mb: "20px",
};

const optionsContainer = {
  borderRight: "solid 1px #3F464B",
};

const notificationsGrid = {
  justifyContent: "center",
  alignItems: "center",
};

const optionsCheckBox = {
  alignItems: "center",
};

const optionsInputContainer = {
  width: "200px",
  ml: "50px",
};

const optionsRow = {
  mb: "25px",
  width: "100%",
};

const notificationsHeader = {
  display: "flex",
};

const notificationsTitle = {
  height: "46px",
  fontSize: "28px",
};

const notificationsContainer = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  width: "100%",
};

const notesTextArea = {
  padding: "24px 12px 24px 12px",
  width: "100%",
  color: "black",
  font: "inherit",
  fontSize: 17,
  border: "1px solid black",
  borderRadius: 5,
};

export {
  StyledSwitch,
  pinCheckBox,
  displayOptionsContainer,
  displayOptionsTitle,
  accountActive,
  formGrid,
  generalInfoGrid,
  imageContainer,
  boxContainer,
  profileImage,
  profileImageButton,
  relationshipRow,
  optionsContainer,
  notificationsGrid,
  optionsCheckBox,
  optionsInputContainer,
  optionsRow,
  notificationsHeader,
  notesTextArea,
  notificationsTitle,
  notificationsContainer,
};
