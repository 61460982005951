import axios from "../utils/axios-util";

const coursesEndpoint = "/api/courses";

const fetchAllCourses = async (params) => {
  const { data } = await axios.get(coursesEndpoint, params);
  return data;
};

const fetchAllPredefinedCourses = async () => {
  const { data } = await axios.get("/api/predefined_courses");
  return data;
};

const createCourse = async (params) => {
  const { data } = await axios.post(coursesEndpoint, params);
  return data;
};

const updateCourse = async (params) => {
  const { data } = await axios.patch(
    `${coursesEndpoint}/${params.course_id}`,
    params
  );
  return data;
};

const updateCourseStatus = (params) =>
  axios.put(
    `${coursesEndpoint}/${params.course_id}/update_course_status`,
    params
  );

const deleteCourse = async (id) => {
  const { data } = await axios.delete(`${coursesEndpoint}/${id}`);
  return data;
};

export default {
  fetchAllCourses,
  fetchAllPredefinedCourses,
  createCourse,
  updateCourse,
  updateCourseStatus,
  deleteCourse,
};
