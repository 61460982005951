const guardianCheckmark = (checked) => (theme) => ({
  position: "absolute",
  transform: "rotate(45deg) translate(-50%, -50%)",
  left: "35%",
  top: "45%",
  height: 12,
  width: 7,
  borderBottom: checked ? `2.5px solid ${theme.palette.text.strongBlue}` : "",
  borderRight: checked ? `2px solid ${theme.palette.text.strongBlue}` : "",
});

const guardianCircle = (theme) => ({
  position: "relative",
  background: theme.palette.text.lightBlue,
  borderRadius: "50%",
  width: 35,
  height: 35,
  cursor: "pointer",
});

const guardianNotification = {
  fontSize: "17px",
  alignItems: "center",
};

const guardianNotificationsGrid = {
  justifyContent: "center",
  alignItems: "center",
};

const guardianNotificationsText = {
  alignItems: "center",
};

export {
  guardianCheckmark,
  guardianCircle,
  guardianNotification,
  guardianNotificationsGrid,
  guardianNotificationsText,
};
