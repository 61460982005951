const classSchedulesRowPeriod = {
  alignItems: "center",
  justifyContent: "space-between",
  width: "185px",
};

const classSchedulesRowTitle = {
  fontWeight: "700",
  textTransform: "uppercase",
  fontSize: "12px",
};

const classSchedulesRowContainer = {
  color: "black",
  borderBottom: "1px solid black",
  py: "20px",
};

const classSchedulesRowTime = {
  mr: "30px",
};

const classSchedulesRowItem = {
  py: "10px",
  pl: "20px",
  width: "185px",
  "&:not(:first-of-type)": {
    borderLeft: "1px solid #D6D6D6",
  },
};

export {
  classSchedulesRowPeriod,
  classSchedulesRowTitle,
  classSchedulesRowContainer,
  classSchedulesRowTime,
  classSchedulesRowItem,
};
