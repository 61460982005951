import React from "react";
import { Tooltip } from "@mui/material";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";

export default function PrekGradePostedIcon() {
  return (
    <Tooltip title="Grades posted" placement="left-start" arrow>
      <AssignmentOutlinedIcon color="success" />
    </Tooltip>
  );
}
