import FieldBase from "./FieldBase";

export default class SubHeading extends FieldBase {
  constructor(field) {
    super(field);
    this.label = field.label;
    this.dataType = "value_text";
    this.propertyComponents = [];
    this.properties.formBuilderField = true;
    this.properties.subHeadingText =
      field?.properties?.subHeadingText || "Heading";
    this.w = 12;
    this.isResizable = false;
  }
}
