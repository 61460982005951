const formatFullName = (data) => {
  let fullName = "";
  if (data) {
    fullName = `${data.first_name} ${data.middle_name} ${data.last_name}`
      ?.replace(/null|NULL/g, " ")
      ?.replace(/undefined|UNDEFINED/g, " ")
      ?.replace(/\s+/g, " ")
      ?.trim();
  }
  return fullName;
};

export default formatFullName;
