import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";

const CssTextField = styled(TextField)({
  "& .MuiFilledInput-root": {
    backgroundColor: "#ffffff !important",
    color: "text.primary",
    borderRadius: 3,
    "&:before": {
      borderBottom: "0 !important",
    },
  },
});

export default CssTextField;
