import { mt70 } from "../components/sharedStyles";

const preKHeaderContainer = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const preKSelectTermBox = {
  ...mt70,
  display: "flex",
  justifyContent: "center",
};

const tabsBox = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: 0,
  borderBottom: "1px solid",
};

const preKTab = {
  fontSize: 18,
  textTransform: "none",
  fontWeight: 400,
  color: "black",
};

const preKContainer = {
  px: "0px !important",
};

const selectBox = {
  marginTop: "-20px",
};

const tableContainer = {
  overflowX: "auto",
  width: "100%",
  maxHeight: "1000px",
  minHeight: "340px",
  marginLeft: "50px",
};

const tableStyle = {
  width: "auto",
};

const tableHead = {
  position: "sticky",
  top: 0,
  zIndex: 99,
};

const tableDefault = {
  fontWeight: 400,
  color: "black",
  fontSize: 14,
  paddingTop: "82px",
};

const tableHeadName = {
  ...tableDefault,
  position: "sticky",
  left: 0,
  zIndex: 98,
};

const tableHeadAge = {
  ...tableDefault,
  fontSize: 11,
  color: "#7f7f7f",
  paddingTop: 0,
};

const nameSort = {
  display: "flex",
  width: "200px",
};

const tableHeadOptions = {
  ...tableDefault,
  paddingTop: 0,
  fontSize: 12,
  cursor: "pointer",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 5,
  WebkitBoxOrient: "vertical",
  height: "90px",
  width: "90px",
};

const tableHeadCustomOptions = {
  display: "block",
  alignContent: "end",
};

const sortBox = {
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
};

const tableBorderRight = {
  borderRight: "1px solid #e0e0e0",
};

export {
  preKHeaderContainer,
  preKSelectTermBox,
  tabsBox,
  preKTab,
  preKContainer,
  selectBox,
  tableHead,
  tableStyle,
  tableContainer,
  tableHeadAge,
  tableHeadOptions,
  tableHeadName,
  sortBox,
  tableBorderRight,
  tableHeadCustomOptions,
  nameSort,
};
