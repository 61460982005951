import React, { useState, useEffect } from "react";
import { Tab, Tabs } from "@mui/material";
import {
  useNavigate,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { pageTabs, tabsStyle } from "./processFieldSet.style";

export default function ProcessFieldSetTabs() {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const schoolId = params.school_id;

  const formattedParams = () => {
    let paramsUrl = "?";
    const schoolYearId = searchParams.get("school_year");
    const processId = searchParams.get("process_id");
    if (schoolYearId) {
      paramsUrl = paramsUrl.concat(`&school_year=${schoolYearId}`);
    }
    if (processId) {
      paramsUrl = paramsUrl.concat(`&process_id=${processId}`);
    }
    return paramsUrl;
  };

  const tabs = [
    {
      index: 0,
      label: "Form",
      url: `/school/${schoolId}/admissions/setup/process-builder/form${formattedParams()}`,
    },
    {
      index: 1,
      label: "Files",
      url: `/school/${schoolId}/admissions/setup/process-builder/files${formattedParams()}`,
    },
    // {
    //   index: 2,
    //   label: "Fee",
    //   url: `/school/${schoolId}/admissions/setup/process-builder/fee${formattedParams()}`,
    // },
    {
      index: 3,
      label: "Task",
      url: `/school/${schoolId}/admissions/setup/process-builder/task${formattedParams()}`,
    },
    // {
    //   index: 4,
    //   label: "Event",
    //   url: `/school/${schoolId}/admissions/setup/process-builder/event${formattedParams()}`,
    // },
    // {
    //   index: 5,
    //   label: "Alert",
    //   url: `/school/${schoolId}/admissions/setup/process-builder/alert${formattedParams()}`,
    // },
  ];

  const [tabIndex, setTabIndex] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  const handleTabChange = (value, path) => {
    setTabIndex(value);
    navigate(path);
  };

  useEffect(() => {
    const getTabFromUrl = tabs.find((tab) =>
      location.pathname.includes(tab.url.split("?")[0])
    )?.index;
    if (getTabFromUrl) setTabIndex(getTabFromUrl);
    else navigate(tabs[0].url);
  }, []);

  return (
    <Tabs value={tabIndex} sx={tabsStyle}>
      {tabs?.map((tab) => (
        <Tab
          key={tab.index}
          value={tab.index}
          label={tab.label}
          onClick={() => handleTabChange(tab.index, tab.url)}
          sx={pageTabs}
        />
      ))}
    </Tabs>
  );
}
