import {
  Box,
  Container,
  Stack,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Outlet, useParams, useSearchParams } from "react-router-dom";
import { Clear, Search } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { useForm } from "react-hook-form";
import {
  pageHeaderTitle,
  pageTabsContainer,
  pb0,
} from "../components/sharedStyles";
import {
  admissionsContainer,
  searchAndSelectContainer,
} from "./Admissions.styles";
import StaffTabs from "../components/Staff/StaffTabs";
import StaffActions from "../components/Staff/StaffActions/StaffActions";
import { StaffActionsProvider } from "../context/StaffActionsContext";

const CssSearchField = styled(TextField)({
  "& .MuiInput-root": {
    marginRight: "17px",
    "&:before": {
      borderBottom: "none",
    },
  },
});

export default function Staffs() {
  const schoolId = useParams().school_id;
  const isOnIndividualApplicationScreen = !!useParams().id;
  const [searchParams, setSearchParams] = useSearchParams();
  const { register, handleSubmit, getValues, setValue } = useForm();
  const [query, setQuery] = useState(searchParams.get("query") || "");
  const [showClearButton, setShowClearButton] = useState(
    searchParams.get("query") || false
  );
  const [tabChanged, setTabChanged] = useState();

  const handleSearch = (data) => {
    searchParams.set("query", data.search || "");
    setQuery(data.search || "");
    setSearchParams(searchParams);
  };

  const handleQuickFindChange = (event) => {
    if (event.target.value) setShowClearButton(true);
    else setShowClearButton(false);
  };

  const cancelButtonStyle = {
    visibility: showClearButton ? "inherit" : "hidden",
  };

  const handleClickOnClearButton = () => {
    setValue("search", null);
    setShowClearButton(false);
    handleSearch(getValues());
  };

  useEffect(() => {
    if (tabChanged) {
      handleClickOnClearButton();
      setTabChanged(false);
    }
  }, [tabChanged]);

  return (
    <StaffActionsProvider>
      <Container maxWidth={false} variant="header" sx={pb0}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Stack direction="row" alignItems="baseline">
              <Typography sx={pageHeaderTitle}>Staff</Typography>
            </Stack>
          </Box>
          <Box sx={searchAndSelectContainer}>
            {!isOnIndividualApplicationScreen && (
              <form
                onSubmit={handleSubmit(handleSearch)}
                onChange={handleQuickFindChange}
                style={{ width: "100%", padding: "0 2rem" }}
              >
                <CssSearchField
                  label="Quick Find"
                  variant="standard"
                  color="primary"
                  fullWidth
                  defaultValue={query || null}
                  {...register("search")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {showClearButton && (
                          <IconButton
                            onClick={handleClickOnClearButton}
                            sx={cancelButtonStyle}
                          >
                            <Clear fontSize="large" />
                          </IconButton>
                        )}
                        {!showClearButton && (
                          <IconButton type="submit">
                            <Search fontSize="large" color="primary" />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </form>
            )}
          </Box>
        </Stack>

        <Box sx={pageTabsContainer}>
          <StaffTabs setTabChanged={setTabChanged} />
        </Box>
      </Container>
      <StaffActions />
      <Box sx={admissionsContainer}>
        <Outlet context={[schoolId, query, setQuery]} />
      </Box>
    </StaffActionsProvider>
  );
}
