import moment from "moment";
import ProcessStageElement from "./ProcessStageElement";
import dateTimeFormats from "../../../../../utils/constants/dateTimeFormats";
import { generateGUID } from "../../../../../utils/constants/utils";

export default class AdmissionProcessStage {
  constructor(_stage) {
    const stage = _stage || {};
    this.id = stage.id || null;
    this.stageId = generateGUID();
    this.name = stage.name || "Initial application";
    this.description = stage.description || "";
    this.deadline =
      (stage.deadline &&
        moment.utc(stage.deadline).format(dateTimeFormats.MMDDYYYY)) ||
      null;
    this.admissionProcessId =
      stage.admission_process_id || stage.admissionProcessId || null;
    this.includeInTimeline =
      stage.include_in_time_line || stage.includeInTimeline || false;
    this.previousStageId =
      stage.previous_stage_id || stage.previousStageId || null;
    this.nextStageId = stage.next_stage_id || stage.nextStageId || null;
    this.order = stage.order || 1;
    this.elements = stage.elements || [];
    if (stage.stage_elements) {
      this.elements = [];
      stage.stage_elements.forEach((ele) => {
        this.elements.push(new ProcessStageElement(ele));
      });
    }
  }

  serialize() {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      deadline: this.deadline,
      include_in_time_line: this.includeInTimeline,
      admission_process_id: this.admissionProcessId,
      previous_stage_id: this.previous_stage_id,
      next_stage_id: this.next_stage_id,
      order: this.order,
      stage_elements_attributes: this.elements.map((element) =>
        new ProcessStageElement(element).serialize()
      ),
    };
  }
}
