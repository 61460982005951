import React from "react";
import StarOutlineOutlinedIcon from "@mui/icons-material/StarOutlineOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { textCenter, cursorPointer } from "../../../../sharedStyles";
import { tableStyles, primaryIcon } from "./EmergencyContacts.styles";
import { formatPhoneNumber } from "../../../../../utils/constants/utils";

export default function EmergencyContactTable({
  emergencyContacts,
  handleTableClick,
  managePermission,
}) {
  return (
    <TableContainer>
      <Table sx={tableStyles} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="left">Relationship</TableCell>
            <TableCell align="left">Email</TableCell>
            <TableCell align="left">Phone</TableCell>
            {managePermission && <TableCell />}
          </TableRow>
        </TableHead>
        <TableBody>
          {emergencyContacts.map((contact) => (
            <TableRow key={contact.id} hover>
              <TableCell
                component="th"
                scope="contact"
                sx={managePermission && cursorPointer}
                onClick={() => managePermission && handleTableClick(contact.id)}
              >
                <Typography variant="h6">
                  {contact.first_name}
                  {contact.is_primary && (
                    <StarOutlineOutlinedIcon sx={primaryIcon} />
                  )}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>{contact.relationship}</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>{contact.email}</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>{formatPhoneNumber(contact.cell_phone)}</Typography>
              </TableCell>
              {managePermission && (
                <TableCell sx={textCenter}>
                  <SettingsOutlinedIcon
                    sx={cursorPointer}
                    onClick={() => handleTableClick(contact.id)}
                  />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
