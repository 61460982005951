import theme from "../utils";

const stickyCell = {
  height: "90px",
  position: "sticky",
  left: 0,
  backgroundColor: "white",
  zIndex: 98,
  py: 0,
  width: "100vw",
  display: "table-cell",
};

const socialDevelopmentCell = {
  color: "black",
  borderColor: "black",
  py: 0,
  position: "relative",
};

const socialDevelopmentCellComment = {
  ...socialDevelopmentCell,
  width: "560px",
  minWidth: "560px",
  height: "90px",
};

const socialDevelopmentCellName = {
  ...socialDevelopmentCell,
  fontSize: "20px",
};

const socialDevelopmentCellNameContainer = {
  whiteSpace: "nowrap",
};

const socialDevelopmentRowCellGrade = {
  ...socialDevelopmentCell,
  px: 0,
  width: "100px",
  height: "70px",
  // borderLeft: "1px solid black",
  borderRight: "1px solid black",
  backgroundColor: "white",
  "& :hover": {
    cursor: "pointer",
    backgroundColor: "#ededed",
  },
  "& p": {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

const socialDevelopmentSquare = {
  "&:focus-within": {
    border: `6px solid ${theme.palette.cell.selected}`,
  },
  height: "100%",
  width: "100%",
  fontSize: "20px",
  fontWeight: "400",
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
};

const socialDevelopmentInput = {
  textAlign: "center",
  color: "black",
  textTransform: "uppercase",
};

const socialDevelopmentCellPadding = {
  borderBottom: "none",
};

const socialDevelopmentLockIcon = {
  position: "absolute",
  top: 0,
  mt: "5px",
};

export {
  socialDevelopmentCell,
  socialDevelopmentCellName,
  socialDevelopmentCellNameContainer,
  socialDevelopmentRowCellGrade,
  socialDevelopmentSquare,
  socialDevelopmentInput,
  socialDevelopmentCellComment,
  socialDevelopmentCellPadding,
  socialDevelopmentLockIcon,
  stickyCell,
};
