import { tableCellClasses, tableRowClasses } from "@mui/material";

const tableStyles = {
  minWidth: 650,
  [`& .${tableCellClasses.root}`]: {
    borderBottom: "none",
  },
  [`& .${tableRowClasses.root}:not(:last-child)`]: {
    borderBottom: "1px solid #d9d9d9",
  },
  "& .MuiTableCell-head": {
    color: "#8f8e8e",
    fontWeight: "500 !important",
  },
  "& .MuiTableCell-body": {
    color: "black",
    fontSize: 16,
  },
};

const chipStyles = {
  "& .MuiChip-label": {
    fontSize: "14px",
    color: "black",
  },
  "& .MuiChip-deleteIcon": {
    color: "black",
  },
};

export { tableStyles, chipStyles };
