import React, { useEffect, useState } from "react";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import {
  font16,
  font20,
  font26,
  font32,
  formButtons,
  formContainer,
  formHeader,
  formTitle,
  mb10,
  mb45,
  mt25,
} from "../sharedStyles";
import {
  reportCardDetail,
  reportCardDrawer,
  reportCardGradeBox,
  reportCardSkillsContainer,
} from "./ReportCardTKKDrawer.styles";
import prekDevelopmentService from "../../service/preKDevelopmentService";

export default function ReportCardPrekDrawer({
  selectedPrekStudent,
  selectedPrekStudentDomain,
  term,
  handleDrawerClose,
}) {
  const [prekDomainElements, setPrekDomainElements] = useState([]);

  const getPrekDomainElements = async () => {
    const response =
      await prekDevelopmentService.fetchStudentsPrekDomainElements(
        term.id,
        selectedPrekStudent,
        selectedPrekStudentDomain
      );

    if (response.data) {
      setPrekDomainElements(response.data);
    }
  };

  useEffect(() => {
    (async () => {
      await getPrekDomainElements();
    })();
  }, []);

  return (
    <Box sx={reportCardDrawer}>
      <Stack direction="row" sx={formHeader}>
        <Typography sx={formTitle}>
          {selectedPrekStudentDomain.prek_development_domain.name}
        </Typography>
        <Stack direction="row">
          <Button sx={formButtons} onClick={() => handleDrawerClose()}>
            Close
          </Button>
        </Stack>
      </Stack>
      <Box sx={formContainer}>
        <Box sx={reportCardDetail}>
          <Typography sx={font32}>
            {selectedPrekStudent?.first_name} {selectedPrekStudent?.last_name}
          </Typography>
          <Typography sx={font20}>
            {selectedPrekStudent.teacher?.first_name}{" "}
            {selectedPrekStudent.teacher?.last_name}
          </Typography>
        </Box>
        <Box sx={mb45}>
          <Box sx={mt25}>
            <TextField
              id="comment"
              label="Comment"
              fullWidth
              type="text"
              multiline
              rows={4}
              disabled
              value={selectedPrekStudentDomain.comment || ""}
            />
          </Box>
        </Box>
        <Box>
          <Typography sx={{ ...font26, ...mb10 }}>
            {selectedPrekStudentDomain.prek_development_domain.name}
          </Typography>
          {prekDomainElements.map((element) => (
            <Box key={element.id} sx={reportCardSkillsContainer}>
              <Box sx={reportCardGradeBox}>{element.grade}</Box>
              <Box sx={font16}>
                {element.prek_development_domain_element.name}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
