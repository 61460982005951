import React, { useEffect, useState } from "react";
import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";
import QontoStepIcon from "../../QontoStepIcon";
import { submitButton } from "../../Admissions/Setup/RegistrationForm.style";
import admissionProcessService from "../../../service/admissionProcessService";
import StageElementType from "../../Admissions/Setup/AdmissionProcessBuilder/Models/constants";
import { formatDateTime } from "../../../utils/constants/utils";

export default function Checklist() {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { stageInstances } = useOutletContext();
  const [stageInstancesToRender, setStageInstancesToRender] =
    useState(stageInstances);

  const formattedParams = () => {
    let paramsUrl = "";
    const processId = searchParams.get("process_id");
    if (processId) {
      paramsUrl = paramsUrl.concat(`&process_id=${processId}`);
    }

    return paramsUrl;
  };

  const navigateTopFormFill = (elementInstanceId, formId) => {
    admissionProcessService.startElementInstance(elementInstanceId);
    navigate(
      `/guardian/${
        params.id
      }/form-fill/${formId}?element_instance_id=${elementInstanceId}${formattedParams()}`
    );
  };

  const navigateToPaymentPage = (elementInstanceId, url) => {
    admissionProcessService.startElementInstance(elementInstanceId);
    localStorage.setItem(
      "redirectUrl",
      `${location.pathname}?element_to_complete=${elementInstanceId}`
    );
    window.location.href = url;
  };

  const isStageInstanceCompleted = (stageInstace) => {
    let isCompleted = true;
    stageInstace.element_instances.forEach((elementInstance) => {
      if (elementInstance.status !== "completed") isCompleted = false;
    });
    return isCompleted;
  };

  const isDeadlineCrossed = (deadline) => {
    const currentDate = new Date();
    const deadlineDate = new Date(deadline);
    return deadlineDate < currentDate;
  };

  const getPaymentUrl = (paymentProperties) => {
    if (
      !paymentProperties.earlyFeePaymentUrl ||
      (paymentProperties.deadline &&
        isDeadlineCrossed(paymentProperties.deadline))
    )
      return paymentProperties.lateFeePaymentUrl;

    return paymentProperties.earlyFeePaymentUrl;
  };

  const elementInstanceAction = (elementInstance) => {
    switch (elementInstance.stage_element.element_type) {
      case StageElementType.FORM:
        navigateTopFormFill(
          elementInstance.id,
          elementInstance.stage_element.properties.id
        );
        break;
      case StageElementType.FEE:
        {
          const paymentUrl = getPaymentUrl(elementInstance.properties);
          navigateToPaymentPage(elementInstance.id, paymentUrl);
        }
        break;
      default:
        navigateTopFormFill(
          elementInstance.id,
          elementInstance.stage_element.properties.id
        );
    }
  };

  const getActionButtonName = (elementInstance) => {
    if (elementInstance.status === "in_process") return "Continue";

    return elementInstance.stage_element.element_type === StageElementType.FORM
      ? "Start"
      : "Pay Fee";
  };

  useEffect(() => {
    const elementInstanceId = searchParams.get("element_to_complete");
    if (elementInstanceId)
      admissionProcessService
        .markElementCompleted(elementInstanceId)
        .then(() => {
          const updatedStages = stageInstancesToRender.map((stage) => ({
            ...stage,
            element_instances: stage.element_instances.map((element) => {
              if (element.id === elementInstanceId) {
                return { ...element, status: "completed" };
              }
              return element;
            }),
          }));
          setStageInstancesToRender(updatedStages);
          searchParams.delete("element_to_complete");
          setSearchParams(searchParams);
        });
  }, [searchParams.get("element_to_complete")]);

  useEffect(() => {
    setStageInstancesToRender(stageInstances);
  });

  const getElementDescription = (elementInstance) => {
    if (elementInstance.stage_element.element_type === StageElementType.FEE) {
      return elementInstance.properties.earlyFeeName &&
        !isDeadlineCrossed(elementInstance.properties.deadline)
        ? `${elementInstance.properties.earlyFeeName} ${
            elementInstance.properties.earlyFeeCost || ""
          }`
        : `${elementInstance.properties.lateFeeName} ${
            elementInstance.properties.lateFeeCost || ""
          }`;
    }
    return elementInstance.stage_element.name;
  };

  return stageInstancesToRender.map((stageInstance) => (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ borderBottom: 0 }}>
              <Stack direction="row" alignItems="center">
                <QontoStepIcon
                  checked={isStageInstanceCompleted(stageInstance)}
                />
                <Typography color="black" fontSize={20} fontWeight={500} ml={1}>
                  {stageInstance.admission_process_stage.name}
                </Typography>
              </Stack>
            </TableCell>
            <TableCell sx={{ borderBottom: 0 }}>
              <Typography color="black" fontWeight={500}>
                DUE
              </Typography>
            </TableCell>
            <TableCell sx={{ borderBottom: 0 }}>
              <Typography color="black" fontWeight={500}>
                COMPLETED
              </Typography>
            </TableCell>
            <TableCell sx={{ borderBottom: 0 }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {stageInstance.element_instances.map((elementInstance) => (
            <TableRow>
              <TableCell sx={{ borderBottom: 0, py: "5px" }}>
                <Stack direction="row" alignItems="center" ml="25px">
                  <QontoStepIcon
                    checked={elementInstance.status === "completed"}
                  />
                  <Typography color="black" ml={1}>
                    {getElementDescription(elementInstance)}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell sx={{ borderBottom: 0, py: "5px" }}>
                <Typography color="black">
                  {stageInstance.admission_process_stage.deadline
                    ? formatDateTime(
                        stageInstance.admission_process_stage.deadline
                      )
                    : "-"}
                </Typography>
              </TableCell>
              <TableCell sx={{ borderBottom: 0, py: "5px" }}>
                <Typography color="black">
                  {elementInstance.date_completed
                    ? formatDateTime(elementInstance.date_completed)
                    : "-"}
                </Typography>
              </TableCell>
              <TableCell sx={{ borderBottom: 0, py: "5px" }}>
                {elementInstance.status !== "completed" && (
                  <Button
                    sx={{
                      ...submitButton,
                      backgroundColor:
                        elementInstance.status === "in_process"
                          ? "#ED6C02"
                          : "#2196F3",
                      width: "100%",
                      maxWidth: 100,
                    }}
                    onClick={() => elementInstanceAction(elementInstance)}
                  >
                    {getActionButtonName(elementInstance)}
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ));
}
