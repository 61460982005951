import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { mb45, font20 } from "../../../sharedStyles";
import { calculationsTableCellBold } from "./Calculations.styles";
import termGradeService from "../../../../service/termGradeService";
import CalculationsRow from "./CalculationsRow";
import formattedGrade from "../../../../utils/formattedGrade";

export default function Calculations({ activeGrade, specialMarks }) {
  const [categoryScores, setCategoryScores] = useState({});

  const getScores = async () => {
    const studentId = activeGrade?.termGrade?.student.id;
    const klassesId = activeGrade?.termGrade?.klass?.id;
    const response = await termGradeService.fetchCategoryScores(studentId, {
      params: {
        klass_id: klassesId,
      },
    });

    if (response.assignment_student) {
      setCategoryScores(response.category_scores);
    }
  };

  const letterGrade = formattedGrade(
    activeGrade?.termGrade?.calculated_grade,
    100,
    activeGrade?.termGrade?.klass?.grading_scale?.marking_codes,
    specialMarks
  );

  useEffect(() => {
    getScores();
  }, []);

  return (
    <Box sx={mb45}>
      <Typography sx={font20}>
        {activeGrade?.termGrade?.term?.name} Calculations
      </Typography>
      <TableContainer>
        <Table>
          <TableBody>
            {categoryScores.length > 0 &&
              categoryScores.map((cs) => (
                <CalculationsRow
                  key={cs.id}
                  category={cs}
                  isLastRow={categoryScores[categoryScores.length - 1] === cs}
                  markingCodes={
                    activeGrade?.termGrade?.klass?.grading_scale?.marking_codes
                  }
                  specialMarks={specialMarks}
                  isPassFail={activeGrade?.termGrade?.klass?.is_pass_fail}
                />
              ))}
            <TableRow>
              <TableCell sx={calculationsTableCellBold}>
                {activeGrade?.termGrade?.term?.name} Total
              </TableCell>
              <TableCell sx={calculationsTableCellBold}>
                {activeGrade?.termGrade?.calculated_grade}% {letterGrade}
              </TableCell>
              <TableCell sx={calculationsTableCellBold} />
              <TableCell />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
