import React, { useContext } from "react";
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { AccessTime, Delete } from "@mui/icons-material";
import { useDrop } from "react-dnd";
import { ButtonBase } from "@mui/material";
import { ProcessBuilderContext } from "./ProcessBuilderContext";
import ProcessStageElement from "./Models/ProcessStageElement";
import { stageContainer, stageElement } from "./admissionProcess.style";

export default function ProcessStage({ stage, stageIndex }) {
  const {
    process,
    updateProcess,
    selectedElement,
    selectElement,
    updateCurrentStageIndex,
    currentStageIndex,
  } = useContext(ProcessBuilderContext);

  const selected = (elementId) => elementId === selectedElement?.elementId;

  const [, drop] = useDrop({
    accept: "stage-element-type",
    drop: (item) => {
      const newField = new ProcessStageElement(item);
      const updatedProcessStages = [...process.stages];

      newField.properties.order =
        updatedProcessStages[stageIndex].elements.length + 1;
      // Update the specific section by adding the new field
      updatedProcessStages[stageIndex] = {
        ...updatedProcessStages[stageIndex],
        elements: [...updatedProcessStages[stageIndex].elements, newField],
      };

      // Update the process with the new stages array
      updateProcess({ ...process, stages: updatedProcessStages });

      selectElement(newField);
    },
  });

  const deleteStage = (e) => {
    e.stopPropagation();

    let updatedStages = [...process.stages];

    updatedStages.splice(stageIndex, 1);

    updatedStages = updatedStages.map((s, index) => ({
      ...s,
      order: index + 1,
    }));
    updateProcess({ ...process, stages: updatedStages });

    if (currentStageIndex === stageIndex) updateCurrentStageIndex(0);
  };

  const deleteElement = (elementIndex, elementId) => {
    const updatedStages = [...process.stages];

    updatedStages[stageIndex].elements.splice(elementIndex, 1);

    updatedStages[stageIndex].elements = updatedStages[stageIndex].elements.map(
      (ele, index) => {
        const element = ele;
        element.properties.order = index + 1;
        return element;
      }
    );

    updateProcess({ ...process, stages: updatedStages });

    if (selectedElement.elementId === elementId) selectElement(null);
  };

  const getStageElementTimeIconColor = (element) => {
    if (element.properties.includeInTimeline)
      return `${
        (selected(element.elementId) && "black") || "#00B0FF"
      } !important`;
    return `${
      (selected(element.elementId) && "white") || "rgba(217, 217, 217, 1)"
    } !important`;
  };

  return (
    <Card
      onClick={() => selectElement(stage)}
      ref={drop}
      sx={stageContainer(selectedElement?.stageId === stage.stageId)}
    >
      <ListItem sx={{ color: "white", background: "#616161" }}>
        <ListItemText primary={process.stages[stageIndex].name} />
        {process.stages.length !== 1 && (
          <ButtonBase onClick={deleteStage}>
            <Delete sx={{ mr: 1 }} />
          </ButtonBase>
        )}
        <AccessTime
          sx={{ color: stage.includeInTimeline ? "#00B0FF" : "white" }}
        />
      </ListItem>
      <List
        component="nav"
        aria-label="application process steps"
        sx={{
          padding: 2,
          color: "black",
          "& .MuiListItem-root": { py: 0.5 },
          "& .MuiSvgIcon-root": { color: "#D6D6D6" },
        }}
      >
        {stage.elements.map((element, index) => (
          <ListItem sx={selected(element.elementId) && stageElement}>
            <ListItemText
              onClick={(e) => {
                e.stopPropagation();
                updateCurrentStageIndex(stageIndex);
                selectElement(element);
              }}
              sx={{ width: "60%", cursor: "pointer" }}
              primary={element.name}
            />
            <ListItemText
              sx={{ textAlign: "center", color: "black" }}
              primary={element.type}
            />
            <ButtonBase
              onClick={(e) => {
                e.stopPropagation();
                deleteElement(index, element.elementId);
              }}
            >
              <Delete sx={{ mr: 1 }} />
            </ButtonBase>
            <AccessTime
              sx={{
                color: getStageElementTimeIconColor(element),
              }}
            />
          </ListItem>
        ))}
        <ListItem>
          <ListItemText
            sx={{ textAlign: "center" }}
            primary="Add an element to your process"
          />
        </ListItem>
      </List>
    </Card>
  );
}
