import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, CircularProgress, Grid } from "@mui/material";
import { fetchGuardianKidProfile } from "../../../../service/guardiansService";
import GuardianSection from "./GuardianSection";
import EmergencyContactSection from "./EmergencyContactSection";
import { loader } from "../../../sharedStyles";
import NameInformation from "./NameInformation";
import ContactInformation from "./ContactInformation";
import { boxContainer, gridItemBorder } from "./Profile.styles";
import Demographics from "./Demographic";

export default function GuardianKidsInfoProfile() {
  const params = useParams();
  const guardianId = params.id;
  const kidId = params.kid_id;
  const [kidProfile, setKidProfile] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (guardianId && kidId) {
        try {
          setLoading(true);
          const response = await fetchGuardianKidProfile(guardianId, kidId);

          if (response.data) {
            setLoading(false);
            setKidProfile(response.data);
          }
        } catch (e) {
          // handle catch
        }
      }
    })();
  }, [kidId]);

  if (loading) {
    return <CircularProgress sx={loader} size={100} />;
  }

  const emergencyContacts = (guardians) =>
    guardians.filter((guardian) => guardian.is_emergency_contact);

  return (
    kidProfile && (
      <Box sx={boxContainer}>
        <Grid container spacing={2}>
          <Grid item md={8} sx={gridItemBorder}>
            <NameInformation kidInfo={kidProfile} />
            <ContactInformation kidInfo={kidProfile} />
          </Grid>
          <Grid item md={4} style={{ paddingTop: 0 }}>
            <Demographics kidInfo={kidProfile} />
          </Grid>
        </Grid>
        <GuardianSection guardians={kidProfile.guardians} />
        <EmergencyContactSection
          emergencyContacts={emergencyContacts(kidProfile.guardians)}
        />
      </Box>
    )
  );
}
