import React from "react";
import { Button, Link } from "@mui/material";
import { Link as RouterLink, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function AssignmentMissingGuardian({ notification }) {
  const { t } = useTranslation("notifications");
  const params = useParams();
  const { data } = notification;

  return (
    <div>
      <Link
        to={`/guardian/${params.id}/students/${data.student_id}/classes/${data.class_id}?term=${data.term_id}&type=assignments&assignments=missing`}
        component={RouterLink}
        variant="inherit"
      >
        <Button variant="contained">{t("goToClass")}</Button>
      </Link>
    </div>
  );
}
