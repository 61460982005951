import React from "react";
import { Stack, Box, Typography } from "@mui/material";
import {
  klassStudentDetailGradeTxt,
  klassStudentDetailGradeLetter,
} from "./KlassStudentDetail.styles";
import { black, ml30 } from "../../../sharedStyles";
import formattedGrade from "../../../../utils/formattedGrade";

export default function KlassStudentDetailTermGrade({
  termGrade,
  specialMarks,
  markingCodes,
}) {
  const letterGrade = formattedGrade(
    termGrade.calculated_grade,
    100,
    markingCodes,
    specialMarks
  );

  return (
    <Stack direction="row" alignItems="center">
      <Typography sx={klassStudentDetailGradeTxt}>
        CURRENT <br /> REPORTED <br /> GRADE
      </Typography>
      <Box sx={ml30}>
        <Typography sx={klassStudentDetailGradeLetter}>
          {letterGrade}
        </Typography>
        {termGrade.calculated_grade && (
          <Typography sx={black}>{`${termGrade.calculated_grade}%`}</Typography>
        )}
      </Box>
    </Stack>
  );
}
