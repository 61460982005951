const tableContainer = {
  padding: "80px",
  paddingTop: "20px",
};

const tableHead = {
  borderBottom: "1.5px solid #000000",
};

const tableHeadRow = {
  "& th": {
    fontWeight: 500,
    fontSize: "11px",
    lineHeight: "16px",
    letterSpacing: "-0.3px",
    color: "#000000",
    textTransform: "uppercase",
  },
};

const tableBodyRow = {
  borderBottom: "1.5px solid #BFC7CA",
  "& td": {
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "-0.5px",
    color: "#000000",
  },
};

const gridItemDate = {
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "18px",
  letterSpacing: "-0.3px",
  color: "#000000",
  paddingTop: "20px",
};

const gridItemSelect = {
  float: "right",
  marginTop: "10px",
  minWidth: 300,
};

export {
  tableContainer,
  tableHead,
  tableHeadRow,
  tableBodyRow,
  gridItemDate,
  gridItemSelect,
};
