const reportCardDetail = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "baseline",
};

const reportCardInfoContainer = {
  display: "flex",
  justifyContent: "space-between",
  flexGrow: 0.3,
};

const reportCardSkillsContainer = {
  display: "flex",
  borderTop: "1px solid",
  borderTopColor: "#BFC7CA",
  borderBottomColor: "#BFC7CA",
  padding: "16px",
  alignItems: "center",
};

const reportCardDrawer = { width: "800px" };

const reportCardGradeBox = {
  borderRadius: "5px",
  backgroundColor: "#F5F5F5",
  padding: "18px 18px 18px 18px",
  marginRight: "15px",
  minWidth: "60px",
  minHeight: "60px",
  display: "flex",
  justifyContent: "center",
  textTransform: "uppercase",
};

export {
  reportCardDrawer,
  reportCardDetail,
  reportCardInfoContainer,
  reportCardSkillsContainer,
  reportCardGradeBox,
};
