import React from "react";
import { Box, Grid, Typography, Link } from "@mui/material";
import { contentTitleContainer } from "./Content.styles";
import previewLinkStyle from "./Preview.style";

export default function Preview({ text, shortlink }) {
  return (
    <Grid container>
      <Grid item md={12} sx={contentTitleContainer}>
        <Typography variant="subtitle">Text Message Preview</Typography>
      </Grid>
      <Grid item>
        <Box color="#8F8F8F">
          {text}{" "}
          <Link href={shortlink} sx={previewLinkStyle}>
            {shortlink}
          </Link>
        </Box>
      </Grid>
    </Grid>
  );
}
