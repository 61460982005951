import axios from "../utils/axios-util";

const admissionFormsEndpoint = "/api/admission_forms";

const create = async (formData, schoolYearId) => {
  const { data } = await axios.post(admissionFormsEndpoint, formData, {
    params: { school_year_id: schoolYearId },
  });
  if (!data) {
    console.error("Error fetching form list");
    return null;
  }
  return data;
};

const fetchAllForms = async (schoolYearId) => {
  const { data } = await axios.get(admissionFormsEndpoint, {
    params: { school_year_id: schoolYearId },
  });
  if (!data) {
    console.error("Error fetching form list");
    return [];
  }
  return data;
};

const fetchForm = async (formId) => {
  const { data } = await axios.get(`${admissionFormsEndpoint}/${formId}`);
  if (!data) {
    console.error("Error fetching form");
    return {};
  }
  return data;
};

const fetchByToken = async (token) => {
  const { data } = await axios.get(`${admissionFormsEndpoint}/token/${token}`);
  if (!data) {
    console.error("Error fetching form");
    return {};
  }
  return data;
};

const updateForm = async (form) => {
  const { data } = await axios.put(
    `${admissionFormsEndpoint}/${form.id}`,
    form
  );
  if (!data) {
    console.error("Error fetching form");
    return {};
  }
  return data;
};

const saveFormResponse = async (
  formData,
  elementInstanceId,
  isPartial,
  studentId
) => {
  const { data } = await axios.post(
    `${admissionFormsEndpoint}/save_form_response`,
    formData,
    {
      params: {
        element_instance_id: elementInstanceId,
        is_partial: isPartial,
        student_id: studentId,
      },
    }
  );
  if (!data) {
    console.error("Error fetching form list");
    return null;
  }
  return data;
};

const getInquiryFormResponses = async (schoolYearId) => {
  const { data } = await axios.get(
    `${admissionFormsEndpoint}/inquiry_form_responses`,
    {
      params: { school_year_id: schoolYearId },
    }
  );
  if (!data) {
    console.error("Error fetching form list");
    return [];
  }
  return data;
};

const getInquiryForm = async (schoolYearId) => {
  const { data } = await axios.get(`${admissionFormsEndpoint}/inquiry_form`, {
    params: { school_year_id: schoolYearId },
  });
  if (!data) {
    console.error("Error fetching form list");
    return {};
  }
  return data;
};

const getFormRender = async (
  formId,
  elementInstanceId = null,
  formResponseId = null,
  withoutPages = false
) => {
  const { data } = await axios.get(`${admissionFormsEndpoint}/form_render`, {
    params: {
      id: formId,
      element_instance_id: elementInstanceId,
      without_pages: withoutPages,
      form_response_id: formResponseId,
    },
  });
  if (!data) {
    console.error("Error fetching form list");
    return {};
  }
  return data;
};

export default {
  create,
  fetchAllForms,
  fetchForm,
  fetchByToken,
  updateForm,
  saveFormResponse,
  getInquiryFormResponses,
  getInquiryForm,
  getFormRender,
};
