import axios from "../utils/axios-util";

const customFieldsEndpoint = "/api/custom_fields";

const create = async (customField) => {
  const { data } = await axios.post(customFieldsEndpoint, customField);
  if (!data) {
    console.error("Error creating custom field.");
    return null;
  }
  return data;
};

const update = async (customField) => {
  const { data } = await axios.put(
    `${customFieldsEndpoint}/${customField.id}`,
    customField
  );
  if (!data) {
    console.error("Error updating custom field.");
    return null;
  }
  return data;
};

const deleteField = async (customFieldId) => {
  const { data } = await axios.delete(
    `${customFieldsEndpoint}/${customFieldId}`
  );
  if (!data) {
    console.error("Error updating custom field.");
    return null;
  }
  return data;
};

const fetchAllCustomFields = async () => {
  const { data } = await axios.get(customFieldsEndpoint);
  if (!data) {
    console.error("Error fetching form list");
    return [];
  }
  return data;
};

const updateFieldsOrder = async (fieldsData) => {
  const { data } = await axios.put(
    `${customFieldsEndpoint}/update_fields_order`,
    fieldsData
  );
  if (!data.success) {
    console.error("Error updating order");
  }
  return data.success;
};

export default {
  create,
  update,
  deleteField,
  fetchAllCustomFields,
  updateFieldsOrder,
};
