import React, { createContext, useMemo, useState } from "react";
import { Form } from "./Models/Form";

export const FormBuilderContext = createContext({
  selectedFieldId: null,
  formFields: [],
  form: new Form(),
  currentSectionIndex: 0,
  currentPageIndex: 0,
  onDragStop: false,
  onDragStart: null,
  onDragEnd: false,
  onDrag: null,
});

export function FormBuilderContextProvider({ children }) {
  const [form, setForm] = useState(new Form());
  const [formFields, setFormFields] = useState([]);
  const [selectedFieldId, setSelectedFieldId] = useState(null);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [onDragStop, setOnDragStop] = useState(false);
  const [onDragEnd, setOnDragEnd] = useState(false);
  const [onDragStart, setOnDragStart] = useState(null);
  const [onDrag, setOnDrag] = useState(null);
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const selectField = (fieldId) => {
    setSelectedFieldId(fieldId);
  };

  const updateFormFields = (fields) => {
    setFormFields(fields);
  };

  const updateForm = (updatedForm) => {
    setForm(updatedForm);
    setUnsavedChanges(true);
  };

  const saveChanges = () => {
    setUnsavedChanges(false);
  };

  const updateCurrentSectionIndex = (sectionIndex) => {
    setCurrentSectionIndex(sectionIndex);
  };

  const updateCurrentPageIndex = (pageIndex) => {
    setCurrentPageIndex(pageIndex);
  };

  const fireOnDragStop = () => {
    setOnDragStop(true);
  };

  const cleanOnDragStop = () => {
    setOnDragStop(false);
  };

  const fireOnDragStart = (data) => {
    setOnDragStart(data);
  };

  const cleanOnDragStart = () => {
    setOnDragStart(null);
  };

  const fireOnDragEnd = () => {
    setOnDragEnd(true);
  };

  const cleanOnDragEnd = () => {
    setOnDragEnd(false);
  };

  const fireOnDrag = (data) => {
    setOnDrag(data);
  };

  const cleanOnDrag = () => {
    setOnDrag(null);
  };

  const contextValue = useMemo(
    () => ({
      selectField,
      selectedFieldId,
      formFields,
      form,
      updateFormFields,
      updateForm,
      saveChanges,
      unsavedChanges,
      updateCurrentSectionIndex,
      currentSectionIndex,
      updateCurrentPageIndex,
      currentPageIndex,
      fireOnDragStop,
      cleanOnDragStop,
      onDragStop,
      fireOnDragStart,
      cleanOnDragStart,
      onDragStart,
      fireOnDragEnd,
      cleanOnDragEnd,
      onDragEnd,
      fireOnDrag,
      cleanOnDrag,
      onDrag,
    }),
    [
      selectedFieldId,
      formFields,
      form,
      currentSectionIndex,
      unsavedChanges,
      currentPageIndex,
      onDragStop,
      onDragStart,
      onDragEnd,
      onDrag,
    ]
  );

  return (
    <FormBuilderContext.Provider value={contextValue}>
      {children}
    </FormBuilderContext.Provider>
  );
}
