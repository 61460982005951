import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ButtonBase } from "@mui/material";
import tableStyles from "./admissionProcesses.style";
import admissionProcessService from "../../../service/admissionProcessService";
import { formatDateTime } from "../../../utils/constants/utils";

export default function AdmissionProcesses() {
  const [processes, setProcesses] = React.useState([]);
  const params = useParams();
  const schoolId = params.school_id;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  React.useEffect(() => {
    admissionProcessService
      .fetchAllProcesses(searchParams.get("school_year"))
      .then((response) => {
        setProcesses(response.data);
      });
  }, [searchParams.get("school_year")]);

  const navigateToProcessBuilder = (processId) => {
    navigate(
      `/school/${schoolId}/admissions/setup/process-builder/form?process_id=${processId}&school_year=${searchParams.get(
        "school_year"
      )}`
    );
  };

  return (
    <TableContainer sx={tableStyles}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="center">STAGES</TableCell>
            <TableCell align="center">Date Modified</TableCell>
            <TableCell align="center">Active</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {processes.map((process) => (
            <TableRow key={process.id}>
              <TableCell component="th" scope="row">
                <ButtonBase
                  onClick={() => {
                    navigateToProcessBuilder(process.id);
                  }}
                >
                  {process.name}
                </ButtonBase>
              </TableCell>
              <TableCell align="center">
                {process.admission_process_stages.length}
              </TableCell>
              <TableCell align="center">
                {formatDateTime(process.updated_at)}
              </TableCell>
              <TableCell align="center">{process.active}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
