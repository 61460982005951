const scheduleBox = {
  fontSize: "28px",
  lineHeight: "36px",
  letterSpacing: "-0.05em",
  pb: "10px",
};

const scheduleCard = {
  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
  borderRadius: "6px",
  maxHeight: 600,
  minHeight: 600,
  overflowY: "auto",
};

const scheduleBoxDate = {
  fontSize: "25.92px",
  lineHeight: "31px",
  letterSpacing: "-0.04em",
  color: "#3F464B",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const tableContainer = { maxHeight: 470 };

const tableHead = {
  fontWeight: 700,
  fontSize: "10px",
  lineHeight: "12px",
  textTransform: "uppercase",
  color: "#000000",
};

const tableBodyTime = {
  fontWeight: 300,
  fontSize: "14px",
  textTransform: "uppercase",
  color: "#000000",
};

const tableBodyClass = {
  "& p": {
    fontWeight: 500,
    fontSize: "16px",
    color: "#000000",
  },
};

export {
  scheduleBox,
  scheduleCard,
  scheduleBoxDate,
  tableContainer,
  tableHead,
  tableBodyTime,
  tableBodyClass,
};
