import axios from "../utils/axios-util";

const gradingScalesEndpoint = "/api/grading_scales";

const fetchAllGradingScales = async (params) => {
  const { data } = await axios.get(`${gradingScalesEndpoint}`, params);
  if (!data.data) {
    console.error("Error fetching day templates from the API");
    return [];
  }
  return data;
};

const fetchAllWithSocialDevelopmentSkillsAndStudents = async (params) => {
  const { data } = await axios.get(
    `${gradingScalesEndpoint}_with_sd_skills_students`,
    params
  );
  if (!data.data) {
    console.error("Error fetching grading scales from API");
    return [];
  }
  return data;
};

const updateMarkingCodes = async (params) => {
  const { data } = await axios.put(
    `${gradingScalesEndpoint}/update_marking_codes`,
    params
  );

  if (!data.data) {
    console.error("Error updating marking codes from the API");
    return [];
  }
  return data;
};

export default {
  fetchAllGradingScales,
  fetchAllWithSocialDevelopmentSkillsAndStudents,
  updateMarkingCodes,
};
