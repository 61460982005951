import React, { useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Link,
  Pagination,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { PrivacyTipOutlined } from "@mui/icons-material";
import { studentTablePaper } from "../../studentsTable.style";
import {
  studentsSubjectTableHeadCell,
  studentsSubjectTableCell,
  studentsSubjectTableHeadCellActive,
  studentsSubjectTableHeaderText,
  studentsSubjectTableName,
  studentTableCell,
  studentsSubjectNameCell,
} from "./StudentsSubjectTable.styles";
import { tableAvatar, paginationContent } from "../../sharedStyles";
import APP_PERMISSIONS from "../../../utils/constants/permissions";
import PERMISSION_TYPES from "../../../utils/constants/permission_types";
import { PermissionsContext } from "../../../context/PermissionsContext";
import Authorize from "../../Authorize";

const headCells = [
  {
    id: "student",
    numeric: false,
    disablePadding: true,
    label: "NAME",
    sortable: true,
  },
  {
    id: "medical_badge",
    numeric: false,
    disablePadding: true,
    label: "",
    sortable: false,
    width: "5%",
  },
  {
    id: "klass",
    numeric: false,
    disablePadding: true,
    label: "CLASS",
    sortable: true,
  },
  {
    id: "grade-level",
    numeric: false,
    disablePadding: true,
    label: "GRADE LEVEL",
    sortable: true,
  },
  {
    id: "assignments",
    numeric: true,
    disablePadding: true,
    label: "ASSIGNMENTS",
    sortable: true,
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const isHeaderActive = (id) =>
    orderBy === id
      ? studentsSubjectTableHeadCellActive
      : studentsSubjectTableHeadCell;

  const renderSortChevron = (id) => {
    if (id !== orderBy) return "";

    return order === "asc" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell sx={studentsSubjectTableHeadCell} />
        {headCells.map((headCell) => (
          <TableCell
            {...(headCell.sortable && {
              onClick: createSortHandler(headCell.id),
            })}
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? "none" : "normal"}
            sx={isHeaderActive(headCell.id)}
            width={headCell.width}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <Box sx={studentsSubjectTableHeaderText(headCell.id)}>
              {headCell.label}
              {renderSortChevron(headCell.id)}
            </Box>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function StudentsSubjectTable({
  students,
  searchParams,
  setSearchParams,
  order,
  orderBy,
  setOrderBy,
  setOrder,
  setPage,
  totalPages,
  page,
  schoolId,
  subjectId,
}) {
  const klassesIds = searchParams.get("classes") || null;
  const termId = searchParams.get("term") || null;

  const { hasPermission, hasAnyPermissionType } =
    useContext(PermissionsContext);

  const managePermission = hasPermission(
    APP_PERMISSIONS.KLASSES_STUDENTS,
    PERMISSION_TYPES.MANAGE
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    const direction = isAsc ? "desc" : "asc";
    setOrder(direction);
    setOrderBy(property);

    searchParams.set("sort", property);
    searchParams.set("direction", direction);
    setSearchParams(searchParams);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
    searchParams.set("page", value);
    setSearchParams(searchParams);
  };

  function handleProfilePictureSrc(student) {
    if (student?.image_url) {
      return (
        <img
          alt="profile_image"
          src={student?.image_url.url}
          style={{
            borderRadius: "100px",
            width: "40px",
            height: "40px",
            objectFit: "cover",
          }}
        />
      );
    }
    return (
      <Avatar sx={tableAvatar}>
        {student.first_name.charAt(0).toUpperCase()}
      </Avatar>
    );
  }

  const formattedParams = () => {
    let paramsUrl = "?";
    if (klassesIds) {
      paramsUrl = paramsUrl.concat(`&classes=${klassesIds}`);
    }

    if (termId) {
      paramsUrl = paramsUrl.concat(`&term=${termId}`);
    }
    return paramsUrl;
  };

  return (
    <Authorize
      permission={hasAnyPermissionType(APP_PERMISSIONS.KLASSES_STUDENTS)}
    >
      <Box>
        <Container maxWidth="lg">
          {students === undefined ? (
            "Loading..."
          ) : (
            <Paper sx={studentTablePaper} elevation={0}>
              <TableContainer>
                <Table aria-labelledby="tableTitle" size="medium">
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={students.length}
                  />
                  <TableBody>
                    {students.map((student) => {
                      const labelId = `enhanced-table-checkbox-${student.id}`;

                      return (
                        <TableRow hover tabIndex={-1} key={student.id}>
                          {managePermission ? (
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                              sx={studentsSubjectTableCell(true)}
                            >
                              {handleProfilePictureSrc(student)}
                            </TableCell>
                          ) : (
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                              sx={studentsSubjectTableCell(false)}
                            >
                              <Avatar
                                sx={{ ...tableAvatar, cursor: "default" }}
                              >
                                {student.first_name.charAt(0).toUpperCase()}
                              </Avatar>
                            </TableCell>
                          )}

                          {managePermission ? (
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                              sx={studentsSubjectNameCell(true)}
                            >
                              <Link
                                to={`/school/${schoolId}/subjects/${subjectId}/classes/${
                                  student.klass_id
                                }/students/${student.slug}${formattedParams()}`}
                                underline="none"
                                component={RouterLink}
                              >
                                <span style={studentsSubjectTableName}>
                                  {`${student.last_name}, ${student.first_name}`}
                                </span>
                              </Link>
                            </TableCell>
                          ) : (
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                              sx={studentsSubjectNameCell(false)}
                            >
                              <span style={studentsSubjectTableName}>
                                {`${student.last_name}, ${student.first_name}`}
                              </span>
                            </TableCell>
                          )}
                          <TableCell width="5%">
                            {student.medical_badge && <PrivacyTipOutlined />}
                          </TableCell>
                          <TableCell align="center" sx={studentTableCell}>
                            {student.klass_name}
                          </TableCell>
                          <TableCell align="center" sx={studentTableCell}>
                            {student.grade_level}
                          </TableCell>
                          <TableCell align="center" sx={studentTableCell}>
                            {student.assignment_count}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Pagination
                count={totalPages}
                onChange={handleChangePage}
                sx={paginationContent}
                page={parseInt(page, 10)}
              />
            </Paper>
          )}
        </Container>
      </Box>
    </Authorize>
  );
}
