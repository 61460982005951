import React, { useContext, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Box, Button, Stack, Typography } from "@mui/material";
import {
  formButtons,
  formContainer,
  formHeader,
  formTitle,
} from "../../sharedStyles";
import GenericSubmitButton from "../../GenericSubmitButton";
import GeneralInfo from "./GeneralInfo";
import ReportCard from "./ReportCard";
import Transcript from "./Transcript";
import courseService from "../../../service/courseService";
import { SnackbarContext } from "../../../context/SnackbarContext";
import AlertDialog from "../../AlertDialog";
import { PermissionsContext } from "../../../context/PermissionsContext";
import APP_PERMISSIONS from "../../../utils/constants/permissions";
import PERMISSION_TYPES from "../../../utils/constants/permission_types";

export default function CourseForm({
  handleCourseDrawer,
  activeCourse,
  subjects,
  predefinedCourses,
  departments,
  gpaScales,
}) {
  const methods = useForm();
  const snackbarContext = useContext(SnackbarContext);
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);

  const createCourse = async (data) => {
    const response = await courseService
      .createCourse({ ...data })
      .catch((err) =>
        snackbarContext.setSnackbar({
          message: err.response.data.message,
          severity: "error",
          open: true,
        })
      );

    if (response.data) {
      snackbarContext.setSnackbar({
        open: true,
        message: "Course created.",
        severity: "success",
      });
      handleCourseDrawer(false);
    }
  };

  const updateCourse = async (data) => {
    const response = await courseService
      .updateCourse({
        ...{
          course_id: activeCourse?.id,
        },
        ...data,
      })
      .catch((err) =>
        snackbarContext.setSnackbar({
          message: err.response.data.message,
          severity: "error",
          open: true,
        })
      );

    if (response.data) {
      snackbarContext.setSnackbar({
        open: true,
        message: "Course updated.",
        severity: "success",
      });
      handleCourseDrawer(false);
    }
  };

  const onSubmit = async (data) => {
    if (data.not_nad_course) {
      // eslint-disable-next-line no-param-reassign
      data.predefined_course = "";
    } else {
      // eslint-disable-next-line no-param-reassign
      data.custom_course = "";
    }
    if (activeCourse) {
      await updateCourse(data);
    } else {
      await createCourse(data);
    }
  };

  const deleteCourse = async () => {
    const response = await courseService
      .deleteCourse(activeCourse.id)
      .catch((err) =>
        snackbarContext.setSnackbar({
          message: err.response.data.message,
          severity: "error",
          open: true,
        })
      );

    if (response) {
      snackbarContext.setSnackbar({
        message: "Course deleted.",
        severity: "success",
        open: true,
      });
      handleCourseDrawer(false);
    }
  };

  const handleCloseAlertDialog = () => {
    setAlertDialogOpen(false);
  };

  const { hasPermission } = useContext(PermissionsContext);
  const managePermission = hasPermission(
    APP_PERMISSIONS.COURSES,
    PERMISSION_TYPES.MANAGE
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack direction="row" sx={formHeader}>
          <Typography sx={formTitle}>
            {activeCourse ? "Edit Course" : "Add Course"}
          </Typography>
          <Stack direction="row">
            <Button
              sx={formButtons}
              onClick={() => handleCourseDrawer(false, null, "cancel")}
            >
              Cancel
            </Button>
            {activeCourse && managePermission && (
              <GenericSubmitButton
                text="Delete"
                submittingText="Deleting..."
                type="button"
                onClick={() => {
                  setAlertDialogOpen(true);
                }}
              />
            )}
            {managePermission && (
              <GenericSubmitButton text="Save" submittingText="Saving..." />
            )}
          </Stack>
        </Stack>

        <Box sx={formContainer}>
          <GeneralInfo
            subjects={subjects}
            predefinedCourses={predefinedCourses}
            activeCourse={activeCourse}
            departments={departments}
            gpaScales={gpaScales}
          />
          <ReportCard activeCourse={activeCourse} />
          <Transcript activeCourse={activeCourse} />
        </Box>
      </form>
      <AlertDialog
        isOpen={alertDialogOpen}
        handleClose={handleCloseAlertDialog}
        handleConfirm={deleteCourse}
      />
    </FormProvider>
  );
}
