import CharacterLimit from "./CharacterLimit";
import DateFormats from "./DateFormats";
import IsRequired from "./IsRequired";
import IsVisible from "./IsVisible";
import Options from "./Options";

const propertiesComponentMap = {
  characterLimit: CharacterLimit,
  isVisible: IsVisible,
  options: Options,
  dateFormats: DateFormats,
  isRequired: IsRequired,
};

export default propertiesComponentMap;
