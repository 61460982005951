import React, { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { klassFormTitle, klassFormRow } from "./KlassForm.styles";
import { bgWhite, formCheckBox } from "../sharedStyles";
import ErasableTextField from "../ErasableTextField";
import {
  klassFormAssignmentGradingContainer,
  klassFormAssignmentGradingWeightContainer,
} from "./DefaultAssignmentGrading.styles";
import { requiredMessage, renderError } from "../../utils/constants/forms";

export default function DefaultAssignmentGrading({ activeKlass }) {
  const {
    register,
    formState: { errors },
    control,
    setValue,
  } = useFormContext();
  const [selectedInput, setSelectedInput] = useState(
    activeKlass?.default_assignment_grading || null
  );

  let onlyHasPointAssignments = true;

  if (
    activeKlass &&
    activeKlass.assignments &&
    activeKlass.assignments.length > 0
  ) {
    activeKlass.assignments.forEach((assignment) => {
      if (assignment.grading !== "points") onlyHasPointAssignments = false;
    });
  }
  const maxPointsAsWeight = useWatch({
    control,
    name: "maxPointsAsWeight",
  });

  useEffect(() => {
    if (maxPointsAsWeight) {
      setValue("inputAs", "points", { shouldValidate: true });
      setSelectedInput("points");
    }
  }, [maxPointsAsWeight, setValue]);

  const handleInputChange = (e) => {
    setSelectedInput(e.target.value);
  };

  const defaultMaxPointsAsWeight = activeKlass
    ? activeKlass.max_points_as_weight
    : false;

  return (
    <>
      <Box>
        <Typography sx={klassFormTitle}>Default assignment grading</Typography>

        <Box sx={klassFormAssignmentGradingContainer}>
          <FormControl
            fullWidth
            sx={klassFormRow}
            error={errors?.inputAs && true}
          >
            <InputLabel id="inputAs" shrink sx={bgWhite}>
              Input as*
            </InputLabel>
            <Select
              labelId="inputAs"
              id="inputAs"
              defaultValue={activeKlass?.default_assignment_grading || ""}
              disabled={maxPointsAsWeight}
              {...register("inputAs", {
                required: requiredMessage,
                onChange: (e) => handleInputChange(e),
              })}
            >
              <MenuItem value="points">Points</MenuItem>
              <MenuItem value="percent">Percentage</MenuItem>
              <MenuItem value="letter_grade">Letter Grade</MenuItem>
            </Select>
            {errors?.inputAs && renderError(errors?.inputAs.message)}
          </FormControl>
          {selectedInput === "points" && (
            <Box>
              <ErasableTextField
                id="maxPoints"
                label="Max Points*"
                fullWidth
                isRequired
                type="text"
                defaultValue={activeKlass?.default_assignment_max_score || ""}
                hasError={errors?.maxPoints && true}
              />

              {errors?.maxPoints && renderError(errors?.maxPoints.message)}
            </Box>
          )}
        </Box>
      </Box>
      <Box sx={klassFormAssignmentGradingWeightContainer}>
        {selectedInput === "points" && (
          <>
            <FormControl fullWidth sx={formCheckBox}>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={defaultMaxPointsAsWeight}
                    disabled={!onlyHasPointAssignments}
                    {...register("maxPointsAsWeight")}
                  />
                }
                label={
                  <Typography>Use max points as weight calculation</Typography>
                }
              />
            </FormControl>

            {errors?.maxPointsAsWeight &&
              renderError(errors?.maxPointsAsWeight.message)}
          </>
        )}
      </Box>
    </>
  );
}
