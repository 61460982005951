import theme from "../../../utils";

const academicsFormControl = { marginLeft: "7%", marginRight: "15%" };

const academicsTextField = {
  marginLeft: "7%",
  marginRight: "15%",
};
const academicsTextField2 = {
  mb: "20px",
};
const academicsPaper = {
  backgroundColor: theme.topNavBar.secondary.backgroundColor,
};
const academicsSchool = { py: 1, ml: 1, fontSize: 20, fontWeight: "bold" };

const academicsSchoolTypography = { fontSize: 14, fontWeight: "bold" };

export {
  academicsPaper,
  academicsFormControl,
  academicsTextField2,
  academicsTextField,
  academicsSchool,
  academicsSchoolTypography,
};
