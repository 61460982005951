import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Fields from "./Fields";
import customFieldsService from "../../../service/customFieldsService";
import customFieldsModelMap from "./CustomFieldRenderer/FieldModels/customFieldsModelMap";

export default function CustomFields() {
  const [fields, setFields] = useState([]);
  useEffect(() => {
    customFieldsService.fetchAllCustomFields().then((response) => {
      const sortedData = response.data.sort((a, b) => a.order - b.order);
      setFields(
        sortedData.map(
          (field) => new customFieldsModelMap[field.field_type](field)
        )
      );
    });
  }, []);

  return (
    <Grid container pt={5} justifyContent="center" columnSpacing={10}>
      <Grid item md={3.2} color="#79747E">
        <Typography fontSize={24} fontWeight={400}>
          Student
        </Typography>

        <Typography fontSize={19} ml={1} mt={2}>
          Profile
        </Typography>
        <Fields
          responseType="Student"
          sectionType="student_profile"
          _fields={fields.filter((f) => f.sectionType === "student_profile")}
        />

        <Typography fontSize={19} ml={1} mt={2}>
          Medical
        </Typography>
        <Fields
          responseType="Student"
          sectionType="student_medical"
          _fields={fields.filter((f) => f.sectionType === "student_medical")}
        />
      </Grid>
      <Grid item md={3.2}>
        <Typography fontSize={24} fontWeight={400}>
          Parent
        </Typography>
        <Box>
          <Typography fontSize={19} ml={1} mt={2}>
            Profile
          </Typography>
          <Fields
            responseType="Guardian"
            sectionType="guardian_profile"
            _fields={fields.filter((f) => f.sectionType === "guardian_profile")}
          />
        </Box>
      </Grid>
      <Grid item md={3.2}>
        <Typography fontSize={24} fontWeight={400}>
          Staff
        </Typography>
        <Box>
          <Typography fontSize={19} ml={1} mt={2}>
            Account
          </Typography>
          <Fields
            responseType="Staff"
            sectionType="staff_account"
            _fields={fields.filter((f) => f.sectionType === "staff_account")}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
