const quickBarSpeedDial = {
  fontSize: "20px",
  position: "fixed",
  bottom: "17%",
};

const quickBarSpeedDialFab = {
  backgroundColor: "#FDC430",
  color: "#2D3941",
  width: "60px",
  fontSize: "40px",
  height: "60px",
  borderRadius: "0% 30% 30% 0%",
  "&:hover": {
    backgroundColor: "#FDC430",
    color: "#2D3941",
  },
  ".MuiSpeedDialIcon-root": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  ".MuiSvgIcon-root": {
    fontSize: "40px",
  },
};

const quickBarSpeedDialAction = {
  ".MuiSpeedDialAction-staticTooltipLabel": {
    backgroundColor: "#FDC430",
    color: "#2D3941",
    whiteSpace: "nowrap",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#e7e7e7",
    },
  },
};

const quickBarSpeedDialActionDisabled = {
  ".MuiSpeedDialAction-staticTooltipLabel": {
    backgroundColor: "#e7e7e7",
    color: "#2D3941",
    whiteSpace: "nowrap",
    cursor: "normal",
    "&:hover": {
      backgroundColor: "#e7e7e7",
    },
  },
};

const quickBarSpeedDialActionFab = (handleClick) => ({
  sx: {
    backgroundColor: "#FDC430",
    color: "#2D3941",
  },
  onClick: handleClick,
});

const quickBarSpeedDialActionFabDisabled = {
  sx: {
    backgroundColor: "#e7e7e7",
    color: "#2D3941",
    "&:hover": {
      backgroundColor: "#e7e7e7",
      cursor: "auto",
    },
  },
  disabled: true,
};

export {
  quickBarSpeedDial,
  quickBarSpeedDialFab,
  quickBarSpeedDialAction,
  quickBarSpeedDialActionFab,
  quickBarSpeedDialActionDisabled,
  quickBarSpeedDialActionFabDisabled,
};
