import React from "react";
import { Button, Link } from "@mui/material";
import { Link as RouterLink, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function AssignmentMissingStudent({ notification }) {
  const { t } = useTranslation("notifications");
  const params = useParams();
  const { data } = notification;

  return (
    <div>
      <Link
        to={`/student/${params.id}/schools/${params.school_id}/classes/${data.class_id}?type=assignments&assignments=missing`}
        component={RouterLink}
        variant="inherit"
      >
        <Button variant="contained">{t("goToClass")}</Button>
      </Link>
    </div>
  );
}
