import React, { useContext, useEffect, useState } from "react";
import {
  Link as RouterLink,
  useParams,
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import DoubleArrowRoundedIcon from "@mui/icons-material/DoubleArrowRounded";
import { useTheme } from "@mui/material/styles";
import {
  Container,
  Typography,
  Link,
  Drawer,
  Box,
  Stack,
  Tab,
  CircularProgress,
} from "@mui/material";
import { flex, mr15, loader } from "../components/sharedStyles";
import {
  CssTabs,
  DrawerHeader,
  Main,
  studentArrowIcon,
  studentInfoDrawer,
  studentInfoHighlightedLink,
  studentInfoStackArrowIcon,
  studentInfoTabBorder,
  studentInfoTabBox,
  studentInfoTabPanelSpacing,
  studentInfoTabContainer,
} from "./StudentInfo.style";
import teachersService from "../service/staffsService";
import StaffMembers from "./StaffMembers";
import StaffActions from "../components/Staff/StaffActions/StaffActions";
import { StaffActionsProvider } from "../context/StaffActionsContext";
import APP_PERMISSIONS from "../utils/constants/permissions";
import Authorize from "../components/Authorize";
import { PermissionsContext } from "../context/PermissionsContext";

export default function StaffInfo() {
  const params = useParams();
  const location = useLocation();
  const teacherId = params.id;
  const schoolId = params.school_id;
  const [, , teacher] = useOutletContext();
  const { hasAnyPermissionType } = useContext(PermissionsContext);

  const staffAccountPermission = hasAnyPermissionType(
    APP_PERMISSIONS.STAFF_ACCOUNT
  );

  const tabs = [
    {
      index: 0,
      label: "Account",
      url: `/school/${schoolId}/staff/${teacherId}/account`,
    },
  ];

  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [tabValue, setTabValue] = useState(tabs[0]);
  const [selectedTeacher, setSelectedTeacher] = useState(null);

  const handleTabChange = (value, path) => {
    setTabValue(tabs[value]);

    navigate(path);
  };

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    (async () => {
      setSelectedTeacher(null);
      const response = await teachersService.fetchTeacher(teacherId, {
        params: { school_id: schoolId },
      });
      setSelectedTeacher(response);
    })();
  }, [teacherId]);

  useEffect(() => {
    const getTabFromUrl = tabs.find((tab) =>
      location.pathname.includes(tab.url)
    );

    setTabValue(getTabFromUrl || tabs[0]);
  }, [location]);

  return (
    <StaffActionsProvider>
      <Box sx={flex}>
        <Drawer
          sx={studentInfoDrawer}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <DoubleArrowRoundedIcon
              size="small"
              onClick={handleDrawerToggle}
              sx={studentArrowIcon}
            />
          </DrawerHeader>

          <Box>
            <StaffMembers isCompact />
          </Box>
        </Drawer>
        <Main open={open}>
          <Container maxWidth={false} variant={open ? "container" : "header"}>
            <Stack direction="row" justifyContent="space-between">
              <div>
                <DoubleArrowRoundedIcon
                  size="small"
                  onClick={handleDrawerToggle}
                  sx={studentInfoStackArrowIcon(open)}
                />
                <Stack direction="row" alignItems="end">
                  <Typography variant="h1" color="#000000" sx={mr15}>
                    {selectedTeacher
                      ? `${selectedTeacher.first_name} ${selectedTeacher.last_name}`
                      : "Loading..."}
                  </Typography>
                </Stack>
              </div>
              <Link
                to={`/school/${schoolId}/staff/staffs`}
                underline="hover"
                as={RouterLink}
                sx={studentInfoHighlightedLink}
              >
                <ArrowBackRoundedIcon size="small" />
                Return to Staff List
              </Link>
            </Stack>
          </Container>

          <Box sx={studentInfoTabBox}>
            <Container maxWidth={false} sx={studentInfoTabContainer(open)}>
              <Box sx={studentInfoTabBorder}>
                <CssTabs value={tabValue.index}>
                  {tabs.map((tab) => (
                    <Tab
                      key={tab.index}
                      label={tab.label}
                      onClick={() => handleTabChange(tab.index, tab.url)}
                    />
                  ))}
                </CssTabs>
              </Box>
            </Container>
          </Box>
          <StaffActions />
          <Authorize permission={staffAccountPermission}>
            <Box sx={studentInfoTabPanelSpacing(theme, open)}>
              {selectedTeacher ? (
                <Outlet context={[selectedTeacher, open, teacher]} />
              ) : (
                <CircularProgress sx={loader} size={100} />
              )}
            </Box>
          </Authorize>
        </Main>
      </Box>
    </StaffActionsProvider>
  );
}
