import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Grid,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams, useSearchParams } from "react-router-dom";
import termService from "../../../service/termService";
import IOSSwitch from "../../IOSSwitch";
import {
  formButtons,
  formHeader,
  formTitle,
  danger,
  loader,
} from "../../sharedStyles";
import { form, formControlStyle } from "./ClassScheduleForm.styles";
import { requiredMessage, renderError } from "../../../utils/constants/forms";
import classScheduleService from "../../../service/classScheduleService";
import GenericSubmitButton from "../../GenericSubmitButton";

export default function ClassScheduleForm({
  handleClassScheduleDrawer,
  classSchedule,
  setSnackBarOpen,
}) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      is_published: classSchedule?.is_published || false,
    },
  });
  const [loading, setLoading] = useState(false);
  const [fieldError, setFieldError] = useState([]);
  const [terms, setTerms] = useState([]);
  const params = useParams();
  const schoolId = params.school_id;
  const [searchParams] = useSearchParams();
  const schoolYearId = searchParams.get("school_year") || null;
  const [classSchedules, setClassSchedules] = useState([]);

  const onToggle = (event) => {
    setValue("is_published", event.target.checked, { shouldDirty: true });
  };

  const onSubmit = async (formData) => {
    setLoading(true);
    if (classSchedule) {
      const updatedClassSchedule = { ...classSchedule, ...formData };
      try {
        const response = await classScheduleService.update({
          ...updatedClassSchedule,
        });
        if (response.data) {
          setSnackBarOpen({
            open: true,
            message: "Class schedule updated.",
          });
          handleClassScheduleDrawer(false);
        }
      } catch (error) {
        setFieldError(error.response.data.errors);
      }
    } else {
      try {
        const response = await classScheduleService.create({
          ...formData,
        });
        if (response.data) {
          setSnackBarOpen({
            open: true,
            message: "Class schedule created.",
          });
          handleClassScheduleDrawer(false);
        }
      } catch (error) {
        setFieldError(error.response.data.errors);
      }
    }
    setLoading(false);
  };

  const disabledTerm = (termId) =>
    classSchedules.some((_classSchedule) => _classSchedule.term.id === termId);

  const loadTerms = async () => {
    const response = await termService.fetchAllTermsInSchool({
      params: {
        school_id: schoolId,
        school_year_id: schoolYearId,
        upcoming_terms: true,
      },
    });
    setLoading(false);
    if (response.data) {
      setTerms(response.data.terms);
    }
  };

  const loadClassSchedules = async () => {
    const response = await classScheduleService.fetchAllBySchoolYear({
      params: {
        school_year_id: schoolYearId,
        show_last_year_schedules: true,
      },
    });
    if (response.data) {
      setClassSchedules(response.data.klass_schedules);
    }
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([loadTerms(), loadClassSchedules()]).then(() => {
      setLoading(false);
    });
  }, []);

  const handleChange = (event) => {
    setValue("term", event.target.value);
  };

  const displayError = (formField) => {
    const errorInModelValidation = fieldError?.model === "klass_schedule";
    if (errors?.[formField]) {
      return renderError(errors[formField]?.message || requiredMessage);
    }
    if (errorInModelValidation) {
      return renderError(fieldError?.errors?.[formField]);
    }
    return "";
  };

  const deleteClassSchedule = async () => {
    try {
      const response = await classScheduleService.remove(classSchedule.id);

      if (response.data) {
        setSnackBarOpen({
          open: true,
          message: "Class Schedule deleted.",
        });
        handleClassScheduleDrawer(false);
      }
    } catch (error) {
      if (error.response.data.errors.length) {
        setFieldError(error.response.data.errors);
      } else {
        setSnackBarOpen({
          open: true,
          message: error.response.data.message,
          severity: "error",
        });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {!loading ? (
        <>
          <Stack direction="row" sx={formHeader}>
            <Typography sx={formTitle}>
              {classSchedule ? "Edit Class Schedule" : "New Class Schedule"}
            </Typography>
            <Stack direction="row">
              <Button
                sx={formButtons}
                onClick={() => handleClassScheduleDrawer(false)}
              >
                Cancel
              </Button>
              {classSchedule && (
                <GenericSubmitButton
                  text="Delete"
                  submittingText="Deleting..."
                  type="button"
                  onClick={() => deleteClassSchedule()}
                />
              )}
              <GenericSubmitButton text="Save" submittingText="Saving..." />
            </Stack>
          </Stack>

          <Box sx={form}>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <TextField
                  label="Name"
                  fullWidth
                  defaultValue={classSchedule?.name}
                  {...register("name", {
                    required: requiredMessage,
                  })}
                  autoComplete="off"
                  error={!!errors?.name}
                />
                {displayError("name")}
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Abbreviation"
                  fullWidth
                  defaultValue={classSchedule?.abbreviation}
                  {...register("abbreviation", {
                    required: requiredMessage,
                  })}
                  autoComplete="off"
                  error={!!errors?.abbreviation}
                />
                {displayError("abbreviation")}
              </Grid>
              <Grid item xs={3} alignItems="center" display="flex">
                <IOSSwitch
                  defaultChecked={classSchedule?.is_published}
                  onChange={onToggle}
                />
                <Typography ml={2}>Published</Typography>
              </Grid>
              <Grid item xs={12} mt={2}>
                <FormControl sx={formControlStyle}>
                  <InputLabel id="dayDesignation">Term</InputLabel>
                  <Select
                    defaultValue={classSchedule?.term.id || ""}
                    onChange={handleChange}
                    label="Term*"
                    disabled={classSchedule?.klasses_count > 0}
                    labelId="term"
                    {...register("term_id", {
                      required: {
                        value:
                          classSchedule === undefined ||
                          classSchedule?.klasses_count === 0,
                        message: "This field is required",
                      },
                    })}
                  >
                    {terms.map((term) => (
                      <MenuItem
                        key={term.id}
                        value={term?.id}
                        disabled={disabledTerm(term.id)}
                      >
                        {term.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors &&
                    errors.term_id &&
                    (classSchedule === undefined ||
                      classSchedule?.klasses_count === 0) && (
                      <Typography sx={danger}>{requiredMessage}</Typography>
                    )}
                </FormControl>
              </Grid>
              {!classSchedule && (
                <Grid item xs={12} mt={2}>
                  <FormControl fullWidth>
                    <InputLabel id="cloneFrom">Clone From</InputLabel>
                    <Controller
                      name="clone_from"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange, value } }) => (
                        <Select
                          labelId="cloneFrom"
                          id="cloneFrom"
                          value={value}
                          label="Clone From"
                          onChange={(e) => {
                            onChange(e);
                          }}
                          {...register("clone_from", {})}
                        >
                          {classSchedules.map((_classSchedule) => (
                            <MenuItem
                              key={_classSchedule.id}
                              value={_classSchedule.id}
                            >
                              {_classSchedule.term.school_year_id ===
                              Number(schoolYearId)
                                ? _classSchedule.name
                                : `${_classSchedule.name} (Last Year)`}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </Box>
        </>
      ) : (
        <CircularProgress sx={loader} size={100} />
      )}
    </form>
  );
}
