import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import klassCategoriesService from "../../service/klassCategoriesService";
import EditAssignmentRow from "./EditAssignmentRow";
import AddAssignmentRow from "./AddAssignmentRow";

export default function KlassRow({
  selectedKlasses,
  setSelectedKlasses,
  klass,
  activeAssignment,
  assignmentStudents,
  isGoogleAssignment,
}) {
  const { setValue, formState } = useFormContext();

  setValue(`klasses.klass-${klass.id}.id`, klass.id);

  const [affectsGrade, setAffectsGrade] = useState(
    activeAssignment ? activeAssignment.should_affect_grade : false
  );
  const [selectedInputAs, setSelectedInputAs] = useState(
    activeAssignment ? activeAssignment.grading : ""
  );

  // eslint-disable-next-line no-unused-vars
  const [selectedCategory, setSelectedCategory] = useState(
    activeAssignment ? activeAssignment.klass_category_id : ""
  );
  const [categories, setCategories] = useState([]);

  const hasCategoryError = () =>
    formState.errors?.klasses?.[`klass-${klass.id}`]?.category;

  const hasInputAsError = () =>
    formState.errors?.klasses?.[`klass-${klass.id}`]?.inputAs;

  const hasMaxError = () =>
    formState.errors?.klasses?.[`klass-${klass.id}`]?.max;

  const getCategories = async () => {
    const response = await klassCategoriesService.fetchKlassCategories({
      params: { id: klass.id },
    });

    if (response.data) {
      setCategories(response.data.klassCategories);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  return !activeAssignment ? (
    <AddAssignmentRow
      klass={klass}
      categories={categories}
      selectedCategory={selectedCategory}
      affectsGrade={affectsGrade}
      setAffectsGrade={setAffectsGrade}
      selectedKlasses={selectedKlasses}
      setSelectedKlasses={setSelectedKlasses}
      selectedInputAs={selectedInputAs}
      setSelectedInputAs={setSelectedInputAs}
      hasCategoryError={hasCategoryError}
      hasInputAsError={hasInputAsError}
      hasMaxError={hasMaxError}
    />
  ) : (
    <EditAssignmentRow
      klass={klass}
      categories={categories}
      selectedCategory={selectedCategory}
      activeAssignment={activeAssignment}
      affectsGrade={affectsGrade}
      setAffectsGrade={setAffectsGrade}
      selectedInputAs={selectedInputAs}
      setSelectedInputAs={setSelectedInputAs}
      hasCategoryError={hasCategoryError}
      hasInputAsError={hasInputAsError}
      hasMaxError={hasMaxError}
      isGoogleAssignment={isGoogleAssignment}
      assignmentStudents={assignmentStudents}
    />
  );
}
