import React from "react";
import LockIcon from "@mui/icons-material/Lock";
import { Box, Tooltip } from "@mui/material";
import gradeLockIconContainer from "./GradeLockIcon.styles";

export default function GradeLockIcon() {
  return (
    <Box sx={gradeLockIconContainer}>
      <Tooltip title="Grades are published" placement="left-start" arrow>
        <LockIcon color="warning" />
      </Tooltip>
    </Box>
  );
}
