import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, Grid, Link, Typography } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useParams } from "react-router-dom";
import {
  gradeCard,
  gradeCardContent,
  gradeCardContentRow1,
  gradeCardContentRow2,
  gradeCardContentRow3,
  gradeBox,
} from "./Grades.styles";
import { fetchStudentGrades } from "../../../service/studentPortalService";
import termGradeLetter from "../../../utils/termGradeLetter";

export default function StudentPortalHomeGrades() {
  const params = useParams();
  const studentId = params.id;
  const schoolId = params.school_id;
  const [studentGrades, setStudentGrades] = useState([]);
  const [specialMarks, setSpecialMarks] = useState([]);

  useEffect(() => {
    (async () => {
      if (studentId && schoolId) {
        try {
          const response = await fetchStudentGrades(studentId, {
            params: { school_id: schoolId },
          });
          setStudentGrades(response.data.student_grades);
          setSpecialMarks(response.data.special_marks);
        } catch (e) {
          // handle catch
        }
      }
    })();
  }, [schoolId]);

  const card = (grade) => (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link underline="none" key={grade.id} sx={{ mb: "10px" }}>
      <Card variant="outlined" sx={[gradeCard, { background: "#F7F7F7" }]}>
        <CardContent sx={gradeCardContent}>
          <Typography sx={gradeCardContentRow1}>
            {grade?.klass?.name}
          </Typography>
          <Typography sx={[gradeCardContentRow2, { fontSize: "68.0244px" }]}>
            {termGradeLetter(grade, specialMarks)}
          </Typography>

          <Typography sx={gradeCardContentRow3}>
            <OpenInNewIcon color="disabled" />
          </Typography>
        </CardContent>
      </Card>
    </Link>
  );

  return (
    <>
      <Box sx={gradeBox}>My current grades</Box>

      {studentGrades.length > 0 ? (
        <Grid container>{studentGrades.map((grade) => card(grade))}</Grid>
      ) : (
        <Typography>No grades.</Typography>
      )}
    </>
  );
}
