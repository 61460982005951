import * as React from "react";
import ExpandCircleDownRoundedIcon from "@mui/icons-material/ExpandCircleDownRounded";
import {
  Grid,
  Typography,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import {
  headerCell,
  paddedHeaderCell,
  calendarIconLeft,
  calendarIconRight,
  styledCell,
  styledGrid,
  styledRow,
  calendar,
} from "./Schedule.style";
import { mb25, pr25 } from "../../sharedStyles";

function createData(time, className, room) {
  return { time, className, room };
}

const rows = Array(6).fill(createData("00:00 AM", "Name of class", "Room100"));

export default function Schedule({ open }) {
  return (
    <Grid item lg={open ? 6 : 7} xl={7} md={6} sx={styledGrid}>
      <Stack
        direction="row"
        alignItems="baseline"
        justifyContent="space-between"
      >
        <Typography variant="h2" sx={mb25} color="#000000">
          Schedule
        </Typography>

        <Stack direction="row" alignItems="center" sx={calendar(open)}>
          <ExpandCircleDownRoundedIcon sx={calendarIconLeft} />
          <Typography variant="boxTitle" sx={{ mx: "15px" }}>
            Friday, May 20, 2022
          </Typography>
          <ExpandCircleDownRoundedIcon sx={calendarIconRight} />
        </Stack>
      </Stack>

      <TableContainer sx={pr25}>
        <Table size="small">
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                /* eslint-disable-next-line react/no-array-index-key */
                key={index}
                sx={styledRow}
              >
                <TableCell sx={styledCell}>
                  <Typography variant="boxTitle">{row.time}</Typography>
                </TableCell>
                <TableCell sx={headerCell}>
                  <Typography variant="title">{row.className}</Typography>
                </TableCell>
                <TableCell align="right" sx={paddedHeaderCell}>
                  <Typography variant="title">{row.room}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
