import { Box, Container } from "@mui/material";
import React, { useState } from "react";
import { Outlet, useSearchParams, useOutletContext } from "react-router-dom";
import { pageTabsContainer, pb0 } from "../../sharedStyles";
import EnrollmentsTabs from "./EnrollmentsTabs";
import { admissionsContainer } from "../../../views/Admissions.styles";

export default function Enrollments({ schoolId, selectedSchoolYear }) {
  const [, useAdvanceAdmissions] = useOutletContext();
  const [searchParams] = useSearchParams();
  const [query, setQuery] = useState(searchParams.get("query") || "");

  return (
    <>
      {useAdvanceAdmissions && (
        <Container maxWidth={false} variant="header" sx={pb0}>
          <Box sx={pageTabsContainer}>
            <EnrollmentsTabs />
          </Box>
        </Container>
      )}

      <Box sx={useAdvanceAdmissions && admissionsContainer}>
        <Outlet context={[schoolId, selectedSchoolYear, query, setQuery]} />
      </Box>
    </>
  );
}
