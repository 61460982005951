import React, { useState, useEffect, useContext } from "react";
import { useForm, FormProvider } from "react-hook-form";
import {
  Box,
  Stack,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  formHeader,
  formButtons,
  formTitle,
  formContainer,
  loader,
} from "../sharedStyles";
import GeneralInfo from "./GeneralInfo";
import Klasses from "./Klasses";
import StudentList from "./StudentList";
import assignmentService from "../../service/assignmentService";
import klassesService from "../../service/klassesService";
import { SnackbarContext } from "../../context/SnackbarContext";
import moment from "../../utils/constants/momentConfig";
import dateTimeFormats from "../../utils/constants/dateTimeFormats";
import { actionButtonsContainer } from "./AssignmentForm.styles";
import AlertDialog from "../AlertDialog";
import Attachment from "./Attachment";
import GenericSubmitButton from "../GenericSubmitButton";

export default function AssignmentsForm({
  handleAssignmentsDrawer,
  schoolId,
  activeAssignment,
  assignmentStudentsFromAssignment,
  duplicate,
  termId,
}) {
  const maxFileSize = 20000000;

  const methods = useForm({ mode: "onChange" });
  const snackbarContext = useContext(SnackbarContext);
  const [selectedKlasses, setSelectedKlasses] = useState([]);
  const [klasses, setKlasses] = useState([]);
  const [hasErrors, setErrors] = useState(false);
  const [saveAndOpen, setSaveAndOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const { search, pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const isGoogleAssignment = !!activeAssignment?.lms_assignment_id;

  const klassStudents = activeAssignment?.klass.enrolled_klass_students;
  const assignmentStudents =
    activeAssignment?.enrolled_assignment_students ||
    assignmentStudentsFromAssignment;

  const defaultSelectedStudents = () => {
    const studentsIds = [];

    const result = klassStudents?.filter((o1) =>
      assignmentStudents.some((o2) => o1.student_id === o2.student_id)
    );

    result?.map((r) => studentsIds.push(r.student_id));

    return studentsIds;
  };

  const [selectedStudents, setSelectedStudents] = useState(
    defaultSelectedStudents
  );

  const [repeatEvent, setRepeatEvent] = useState(false);
  const [turnInOnline, setTurnInOnline] = useState(
    activeAssignment?.turn_in_online || false
  );
  const [turnInOnlineUsing, setTurnInOnlineUsing] = useState(
    activeAssignment?.turn_in_online_using || "file"
  );
  const [repeatEventError, setRepeatEventError] = useState(false);
  const [displayInPortal, setDisplayInPortal] = useState(
    activeAssignment?.display_in_portal
  );

  const handleTurnInOnlineUsing = (event) => {
    setTurnInOnlineUsing(event.target.value);
  };

  const [selectedDays, setSelectedDays] = useState({
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
  });

  const [uploadedFiles, setUploadedFiles] = useState([]);

  const SizeException = (message) => ({
    message,
    error: "sizeException",
  });

  const onSubmit = async (_data) => {
    const noClassesSelected = selectedKlasses.length === 0;

    if (duplicate && (repeatEventError || noClassesSelected)) {
      return;
    }
    if (repeatEventError || (!activeAssignment && noClassesSelected)) {
      return;
    }

    const data = { ..._data };
    if (repeatEvent) {
      const selectedDaysArray = Object.keys(selectedDays).filter(
        (day) => selectedDays[day] === true
      );
      const checkedKlasses = {};

      for (let i = 0; i < Object.entries(data.klasses).length; i += 1) {
        const [key, value] = Object.entries(data.klasses)[i];
        if (value.checked) {
          checkedKlasses[key] = value;
        }
      }

      const dataToSend = {
        school_id: schoolId,
        ...data,
        klasses: checkedKlasses,
        selectedDays: selectedDaysArray,
        term_id: termId,
        turnInOnline,
        turnInOnlineUsing,
        displayInPortal,
      };

      const formData = new FormData();
      Object.entries(dataToSend).forEach(([key, value]) => {
        formData.append(key, JSON.stringify(value));
      });

      Array.prototype.forEach.call(uploadedFiles, (file, index) => {
        if (uploadedFiles.length > 10)
          throw new SizeException("Maximum of 10 files are permitted.");
        if (file.size > maxFileSize)
          throw new SizeException(`The file ${file.name} is bigger than 20Mb.`);

        const fileToUpload = uploadedFiles[index].type
          ? uploadedFiles[index]
          : JSON.stringify(uploadedFiles[index]);
        formData.append(`attachments[]`, fileToUpload);
      });

      const response = await assignmentService.repeatAssignment(formData);

      if (response.data) {
        snackbarContext.setSnackbar({
          message: "Assignment created.",
          severity: "success",
          open: true,
        });
        handleAssignmentsDrawer(false);
        if (pathname.includes("subjects") && pathname.includes("gradebook")) {
          searchParams.set("added_assignment", true);
          setSearchParams(searchParams);
        }
      }
    } else if (!hasErrors) {
      const assignedTime = data.assignedTime
        ? moment(data.assignedTime).format(dateTimeFormats.LT).toString()
        : null;
      const dueTime = data.dueTime
        ? moment(data.dueTime).format(dateTimeFormats.LT).toString()
        : null;

      data.assignedDate = new Date(data.assignedDate);
      if (data.dueDate) {
        data.dueDate = new Date(data.dueDate);
      }

      if (activeAssignment && !duplicate) {
        Object.keys(data.klasses).forEach((key) => {
          // eslint-disable-next-line no-param-reassign
          data.klasses[key].affectsGrade = !data.klasses[key].affectsGrade;
        });

        const queryParams = new URLSearchParams(search);

        const dataToSend = {
          ...data,
          klassId: activeAssignment.klass_id,
          term_id: queryParams.get("term"),
          selectedStudents,
          assignedTime,
          dueTime,
          displayInPortal,
        };

        const formData = new FormData();
        Object.entries(dataToSend).forEach(([key, value]) => {
          formData.append(key, JSON.stringify(value));
        });

        Array.prototype.forEach.call(uploadedFiles, (file, index) => {
          if (uploadedFiles.length > 10)
            throw new SizeException("Maximum of 10 files are permitted.");
          if (file.size > maxFileSize)
            throw new SizeException(
              `The file ${file.name} is bigger than 20Mb.`
            );

          const fileToUpload = uploadedFiles[index].type
            ? uploadedFiles[index]
            : JSON.stringify(uploadedFiles[index]);
          formData.append(`attachments[]`, fileToUpload);
        });

        try {
          const response = await assignmentService.update(
            activeAssignment.id,
            formData
          );

          if (response.data) {
            snackbarContext.setSnackbar({
              message: "Assignment updated.",
              severity: "success",
              open: true,
            });
            handleAssignmentsDrawer(false);
            if (
              pathname.includes("subjects") &&
              pathname.includes("gradebook")
            ) {
              searchParams.set("added_assignment", true);
              setSearchParams(searchParams);
            }
          }
        } catch (e) {
          snackbarContext.setSnackbar({
            message: "Error, try again later.",
            severity: "error",
            open: true,
          });
        }
      } else {
        const checkedKlasses = {};

        for (let i = 0; i < Object.entries(data.klasses).length; i += 1) {
          const [key, value] = Object.entries(data.klasses)[i];
          if (value.checked) {
            checkedKlasses[key] = value;
          }
        }

        const dataToSend = {
          school_id: schoolId,
          ...data,
          klasses: checkedKlasses,
          assignedTime,
          dueTime,
          turnInOnline,
          turnInOnlineUsing,
          displayInPortal,
        };

        const formData = new FormData();
        Object.entries(dataToSend).forEach(([key, value]) => {
          formData.append(key, JSON.stringify(value));
        });

        Array.prototype.forEach.call(uploadedFiles, (file, index) => {
          if (uploadedFiles.length > 10)
            throw new SizeException("Maximum of 10 files are permitted.");
          if (file.size > maxFileSize)
            throw new SizeException(
              `The file ${file.name} is bigger than 20Mb.`
            );

          const fileToUpload = uploadedFiles[index].type
            ? uploadedFiles[index]
            : JSON.stringify(uploadedFiles[index]);
          formData.append(`attachments[]`, fileToUpload);
        });

        const response = await assignmentService.create(formData);

        if (response.data) {
          snackbarContext.setSnackbar({
            message: "Assignment created.",
            severity: "success",
            open: true,
          });
          handleAssignmentsDrawer(false);
          if (pathname.includes("subjects") && pathname.includes("gradebook")) {
            searchParams.set("added_assignment", true);
            setSearchParams(searchParams);
          }

          if (saveAndOpen && response.data.assignment) {
            const queryParams = new URLSearchParams(search);
            const { assignment } = response.data;

            navigate(
              `/school/${params.school_id}/subjects/${
                assignment.subject_id
              }/class/${assignment.klass_id}/assignments/${
                assignment.id
              }?term=${queryParams.get("term")}`
            );
          }
        }
      }
    }
  };

  const getKlasses = async () => {
    setLoading(true);
    const response = await klassesService.fetchAllKlasses({
      params: {
        school_id: schoolId,
        orderBy: "name",
        order: "asc",
        term_id: termId,
        prek: false,
      },
    });
    if (response.data) {
      const returnedKlasses = response.data.klasses.map((k) => ({
        name: k.name,
        abbreviation: k.abbreviation,
        id: k.id,
        defaultGrading: k.default_assignment_grading,
        defaultMaxScore: k.default_assignment_max_score,
        maxPointsAsWeight: k.max_points_as_weight,
      }));
      setKlasses(returnedKlasses);
      setLoading(false);
    }
  };

  const deleteAssignment = async () => {
    await assignmentService
      .deleteAssignment(activeAssignment.id)
      .then(() => {
        snackbarContext.setSnackbar({
          message: "Assignment deleted.",
          severity: "success",
          open: true,
        });
        handleAssignmentsDrawer(false);
      })
      .catch(() => {
        snackbarContext.setSnackbar({
          message: "Failed to delete assignment.",
          severity: "error",
          open: true,
        });
      });

    const queryParams = new URLSearchParams(search);
    if (params.subject_id) {
      navigate(
        `/school/${params.school_id}/subjects/${
          params.subject_id
        }/assignments?term=${queryParams.get("term")}`
      );
    } else {
      navigate(
        `/school/${params.school_id}/assignments?term=${queryParams.get(
          "term"
        )}`
      );
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  let assignmentTitle;

  if (duplicate) {
    assignmentTitle = "Duplicate Assignment";
  } else if (activeAssignment) {
    assignmentTitle = "Edit Assignment";
  } else {
    assignmentTitle = "Add Assignment";
  }

  const getAttachments = async () => {
    const response = await assignmentService.attachments(activeAssignment.id);
    setUploadedFiles(response.data);
  };

  useEffect(() => {
    getKlasses();
    if (activeAssignment) getAttachments();
  }, []);

  if (loading) {
    return <CircularProgress sx={loader} size={100} />;
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack direction="row" sx={formHeader}>
          <Typography sx={formTitle}>{assignmentTitle}</Typography>
          <Stack sx={actionButtonsContainer} direction="row">
            <Button
              sx={formButtons}
              onClick={() => handleAssignmentsDrawer(false)}
            >
              Cancel
            </Button>
            {activeAssignment && !duplicate && (
              <GenericSubmitButton
                text="Delete"
                submittingText="Deleting..."
                type="button"
                onClick={() => {
                  setDialogOpen(true);
                }}
              />
            )}
            {!activeAssignment && selectedKlasses.length === 1 && (
              <GenericSubmitButton
                text="Save & Open"
                submittingText="Saving..."
                onClick={() => {
                  setSaveAndOpen(true);
                }}
              />
            )}
            <GenericSubmitButton
              text="Save"
              submittingText="Saving..."
              onClick={() => {
                setRepeatEventError(false);
                const repeatAssignmentError =
                  Object.values(selectedDays).every(
                    (value) => value === false
                  ) && repeatEvent;
                setRepeatEventError(repeatAssignmentError);
              }}
            />
          </Stack>
        </Stack>

        <Box sx={formContainer}>
          <GeneralInfo
            activeAssignment={activeAssignment}
            setErrors={setErrors}
            isGoogleAssignment={isGoogleAssignment}
            duplicate={duplicate}
            termId={termId}
            selectedDays={selectedDays}
            repeatEventError={repeatEventError}
            repeatEvent={repeatEvent}
            setRepeatEvent={setRepeatEvent}
            turnInOnline={turnInOnline}
            setTurnInOnline={setTurnInOnline}
            turnInOnlineUsing={turnInOnlineUsing}
            handleTurnInOnlineUsing={handleTurnInOnlineUsing}
            setSelectedDays={setSelectedDays}
            displayInPortal={displayInPortal}
            setDisplayInPortal={setDisplayInPortal}
          />
          <Attachment
            uploadedFiles={uploadedFiles}
            setUploadedFiles={setUploadedFiles}
          />
          <Klasses
            klasses={klasses}
            selectedKlasses={selectedKlasses}
            setSelectedKlasses={setSelectedKlasses}
            activeAssignment={!duplicate ? activeAssignment : null}
            isGoogleAssignment={isGoogleAssignment}
            assignmentStudents={!duplicate ? assignmentStudents : []}
            isSubmitted={methods.formState.isSubmitted}
          />
          {activeAssignment && !duplicate && (
            <StudentList
              klassStudents={klassStudents.filter((ks) => !ks.is_deleted)}
              assignmentStudents={assignmentStudents}
              schoolId={schoolId}
              selectedStudents={selectedStudents}
              setSelectedStudents={setSelectedStudents}
              isGoogleAssignment={isGoogleAssignment}
              isAssignment
            />
          )}
        </Box>
      </form>
      <AlertDialog
        isOpen={isDialogOpen}
        handleClose={handleCloseDialog}
        handleConfirm={deleteAssignment}
      />
    </FormProvider>
  );
}
