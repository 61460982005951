const overrideGradeInput = {
  mx: "10px",
  width: "105px",
};

const overrideGradeContainer = {
  alignItems: "center",
  mb: "20px",
};

const overrideGradeInfoInput = {
  backgroundColor: "#FCEEEE",
};

const overrideGradeWarning = {
  color: "#E46962",
  fontSize: "14px",
};

export {
  overrideGradeInput,
  overrideGradeContainer,
  overrideGradeInfoInput,
  overrideGradeWarning,
};
