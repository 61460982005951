import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { Outlet, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { StudentPortalTopNavBar, StudentPortalFooter } from "../components";
import { storeCurrentSchoolId } from "../utils/constants/schoolIdStore";
import messagesService from "../service/messagesService";
import notificationsService from "../service/notificationsService";

export default function StudentPortalLayout({ defaultStudentSchoolId }) {
  const { i18n } = useTranslation();
  const [studentSchool, setStudentSchool] = useState();
  const [hasUnreadMessages, setUnreadMessages] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState();
  const params = useParams();

  const getUnreadMessages = async () => {
    const response = await messagesService.checkForUnreadMessages({
      params: { recipient_type: "Student" },
    });
    setUnreadMessages(response.data);
  };

  const getUnreadNotifications = async () => {
    const response = await notificationsService.checkForUnreadNotifications();
    setUnreadNotifications(response);
  };

  useEffect(() => {
    getUnreadMessages();
    getUnreadNotifications();
  }, [params]);

  useEffect(() => {
    storeCurrentSchoolId(defaultStudentSchoolId);
  }, [defaultStudentSchoolId]);

  // Let's guarantee that the default language is English.
  // This is important because the student portal is only available in English.
  // It also prevents some shared translated components from breaking.
  useEffect(() => {
    i18n.changeLanguage("en");
  }, [i18n]);

  return (
    <div style={{ minWidth: "1400px", backgroundColor: "#FFFFFF" }}>
      <StudentPortalTopNavBar
        defaultStudentSchoolId={defaultStudentSchoolId}
        hasUnreadMessages={hasUnreadMessages}
        unreadNotifications={unreadNotifications}
      />
      <Box sx={{ minWidth: "1400px", minHeight: "calc(96vh - 200px)" }}>
        <Outlet context={[setStudentSchool]} />
      </Box>
      <StudentPortalFooter studentSchool={studentSchool} />
    </div>
  );
}
