import React, { useState, useRef } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import {
  AppBar,
  Badge,
  Box,
  Grid,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  Link,
  IconButton,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { removeToken, storedToken } from "../../utils/constants/auth";
import {
  appBarBox,
  menuDefaultColor,
  navBarIconButton,
  navBarMenu,
  navBarTypography,
  styledAppBar,
  navBarSchoolLink,
  iconButtonLink,
  notificationsIconButton,
} from "./TopNavBar.styles";
import { flex } from "../sharedStyles";
import axios from "../../utils/axios-util";
import SnackBarNotification from "../SnackBarNotification";

export default function TopNavBar({
  defaultStudentSchoolId,
  hasUnreadMessages,
  unreadNotifications,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverEl, setPopoverEl] = useState(null);
  const openQuickMenu = Boolean(anchorEl);
  const openPopover = Boolean(popoverEl);
  const secondaryBarRef = useRef();
  const params = useParams();
  const studentId = params.id;
  const schoolId = params.school_id || defaultStudentSchoolId;
  const [snackBarOpen, setSnackBarOpen] = useState({
    open: false,
    message: "",
    severity: null,
  });
  const overlayType = {
    popover: 1,
    quickMenu: 2,
  };

  const handleClick = (type) => (event) => {
    if (type === overlayType.popover) {
      setPopoverEl(secondaryBarRef.current);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = (type) => () => {
    if (type === overlayType.popover) {
      setPopoverEl(null);
    } else {
      setAnchorEl(null);
    }
  };

  const handleLogout = async () => {
    try {
      const payload = await axios.delete("/users/sign_out");

      if (payload.data.message === "You are logged out.") {
        removeToken(storedToken);
        window.location.replace("/login");
      }
    } catch (e) {
      window.location.reload();
    }
  };

  return (
    <>
      <Box sx={appBarBox(openPopover)}>
        <AppBar position="relative" sx={styledAppBar}>
          <Toolbar variant="dense">
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs>
                <div style={flex}>
                  <IconButton
                    size="small"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={navBarIconButton}
                  >
                    <Link
                      to={`/student/${studentId}/schools/${schoolId}/home`}
                      component={RouterLink}
                      color="inherit"
                    >
                      <HomeIcon fontSize="medium" />
                    </Link>
                  </IconButton>

                  <Link
                    to={`/student/${studentId}/schools/${schoolId}/home`}
                    component={RouterLink}
                    sx={navBarSchoolLink}
                  >
                    <Typography noWrap component="div" sx={navBarTypography}>
                      AE-CONNECT
                    </Typography>
                  </Link>
                </div>
              </Grid>
              <Grid item xs textAlign="right">
                <Link
                  sx={notificationsIconButton}
                  to={`/student/${studentId}/schools/${schoolId}/notifications`}
                  component={RouterLink}
                  color="inherit"
                >
                  <IconButton color="inherit" size="medium">
                    <Badge
                      color="error"
                      badgeContent={unreadNotifications?.total}
                    >
                      <NotificationsIcon fontSize="large" />
                    </Badge>
                  </IconButton>
                </Link>

                <Link
                  sx={iconButtonLink}
                  to={`/student/${studentId}/schools/${schoolId}/messages`}
                  component={RouterLink}
                  color="inherit"
                >
                  <IconButton color="inherit" size="medium">
                    <Badge
                      variant="dot"
                      color="error"
                      invisible={!hasUnreadMessages}
                    >
                      <ArchiveOutlinedIcon fontSize="large" />
                    </Badge>
                  </IconButton>
                </Link>

                <IconButton
                  onClick={handleClick(overlayType.quickMenu)}
                  color="inherit"
                  size="medium"
                >
                  <Badge
                    overlap="circular"
                    aria-controls={openQuickMenu ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openQuickMenu ? "true" : undefined}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                  >
                    <AccountCircleOutlinedIcon fontSize="large" />
                  </Badge>
                </IconButton>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={openQuickMenu}
          onClose={handleClose(overlayType.quickMenu)}
          onClick={handleClose(overlayType.quickMenu)}
          PaperProps={{
            elevation: 0,
            sx: navBarMenu,
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem onClick={() => handleLogout()}>
            <Typography sx={menuDefaultColor}>Log Out</Typography>
          </MenuItem>
        </Menu>
      </Box>

      <SnackBarNotification
        message={snackBarOpen.message}
        open={snackBarOpen.open}
        severity={snackBarOpen.severity}
        handleClose={() => setSnackBarOpen({ message: "", open: false })}
      />
    </>
  );
}
