import CustomFieldBase from "./CustomFieldBase";

export default class CustomTextField extends CustomFieldBase {
  constructor(field) {
    super(field);
    this.dataType = "value_text";
    this.type = "short-text";
    this.prompt = "TEXT";
    this.propertyComponents = ["characterLimit", "isVisible", "isRequired"];
  }
}
