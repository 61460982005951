const summaryStyle = {
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
  cursor: "pointer",
};

const cardTitle = {
  fontSize: "18px",
  fontWeight: 500,
};

export { summaryStyle, cardTitle };
