const addTranscriptFormSubject = {
  width: "200px",
};

const addTranscriptFormSubject2 = {
  width: "425px",
};

const addTranscriptKlassName = {
  width: "425px",
  mr: "25px",
};

const addTranscriptRecordSelect = {
  width: "200px",
  mr: "25px",
};

const addTranscriptRecordSelect2 = {
  width: "425px",
  mr: "25px",
};

const classNameTranscriptRecord = {
  width: "100%",
};

const creditsEarnedTranscriptRecord = {
  width: "50%",
};

const gradeTranscriptRecord = {
  width: "30%",
};

const gradeGPARecord = {
  width: "33%",
};

const transcriptTable = {
  width: "100%",
};

const transcriptTableContainer = {
  overflowX: "auto",
  width: "100%",
  maxHeight: "1000px",
};

const transcriptDivider = {
  borderBottom: "1px solid #000",
  pb: "25px",
};

export {
  addTranscriptRecordSelect,
  addTranscriptRecordSelect2,
  addTranscriptFormSubject,
  addTranscriptKlassName,
  addTranscriptFormSubject2,
  transcriptTable,
  classNameTranscriptRecord,
  creditsEarnedTranscriptRecord,
  gradeTranscriptRecord,
  gradeGPARecord,
  transcriptTableContainer,
  transcriptDivider,
};
