import React, { useContext, useState } from "react";
import { Box, Stack, Button, Typography, Grid } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import GeneralInfo from "../../TemporaryStaff/GeneralInfo";
import {
  mb25,
  mt70,
  formHeader,
  formButtons,
  formTitle,
  formContainer,
} from "../../../sharedStyles";
import AlertDialog from "../../../AlertDialog";
import SnackBarNotification from "../../../SnackBarNotification";
import { StaffActionsContext } from "../../../../context/StaffActionsContext";
import TextEditor from "../../../TextEditor/TextEditor";
import temporaryStaffService from "../../../../service/temporaryStaffService";
import IOSSwitch from "../../../IOSSwitch";
import GenericSubmitButton from "../../../GenericSubmitButton";

export default function TemporaryStaffForm({
  setTemporaryStaffDrawerOpen,
  currentStaff,
}) {
  const methods = useForm();
  const staffActionsContext = useContext(StaffActionsContext);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState({
    open: false,
    message: null,
  });

  const handleSackBarClose = () => {
    setSnackBarOpen({ open: false });
  };

  const handleCreate = async (body) => {
    const response = await temporaryStaffService.create(body);

    if (response.data) {
      setSnackBarOpen({
        open: true,
        message: "Temporary staff created.",
      });
      staffActionsContext.fireTemporaryStaffUpdated(true);
      setTemporaryStaffDrawerOpen(false);
    }
  };

  const handleEcontactDrawer = (state) => {
    setTemporaryStaffDrawerOpen(state);
  };

  const handleUpdate = async (body) => {
    const response = await temporaryStaffService.update(currentStaff.id, body);

    if (response.data) {
      setSnackBarOpen({
        open: true,
        message: "Temporary staff updated.",
      });
      handleEcontactDrawer(false);
      staffActionsContext.fireTemporaryStaffUpdated(true);
    }
  };

  const handleDelete = async () => {
    await temporaryStaffService.deleteStaff(currentStaff.id);
    setSnackBarOpen({
      open: true,
      message: "Temporary staff deleted.",
    });
    handleEcontactDrawer(false);
    staffActionsContext.fireTemporaryStaffUpdated(true);
  };

  const onSubmit = async (data) => {
    const body = data;

    if (currentStaff) {
      handleUpdate(body);
    } else {
      handleCreate(body);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const onNotesChange = (data) => {
    methods.setValue("notes", data, { shouldDirty: true });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack direction="row" sx={formHeader}>
          <Typography sx={formTitle}>
            {currentStaff ? "Edit Temporary Staff" : "Add Temporary Staff"}
          </Typography>
          <Stack direction="row">
            <Button
              sx={formButtons}
              onClick={() => handleEcontactDrawer(false)}
            >
              Cancel
            </Button>
            {currentStaff && (
              <GenericSubmitButton
                text="Delete"
                submittingText="Deleting..."
                type="button"
                onClick={() => setDialogOpen(true)}
              />
            )}
            <GenericSubmitButton text="Save" submittingText="Saving..." />
          </Stack>
        </Stack>
        <Box sx={formContainer}>
          <Grid container justifyContent="end" mb={6}>
            <Typography mr={2}>Active</Typography>
            <IOSSwitch
              defaultChecked={currentStaff?.is_active}
              onChange={(event) => {
                methods.setValue("is_active", event.target.checked, {
                  shouldDirty: true,
                });
              }}
            />
          </Grid>
          <GeneralInfo currentStaff={currentStaff} />

          <Grid container item columnSpacing={2} sx={mt70}>
            <Grid container item sm={12}>
              <Typography variant="h2" sx={mb25}>
                Notes
              </Typography>
            </Grid>
            <Grid container item sm={12}>
              <TextEditor
                value={currentStaff?.notes}
                onChange={(data) => onNotesChange(data)}
                isSimplified
              />
            </Grid>
          </Grid>
        </Box>
      </form>

      <AlertDialog
        isOpen={isDialogOpen}
        handleClose={handleCloseDialog}
        handleConfirm={handleDelete}
      />
      <SnackBarNotification
        message={snackBarOpen.message}
        open={snackBarOpen.open}
        handleClose={handleSackBarClose}
      />
    </FormProvider>
  );
}
