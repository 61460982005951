import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Typography, Box } from "@mui/material";
import { ml15 } from "../../../../sharedStyles";
import {
  guardianCircle,
  guardianCheckmark,
} from "./NotificationCheckmarkSelect.styles";

export default function OptionsCheckbox({ title, label, currentContact }) {
  const [isChecked, setIsChecked] = useState(false);
  const { register, setValue } = useFormContext();

  const handleIsChecked = () => {
    setIsChecked(!isChecked);
    setValue(label, !isChecked);
  };

  useEffect(() => {
    setIsChecked(currentContact && currentContact[label]);

    if (currentContact) {
      setValue(label, currentContact[label]);
    }
  }, [currentContact]);

  return (
    <>
      <Box sx={guardianCircle} onClick={() => handleIsChecked()}>
        <Box sx={guardianCheckmark(isChecked)} {...register(label, {})} />
      </Box>

      <Typography sx={ml15}>{title}</Typography>
    </>
  );
}
