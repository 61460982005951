const termsContainer = {
  mt: "20px",
  width: "100%",
  display: "flex",
};

const klassFormAssignmentGradingWeightContainer = {
  mt: "105px",
  width: "250px",
};

const termsTitle = { fontSize: "28px" };

export {
  termsContainer,
  klassFormAssignmentGradingWeightContainer,
  termsTitle,
};
