import React from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { groupModalButton, groupModalStyle } from "./GroupModal.styles";
import { mt25 } from "../../sharedStyles";
import groupService from "../../../service/groupService";

export default function GroupModal({ open, handleClose, setSnackBarOpen }) {
  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    const response = await groupService.createGroup(data);

    if (response.data) {
      setSnackBarOpen({
        open: true,
        message: "Group created.",
      });
      handleClose();
    }
  };

  return (
    <Modal open={open} onClose={() => handleClose()}>
      <Box sx={groupModalStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Create a new group
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Enter the name of the group, and click create to start adding
          individuals to your new group.
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack sx={mt25} direction="row" alignItems="center">
            <TextField
              fullWidth
              label="Group name"
              variant="standard"
              {...register(`name`, {
                required: true,
              })}
            />
            <Button type="submit" variant="contained" sx={groupModalButton}>
              Create
            </Button>
          </Stack>
        </form>
      </Box>
    </Modal>
  );
}
