import React, { useState, useEffect, useContext } from "react";
import { useFormContext } from "react-hook-form";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import {
  academicsFormControl,
  academicsTextField2,
} from "./AcademicsInfo.style";
import ErasableTextField from "../../ErasableTextField";
import lookupService from "../../../service/lookupService";
import { PermissionsContext } from "../../../context/PermissionsContext";
import APP_PERMISSIONS from "../../../utils/constants/permissions";
import PERMISSION_TYPES from "../../../utils/constants/permission_types";

export default function AcademicsInfo({ student }) {
  const { register, setValue, getValues, trigger } = useFormContext();
  const [drivingInformationState, setDrivingInformationState] = useState(
    student.student_profile?.student_drives_to_school
  );
  const [is504Plan, set504PlanState] = useState(
    student.student_profile?.is_504_plan
  );
  const [isIEP, setIEPState] = useState(student.student_profile?.is_504_plan);
  const [participatedInECP, setParticipatedInECP] = useState(
    student.student_profile?.participated_in_ecp_program
  );
  const [fromPartnerCampus, setFromPartnerCampus] = useState(
    student.student_profile?.is_from_a_partner_campus
  );
  const [adventistOrganizations, setAdventistOrganizations] = useState([]);
  const [ecpPrograms, setECPPrograms] = useState([]);
  const { hasPermission } = useContext(PermissionsContext);

  const managePermission = hasPermission(
    APP_PERMISSIONS.STUDENT_PROFILE,
    PERMISSION_TYPES.MANAGE
  );

  useEffect(() => {
    setValue(
      "student_profile_attributes.adventist_organization",
      student.student_profile?.adventist_organization
    );
    setValue(
      "student_profile_attributes.ECP_program_name",
      student.student_profile?.ECP_program_name
    );
    trigger();
    Promise.all([
      lookupService.fetchAdventistOrganizations(),
      lookupService.fetchECPPrograms(),
    ]).then((response) => {
      setAdventistOrganizations(response[0].data.data);
      setECPPrograms(response[1].data.data);
    });
  }, [student]);

  const enableDisableDrivingInformation = (event) => {
    if (!event.target.checked) {
      setValue("student_profile_attributes.driver_license_number", null, {
        shouldDirty: true,
      });
      setValue("student_profile_attributes.vehicle_license", null, {
        shouldDirty: true,
      });
      setValue("student_profile_attributes.vehicle_make_and_model", null, {
        shouldDirty: true,
      });
    }
    setDrivingInformationState(event.target.checked);
  };

  return (
    <Grid container spacing={2} mx={1} mt={2}>
      <Grid item container spacing={2} xs={12} md={12} pr={2}>
        <Grid item xs={4} my={1}>
          <Typography variant="profileTitle">Academics</Typography>
        </Grid>
        <Grid item xs={4} my={1} />
        <Grid item xs={4} my={1}>
          <Typography variant="profileTitle">Driving Information</Typography>
        </Grid>

        <Grid container item>
          <Grid item xs={12} sm={12} md={4} mb={2} px={3}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={student.student_profile?.is_aple}
                    {...register("student_profile_attributes.is_aple")}
                    disabled={!managePermission}
                  />
                }
                label="Is Aple"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={
                      student.student_profile?.is_a_part_time_student
                    }
                    {...register(
                      "student_profile_attributes.is_a_part_time_student"
                    )}
                    disabled={!managePermission}
                  />
                }
                label="Is a part time student"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={
                      student.student_profile?.is_a_boarding_student
                    }
                    {...register(
                      "student_profile_attributes.is_a_boarding_student"
                    )}
                    disabled={!managePermission}
                  />
                }
                label="Is a boarding student"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={
                      student.student_profile?.is_a_distant_learner
                    }
                    {...register(
                      "student_profile_attributes.is_a_distant_learner"
                    )}
                    disabled={!managePermission}
                  />
                }
                label="Is a distant learner"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={
                      student.student_profile?.is_a_international_student
                    }
                    {...register(
                      "student_profile_attributes.is_a_international_student"
                    )}
                    disabled={!managePermission}
                  />
                }
                label="Is an international student"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={
                      student.student_profile?.is_from_a_partner_campus
                    }
                    {...register(
                      "student_profile_attributes.is_from_a_partner_campus",
                      {
                        onChange: (e) => {
                          setFromPartnerCampus(e.target.checked);
                          if (!e.target.checked)
                            setValue(
                              "student_profile_attributes.adventist_organization",
                              null,
                              { shouldDirty: true }
                            );
                        },
                      }
                    )}
                    disabled={!managePermission}
                  />
                }
                label="Is from a partner campus"
              />
              <FormControl sx={academicsFormControl}>
                <InputLabel>Adventist Organization</InputLabel>
                <Select
                  value={
                    getValues(
                      "student_profile_attributes.adventist_organization"
                    ) || ""
                  }
                  label="Adventist Organization"
                  {...register(
                    "student_profile_attributes.adventist_organization"
                  )}
                  disabled={!managePermission && !fromPartnerCampus}
                >
                  {adventistOrganizations.map((adventistOrganization) => (
                    <MenuItem
                      key={adventistOrganization.id}
                      value={adventistOrganization.name}
                    >
                      {adventistOrganization.name}
                    </MenuItem>
                  ))}
                  {/* <MenuItem value="Organization2">Organization2</MenuItem> */}
                </Select>
              </FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={
                      student.student_profile?.participated_in_ecp_program
                    }
                    {...register(
                      "student_profile_attributes.participated_in_ecp_program",
                      {
                        onChange: (e) => {
                          setParticipatedInECP(e.target.checked);
                          if (!e.target.checked)
                            setValue(
                              "student_profile_attributes.ECP_program_name",
                              null,
                              { shouldDirty: true }
                            );
                        },
                      }
                    )}
                    disabled={!managePermission}
                  />
                }
                label="Participated in ECP Program"
              />
              <FormControl sx={academicsFormControl}>
                <InputLabel>ECP Program Name</InputLabel>
                <Select
                  value={
                    getValues("student_profile_attributes.ECP_program_name") ||
                    ""
                  }
                  label="ECP Program Name"
                  disabled={!managePermission && !participatedInECP}
                  {...register("student_profile_attributes.ECP_program_name")}
                >
                  {ecpPrograms.map((ecpProgram) => (
                    <MenuItem key={ecpProgram.id} value={ecpProgram.name}>
                      {ecpProgram.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={
                      student.student_profile
                        ?.obtained_a_certificate_of_completion
                    }
                    {...register(
                      "student_profile_attributes.obtained_a_certificate_of_completion"
                    )}
                    disabled={!managePermission}
                  />
                }
                label="Obtained a certification of completion"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={12} md={4} mb={2} px={3}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={student.student_profile?.is_IEP}
                    {...register("student_profile_attributes.is_IEP", {
                      onChange: (e) => {
                        setIEPState(e.target.checked);
                        if (!e.target.checked)
                          setValue(
                            "student_profile_attributes.text_info_IEP",
                            null,
                            { shouldDirty: true, shouldTouch: true }
                          );
                      },
                    })}
                    disabled={!managePermission}
                  />
                }
                label="IEP"
              />
              <FormControl sx={academicsFormControl}>
                <TextField
                  label="Text Field"
                  variant="outlined"
                  defaultValue={student.student_profile?.text_info_IEP}
                  {...register("student_profile_attributes.text_info_IEP")}
                  disabled={!managePermission && !isIEP}
                />
              </FormControl>

              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={student.student_profile?.is_504_plan}
                    {...register("student_profile_attributes.is_504_plan", {
                      onChange: (e) => {
                        set504PlanState(e.target.checked);
                        if (!e.target.checked)
                          setValue(
                            "student_profile_attributes.text_info_504_plan",
                            null,
                            { shouldDirty: true, shouldTouch: true }
                          );
                      },
                    })}
                    disabled={!managePermission}
                  />
                }
                label="504 plan"
              />
              <FormControl sx={academicsFormControl}>
                <TextField
                  label="Text Field"
                  variant="outlined"
                  defaultValue={student.student_profile?.text_info_504_plan}
                  {...register("student_profile_attributes.text_info_504_plan")}
                  disabled={!managePermission && !is504Plan}
                />
              </FormControl>
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={12} md={4} mb={2} px={3}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={
                    student.student_profile?.student_drives_to_school
                  }
                  {...register(
                    "student_profile_attributes.student_drives_to_school",
                    {
                      onChange: (e) => enableDisableDrivingInformation(e),
                    }
                  )}
                  disabled={!managePermission}
                />
              }
              label="Student drives to school"
            />
            <FormGroup sx={academicsFormControl}>
              <Box sx={academicsTextField2}>
                <ErasableTextField
                  id="student_profile_attributes.driver_license_number"
                  label="Drivers license number"
                  variant="outlined"
                  defaultValue={student.student_profile?.driver_license_number}
                  fullWidth
                  disabled={!managePermission && !drivingInformationState}
                />
              </Box>
              <Box sx={academicsTextField2}>
                <ErasableTextField
                  id="student_profile_attributes.vehicle_license"
                  label="Vehicle license"
                  variant="outlined"
                  defaultValue={student.student_profile?.vehicle_license}
                  fullWidth
                  disabled={!managePermission && !drivingInformationState}
                />
              </Box>
              <Box sx={academicsTextField2}>
                <ErasableTextField
                  id="student_profile_attributes.vehicle_make_and_model"
                  label="Vehicle make and model"
                  variant="outlined"
                  defaultValue={student.student_profile?.vehicle_make_and_model}
                  fullWidth
                  disabled={!managePermission && !drivingInformationState}
                />
              </Box>
            </FormGroup>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
