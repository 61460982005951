import axios from "../utils/axios-util";

const usersEndpoint = "/api/users";

const fetchAll = async (params) => {
  const { data } = await axios.post(usersEndpoint, params);

  if (!data.data) {
    console.error("Error fetching users from API");
    return [];
  }
  return data;
};

const sendResetPasswordInstructions = async (params) => {
  const { data } = await axios.post("/users/password", params);

  if (!data) {
    console.error("Error sending password reset instructions.");
    return [];
  }
  return data;
};

const resetPassword = async (params) => {
  const { data } = await axios.put("/users/password", params);

  if (!data) {
    console.error("Error updating password.");
    return [];
  }
  return data;
};

export default {
  fetchAll,
  sendResetPasswordInstructions,
  resetPassword,
};
