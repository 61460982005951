import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import RegistrationForm from "../components/Admissions/Setup/RegistrationForm";
import { font40 } from "../components/sharedStyles";
import theme from "../utils";
import axios from "../utils/axios-util";
import {
  backgroundStyle,
  centerContent,
  colorWhite,
  footerButtonStyle,
  footerStyle,
  footerTextStyle,
} from "./Registration.style";

export default function Registration({
  loadRegistration = false,
  needAuthorization = true,
}) {
  const params = useParams();
  const { token } = params;
  const [loading, setLoading] = useState(true);
  const [tokenError, setTokenError] = useState(false);
  const [schoolYear, setSchoolYear] = useState(null);

  const verifyToken = async () => {
    try {
      const response = await axios.get(
        `/api/school_year_applications/verify_token`,
        {
          params: {
            token,
          },
        }
      );
      if (response.status === 200) {
        setLoading(false);
        setSchoolYear(response.data.data.school_year);
      }
    } catch (e) {
      setLoading(false);
      setTokenError(true);
    }
  };

  const openSchoolWebsite = (url) => {
    // eslint-disable-next-line no-param-reassign
    url = url.match(/^https?:/) ? url : `//${url}`;
    window.open(url, "_blank", "noreferrer");
  };

  useEffect(() => {
    if (!loadRegistration) {
      verifyToken();
    }
  }, []);

  return (
    <Box sx={backgroundStyle(loadRegistration)}>
      {loading && (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={centerContent}
        >
          <Grid item xs={12}>
            <CircularProgress color="inherit" size={100} />
          </Grid>
        </Grid>
      )}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        pb={10}
      >
        {!loading && tokenError && (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={centerContent}
          >
            <Grid item xs={12}>
              <Typography sx={font40}>Invalid Registration Form</Typography>
            </Grid>
          </Grid>
        )}
        {!loading && !tokenError && (
          <RegistrationForm
            schoolYear={schoolYear}
            needAuthorization={needAuthorization}
          />
        )}
      </Grid>
      {!loading && !tokenError && (
        <Grid
          container
          pb={3}
          px={() => (useMediaQuery(theme.breakpoints.up("lg")) && 15) || 3}
          sx={footerStyle}
        >
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={12} md={6} mt={3}>
              <Typography sx={footerTextStyle}>
                {schoolYear?.school?.description}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} mt={3} alignSelf="center">
              {schoolYear?.school?.website && (
                <Button
                  sx={footerButtonStyle}
                  onClick={() =>
                    openSchoolWebsite(schoolYear?.school?.website?.toString())
                  }
                >
                  Launch School Website
                </Button>
              )}
            </Grid>
          </Grid>
          <Grid container item xs={12} mt={2}>
            <Grid
              item
              xs={12}
              md={6}
              mt={3}
              alignSelf="flex-end"
              sx={colorWhite}
            >
              <div>
                <div
                  style={{
                    display: "inline",
                    paddingLeft: 10,
                    fontStyle: "italic",
                    fontWeight: 500,
                    fontSize: 12,
                  }}
                >
                  {" "}
                  Powered by{" "}
                </div>
                <img
                  src="/assets/logo-new.png"
                  style={{
                    width: 189,
                    height: 26,
                    objectFit: "contain",
                  }}
                  alt="ae logo"
                />
                <Typography
                  style={{
                    fontWeight: 700,
                    fontSize: 12,
                    textTransform: "uppercase",
                    paddingLeft: 125,
                  }}
                >
                  Adventist Education Student Information System
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={6} mt={3} textAlign="right">
              <div>
                <img
                  src="/assets/ae-footer-logo.png"
                  style={{
                    width: "inherit",
                    height: 90,
                    objectFit: "contain",
                  }}
                  alt="ae logo"
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
