import LabelComponent from "./LabelComponent";
import SupportingTextComponent from "./SupportingTextComponent";
import RequiredComponent from "./RequiredComponent";
import MinLengthComponent from "./MinLengthComponent";
import MaxLengthComponent from "./MaxLengthComponent";
import MultiLineComponent from "./MultiLine";
import OptionComponent from "./OptionComponent";
import MinValueComponent from "./MinValueComponent";
import MaxValueComponent from "./MaxValueComponent";
import IsDecimalComponent from "./IsDecimalComponent";
import DecimalPlacesComponent from "./DecimalPlacesComponent";
import IsMaskedComponent from "./IsMaskedComponent";
import NameComponent from "./NameComponent";
import FieldWidthComponent from "./FieldWidth";
import IsMultiselectComponent from "./IsMultiselectComponent";
import VisibilityCondition from "./VisibilityCondition";
import ValidityCondition from "./ValidityCondition";

const fieldPropertiesMap = {
  name: NameComponent,
  label: LabelComponent,
  "supporting-text": SupportingTextComponent,
  required: RequiredComponent,
  "min-length": MinLengthComponent,
  "max-length": MaxLengthComponent,
  "min-value": MinValueComponent,
  "max-value": MaxValueComponent,
  "multi-line": MultiLineComponent,
  options: OptionComponent,
  "is-decimal": IsDecimalComponent,
  "decimal-places": DecimalPlacesComponent,
  "is-masked": IsMaskedComponent,
  "field-width": FieldWidthComponent,
  "multi-select": IsMultiselectComponent,
  "validity-condition": ValidityCondition,
  "visibility-condition": VisibilityCondition,
};

export default fieldPropertiesMap;
