import React from "react";
import { Box, Typography } from "@mui/material";
import { assignmentFormTitle } from "./AssignmentForm.styles";
import { assignmentsTableBox } from "./Klasses.styles";
import StudentListForm from "../StudentListForm";

export default function StudentList({
  klassStudents,
  assignmentStudents,
  schoolId,
  selectedStudents,
  setSelectedStudents,
  isGoogleAssignment,
  isAssignment,
}) {
  const isSelected = (id) => selectedStudents.indexOf(id) !== -1;

  const handleCheckboxClick = (event, name) => {
    const selectedStudentsIndex = selectedStudents.indexOf(name);
    let newSelected = [];

    if (selectedStudentsIndex === -1) {
      newSelected = newSelected.concat(selectedStudents, name);
    } else if (selectedStudentsIndex === 0) {
      newSelected = newSelected.concat(selectedStudents.slice(1));
    } else if (selectedStudentsIndex === selectedStudents.length - 1) {
      newSelected = newSelected.concat(selectedStudents.slice(0, -1));
    } else if (selectedStudentsIndex > 0) {
      newSelected = newSelected.concat(
        selectedStudents.slice(0, selectedStudentsIndex),
        selectedStudents.slice(selectedStudentsIndex + 1)
      );
    }

    setSelectedStudents(newSelected);
  };

  return (
    <Box sx={assignmentsTableBox}>
      <Typography sx={assignmentFormTitle}>Assigned Students</Typography>
      <StudentListForm
        schoolId={schoolId}
        students={klassStudents}
        assignmentStudents={assignmentStudents}
        totalPages={0}
        page={1}
        isSelected={isSelected}
        handleCheckboxClick={handleCheckboxClick}
        isGoogleAssignment={isGoogleAssignment}
        isAssignment={isAssignment}
      />
    </Box>
  );
}
