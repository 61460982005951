import React, { useState, useEffect } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { pageTabs } from "../sharedStyles";
import { parameterizeString } from "../../utils/constants/utils";

export default function ReportCardTabs({ schoolId }) {
  const tabs = [
    {
      index: 0,
      label: "Dashboard",
      url: `/school/${schoolId}/report-cards/dashboard`,
    },
    {
      index: 1,
      label: "Report Cards",
      url: `/school/${schoolId}/report-cards/`,
    },
  ];
  const [tabValue, setTabValue] = useState(tabs[0]);

  const navigate = useNavigate();
  const location = useLocation();

  const handleTabChange = (value, path) => {
    setTabValue(tabs[value]);

    navigate(path);
  };

  useEffect(() => {
    const getTabFromUrl = tabs.find((tab) =>
      parameterizeString(location.pathname).includes(
        parameterizeString(tab.label)
      )
    );

    setTabValue(getTabFromUrl || tabs[0]);
  }, [location]);

  return (
    <Box>
      <Tabs value={tabValue.index} sx={pageTabs}>
        {tabs.map((tab) => (
          <Tab
            key={tab.index}
            label={tab.label}
            onClick={() => handleTabChange(tab.index, tab.url)}
          />
        ))}
      </Tabs>
    </Box>
  );
}
