import React, { useEffect, useState } from "react";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import {
  font16,
  font20,
  font26,
  font32,
  formButtons,
  formContainer,
  formHeader,
  formTitle,
  mb10,
  mb45,
  mt25,
} from "../sharedStyles";
import {
  reportCardDetail,
  reportCardDrawer,
  reportCardGradeBox,
  reportCardSkillsContainer,
} from "./ReportCardTKKDrawer.styles";
import socialDevelopmentCommentService from "../../service/socialDevelopmentCommentService";

export default function ReportCardSocialDevelopmentDrawer({
  student,
  teachers,
  socialDevelopment,
  handleDrawerClose,
  socialDevelopmentSkills,
  term,
}) {
  const teacher = teachers.find((t) => t.id === student.homeroom_teacher_id);
  const studentScores = socialDevelopmentSkills.scores_by_student[student.id];
  const [comment, setComment] = useState("");

  const getComment = async () => {
    const { data } = await socialDevelopmentCommentService.fetch({
      term_id: term.id,
      student_id: student.id,
    });

    setComment(data?.comment);
  };

  useEffect(() => {
    (async () => {
      await getComment();
    })();
  }, []);

  const renderGradeBox = (sdSkillID) => (
    <Box sx={reportCardGradeBox}>
      {studentScores && studentScores[sdSkillID]
        ? studentScores[sdSkillID].score || ""
        : ""}
    </Box>
  );

  return (
    <Box sx={reportCardDrawer}>
      <Stack direction="row" sx={formHeader}>
        <Typography sx={formTitle}>Social Development</Typography>
        <Stack direction="row">
          <Button sx={formButtons} onClick={() => handleDrawerClose()}>
            Close
          </Button>
        </Stack>
      </Stack>
      <Box sx={formContainer}>
        <Box sx={reportCardDetail}>
          <Typography sx={font32}>
            {student?.first_name} {student?.last_name}
          </Typography>
          <Typography sx={font20}>
            {teacher?.first_name} {teacher?.last_name}
          </Typography>
        </Box>
        <Box sx={mb45}>
          <Box sx={mt25}>
            <TextField
              id="comment"
              label="Report Card Comment"
              fullWidth
              type="text"
              multiline
              rows={4}
              disabled
              value={comment}
            />
          </Box>
        </Box>
        <Box>
          <Typography sx={{ ...font26, ...mb10 }}>Skill Grades</Typography>
          {socialDevelopment ? (
            Object.values(socialDevelopment).map((sd) => (
              <Box key={sd.id} sx={reportCardSkillsContainer}>
                {renderGradeBox(sd.id)}
                <Box sx={font16}>{sd.name}</Box>
              </Box>
            ))
          ) : (
            <Box>This Subject does not have skills associated.</Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
