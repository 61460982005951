import React, { useMemo } from "react";
import { Box, Container, Tab } from "@mui/material";
import {
  Outlet,
  useNavigate,
  useLocation,
  useParams,
  useOutletContext,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CssTabs, kidInfoTabBorder, kidInfoContainer } from "./Info.styles";

export default function GuardianKidsInfo() {
  const { t } = useTranslation("guardian", { keyPrefix: "studentTabs" });
  const params = useParams();
  const guardianId = params.id;
  const kidId = params.kid_id;
  const navigate = useNavigate();
  const location = useLocation();
  const { kidInfo } = useOutletContext();

  const isChecklistRoute = location.pathname.includes(
    "/home/enrollments/checklist"
  );

  const tabs = useMemo(() => {
    if (kidInfo && kidInfo.has_enrollment && !isChecklistRoute) {
      return [
        {
          index: 0,
          label: t("home"),
          url: `/guardian/${guardianId}/students/${kidId}/home`,
        },
        {
          index: 1,
          label: t("classes"),
          url: `/guardian/${guardianId}/students/${kidId}/classes`,
        },
        {
          index: 2,
          label: t("messages"),
          url: `/guardian/${guardianId}/students/${kidId}/messages`,
        },
        {
          index: 3,
          label: t("studentProfile"),
          url: `/guardian/${guardianId}/students/${kidId}/profile`,
        },
      ];
    }
    return [];
  }, [kidInfo, guardianId, kidId]);

  const getIndexFromLocation = () => {
    const index = tabs.findIndex((tab) => location.pathname.includes(tab.url));
    if (index < 0) return tabs[0]?.index;
    return index;
  };

  return (
    <Container maxWidth={false} sx={kidInfoContainer}>
      {tabs.length > 0 && (
        <Box sx={kidInfoTabBorder}>
          <CssTabs value={getIndexFromLocation()} onChange={() => {}}>
            {tabs.map((tab) => (
              <Tab
                key={tab.index}
                label={tab.label}
                onClick={() => navigate(tab.url)}
              />
            ))}
          </CssTabs>
        </Box>
      )}

      <Outlet />
    </Container>
  );
}
