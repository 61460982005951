import { Box, TextField } from "@mui/material";
import React from "react";

export default function NumberField({ field }) {
  return (
    <Box>
      <TextField
        fullWidth
        type="number"
        required={field.properties.isRequired}
        disabled
        label={field.label}
        key={field.fieldId}
        helperText={field.properties.supportingText}
      />
    </Box>
  );
}
