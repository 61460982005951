import { useState, useEffect } from "react";

const useFormattedMarkingCodes = (markingCodes) => {
  const [formattedMarkingCodes, setFormattedMarkingCodes] = useState([
    [],
    [],
    [],
  ]);

  useEffect(() => {
    if (markingCodes) {
      let newFormattedMarkingCodes = [[], [], []];
      const isPassFail = markingCodes.length === 2;
      if (isPassFail) {
        newFormattedMarkingCodes = [[]];
        newFormattedMarkingCodes[0].push({
          id: Math.floor(Math.random() * 1000),
          grading_scale_id: Math.floor(Math.random() * 1000),
          code: markingCodes[0].code,
          value: markingCodes[0].value,
        });
        newFormattedMarkingCodes[0].push({
          id: Math.floor(Math.random() * 1000),
          grading_scale_id: Math.floor(Math.random() * 1000),
          code: markingCodes[1].code,
          value: markingCodes[1].value,
        });
      } else {
        for (let index = 0; index < markingCodes.length; index += 1) {
          const code = markingCodes[index];
          const remainder = index % 3;
          newFormattedMarkingCodes[remainder].push(code);
        }

        const maxLength = Math.max(
          ...newFormattedMarkingCodes.map((subArray) => subArray.length)
        );
        newFormattedMarkingCodes.forEach((subArray) => {
          while (subArray.length < maxLength) {
            subArray.push({
              id: Math.floor(Math.random() * 1000),
              grading_scale_id: Math.floor(Math.random() * 1000),
              code: "",
              value: "",
            });
          }
        });
      }

      setFormattedMarkingCodes(newFormattedMarkingCodes);
    }
  }, [markingCodes]);

  return formattedMarkingCodes;
};

export default useFormattedMarkingCodes;
