import axios from "../utils/axios-util";

const studentPortalEndpoint = "/api/student_portal";

const fetchStudentInfo = async (id) => {
  const { data } = await axios.get(`${studentPortalEndpoint}/${id}`);
  return data;
};

const fetchStudentMessages = async (id, params) => {
  const { data } = await axios.get(
    `${studentPortalEndpoint}/${id}/messages`,
    params
  );
  return data;
};

const fetchStudentGrades = async (id, params) => {
  const { data } = await axios.get(
    `${studentPortalEndpoint}/${id}/grades`,
    params
  );
  return data;
};

const fetchStudentClassesAndAssignments = async (id, params) => {
  const { data } = await axios.get(
    `${studentPortalEndpoint}/${id}/classes_and_assignments`,
    params
  );
  return data;
};

const fetchStudentRecipients = async (id, params) => {
  const { data } = await axios.get(
    `${studentPortalEndpoint}/${id}/messages/recipients`,
    params
  );
  return data;
};

const fetchStudentClasses = async (id, params) => {
  const { data } = await axios.get(
    `${studentPortalEndpoint}/${id}/classes`,
    params
  );
  return data;
};

const updateTurnInAssignment = async (id, params) => {
  const { data } = await axios.put(
    `${studentPortalEndpoint}/${id}/update_turn_in_assignment`,
    params
  );
  return data;
};

export {
  fetchStudentInfo,
  fetchStudentMessages,
  fetchStudentGrades,
  fetchStudentClassesAndAssignments,
  fetchStudentRecipients,
  fetchStudentClasses,
  updateTurnInAssignment,
};
