const gDSContainer = {
  position: "absolute",
  backgroundColor: "white",
  top: "0",
  left: "0",
  height: "95px",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
};

const currentDateTypography = { fontSize: 23, width: "100%" };

const navigationButtons = { display: "flex" };

const gDSContainerInner = {
  backgroundColor: "white",
  zIndex: 999,
  width: "374px",
  height: "100%",
  alignItems: "center",
  justifyContent: "space-between",
  display: "flex",
};

export {
  gDSContainer,
  currentDateTypography,
  navigationButtons,
  gDSContainerInner,
};
