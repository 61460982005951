import { Divider, Grid, Typography } from "@mui/material";
import React from "react";
import StageInstance from "./StageInstance";
import QontoStepIcon from "../../../../QontoStepIcon";

export default function ProcessInstance({
  currentStaffId,
  processInstance,
  setProcessInstance,
}) {
  const isProcessInstanceCompleted = () => {
    let isCompleted = true;
    processInstance.stage_instances.forEach((stageInstance) => {
      stageInstance.element_instances.forEach((elementInstance) => {
        if (elementInstance.status !== "completed") isCompleted = false;
      });
    });

    return isCompleted;
  };

  const updateProcessInstance = () => {
    const cloneProcessInstance = { ...processInstance };
    setProcessInstance(cloneProcessInstance);
  };

  return (
    processInstance && (
      <Grid container>
        <Grid item md={12} container mt={4} mb={2.5} alignItems="center">
          <QontoStepIcon checked={isProcessInstanceCompleted()} />{" "}
          <Typography fontSize={20} fontWeight={500} color="black" ml={2.5}>
            {processInstance.admission_process.name}
          </Typography>
        </Grid>
        <Grid item md={12} container alignItems="center" mb={2}>
          {processInstance.stage_instances.map((stageInstance) => (
            <StageInstance
              key={stageInstance.id}
              stageInstance={stageInstance}
              updateProcessInstance={updateProcessInstance}
              currentStaffId={currentStaffId}
            />
          ))}
        </Grid>
        <Grid item md={12}>
          <Divider />
        </Grid>
      </Grid>
    )
  );
}
