/* eslint-disable no-param-reassign */
import React, { useContext, useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import {
  guardianActions,
  notesTextArea,
  formGrid,
  guardianGrid,
} from "../../Account/Guardian/Guardian.styles";
import {
  font17,
  mb25,
  ml20,
  danger,
  loader,
  formHeader,
  formButtons,
} from "../../../sharedStyles";
import theme from "../../../../utils";
import { convertBase64 } from "../../../../utils/constants/utils";
import GeneralInfo from "../../Account/Guardian/GeneralInfo";
import Options from "../../Account/Guardian/Options";
import Notifications from "../../Account/Guardian/Notifications";
import {
  fetchGuardian,
  postGuardian,
  updateGuardian,
  deleteGuardian,
} from "../../../../service/guardiansService";
import AddressFields from "../../../AddressFields";
import { StudentActionsContext } from "../../../../context/StudentActionsContext";
import GenericSubmitButton from "../../../GenericSubmitButton";
import AlertDialog from "../../../AlertDialog";
import { SnackbarContext } from "../../../../context/SnackbarContext";
import lookupService from "../../../../service/lookupService";
import CustomFieldRenderer from "../../../School/CustomFields/CustomFieldRenderer/CustomFieldRenderer";

function Guardian({
  setIsGuardianDrawerOpen,
  currentGuardianId,
  setCurrentGuardianId,
  setShowGuardianCreateForm,
  setShowGuardianSearchForm,
  isGuardianView,
  studentSlug,
  defaultGuardianKidId,
  onUpdateGuardian = () => {},
}) {
  const { t } = useTranslation("common");
  const params = useParams();
  const methods = useForm();
  const [currentGuardian, setCurrentGuardian] = useState({});
  const [languages, setLanguages] = useState([]);
  const [isSameAddress, setIsSameAddress] = useState(true);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const studentActionsContext = useContext(StudentActionsContext);
  const setDisplay = isGuardianView && "none";
  const location = useLocation();
  const snackbarContext = useContext(SnackbarContext);
  const kidId = params.kid_id || defaultGuardianKidId;

  const getLanguages = async () => {
    const response = await lookupService.fetchLanguages();

    if (response.data.data) {
      setLanguages(response.data.data);
    }
  };

  useEffect(() => {
    if (currentGuardianId) {
      (async () => {
        if (currentGuardian) {
          let { id } = params;
          if (location.pathname.includes("guardian")) {
            id = "";
            if (kidId) {
              id = kidId;
            }
          }
          if (params.student_slug) {
            id = params.student_slug;
          }
          if (studentSlug) id = studentSlug;
          const response = await fetchGuardian(currentGuardianId, id);
          setCurrentGuardian(response.data);
        }
      })();
    }
  }, [currentGuardianId]);

  useEffect(() => {
    getLanguages();
  }, []);

  const sameAddressValue = () =>
    currentGuardian
      ? currentGuardian.physical_address_id ===
        currentGuardian.mailing_address_id
      : true;

  useEffect(() => {
    setIsSameAddress(sameAddressValue());
  }, [currentGuardian]);

  useEffect(() => {
    methods.setValue("same_address", isSameAddress);
  }, [isSameAddress]);

  const requiredMessage = "This field is required.";

  const handleCheckboxChange = () => {
    setIsSameAddress(!isSameAddress);
  };

  const cleanForm = () => {
    setIsGuardianDrawerOpen(false);
    if (setShowGuardianCreateForm) setShowGuardianCreateForm(false);
    if (setShowGuardianSearchForm) setShowGuardianSearchForm(true);

    if (studentActionsContext.fireParentsUpdated)
      studentActionsContext.fireParentsUpdated(true);
    if (setCurrentGuardianId) setCurrentGuardianId(0);
  };

  const handleCancel = () => {
    cleanForm();
  };

  const notesDefaultValue = () =>
    currentGuardian && currentGuardian.notes ? currentGuardian.notes : "";

  const isAddGuardian = () => !currentGuardianId;

  const loadingComplete = () =>
    (currentGuardian && Object.keys(currentGuardian).length > 0) ||
    isAddGuardian();

  const onSubmit = async (data) => {
    if (data.contactType === "is_custodial_parent") {
      data.is_custodial_parent = true;
      data.is_primary_contact = false;
    }

    if (data.contactType === "is_primary_contact") {
      data.is_primary_contact = true;
      data.is_custodial_parent = false;
    }

    if (data.emergencyContactType === "is_emergency_contact") {
      data.is_emergency_contact = true;
      data.is_primary_emergency_contact = false;
    }

    if (data.emergencyContactType === "is_primary_emergency_contact") {
      data.is_primary_emergency_contact = true;
      data.is_emergency_contact = false;
    }

    const body = {
      ...data,
      student_id: kidId || params.student_slug || studentSlug,
      same_address: isSameAddress,
    };

    if (data.profilePicture[0])
      body.profilePicture = await convertBase64(data.profilePicture[0]);

    if (isAddGuardian()) {
      try {
        const response = await postGuardian(body);
        if (response) {
          studentActionsContext.fireParentsUpdated(true);
          cleanForm();
        }
      } catch (error) {
        if (error.response.data.errors.email) {
          methods.setError("email", {
            type: "manual",
            message: error.response.data.errors.email[0],
          });
        } else if (
          error.response.data.errors.includes("index_guardians_on_email")
        ) {
          methods.setError("email", {
            type: "manual",
            message: "Email is already taken.",
          });
        } else {
          snackbarContext.setSnackbar({
            open: true,
            message: error.response.data.message,
            severity: "error",
          });
        }
      }
    } else if (isGuardianView && data.email === "") {
      methods.setError("email", {
        type: "manual",
        message: "cant be blank",
      });
    } else {
      try {
        const response = await updateGuardian(currentGuardian.id, body);
        onUpdateGuardian(response.data.data);
        if (studentActionsContext.fireParentsUpdated)
          studentActionsContext.fireParentsUpdated(true);
        cleanForm();
        snackbarContext.setSnackbar({
          open: true,
          message: t("profileUpdated", { keyPrefix: "guardianDrawer" }),
          severity: "success",
        });
      } catch (error) {
        if (error.response.data.errors.email) {
          methods.setError("email", {
            type: "manual",
            message: error.response.data.errors.email[0],
          });
        } else if (
          error.response.data.errors.includes("index_guardians_on_email")
        ) {
          methods.setError("email", {
            type: "manual",
            message: "Email is already taken.",
          });
        } else {
          snackbarContext.setSnackbar({
            open: true,
            message: error.response.data.message,
            severity: "error",
          });
        }
      }
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleDelete = async () => {
    let { id } = params;
    if (params.student_slug) {
      id = params.student_slug;
    }
    await deleteGuardian(currentGuardianId, id);
    if (studentActionsContext.fireParentsUpdated)
      studentActionsContext.fireParentsUpdated(true);
    cleanForm();
    snackbarContext.setSnackbar({
      open: true,
      message: "Guardian deleted.",
      severity: "success",
    });
  };

  const renderError = (message) => (
    <Typography sx={danger}>{message}</Typography>
  );

  const renderForm = () => (
    <>
      <GeneralInfo
        requiredMessage={requiredMessage}
        renderError={renderError}
        currentGuardian={currentGuardian}
        currentGuardianId={currentGuardianId}
        isGuardianView={isGuardianView}
        languages={languages}
      />
      <Grid container item sx={formGrid} columnSpacing={4} rowSpacing={2}>
        <Grid item sm={12}>
          <Typography variant="h5">
            {t("mailingAddress", { keyPrefix: "guardianDrawer" })}
          </Typography>
        </Grid>
        <AddressFields
          label="mailing_address"
          requiredMessage={requiredMessage}
          renderError={renderError}
          entity={currentGuardian}
        />
      </Grid>
      <Grid container item sx={formGrid} columnSpacing={4} rowSpacing={2}>
        <Grid item sm={12}>
          <Typography variant="h5">
            {t("physicalAddress", { keyPrefix: "guardianDrawer" })}
          </Typography>
        </Grid>
        <Grid item sm={12}>
          <FormGroup sx={ml20}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isSameAddress}
                  onClick={() => setIsSameAddress(!isSameAddress)}
                  onChange={handleCheckboxChange}
                />
              }
              label={
                <Typography sx={font17}>
                  {t("sameAsMailing", { keyPrefix: "guardianDrawer" })}
                </Typography>
              }
            />
          </FormGroup>
        </Grid>
        {!isSameAddress && (
          <AddressFields
            label="physical_address"
            requiredMessage={requiredMessage}
            renderError={renderError}
            entity={currentGuardian}
          />
        )}
      </Grid>
      <Grid container item columnSpacing={2} sx={guardianGrid}>
        <Grid
          container
          item
          sm={6}
          sx={{
            borderRight: `solid 1px ${theme.palette.text.primary}`,
          }}
          style={{ display: setDisplay }}
        >
          <Options
            methods={methods}
            currentGuardian={currentGuardian}
            isGuardianView={isGuardianView}
            languages={languages}
          />
        </Grid>
        <Grid container item sm={6} rowSpacing={1}>
          <Notifications
            currentGuardian={currentGuardian}
            studentId={kidId}
            studentSlug={params.student_slug || studentSlug}
          />
        </Grid>
      </Grid>
      <Divider sx={{ width: "100%", borderColor: "black" }} />
      <Grid container spacing={2} mx={1} mt={2}>
        <Grid item container spacing={2} xs={12} md={12} pr={2}>
          <Grid container item>
            <Grid item xs={12} sm={12} md={12} mb={2} px={3}>
              <CustomFieldRenderer
                sectionType="guardian_profile"
                customFieldResponses={
                  currentGuardian.custom_field_responses || []
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider sx={{ width: "100%", borderColor: "black" }} />
      <Grid
        container
        item
        columnSpacing={2}
        sx={guardianGrid}
        style={{ display: setDisplay }}
      >
        <Grid container item sm={12}>
          <Typography variant="h2" sx={mb25}>
            Notes
          </Typography>
        </Grid>
        <Grid container item sm={12}>
          <TextareaAutosize
            aria-label="empty textarea"
            placeholder="Notes"
            style={notesTextArea}
            defaultValue={notesDefaultValue()}
            {...methods.register("notes")}
          />
        </Grid>
      </Grid>
    </>
  );

  return (
    <Grid container>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          style={{ width: "100%" }}
        >
          <Grid
            container
            item
            sx={formHeader}
            direction="row"
            columnSpacing={4}
            rowSpacing={0}
          >
            <Grid item sm={7}>
              <Grid item>
                <Typography variant="h2">
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {isGuardianView
                    ? t("myProfile", { keyPrefix: "guardianDrawer" })
                    : isAddGuardian()
                    ? "Add Family"
                    : "Edit Family"}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container sx={guardianActions} sm={5}>
              <Button
                variant="outlined"
                sx={formButtons}
                onClick={handleCancel}
              >
                {t("cancel")}
              </Button>
              {loadingComplete() && currentGuardianId && !isGuardianView ? (
                <GenericSubmitButton
                  text="Remove"
                  submittingText="Removing..."
                  type="button"
                  onClick={() => setDialogOpen(true)}
                />
              ) : (
                ""
              )}
              {loadingComplete() ? (
                <GenericSubmitButton
                  text={t("save")}
                  submittingText={t("saving")}
                />
              ) : (
                ""
              )}
            </Grid>
          </Grid>

          {loadingComplete() ? (
            renderForm()
          ) : (
            <CircularProgress sx={loader} size={100} />
          )}
        </form>
      </FormProvider>
      <AlertDialog
        isOpen={isDialogOpen}
        handleClose={handleCloseDialog}
        handleConfirm={handleDelete}
      />
    </Grid>
  );
}

export default Guardian;
