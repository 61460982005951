import FieldBase from "./FieldBase";

export default class HorizontalDivider extends FieldBase {
  constructor(field) {
    super(field);
    this.propertyComponents = [];
    this.w = 12;
    this.isResizable = false;
    this.draggableItemStyle = {};
    this.fieldContainerStyle = {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    };
  }
}
