import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import {
  Box,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
} from "@mui/material";
import { klassFormTitle } from "./KlassForm.styles";

export default function GradingScale({
  gradingScales,
  activeKlass,
  selectedSubject,
  subjects,
}) {
  const { control, setValue } = useFormContext();
  const isDisabled = activeKlass && true;
  const [showPassFail, setShowPassFail] = React.useState(false);

  const handlePassFail = (value) => {
    const nonCoreSubject = subjects.some(
      (s) => s.id === selectedSubject && s.non_core
    );
    if (nonCoreSubject) {
      const selectedGradingScaleIs38 =
        Number(value) === gradingScales.find((gs) => gs.name === "3-8")?.id;
      if (selectedGradingScaleIs38) {
        setShowPassFail(true);
      } else {
        setShowPassFail(false);
      }
    }
  };

  React.useEffect(() => {
    if (activeKlass) {
      setValue("gradingScales", activeKlass.grading_scale_id);
      setValue("is_pass_fail", activeKlass.is_pass_fail);
      setShowPassFail(activeKlass.is_pass_fail);
      return;
    }
    setValue("gradingScales", gradingScales[0]?.id);
    setValue("is_pass_fail", false);
    setShowPassFail(false);
  }, [selectedSubject]);

  return (
    <Box>
      <Typography sx={klassFormTitle}>Grading Scale*</Typography>
      {gradingScales.length > 0 && (
        <Controller
          name="gradingScales"
          defaultValue={activeKlass?.grading_scale_id || gradingScales[0].id}
          control={control}
          render={({ field }) => (
            <FormControl>
              <RadioGroup
                {...field}
                onChange={(event, value) => {
                  field.onChange(value);
                  handlePassFail(value);
                }}
                value={field.value}
              >
                {gradingScales.map((gs) => (
                  <FormControlLabel
                    key={gs.id}
                    value={gs.id}
                    control={<Radio />}
                    label={gs.name}
                    disabled={isDisabled}
                  />
                ))}
                {showPassFail && (
                  <Box ml={3}>
                    <FormControlLabel
                      control={
                        <Controller
                          name="is_pass_fail"
                          control={control}
                          render={({ field: props }) => (
                            <Checkbox
                              {...props}
                              disabled={!showPassFail || isDisabled}
                              checked={!!props.value}
                              onChange={(e) => props.onChange(e.target.checked)}
                            />
                          )}
                        />
                      }
                      label="Pass/Fail"
                    />
                  </Box>
                )}
              </RadioGroup>
            </FormControl>
          )}
        />
      )}
    </Box>
  );
}
