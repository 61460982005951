import React from "react";
import { Grid, Typography } from "@mui/material";

export default function PrintLayoutFooter({ content }) {
  return (
    <tfoot
      className="report-footer"
      style={{
        display: "none",
      }}
    >
      <style>
        {`
          @media print {
            .report-footer {
              display: table-footer-group !important;
            }

            .report-footer-cell {
              padding: 2rem 1rem 1rem 2rem;
            }

            @-moz-document url-prefix() {
              .report-footer-cell {
                padding: 0;
              }
            }
          }
        `}
      </style>
      <tr>
        <td className="report-footer-cell">
          <Grid container justifyContent="space-between" direction="row">
            <Typography
              sx={{
                textTransform: "none",
                fontSize: 18,
                color: "black",
                fontWeight: 500,
                visibility: "hidden",
              }}
            >
              {content}
            </Typography>
            <Typography
              sx={{
                textTransform: "none",
                fontSize: 18,
                color: "black",
                fontWeight: 500,
                position: "fixed",
                bottom: 15,
              }}
            >
              {content}
            </Typography>
          </Grid>
        </td>
      </tr>
    </tfoot>
  );
}
