import {
  Button,
  DialogContent,
  Grid,
  Grow,
  Popper,
  Typography,
} from "@mui/material";
import React from "react";
import GenericSubmitButton from "./GenericSubmitButton";
import {
  cancelButtonStyle,
  popperStyle,
  saveButtonStyle,
} from "./SaveChangesPopper.style";
import { font15 } from "./sharedStyles";

function SaveChangesPopper({
  text = "You have unsaved changes",
  savingText = "Saving...",
  open,
  setOpen,
  handleCancel,
  handleSave = () => {},
}) {
  const id = open ? "transition-popper" : undefined;

  const handleClose = () => {
    setOpen(false);
    if (handleCancel) {
      handleCancel();
    }
  };

  return (
    <Popper id={id} open={open} transition sx={popperStyle}>
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} in={open} timeout={350}>
          <Grid container>
            <Grid item xs={7}>
              <DialogContent>
                <Typography variant="subtitle" sx={font15}>
                  {text}
                </Typography>
              </DialogContent>
            </Grid>
            <Grid container xs={5} alignSelf="center" spacing={0}>
              <Grid item xs={6} textAlign="center">
                <Button sx={cancelButtonStyle} onClick={handleClose}>
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <GenericSubmitButton
                  text="Save"
                  submittingText={savingText}
                  onClick={() => handleSave()}
                  sx={saveButtonStyle}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grow>
      )}
    </Popper>
  );
}

export default SaveChangesPopper;
