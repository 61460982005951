import theme from "../../utils";

const tableDefault = {
  fontWeight: 400,
  color: "black",
  fontSize: 14,
  paddingTop: "82px",
};

const tableBodyGrade = {
  ...tableDefault,
  fontSize: 22,
  textAlign: "center",
  paddingTop: "20px",
};

const tableBorderRight = {
  borderRight: "1px solid #e0e0e0",
};

const cellGrade = {
  p: 0,
  width: "90px",
  height: "90px",
  "& :hover": {
    cursor: "pointer",
    backgroundColor: "#ededed",
  },
};

const cellSquare = {
  "&:focus-within": {
    border: `6px solid ${theme.palette.cell.selected}`,
  },
  height: "100%",
  width: "100%",
  justifyContent: "center",
  "& input": {
    textAlign: "center",
    textTransform: "uppercase",
  },
};

const cellInput = {
  height: "100%",
  width: "100%",
};

const cellPopover = {
  borderRadius: "20px",
  width: "350px",
  overflow: "visible",
  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
};

const popoverButton = {
  maxWidth: "50px",
  maxHeight: "40px",
  minWidth: "50px",
  minHeight: "40px",
  mx: "5px",
  mr: "20px",
};

const popoverHead = {
  display: "block",
  mb: "20px",
};

const popoverGradeBox = {
  display: "flex",
  alignItems: "center",
  justifyContent: "left",
  margin: "10px",
  paddingLeft: "35px",
};

export {
  tableBorderRight,
  tableBodyGrade,
  cellSquare,
  cellInput,
  cellGrade,
  cellPopover,
  popoverButton,
  popoverHead,
  popoverGradeBox,
};
