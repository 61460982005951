const attendanceTableHead = {
  border: "none",
  py: 0,
};

const attendanceTableHeadKlass = {
  ...attendanceTableHead,
  pr: "25px !important",
};

const badgeCell = { width: "5%", textAlign: "center" };

const attendanceTableHeadDayInner = {
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
};

const attendanceQuickfillIcon = {
  position: "absolute",
  right: "4px",
};

const attendanceTableHeadText = {
  color: "#b2b3b4",
  cursor: "pointer",
  fontSize: "14px",
  display: "flex",
  alignItems: "center",
  userSelect: "none",
};

const attendanceTableHeadTextActive = {
  ...attendanceTableHeadText,
  color: "black",
};

const attendanceTableHeadName = {
  ...attendanceTableHead,
  width: "18%",
};

const circleContainer = {
  display: "inline-block",
  backgroundColor: "#00d53b",
  margin: "10px",
  borderRadius: "50%",
};

const cirleContent = {
  color: "white",
  display: "table-cell",
  verticalAlign: "middle",
  textAlign: "center",
  textDecoration: "none",
  height: "100px",
  width: "100px",
  fontSize: "30px",
};

const tableAvatar = {
  backgroundColor: "#5077A4",
  fontSize: "16px",
};

const attendanceTableHeadQtr = {
  width: "180px",
  ...attendanceTableHead,
};

const attendanceTableHeadActions = {
  ...attendanceTableHead,
  width: "180px",
  borderRight: "1px solid black",
  borderLeft: "1px solid black",
  cursor: "pointer",
};

const massActionButton = {
  fontSize: "12px",
  cursor: "pointer",
  "&:not(:last-of-type)": {
    mr: "10px",
  },
};

const attendanceTableHeadDay = {
  textAlign: "center",
};

const attendanceTableHeadWeekDay = {
  fontSize: "12px",
  fontWeight: "bold",
  textTransform: "uppercase",
};

const attendanceTableHeadRow = {
  verticalAlign: "bottom",
  "& th": {
    px: 0,
  },
};

const attendanceTableActionWeek = {
  borderRight: "1px solid black",
  borderLeft: "1px solid black",
  p: "0px !important",
};

const attendanceTableAction = {
  ...attendanceTableActionWeek,
  px: "10px",
};

const attendanceTableBtn = (color) => ({
  textTransform: "none",
  fontSize: "27px",
  color: "#79747E",
  height: "40px",
  minWidth: "40px",
  width: "40px",
  fontWeight: "bold",
  backgroundColor: color,
  // "&:not(:last-of-type)": {
  //   mr: "13px",
  // },
  "&:hover": {
    backgroundColor: color,
  },
});

const klassAttendanceBtn = {
  minWidth: "60px",
  width: "60px",
};

const attendanceTableHeadDayContainer = (isKlassAttendance) => ({
  width: isKlassAttendance ? "120px" : "80px",
});

const attendanceTableBottom = {
  height: "50px",
};

const attendanceTableName = {
  // display: "flex",
  // alignItems: "center",
  color: "black",
  "& p": {
    fontSize: "20px",
  },
};

const stackStyle = {
  marginLeft: "10px",
  marginRight: "10px",
};

const daySelectContainer = {
  width: "380px",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-end",
};

const attendanceTableHeadComment = {
  fontSize: "10px",
  color: "black",
  ml: "12px",
};

const attendanceRow = (isEnrolled) => ({
  backgroundColor: isEnrolled ? "white" : "#D9D9D9",
  "& td": {
    py: "5px",
    px: "10px",
  },
});

export {
  attendanceTableHead,
  attendanceTableHeadName,
  attendanceTableHeadQtr,
  attendanceTableHeadActions,
  attendanceTableHeadText,
  massActionButton,
  attendanceTableHeadDay,
  attendanceTableHeadRow,
  attendanceTableAction,
  attendanceTableActionWeek,
  attendanceTableBtn,
  attendanceTableHeadWeekDay,
  attendanceTableHeadDayInner,
  attendanceTableHeadDayContainer,
  attendanceTableHeadTextActive,
  attendanceTableHeadComment,
  attendanceTableBottom,
  attendanceTableName,
  badgeCell,
  circleContainer,
  cirleContent,
  tableAvatar,
  stackStyle,
  daySelectContainer,
  attendanceRow,
  attendanceQuickfillIcon,
  klassAttendanceBtn,
  attendanceTableHeadKlass,
};
