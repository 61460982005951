import FieldBase from "./FieldBase";

export default class DropdownList extends FieldBase {
  constructor(field) {
    super(field);
    this.label = field.label;
    this.dataType = "value_text";

    this.properties.options = field.properties?.options || [];
    this.propertyComponents = [
      "name",
      "label",
      "supporting-text",
      "required",
      "options",
      "multi-select",
    ];
    this.properties.isMultiselect = field.properties?.isMultiselect || false;
  }
}
