import { Box, Container } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import FieldSetTabs from "./FieldSetTabs";
import { tabsContainer, fieldSetContainer } from "./FieldSet.style";

export default function FieldSet() {
  return (
    <Container maxWidth={false} variant="header" sx={fieldSetContainer}>
      <Box sx={tabsContainer}>
        <FieldSetTabs />
      </Box>
      <Box py={2} px={2} maxHeight="92vh" overflow="auto">
        <Outlet />
      </Box>
    </Container>
  );
}
