import React from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { font12, black } from "../../../sharedStyles";

export default function KlassStudentCategoryList({ categories }) {
  return (
    <TableContainer>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell sx={black}>
              <Typography sx={font12}>CATEGORIES</Typography>
            </TableCell>
            <TableCell sx={black}>
              <Typography sx={font12}>WEIGHT</Typography>
            </TableCell>
            <TableCell sx={black}>
              <Typography sx={font12}>ASSIGNMENTS</Typography>
            </TableCell>
            <TableCell sx={black}>
              <Typography sx={font12}>SCORE</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {categories.map((kat) => (
            <TableRow>
              <TableCell sx={{ maxWidth: 200, color: "black" }}>
                <Typography>{kat.name}</Typography>
              </TableCell>
              <TableCell sx={black}>
                <Typography>{kat.category_weight}%</Typography>
              </TableCell>
              <TableCell sx={black}>
                <Typography>{kat.number_of_assignments || "-"}</Typography>
              </TableCell>
              <TableCell sx={black}>
                <Typography>{kat.category_score || "-"}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
