const groupTypeContainer = {
  color: "black",
  mb: "20px",
};

const groupTab = {
  px: "0px",
  minWidth: 0,
  mr: "20px",
  textTransform: "capitalize",
  "&.Mui-selected": {
    color: "#2196F3",
  },
};

export { groupTypeContainer, groupTab };
