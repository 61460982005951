import React from "react";
import { Typography, Stack, Grid, Button } from "@mui/material";
import { proficiencyLevels } from "../../utils/constants/toolTipOptions";

export default function renderSkillOptions(
  skill,
  formattedMarkingCodes,
  handleClick
) {
  return (
    <Grid
      container
      item
      xs={12}
      align="center"
      mb={2}
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      <Grid
        container
        item
        xs={12}
        justifyContent="center"
        alignItems="center"
        mt={2}
      >
        {skill.name !== "TK-K" ? (
          <Grid
            container
            item
            xs={12}
            align="center"
            mb={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              mt={2}
            >
              {proficiencyLevels.map((level) => (
                <Grid
                  key={level.value}
                  container
                  item
                  xs={12}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <Grid item xs={4}>
                    <Button
                      variant="outlined"
                      sx={{
                        maxWidth: "50px",
                        maxHeight: "40px",
                        minWidth: "50px",
                        minHeight: "40px",
                      }}
                      onClick={() => handleClick(level.value)}
                    >
                      {level.value}
                    </Button>
                  </Grid>
                  <Grid item xs={8} alignSelf="left">
                    <Typography sx={{ textAlign: "left" }}>
                      {level.title}
                    </Typography>
                    <Typography sx={{ textAlign: "left" }}>
                      {level.description}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        ) : (
          <>
            {formattedMarkingCodes.map((row, index) => (
              <Grid
                container
                item
                xs={12}
                align="center"
                mt={1}
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                key={row[index]?.code}
              >
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  {row.map((option) => (
                    <Button
                      key={option?.code}
                      variant="outlined"
                      sx={{
                        maxWidth: "50px",
                        maxHeight: "40px",
                        minWidth: "50px",
                        minHeight: "40px",
                      }}
                      disabled={option?.code === ""}
                      onClick={() => handleClick(option?.code)}
                    >
                      {option?.code}
                    </Button>
                  ))}
                </Stack>
              </Grid>
            ))}
            <Grid
              container
              item
              xs={12}
              align="left"
              mt={1}
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Button
                  variant="outlined"
                  sx={{
                    maxWidth: "50px",
                    maxHeight: "40px",
                    minWidth: "50px",
                    minHeight: "40px",
                  }}
                  onClick={() => handleClick("N/A")}
                >
                  N/A
                </Button>
                <Button
                  disabled
                  sx={{
                    maxWidth: "50px",
                    maxHeight: "40px",
                    minWidth: "50px",
                    minHeight: "40px",
                  }}
                />
                <Button
                  disabled
                  sx={{
                    maxWidth: "50px",
                    maxHeight: "40px",
                    minWidth: "50px",
                    minHeight: "40px",
                  }}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} align="center" mt={2} />
          </>
        )}
      </Grid>
    </Grid>
  );
}
