import { Box, TextField } from "@mui/material";
import React from "react";

export default function Name({ field }) {
  return (
    <Box>
      <TextField
        fullWidth
        type="name-field"
        label={field.label}
        key={field.fieldId}
        helperText={field.properties.supportingText}
        minLength={field.properties.minLength}
        maxLength={field.properties.maxLength}
      />
    </Box>
  );
}
