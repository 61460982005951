import React, { useState } from "react";
import {
  Grid,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useFormContext } from "react-hook-form";
import AddressFields from "../../../AddressFields";
import { danger, font17 } from "../../../sharedStyles";

function AddressSection({ teacher, disabled = false }) {
  const { register } = useFormContext();
  const requiredMessage = "This field is required.";
  const [isSameAddress, setIsSameAddress] = useState(
    teacher.mailing_address_id === teacher.physical_address_id
  );

  const renderError = (message) => (
    <Typography sx={danger}>{message}</Typography>
  );

  const formGrid = {
    py: 5,
    pr: 8,
    justifyContent: "space-between",
    alignItems: "flex-start",
  };

  const handleCheckboxChange = () => {
    setIsSameAddress(!isSameAddress);
  };

  return (
    <>
      <Grid container item sx={formGrid} columnSpacing={4} rowSpacing={2}>
        <Grid item sm={12}>
          <Typography variant="h5">Mailing Address</Typography>
        </Grid>
        <AddressFields
          label="mailing_address"
          requiredMessage={requiredMessage}
          renderError={renderError}
          entity={teacher}
          disabled={disabled}
        />
      </Grid>
      <Grid container item sx={formGrid} columnSpacing={4} rowSpacing={2}>
        <Grid item sm={12}>
          <Typography variant="h5">Physical Address</Typography>
        </Grid>
        <Grid item sm={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={disabled}
                  defaultChecked={isSameAddress}
                  {...register("same_address", {
                    onChange: handleCheckboxChange,
                  })}
                />
              }
              label={
                <Typography sx={font17}>Same as mailing address</Typography>
              }
            />
          </FormGroup>
        </Grid>
        {!isSameAddress && (
          <AddressFields
            label="physical_address"
            requiredMessage={requiredMessage}
            renderError={renderError}
            entity={teacher}
            disabled={disabled}
          />
        )}
      </Grid>
    </>
  );
}

export default AddressSection;
