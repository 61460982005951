import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Container,
  Button,
  CircularProgress,
  Grid,
} from "@mui/material";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import {
  conversationInner,
  conversationTitle,
  conversationContainer,
  conversationDeletedMessagesContainer,
  backButton,
  backArrow,
} from "./Conversation.styles";
import { mt25, font14, loader } from "../../sharedStyles";
import messagesService from "../../../service/messagesService";
import SnackBarNotification from "../../SnackBarNotification";
import ConversationBox from "./ConversationBox";

export default function Conversation() {
  const params = useParams();
  const conversationKey = params.conversation_key;
  const [loading, setLoading] = useState(true);
  const [originalMessage, setOriginalMessage] = useState(null);
  const [conversationMessages, setConversationMessages] = useState([]);
  const [deletedMessages, setDeletedMessages] = useState([]);
  const [snackBarOpen, setSnackBarOpen] = useState({
    open: false,
    message: null,
  });
  const navigate = useNavigate();

  const getConversation = async () => {
    setLoading(true);
    const response = await messagesService.fetchConversation(conversationKey);

    if (response) {
      const messages = [
        response.data.original_message,
        ...response.data.replies,
      ];

      setOriginalMessage(response.data.original_message);
      setConversationMessages(
        messages.filter((message) => !message.is_deleted)
      );
      setDeletedMessages(messages.filter((message) => message.is_deleted));
      setLoading(false);
    }
  };

  useEffect(() => {
    getConversation();
  }, []);

  const hasConversation = conversationMessages.length > 0 && !loading;

  if (loading) {
    return <CircularProgress sx={loader} size={100} />;
  }

  return (
    <Grid container mt={2}>
      <Button
        onClick={() =>
          navigate(
            `/school/${params.school_id}/students/${params.student_slug}/messages/`
          )
        }
        sx={backButton}
      >
        <ArrowBackRoundedIcon size="small" sx={backArrow} />
        View all messages
      </Button>

      <Container maxWidth={false} variant="header" sx={conversationContainer}>
        <Container sx={conversationInner}>
          <Typography sx={conversationTitle}>
            {hasConversation && originalMessage?.subject}
          </Typography>

          <Box sx={mt25}>
            {hasConversation ? (
              conversationMessages.map((message) => (
                <ConversationBox message={message} key={message.id} />
              ))
            ) : (
              <Typography align="center">
                {`This conversation doesn't exist`}
              </Typography>
            )}
          </Box>
          {deletedMessages.length > 0 && (
            <Box sx={conversationDeletedMessagesContainer}>
              <Typography sx={font14}>
                {`${deletedMessages.length} deleted message in this conversation`}
              </Typography>
            </Box>
          )}
        </Container>
      </Container>

      <SnackBarNotification
        message={snackBarOpen.message}
        open={snackBarOpen.open}
        severity={snackBarOpen.severity}
        handleClose={() => setSnackBarOpen(false)}
      />
    </Grid>
  );
}
