import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { ArrowCircleRight } from "@mui/icons-material";
import {
  Typography,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Box,
} from "@mui/material";
import {
  CssTextField,
  loginScreenArrowCircle,
  loginScreenContainer,
  loginScreenForm,
  loginScreenGrid,
  loginScreenIconButton,
  loginScreenLogo,
} from "./LoginScreen.style";
import usersService from "../../service/usersService";
import { danger } from "../sharedStyles";
import { removeToken, storedToken } from "../../utils/constants/auth";
import axios from "../../utils/axios-util";

export default function ResetPassword() {
  const { t, i18n } = useTranslation("common", { keyPrefix: "password" });
  const [errors, setErrors] = useState({
    state: false,
    message: null,
  });
  const { register, handleSubmit, watch } = useForm();
  const [searchParams] = useSearchParams();
  const onSubmit = async (data) => {
    const response = await usersService.resetPassword({
      locale: i18n.language,
      password: data.password,
      password_confirmation: data.password_confirmation,
      token: searchParams.get("reset_password_token"),
    });

    if (response.message === "Password updated successfully.") {
      window.location.replace("/login");
    } else {
      setErrors({
        state: true,
        message: response.error.join(", "),
      });
    }
  };

  const handleLogout = async () => {
    const payload = await axios.delete("/users/sign_out");

    if (payload.data.message === "You are logged out.") {
      removeToken(storedToken);
    }
  };

  useEffect(() => {
    if (watch("password") !== watch("password_confirmation")) {
      setErrors({
        state: true,
        message: t("passwordConfirmationNotMatch"),
      });
    } else {
      setErrors({
        state: false,
        message: null,
      });
    }
  }, [watch("password"), watch("password_confirmation")]);

  useEffect(() => {
    if (storedToken) {
      handleLogout();
    }
  }, []);

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={loginScreenGrid}
    >
      <Grid item xs={3}>
        <Container style={loginScreenContainer}>
          <img src="assets/logo-new.png" style={loginScreenLogo} alt="logo" />
        </Container>

        <Box>
          <Typography variant="title" align="center">
            <p>{t("resetPassword")}</p>
          </Typography>

          <form onSubmit={handleSubmit(onSubmit)} style={loginScreenForm}>
            <CssTextField
              required
              type="password"
              id="new-password-required"
              label={t("enterNewPassword")}
              size="small"
              variant="filled"
              color="text"
              margin="dense"
              fullWidth
              {...register("password", { required: true })}
            />
            <CssTextField
              required
              type="password"
              id="confirm-password-required"
              label={t("confirmPassword")}
              size="small"
              variant="filled"
              color="text"
              margin="dense"
              fullWidth
              {...register("password_confirmation", {
                required: true,
                validate: errors.state,
              })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton type="submit" sx={loginScreenIconButton}>
                      <ArrowCircleRight
                        color="success"
                        sx={loginScreenArrowCircle}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            {errors && <Typography sx={danger}>{errors.message}</Typography>}
          </form>
        </Box>
      </Grid>
    </Grid>
  );
}
