import React from "react";
import { FormControl, InputLabel, Select, MenuItem, Box } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import {
  classSchedulesSelect,
  classSchedulesContainer,
  classSchedulesCheckbox,
  classSchedulesLabel,
} from "./ClassSchedulesSelect.styles";

export default function ClassSchedulesSelect({
  activeFilter,
  setActiveFilter,
  label,
  items,
  selectedItem,
  setSelectedItem,
  searchParams,
  setSearchParams,
}) {
  const isActive = activeFilter === label;
  const filters = ["class", "teacher", "student"];

  const handleChange = (e) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set(label.toLowerCase(), e.target.value);
    filters
      .filter((filter) => filter !== label.toLowerCase())
      .forEach((filter) => {
        updatedSearchParams.delete(filter);
      });

    setSelectedItem(e.target.value);
    setSearchParams(updatedSearchParams);
  };

  return (
    <Box
      sx={classSchedulesContainer(isActive)}
      onClick={() => setActiveFilter(label)}
    >
      <Box sx={classSchedulesCheckbox}>{isActive && <CheckIcon />}</Box>
      <FormControl sx={classSchedulesSelect(isActive)}>
        <InputLabel id="class" shrink sx={classSchedulesLabel(isActive)}>
          {label}
        </InputLabel>
        <Select
          labelId="class"
          id="class"
          value={selectedItem || 0}
          displayEmpty
          disabled={!isActive}
          onChange={handleChange}
        >
          <MenuItem value={0}>All</MenuItem>
          {items &&
            items.length > 0 &&
            items.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name ||
                  `${item.first_name} ${item.last_name} ${
                    item.is_active === false ? "(Inactive)" : ""
                  }`}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
}
