import FieldBase from "./FieldBase";

export default class NumberField extends FieldBase {
  constructor(field) {
    super(field);
    this.label = field.label;
    this.dataType = "value_number";
    this.properties.maxValue = field.properties?.maxValue || "";
    this.properties.minValue = field.properties?.minValue || "";

    this.propertyComponents = [
      "name",
      "label",
      "supporting-text",
      "min-value",
      "max-value",
      "required",

      "is-decimal",
      "decimal-places",
    ];
  }
}
