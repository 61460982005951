import axios from "../utils/axios-util";

const reportCardsEndpoint = "/api/report_cards";

const fetchStudents = async (params) => {
  const { data } = await axios.get(`${reportCardsEndpoint}/students`, params);
  if (!data.data) {
    console.error("Error fetching data from the API");
    return [];
  }
  return data;
};

const fetchKlasses = async (params) => {
  const { data } = await axios.post(`${reportCardsEndpoint}/klasses`, params);
  if (!data.data) {
    console.error("Error fetching data from the API");
    return [];
  }
  return data;
};

const fetchTermGrades = async (params) => {
  const { data } = await axios.get(
    `${reportCardsEndpoint}/term_grades`,
    params
  );
  if (!data.data) {
    console.error("Error fetching data from the API");
    return [];
  }
  return data;
};

const fetchGradeLevels = async (params) => {
  const { data } = await axios.post(
    `${reportCardsEndpoint}/grade_levels`,
    params
  );
  if (!data.data) {
    console.error("Error fetching data from the API");
    return [];
  }
  return data;
};

const fetchKlassesStudents = async (params) => {
  const { data } = await axios.post(
    `${reportCardsEndpoint}/klasses_students`,
    params
  );
  if (!data.data) {
    console.error("Error fetching data from the API");
    return [];
  }
  return data;
};

const fetchReportCards = async (params) => {
  const { data } = await axios.post(`${reportCardsEndpoint}/index`, params);
  if (!data.data) {
    console.error("Error fetching data from the API");
    return [];
  }
  return data;
};

const fetchStudentSkills = async (params) => {
  const { data } = await axios.post(`${reportCardsEndpoint}/skills`, params);
  if (!data.data) {
    console.error("Error fetching data from the API");
    return [];
  }
  return data;
};

const fetchSocialDevelopment = async (params) => {
  const { data } = await axios.post(
    `${reportCardsEndpoint}/social_development`,
    params
  );
  if (!data.data) {
    console.error("Error fetching data from the API");
    return [];
  }
  return data;
};

const publishReportCards = async (params) => {
  const { data } = await axios.post(`${reportCardsEndpoint}/publish`, params);
  if (!data.data) {
    console.error("Error fetching data from the API");
    return [];
  }
  return data;
};

const unpublishReportCards = async (params) => {
  const { data } = await axios.post(`${reportCardsEndpoint}/unpublish`, params);
  if (!data.data) {
    console.error("Error fetching data from the API");
    return [];
  }
  return data;
};

const distributeTermGrades = async (params) => {
  const { data } = await axios.post(
    `${reportCardsEndpoint}/distribute`,
    params
  );
  if (!data.data) {
    console.error("Error posting data to the API");
    return [];
  }
  return data;
};

const fetchDashboard = async (params) => {
  const { data } = await axios.get(`${reportCardsEndpoint}/dashboard`, params);
  if (!data.data) {
    console.error("Error fetching data from the API");
    return [];
  }
  return data;
};

const fetchPreview = async (params) => {
  const { data } = await axios.get(`/api/report_card_pdfs/preview`, { params });
  if (!data) {
    console.error("Error fetching data from the API");
    return [];
  }
  return data;
};

export default {
  fetchStudents,
  fetchKlasses,
  fetchTermGrades,
  fetchGradeLevels,
  fetchKlassesStudents,
  fetchReportCards,
  fetchStudentSkills,
  fetchSocialDevelopment,
  fetchDashboard,
  publishReportCards,
  unpublishReportCards,
  distributeTermGrades,
  fetchPreview,
};
