import axios from "../utils/axios-util";

const skillsEndpoint = "/api/skills";

const fetchAllSkills = async (params) => {
  const { data } = await axios.get(skillsEndpoint, params);
  if (!data.data) {
    console.error("Error fetching school from the API");
    return [];
  }
  return data;
};

export default { fetchAllSkills };
