import FieldBase from "./FieldBase";

export default class PhoneNumber extends FieldBase {
  constructor(field) {
    super(field);
    this.label = field.label;
    this.dataType = "value_text";

    this.propertyComponents = [
      "name",
      "label",
      "supporting-text",
      "required",

      "is-masked",
    ];
  }
}
