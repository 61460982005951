import axios from "../utils/axios-util";

const transcriptEndpoint = "/api/transcripts";

const fetchAll = async (params) => {
  const { data } = await axios.post(`${transcriptEndpoint}/index`, params);

  if (!data.data) {
    console.error("Error fetching transcripts from API");
    return [];
  }
  return data;
};

const createTranscript = async (params) => {
  const { data } = await axios.post(transcriptEndpoint, params);

  if (!data.data) {
    console.error("Error creating transcript from the API");
    return [];
  }

  return data;
};

const fetchTranscriptRecord = async (transcriptRecordID) => {
  const { data } = await axios.get(
    `${transcriptEndpoint}/${transcriptRecordID}`
  );
  if (!data.data) {
    console.error("Error fetching klass from the API");
    return [];
  }
  return data;
};

const updateTranscriptRecord = async (params) => {
  const { data } = await axios.patch(
    `${transcriptEndpoint}/${params.id}`,
    params
  );

  if (!data.data) {
    console.error("Error updating transcript record from the API");
    return [];
  }
  return data;
};

const deleteTranscript = async (id) => {
  const { data } = await axios.delete(`${transcriptEndpoint}/${id}`);
  return data;
};

export default {
  createTranscript,
  fetchAll,
  fetchTranscriptRecord,
  updateTranscriptRecord,
  deleteTranscript,
};
