import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams, useSearchParams, useOutletContext } from "react-router-dom";
import {
  Grid,
  Typography,
  useMediaQuery,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useForm } from "react-hook-form";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import Summary from "./Summary";
import EventsTable from "./EventsTable";
import SnackBarNotification from "../../SnackBarNotification";
import {
  eventsGroupBorderBox,
  quickFindBox,
  quickFindSearchField,
} from "./StudentInfoEvents.style";
import eventsService from "../../../service/eventsService";
import { StudentActionsContext } from "../../../context/StudentActionsContext";
import APP_PERMISSIONS from "../../../utils/constants/permissions";
import { PermissionsContext } from "../../../context/PermissionsContext";
import Authorize from "../../Authorize";

function StudentInfoEvents() {
  const studentActionsContext = useContext(StudentActionsContext);
  const [, open] = useOutletContext();
  const [events, setEvents] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const [order, setOrder] = useState(searchParams.get("direction") || "desc");
  const [orderBy, setOrderBy] = useState(searchParams.get("sort") || "date");
  const [count, setCount] = useState(10);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [snackBarOpen, setSnackBarOpen] = useState({
    open: false,
    message: null,
  });
  const [categories, setCategories] = useState([]);

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const params = useParams();
  const { register, handleSubmit, getValues, setValue } = useForm();
  const [showClearButton, setShowClearButton] = useState(
    searchParams.get("query")
  );
  const [selectedCategory, setSelectedCategory] = useState(
    Number(searchParams.get("selectedCategory"))
  );
  const searchRef = useRef(null);
  const { hasAnyPermissionType } = useContext(PermissionsContext);

  const studentEventsPermission = hasAnyPermissionType(
    APP_PERMISSIONS.STUDENT_EVENTS
  );

  const handleSackBarClose = () => {
    setSnackBarOpen(false);
  };

  const handleCheckboxClick = (event, name) => {
    const selectedEventsIndex = selectedEvents.indexOf(name);
    let newSelected = [];

    if (selectedEventsIndex === -1) {
      newSelected = newSelected.concat(selectedEvents, name);
    } else if (selectedEventsIndex === 0) {
      newSelected = newSelected.concat(selectedEvents.slice(1));
    } else if (selectedEventsIndex === selectedEvents.length - 1) {
      newSelected = newSelected.concat(selectedEvents.slice(0, -1));
    } else if (selectedEventsIndex > 0) {
      newSelected = newSelected.concat(
        selectedEvents.slice(0, selectedEventsIndex),
        selectedEvents.slice(selectedEventsIndex + 1)
      );
    }

    setSelectedEvents(newSelected);
  };

  const handleSearch = (data) => {
    if (data.search) searchParams.set("query", data.search);
    else searchParams.delete("query");
    setPage(1);
    searchParams.set("page", 1);
    setSearchParams(searchParams);
  };

  const handleQuickFindChange = (event) => {
    if (event.target.value) setShowClearButton(true);
    else setShowClearButton(false);
  };

  useEffect(() => {
    if (showClearButton) searchRef.current.focus();
  }, [showClearButton]);

  const handleClickOnClearButton = () => {
    setValue("search", "");
    setPage(1);
    setShowClearButton(false);
    handleSearch(getValues());
  };

  const cancelButtonStyle = {
    visibility: showClearButton ? "inherit" : "hidden",
  };

  const fetchAllEvents = async () => {
    const response = await eventsService.fetchAllEvents(
      params.student_slug,
      page,
      count,
      order,
      orderBy,
      searchParams.get("query") || "",
      searchParams.get("selectedCategory") || ""
    );
    if (response.data) {
      setEvents(response.data);

      const queryPage = searchParams.get("page") || 1;
      const getTotalPages = response.pagination.total_pages;
      const currentPage = queryPage > getTotalPages ? getTotalPages : queryPage;
      const countPerPage = searchParams.get("count") || 10;
      setTotalPages(getTotalPages);
      setPage(currentPage);
      setCount(countPerPage);
    }
    if (response.countByCategory) {
      setCategories(response.countByCategory);
    }
  };

  const handleEventClick = (id) => {
    studentActionsContext.openEventDrawer(fetchAllEvents, id);
  };

  useEffect(() => {
    if (studentActionsContext.medicalEventsUpdated) {
      fetchAllEvents();
      studentActionsContext.cleanMedicalEventsUpdated();
    }
  }, [studentActionsContext.medicalEventsUpdated]);

  useEffect(() => {
    (async () => {
      fetchAllEvents();
    })();

    setOrder(searchParams.get("direction") || "desc");
    setOrderBy(searchParams.get("sort") || "date");
  }, [searchParams]);

  return (
    <Authorize permission={studentEventsPermission}>
      <Grid
        container
        item
        columnSpacing={3}
        mt={3}
        direction={smallScreen ? "column" : "row"}
        md={open ? 10 : 7.5}
        paddingX={1}
      >
        <Grid container item sm={12} direction="column">
          <Grid container item direction="row" rowSpacing={2}>
            <Grid
              container
              item
              xs={12}
              justifyContent={
                categories.length > 4 ? "flex-start" : "space-evenly"
              }
            >
              <Summary
                open={open}
                categories={categories}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
                setPage={setPage}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container sx={quickFindBox}>
        <Grid container item md={1.5}>
          <form onSubmit={handleSubmit(handleSearch)}>
            <TextField
              label="Quick Find"
              variant="standard"
              color="primary"
              defaultValue={searchParams.get("query") || ""}
              InputLabelProps={{
                shrink: showClearButton || searchRef.current?.focused,
              }}
              {...register("search", {
                onChange: handleQuickFindChange,
              })}
              sx={quickFindSearchField}
              inputRef={searchRef}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickOnClearButton}
                      sx={cancelButtonStyle}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                    <IconButton type="submit">
                      <SearchIcon size="small" color="primary" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </form>
        </Grid>
      </Grid>
      <Grid item sx={eventsGroupBorderBox} xs={12} />
      <Grid container item columnSpacing={3} mt={3} mb={9} md={open ? 12 : 9.5}>
        <Grid container item sm={12} direction="column">
          <Grid container item direction="row" rowSpacing={2}>
            <Grid container item sm={12} justifyContent="space-around">
              {events.length > 0 ? (
                <EventsTable
                  events={events}
                  searchParams={searchParams}
                  setSearchParams={setSearchParams}
                  setPage={setPage}
                  page={page}
                  totalPages={totalPages}
                  order={order}
                  orderBy={orderBy}
                  setOrder={setOrder}
                  setOrderBy={setOrderBy}
                  selectedEvents={selectedEvents}
                  setSelectedEvents={setSelectedEvents}
                  handleCheckboxClick={handleCheckboxClick}
                  handleEventClick={handleEventClick}
                  count={count}
                  setCount={setCount}
                />
              ) : (
                <Typography>
                  No{" "}
                  {categories
                    .find((cat) => cat.category_id === Number(selectedCategory))
                    ?.name?.toLowerCase()}{" "}
                  events for this student.
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <SnackBarNotification
        message={snackBarOpen.message}
        open={snackBarOpen.open}
        handleClose={handleSackBarClose}
      />
    </Authorize>
  );
}

export default StudentInfoEvents;
