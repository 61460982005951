import { Grid, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

export default function SupportingTextComponent({ selectedField }) {
  const { register, setValue } = useFormContext();

  useEffect(() => {
    setValue("supportingText", selectedField.properties.supportingText, {
      shouldDirty: true,
      shouldTouch: true,
    });
  }, [selectedField]);

  return (
    <Grid item md={12}>
      <TextField
        fullWidth
        label="Supporting Text"
        {...register("supportingText")}
        disabled={selectedField.source_field_id}
      />
    </Grid>
  );
}
