const tableContainer = {
  padding: "80px",
  paddingTop: "20px",
};

const tableHead = {
  borderBottom: "1.5px solid #000000",
};

const tableHeadRow = {
  "& th": {
    fontWeight: 500,
    fontSize: "11px",
    lineHeight: "16px",
    letterSpacing: "-0.3px",
    color: "#000000",
    textTransform: "uppercase",
  },
};

const tableBodyRow = {
  borderBottom: "1.5px solid #BFC7CA",
  "& td": {
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "-0.5px",
    color: "#000000",
  },
};

const gridItemTitle = {
  fontSize: "34px",
  lineHeight: "56px",
  letterSpacing: "-2px",
  color: "#000000",
};

const gridItemTerm = {
  float: "right",
  marginTop: "10px",
  fontSize: "24px",
  color: "#000000",
};

export {
  tableContainer,
  tableHead,
  tableHeadRow,
  tableBodyRow,
  gridItemTitle,
  gridItemTerm,
};
