import React from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import theme from "../../utils";

const functionCardStyled = {
  backgroundColor: `${theme.palette.primary.main}!important`,
  color: "white",
  borderRadius: "3%",
};

export default function FunctionCard(props) {
  return (
    <Grid {...props} item xs={6} sm={4} md={3} pt={2} pl={2}>
      <Card elevation={0} sx={functionCardStyled}>
        <CardContent>
          <Typography variant="h5">Function</Typography>
          <Typography>Optional details</Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}
