import {
  Box,
  Button,
  Stack,
  Typography,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  Link,
  Checkbox,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams, Link as RouterLink } from "react-router-dom";
import classScheduleService from "../../../service/classScheduleService";
import studentsService from "../../../service/studentsService";
import {
  formButtons,
  formHeader,
  formTitle,
  loader,
  font16,
  font14,
  tableAvatar,
  font20,
} from "../../sharedStyles";
import {
  borderRight,
  filtersBorder,
  formStudentsAvatar,
  formStudentsCheckbox,
  formStudentsTable,
  assignmentsFilterButton,
  assignmentsFilterButtonKlass,
  filtersBorderKlass,
} from "./ClassScheduleBulkEnroll.styles";
import klassesStudentsService from "../../../service/klassesStudentsService";
import GenericSubmitButton from "../../GenericSubmitButton";

export default function ClassScheduleBulkEnroll({
  schoolId,
  handleBulkEnrollDrawer,
  setSnackBarOpen,
  currentSchool,
}) {
  const methods = useForm();
  const params = useParams();
  const [classes, setClasses] = useState([]);
  const [students, setStudents] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [gradeLevel, setGradeLevel] = useState("all");
  const [gradingScaleKlass, setGradingScaleKlass] = useState("all");
  const [loading, setLoading] = useState(false);

  let levels = [];
  if (currentSchool.has_pk_tracker) {
    levels.push("PK");
  }
  levels = levels.concat(["TK", "K", "1", "2", "3", "4", "5", "6", "7", "8"]);
  if (currentSchool.has_secondary_students) {
    levels = levels.concat(["9", "10", "11", "12"]);
  }

  const loadClassSchedule = async () => {
    const response = await classScheduleService.fetchClassSchedule(
      params.school_id,
      params.class_schedule_id,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      gradingScaleKlass
    );

    if (response.data) {
      setClasses(response.data.klasses);
    }
  };

  const loadStudents = async () => {
    const response = await studentsService.fetchAllStudentsInSchool(
      { params: { school_id: schoolId, isPaginated: null } },
      1,
      "grade-level",
      "asc",
      "",
      null,
      gradeLevel
    );

    if (response.data) {
      setStudents(response.data);
    }
  };

  const handleCheckboxClick = (_event, id, selectedState, setSelectedState) => {
    if (id === "all" && _event.target.checked) {
      const allSelected = students.map((s) => s.id);
      setSelectedState(allSelected);
    } else if (id === "all" && !_event.target.checked) {
      setSelectedState([]);
    } else {
      const selected = new Set(selectedState);

      if (selected.has(id)) {
        selected.delete(id);
      } else {
        selected.add(id);
      }

      if (selected.size !== selectedState.size) {
        setSelectedState(Array.from(selected));
      }
    }
  };

  const isSelected = (id, selectedState) => selectedState.indexOf(id) !== -1;
  const isAllSelected = students.length === selectedStudents.length;

  const createKlassesStudents = async () => {
    const response = await klassesStudentsService.create({
      klasses: selectedClasses,
      students: selectedStudents,
    });

    if (response.data) {
      setSnackBarOpen({
        open: true,
        message: "Students enrolled.",
      });
      handleBulkEnrollDrawer(false);
    }
  };

  const onSubmit = async () => {
    if (selectedClasses.length === 0) {
      setSnackBarOpen({
        open: true,
        message: "Please select a class.",
        severity: "error",
      });
      return;
    }
    if (selectedStudents.length === 0) {
      setSnackBarOpen({
        open: true,
        message: "Please select a student.",
        severity: "error",
      });
      return;
    }
    createKlassesStudents();
  };

  const handleStudentFilterClick = (value) => {
    setGradeLevel(value);
    setSelectedStudents([]);
  };

  const handleKlassFilterClick = (value) => {
    setGradingScaleKlass(value);
    setSelectedClasses([]);
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([loadClassSchedule(), loadStudents()]).then(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    loadStudents();
  }, [gradeLevel]);

  useEffect(() => {
    loadClassSchedule();
  }, [gradingScaleKlass]);

  function handleProfilePictureSrc(student) {
    if (student?.image_url) {
      return (
        <img
          alt="profile_image"
          src={student.image_url.url}
          style={{
            borderRadius: "100px",
            width: "40px",
            height: "40px",
            objectFit: "cover",
          }}
        />
      );
    }
    return (
      <Avatar sx={tableAvatar}>
        {student.first_name.charAt(0).toUpperCase()}
      </Avatar>
    );
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {loading ? (
          <CircularProgress color="inherit" size={100} sx={loader} />
        ) : (
          <>
            <Stack direction="row" sx={formHeader}>
              <Typography sx={formTitle}>Bulk Enroll Students</Typography>
              <Stack direction="row">
                <Button
                  sx={formButtons}
                  onClick={() => handleBulkEnrollDrawer(false)}
                >
                  Cancel
                </Button>
                <GenericSubmitButton text="Save" submittingText="Saving..." />
              </Stack>
            </Stack>
            <Box p={2}>
              <Grid container spacing={2}>
                <Grid item xs={7} sx={filtersBorder}>
                  <Button
                    sx={assignmentsFilterButton(gradeLevel === "all")}
                    size="small"
                    onClick={() => handleStudentFilterClick("all")}
                  >
                    All
                  </Button>
                  {levels.map((level) => (
                    <Button
                      sx={assignmentsFilterButton(gradeLevel === level, level)}
                      size="small"
                      key={level}
                      onClick={() => handleStudentFilterClick(level)}
                    >
                      {level}
                    </Button>
                  ))}
                </Grid>
                <Grid item xs={7} pr={2} sx={borderRight}>
                  {students.length > 0 ? (
                    <TableContainer sx={{ height: "70vh" }}>
                      <Table stickyHeader size="medium" sx={formStudentsTable}>
                        <TableHead>
                          <TableRow>
                            <TableCell padding="none" sx={formStudentsCheckbox}>
                              <Checkbox
                                onClick={(e) =>
                                  handleCheckboxClick(
                                    e,
                                    "all",
                                    selectedStudents,
                                    setSelectedStudents
                                  )
                                }
                                color="primary"
                                checked={isAllSelected}
                              />
                            </TableCell>
                            <TableCell />
                            <TableCell padding="none">
                              <Typography sx={font14}>NAME</Typography>
                            </TableCell>
                            <TableCell align="center" padding="none">
                              <Typography sx={font14}>GRADE</Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {students.map((s) => (
                            <TableRow hover tabIndex={-1} key={s.id}>
                              <TableCell
                                padding="none"
                                sx={formStudentsCheckbox}
                              >
                                <Checkbox
                                  onClick={(e) =>
                                    handleCheckboxClick(
                                      e,
                                      s.id,
                                      selectedStudents,
                                      setSelectedStudents
                                    )
                                  }
                                  color="primary"
                                  checked={isSelected(s.id, selectedStudents)}
                                />
                              </TableCell>

                              <TableCell padding="none" sx={formStudentsAvatar}>
                                <Link
                                  to={`/school/${schoolId}/students/${s.slug}/account`}
                                  underline="none"
                                  component={RouterLink}
                                  target="_blank"
                                >
                                  {handleProfilePictureSrc(s)}
                                </Link>
                              </TableCell>

                              <TableCell padding="none">
                                <Link
                                  to={`/school/${schoolId}/students/${s.slug}/account`}
                                  underline="none"
                                  component={RouterLink}
                                  target="_blank"
                                >
                                  <Typography sx={font20}>
                                    {`${s.last_name}, ${s.first_name}`}
                                  </Typography>
                                </Link>
                              </TableCell>
                              <TableCell align="center" padding="none">
                                <Typography sx={font16}>
                                  {s.grade_level}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <Box>No students found.</Box>
                  )}
                </Grid>
                <Grid item xs={5} mt={3}>
                  <Typography sx={font20}>
                    Select all classes you would like to enroll students in:
                  </Typography>
                  <Grid item sx={filtersBorderKlass}>
                    <Button
                      sx={assignmentsFilterButtonKlass(
                        gradingScaleKlass === "all"
                      )}
                      size="small"
                      onClick={() => handleKlassFilterClick("all")}
                    >
                      All
                    </Button>
                    {currentSchool.has_pk_tracker && (
                      <Button
                        sx={assignmentsFilterButtonKlass(
                          gradingScaleKlass === "pk"
                        )}
                        size="small"
                        onClick={() => handleKlassFilterClick("pk")}
                      >
                        PK
                      </Button>
                    )}
                    <Button
                      sx={assignmentsFilterButtonKlass(
                        gradingScaleKlass === "tk-k"
                      )}
                      size="small"
                      onClick={() => handleKlassFilterClick("tk-k")}
                    >
                      TK-K
                    </Button>
                    <Button
                      sx={assignmentsFilterButtonKlass(
                        gradingScaleKlass === "1-2"
                      )}
                      size="small"
                      onClick={() => handleKlassFilterClick("1-2")}
                    >
                      1-2
                    </Button>
                    <Button
                      sx={assignmentsFilterButtonKlass(
                        gradingScaleKlass === "3-8"
                      )}
                      size="small"
                      onClick={() => handleKlassFilterClick("3-8")}
                    >
                      3-8
                    </Button>
                    {currentSchool.has_secondary_students && (
                      <Button
                        sx={assignmentsFilterButtonKlass(
                          gradingScaleKlass === "9-12"
                        )}
                        size="small"
                        onClick={() => handleKlassFilterClick("9-12")}
                      >
                        9-12
                      </Button>
                    )}
                  </Grid>
                  {classes.length > 0 ? (
                    <TableContainer sx={{ height: "50vh" }}>
                      <Table stickyHeader size="medium">
                        <TableBody>
                          {classes.map((_class) => (
                            <TableRow hover tabIndex={-1} key={_class.id}>
                              <TableCell
                                padding="none"
                                sx={formStudentsCheckbox}
                              >
                                <Checkbox
                                  onClick={(e) =>
                                    handleCheckboxClick(
                                      e,
                                      _class.id,
                                      selectedClasses,
                                      setSelectedClasses
                                    )
                                  }
                                  color="primary"
                                  checked={isSelected(
                                    _class.id,
                                    selectedClasses
                                  )}
                                />
                              </TableCell>

                              <TableCell padding="none">
                                <Typography>
                                  {_class.name} ({_class.abbreviation})
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <Box>No classes found.</Box>
                  )}
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </form>
    </FormProvider>
  );
}
