const gradeLockIconContainer = {
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  top: 0,
  left: 0,
  zIndex: 1,
};

export default gradeLockIconContainer;
