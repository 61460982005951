import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";

const CssTextField = styled(TextField)({
  "& .MuiFilledInput-root": {
    backgroundColor: "#ffffff !important",
    color: "text.primary",
    borderRadius: 3,
    "&:before": {
      borderBottom: "0 !important",
    },
  },
});

const loginScreenGrid = {
  backgroundColor: "primary.main",
  color: "#ffffff",
  height: "100%",
};

const loginScreenContainer = {
  maxWidth: 645,
  marginLeft: "auto",
  marginRight: "auto",
};

const loginScreenLogo = { width: "100%" };

const loginScreenForm = {
  maxWidth: 348,
  marginLeft: "auto",
  marginRight: "auto",
};

const loginScreenPasswordSpacing = { marginTop: "60px" };
const loginScreenPasswordLink = {
  textAlign: "center",
  fontSize: "18px",
};

const loginScreenArrowCircle = {
  height: "50px",
  width: "40px",
};

const loginScreenIconButton = {
  padding: 0,
  "&:hover": {
    background: "transparent",
  },
};

const continueButton = {
  width: "20%",
  backgroundColor: "#2196F3",
  border: "1px solid #79747E",
  color: "white",
  fontSize: "14px",
  textTransform: "none",
};

const buttonCheckBox = { width: "100%", textAlign: "center" };

const checkbox = {
  color: "white",
  "&.Mui-checked": {
    color: "white",
  },
};

const forgotPasswordSuccessText = {
  fontSize: "18px",
  maxWidth: 700,
};

export {
  loginScreenContainer,
  loginScreenPasswordSpacing,
  loginScreenForm,
  loginScreenGrid,
  loginScreenLogo,
  CssTextField,
  loginScreenIconButton,
  loginScreenArrowCircle,
  continueButton,
  buttonCheckBox,
  checkbox,
  loginScreenPasswordLink,
  forgotPasswordSuccessText,
};
