import { Box, TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import React from "react";

export default function DateField({ field }) {
  return (
    <Box>
      <DesktopDatePicker
        label={field.label}
        inputFormat="MM/DD/YYYY"
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{ width: "100%" }}
            required={field.properties.isRequired}
            disabled
          />
        )}
        disableOpenPicker
      />
    </Box>
  );
}
