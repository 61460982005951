const guardianRelationshipGrid = {
  py: 0,
  px: 8,
  justifyContent: "space-between",
  alignItems: "center",
};

const formGrid = {
  py: 5,
  px: 8,
  justifyContent: "space-between",
  alignItems: "flex-start",
};

const guardianGrid = {
  py: 5,
  px: 8,
};

const guardianActions = {
  display: "flex",
  justifyContent: "flex-end",
};

const imageContainer = {
  margin: "25px 0 25px 0",
  justifyContent: "flex-end",
};

const boxContainer = {
  position: "relative",
};

const accountProfileImage = {
  maxHeight: "356px",
  width: "274px",
  overflow: "hidden",
};

const accountProfileImageButton = {
  margin: "0",
  position: "absolute",
  top: "85%",
  left: "50%",
  msTransform: "translate(-50%, -50%)",
  transform: "translate(-50%, -50%)",
  color: "white",
  border: "1px solid white",
  textTransform: "capitalize",
  "&:hover": {
    border: "1px solid white",
  },
};

const notesTextArea = {
  padding: "24px 12px 24px 12px",
  width: "100%",
  color: "black",
  font: "inherit",
  fontSize: 17,
  border: "1px solid black",
  borderRadius: 5,
};

const guardianCheckCircle = (theme) => ({
  color: theme.palette.text.lightBlue,
  transform: "scale(1.5)",
});

const guardianNotification = {
  fontSize: "17px",
  alignItems: "center",
};

const guardianCircle = (theme) => ({
  position: "relative",
  background: theme.palette.text.lightBlue,
  borderRadius: "50%",
  width: 35,
  height: 35,
  cursor: "pointer",
});

const guardianCheckmark = (checked) => (theme) => ({
  position: "absolute",
  transform: "rotate(45deg) translate(-50%, -50%)",
  left: "35%",
  top: "45%",
  height: 12,
  width: 7,
  borderBottom: checked ? `2.5px solid ${theme.palette.text.strongBlue}` : "",
  borderRight: checked ? `2px solid ${theme.palette.text.strongBlue}` : "",
});

const guardianNotificationsGrid = {
  width: "42px",
  textAlign: "center",
  mr: "7px",
};

const guardianNotificationsText = {
  alignItems: "center",
};

const selectLabel = {
  backgroundColor: "white",
};

const guardianSearchButton = {
  width: "112px",
  height: "40px",
  backgroundColor: "#5077A4",
  borderRadius: "6px",
  color: "#FFFFFF",
  fontSize: "14px",
  fontWeight: "400",
  textTransform: "none",
  "&:not(:last-of-type)": {
    mr: "10px",
  },
  "&:hover": {
    backgroundColor: "#5981b0",
  },
  "&:disabled": {
    color: "#FFFFFF",
  },
};

const guardianSelectButton = {
  ...guardianSearchButton,
  width: "43px",
  "&:disabled": {
    color: "#FFFFFF",
  },
};

const disabledRow = {
  backgroundColor: "lightgray",
  cursor: "not-allowed",
};

const guardianTable = {
  paddingLeft: "64px",
  paddingRight: "64px",
};

const guardianCreateContainer = {
  alignItems: "center",
  justifyContent: "center",
  pt: "30px",
  pb: "30px",
};

const guardianCreateButton = {
  ...guardianSearchButton,
  width: "207px",
};

const boxText = {
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: "-0.5px",
  color: "#000000",
};

const guardianNotificationContainer = {
  width: "100%",

  "& .eventOption": {
    mt: "24px",
  },

  "& .optionsTitle": {
    textTransform: "uppercase",
    fontWeight: "bold",
  },

  "& .optionsHeaders": {
    "& p": {
      textTransform: "uppercase",
      fontWeight: "bold",
      fontSize: "12px",
      lineHeight: "24px",
    },
  },

  "& .eventCheckmarkOption": {
    ml: "-6px",

    "& .MuiFormControlLabel-label": {
      fontSize: "14px",
    },
  },

  "& .emailTextOptions": {
    "& label": {
      margin: "0 auto",
    },
  },
};

const guardianNotificationTitle = {
  fontSize: "28px",
  mt: "8px",
};

export {
  formGrid,
  guardianGrid,
  guardianActions,
  guardianRelationshipGrid,
  imageContainer,
  boxContainer,
  accountProfileImage,
  accountProfileImageButton,
  notesTextArea,
  guardianCheckCircle,
  guardianNotification,
  guardianCircle,
  guardianCheckmark,
  guardianNotificationsGrid,
  guardianNotificationsText,
  selectLabel,
  guardianSearchButton,
  guardianSelectButton,
  guardianCreateButton,
  guardianTable,
  guardianCreateContainer,
  boxText,
  guardianNotificationContainer,
  guardianNotificationTitle,
  disabledRow,
};
