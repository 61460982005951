const classSchedulesContainer = (isActive) => ({
  color: isActive ? "black !important" : "gray !important",
  display: "flex",
  alignItems: "center",
  "&:not(:last-of-type)": {
    mr: "25px",
  },
});

const classSchedulesSelect = (isActive) => ({
  width: "180px",
  "& fieldset": { borderColor: isActive ? "black" : "gray" },
});

const classSchedulesLabel = (isActive) => ({
  backgroundColor: "white",
  color: isActive ? "black" : "gray",
});

const classSchedulesCheckbox = {
  position: "relative",
  borderRadius: "50%",
  width: 45,
  height: 45,
  cursor: "pointer",
  border: "1px solid",
  mr: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export {
  classSchedulesSelect,
  classSchedulesContainer,
  classSchedulesCheckbox,
  classSchedulesLabel,
};
