const messagesRow = {
  p: "15px",
  color: "black",
  "&:hover": {
    backgroundColor: "#0000000",
    cursor: "pointer",
  },
};

const messagesAttachIcon = {
  transform: "rotate(45deg)",
};

const messagesRowTimestamp = {
  fontSize: "11px",
  color: "#336898",
};

const messagesRowDescription = {
  my: "auto",
};

const inboxRowCount = {
  backgroundColor: "black",
  ml: "50px ",
  fontSize: "16px",
  width: "35px",
  height: "35px",
};

const messagesRowName = {
  fontWeight: 500,
  lineHeight: "20px",
};

const conversationInner = {
  width: "1085px",
};

const conversationContainer = {
  color: "black",
};

const conversationTitle = {
  fontSize: "22px",
};

const conversationAttachment = {
  ml: "10px",
  color: "#5077A4",
  maxWidth: "250px",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
  display: "inline-block",
  verticalAlign: "middle",
  cursor: "pointer",
};

const conversationSenderBox = {
  backgroundColor: "#E7F3FF",
  borderRadius: "12px",
  mb: "15px",
  maxWidth: "870px",
  width: "max-content",
  ml: "15px",
};

const conversationAttachIcon = {
  color: "#79747E",
  transform: "rotate(45deg)",
};

const conversationBoxInner = {
  p: "10px",
  height: "100%",
};

const conversationRecipientBox = {
  ...conversationSenderBox,
  backgroundColor: "#F2F2F2",
  ml: "15px",
  position: "relative",
  "&:before": {
    zIndex: -1,
    content: '""',
    display: "block",
    position: "absolute",
    top: "60px",
    left: "-5px",
    width: "20px",
    height: "20px",
    bgcolor: "#F2F2F2",
    transform: "translateY(-50%) rotate(45deg)",
  },
};

const conversationSenderMessageContent = {
  mt: "10px",
  fontSize: "14px",
  "& a": {
    color: "#7eadda",
  },
};

const conversationSenderTimeStamp = {
  fontSize: "12px",
  color: "#5077A4",
  mt: "5px",
};

const conversationSenderFileContainer = {
  borderTop: "1px solid white",
  pt: "5px",
  mt: "5px",
};

const conversationSenderFileContainerBorderless = {
  border: "none",
  m: 0,
  p: 0,
};

const backButton = {
  color: "#2196F3",
  marginTop: "20px",
  marginLeft: "-30px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "transparent",
  },
};

const backArrow = {
  paddingRight: "5px",
};

const conversationDeletedMessagesContainer = {
  backgroundColor: "#F2F2F2",
  p: "10px",
  borderRadius: "6px",
  mt: "60px",
};

export {
  messagesRow,
  messagesAttachIcon,
  messagesRowTimestamp,
  messagesRowDescription,
  inboxRowCount,
  messagesRowName,
  conversationInner,
  conversationContainer,
  conversationTitle,
  conversationAttachment,
  conversationSenderBox,
  conversationAttachIcon,
  conversationBoxInner,
  conversationRecipientBox,
  conversationSenderMessageContent,
  conversationSenderTimeStamp,
  conversationSenderFileContainer,
  conversationSenderFileContainerBorderless,
  backButton,
  backArrow,
  conversationDeletedMessagesContainer,
};
