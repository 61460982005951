import { font16, font20, formButtons, formContainer } from "../../sharedStyles";

const explore = {
  font16,
  color: "#7D5260",
  alignSelf: "center",
  marginLeft: 2,
};

const lampIcon = { fontSize: 42, color: "#7D5260" };
const form = { ...formContainer, paddingTop: 2 };
const schoolYearField = { width: "15rem" };
const checkbox = { float: "right" };
const buttons = { ...formButtons, fontWeight: "300" };
const formDivider = { borderBottom: "1px solid #BFC7CA", paddingBottom: 2 };
const accordionStyle = {
  "&:before": {
    backgroundColor: "transparent !important",
  },
};
const accordionSummaryStyle = {
  paddingLeft: 0,
};
const iconSize = { fontSize: font20 };
const alignIcon = { alignSelf: "center" };

export {
  explore,
  lampIcon,
  form,
  schoolYearField,
  checkbox,
  buttons,
  formDivider,
  accordionStyle,
  accordionSummaryStyle,
  iconSize,
  alignIcon,
};
