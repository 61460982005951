import AdmissionProcessStage from "./AdmissionProcessStage";

export default class AdmissionProcess {
  constructor(_process) {
    const process = _process || {};
    this.id = process.id || null;
    this.name = process.name || "New Process";
    this.description = process.description || "";
    this.stages = process.stages || [new AdmissionProcessStage()];
    if (process.admission_process_stages) {
      this.stages = [];
      process.admission_process_stages.forEach((stage) => {
        this.stages.push(new AdmissionProcessStage(stage));
      });
    }
  }

  serialize() {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      admission_process_stages_attributes: this.stages.map((stage) =>
        new AdmissionProcessStage(stage).serialize()
      ),
    };
  }
}
