import React from "react";
import { Route } from "react-router-dom";
import Subject from "../views/Subject";
import AssignmentsList from "../components/Subject/Assignments/AssignmentsList";
import AssignmentTable from "../components/Subject/Assignments/AssignmentTable";
import NewGradebook from "../components/Subject/NewGradebook/NewGradebook";
import Gradebook from "../components/Subject/Gradebook/Gradebook";
import Students from "../components/Subject/Students/Students";
import TermGrade from "../components/Subject/TermGrade/TermGrade";
import TermGradeKlass from "../components/Subject/TermGrade/TermGradeKlass";
import TermGradeSkills from "../components/Subject/TermGrade/TermGradeSkills";
import KlassStudentDetail from "../components/Subject/Students/KlassStudentDetail/KlassStudentDetail";

export default [
  <Route
    path="/school/:school_id/subjects/:subject_id"
    element={<Subject />}
    key={0}
  >
    <Route
      path="/school/:school_id/subjects/:subject_id/students"
      element={<Students />}
      key={1}
    />
    <Route
      path="/school/:school_id/subjects/:subject_id/classes/:klass_id/students/:student_id"
      element={<KlassStudentDetail />}
      key={2}
    />
    <Route
      path="/school/:school_id/subjects/:subject_id/gradebook"
      element={<Gradebook />}
      key={3}
    />
    <Route
      path="/school/:school_id/subjects/:subject_id/new-gradebook"
      element={<NewGradebook />}
      key={4}
    />
    <Route
      path="/school/:school_id/subjects/:subject_id/assignments"
      element={<AssignmentsList />}
      key={5}
    />
    <Route
      path="/school/:school_id/subjects/:subject_id/class/:klass_id/assignments/:assignment_id"
      element={<AssignmentTable />}
      key={6}
    />
    <Route
      path="/school/:school_id/subjects/:subject_id/term-grade"
      element={<TermGrade />}
      key={7}
    >
      <Route
        path="/school/:school_id/subjects/:subject_id/term-grade/class-grade"
        element={<TermGradeKlass />}
        key={8}
      />
      <Route
        path="/school/:school_id/subjects/:subject_id/term-grade/skills"
        element={<TermGradeSkills />}
        key={9}
      />
    </Route>
  </Route>,
];
