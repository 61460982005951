/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from "react";
import {
  ButtonBase,
  Divider,
  FormControl,
  Grid,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import { Add, Clear, Queue } from "@mui/icons-material";
import { useFormContext } from "react-hook-form";
import Condition from "./Condition";
import {
  ConditionNodeType,
  LogicalOperator,
} from "../../../Models/Fields/ConditionNodes/constants";
import ConditionNode from "../../../Models/Fields/ConditionNodes/ConditionNode";

function Group({ conditionNode, parent, nodeIndex, control, updateParent }) {
  const { register, setValue, unregister } = useFormContext();
  const [conditionNodeState, setConditionState] = useState(conditionNode);

  const onAddCondition = () => {
    const newNode = new ConditionNode(ConditionNodeType.NODE);
    const updatedNodes = [...parent.conditionNodes];
    updatedNodes.splice(nodeIndex + 1, 0, newNode);
    updateParent({ ...parent, conditionNodes: updatedNodes });
  };

  const onRemoveCondition = () => {
    const updatedParent = { ...parent };
    updatedParent.conditionNodes.splice(nodeIndex, 1);
    unregister(`${control}.conditionNodes[${nodeIndex}]`);
    updateParent(updatedParent);
  };

  useEffect(() => {
    setValue(`${control}.type`, conditionNode.type);
  }, []);

  const onAddNestedCondition = () => {
    const newNode = new ConditionNode(ConditionNodeType.NODE);
    const updatedNodes = [...conditionNodeState.conditionNodes, newNode];
    setConditionState({
      ...conditionNodeState,
      conditionNodes: updatedNodes,
    });
  };

  const onAddGroup = () => {
    const updatedConditionNodes = [
      ...conditionNodeState.conditionNodes,
      new ConditionNode(ConditionNodeType.GROUP),
    ];
    conditionNodeState.conditionNodes = updatedConditionNodes;
    setConditionState({
      ...conditionNodeState,
      conditionNodes: updatedConditionNodes,
    });
  };

  const onRemoveGroup = () => {
    if (parent.conditionNodes) {
      const updatedConditionNodes = [...parent.conditionNodes];
      updatedConditionNodes.splice(nodeIndex, 1);
      parent.conditionNodes = updatedConditionNodes;
      updateParent({ ...parent, conditionNodes: updatedConditionNodes });
    } else {
      const updatedNodes = [...parent];
      updatedNodes.splice(nodeIndex, 1);
      updateParent(updatedNodes);
    }
  };

  const getControlName = (index) => {
    if (conditionNode.conditionNodes)
      return `${control}.conditionNodes[${index}]`;
    return control;
  };

  return (
    <>
      {!!nodeIndex && (
        <Grid container justifyContent="center">
          <Grid item container md={12} justifyContent="center">
            <Divider
              orientation="vertical"
              flexItem
              sx={{ height: "20px", width: "1px" }}
            />
          </Grid>
          <Grid item container md={12} justifyContent="center">
            <FormControl>
              <Select
                defaultValue={conditionNode.logicalOperator}
                {...register(`${control}.logicalOperator`)}
              >
                <MenuItem key="operator-and" value={LogicalOperator.AND}>
                  <Typography>AND</Typography>
                </MenuItem>
                <MenuItem key="operator-or" value={LogicalOperator.OR}>
                  <Typography>OR</Typography>
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item container md={12} justifyContent="center">
            <Divider
              orientation="vertical"
              flexItem
              sx={{ height: "20px", width: "1px" }}
            />
          </Grid>
        </Grid>
      )}
      <Grid
        className="group"
        sx={{
          border:
            conditionNode.type === ConditionNodeType.GROUP
              ? "1px solid black"
              : "1px solid rgba(0, 0, 0, 0.12)",
          p: 1,
          borderRadius: 1,
        }}
      >
        {conditionNodeState.type === ConditionNodeType.NODE && (
          <>
            <Condition
              conditionControl={control}
              expression={conditionNodeState.expression}
            />
            <Grid container justifyContent="end" mt={2}>
              <ButtonBase onClick={onAddCondition}>
                <Add />
              </ButtonBase>
              <ButtonBase onClick={onRemoveCondition}>
                <Clear />
              </ButtonBase>
            </Grid>
          </>
        )}
        {/* Render nested condition nodes */}
        {conditionNodeState.type === ConditionNodeType.GROUP &&
          conditionNodeState.conditionNodes.length > 0 &&
          conditionNodeState.conditionNodes.map((node, index) => (
            <Group
              key={node.id}
              conditionNode={node}
              parent={conditionNodeState}
              nodeIndex={index}
              control={getControlName(index)}
              updateParent={setConditionState}
            />
          ))}
        {conditionNode.type === ConditionNodeType.GROUP && (
          <Grid container justifyContent="end" mt={2}>
            <ButtonBase onClick={onAddNestedCondition}>
              <Add />
            </ButtonBase>
            <ButtonBase onClick={onAddGroup}>
              <Queue />
            </ButtonBase>
            <ButtonBase onClick={onRemoveGroup}>
              <Clear />
            </ButtonBase>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default Group;
