import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Avatar,
  Box,
  CircularProgress,
  Container,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";
import { Link, useParams, useSearchParams } from "react-router-dom";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { fetchGuardianKidMessages } from "../../../../service/guardiansService";
import {
  loader,
  mx25,
  paginationContent,
  textCenter,
} from "../../../sharedStyles";
import {
  messagesAttachIcon,
  messagesRow,
  messagesRowDescription,
  messagesRowTimestamp,
  inboxRowCount,
  messagesRowName,
} from "./Messages.styles";
import { displayInline } from "../Home/Home.styles";
import profilePictureIcon from "../../../../utils/profilePictureIcon";
import { longShortDateTime } from "../../../../utils/dateTimePresets";

export default function GuardianKidsInfoMessages() {
  const { t, i18n } = useTranslation("messages");
  const params = useParams();
  const guardianId = params.id;
  const kidId = params.kid_id;
  const [kidMessages, setKidMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(searchParams.get("page") || 1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    (async () => {
      if (kidId && page) {
        try {
          setLoading(true);
          const response = await fetchGuardianKidMessages({
            params: {
              guardianId,
              kidId,
              count: 10,
              page,
            },
          });
          if (response.data) {
            setLoading(false);
            setKidMessages(response.data);

            const lastPage = response.pagination.total_pages;
            setTotalPages(lastPage);
          }
        } catch (e) {
          // handle catch
        }
      }
    })();
  }, [kidId, page]);

  const handleChangePage = (event, value) => {
    setPage(value);
    searchParams.set("page", value);
    setSearchParams(searchParams);
  };

  function truncate(str) {
    const newStr = str.length > 125 ? `${str.substring(0, 125)}...` : str;
    return newStr.replace(/(<([^>]+)>)/gi, "").replaceAll("&nbsp;", "");
  }

  function displayName(sender) {
    return `${sender.first_name} ${sender.last_name}`;
  }

  const conversationKey = (message) =>
    message.conversation_key || message.original_message_conversation_key;

  if (loading) {
    return <CircularProgress sx={loader} size={100} />;
  }

  return (
    <Container maxWidth={false} variant="header">
      <Container maxWidth="xl">
        {kidMessages.length > 0 ? (
          <>
            <Box>
              {kidMessages.map((message) => (
                <Box sx={messagesRow} key={message.id}>
                  <Link
                    to={`/guardian/${guardianId}/students/${kidId}/messages/${conversationKey(
                      message
                    )}`}
                  >
                    <Stack direction="row" justifyContent="space-between">
                      <Stack direction="row">
                        <img
                          alt="profile_image"
                          src={profilePictureIcon(
                            message?.recent_message?.sender
                          )}
                          style={{
                            borderRadius: "100px",
                            width: "90px",
                            height: "90px",
                            objectFit: "cover",
                          }}
                        />
                        <Box sx={[mx25, displayInline]}>
                          <Typography sx={messagesRowName}>
                            {displayName(message.recent_message?.sender)}
                          </Typography>
                          <Typography sx={messagesRowDescription}>
                            {truncate(message.recent_message.description)}
                          </Typography>
                          <Typography sx={messagesRowTimestamp}>
                            {longShortDateTime(
                              message.recent_message.created_at,
                              { language: i18n.language }
                            )}
                          </Typography>
                        </Box>
                      </Stack>
                      <Stack direction="row" alignItems="center">
                        {message.has_attachments_in_thread && (
                          <AttachFileIcon sx={messagesAttachIcon} />
                        )}
                        <Avatar sx={inboxRowCount}>
                          {message.replies_count + 1}
                        </Avatar>
                      </Stack>
                    </Stack>
                  </Link>
                </Box>
              ))}
            </Box>

            <Pagination
              count={totalPages}
              page={parseInt(page, 10)}
              onChange={handleChangePage}
              sx={paginationContent}
            />
          </>
        ) : (
          <Box sx={textCenter}>
            <Typography>{t("noMessages")}</Typography>
          </Box>
        )}
      </Container>
    </Container>
  );
}
