import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import {
  Box,
  TextField,
  Typography,
  Container,
  Stack,
  Button,
  CircularProgress,
  Chip,
  Grid,
} from "@mui/material";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import {
  conversationInner,
  conversationTitle,
  conversationContainer,
  conversationReplyInput,
  conversationReplyAttachButton,
  conversationReplySendButton,
  conversationAttachIcon,
  conversationBoxReply,
  conversationDeletedMessagesContainer,
  attachmentsContainer,
  backButton,
} from "./Conversation.styles";
import { mt25, font14, loader, my5 } from "../../sharedStyles";
import messagesService from "../../../service/messagesService";
import SnackBarNotification from "../../SnackBarNotification";
import GuardianConversationBox from "./ConversationBox";
import { gridContainer, gridItemMessages } from "./Messages.styles";
import { backArrow } from "../Kids/Messages/Messages.styles";

export default function GuardianConversationMessages() {
  const { t } = useTranslation("messages");
  const { register, setValue, handleSubmit } = useForm();
  const params = useParams();
  const conversationKey = params.conversation_key;
  const [loading, setLoading] = useState(true);
  const [originalMessage, setOriginalMessage] = useState(null);
  const [conversationMessages, setConversationMessages] = useState([]);
  const [deletedMessages, setDeletedMessages] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [snackBarOpen, setSnackBarOpen] = useState({
    open: false,
    message: null,
  });
  const navigate = useNavigate();
  const guardianId = params.id;

  function SizeException(message) {
    setSnackBarOpen({
      message,
      open: true,
      severity: "error",
    });
    return {
      message,
      error: "sizeException",
    };
  }

  const maxFileSize = 20000000;

  const handleUnreadMessages = async (unreadMessages) => {
    await messagesService.readMessages({
      message_ids: unreadMessages,
      recipient_type: "Guardian",
    });
  };

  const getConversation = async () => {
    setLoading(true);
    const response = await messagesService.fetchConversation(conversationKey);

    if (response) {
      const messages = [
        response.data.original_message,
        ...response.data.replies,
      ];

      const unreadMessagesIds = messages
        .filter((message) => message.read_at === null)
        .map((message) => message.id);

      setOriginalMessage(response.data.original_message);
      setConversationMessages(
        messages.filter((message) => !message.is_deleted)
      );
      setDeletedMessages(messages.filter((message) => message.is_deleted));

      if (unreadMessagesIds.length > 0) {
        await handleUnreadMessages(unreadMessagesIds);
      }

      setLoading(false);
    }
  };

  const handleReply = async (data) => {
    const formData = new FormData();

    const formParams = {
      conversation_key: conversationKey,
      description: data.reply,
      sender_type: "Guardian",
    };

    Object.keys(formParams).map((keyName) =>
      formData.append(keyName, formParams[keyName])
    );

    Array.prototype.forEach.call(uploadedFiles, (file, index) => {
      if (uploadedFiles.length > 10)
        throw new SizeException("Maximum of 10 files are permitted.");
      if (file.size > maxFileSize)
        throw new SizeException(`The file ${file.name} is bigger than 20Mb.`);
      formData.append(`attachments[]`, uploadedFiles[index]);
    });

    const response = await messagesService.createReply(formData);

    if (response.data) {
      getConversation();
      setValue("reply", null);
      setUploadedFiles([]);
    }
  };

  const handleFileUploads = (e) => {
    const files = [...uploadedFiles];
    Array.prototype.forEach.call(e.target.files, (file, index) => {
      const fileWithId = file;
      const fileId =
        uploadedFiles.length > 0 ? index + uploadedFiles.at(-1).id + 1 : index;
      fileWithId.id = fileId;
      files.push(fileWithId);
    });

    try {
      if (files) {
        setUploadedFiles(files);
      }
    } catch (err) {
      if (err.error === "sizeException") {
        setSnackBarOpen({
          message: err.message,
          open: true,
          severity: "error",
        });
      }
    }
  };

  const handleDeleteFile = (objId) => {
    setUploadedFiles(uploadedFiles.filter((file) => file.id !== objId));
  };

  useEffect(() => {
    getConversation();
  }, []);

  const hasConversation = conversationMessages.length > 0 && !loading;

  if (loading) {
    return <CircularProgress sx={loader} size={100} />;
  }

  return (
    <>
      <Grid container spacing={2} sx={gridContainer}>
        <Grid item md={10} xs={10} sx={gridItemMessages}>
          <Box>{t("messages")}</Box>
        </Grid>
      </Grid>

      <Button
        onClick={() => navigate(`/guardian/${guardianId}/messages`)}
        sx={backButton}
      >
        <ArrowBackRoundedIcon size="small" sx={backArrow} />
        {t("viewAllMessages")}
      </Button>

      <Container maxWidth={false} variant="header" sx={conversationContainer}>
        <Container sx={conversationInner}>
          <Typography sx={conversationTitle}>
            {hasConversation && originalMessage?.subject}
          </Typography>

          <Box sx={mt25}>
            {hasConversation ? (
              conversationMessages.map((message) => (
                <GuardianConversationBox message={message} key={message.id} />
              ))
            ) : (
              <Typography align="center">
                {t("conversationNotExist")}
              </Typography>
            )}
          </Box>

          {originalMessage.no_reply ||
            (hasConversation && (
              <>
                <Box sx={conversationBoxReply}>
                  <form onSubmit={handleSubmit(handleReply)}>
                    <TextField
                      variant="standard"
                      sx={conversationReplyInput}
                      multiline
                      focused
                      rows={5}
                      placeholder={t("reply")}
                      {...register("reply", { required: true })}
                    />
                    <Stack direction="row" justifyContent="space-between">
                      <Stack direction="row" alignItems="center">
                        <Button
                          component="label"
                          sx={conversationReplyAttachButton}
                          startIcon={
                            <AttachFileIcon sx={conversationAttachIcon} />
                          }
                          disableRipple
                        >
                          {t("addFile")}
                          <input
                            hidden
                            accept=".jpeg, .jpg, .pdf, .png, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .zip"
                            type="file"
                            multiple
                            onChange={handleFileUploads}
                          />
                        </Button>
                      </Stack>
                      <Button type="submit" sx={conversationReplySendButton}>
                        {t("send")}
                      </Button>
                    </Stack>
                  </form>
                </Box>

                <Box sx={attachmentsContainer}>
                  {uploadedFiles &&
                    Array.from(uploadedFiles?.entries()).map((file) => (
                      <Box sx={my5} key={file[0]}>
                        <Chip
                          label={file[1].name}
                          onDelete={() => handleDeleteFile(file[1].id)}
                        />
                      </Box>
                    ))}
                </Box>
              </>
            ))}

          {deletedMessages.length > 0 && (
            <Box sx={conversationDeletedMessagesContainer}>
              <Typography sx={font14}>
                {t("deletedMessages", { count: deletedMessages.length })}
              </Typography>
            </Box>
          )}
        </Container>
      </Container>

      <SnackBarNotification
        message={snackBarOpen.message}
        open={snackBarOpen.open}
        severity={snackBarOpen.severity}
        handleClose={() => setSnackBarOpen(false)}
      />
    </>
  );
}
