import { Box, Checkbox, FormControl, FormControlLabel } from "@mui/material";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";

export default function IsVisible({ fieldProp }) {
  const { setValue } = useFormContext();
  const [isVisible, setIsVisible] = useState(fieldProp.properties.isVisible);
  return (
    <Box ml={2} mb={2}>
      <FormControl fullWidth>
        <FormControlLabel
          control={
            <Checkbox
              checked={isVisible}
              onChange={(e) => {
                setValue("properties.isVisible", e.target.checked, {
                  shouldDirty: true,
                });
                setIsVisible(e.target.checked);
              }}
            />
          }
          label="Visible in application"
          defaultChecked={false}
        />
      </FormControl>
    </Box>
  );
}
