const attachmentsContainer = {
  mt: "45px",
};

const attachmentsInfoContainer = {
  width: "450px",
  mx: "auto",
  textAlign: "center",
  mt: "20px",
  "& p": {
    fontSize: "14px",
  },
};

const attachmentBtnLabel = {
  fontSize: "14px",
  color: "#5077A4",
};

export { attachmentsContainer, attachmentsInfoContainer, attachmentBtnLabel };
