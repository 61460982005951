import React, { useEffect, useState } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Box,
} from "@mui/material";
import { tableStyles } from "./StudentProgress.style";

export default function KlassComments({ klasses }) {
  const [filteredKlasses, seFilteredKlasses] = useState([]);
  useEffect(() => {
    seFilteredKlasses(
      klasses.filter(
        (klass) => !!klass.comments || klass.missing_assignments.length > 0
      )
    );
  }, []);
  return (
    <Table sx={tableStyles}>
      <TableHead>
        <TableRow>
          <TableCell fontSize="16px" color="black" fontWeight={700}>
            Comments
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {filteredKlasses.map((klass) => (
          <TableRow>
            <TableCell>
              <Typography
                borderBottom="1px solid #8F8F8F"
                color="black"
                fontWeight={700}
                fontSize={14}
                pb="8px"
              >
                {klass.name}
              </Typography>
              <Box sx={{ ml: "20px" }}>
                <Typography fontStyle="italic" paddingY={1} fontSize="0.9rem">
                  {klass.name_title} {klass.first_name} {klass.last_name} :
                </Typography>
                <Typography fontSize="12px">{klass.comments}</Typography>
                <Typography fontSize="12px" mt={2}>
                  Missing Assignments:
                </Typography>
                {klass.missing_assignments &&
                klass.missing_assignments.length > 0 ? (
                  klass.missing_assignments.map((assignment) => (
                    <Grid container key={`${klass.id}_${assignment.id}`}>
                      <Grid item xs={6}>
                        <Typography fontSize="12px">
                          {assignment.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography fontSize="12px">
                          {assignment.due_date
                            ? new Date(assignment.due_date).toLocaleString(
                                "en-US",
                                {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                }
                              )
                            : ""}{" "}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))
                ) : (
                  <Typography fontSize="12px">
                    No missing assignments
                  </Typography>
                )}
              </Box>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
