import React from "react";
import { CheckCircle, PanoramaFishEye } from "@mui/icons-material";
import { styled } from "@mui/material";

const useQontoStepIconStyles = () =>
  styled({
    root: {
      color: "#eaeaf0",
      display: "flex",
      height: 22,
      alignItems: "center",
    },
    active: {
      color: "#784af4",
    },
    circle: {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "white",
      border: "1px solid grey",
    },
    completed: {
      color: "#784af4",
      zIndex: 1,
      fontSize: 18,
    },
  });

export default function QontoStepIcon({ checked }) {
  const classes = useQontoStepIconStyles();

  return checked ? (
    <CheckCircle sx={{ color: "#2196F3" }} className={classes.completed} />
  ) : (
    <PanoramaFishEye
      sx={{ color: "rgba(0, 0, 0, 0.15)" }}
      className={classes.circle}
    />
  );
}
