import * as React from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from "@mui/material";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import { useContext } from "react";
import {
  paddedMedicalCell,
  titleStyle,
  authorNameCell,
  authorNameHead,
  eventTablePinnedRow,
  eventTableStrike,
  eventTablePinned,
  eventTableBodyCell,
  pinnedRow,
} from "./MedicalEvents.style";
import { my30 } from "../../sharedStyles";
import { PermissionsContext } from "../../../context/PermissionsContext";
import APP_PERMISSIONS from "../../../utils/constants/permissions";
import PERMISSION_TYPES from "../../../utils/constants/permission_types";

const medicalTableHeader = { "td, th": { border: 0 } };
const row = {
  "&:not(:last-child) td, &:not(:last-of-type) th": {
    borderBottom: "1px solid #D3D4D5 !important",
  },
  cursor: "pointer",
};

export default function MedicalEvents({ medicalEvents, editMedicalEvent }) {
  const { hasPermission } = useContext(PermissionsContext);

  const managePermission = hasPermission(
    APP_PERMISSIONS.STUDENT_MEDICAL,
    PERMISSION_TYPES.MANAGE
  );

  const pinnedEvents = medicalEvents.filter(
    (event) => event.is_pinned === true
  );
  return (
    <Box sx={my30} width="100%">
      <Typography variant="h2" sx={titleStyle}>
        Medical Events
      </Typography>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={medicalTableHeader}>
              <TableCell />
              <TableCell sx={authorNameHead}>Author</TableCell>
              <TableCell sx={authorNameHead}>Date Added</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {pinnedEvents.length > 0 && (
              <TableRow>
                <TableCell sx={eventTablePinnedRow} colSpan={6}>
                  <h2 style={eventTableStrike}>
                    <span style={eventTablePinned}>PINNED</span>
                  </h2>
                </TableCell>
              </TableRow>
            )}
            {medicalEvents.map((event) => (
              <TableRow
                key={event.id}
                hover
                role="checkbox"
                sx={
                  pinnedEvents[pinnedEvents.length - 1] === event &&
                  event.is_pinned
                    ? pinnedRow(managePermission)
                    : row
                }
                onClick={() => {
                  if (managePermission) editMedicalEvent(event.id);
                }}
              >
                <TableCell
                  padding="none"
                  align="left"
                  sx={paddedMedicalCell}
                  style={eventTableBodyCell(event.is_pinned)}
                >
                  <p>
                    <b>COMMENT:</b> {event.comment}
                  </p>
                  <p>
                    <b>ACTION NEEDED/TAKEN:</b> {event.action}
                  </p>
                </TableCell>
                <TableCell
                  sx={authorNameCell}
                  style={eventTableBodyCell(event.is_pinned)}
                >
                  {event.created_by.first_name || event.created_by.last_name
                    ? `${event.created_by.first_name} ${event.created_by.last_name}`
                    : "-"}
                </TableCell>
                <TableCell
                  sx={authorNameCell}
                  style={eventTableBodyCell(event.is_pinned)}
                >
                  {event.created_at
                    ? new Date(event.created_at).toLocaleString("en-US", {
                        month: "long",
                        day: "numeric",
                        year: "numeric",
                      })
                    : ""}
                </TableCell>
                <TableCell
                  width="5%"
                  style={eventTableBodyCell(event.is_pinned)}
                >
                  {event.is_pinned && (
                    <PushPinOutlinedIcon sx={{ fontSize: "2.5rem" }} />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
