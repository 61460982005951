import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import {
  useSearchParams,
  Link as RouterLink,
  useParams,
} from "react-router-dom";
import TextFormatIcon from "@mui/icons-material/TextFormat";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Clear, Search } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { useForm } from "react-hook-form";
import {
  buttonsTabsContainer,
  hidden,
  listCell,
  listHead,
  listHeadName,
  listTable,
} from "./Registrations.styles";
import { mr10, py30 } from "../../sharedStyles";
import dateTimeFormats from "../../../utils/constants/dateTimeFormats";
import moment from "../../../utils/constants/momentConfig";
import schoolYearApplicationService from "../../../service/schoolYearApplicationService";
import { submitButton } from "../../../views/Registration.style";
import { PermissionsContext } from "../../../context/PermissionsContext";
import APP_PERMISSIONS from "../../../utils/constants/permissions";
import PERMISSION_TYPES from "../../../utils/constants/permission_types";

const CssSearchField = styled(TextField)({
  "& .MuiInput-root": {
    marginRight: "17px",
    "&:before": {
      borderBottom: "none",
    },
  },
});

export default function Registrations() {
  const [schoolYearApplications, setSchoolYearApplications] = useState([]);
  const [schoolYear, setSchoolYear] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [order, setOrder] = useState(searchParams.get("direction") || "desc");
  const [orderBy, setOrderBy] = useState(
    searchParams.get("sort") || "created_at"
  );
  const registrationLink = `/registration/token=${schoolYear?.registration_token}`;
  const { hasPermission } = useContext(PermissionsContext);
  const managePermission = hasPermission(
    APP_PERMISSIONS.ADMISSIONS,
    PERMISSION_TYPES.MANAGE
  );
  const params = useParams();
  const schoolId = params.school_id;

  const { register, handleSubmit, getValues, setValue } = useForm();
  const [query, setQuery] = useState(searchParams.get("query") || "");
  const [showClearButton, setShowClearButton] = useState(
    searchParams.get("query") || false
  );

  const getRegistrations = async () => {
    const response = await schoolYearApplicationService.fetchAll({
      params: {
        school_year_id: searchParams.get("school_year"),
        order,
        order_by: orderBy,
        query,
      },
    });
    if (response.data) {
      setSchoolYearApplications(response.data.school_year_applications);
      setSchoolYear(response.data.school_year);
    }
  };
  const renderSortChevron = (label) => {
    const noColumnSelected = searchParams.get("sort") === null;

    if ((noColumnSelected && label === "created_at") || label === orderBy) {
      return order === "asc" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
    }
    return <ArrowDropUpIcon sx={hidden} />;
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    const direction = isAsc ? "desc" : "asc";
    setOrder(direction);
    setOrderBy(property);

    searchParams.set("sort", property);
    searchParams.set("direction", direction);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (searchParams.get("school_year")) {
      getRegistrations();
      setOrder(searchParams.get("direction") || "desc");
      setOrderBy(searchParams.get("sort") || "created_at");
    }
  }, [order, orderBy, searchParams.get("school_year"), query]);

  const handleSearch = (data) => {
    searchParams.set("query", data.search || "");
    setQuery(data.search || "");
    setSearchParams(searchParams);
  };

  const handleQuickFindChange = (event) => {
    if (event.target.value) setShowClearButton(true);
    else setShowClearButton(false);
  };

  const cancelButtonStyle = {
    visibility: showClearButton ? "inherit" : "hidden",
  };

  const handleClickOnClearButton = () => {
    setValue("search", null);
    setShowClearButton(false);
    handleSearch(getValues());
  };

  return (
    <>
      {managePermission && (
        <Box sx={buttonsTabsContainer}>
          <form
            onSubmit={handleSubmit(handleSearch)}
            onChange={handleQuickFindChange}
            style={{ padding: "0 2rem" }}
          >
            <CssSearchField
              label="Quick Find"
              variant="standard"
              color="primary"
              fullWidth
              defaultValue={query || null}
              {...register("search")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {showClearButton && (
                      <IconButton
                        onClick={handleClickOnClearButton}
                        sx={cancelButtonStyle}
                      >
                        <Clear fontSize="large" />
                      </IconButton>
                    )}
                    {!showClearButton && (
                      <IconButton type="submit">
                        <Search fontSize="large" color="primary" />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </form>
          <Box mr={2}>
            <Button
              sx={submitButton}
              endIcon={<CopyAllIcon />}
              onClick={() => {
                navigator.clipboard.writeText(
                  `${window.location.origin}${registrationLink}`
                );
              }}
            >
              Copy Registration Link
            </Button>
          </Box>
          <Button sx={submitButton} endIcon={<TextFormatIcon />}>
            <Link
              to={registrationLink}
              underline="none"
              component={RouterLink}
              sx={submitButton}
              target="_blank"
            >
              View Registration Form
            </Link>
          </Button>
        </Box>
      )}
      <Container maxWidth={false} variant="header">
        <Container maxWidth="lg">
          <Grid container>
            <Grid item sm={12}>
              {schoolYearApplications.length > 0 ? (
                <TableContainer sx={py30}>
                  <Table sx={listTable}>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={listHeadName(false, orderBy === "name")}
                          onClick={() => handleRequestSort("name")}
                        >
                          <Typography>
                            NAME {renderSortChevron("name")}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={listHead(false, orderBy === "applying_grade")}
                          onClick={() => handleRequestSort("applying_grade")}
                        >
                          <Typography sx={mr10}>
                            NEXT GRADE {renderSortChevron("applying_grade")}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={listHead(false, orderBy === "status")}
                          onClick={() => handleRequestSort("status")}
                        >
                          <Typography>
                            STATUS {renderSortChevron("status")}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={listHead(false, orderBy === "current_grade")}
                          onClick={() => handleRequestSort("current_grade")}
                        >
                          <Typography sx={mr10}>
                            CURRENT GRADE {renderSortChevron("current_grade")}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={listHead(false, orderBy === "parent_name")}
                          onClick={() => handleRequestSort("parent_name")}
                        >
                          <Typography sx={mr10}>
                            PARENT/GUARDIAN
                            {renderSortChevron("parent_name")}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={listHead(false, orderBy === "created_at")}
                          onClick={() => handleRequestSort("created_at")}
                        >
                          <Typography sx={mr10}>
                            SUBMISSION DATE
                            {renderSortChevron("created_at")}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {schoolYearApplications.map((application) => (
                        <TableRow hover key={application.id}>
                          <TableCell sx={listCell()}>
                            <Link
                              to={`/school/${schoolId}/admissions/registrations/${application.id}`}
                              underline="none"
                              component={RouterLink}
                            >
                              <Typography>
                                {`${application?.last_name}, ${application?.first_name}`}
                              </Typography>
                            </Link>
                          </TableCell>
                          <TableCell sx={listCell()}>
                            <Typography>
                              {application?.applying_grade}
                            </Typography>
                          </TableCell>
                          <TableCell sx={listCell()}>
                            <Typography>{application?.status}</Typography>
                          </TableCell>
                          <TableCell sx={listCell()}>
                            <Typography>
                              {application?.current_grade}
                            </Typography>
                          </TableCell>
                          <TableCell sx={listCell()}>
                            <Typography>
                              {`${application?.parent_first_name} ${application?.parent_last_name} / ${application?.parent_phone_number}`}
                            </Typography>
                          </TableCell>
                          <TableCell sx={listCell()}>
                            <Typography>
                              {moment(application?.created_at).format(
                                dateTimeFormats.MMDDYYYY
                              )}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography align="center">No registrations found.</Typography>
              )}
            </Grid>
          </Grid>
        </Container>
      </Container>
    </>
  );
}
