import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { fetchStudentClasses } from "../../../service/studentPortalService";
import {
  tableContainer,
  tableHead,
  tableHeadRow,
  tableBodyRow,
  gridItemTitle,
  gridItemTerm,
} from "./Classes.styles";
import { loader, pt30, textCenter } from "../../sharedStyles";
import termGradeLetter from "../../../utils/termGradeLetter";

export default function StudentPortalClasses() {
  const params = useParams();
  const studentId = params.id;
  const schoolId = params.school_id;
  const [currentTerm, setCurrentTerm] = useState(null);
  const [termGrades, setTermGrades] = useState([]);
  const [specialMarks, setSpecialMarks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [student] = useOutletContext();
  const setDisplay =
    ["TK", "K", "1", "2"].includes(student.grade_level) && "none";
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const response = await fetchStudentClasses(studentId, {
          params: {
            school_id: schoolId,
          },
        });

        if (response.data) {
          setTermGrades(response.data.term_grades);
          setCurrentTerm(response.data.current_term);
          setSpecialMarks(response.data.special_marks);
          setLoading(false);
        }
      } catch (e) {
        // handle catch
      }
    })();
  }, [schoolId]);

  const calculateMissingAssignments = (assignments) => {
    let count = 0;
    // eslint-disable-next-line array-callback-return
    assignments.map((assignment) => {
      count += assignment.assignment_students.filter(
        (as) => as.score === "/"
      ).length;
    });

    if (count === 0) {
      count = "";
    } else if (count === 1) {
      count = `${count} missing assignment`;
    } else {
      count = `${count} missing assignments`;
    }
    return count;
  };

  if (loading) {
    return <CircularProgress sx={loader} size={100} />;
  }

  if (!currentTerm) {
    return <Typography>No current term.</Typography>;
  }

  return (
    <>
      <Grid container>
        <Grid item md={6} xs={6}>
          <Typography sx={gridItemTitle}>My Classes</Typography>
        </Grid>
        <Grid item md={6} xs={6}>
          <Typography sx={gridItemTerm}>{currentTerm.name}</Typography>
        </Grid>
      </Grid>

      {termGrades.length > 0 ? (
        <TableContainer sx={tableContainer}>
          <Table aria-label="classes">
            <TableHead sx={tableHead}>
              <TableRow sx={tableHeadRow}>
                <TableCell>Class</TableCell>
                <TableCell>Teacher</TableCell>
                <TableCell sx={textCenter}>Missing assignments</TableCell>
                <TableCell sx={textCenter} style={{ display: setDisplay }}>
                  Current grade
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {termGrades.map((termGrade) => (
                <TableRow key={termGrade.id} hover sx={tableBodyRow}>
                  <TableCell
                    sx={{ cursor: "pointer" }}
                    /* eslint-disable-next-line no-return-assign */
                    onClick={() =>
                      navigate(
                        `/student/${studentId}/schools/${schoolId}/classes/${termGrade.klass.id}`
                      )
                    }
                  >
                    {termGrade.klass.name}
                  </TableCell>
                  <TableCell>
                    {termGrade.klass.teacher.first_name}{" "}
                    {termGrade.klass.teacher.last_name}
                  </TableCell>
                  <TableCell
                    style={{ color: "#F50E0E", cursor: "pointer" }}
                    sx={textCenter}
                    onClick={() =>
                      navigate(
                        `/student/${studentId}/schools/${schoolId}/classes/${termGrade.klass.id}?assignments=missing`
                      )
                    }
                  >
                    {calculateMissingAssignments(termGrade.klass.assignments)}
                  </TableCell>
                  <TableCell sx={textCenter} style={{ display: setDisplay }}>
                    {termGradeLetter(termGrade, specialMarks)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography sx={pt30}>No classes for the current term.</Typography>
      )}
    </>
  );
}
