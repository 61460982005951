const formattedGrade = (
  score,
  maxScore,
  markingCodes,
  specialMarks,
  setGrade
) => {
  const isInSpecialMarks =
    score === undefined || score === null
      ? false
      : specialMarks.filter(
          (e) => e.code.toLowerCase() === score.toString().toLowerCase()
        ).length > 0;

  if (score === "" || score === null) {
    return setGrade ? setGrade("-") : "-";
  }

  if (isInSpecialMarks && score !== undefined) {
    const specialMark = specialMarks.filter(
      (e) => e.code.toLowerCase() === score.toString().toLowerCase()
    )[0];

    const specialMarkScore = markingCodes.filter(
      (m) =>
        parseFloat(m.value) === specialMark.score ||
        parseFloat(m.value) <= specialMark.score
    )[0];

    return setGrade
      ? setGrade(specialMarkScore?.code || "-")
      : specialMarkScore?.code || "-";
  }

  for (let i = 0; i < markingCodes?.length; i += 1) {
    const item = markingCodes[i];
    const value = parseFloat(item.value);

    const sortedMarkingCodes = markingCodes.sort(
      (a, b) => Number(b.value) - Number(a.value)
    );
    const markingCode = sortedMarkingCodes.find(
      (_) => Number(score) >= Number(_.value) || score === _.code
    );

    if (markingCode) {
      return setGrade ? setGrade(markingCode.code) : markingCode.code;
    }

    if (score >= value) {
      return setGrade ? setGrade(item.code) : item.code;
    }
  }

  return setGrade ? setGrade("-") : "-";
};

export default formattedGrade;
