import React from "react";
import { useOutletContext } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Grid, Typography, Link } from "@mui/material";
import GeneralInfo from "./GeneralInfo";
import Schedule from "./Schedule";
import WhatsNext from "./WhatsNext";
import Notes from "./Notes";
import SimpleCard from "../../SimpleCard";
import { mb10, my60 } from "../../sharedStyles";

const dashboardScheduleGrid = {
  borderBottom: 1,
  borderTop: 1,
  borderColor: "divider",
  margin: 0,
};

export default function StudentInfoDashboard() {
  const [student, open] = useOutletContext();

  return (
    <Grid container item md={open ? 12 : 9.5} paddingX={1} paddingY={2}>
      <GeneralInfo open={open} student={student} />
      <Grid container direction="row" justifyContent="space-between" sx={my60}>
        <Link underline="none" sx={mb10} href="/#">
          <SimpleCard
            marginless
            compact
            title={<Typography variant="h4">Attendance Year</Typography>}
            content={<Typography variant="h1">98%</Typography>}
            icon={<OpenInNewIcon color="disabled" />}
          />
        </Link>
        <Link underline="none" sx={mb10} href="/#">
          <SimpleCard
            marginless
            compact
            title={<Typography variant="h4">GPA</Typography>}
            content={<Typography variant="h1">3.28</Typography>}
            icon={<OpenInNewIcon color="disabled" />}
          />
        </Link>
        <Link underline="none" sx={mb10} href="/#">
          <SimpleCard
            marginless
            compact
            title={<Typography variant="h4">Merits</Typography>}
            content={<Typography variant="h1">3</Typography>}
            icon={<OpenInNewIcon color="disabled" />}
          />
        </Link>
        <Link underline="none" sx={mb10} href="/#">
          <SimpleCard
            marginless
            compact={!!open}
            title={<Typography variant="h4">Demerits</Typography>}
            content={<Typography variant="h1">0</Typography>}
            icon={<OpenInNewIcon color="disabled" />}
          />
        </Link>
        <Link underline="none" sx={mb10} href="/#">
          <SimpleCard
            marginless
            compact
            title={<Typography variant="h4">Service Hours</Typography>}
            content={<Typography variant="h1">1.5</Typography>}
            icon={<OpenInNewIcon color="disabled" />}
          />
        </Link>
      </Grid>

      <Grid container spacing={2} sx={dashboardScheduleGrid}>
        <Schedule open={open} />

        <WhatsNext open={open} />
      </Grid>

      <Notes />
    </Grid>
  );
}
