import React from "react";
import { Typography } from "@mui/material";

export default function SubHeadingRenderer(props) {
  const { properties } = props;
  return (
    <Typography fontSize="1.45rem" py={3} fontWeight={500}>
      {properties.subHeadingText}
    </Typography>
  );
}
