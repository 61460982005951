import React, { useContext, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ButtonBase,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, FormProvider, useForm } from "react-hook-form";
import MenuIcon from "@mui/icons-material/Menu";
import { EditOutlined } from "@mui/icons-material";
import { Draggable } from "react-beautiful-dnd";
import customFieldsService from "../../../service/customFieldsService";
import { SnackbarContext } from "../../../context/SnackbarContext";
import propertiesComponentMap from "./CustomFieldProperties/propertiesComponentMap";
import AlertDialog from "../../AlertDialog";
import customFieldsModelMap from "./CustomFieldRenderer/FieldModels/customFieldsModelMap";

export default function Field({
  index,
  fieldProp,
  deleteField,
  accordionState,
  setAccordionState,
  setElementHeight,
}) {
  const methods = useForm();
  const { control, handleSubmit, reset } = methods;
  const snackbarContext = useContext(SnackbarContext);
  const [alertDialogState, setAlertDialogState] = useState(false);
  const [fieldData, setFieldData] = useState(fieldProp);

  const onSubmit = (data) => {
    Object.keys(data).forEach((key) => {
      fieldData[key] = data[key];
    });
    snackbarContext.setSnackbar({
      message: "Saving...",
      severity: "info",
      open: true,
    });
    if (!fieldData.id)
      customFieldsService
        .create(fieldData.serialize())
        .then((response) => {
          setFieldData(new customFieldsModelMap[fieldData.type](response.data));
          snackbarContext.setSnackbar({
            message: "Field saved.",
            severity: "success",
            open: true,
          });
        })
        .catch(() => {
          snackbarContext.setSnackbar({
            message: "Failed to save field.",
            severity: "error",
            open: true,
          });
        });
    else
      customFieldsService
        .update(fieldData.serialize())
        .then((response) => {
          setFieldData(new customFieldsModelMap[fieldData.type](response.data));
          snackbarContext.setSnackbar({
            message: "Field saved.",
            severity: "success",
            open: true,
          });
        })
        .catch(() => {
          snackbarContext.setSnackbar({
            message: "Failed to save field.",
            severity: "error",
            open: true,
          });
        });
  };

  useEffect(() => {
    reset(
      {
        properties: fieldData.properties,
        label: fieldData.label,
        identifier: fieldData.identifier,
      },
      { keepTouched: true, keepDirty: true }
    );
  }, []);

  useEffect(() => {
    setElementHeight(
      document.getElementsByClassName("draggable-item")[0]?.clientHeight || 0
    );
  }, [document.getElementsByClassName("draggable-item")[0]]);

  return (
    <Draggable draggableId={fieldData.fieldId} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className="draggable-item"
        >
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Accordion
                sx={{
                  boxShadow: "none",
                  ".MuiAccordionSummary-root": {
                    backgroundColor: "inherit !important",
                  },
                }}
                expanded={accordionState === fieldData.fieldId}
              >
                <AccordionSummary
                  sx={{
                    p: 0,
                    boxShadow: "none !important",
                    ".MuiAccordionSummary-content": {
                      boxShadow: "none !important",
                    },
                  }}
                  onClick={(e) => {
                    if (!e.target.closest(".edit-icon")) {
                      return;
                    }
                    setAccordionState(
                      accordionState === fieldData.fieldId
                        ? null
                        : fieldData.fieldId
                    );
                  }}
                >
                  <Grid container mt={3}>
                    <Grid
                      item
                      md={1.5}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      {...provided.dragHandleProps}
                    >
                      <MenuIcon />
                    </Grid>
                    <Grid item md={9}>
                      <Controller
                        name="label"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            defaultValue={fieldData.label}
                            size="small"
                            {...field}
                            fullWidth
                            label="Field Label"
                            disabled={accordionState !== fieldData.fieldId}
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      md={1.5}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <ButtonBase className="edit-icon">
                        <EditOutlined />
                      </ButtonBase>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails sx={{ px: 0 }}>
                  <Grid container>
                    <Grid item md={1.5} />
                    <Grid item md={9}>
                      <Typography fontSize={13} align="center" mb={2}>
                        {fieldData.prompt}
                      </Typography>
                      <Box mb={2}>
                        <Controller
                          name="identifier"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <TextField
                              defaultValue={fieldData.identifier}
                              size="small"
                              {...field}
                              fullWidth
                              label="Field Identifier"
                            />
                          )}
                        />
                      </Box>
                      {fieldData.propertyComponents.map((property) => {
                        const Component = propertiesComponentMap[property];
                        return <Component fieldProp={fieldData} />;
                      })}
                      <Box
                        display="flex"
                        justifyContent="center"
                        borderTop="1px solid black"
                        p={1}
                      >
                        <Button
                          onClick={() => setAlertDialogState(true)}
                          sx={{ textTransform: "none", color: "#2196F3" }}
                          type="button"
                        >
                          Delete
                        </Button>
                        <Button
                          sx={{ textTransform: "none", color: "#2196F3" }}
                          type="button"
                          onClick={() => setAccordionState(null)}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          sx={{ textTransform: "none", color: "#2196F3" }}
                        >
                          Save
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item md={1.5} />
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </form>
            <AlertDialog
              isOpen={alertDialogState}
              handleClose={() => setAlertDialogState(false)}
              handleConfirm={() => deleteField(index, fieldData.id)}
            />
          </FormProvider>
        </div>
      )}
    </Draggable>
  );
}
