import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useFormContext } from "react-hook-form";
import { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import IOSSwitch from "../../IOSSwitch";
import {
  preferencesFormControlProp,
  preferencesSendButton,
  preferencesTypographyButton,
  setPasswordTypography,
  resetPasswordButton,
} from "./Preferences.style";
import studentsService from "../../../service/studentsService";
import { danger } from "../../sharedStyles";
import SnackBarNotification from "../../SnackBarNotification";
import { PermissionsContext } from "../../../context/PermissionsContext";
import APP_PERMISSIONS from "../../../utils/constants/permissions";
import PERMISSION_TYPES from "../../../utils/constants/permission_types";

export default function Preferences({ student }) {
  const { setValue } = useFormContext();
  const [portalAccess, setPortalAccess] = useState(student.portal_access);
  const [loading, setLoading] = useState(false);
  const [portalLinkLoading, setPortalLinkLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [password, setPassword] = useState(student.password);
  const params = useParams();
  const studentSlug = params.student_slug;
  const [snackBarOpen, setSnackBarOpen] = useState({
    open: false,
    message: "",
    severity: null,
  });
  const [showPassword, setShowPassword] = React.useState(false);
  const { hasPermission } = useContext(PermissionsContext);

  const managePermission = hasPermission(
    APP_PERMISSIONS.STUDENT_PROFILE,
    PERMISSION_TYPES.MANAGE
  );

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onToggle = (event) => {
    setValue("portal_access", event.target.checked, { shouldDirty: true });
    setPortalAccess(event.target.checked);
  };

  const hasPrimaryEmail = () => {
    let disabled = false;
    if (!student.primary_email) {
      disabled = true;
    }
    return disabled;
  };

  const sendPortalLink = async () => {
    setPortalLinkLoading(true);
    try {
      const response = await studentsService.sendPortalLink(studentSlug);
      if (response) {
        setSnackBarOpen({
          open: true,
          message: "Portal link sent.",
        });
      }
    } catch (e) {
      setSnackBarOpen({
        severity: "error",
        open: true,
        message: "Something went wrong.",
      });
    }
    setPortalLinkLoading(false);
  };

  const resetPassword = async () => {
    setLoading(true);
    setError(false);
    setErrorMessage(null);
    if (password && password.length > 5) {
      try {
        const response = await studentsService.resetPassword(
          studentSlug,
          password
        );
        if (response) {
          setSnackBarOpen({
            open: true,
            message: "Password updated.",
          });
        }
      } catch (e) {
        setSnackBarOpen({
          severity: "error",
          open: true,
          message: "Something went wrong.",
        });
      }
    } else {
      setError(true);
      setErrorMessage(
        password
          ? "Password must contain at least 6 characters."
          : "Password cannot be empty."
      );
    }
    setLoading(false);
  };

  return (
    <>
      <Grid container mb={5}>
        <Grid item xs={12} my={3}>
          <Typography variant="profileTitle">Preferences</Typography>
        </Grid>
        <Grid container item xs={12} px={3}>
          <Grid container item xs={4} md={4}>
            <Grid item xs={12}>
              <Tooltip
                placement="bottom-start"
                title={
                  student.primary_email
                    ? ""
                    : "Add primary email to enable portal access."
                }
                key={student.id}
              >
                <Stack direction="row" sx={preferencesTypographyButton} pr={4}>
                  <IOSSwitch
                    defaultChecked={student.portal_access}
                    onChange={onToggle}
                    disabled={hasPrimaryEmail() || !managePermission}
                  />
                  <Typography sx={preferencesFormControlProp}>
                    Portal Access
                  </Typography>
                </Stack>
              </Tooltip>

              {portalAccess && (
                <Stack ml={2}>
                  <Button
                    disabled={portalLinkLoading}
                    variant="contained"
                    sx={preferencesSendButton}
                    onClick={sendPortalLink}
                  >
                    {portalLinkLoading ? "Please wait..." : "Send Portal Link"}
                  </Button>
                </Stack>
              )}
            </Grid>
          </Grid>
          {portalAccess && (
            <Grid item xs={8} md={8} sx={{ pl: "40px" }}>
              <Grid item xs={12} md={12}>
                <Typography sx={setPasswordTypography}>Set password</Typography>
              </Grid>
              <Grid container item xs={12} md={12}>
                <Grid item xs={5} md={5}>
                  <FormControl
                    fullWidth
                    sx={{ mt: "20px", pr: "10px" }}
                    variant="outlined"
                  >
                    <InputLabel
                      error={error}
                      htmlFor="outlined-adornment-password"
                    >
                      Password
                    </InputLabel>
                    <OutlinedInput
                      error={error}
                      autoComplete="off"
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      defaultValue={password}
                      onChange={(event) => {
                        const targetValue = event.target.value;
                        if (targetValue) {
                          setError(false);
                          setErrorMessage(null);
                        }
                        setPassword(event.target.value);
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Button
                    variant="contained"
                    sx={resetPasswordButton}
                    disabled={loading}
                    onClick={resetPassword}
                  >
                    {loading ? "Saving..." : "Reset Password"}
                  </Button>
                </Grid>
              </Grid>
              {errorMessage && (
                <Grid item sm={12}>
                  <Typography sx={danger}>{errorMessage}</Typography>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
      <SnackBarNotification
        message={snackBarOpen.message}
        open={snackBarOpen.open}
        severity={snackBarOpen.severity}
        handleClose={() => setSnackBarOpen({ message: "", open: false })}
      />
    </>
  );
}
