import { mt70 } from "../sharedStyles";

const ReportCardTableCell = {
  color: "black",
  borderColor: "black",
  position: "relative",
};

const reportCardCheckboxCell = (isEnrolled = true) => ({
  ...ReportCardTableCell,
  minWidth: "20px",
  maxWidth: "60px",
  width: "50px",
  position: "sticky",
  left: "0",
  zIndex: "999",
  background: isEnrolled ? "white" : "#D9D9D9",
});

const reportCardNameCell = {
  ...ReportCardTableCell,
  p: "0",
  position: "sticky",
  left: "50px",
  zIndex: "999",
  background: "white",
  minWidth: "220px",
  width: "220px",
};

const reportCardStudentsHeader = {
  display: "inline",
  fontSize: "14px",
};

const reportCardKlassHeaderCell = {
  ...ReportCardTableCell,
  width: "120px",
  minWidth: "120px",
  padding: "0px",
  verticalAlign: "center",
  "& p": {
    fontSize: "12px",
    fontWeight: "500",
  },
};

const reportCardCommentCell = {
  ...ReportCardTableCell,
  width: "240px",
  minWidth: "240px",
  padding: "0px",
  verticalAlign: "center",
  "& p": {
    fontSize: "12px",
    fontWeight: "500",
  },
};

const ReportCardLevelsHead = {
  ...reportCardKlassHeaderCell,
  borderBottom: "0px",
};

const ReportCardTermSkillsHead = {
  ...ReportCardLevelsHead,
  pt: "50px",
  pl: "0px",
};

const ReportCardLevelsBorderedHead = {
  ...reportCardKlassHeaderCell,
  borderLeft: "1px solid black",
  borderRight: "1px solid black",
  borderBottom: "0px",
  "& p": {
    fontWeight: "600",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
  },
};

const emptyMessageContainer = {
  display: "flex",
  justifyContent: "center",
  width: "100%",
  "& p": {
    color: "black",
    fontSize: "16px",
    mt: "20px",
  },
};

const reportCardGradeLevelCell = {
  ...reportCardKlassHeaderCell,
  // width: "1%",
  position: "sticky",
  left: "270px",
  zIndex: "999",
  background: "white",
};

const reportCardPublishedCell = {
  ...reportCardKlassHeaderCell,
  // width: "1%",
  position: "sticky",
  left: "390px",
  zIndex: "999",
  background: "white",
};

const reportCardTable = {
  borderCollapse: "separate",
  borderSpacing: "0",
  tableLayout: "fixed",
};

const reportCardKlassesCellBox = {
  // maxWidth: "100px",
  minwidth: "150px",
};

const reportCardStickyHeaders = {
  top: 0,
  position: "sticky",
  zIndex: 9999,
  background: "white",
};

const reportCardSelectTermBox = {
  ...mt70,
  display: "flex",
  justifyContent: "center",
};

const reportCardModalPreview = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  overflow: "scroll",
  height: "95%",
  width: "95%",
};

const showCommentsDiv = {
  display: "flex",
  alignItems: "center",
  color: "black",
  cursor: "pointer",
};

const reportCardWithdrawnRow = (isEnrolled = true) => ({
  background: isEnrolled ? "white" : "#D9D9D9",
});

export {
  ReportCardTableCell,
  reportCardTable,
  reportCardCheckboxCell,
  reportCardStudentsHeader,
  reportCardKlassHeaderCell,
  ReportCardLevelsHead,
  ReportCardLevelsBorderedHead,
  ReportCardTermSkillsHead,
  emptyMessageContainer,
  reportCardNameCell,
  reportCardGradeLevelCell,
  reportCardCommentCell,
  reportCardKlassesCellBox,
  reportCardStickyHeaders,
  reportCardSelectTermBox,
  reportCardModalPreview,
  showCommentsDiv,
  reportCardPublishedCell,
  reportCardWithdrawnRow,
};
