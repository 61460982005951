import React from "react";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { Tooltip } from "@mui/material";

export default function GradeCheckIconSquare() {
  return (
    <Tooltip title="Skills posted" placement="left-start" arrow>
      <AssignmentIcon color="success" />
    </Tooltip>
  );
}
