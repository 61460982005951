import CheckboxListRenderer from "./CheckboxListFieldRenderer";
import DateFieldRenderer from "./DateFieldRenderer";
import DropdownFieldRenderer from "./DropdownFieldRenderer";
import NumberFieldRenderer from "./NumberFieldRenderer";
import RadioGroupFieldRenderer from "./RadioGroupFieldRenderer";
import TextFieldRenderer from "./TextFieldRenderer";

const customFieldComponentsMap = {
  "short-text": TextFieldRenderer,
  "number-field": NumberFieldRenderer,
  "dropdown-list": DropdownFieldRenderer,
  "radio-button-list": RadioGroupFieldRenderer,
  "checkbox-field": CheckboxListRenderer,
  "date-field": DateFieldRenderer,
};

export default customFieldComponentsMap;
