import { styled } from "@mui/material/styles";
import { Tabs } from "@mui/material";
import theme from "../../../utils";

const StyledEventTabs = styled(Tabs)({
  "&.MuiTabs-root": {
    minHeight: 30,
  },
  "& .MuiTab-root": {
    color: theme.palette.primary.main,
    minHeight: 30,
    paddingTop: 0,
    paddingBottom: 0,
    borderRight: "1px solid #D3D4D5",
    fontSize: 17,
    fontWeight: 400,
    minWidth: 65,
    textTransform: "Capitalize",
  },
  "& .MuiTab-root.Mui-selected": {
    color: "#ffffff",
    backgroundColor: theme.palette.primary.main,
    borderRight: 0,
  },
});

const eventTabs = {
  padding: "0px !important",
};

const eventTabsBordered = {
  borderRight: "1px solid #D3D4D5",
};

const eventsGroupBox = {
  display: "flex",
  maxWidth: "100vw",
};

const eventsGroupBorderBox = {
  borderTop: "1px solid #D3D4D5",
  display: "flex",
  borderBottom: "1px solid #D3D4D5",
};

const eventsParentsText = {
  fontSize: 17,
  color: "#95D122",
};

const eventsProfileBox = {
  width: 250,
  height: 325,
  bottom: 25,
  position: "relative",
  margin: "auto",
};

const eventsProfileImg = {
  width: "inherit",
  height: "inherit",
  objectFit: "contain",
  position: "absolute",
};

const eventsDrawerContainer = {
  width: "800px",
};

const quickFindBox = {
  textAlign: "right",
  marginY: "10px",
  justifyContent: "flex-end",
  paddingX: "1rem",
};

const quickFindSearchField = {
  "& .MuiInput-root": {
    marginRight: "17px",
    "&:before": {
      borderBottom: "none !important",
    },
  },
};

export {
  StyledEventTabs,
  eventTabs,
  eventTabsBordered,
  eventsGroupBox,
  eventsGroupBorderBox,
  eventsParentsText,
  eventsProfileBox,
  eventsProfileImg,
  eventsDrawerContainer,
  quickFindBox,
  quickFindSearchField,
};
