import React, { useContext, useEffect, useRef, useState } from "react";
import { Typography, TableCell, TableRow, TextField } from "@mui/material";
import { useParams, useSearchParams, Link } from "react-router-dom";
import useGrade from "../../../../hooks/useGrade";
import moment from "../../../../utils/constants/momentConfig";
import dateTimeFormats from "../../../../utils/constants/dateTimeFormats";
import { black } from "../../../sharedStyles";
import {
  assignmentDetailScore,
  assignmentDetailScoreInput,
} from "./KlassStudentDetail.styles";
import useFormattedMarkingCodes from "../../../../hooks/useFormattedMarkingCodes";
import ValidInputCodes from "../../../ToolTip/ValidInputCodes";
import PopoverComponent from "../../../ToolTip/ToolTip";
import PartialValidCodes from "../../../ToolTip/PartialValidCodes";
import { SnackbarContext } from "../../../../context/SnackbarContext";
import assignmentService from "../../../../service/assignmentService";

export default function KlassStudentAssignmentRow({
  assignment,
  klass,
  specialMarks,
  activeStudent,
  getStudentDetail,
  hasPointsGrading,
}) {
  const [searchParams] = useSearchParams();
  const [isBackspace, setBackspace] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [score, setScore] = useState(assignment.score);
  const [currentTarget, setCurrentTarget] = useState(null);
  const [currentAssignment, setCurrentAssignment] = useState(null);
  const [showSpecialMarks, setShowSpecialMarks] = useState(false);
  const [studentDataChanged, setStudentDataChanged] = useState(false);
  const snackbarContext = useRef(useContext(SnackbarContext));
  const timeout = useRef();
  const timer = useRef();
  const params = useParams();
  const getMarkingCodes = () => {
    const isPassFail = klass?.is_pass_fail;
    const passFailMarkingCodes = klass?.grading_scale?.marking_codes.filter(
      (gradingScale) => gradingScale?.code === "P" || gradingScale?.code === "F"
    );

    return isPassFail
      ? passFailMarkingCodes
      : klass?.grading_scale.marking_codes;
  };

  const markingCodes = getMarkingCodes();

  const formattedMarkingCodes = useFormattedMarkingCodes(markingCodes);

  const calculatedScore = () => {
    if (
      assignment.max_score &&
      assignment.score !== "" &&
      !specialMarks.filter(
        (e) => e.code.toLowerCase() === assignment?.score?.toLowerCase()
      ).length > 0
    ) {
      return (Number(assignment.score) * 100) / assignment.max_score;
    }
    return assignment?.score;
  };

  const grade = useGrade(
    assignment.grading === "points" && assignment.score !== null
      ? calculatedScore()
      : assignment.score,
    assignment.max_score,
    markingCodes,
    specialMarks
  );

  const handleKeyDown = (event) => {
    if (event.key === "Backspace") {
      setBackspace(true);
    }
  };

  const shouldShowToolTip = (value, isLetterGrade, validCode, isNumber) => {
    if (
      !isLetterGrade &&
      !isNumber &&
      !validCode.filter((a) => a === value.toLowerCase()).length > 0
    ) {
      return true;
    }

    if (
      isLetterGrade &&
      (!validCode.filter((a) => a === value.toLowerCase()).length > 0 ||
        isNumber)
    ) {
      return true;
    }

    return false;
  };

  const handleScoreChange = (_student, _assignment, value, target) => {
    const isInSpecialMarks =
      specialMarks.filter((e) => e.code.toLowerCase() === value.toLowerCase())
        .length > 0;
    const isLetterGrade = _assignment.grading === "letter_grade";
    setCurrentAssignment(_assignment);
    setCurrentTarget(target);
    const isNumber = /^(\d+\.?\d?)$/.test(value);
    const partialCode = PartialValidCodes(
      formattedMarkingCodes,
      specialMarks,
      isLetterGrade
    );
    const validCode = ValidInputCodes(
      formattedMarkingCodes,
      specialMarks,
      isLetterGrade
    ).concat(partialCode, "\\");
    if (
      !isBackspace &&
      shouldShowToolTip(value, isLetterGrade, validCode, isNumber)
    ) {
      setAnchorEl(target);

      return;
    }

    const enteredPartialCode = partialCode.includes(value.toLowerCase());

    // eslint-disable-next-line no-param-reassign
    assignment.score = value === "\\" ? "✓" : value.toUpperCase();
    setScore(value === "\\" ? "✓" : value.toUpperCase());

    clearTimeout(timeout.current);
    if (
      enteredPartialCode ||
      (!isLetterGrade &&
        !isNumber &&
        !isInSpecialMarks &&
        value !== "" &&
        !validCode.filter((a) => a === value.toLowerCase()).length > 0)
    ) {
      timeout.current = setTimeout(async () => {
        // eslint-disable-next-line no-param-reassign
        assignment.score = "";
        setAnchorEl(target);
      }, 2000);
    } else {
      setStudentDataChanged(true);
    }
  };

  useEffect(() => {
    if (studentDataChanged) {
      snackbarContext.current.setSnackbar({
        message: "Saving...",
        severity: "info",
        open: true,
      });
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        const data = {
          student_id: activeStudent?.klass_student.student_id,
          assignment_id: assignment?.assignment_id,
          subject_id: klass.subject_id,
          score,
        };
        assignmentService
          .updateStudentAssignment({
            ...data,
            term_id: searchParams.get("term"),
          })
          .then(() => {
            getStudentDetail();
            snackbarContext.current.setSnackbar({
              message: "Saved",
              severity: "success",
              open: true,
            });
          })
          .catch((err) =>
            snackbarContext.current.setSnackbar({
              message: err.response.data.message || "Error, try again later.",
              severity: "error",
              open: true,
            })
          );
      }, 3000);
      setStudentDataChanged(false);
    }
  }, [studentDataChanged]);

  useEffect(() => {
    setScore(assignment.score);
  }, [assignment]);

  return (
    <TableRow>
      <TableCell sx={{ maxWidth: 200, color: "black" }}>
        <Link
          to={`/school/${params.school_id}/subjects/${klass.subject_id}/class/${
            klass.id
          }/assignments/${assignment.assignment_id}?term=${searchParams.get(
            "term"
          )}`}
        >
          <Typography>{assignment.name}</Typography>
        </Link>
      </TableCell>
      <TableCell sx={black}>
        <Typography>
          {assignment.due_date !== null
            ? moment(assignment.due_date).format(dateTimeFormats.MMDDYYYY)
            : "-"}
        </Typography>
      </TableCell>
      <TableCell sx={{ maxWidth: 200, color: "black" }}>
        <Typography>{assignment.category}</Typography>
      </TableCell>
      <TableCell sx={assignmentDetailScore}>
        <TextField
          variant="outlined"
          inputProps={{
            min: 0,
            style: {
              textAlign: "center",
            },
            id: activeStudent?.id,
            className: "assignment_score_input",
          }}
          sx={assignmentDetailScoreInput}
          value={score || ""}
          onChange={(e) =>
            handleScoreChange(
              activeStudent,
              assignment,
              e.target.value,
              e.currentTarget
            )
          }
          onKeyDown={handleKeyDown}
          onKeyUp={() => setBackspace(false)}
        />
        <PopoverComponent
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          showSpecialMarks={showSpecialMarks}
          setShowSpecialMarks={setShowSpecialMarks}
          handleClick={handleScoreChange}
          student={activeStudent}
          currentAssignment={currentAssignment}
          currentTarget={currentTarget}
          specialMarks={specialMarks}
          markingCodes={markingCodes}
          maxScore={assignment?.max_score}
        />
      </TableCell>
      {hasPointsGrading && (
        <TableCell sx={black}>
          <Typography>
            {assignment.grading === "points" ? assignment.max_score : "-"}
          </Typography>
        </TableCell>
      )}
      <TableCell sx={black}>
        <Typography>{grade}</Typography>
      </TableCell>
    </TableRow>
  );
}
