const conversationInner = {
  width: "1085px",
};

const conversationContainer = {
  color: "black",
};

const conversationTitle = {
  fontSize: "22px",
};

const conversationSenderBox = {
  backgroundColor: "#F2F2F2",
  borderRadius: "12px",
  mb: "15px",
  maxWidth: "870px",
  width: "max-content",
  ml: "auto",
};

const backArrow = {
  paddingRight: "5px",
};

const conversationRecipientBox = {
  ...conversationSenderBox,
  backgroundColor: "#E7F3FF",
  ml: "15px",
  position: "relative",
  "&:before": {
    zIndex: -1,
    content: '""',
    display: "block",
    position: "absolute",
    top: "60px",
    left: "-5px",
    width: "20px",
    height: "20px",
    bgcolor: "#F2F2F2",
    transform: "translateY(-50%) rotate(45deg)",
  },
};

const conversationBoxReply = {
  ...conversationSenderBox,
  backgroundColor: "#F2F2F2",
  display: "block",
  width: "100%",
  height: "195px",
};

const conversationReplyInput = {
  width: "100%",
  p: "10px",
  ".MuiInput-underline:after": {
    borderBottom: "1px solid black",
  },
  ".MuiInput-root:hover:not(.Mui-disabled):before": {
    borderBottom: "1px solid black",
  },
};

const conversationReplyAttachButton = {
  ml: "10px",
  color: "#5077A4",
  fontWeight: "normal",
  "&:hover": {
    backgroundColor: "transparent",
  },
};

const conversationReplySendButton = {
  mr: "10px",
  backgroundColor: "#5077A4",
  color: "white",
  fontSize: "14px",
  textTransform: "none",
  fontWeight: "normal",
  width: "82px",
  "&:hover": {
    backgroundColor: "#5077A4",
  },
};

const conversationAttachIcon = {
  color: "#79747E",
  transform: "rotate(45deg)",
};

const conversationBoxInner = {
  p: "10px",
  height: "100%",
};

const conversationDeleteButton = {
  p: 0,
};

const conversationSenderMessageContent = {
  mt: "10px",
  fontSize: "14px",
  "& a": {
    color: "#7eadda",
  },
};

const conversationSenderTimeStamp = {
  fontSize: "12px",
  color: "#5077A4",
  mt: "5px",
};

const conversationSenderFileContainer = {
  borderTop: "1px solid white",
  pt: "5px",
  mt: "5px",
};

const conversationSenderFileContainerBorderless = {
  border: "none",
  m: 0,
  p: 0,
};

const conversationDeletedMessagesContainer = {
  backgroundColor: "#F2F2F2",
  p: "10px",
  borderRadius: "6px",
  mt: "60px",
};

const attachmentsContainer = {
  maxWidth: "870px",
  ml: "auto",
};

const backButton = {
  color: "#2196F3",
  marginLeft: "90px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "transparent",
  },
};

export {
  conversationTitle,
  conversationContainer,
  conversationSenderBox,
  conversationReplyInput,
  conversationReplyAttachButton,
  conversationReplySendButton,
  conversationAttachIcon,
  conversationBoxInner,
  conversationDeleteButton,
  conversationSenderMessageContent,
  conversationSenderTimeStamp,
  conversationSenderFileContainer,
  conversationRecipientBox,
  conversationBoxReply,
  conversationInner,
  conversationDeletedMessagesContainer,
  conversationSenderFileContainerBorderless,
  attachmentsContainer,
  backButton,
  backArrow,
};
