import React, { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Container,
  Pagination,
  CircularProgress,
  IconButton,
} from "@mui/material";
import BookOutlinedIcon from "@mui/icons-material/BookOutlined";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import ApprovalOutlinedIcon from "@mui/icons-material/ApprovalOutlined";
import AirlineStopsOutlinedIcon from "@mui/icons-material/AirlineStopsOutlined";
import MedicalServicesOutlinedIcon from "@mui/icons-material/MedicalServicesOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import FilePresentOutlinedIcon from "@mui/icons-material/FilePresentOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import moment from "../../../utils/constants/momentConfig";
import dateTimeFormats from "../../../utils/constants/dateTimeFormats";
import { py10, paginationContent, loader } from "../../sharedStyles";
import {
  portfolioTableHead,
  portfolioTableCell,
} from "./PortfolioTable.styles";
import portfolioFilesService from "../../../service/portfolioFilesService";

export default function PortfolioTable({
  drawerOpen,
  handleEditDrawer,
  tableColumns,
}) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [files, setFiles] = useState([]);
  const [order, setOrder] = useState(searchParams.get("direction") || "asc");
  const [orderBy, setOrderBy] = useState(searchParams.get("sort") || "name");
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = React.useState(searchParams.get("page") || 1);
  const [count, setCount] = useState(30);
  const [loading, setLoading] = useState(false);

  const params = useParams();

  const getFiles = async () => {
    setLoading(true);
    const response = await portfolioFilesService.fetchAll({
      params: {
        student_slug: params.student_slug,
        collection_ids: searchParams.get("collections"),
        query: searchParams.get("query"),
        order,
        orderBy,
        count,
        page,
        filterBy: searchParams.get("filterBy"),
        operator: searchParams.get("operator"),
        value: searchParams.get("value"),
      },
    });

    const lastPage = response.pagination.total_pages;
    setTotalPages(lastPage);

    const queryPage = searchParams.get("page") || 1;
    const currentPage = queryPage > lastPage ? lastPage : queryPage;
    setPage(currentPage || 1);

    const countPerPage = searchParams.get("count") || 30;
    setCount(countPerPage);

    setOrder(searchParams.get("direction") || "asc");
    setOrderBy(searchParams.get("sort") || "name");

    if (response) {
      setFiles(response.data);
      setLoading(false);
    }
  };

  const renderFileIcon = (fileType) => {
    switch (fileType) {
      case "Academic":
        return <BookOutlinedIcon />;
      case "Accommodation":
        return <StarBorderOutlinedIcon />;
      case "Certificate":
        return <ApprovalOutlinedIcon />;
      case "Discipline":
        return <AirlineStopsOutlinedIcon />;
      case "Medical":
        return <MedicalServicesOutlinedIcon />;
      case "Permission":
        return <CheckCircleOutlineOutlinedIcon />;
      case "Reference":
        return <AssignmentIndOutlinedIcon />;
      case "Other":
        return <FilePresentOutlinedIcon />;
      default:
        return <FilePresentOutlinedIcon />;
    }
  };

  const renderSortChevron = (label) => {
    const noColumnSelected = searchParams.get("sort") === null;

    if ((noColumnSelected && label === "name") || label === orderBy) {
      return order === "asc" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
    }
    return <ArrowDropUpIcon sx={{ visibility: "hidden" }} />;
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    const direction = isAsc ? "desc" : "asc";
    setOrder(direction);
    setOrderBy(property);

    searchParams.set("sort", property);
    searchParams.set("direction", direction);
    setSearchParams(searchParams);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
    searchParams.set("page", value);
    setSearchParams(searchParams);
  };

  const filterParams =
    searchParams.get("filterBy") !== null &&
    searchParams.get("operator") !== null &&
    searchParams.get("value") !== null;

  useEffect(() => {
    if (filterParams || searchParams.get("filtersCleared")) {
      searchParams.delete("filtersCleared");
      setSearchParams(searchParams);
      getFiles();
    }
  }, [
    searchParams.get("filterBy"),
    searchParams.get("operator"),
    searchParams.get("value"),
  ]);

  useEffect(() => {
    if (!drawerOpen) {
      getFiles();
    }
  }, [
    drawerOpen,
    order,
    orderBy,
    page,
    searchParams.get("query"),
    searchParams.get("collections"),
  ]);

  if (loading) {
    return <CircularProgress sx={loader} size={100} />;
  }

  return (
    <Container maxWidth={false} variant="header">
      <Container maxWidth="xl">
        {files.length > 0 ? (
          <>
            <TableContainer sx={py10}>
              <Table>
                <TableHead>
                  <TableRow>
                    {tableColumns.map((column) => (
                      <TableCell
                        key={column.id}
                        onClick={() => handleRequestSort(column.id)}
                        sx={portfolioTableHead(
                          column.visible,
                          orderBy === column.id
                        )}
                      >
                        <Typography sx={{ textTransform: "uppercase" }}>
                          {column.name} {renderSortChevron(column.id)}
                        </Typography>
                      </TableCell>
                    ))}
                    <TableCell sx={portfolioTableHead(false)} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {files.map((file) => (
                    <TableRow key={file.id}>
                      <TableCell
                        sx={portfolioTableCell(
                          tableColumns.find((column) => column.id === "name")
                            .visible
                        )}
                        onClick={() => handleEditDrawer(file)}
                      >
                        <Typography>{file.name}</Typography>
                      </TableCell>
                      <TableCell
                        sx={portfolioTableCell(
                          tableColumns.find((column) => column.id === "type")
                            .visible
                        )}
                        onClick={() => handleEditDrawer(file)}
                      >
                        <Typography>{file.portfolio_file_type}</Typography>
                      </TableCell>
                      <TableCell
                        sx={portfolioTableCell(
                          tableColumns.find((column) => column.id === "date")
                            .visible
                        )}
                        onClick={() => handleEditDrawer(file)}
                      >
                        <Typography>
                          {moment(file.created_at).format(
                            dateTimeFormats.MMDDYYYY
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={portfolioTableCell(
                          tableColumns.find(
                            (column) => column.id === "added-by"
                          ).visible
                        )}
                        onClick={() => handleEditDrawer(file)}
                      >
                        <Typography>{file.added_by}</Typography>
                      </TableCell>
                      <TableCell
                        sx={portfolioTableCell(
                          tableColumns.find((column) => column.id === "year")
                            .visible
                        )}
                        onClick={() => handleEditDrawer(file)}
                      >
                        <Typography>{file.school_year.name}</Typography>
                      </TableCell>
                      <TableCell
                        sx={portfolioTableCell(
                          tableColumns.find((column) => column.id === "note")
                            .visible
                        )}
                        onClick={() => handleEditDrawer(file)}
                      >
                        <Typography>{file.description}</Typography>
                      </TableCell>
                      <TableCell>
                        <IconButton href={file.attachments_url}>
                          {renderFileIcon(file.portfolio_file_type)}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Pagination
              count={totalPages}
              onChange={handleChangePage}
              sx={paginationContent}
              page={parseInt(page, 10)}
            />
          </>
        ) : (
          <Typography align="center">No files for this student.</Typography>
        )}
      </Container>
    </Container>
  );
}
