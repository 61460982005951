const assignmentDetailInner = {
  alignItems: "baseline",
  justifyContent: "space-between",
  mx: "65px",
  py: "20px",
  borderTop: "1px solid black",
};

const assignmentDetailName = {
  color: "black",
  fontSize: "32px",
  mr: "10px",
};

const assignmentDetailSubtitle = {
  fontSize: "24px",
};

const assignmentNameContainer = { alignItems: "baseline" };

const assignmentStudentCount = {
  color: "#3F464B",
  mr: "30px",
};

const assignmentDetailScore = {
  borderLeft: "1px solid black",
  borderRight: "1px solid black",
  width: "20px",
  px: "10px",
};

const assignmentDetailHead = {
  borderBottom: "none",
  color: "black",
  "& p": {
    fontSize: "14px",
  },
};

const assignmentDetailScoreHead = {
  ...assignmentDetailHead,
  borderLeft: "1px solid black",
  borderRight: "1px solid black",
};

const assignmentDetailHeadName = {
  ...assignmentDetailHead,
  px: 0,
};

const assignmentDetailScoreInput = {
  backgroundColor: "#EBF0F1",
  "& input": { textAlign: "center" },
  "& .MuiOutlinedInput-root": { color: "black" },
  "& fieldset": { border: "none" },
};

const assignmentDetailCellSmall = {
  width: "20px",
};

const assignmentDetailLast = {
  "& td": {
    borderBottom: "none",
  },
};

const assignmentDetailCellName = {
  width: "200px",
  px: 0,
  "& p": {
    fontSize: "20px",
    color: "black",
  },
};

const assignmentDetailCommentInput = {
  "& .MuiOutlinedInput-root": { color: "black" },
};

const assignmentDetailDates = {
  mx: "75px",
};

export {
  assignmentDetailDates,
  assignmentDetailInner,
  assignmentDetailName,
  assignmentDetailSubtitle,
  assignmentNameContainer,
  assignmentStudentCount,
  assignmentDetailScore,
  assignmentDetailScoreHead,
  assignmentDetailScoreInput,
  assignmentDetailCellSmall,
  assignmentDetailHead,
  assignmentDetailLast,
  assignmentDetailCellName,
  assignmentDetailHeadName,
  assignmentDetailCommentInput,
};
