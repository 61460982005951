const collectionsContainer = {
  backgroundColor: "#F8F7F5",
  width: "100%",
  px: "65px",
  boxShadow: "none",
};

const collectionsCard = {
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
  cursor: "pointer",
};

const collectionsCardTitle = {
  fontSize: "18px",
  fontWeight: 500,
};

const collectionsCardsContainer = {
  display: "flex",
  justifyContent: "center",
};

const collectionsIcon = {
  color: "#2196F3",
};

export {
  collectionsContainer,
  collectionsCardsContainer,
  collectionsCard,
  collectionsCardTitle,
  collectionsIcon,
};
