import React, { createContext, useState, useMemo } from "react";

export const CommonContext = createContext({
  processStarted: false,
});

export function CommonProvider({ children }) {
  const [processStarted, setProcessStarted] = useState(false);

  const fireProcessStarted = () => {
    setProcessStarted(true);
  };
  const cleanFireProcessStarted = () => {
    setProcessStarted(false);
  };

  const contextValue = useMemo(
    () => ({
      fireProcessStarted,
      cleanFireProcessStarted,
      processStarted,
    }),
    [processStarted]
  );

  return (
    <CommonContext.Provider value={contextValue}>
      {children}
    </CommonContext.Provider>
  );
}
