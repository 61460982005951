import React, { useState, useEffect, useContext } from "react";
import { useOutletContext } from "react-router-dom";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { QuickBarContext } from "../../../context/QuickBarContext";
import GroupNameRow from "./GroupNameRow";
import Group from "./Group";
import { loader, mt15 } from "../../sharedStyles";
import GroupModal from "./GroupModal";
import SnackBarNotification from "../../SnackBarNotification";
import groupService from "../../../service/groupService";

export default function Groups() {
  const [schoolId] = useOutletContext();
  const quickBarContext = useContext(QuickBarContext);

  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [operation, setOperation] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState({
    open: false,
    message: null,
  });

  const getGroups = async () => {
    setLoading(true);
    const response = await groupService.fetchAllGroups();

    if (response.data) {
      setGroups(response.data);
      setSelectedGroup(response.data[0]);
      setLoading(false);
    }
  };

  const handleOpenModal = async () => {
    setOperation("create");
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setOperation("");
    setModalOpen(false);
    quickBarContext.hideAddGroups();
  };

  useEffect(() => {
    if (modalOpen === false && isDeleteDialogOpen === false) {
      getGroups();
    }
  }, [modalOpen, isDeleteDialogOpen]);

  useEffect(() => {
    (async () => {
      if (quickBarContext.addGroups) {
        await handleOpenModal();
      }

      return () => {
        quickBarContext.hideAddGroups();
      };
    })();
  }, [quickBarContext.addGroups]);

  if (loading) {
    return <CircularProgress sx={loader} size={100} />;
  }

  return (
    <Box>
      {groups.length > 0 ? (
        <Grid sx={{ mt: "30px" }} container columnSpacing={2}>
          <Grid item sm={3} sx={{ borderRight: "1px solid black", mr: "30px" }}>
            {groups.map((group) => (
              <GroupNameRow
                key={group.id}
                group={group}
                selectedGroup={selectedGroup}
                setSelectedGroup={setSelectedGroup}
                setSnackBarOpen={setSnackBarOpen}
                isDeleteDialogOpen={isDeleteDialogOpen}
                setDeleteDialogOpen={setDeleteDialogOpen}
              />
            ))}
          </Grid>
          <Grid item sm={5}>
            <Group selectedGroup={selectedGroup} />
          </Grid>
        </Grid>
      ) : (
        <Typography align="center" sx={mt15}>
          No groups available.
        </Typography>
      )}

      <GroupModal
        open={modalOpen}
        handleClose={handleCloseModal}
        operation={operation}
        schoolId={schoolId}
        setSnackBarOpen={setSnackBarOpen}
      />

      <SnackBarNotification
        message={snackBarOpen.message}
        open={snackBarOpen.open}
        severity={snackBarOpen.severity}
        handleClose={() => setSnackBarOpen(false)}
      />
    </Box>
  );
}
