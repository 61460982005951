const termGradeKlassCell = {
  color: "black",
  borderColor: "black",
  py: 0,
  position: "relative",
  fontSize: "16px",
};

const termGradeKlassHead = {
  ...termGradeKlassCell,
  py: 0,
  "& p": {
    fontSize: "14px",
    fontWeight: "normal",
    height: "80px",
    display: "flex",
    alignItems: "end",
    pb: "10px",
  },
  "&:not(:last-child)": {
    "& p": {
      justifyContent: "center",
    },
  },
};

const termGradeKlassCellSm = {
  ...termGradeKlassCell,
  fontSize: "16px",
};

const termGradeKlassCellName = {
  ...termGradeKlassCell,
  fontSize: "20px",
};

const termGradeKlassHeadTerm = {
  color: "black",
  borderLeft: "1px solid black",
  borderBottom: "1px solid black",
  height: "80px",
  "&:not(:last-of-type)": {
    borderRight: "1px solid black",
  },
};

const termGradeKlassCellGrade = {
  ...termGradeKlassCell,
  px: 0,
  width: "100px",
  height: "70px",
  borderLeft: "1px solid black",
  backgroundColor: "white",
  "&:not(:last-of-type)": {
    borderRight: "1px solid black",
  },
  "& :hover": {
    cursor: "pointer",
    backgroundColor: "#ededed",
  },
  "& p": {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

const termGradeKlassCellComment = {
  ...termGradeKlassCell,
  width: "35%",
};

const termGradeKlassCellGradeGray = {
  ...termGradeKlassCellGrade,
  backgroundColor: "#D9D9D9",
};

const termGradeKlassCheckIconContainer = {
  position: "absolute",
  display: "flex",
  // justifyContent: "center",
  flexDirection: "column",
  top: 0,
  right: 0,
};

const termGradeKlassAlertIconContainer = {
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  bottom: 0,
  right: 0,
};

const termGradeKlassLockIconContainer = {
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  top: 0,
  left: 0,
};

const termGradeKlassFoldContainer = {
  width: "20px",
  height: "20px",
  backgroundColor: "black",
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  top: 0,
  right: 0,
  pr: "5px",
  border: "1px solid black",
  background:
    "linear-gradient(45deg, transparent 0%, transparent 50%, black 50%)",
};

const termGradeKlassToolTipContainer = {
  padding: "15px",
};

const termGradeKlassToolTipTitle = {
  fontWeight: "bold",
  mb: "15px",
};

export {
  termGradeKlassCell,
  termGradeKlassCellSm,
  termGradeKlassCellName,
  termGradeKlassHeadTerm,
  termGradeKlassCellGrade,
  termGradeKlassHead,
  termGradeKlassCellComment,
  termGradeKlassCellGradeGray,
  termGradeKlassCheckIconContainer,
  termGradeKlassAlertIconContainer,
  termGradeKlassFoldContainer,
  termGradeKlassToolTipContainer,
  termGradeKlassLockIconContainer,
  termGradeKlassToolTipTitle,
};
