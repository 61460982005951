import { font16, formContainer } from "../../sharedStyles";

const explore = {
  font16,
  color: "#7D5260",
  alignSelf: "center",
  marginLeft: 2,
};

const lampIcon = { fontSize: 42, color: "#7D5260" };
const form = { ...formContainer, paddingTop: 2 };
const schoolYearField = { width: "15rem" };
const checkbox = { float: "right" };

export { explore, lampIcon, form, schoolYearField, checkbox };
