import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { fetchStudentMessages } from "../../../service/studentPortalService";
import moment from "../../../utils/constants/momentConfig";
import dateTimeFormats from "../../../utils/constants/dateTimeFormats";
import { textCenter } from "../../sharedStyles";
import {
  messagesBox,
  messagesCard,
  tableBodyImage,
  tableBodyContent,
  tableBodyContentName,
  tableBodyContentDesc,
  tableBodyContentTime,
} from "./Messages.styles";
import profilePictureIcon from "../../../utils/profilePictureIcon";

function truncate(str) {
  const newStr = str.length > 100 ? `${str.substring(0, 100)}...` : str;
  return newStr.replace(/(<([^>]+)>)/gi, "").replaceAll("&nbsp;", "");
}

function formatDateTime(str) {
  return moment(str).format(dateTimeFormats.LLL);
}

function displayName(sender) {
  return `${sender.first_name} ${sender.last_name}`;
}

export default function StudentPortalHomeMessages() {
  const params = useParams();
  const studentId = params.id;
  const schoolId = params.school_id;
  const [studentMessages, setStudentMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const conversationKey = (message) =>
    message.conversation_key || message.original_message_conversation_key;

  useEffect(() => {
    (async () => {
      if (studentId && schoolId) {
        try {
          setLoading(true);
          const response = await fetchStudentMessages(studentId, {
            params: {
              school_id: schoolId,
              count: 20,
              page: 1,
            },
          });

          if (response.data) {
            setStudentMessages(response.data);
            setLoading(false);
          }
        } catch (e) {
          // handle catch
        }
      }
    })();
  }, [schoolId]);

  return (
    <>
      <Box sx={messagesBox}>Messages</Box>

      <Card sx={messagesCard}>
        <CardContent>
          <TableContainer>
            <Table aria-label="messages">
              <TableBody>
                {studentMessages.length > 0 ? (
                  studentMessages.map((message) => (
                    <TableRow
                      key={message.id}
                      hover
                      sx={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(
                          `/student/${studentId}/schools/${schoolId}/messages/${conversationKey(
                            message
                          )}`
                        )
                      }
                    >
                      <TableCell sx={tableBodyImage}>
                        <img
                          alt="profile_image"
                          src={profilePictureIcon(
                            message?.recent_message?.sender
                          )}
                          style={{
                            borderRadius: "100px",
                            width: "90px",
                            height: "90px",
                            objectFit: "cover",
                          }}
                        />
                      </TableCell>
                      <TableCell sx={tableBodyContent}>
                        <Typography sx={tableBodyContentName}>
                          {displayName(message.recent_message?.sender)}
                        </Typography>
                        <Typography sx={tableBodyContentDesc}>
                          {truncate(message.recent_message.description)}
                        </Typography>
                        <Typography sx={tableBodyContentTime}>
                          {formatDateTime(message.recent_message.created_at)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow sx={textCenter}>
                    <TableCell style={{ borderBottom: "none" }} sx={textCenter}>
                      {loading ? "Loading..." : "No messages."}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </>
  );
}
