import React, { useContext, useEffect } from "react";
import { Box, Drawer } from "@mui/material";
import { useParams } from "react-router-dom";
import { StaffActionsContext } from "../../../context/StaffActionsContext";
import { QuickBarContext } from "../../../context/QuickBarContext";
import { rightDrawerContainer } from "../../sharedStyles";
import EmergencyContactForm from "./ActionDrawers/EmergencyContactForm";
import TemporaryStaffForm from "./ActionDrawers/TemporaryStaffForm";

export default function StaffActions() {
  const staffActionsContext = useContext(StaffActionsContext);
  const quickBarContext = useContext(QuickBarContext);
  const { id } = useParams();

  useEffect(() => {
    // (async () => {
    if (quickBarContext.addEmergencyContacts) {
      staffActionsContext.openEmergencyContactDrawer();
      quickBarContext.hideAddEmergencyContacts();
    }
    if (quickBarContext.addTemporaryStaff) {
      staffActionsContext.openTemporaryStaffDrawer();
      quickBarContext.cleanAddTemporaryStaff();
    }
    // })();
  }, [quickBarContext.addEmergencyContacts, quickBarContext.addTemporaryStaff]);
  return (
    <>
      <Drawer anchor="right" open={staffActionsContext.isEContactsDrawerOpen}>
        <Box sx={rightDrawerContainer}>
          <EmergencyContactForm
            studentId={id}
            setIsEContactsDrawerOpen={
              staffActionsContext.drawerProperties.setIsEContactsDrawerOpen
            }
            currentContact={staffActionsContext.drawerProperties.currentContact}
            fetchAllContacts={
              staffActionsContext.drawerProperties.fetchAllContacts
            }
          />
        </Box>
      </Drawer>
      <Drawer
        anchor="right"
        open={staffActionsContext.temporaryStaffDrawerOpen}
      >
        <Box sx={rightDrawerContainer}>
          <TemporaryStaffForm
            setTemporaryStaffDrawerOpen={
              staffActionsContext.drawerProperties.setTemporaryStaffDrawerOpen
            }
            currentStaff={staffActionsContext.drawerProperties.currentStaff}
          />
        </Box>
      </Drawer>
    </>
  );
}
