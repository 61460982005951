const attendanceButton = {
  mr: "15px",
  width: "82px",
  border: "1px solid #79747E",
  textTransform: "none",
};

const attendanceButtonActive = {
  backgroundColor: "#5077A4",
  color: "#ffffff",
  ...attendanceButton,
  "&:hover": {
    backgroundColor: "#5981b0",
  },
};

const attendanceButtonInactive = {
  backgroundColor: "#ffffff",
  color: "#5077A4",
  ...attendanceButton,
};

const codeBarContainer = {
  my: "10px",
  ml: "300px",
};

const attendanceTeacherSelection = {
  width: "260px",
  height: "40px",
  ml: "30px",
  "& .MuiInputBase-root": {
    height: "100%",
  },
};

const attendanceKlassName = {
  fontSize: "20px",
  color: "black",
  mr: "15px",
  fontWeight: "bold",
};

const attendancePeriod = {
  mb: "1.6px",
  color: "black",
};

export {
  attendanceButtonActive,
  attendanceButtonInactive,
  codeBarContainer,
  attendanceTeacherSelection,
  attendanceKlassName,
  attendancePeriod,
};
