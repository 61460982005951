import axios from "../utils/axios-util";

const subjectsEndpoint = "/api/subjects";

const fetchAllSubjects = async (params) => {
  const { data } = await axios.get(subjectsEndpoint, params);

  if (!data.data) {
    console.error("Error fetching subjects from the API");
    return [];
  }
  return data;
};

const fetchSubject = async (subjectId, schoolId) => {
  const { data } = await axios.get(`${subjectsEndpoint}/${subjectId}`, {
    params: { school_id: schoolId },
  });

  if (!data.data) {
    console.error("Error fetching event from the API");
    return [];
  }
  return data;
};

const fetchSubjectStudents = async (subjectId, params) => {
  const { data } = await axios.get(
    `${subjectsEndpoint}/${subjectId}/students`,
    params
  );
  if (!data.data) {
    console.error("Error fetching event from the API");
    return [];
  }
  return data;
};

const fetchStudentDetail = async (subjectId, klassId, studentSlug, params) => {
  const { data } = await axios.get(
    `${subjectsEndpoint}/${subjectId}/classes/${klassId}/students/${studentSlug}`,
    params
  );
  if (!data) {
    console.error("Error fetching student from the API");
    return [];
  }
  return data;
};

const fetchAllSubjectsInCurrentTerm = async (params) => {
  const { data } = await axios.get(`/api/current-subjects`, params);

  if (!data.data) {
    console.error("Error fetching subjects from the API");
    return [];
  }
  return data;
};

const fetchAllSubjectsInTerm = async (params) => {
  const { data } = await axios.get(`/api/subjects-term`, params);

  if (!data.data) {
    console.error("Error fetching subjects from the API");
    return [];
  }
  return data;
};

export default {
  fetchAllSubjects,
  fetchSubject,
  fetchSubjectStudents,
  fetchAllSubjectsInCurrentTerm,
  fetchAllSubjectsInTerm,
  fetchStudentDetail,
};
