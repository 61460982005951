import { Grid, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

export default function MultiLineComponent({ selectedField }) {
  const { register, setValue } = useFormContext();

  useEffect(() => {
    setValue("multiline", selectedField.properties.multiline);
  }, [selectedField]);

  return (
    <Grid item md={12}>
      <TextField
        defaultValue={selectedField.properties.multiline}
        fullWidth
        label="multiline"
        {...register("multiline")}
        disabled={selectedField.source_field_id}
      />
    </Grid>
  );
}
