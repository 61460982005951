import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Stack,
  CircularProgress,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useForm, useFieldArray } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import portfolioCollectionsService from "../../../service/portfolioCollectionsService";
import { font11, font28, mb15, my30, loader } from "../../sharedStyles";
import {
  collectionLabelContainer,
  collectionInputField,
  collectionNameLabel,
  collectionItemsContainer,
  collectionSubmitBtn,
  conllectionBtnContainer,
} from "./StudentPortfolioCollections.styles";
import SnackBarNotification from "../../SnackBarNotification";
import { renderError, requiredMessage } from "../../../utils/constants/forms";

export default function StudentPortfolioCollections({ managePermission }) {
  const [loading, setLoading] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState({
    open: false,
    message: null,
  });

  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "collections",
  });

  const getCollections = async () => {
    setLoading(true);
    const response = await portfolioCollectionsService.fetchAll();

    if (response.data) {
      if (response.data.length > 0) {
        response.data.forEach((item, index) => {
          append({
            itemId: item.id,
            value: item.name,
            isDeleted: false,
            items: item.portfolio_collection_files.length,
          });
          setValue(`collections.${index}.value`, item.name);
        });
      } else {
        append({ value: "", isDeleted: false });
      }

      setLoading(false);
    }
  };

  const onSubmit = async (data) => {
    const collectionsToDelete = data.collections.filter(
      (collection) => collection.isDeleted === true
    );
    const collectionsToUpdate = data.collections.filter(
      (collection) => collection.isDeleted === false
    );

    const response = await portfolioCollectionsService.createCollection({
      collections: collectionsToUpdate,
    });

    if (response.data) {
      setSnackBarOpen({
        open: true,
        message: "Collections updated.",
      });
    }

    if (collectionsToDelete.length > 0) {
      await portfolioCollectionsService.deleteCollection({
        params: {
          collection_ids: collectionsToDelete.map((m) => m.itemId),
        },
      });
    }

    window.location.reload();
  };

  const handleDelete = async (index, id) => {
    if (id) {
      setValue(`collections.${index}.isDeleted`, true);
    } else {
      remove(index);
    }
  };

  const handleUndoDelete = (index) => {
    setValue(`collections.${index}.isDeleted`, false);
  };

  const requiredValidation = {
    value: true,
    message: requiredMessage,
  };

  useEffect(() => {
    getCollections();
  }, []);

  if (loading) {
    return <CircularProgress sx={loader} size={100} />;
  }

  return (
    <Box sx={my30}>
      <Typography sx={font28}>Student Portfolio Collections</Typography>
      <Stack direction="row" sx={collectionLabelContainer}>
        <Typography sx={collectionNameLabel}>NAME OF THE COLLECTION</Typography>
        <Typography sx={[font11, collectionItemsContainer]}>ITEMS</Typography>
      </Stack>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          {fields.map((field, index) => (
            <>
              <Stack direction="row" alignItems="center" sx={mb15}>
                <TextField
                  error={errors?.collections?.[index]}
                  sx={collectionInputField}
                  key={field.id}
                  disabled={!managePermission}
                  label="Collection name"
                  {...register(`collections.${index}.value`, {
                    required: requiredValidation,
                  })}
                />
                <Typography sx={collectionItemsContainer}>
                  {field.items}
                </Typography>
                {managePermission && (
                  <Box>
                    {!watch(`collections.${index}.isDeleted`) ? (
                      <Typography
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleDelete(index, field.itemId)}
                      >
                        <CloseIcon />
                      </Typography>
                    ) : (
                      <Typography
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleUndoDelete(index)}
                      >
                        Undo
                      </Typography>
                    )}
                  </Box>
                )}
              </Stack>

              {errors?.collections?.[index] &&
                renderError(errors?.collections?.[index].value.message)}
            </>
          ))}
        </Box>
        {managePermission && (
          <Stack
            sx={conllectionBtnContainer}
            direction="row"
            justifyContent="space-between"
          >
            <Button
              sx={collectionSubmitBtn}
              startIcon={<AddIcon />}
              variant="text"
              component="label"
              onClick={() => append({ value: "", isDeleted: false })}
            >
              Add New Collection
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
          </Stack>
        )}
      </form>

      <SnackBarNotification
        message={snackBarOpen.message}
        open={snackBarOpen.open}
        severity={snackBarOpen.severity}
        handleClose={() => setSnackBarOpen(false)}
      />
    </Box>
  );
}
