const selectDropdown = {
  position: "absolute",
  cursor: "pointer",
  padding: "5px",
  backgroundColor: "#ffffff",
  zIndex: 9999,
};

const selectDropdownOpen = {
  ...selectDropdown,
  boxShadow: 3,
  borderRadius: "4px",
};

export { selectDropdown, selectDropdownOpen };
