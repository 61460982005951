import React from "react";
// eslint-disable-next-line import/no-unresolved
import { stripHtml } from "string-strip-html";
import { useFormContext, Controller } from "react-hook-form";
import { Box, Typography } from "@mui/material";
import { recipientsTitle } from "./Recipients.styles";
import {
  contentContainer,
  contentTitleContainer,
  contentSubjectContainer,
  contentMessageContainer,
  contentMessageAlert,
} from "./Content.styles";
import ErasableTextField from "../../ErasableTextField";
import TextEditor from "../../TextEditor/TextEditor";
import { danger } from "../../sharedStyles";

export default function Content({
  isAlert,
  handleBodyValidation,
  descriptionError,
  editorRef,
}) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Box sx={contentContainer}>
      <Box sx={contentTitleContainer}>
        <Typography sx={recipientsTitle}>Content</Typography>
      </Box>

      <Box sx={contentSubjectContainer}>
        <ErasableTextField
          id="subject"
          label="Enter a subject"
          fullWidth
          isRequired
          type="text"
          defaultValue=""
          hasError={errors?.subject && true}
        />

        {errors?.subject && (
          <Typography sx={danger}>This field is required.</Typography>
        )}
      </Box>

      <Box sx={contentMessageContainer}>
        {isAlert && (
          <Typography sx={contentMessageAlert}>
            IMPORTANT: Only the subject will appear in text messages
          </Typography>
        )}

        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <TextEditor
              {...field}
              isSimplified
              handleBodyValidation={handleBodyValidation}
              descriptionError={descriptionError}
              editorRef={editorRef}
              hasError={errors?.description}
            />
          )}
          rules={{
            validate: {
              required: (v) =>
                (v && stripHtml(v).result.length > 0) ||
                "Description is required",
            },
          }}
          defaultValue=""
        />
        {errors?.description && (
          <Typography sx={danger}>This field is required.</Typography>
        )}
      </Box>
    </Box>
  );
}
