import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CommentBankOutlinedIcon from "@mui/icons-material/CommentBankOutlined";
import {
  tableBodyRow,
  tableContainer,
  tableHead,
  tableHeadRow,
  noAssignments,
  missingAssignments,
  assignmentName,
  commentBox,
  commentIcon,
} from "./Assignments.styles";
import moment from "../../../utils/constants/momentConfig";
import dateTimeFormats from "../../../utils/constants/dateTimeFormats";
import truncateString from "../../../utils/truncateString";
import formattedGrade from "../../../utils/formattedGrade";

export default function StudentPortalAssignments({
  assignments,
  type,
  specialMarks,
  klassIsPassFail,
  klassMarkingCodes,
  setActiveAssignment,
  setShowDetailedAssignment,
  setActiveAssignmentGrade,
}) {
  const calculatedScore = (assignment) => {
    const { score } = assignment.assignment_students[0];
    if (
      assignment.max_score &&
      score !== "" &&
      score !== null &&
      !specialMarks.filter((e) => e.code.toLowerCase() === score.toLowerCase())
        .length > 0
    ) {
      return (Number(score) * 100) / assignment.max_score;
    }
    return score;
  };

  const getMarkingCodes = () => {
    const isPassFail = klassIsPassFail;
    const passFailMarkingCodes = klassMarkingCodes.filter(
      (gradingScale) => gradingScale.code === "P" || gradingScale.code === "F"
    );
    const letterGradeMarkingCodes = klassMarkingCodes.filter(
      (gradingScale) => gradingScale.code !== "P"
    );

    return isPassFail ? passFailMarkingCodes : letterGradeMarkingCodes;
  };

  const grade = (assignment) => {
    const { score } = assignment.assignment_students[0];

    if (score === "REC") return "Received - not graded";

    return formattedGrade(
      assignment.grading === "points" ? calculatedScore(assignment) : score,
      assignment.max_score,
      getMarkingCodes(),
      specialMarks
    );
  };

  const calculateMissingAssignments = () => {
    let count = 0;
    // eslint-disable-next-line array-callback-return
    assignments.map((assignment) => {
      count += assignment.assignment_students.filter(
        (as) => as.score === "/"
      ).length;
    });

    if (count === 1) {
      count = `${count} missing assignment`;
    } else {
      count = `${count} missing assignments`;
    }
    return count;
  };

  // eslint-disable-next-line consistent-return
  const turnedIn = (assignment) => {
    if (assignment.assignment_students[0].score === "/") {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <mask
            id="mask0_11436_361413"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <rect width="24" height="24" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_11436_361413)">
            <path
              d="M6.3 21.5C5.8 21.5 5.375 21.325 5.025 20.975C4.675 20.625 4.5 20.2 4.5 19.7V4.3C4.5 3.8 4.675 3.375 5.025 3.025C5.375 2.675 5.8 2.5 6.3 2.5H14.25L19.5 7.75V19.7C19.5 20.2 19.325 20.625 18.975 20.975C18.625 21.325 18.2 21.5 17.7 21.5H6.3ZM13.5 8.5V4H6.3C6.23333 4 6.16667 4.03333 6.1 4.1C6.03333 4.16667 6 4.23333 6 4.3V19.7C6 19.7667 6.03333 19.8333 6.1 19.9C6.16667 19.9667 6.23333 20 6.3 20H17.7C17.7667 20 17.8333 19.9667 17.9 19.9C17.9667 19.8333 18 19.7667 18 19.7V8.5H13.5Z"
              fill="#F50E0E"
            />
            <path
              d="M12.375 8C11.19 8 9.975 8.24 9 8.675L9.93 10.235C10.65 10.01 11.43 9.89 12.18 9.89C13.185 9.89 13.845 10.13 13.845 11.165C13.845 11.945 13.395 12.14 12.39 12.605C11.19 13.115 10.8 13.535 10.845 14.465L10.875 15.44H12.765L12.78 14.855C12.795 14.48 13.05 14.33 13.485 14.135C15.075 13.46 16.11 12.89 16.11 11.105C16.11 8.675 14.31 8.015 12.375 8.015V8ZM11.835 16.295C11.145 16.295 10.59 16.85 10.59 17.54C10.59 18.23 11.145 18.8 11.835 18.8C12.54 18.8 13.095 18.23 13.095 17.54C13.095 16.85 12.54 16.295 11.835 16.295Z"
              fill="#F50E0E"
            />
          </g>
        </svg>
      );
    }

    if (assignment.assignment_students[0]?.turned_in_online_at) {
      return <CheckCircleIcon color="success" />;
    }
  };

  if (
    assignments.filter((a) => a.display_in_portal === true).length === 0 &&
    type !== "missing"
  ) {
    return <Typography sx={noAssignments}>No assignments.</Typography>;
  }

  return (
    <>
      {type === "missing" && (
        <Typography sx={missingAssignments}>
          {calculateMissingAssignments()}
        </Typography>
      )}
      {assignments.filter((a) => a.display_in_portal === true).length !== 0 && (
        <TableContainer sx={tableContainer}>
          <Table aria-label="classes">
            <TableHead sx={tableHead}>
              <TableRow sx={tableHeadRow}>
                <TableCell>Assignment</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Assigned Date</TableCell>
                <TableCell>Due Date</TableCell>
                <TableCell>Grade</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {assignments
                .filter((a) => a.display_in_portal === true)
                .map((assignment) => (
                  <TableRow key={assignment.id} hover sx={tableBodyRow}>
                    <TableCell
                      sx={assignmentName}
                      onClick={(e) => {
                        setShowDetailedAssignment(true);
                        setActiveAssignment(assignment);
                        const currentRowGrade =
                          e.target.parentElement.childNodes[4].textContent;
                        setActiveAssignmentGrade(currentRowGrade);
                      }}
                    >
                      {truncateString(assignment.name, 30)}
                      {assignment.assignment_students[0].comment && (
                        <Box sx={commentBox}>
                          <CommentBankOutlinedIcon sx={commentIcon} />
                          <Typography>
                            {assignment.assignment_students[0].comment}
                          </Typography>
                        </Box>
                      )}
                    </TableCell>
                    <TableCell>
                      {assignment.klass_category?.category || "Classwork"}
                    </TableCell>
                    <TableCell>
                      {moment(assignment.assigned_date).format(
                        dateTimeFormats.ddddMMMMD
                      )}
                    </TableCell>
                    <TableCell>
                      {moment(assignment.due_date).format(
                        dateTimeFormats.ddddMMMMD
                      )}
                    </TableCell>
                    <TableCell>{grade(assignment)}</TableCell>
                    <TableCell>{turnedIn(assignment)}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
