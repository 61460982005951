const msgDivider = {
  height: "1rem",
  width: "1px",
  ml: "0.85rem",
};

const msgGrid = { marginTop: "30px" };

const msgItemGrid = { marginTop: "2px", display: "inline-grid" };

const newMsgGrid = { float: "right" };

const msgList = { cursor: "pointer" };

export { msgDivider, msgGrid, msgItemGrid, newMsgGrid, msgList };
