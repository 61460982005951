import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

export default function FieldWidthComponent({ selectedField }) {
  const { register, setValue } = useFormContext();

  useEffect(() => {
    setValue("fieldWidth", selectedField.properties.fieldWidth);
  }, [selectedField]);

  return (
    <Grid item md={12}>
      <FormControl disabled={selectedField.source_field_id} fullWidth>
        <InputLabel id="fieldWidthLabel">Field Width</InputLabel>
        <Select
          labelId="fieldWidthLabel"
          id="fieldWidth"
          label="Field Width"
          defaultValue={selectedField.properties.fieldWidth}
          {...register("fieldWidth")}
        >
          {selectedField.properties.fieldWidthOptions.map(
            (fieldWidthOption) => (
              <MenuItem key={fieldWidthOption} value={fieldWidthOption}>
                <Typography textAlign="left">{fieldWidthOption}</Typography>
              </MenuItem>
            )
          )}
        </Select>
      </FormControl>
    </Grid>
  );
}
