import React, { useContext, useEffect, useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import moment from "moment";
import PrintLayout from "../PrintLayoutComponents/PrintLayout";
import { font11, font28 } from "../../sharedStyles";
import dateTimeFormats from "../../../utils/constants/dateTimeFormats";
import SchoolDayItem from "./SchoolDayItem";
import { SnackbarContext } from "../../../context/SnackbarContext";
import reportsService from "../../../service/reportsService";

export default function SchoolCalendar() {
  const [schoolName] = useOutletContext();

  const [schoolDaysByMonth, setSchoolDaysByMonth] = useState([]);
  const [availableDays, setAvailableDays] = useState(0);
  const [schoolDaysCount, setSchoolDaysCount] = useState(0);
  const [nonSchoolDaysCount, setNonSchoolDaysCount] = useState(0);
  const [schoolYearTerms, setSchoolYearTerms] = useState([]);
  const [dayTemplates, setDayTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  const snackbarContext = useRef(useContext(SnackbarContext));

  useEffect(() => {
    setLoading(true);
    reportsService
      .calenderReport()
      .then((response) => {
        setAvailableDays(response.data.available_days);
        setSchoolDaysCount(response.data.school_days_count);
        setNonSchoolDaysCount(response.data.non_school_days_count);
        setSchoolYearTerms(response.data.terms);
        setDayTemplates(response.data.day_templates);
        const list = [];
        Object.values(response.data.school_days).forEach((_months) => {
          let date = _months[0].date.split("-");
          date = new Date(date[0], date[1] - 1, date[2]);
          const firstDayOfSchoolDays = date.getDate() - 1;
          const firstDayOfMonth =
            Number(moment(date).startOf("month").format("d")) +
            firstDayOfSchoolDays;
          const paddedSchoolDays = _months;
          for (let i = 0; i < firstDayOfMonth; i += 1) {
            paddedSchoolDays.unshift(i);
          }
          list.push({
            monthYear: date,
            schoolDays: paddedSchoolDays,
          });
        });
        const numberOfEmptyDays = list[0].schoolDays.filter(
          (sd) => typeof sd !== "object"
        ).length;
        if (numberOfEmptyDays >= 7) {
          const removeCount = Math.floor(numberOfEmptyDays / 7) * 7;
          list[0].schoolDays.splice(0, removeCount);
        }

        setSchoolDaysByMonth(list);
        setLoading(false);
      })
      .catch((err) => {
        snackbarContext.current.setSnackbar({
          message: "Something went wrong.",
          severity: "error",
          open: true,
        });
        setLoading(false);
        if (err.response?.data?.message) {
          setApiError(err.response?.data?.message);
        }
      });
  }, []);

  const generateBodyContent = () => {
    if (loading) {
      return (
        <Box display="flex" justifyContent="center">
          <Typography color="black" fontSize="20px" mt={5}>
            Loading...
          </Typography>
        </Box>
      );
    }
    if (schoolDaysByMonth.length === 0) {
      return (
        <tr>
          <td>
            <Box textAlign="center" mt={3}>
              {apiError || "No school calender found."}
            </Box>
          </td>
        </tr>
      );
    }
    return (
      <>
        <tr>
          <td>
            <Grid container ml={4}>
              <Grid item xs={1.8} mt={2}>
                <Typography sx={font11}>
                  Available Days: {availableDays}
                </Typography>
              </Grid>
              <Grid item xs={1.8} mt={2}>
                <Typography sx={font11}>
                  School Days: {schoolDaysCount}
                </Typography>
              </Grid>
              <Grid item xs={1.8} mt={2}>
                <Typography sx={font11}>
                  Non-School Days: {nonSchoolDaysCount}
                </Typography>
              </Grid>
            </Grid>
          </td>
        </tr>
        {schoolDaysByMonth.map((month) => (
          <tr key={month.monthYear}>
            <td>
              <div>
                <Grid container alignItems="center" px={3}>
                  <Grid container>
                    <Typography
                      sx={{ fontSize: font28, color: "#81888C", marginTop: 2 }}
                    >
                      {moment(month.monthYear).format(dateTimeFormats.MMMMYYYY)}
                    </Typography>
                  </Grid>
                  <Grid container alignItems="center" mb={2}>
                    {moment.weekdays().map((day) => (
                      <Grid item xs mt={2} key={day}>
                        <Typography color="black" variant="preTitle">
                          {day}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                  {month.schoolDays.map((schoolDay) => (
                    <SchoolDayItem
                      schoolDay={schoolDay}
                      terms={schoolYearTerms}
                      dayTemplates={dayTemplates}
                    />
                  ))}
                </Grid>
                <div style={{ pageBreakBefore: "always" }} />
              </div>
            </td>
          </tr>
        ))}
      </>
    );
  };
  return (
    <PrintLayout
      bodyContent={generateBodyContent()}
      headerContent="School Calendar"
      footerContent={schoolName}
    />
  );
}
