import React, { createContext, useContext, useMemo, useState } from "react";

const UnsavedChangesContext = createContext({
  unsavedChanges: false,
  setUnsavedChanges: null,
});

export function UnsavedChangesProvider({ children }) {
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const value = useMemo(
    () => ({
      unsavedChanges,
      setUnsavedChanges,
    }),
    [unsavedChanges]
  );
  return (
    <UnsavedChangesContext.Provider value={value}>
      {children}
    </UnsavedChangesContext.Provider>
  );
}

export function useUnsavedChanges() {
  const context = useContext(UnsavedChangesContext);
  if (!context) {
    throw new Error(
      "useUnsavedChanges must be used within UnsavedChangesProvider"
    );
  }
  return context;
}
