import React from "react";
import { Table } from "@mui/material";
import PrintLayoutFooter from "./PrintLayoutFooter";
import PrintLayoutHeader from "./PrintLayoutHeader";

export default function PrintLayout({
  headerContent,
  bodyContent,
  footerContent,
}) {
  return (
    <Table className="report-container" width="100%" height="100%">
      <style>
        {`
          @media print{
            .report-container{
              height: 100%;
              position: relative;
            }

            @-moz-document url-prefix() {
              .report-data.student-progress {
                page-break-after: always;
              }
            }
          }
        `}
      </style>

      <PrintLayoutHeader content={headerContent} />
      <tbody className="report-content">{bodyContent}</tbody>
      <PrintLayoutFooter content={footerContent} />
    </Table>
  );
}
