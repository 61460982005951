import axios from "../utils/axios-util";

const emergencyContactsEndpoint = "/api/emergency_contacts";

const fetchAllContacts = async (studentId) => {
  const { data } = await axios.get(
    `${emergencyContactsEndpoint}?student_id=${studentId}`
  );
  if (!data.data) {
    console.error("Error fetching events from the API");
    return [];
  }
  return data;
};

const createEmergencyContact = async (studentId, params) => {
  const { data } = await axios.post(
    `${emergencyContactsEndpoint}?student_id=${studentId}`,
    params
  );
  if (!data.data) {
    console.error("Error fetching event from the API");
    return [];
  }
  return data;
};

const getEmergencyContact = async (studentId, emergencyContactId) => {
  const { data } = await axios.get(
    `${emergencyContactsEndpoint}/${emergencyContactId}?student_id=${studentId}`
  );
  if (!data.data) {
    console.error("Error fetching event from the API");
    return [];
  }
  return data;
};

const updateEmergencyContact = async (
  studentId,
  emergencyContactId,
  params
) => {
  const { data } = await axios.patch(
    `${emergencyContactsEndpoint}/${emergencyContactId}?student_id=${studentId}`,
    params
  );

  if (!data.data) {
    console.error("Error fetching event from the API");
    return [];
  }
  return data;
};

const deleteEmergencyContact = async (studentId, emergencyContactId) => {
  const { data } = await axios.delete(
    `${emergencyContactsEndpoint}/${emergencyContactId}?student_id=${studentId}`
  );
  if (!data.data) {
    console.error("Error fetching event from the API");
    return [];
  }
  return data;
};

export default {
  fetchAllContacts,
  createEmergencyContact,
  getEmergencyContact,
  updateEmergencyContact,
  deleteEmergencyContact,
};
