const classActiveBox = {
  backgroundColor: "black",
  borderRadius: "6px",
  border: "1px solid #79747E",
  mx: "8px",
};

const classInActiveBox = {
  mx: "8px",
};

const classActiveLink = {
  fontSize: "18px",
  lineHeight: "24px",
  color: "white",
  margin: "8px",
};

const classInActiveLink = {
  ...classActiveLink,
  color: "black",
};

const gridContainer = {
  justifyContent: "center",
  mt: "20px",
};

const displayFlex = {
  display: "flex",
};

const gridItemClassName = {
  fontSize: "32px",
  color: "black",
  pl: "10px",
};

const meetingBtn = {
  mt: "5px",
  ml: "105px",
  textTransform: "none",
  backgroundColor: "#2196F3",
  borderRadius: "4px",
  "&:hover": {
    backgroundColor: "#2196F3",
  },
};

const gridItemTeacherName = {
  pl: "10px",
  pt: "10px",
  fontSize: "18px",
  color: "black",
};

const gridItemTeacherName1 = {
  ...gridItemTeacherName,
  pl: "105px",
  pt: "5px",
};

const borderBottom = {
  borderBottom: "1px solid black",
  mt: "-30px",
  ml: "87px",
};

const gridItemBox1 = {
  justifyContent: "center",
  borderBottom: "1px solid black",
  marginTop: "29px",
  display: "flex",
  ml: "-500px",
};

const assignmentsTab = {
  color: "black",
  fontSize: "18px",
  mr: "20px",
  cursor: "pointer",
};

const filesTab = {
  color: "black",
  fontSize: "18px",
  cursor: "pointer",
};

const assignmentsActiveTab = {
  ...assignmentsTab,
  borderBottom: "4px solid black",
};

const filesActiveTab = {
  ...filesTab,
  borderBottom: "4px solid black",
};

const gridItemBox2 = {
  justifyContent: "end",
  display: "flex",
};

const gradeTag = {
  pr: "15px",
  pt: "9px",
  color: "black",
  fontSize: "34px",
  fontWeight: "700",
};

const gradeTag1 = {
  ...gradeTag,
  pr: "28px",
};

const gridItemBox3 = {
  borderBottom: "1px solid black",
};

const gridItemBox5 = {
  pt: "60px",
  borderBottom: "1px solid black",
};

const gridItemBox4 = {
  justifyContent: "end",
  display: "grid",
  pr: "10px",
};

const currentGrade = {
  color: "black",
  textAlign: "center",
  maxWidth: "50px",
};

const assignmentsTypeBtn = {
  textTransform: "none",
  borderRadius: "6px 0px 0px 6px",
  border: "1px solid #8F8F8F",
  background: "#22282C",
  fontSize: "17px",
  color: "white",
  px: "30px",
  py: "0px",
  letterSpacing: "-0.85px",
  fontWeight: 400,
  "&:hover": {
    background: "#22282C",
  },
};

const assignmentsTypeBtnInactive = {
  ...assignmentsTypeBtn,
  borderRadius: "0px 6px 6px 0px",
  background: "white",
  color: "black",
  "&:hover": {
    background: "white",
  },
};

const assignmentsTypeBtn1 = {
  ...assignmentsTypeBtn,
  borderRadius: "0px 6px 6px 0px",
};

const assignmentsTypeBtnInactive1 = {
  ...assignmentsTypeBtnInactive,
  borderRadius: "6px 0px 0px 6px",
};

const gridItem1 = {
  justifyContent: "center",
  display: "flex",
};

const filesDiv = {
  display: "flex",
  justifyContent: "center",
};

export {
  classActiveBox,
  classInActiveBox,
  classActiveLink,
  classInActiveLink,
  gridContainer,
  displayFlex,
  borderBottom,
  gridItemClassName,
  meetingBtn,
  gridItemTeacherName,
  gridItemTeacherName1,
  gridItemBox1,
  assignmentsTab,
  filesTab,
  assignmentsActiveTab,
  filesActiveTab,
  gridItemBox2,
  gradeTag,
  gridItemBox3,
  gridItemBox4,
  currentGrade,
  gradeTag1,
  assignmentsTypeBtn,
  gridItem1,
  assignmentsTypeBtnInactive,
  filesDiv,
  assignmentsTypeBtn1,
  assignmentsTypeBtnInactive1,
  gridItemBox5,
};
