import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Checkbox,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PreviewIcon from "@mui/icons-material/Preview";
import {
  reportCardTableRowCellSquare,
  reportCardTableRowCellName,
  reportCardTableRowBodyCell,
  reportCardIconBox,
  reportCardDisabledCell,
  reportCardTableRowCellGradeLevel,
  reportCardTableRowCellComment,
  reportCardTableRowCellPublished,
} from "./ReportCardRow.style";
import {
  reportCardCheckboxCell,
  reportCardWithdrawnRow,
} from "./ReportCardTable.styles";
import formattedGrade from "../../utils/formattedGrade";
import dateTimeFormats from "../../utils/constants/dateTimeFormats";
import moment from "../../utils/constants/momentConfig";
import reportCardCommentService from "../../service/reportCardCommentService";
import { SnackbarContext } from "../../context/SnackbarContext";
import GradeAlertIcon from "../GradeAlertIcon";
import GradeAlertIconSquare from "../GradeAlertIconSquare";
import GradeCheckIconSquare from "../GradeCheckIconSquare";

export default function ReportCardRow({
  student,
  klasses,
  termGrades,
  specialMarks,
  isCheck,
  setIsCheck,
  klassesStudents,
  reportCards,
  skills,
  studentsMissingGrades,
  socialDevelopment,
  handleDrawerOpen,
  setTkkDrawer,
  setSdDrawer,
  setOneEightDrawer,
  handleOpenPreview,
  showComments,
  comment,
  selectedTerm,
}) {
  const timeout = useRef();
  const snackbarContext = useContext(SnackbarContext);
  const [rowComment, setRowComment] = useState("");

  const handleCommentChange = (event) => {
    setRowComment(event.target.value);

    clearTimeout(timeout.current);
    snackbarContext.setSnackbar({
      message: "Saving...",
      severity: "info",
      open: true,
    });

    timeout.current = setTimeout(async () => {
      const commentSaved = await reportCardCommentService
        .save({
          student_id: student.id,
          term_id: selectedTerm.id,
          comment: event.target.value,
        })
        .catch((err) =>
          snackbarContext.setSnackbar({
            message: err.response.data.message,
            severity: "error",
            open: true,
          })
        );

      if (commentSaved) {
        snackbarContext.setSnackbar({
          message: "Saved successfully",
          severity: "success",
          open: true,
        });
      }
    }, 2000);
  };

  useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    student.is_enrolled = student.is_enrolled_for_current_term;
  });

  useEffect(() => {
    setRowComment(comment || "");
  }, [comment]);

  const termGradeLetter = (termGrade, klass) => {
    let grade = termGrade.calculated_grade;

    if (termGrade.posted_grade) {
      grade = termGrade.posted_grade;
    }

    if (grade === null) {
      return "";
    }

    if (klass.is_pass_fail) {
      return grade >=
        Number(
          klass?.grading_scale?.marking_codes?.find(
            (markingCode) => markingCode.code === "P"
          )?.value
        )
        ? "P"
        : "F";
    }

    const letterGrade = formattedGrade(
      grade,
      100,
      klass?.grading_scale?.marking_codes,
      specialMarks
    );
    return letterGrade;
  };

  const getStudentGradingScale = () => {
    if (
      student.grade_level.toUpperCase() === "TK" ||
      student.grade_level.toUpperCase() === "K"
    ) {
      return "TK-K";
    }
    if (Number(student.grade_level) <= 2 && Number(student.grade_level) >= 1) {
      return "1-2";
    }
    if (Number(student.grade_level) <= 8 && Number(student.grade_level) >= 3) {
      return "3-8";
    }
    return "";
  };

  const showWarning = (termGrade) => {
    const calculatedAt = new Date(termGrade.calculated_at).getTime();
    const postedAt = new Date(termGrade.posted_at).getTime();

    if (
      calculatedAt > postedAt &&
      termGrade.posted_grade !== termGrade.calculated_grade
    ) {
      studentsMissingGrades.push(termGrade.student_id);
      return (
        <Box sx={reportCardIconBox}>
          <GradeAlertIcon isForSkill isReportCard />
        </Box>
      );
    }

    const shouldShowWarning =
      termGrade.posted_grade === null && termGrade.calculated_grade;

    if (shouldShowWarning) {
      studentsMissingGrades.push(termGrade.student_id);
    }

    return shouldShowWarning ? (
      <Box sx={reportCardIconBox}>
        <GradeAlertIcon isForSkill isReportCard />
      </Box>
    ) : (
      ""
    );
  };

  const renderGradeCellTKK = (klass, cellStudent) => {
    const studentKlasses = klassesStudents[cellStudent.id];

    if (studentKlasses && studentKlasses[klass.id]) {
      const subjectsSkills = skills.skills_by_subject
        ? skills.skills_by_subject
        : [];

      const klassSkills = subjectsSkills
        ? subjectsSkills[klass.subject_id]
        : false;

      const klassSkillsArr = klassSkills ? Object.values(klassSkills) : [];

      const filteredKlassSkills = klassSkillsArr.filter(
        (ks) => ks.grading_scale_id === klass.grading_scale_id
      );

      const filteredKlassSkillsObj = filteredKlassSkills.reduce(
        (obj, ks) => ({
          ...obj,
          [ks.id]: ks,
        }),
        {}
      );

      const studentSkills = skills.student_skills_by_student
        ? skills.student_skills_by_student[cellStudent.id]
        : false;

      if (!klassSkills) {
        return (
          <TableCell
            key={`${student.id}-${klass.id}`}
            sx={reportCardTableRowCellSquare}
            align="center"
            onClick={() => {
              handleDrawerOpen(klass, student, null);
              setTkkDrawer(true);
            }}
          >
            <Box sx={reportCardTableRowBodyCell}>
              <GradeCheckIconSquare />
            </Box>
          </TableCell>
        );
      }

      if (!studentSkills) {
        studentsMissingGrades.push(student.id);
        return (
          <TableCell
            key={`${student.id}-${klass.id}`}
            sx={reportCardTableRowCellSquare}
            align="center"
            onClick={() => {
              handleDrawerOpen(klass, student, null);
              setTkkDrawer(true);
            }}
          >
            <Box sx={reportCardTableRowBodyCell}>
              <GradeAlertIconSquare />
            </Box>
          </TableCell>
        );
      }

      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const skillID in filteredKlassSkillsObj) {
        // student is missing a skill grade
        if (!Object.hasOwn(studentSkills, skillID)) {
          studentsMissingGrades.push(student.id);

          return (
            <TableCell
              key={`${student.id}-${klass.id}`}
              sx={reportCardTableRowCellSquare}
              align="center"
              onClick={() => {
                handleDrawerOpen(klass, student, null);
                setTkkDrawer(true);
              }}
            >
              <Box sx={reportCardTableRowBodyCell}>
                <GradeAlertIconSquare />
              </Box>
            </TableCell>
          );
        }

        const updatedAt = new Date(studentSkills[skillID].updated_at).getTime();
        const postedAt = new Date(studentSkills[skillID].posted_at).getTime();

        // student has a grade that hasn't been posted
        if (!postedAt || updatedAt > postedAt) {
          studentsMissingGrades.push(student.id);

          return (
            <TableCell
              key={`${student.id}-${klass.id}`}
              sx={reportCardTableRowCellSquare}
              align="center"
              onClick={() => {
                handleDrawerOpen(klass, student, null);
                setTkkDrawer(true);
              }}
            >
              <Box sx={reportCardTableRowBodyCell}>
                <GradeAlertIcon isForSkill />
              </Box>
            </TableCell>
          );
        }
      }

      // Student is in class and has all grades
      return (
        <TableCell
          key={`${student.id}-${klass.id}`}
          sx={reportCardTableRowCellSquare}
          align="center"
          onClick={() => {
            handleDrawerOpen(klass, student, null);
            setTkkDrawer(true);
          }}
        >
          <Box sx={reportCardTableRowBodyCell}>
            <GradeCheckIconSquare />
          </Box>
        </TableCell>
      );
    }

    // Student isn't in the class at all
    return (
      <TableCell
        key={`${student.id}-${klass.id}`}
        sx={reportCardDisabledCell}
        align="center"
      >
        <Box />
      </TableCell>
    );
  };

  const renderGradeCell = (klass, cellStudent) => {
    if (
      cellStudent.grade_level.toUpperCase() === "TK" ||
      cellStudent.grade_level.toUpperCase() === "K"
    ) {
      return renderGradeCellTKK(klass, cellStudent);
    }

    const termGradeStudent = termGrades[cellStudent.id];
    let termGrade = false;

    if (termGradeStudent) {
      termGrade = termGradeStudent[klass.id];
    }

    if (termGrade) {
      if (termGrade.posted_grade === null) {
        // Student is in class but doesn't have a grade
        studentsMissingGrades.push(student.id);

        return (
          <TableCell
            key={`${student.id}-${klass.id}`}
            sx={reportCardTableRowCellSquare}
            align="center"
            onClick={() => {
              handleDrawerOpen(klass, student, null);
              setOneEightDrawer(true);
            }}
          >
            <Box sx={reportCardTableRowBodyCell}>
              <GradeAlertIconSquare />
            </Box>
          </TableCell>
        );
      }

      // Student has a term grade for the class
      return (
        <TableCell
          key={`${student.id}-${klass.id}`}
          sx={reportCardTableRowCellSquare}
          align="center"
          onClick={() => {
            handleDrawerOpen(klass, student, null);
            setOneEightDrawer(true);
          }}
        >
          <Box sx={reportCardTableRowBodyCell}>
            {showWarning(termGrade)}
            {termGradeLetter(termGrade, klass)} <br />
            {termGrade.posted_grade
              ? termGrade.posted_grade
              : termGrade.calculated_grade}
          </Box>
        </TableCell>
      );
    }

    const studentKlasses = klassesStudents[cellStudent.id];

    if (studentKlasses && studentKlasses[klass.id]) {
      // Student is in class but doesn't have a grade
      studentsMissingGrades.push(student.id);

      return (
        <TableCell
          key={`${student.id}-${klass.id}`}
          sx={reportCardTableRowCellSquare}
          align="center"
          onClick={() => {
            handleDrawerOpen(klass, student, null);
            setOneEightDrawer(true);
          }}
        >
          <Box sx={reportCardTableRowBodyCell}>
            <GradeAlertIconSquare />
          </Box>
        </TableCell>
      );
    }

    return (
      // Student isn't in class
      <TableCell
        key={`${student.id}-${klass.id}`}
        sx={reportCardDisabledCell}
        align="center"
      >
        <Box />
      </TableCell>
    );
  };

  const handleCheckbox = (e, id) => {
    const { checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  const renderGradeCellSocialDevelopment = () => {
    const socialDevelopmentSkills =
      socialDevelopment && socialDevelopment.skills_by_grading_scale
        ? socialDevelopment.skills_by_grading_scale[getStudentGradingScale()]
        : false;

    // Grading Scale does not have skills
    if (!socialDevelopmentSkills) {
      studentsMissingGrades.push(student.id);
      return (
        <Box
          onClick={() => {
            handleDrawerOpen(null, student, socialDevelopmentSkills);
            setSdDrawer(true);
          }}
          sx={reportCardTableRowBodyCell}
        >
          <GradeAlertIconSquare />
        </Box>
      );
    }

    const studentScores =
      socialDevelopment && socialDevelopment.scores_by_student
        ? socialDevelopment.scores_by_student[student.id]
        : false;

    // Student has no scores
    if (!studentScores) {
      studentsMissingGrades.push(student.id);
      return (
        <Box
          onClick={() => {
            handleDrawerOpen(null, student, socialDevelopmentSkills);
            setSdDrawer(true);
          }}
          sx={reportCardTableRowBodyCell}
        >
          <GradeAlertIconSquare />
        </Box>
      );
    }

    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const skillID in socialDevelopmentSkills) {
      // student is missing a social development grade
      if (!Object.hasOwn(studentScores, skillID)) {
        studentsMissingGrades.push(student.id);

        return (
          <Box
            onClick={() => {
              handleDrawerOpen(null, student, socialDevelopmentSkills);
              setSdDrawer(true);
            }}
            sx={reportCardTableRowBodyCell}
          >
            <GradeAlertIconSquare />
          </Box>
        );
      }

      const updatedAt = new Date(studentScores[skillID].updated_at).getTime();
      const postedAt = new Date(studentScores[skillID].posted_at).getTime();

      // student has a grade that hasn't been posted
      if (!postedAt || updatedAt > postedAt) {
        studentsMissingGrades.push(student.id);

        return (
          <Box
            onClick={() => {
              handleDrawerOpen(null, student, socialDevelopmentSkills);
              setSdDrawer(true);
            }}
            sx={reportCardTableRowBodyCell}
          >
            <GradeAlertIcon isForSkill />
          </Box>
        );
      }
    }

    // Student has has all grades
    return (
      <Box
        onClick={() => {
          handleDrawerOpen(null, student, socialDevelopmentSkills);
          setSdDrawer(true);
        }}
        sx={reportCardTableRowBodyCell}
      >
        <GradeCheckIconSquare />
      </Box>
    );
  };

  const publishedAtTitle = (publishedAt) =>
    `Published ${moment(publishedAt).format(dateTimeFormats.LLL)}`;

  // const distributedAtTitle = (distributedAt) =>
  //   `Distributed ${moment(distributedAt).format(dateTimeFormats.LLL)}`;

  return (
    <TableRow sx={reportCardWithdrawnRow(student.is_enrolled)}>
      <TableCell sx={reportCardCheckboxCell(student.is_enrolled)}>
        <Box sx={{ maxWidth: "60px" }}>
          <Checkbox
            checked={isCheck.includes(student.id)}
            onChange={(e) => handleCheckbox(e, student.id)}
          />
        </Box>
      </TableCell>
      <TableCell
        sx={reportCardTableRowCellName(student.is_enrolled)}
        align="left"
      >
        <Box sx={{ width: "200px", marginLeft: 2 }}>
          {`${student.last_name}, ${student.first_name}`}
        </Box>
      </TableCell>
      <TableCell
        sx={reportCardTableRowCellGradeLevel(student.is_enrolled)}
        align="center"
      >
        <Box sx={{ width: "100px" }}>
          <Typography> {student.grade_level}</Typography>
        </Box>
      </TableCell>
      <TableCell
        sx={reportCardTableRowCellPublished(student.is_enrolled)}
        align="center"
      >
        <Box>
          {reportCards[student.id] && reportCards[student.id].is_published ? (
            <Tooltip
              title={publishedAtTitle(reportCards[student.id].published_at)}
              arrow
            >
              <CheckCircleIcon color="success" />
            </Tooltip>
          ) : (
            ""
          )}
        </Box>
      </TableCell>
      {showComments && (
        <TableCell
          sx={reportCardTableRowCellComment(student.is_enrolled)}
          align="center"
        >
          <TextField
            fullWidth
            placeholder="Report Card Comment"
            value={rowComment}
            onChange={handleCommentChange}
          />
        </TableCell>
      )}
      {klasses.map((klass) => renderGradeCell(klass, student))}
      <TableCell sx={reportCardTableRowCellSquare} align="center">
        {renderGradeCellSocialDevelopment()}
      </TableCell>
      {/* <TableCell sx={reportCardTableRowCellSquare} align="center">
        <Box sx={reportCardTableRowBodyCell}>
          {reportCards[student.id] && reportCards[student.id].is_distributed ? (
            <Tooltip
              title={distributedAtTitle(reportCards[student.id].distributed_at)}
              arrow
            >
              <CheckCircleIcon color="success" />
            </Tooltip>
          ) : (
            ""
          )}
        </Box>
      </TableCell> */}
      <TableCell sx={reportCardTableRowCellSquare} align="center">
        <Box
          sx={reportCardTableRowBodyCell}
          onClick={() => handleOpenPreview(student.id)}
        >
          <Tooltip title="Preview" arrow>
            <PreviewIcon color="primary" />
          </Tooltip>
        </Box>
      </TableCell>
    </TableRow>
  );
}
