const termGradeSkillCell = {
  color: "black",
  borderColor: "black",
  py: 0,
  position: "relative",
};

const termGradeSkillsHeadTerm = {
  color: "black",
  borderLeft: "1px solid black",
  borderBottom: "1px solid black",
  borderRight: "1px solid black",
  fontSize: "12px",
  height: "80px",
  verticalAlign: "bottom",
};

const termGradeSkillHead = {
  ...termGradeSkillCell,
  verticalAlign: "bottom",
  "& p": {
    fontSize: "14px",
  },
};

const termGradeLevelsHead = {
  ...termGradeSkillHead,
  borderBottom: "0px",
};

const termGradeTermSkillsHead = {
  ...termGradeLevelsHead,
  pt: "50px",
  pl: "0px",
};

const termGradeTermSkillsHeadBox = {
  height: "40px",
  width: "350px",
  textTransform: "capitalize",
  fontSize: "20px",
  fontWeight: "400",
};

const termGradeLevelsBorderedHead = {
  ...termGradeSkillHead,
  borderLeft: "1px solid black",
  borderRight: "1px solid black",
  borderBottom: "0px",
  "& p": {
    fontWeight: "600",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
  },
};

const emptyMessageContainer = {
  display: "flex",
  justifyContent: "center",
  width: "100%",
  "& p": {
    color: "black",
    fontSize: "20px",
    mt: "20px",
  },
};

export {
  termGradeSkillsHeadTerm,
  termGradeTermSkillsHeadBox,
  termGradeSkillHead,
  termGradeLevelsHead,
  termGradeLevelsBorderedHead,
  termGradeTermSkillsHead,
  emptyMessageContainer,
};
