const StudentSteps = {
  INQUIRY: 1,
  INPROCESS: 2,
  INREVIEW: 3,
  WAITINGLIST: 4,
  ACCEPTED: 5,
  DENIED: 6,
  WITHDRAWN: 7,
};

export default StudentSteps;
