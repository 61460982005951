const groupModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};

const groupModalButton = {
  backgroundColor: "#2196F3",
  ml: "30px",
  color: "white",
  width: "85px",
};

export { groupModalStyle, groupModalButton };
