import { formButtons } from "../sharedStyles";

const klassFormScheduleSelect = {
  width: "200px",
};

const klassFormScheduleSelectM = {
  ...klassFormScheduleSelect,
  mx: "10px",
};
const buttons = { ...formButtons, fontWeight: "300", width: "15%" };

export { klassFormScheduleSelect, klassFormScheduleSelectM, buttons };
