import { tableCellClasses, tableRowClasses } from "@mui/material";

const tableStyles = {
  [`& .${tableCellClasses.root}`]: {
    borderBottom: "none",
  },
  [`& .${tableRowClasses.root}`]: {
    borderBottom: "1px solid #d9d9d9",
  },
  "& .MuiTableRow-head": {
    borderBottom: "none",
    "& .MuiTableCell-head": {
      color: "#8f8e8e",
      textTransform: "uppercase",
      fontWeight: 400,
      fontSize: 11,
    },
  },
  "& .MuiTableCell-body": {
    color: "black",
    fontSize: 14,
    paddingBottom: 0,
    paddingTop: 0.8,
  },
  "& .MuiTableCell-root:nth-child(1)": {
    width: "10%",
  },
  "& .MuiTableCell-root:nth-child(2)": {
    width: "5%",
  },
  "& .MuiTableCell-root:nth-child(3)": {
    width: "10%",
  },
  "& .MuiTableCell-root:nth-child(4)": {
    width: "10%",
  },
  "& .MuiTableCell-root:nth-child(5)": {
    width: "10%",
  },
  "& .MuiTableCell-root:nth-child(6)": {
    width: "7.5%",
  },
  "& .MuiTableCell-root:nth-child(7)": {
    width: "37.5%",
  },
};

export default tableStyles;
