import React, { useState, useEffect } from "react";
import HealthAndSafetyTwoToneIcon from "@mui/icons-material/HealthAndSafetyTwoTone";
import {
  Grid,
  Typography,
  Stack,
  Chip,
  Box,
  Drawer,
  CircularProgress,
  useTheme,
} from "@mui/material";
import { useParams } from "react-router-dom";
import {
  generalInfoChip,
  generalInfoColoredStack,
  generalInfoContainer,
  generalInfoGrid,
  generalInfoImageContainer,
  generalInfoImageStyle,
  generalInfoRightStack,
  generalInfoScheduleContainer,
  generalInfoStackSpacing,
  generalInfoStackColor,
} from "./GeneralInfo.style";
import {
  font40,
  mb10,
  ml15,
  ml5,
  mr30,
  dBlock,
  cursorPointer,
  loader,
  rightDrawerContainer,
} from "../../sharedStyles";
import EmergencyContactForm from "../StudentActions/ActionDrawers/EmergencyContactForm";
import Guardian from "../StudentActions/ActionDrawers/Guardian";
import SnackBarNotification from "../../SnackBarNotification";
import emergencyContactsService from "../../../service/emergencyContactsService";
import { fetchGuardian } from "../../../service/guardiansService";

export default function GeneralInfo({ open, student }) {
  const [emergencyContacts, setEmergencyContacts] = useState([]);
  const [guardians, setGuardians] = useState([]);
  const [emergencyContactsDrawer, setEmergencyContactsDrawer] = useState(false);
  const [currentContact, setCurrentContact] = useState({
    action: null,
    data: null,
    type: null,
  });
  const [snackBarOpen, setSnackBarOpen] = useState({
    open: false,
    message: null,
  });
  const theme = useTheme();
  const params = useParams();

  const handleEmergencyContactsDrawer = (value) => {
    setEmergencyContactsDrawer(value);

    if (value === false) {
      setCurrentContact({
        action: null,
        data: null,
        type: null,
      });
    }
  };

  const getContact = async (currentContactId) => {
    const response = await emergencyContactsService.getEmergencyContact(
      student.id,
      currentContactId
    );

    if (response.data) {
      setCurrentContact({
        action: "update",
        data: response.data,
        type: "ec",
      });
    }
  };

  const getGuardian = async (currentGuardianId) => {
    let { id } = params;
    if (params.kid_id) {
      id = params.kid_id;
    }

    const response = await fetchGuardian(currentGuardianId, id);

    if (response) {
      setCurrentContact({
        action: "update",
        data: response,
        type: "guardian",
      });
    }
  };

  const handleClick = (id, type) => {
    if (type === "ec") {
      getContact(id);
    }

    if (type === "guardian") {
      getGuardian(id);
    }

    handleEmergencyContactsDrawer(true);
  };

  const handleSackBarClose = () => {
    setSnackBarOpen({ open: false });
  };

  useEffect(() => {
    (async () => {
      const response = await emergencyContactsService.fetchAllContacts(
        student.id
      );

      if (response.data) {
        setEmergencyContacts(response.data.emergency_contacts);
        setGuardians(response.data.guardians);
      }
    })();
  }, [student, emergencyContactsDrawer]);

  return (
    <>
      <Grid container justifyContent="space-between" sx={generalInfoGrid}>
        <Grid item md={open ? 6 : 7} xl={7} sx={generalInfoContainer}>
          <Stack sx={generalInfoStackColor}>
            <Typography variant="preTitle">
              Resides with mother and father
            </Typography>
            <Typography variant="title">
              000 Learning Lane, Walla Walla, WA 99362
            </Typography>
            <Typography variant="title">
              000-000-000
              <Typography variant="preTitle" sx={ml5}>
                Cell
              </Typography>
            </Typography>
          </Stack>

          <Stack sx={generalInfoColoredStack}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={mr30}
            >
              <Typography variant="title" sx={mb10}>
                Emergency contacts
              </Typography>

              <HealthAndSafetyTwoToneIcon sx={font40} color="alert" />
            </Stack>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              {guardians.map((guardian) => (
                <Grid
                  item
                  xs={6}
                  key={guardian.id}
                  sx={cursorPointer}
                  onClick={() => handleClick(guardian.id, "guardian")}
                >
                  <Box>
                    <Typography variant="preTitle" sx={dBlock}>
                      {guardian.relationship}
                    </Typography>
                    <Typography variant="title" sx={dBlock}>
                      {guardian.first_name}
                    </Typography>
                    {guardian.cell_phone && (
                      <Box sx={dBlock}>
                        <Typography variant="title">
                          {guardian.cell_phone}
                          <Typography variant="preTitle" sx={ml5}>
                            Cell
                          </Typography>
                        </Typography>
                      </Box>
                    )}
                    {guardian.secondary_phone && (
                      <Box sx={dBlock}>
                        <Typography variant="title">
                          {guardian.secondary_phone}
                          <Typography variant="preTitle" sx={ml5}>
                            Secondary Phone
                          </Typography>
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Grid>
              ))}
              {emergencyContacts.map((ec) => (
                <Grid
                  item
                  xs={6}
                  key={ec.id}
                  sx={cursorPointer}
                  onClick={() => handleClick(ec.id, "ec")}
                >
                  <Box>
                    <Typography variant="preTitle" sx={dBlock}>
                      {ec.relationship}
                    </Typography>
                    <Typography variant="title" sx={dBlock}>
                      {ec.first_name}
                    </Typography>
                    {ec.cell_phone && (
                      <Box sx={dBlock}>
                        <Typography variant="title">
                          {ec.cell_phone}
                          <Typography variant="preTitle" sx={ml5}>
                            Cell
                          </Typography>
                        </Typography>
                      </Box>
                    )}
                    {ec.secondary_phone && (
                      <Box sx={dBlock}>
                        <Typography variant="title">
                          {ec.secondary_phone}
                          <Typography variant="preTitle" sx={ml5}>
                            Secondary Phone
                          </Typography>
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Stack>
        </Grid>
        <Grid item md={open ? 6 : 5} xl={4} sx={generalInfoScheduleContainer()}>
          <Stack
            direction="row"
            sx={{ color: "#000000" }}
            justifyContent={generalInfoStackSpacing(theme)}
          >
            <Stack sx={generalInfoRightStack}>
              <Typography variant="h4">
                2:03 P.M PDT <br />
                Wednesday, March 15
              </Typography>
              <Typography variant="h3" sx={{ my: "20px" }}>
                Mathematics <br />
                Room B3
              </Typography>
              <Typography variant="h4">Kathy Johnson</Typography>
              <Chip label="P" color="success" sx={generalInfoChip} />
            </Stack>
            <Stack sx={ml15}>
              <div style={generalInfoImageContainer}>
                <img
                  alt="profile"
                  src={`${window.location.origin}/assets/profile-default.png`}
                  style={generalInfoImageStyle}
                />
              </div>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      <Drawer anchor="right" open={emergencyContactsDrawer}>
        <Box sx={rightDrawerContainer}>
          {currentContact.action ? (
            <Box>
              {currentContact.type === "ec" ? (
                <EmergencyContactForm
                  studentId={student.id}
                  handleEcontactDrawer={handleEmergencyContactsDrawer}
                  setSnackBarOpen={setSnackBarOpen}
                  currentContact={currentContact.data}
                />
              ) : (
                <Guardian
                  setIsGuardianDrawerOpen={setEmergencyContactsDrawer}
                  currentGuardianId={currentContact.data.data.id}
                />
              )}
            </Box>
          ) : (
            <CircularProgress sx={loader} size={100} />
          )}
        </Box>
      </Drawer>

      <SnackBarNotification
        message={snackBarOpen.message}
        open={snackBarOpen.open}
        handleClose={handleSackBarClose}
      />
    </>
  );
}
