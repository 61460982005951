import { Box, TextField } from "@mui/material";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { renderError } from "../../../../../utils/constants/forms";

export default function DateFieldRenderer({ field, properties, defaultValue }) {
  const {
    register,
    formState: { errors },
    trigger,
    setValue,
  } = useFormContext();
  const [dateValue, setDateValue] = useState(
    (defaultValue &&
      moment(defaultValue).utc().format(properties.dateFormat)) ||
      ""
  );

  const datePatterns = {
    "MM/DD/YYYY": /(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/(19|20)\d{2}/,
    "DD/MM/YYYY": /(0[1-9]|[12]\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}/,
    "YYYY-MM-DD": /(19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])/,
    "MMM DD, YYYY":
      /(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s+\d{1,2},\s+(19|20)\d{2}/,
    "DD MMM YYYY":
      /(0[1-9]|[12]\d|3[01])\s+(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s+(19|20)\d{2}/,
    "dddd, MMM DD, YYYY":
      /(Sunday|Monday|Tuesday|Wednesday|Thursday|Friday|Saturday),\s+(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s+\d{1,2},\s+(19|20)\d{2}/,
    "YYYY/MM/DD": /(19|20)\d{2}\/(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])/,
    "MM/DD/YY": /(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{2}/,
    "DD/MM/YY": /(0[1-9]|[12]\d|3[01])\/(0[1-9]|1[0-2])\/\d{2}/,
    "YY/MM/DD": /\d{2}\/(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])/,
  };

  const onDateChanged = (date) => {
    if (date) {
      setDateValue(date);
      setValue(
        `custom_field_responses.${field.field_id}`,
        date.format(properties.dateFormat),
        {
          shouldDirty: true,
        }
      );
      trigger(`custom_field_responses.${field.field_id}`);
    }
  };

  return (
    <Box padding={1}>
      <DesktopDatePicker
        onChange={onDateChanged}
        label={field.label}
        inputFormat={properties.dateFormat}
        value={dateValue}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{ width: "100%" }}
            InputLabelProps={{
              required: properties.isRequired,
            }}
            error={Boolean(
              errors.custom_field_responses &&
                errors.custom_field_responses[`${field.field_id}`]
            )}
            {...register(`custom_field_responses.${field.field_id}`, {
              required: properties.isRequired && "This field is required.",
              pattern: {
                value: datePatterns[properties.dateFormat],
                message: `The date format should be ${properties.dateFormat}`,
              },
            })}
          />
        )}
      />
      {errors.custom_field_responses &&
        errors.custom_field_responses[`${field.field_id}`] &&
        renderError(errors.custom_field_responses[`${field.field_id}`].message)}
    </Box>
  );
}
