/* eslint-disable no-param-reassign */
import React, { useContext } from "react";
import { Button, ButtonBase, Divider, Grid, Typography } from "@mui/material";
import moment from "moment";
import { useSearchParams, useParams, useNavigate } from "react-router-dom";
import { SimCardDownloadOutlined } from "@mui/icons-material";
import dateTimeFormats from "../../../../../utils/constants/dateTimeFormats";
import QontoStepIcon from "../../../../QontoStepIcon";
import { submitButton } from "../../../../sharedStyles";
import admissionProcessService from "../../../../../service/admissionProcessService";
import { SnackbarContext } from "../../../../../context/SnackbarContext";
import StageElementType from "../../../Setup/AdmissionProcessBuilder/Models/constants";
import fileService from "../../../../../service/fileService";

export default function StageInstance({
  stageInstance,
  updateProcessInstance,
  currentStaffId,
}) {
  const snackbarContext = useContext(SnackbarContext);
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const isStageInstanceCompleted = () => {
    let isCompleted = true;
    stageInstance.element_instances.forEach((elementInstance) => {
      if (elementInstance.status !== "completed") isCompleted = false;
    });
    return isCompleted;
  };

  const formatDateTime = (str) => moment(str).format(dateTimeFormats.MMDDYYYY);

  const markElementApproved = (elementInstanceId) => {
    admissionProcessService
      .markElementApproved(elementInstanceId)
      .then(() => {
        const index = stageInstance.element_instances.findIndex(
          (ei) => ei.id === elementInstanceId
        );
        stageInstance.element_instances[index].properties.markApproved = true;
        stageInstance.element_instances[index].status = "completed";
        updateProcessInstance();
        snackbarContext.setSnackbar({
          message: "Form Approved",
          severity: "success",
          open: true,
        });
      })
      .catch(() => {
        snackbarContext.setSnackbar({
          message: "Failed to approve form",
          severity: "error",
          open: true,
        });
      });
  };

  const markElementCompleted = (elementInstanceId) => {
    admissionProcessService
      .markElementCompleted(elementInstanceId)
      .then(() => {
        const index = stageInstance.element_instances.findIndex(
          (ei) => ei.id === elementInstanceId
        );
        stageInstance.element_instances[index].status = "completed";
        updateProcessInstance();
        snackbarContext.setSnackbar({
          message: "Form Approved",
          severity: "success",
          open: true,
        });
      })
      .catch(() => {
        snackbarContext.setSnackbar({
          message: "Failed to approve form",
          severity: "error",
          open: true,
        });
      });
  };

  const renderDateColumn = (elementInstance) => {
    if (
      elementInstance.stage_element.element_type === StageElementType.FORM &&
      elementInstance.properties.personsResponsible?.includes(currentStaffId)
    )
      return !elementInstance.properties.markApproved ? (
        <Button
          onClick={() => markElementApproved(elementInstance.id)}
          variant="contained"
          sx={submitButton}
          fullWidth
        >
          Mark Approved
        </Button>
      ) : (
        "Approved"
      );
    if (
      elementInstance.stage_element.element_type === StageElementType.TASK &&
      elementInstance.properties.isReady &&
      elementInstance.properties.personsResponsible?.includes(currentStaffId)
    )
      return elementInstance.status !== "completed" ? (
        <Button
          onClick={() => markElementCompleted(elementInstance.id)}
          variant="contained"
          sx={submitButton}
          fullWidth
        >
          Mark Completed
        </Button>
      ) : (
        "Completed"
      );
    return elementInstance.date_completed
      ? formatDateTime(elementInstance.date_completed)
      : "-";
  };

  const formattedParams = () => {
    let paramsUrl = "?";
    const schoolYearId = searchParams.get("school_year");
    const studentId = searchParams.get("student_id");
    const processId = searchParams.get("process_id");

    if (schoolYearId) {
      paramsUrl = paramsUrl.concat(`&school_year=${schoolYearId}`);
    }

    if (studentId) {
      paramsUrl = paramsUrl.concat(`&student_id=${studentId}`);
    }

    if (processId) {
      paramsUrl = paramsUrl.concat(`&process_id=${processId}`);
    }

    return paramsUrl;
  };

  const navigateTopDataView = (elementInstanceId, formId) => {
    let paramsUrl = formattedParams();
    paramsUrl = paramsUrl.concat(`&element_instance_id=${elementInstanceId}`);
    paramsUrl = paramsUrl.concat(`&form_id=${formId}`);
    navigate(`/school/${params.school_id}/data-view${paramsUrl}`);
  };

  const elementInstanceAction = (elementInstance, documentIndex) => {
    switch (elementInstance.stage_element.element_type) {
      case StageElementType.FORM:
        navigateTopDataView(
          elementInstance.id,
          elementInstance.stage_element.properties.id
        );
        break;
      case StageElementType.FILE:
        fileService.fetchStageElementFile({
          element_instance_id: elementInstance.id,
          document_id: elementInstance.documents_details[documentIndex].id,
          filename: elementInstance.documents_details[documentIndex].filename,
        });
        break;
      default:
        navigateTopDataView(
          elementInstance.id,
          elementInstance.stage_element.properties.id
        );
    }
  };

  return (
    <Grid item md={12} container mt={2}>
      <Grid item md={1} />
      <Grid item md={7} container alignItems="center">
        <QontoStepIcon checked={isStageInstanceCompleted()} />{" "}
        <Typography color="black" ml={1}>
          {stageInstance.admission_process_stage.name}
        </Typography>
      </Grid>
      <Grid item md={3.5}>
        <Typography align="center">Date Completed</Typography>
      </Grid>
      {stageInstance.element_instances.map((elementInstance) => (
        <Grid item md={12} container>
          <Grid item md={1.5} />
          <Grid item md={6.5} container alignItems="center">
            <Grid item container md={12}>
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  height: "1rem",
                  width: "1px",
                  ml: "0.725rem",
                  borderColor: "black",
                }}
              />
            </Grid>
            <QontoStepIcon checked={elementInstance.status === "completed"} />{" "}
            {elementInstance.status === "completed" ? (
              <ButtonBase
                onClick={() => elementInstanceAction(elementInstance)}
              >
                <Typography color="black" ml={1}>
                  {elementInstance.stage_element.name}
                </Typography>
              </ButtonBase>
            ) : (
              <Typography color="black" ml={1}>
                {elementInstance.stage_element.name}
              </Typography>
            )}
          </Grid>
          <Grid
            item
            md={3.5}
            container
            alignItems="end"
            justifyContent="center"
          >
            <Typography textAlign="center" width="100%">
              {renderDateColumn(elementInstance)}
            </Typography>
          </Grid>
          {elementInstance.documents_details &&
            elementInstance.documents_details.map((document, documentIndex) => (
              <Grid item md={12} container mt={0.75}>
                <Grid item md={2} />
                <Grid item md={6.5} display="flex" alignItems="center">
                  {document.filename}
                  <ButtonBase
                    onClick={() =>
                      elementInstanceAction(elementInstance, documentIndex)
                    }
                  >
                    <SimCardDownloadOutlined sx={{ ml: 1 }} fontSize="small" />
                  </ButtonBase>
                </Grid>
              </Grid>
            ))}
        </Grid>
      ))}
    </Grid>
  );
}
