import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import Group from "./ConditionNodes/Group";
import { cancelButton, submitButton } from "../../FormBuilder.style";
import { ConditionNodeType } from "../../Models/Fields/ConditionNodes/constants";
import ConditionNode from "../../Models/Fields/ConditionNodes/ConditionNode";
import { FormBuilderContext } from "../../FormBuilderContext";
import generateCode from "./ConditionNodes/generateConditionJS";

export default function ValidityCondition({ selectedField }) {
  const { setValue } = useFormContext();
  const methods = useForm();
  const formBuilderContext = useContext(FormBuilderContext);
  const { form, currentSectionIndex, currentPageIndex } = formBuilderContext;
  const [showValidityConditions, setShowValidityConditions] = useState(false);

  const [validityCondition, setValidityCondition] = useState(
    selectedField.properties.validityCondition
  );

  useEffect(() => {
    setValue("validityCondition", validityCondition);
  }, [validityCondition]);

  useEffect(() => {
    setValidityCondition(selectedField.properties.validityCondition);
  }, [selectedField]);

  const createValidityCondition = () => {
    setValidityCondition(new Array(new ConditionNode(ConditionNodeType.GROUP)));
  };

  const generateJS = () => {
    const validityConditionTree = methods.getValues("validityCondition");
    if (validityConditionTree) {
      setValidityCondition(validityConditionTree);
      const jsString = generateCode(
        validityConditionTree,
        form.formPages[currentPageIndex].sections[currentSectionIndex].fields
      );
      setValue("validityConditionJS", jsString, { shouldDirty: true });
      setValue("validityMessage", methods.getValues("validityMessage"));
      setShowValidityConditions(false);
    }
  };

  const onSubmit = () => {
    if (validityCondition.length) generateJS();
    else {
      setShowValidityConditions(false);
    }
  };

  const onCancel = () => {
    setValue("validityCondition", selectedField.properties.validityCondition);
    setValidityCondition(selectedField.properties.validityCondition);
    setShowValidityConditions(false);
  };

  return (
    <Grid item md={12}>
      <Button
        sx={{ ...cancelButton, width: "100%" }}
        onClick={() => {
          setShowValidityConditions(true);
        }}
      >
        <Typography>Validity Conditions</Typography>
      </Button>
      <Dialog
        open={showValidityConditions}
        sx={{
          "& .MuiDialog-paper": {
            width: "35%",
            maxHeight: 435,
            maxWidth: "unset",
          },
        }}
      >
        <DialogTitle>
          <Typography>
            Validity Conditions for <b>{selectedField.label}</b>
          </Typography>
        </DialogTitle>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <DialogContent>
              {!validityCondition.length && (
                <Grid container justifyContent="center" my={2}>
                  <Button sx={cancelButton} onClick={createValidityCondition}>
                    <Typography>Create Condition</Typography>
                  </Button>
                </Grid>
              )}
              {validityCondition.map((node, index) => (
                <Group
                  key={node.id}
                  conditionNode={node}
                  parent={validityCondition}
                  nodeIndex={index}
                  control={`validityCondition[${index}]`}
                  updateParent={setValidityCondition}
                />
              ))}
              {!!validityCondition.length && (
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <TextField
                    label="Validity error message"
                    type="text"
                    defaultValue={selectedField.properties.validityMessage}
                    error={!!methods.formState.errors.validityMessage}
                    InputLabelProps={{ required: true }}
                    {...methods.register("validityMessage", {
                      required: "Validity message is required",
                    })}
                  />
                </FormControl>
              )}
            </DialogContent>
            <DialogActions>
              <Button sx={cancelButton} onClick={onCancel}>
                <Typography>Cancel</Typography>
              </Button>
              <Button type="submit" sx={submitButton}>
                <Typography>Save</Typography>
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </Grid>
  );
}
