import React from "react";
import { Stack, Typography, Box } from "@mui/material";
import {
  notificationsTitle,
  notificationsContainer,
} from "./EmergencyContactForm.styles";
import { guardianNotificationsGrid } from "../../Guardian/Guardian.styles";
import { font12 } from "../../../../sharedStyles";
import NotificationCheckmarkSelect from "./NotificationCheckmarkSelect";

export default function Notifications({ currentContact }) {
  return (
    <Box sx={notificationsContainer}>
      <Typography sx={notificationsTitle}>Notifications</Typography>
      <Stack direction="row" justifyContent="end">
        <Box sx={guardianNotificationsGrid}>
          <Typography sx={font12}>EMAIL</Typography>
        </Box>
        <Box sx={guardianNotificationsGrid}>
          <Typography sx={font12}>TEXT</Typography>
        </Box>
      </Stack>
      <NotificationCheckmarkSelect
        label="Receive Notifications"
        id="notifications"
        currentGuardian={currentContact}
      />
    </Box>
  );
}
