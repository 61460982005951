/* eslint-disable no-param-reassign */
import React, { useContext, useEffect, useRef, useState } from "react";
import { Clear, DragHandle } from "@mui/icons-material";
import { Box, ButtonBase, Grid, Typography } from "@mui/material";
import { Responsive, WidthProvider } from "react-grid-layout";
import {
  containerStyle,
  fieldContainer,
  fieldDelete,
  fieldDrag,
} from "../FormBuilder.style";
import { FormBuilderContext } from "../FormBuilderContext";
import classesMap from "../Models/Fields/classesMap";
import fieldsComponentMap from "./Fields/fieldsComponentMap";
import "./react_grid_layout.css";
import "./react_resizable.css";
import { generateGUID } from "../../../../../utils/constants/utils";

const ResponsiveGridLayout = WidthProvider(Responsive);

export default function FormSection({ section, sectionIndex }) {
  const [droppable, setDroppable] = useState(true);
  const [dropping, setDropping] = useState(false);
  const [droppingElement, setDroppingElement] = useState({ w: 4, h: 1 });
  const itemsRef = useRef([]);
  const formBuilderContext = useContext(FormBuilderContext);
  const {
    form,
    updateForm,
    selectedFieldId,
    updateCurrentSectionIndex,
    currentPageIndex,
    onDragStop,
    onDragStart,
    onDragEnd,
  } = formBuilderContext;

  useEffect(() => {
    if (onDragStop) {
      formBuilderContext.cleanOnDragStop();
      setDroppable(true);
      setDropping(false);
    }
  }, [onDragStop]);

  useEffect(() => {
    if (onDragStart) {
      if (onDragStart.type === "section") setDroppable(false);
      else setDroppable(true);

      if (onDragStart.w && onDragStart.h) {
        setDroppingElement({ w: onDragStart.w, h: onDragStart.h });
      }
      setDropping(true);
      formBuilderContext.cleanOnDragStart();
    }
  }, [onDragStart]);

  useEffect(() => {
    if (onDragEnd) {
      formBuilderContext.cleanOnDragEnd();
    }
  }, [onDragEnd]);

  const setCurrentSection = () => {
    updateCurrentSectionIndex(sectionIndex);
  };

  const selectField = (fieldId) => {
    setCurrentSection();
    formBuilderContext.selectField(fieldId);
  };

  const deleteField = (fieldIndex, fieldId) => {
    const updatedPages = [...form.formPages];
    const updatedSections = [...updatedPages[0].sections];

    const updatedFields = [...updatedSections[sectionIndex].fields];
    updatedFields.splice(fieldIndex, 1);

    updatedSections[sectionIndex] = {
      ...updatedSections[sectionIndex],
      fields: updatedFields,
    };

    updatedPages[0] = {
      ...updatedPages[0],
      sections: updatedSections,
    };

    updateForm({ ...form, formPages: updatedPages });

    if (selectedFieldId === fieldId) {
      selectField(null);
    }
  };

  const deleteSection = (e) => {
    e.stopPropagation();
    const updatedPages = [...form.formPages];
    updatedPages[0].sections.splice(sectionIndex, 1);
    updateForm({ ...form, formPages: updatedPages });
    if (sectionIndex > 0) updateCurrentSectionIndex(sectionIndex - 1);
    selectField(null);
  };

  const updateSectionName = (e) => {
    const formUpdate = { ...form };
    formUpdate.formPages[currentPageIndex].sections[sectionIndex].name =
      e.target.textContent;
    updateForm(formUpdate);
  };

  const handleKeyDown = (event) => {
    if (event.ctrlKey || event.metaKey) {
      switch (event.key) {
        // Add cases for other shortcuts you want to block
        case "b": // Ctrl+B or Command+B
        case "i": // Ctrl+I or Command+I
        case "u": // Ctrl+U or Command+U
          event.preventDefault();
          break;
        default:
      }
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    // Get text from clipboard
    const text = (event.clipboardData || window.clipboardData).getData(
      "text/plain"
    );
    // Insert text at current cursor position
    document.execCommand("insertText", false, text);
  };

  const onDrop = (layouts, _layoutItem, _event) => {
    setCurrentSection();
    setDroppingElement({ w: 4, h: 1 });
    setDropping(false);
    const item = JSON.parse(_event.dataTransfer.getData("text/plain"));
    const newLayoutItem = layouts[layouts.length - 1];
    item.w = newLayoutItem.w;
    item.h = newLayoutItem.h;
    item.x = newLayoutItem.x;
    item.y = newLayoutItem.y;
    item.i = `_${generateGUID().replaceAll("-", "_")}`;

    const newField = new classesMap[item.type](item);
    const updatedPages = [...form.formPages];
    const updatedSections = [...updatedPages[0].sections];
    newField.order = updatedSections[sectionIndex].fields.length;
    const droppedItems = updatedSections[sectionIndex].fields.map((f) => f.i);

    layouts
      .filter((l) => droppedItems.includes(l.i))
      .forEach((l) => {
        const fieldIndex = updatedSections[sectionIndex].fields.findIndex(
          (f) => f.i === l.i
        );
        updatedSections[sectionIndex].fields[fieldIndex].x = l.x;
        updatedSections[sectionIndex].fields[fieldIndex].y = l.y;
        updatedSections[sectionIndex].fields[fieldIndex].w = l.w;
        updatedSections[sectionIndex].fields[fieldIndex].h = l.h;
      });
    updatedSections[sectionIndex] = {
      ...updatedSections[sectionIndex],
      fields: [...updatedSections[sectionIndex].fields, newField],
    };

    updatedPages[0] = {
      ...updatedPages[0],
      sections: updatedSections,
    };
    updateForm({ ...form, formPages: updatedPages });
    formBuilderContext.selectField(newField.fieldId);
  };

  const updateLayouts = (updatedLayouts) => {
    updatedLayouts.forEach((l) => {
      const fieldIndex = section.fields.findIndex((f) => f.i === l.i);
      section.fields[fieldIndex].x = l.x;
      section.fields[fieldIndex].y = l.y;
      section.fields[fieldIndex].w = l.w;
      section.fields[fieldIndex].h = l.h;
    });
  };

  return (
    <Box
      container
      sx={{
        ...containerStyle(),
        backgroundColor: section.editable ? "#F7FCFE" : "#FFEFF6",
      }}
      onClick={setCurrentSection}
    >
      <Grid mb={2} container justifyContent="space-between">
        <Grid item md={11.5}>
          {section.editable ? (
            <Typography
              contentEditable
              onBlur={updateSectionName}
              onKeyDown={handleKeyDown}
              onPaste={handlePaste}
              color="black"
              width="100%"
            >
              {section.name}
            </Typography>
          ) : (
            <Typography color="black">{section.name}</Typography>
          )}
        </Grid>
        <Grid item md={0.5}>
          <ButtonBase onClick={deleteSection}>
            <Clear sx={{ opacity: "50%" }} />
          </ButtonBase>
        </Grid>
      </Grid>
      <Box id="droppable-section">
        <ResponsiveGridLayout
          autoSize
          className="layout"
          style={{
            minHeight: itemsRef.current.reduce(
              (acc, item) => acc + (item?.clientHeight || 0) + 20,
              0
            ),
            borderRadius: "6px",
            outline:
              dropping && droppable && section.editable
                ? "2px solid #2196f399"
                : "none",
          }}
          rowHeight={90}
          cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
          layouts={{
            lg: section.fields,
            md: section.fields,
            sm: section.fields,
            xs: section.fields,
            xxs: section.fields,
          }}
          onDragStop={updateLayouts}
          onResizeStop={updateLayouts}
          draggableHandle=".drag-handle"
          onDrop={onDrop}
          compactType="vertical"
          isDroppable={section.editable && droppable}
          isResizable
          isDraggable
          droppingItem={{
            w: droppingElement.w,
            h: droppingElement.h,
            i: `${section.fields.length}`,
          }}
          resizeHandles={["e"]}
          measureBeforeMount={false}
          useCSSTransforms={false}
        >
          {section.fields.map((field, index) => {
            const Component = fieldsComponentMap[field.type];
            return (
              <Box
                key={field.i}
                sx={{
                  position: "relative",
                  ...field.draggableItemStyle,
                  "&:hover": {
                    "& #deleteField": { display: "inline-flex" },
                    "& .drag-handle": { display: "inline-flex" },
                  },
                }}
                border={
                  selectedFieldId === field.fieldId ? "2px solid #2196f399" : ""
                }
                borderRadius="4px"
              >
                {field.editable && (
                  <Box
                    sx={{
                      ...fieldContainer,
                      zIndex: !field.properties.formBuilderField && 999,
                    }}
                    // eslint-disable-next-line no-return-assign
                    ref={(el) => (itemsRef.current[index] = el)}
                    disableRipple
                    onMouseDown={() => {
                      selectField(field.fieldId);
                    }}
                  />
                )}

                {field.isDraggable && (
                  <ButtonBase className="drag-handle" sx={fieldDrag}>
                    <DragHandle />
                  </ButtonBase>
                )}
                {field.removable && (
                  <ButtonBase
                    id="deleteField"
                    sx={fieldDelete}
                    onMouseDown={(e) => {
                      e.stopPropagation();

                      deleteField(index, field.fieldId);
                    }}
                  >
                    <Clear />
                  </ButtonBase>
                )}
                <Box
                  sx={field.fieldContainerStyle}
                  onMouseDown={() => {
                    if (field.editable) selectField(field.fieldId);
                  }}
                >
                  <Component field={field} />
                </Box>
              </Box>
            );
          })}
        </ResponsiveGridLayout>
      </Box>
    </Box>
  );
}
