import React, { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import {
  useLocation,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import moment from "moment";
import QontoStepIcon from "../../QontoStepIcon";
import { submitButton } from "../../Admissions/Setup/RegistrationForm.style";
import dateTimeFormats from "../../../utils/constants/dateTimeFormats";
import admissionProcessService from "../../../service/admissionProcessService";

export default function Fees() {
  const { stageInstances } = useOutletContext();
  const [stageInstancesToRender, setStageInstancesToRender] =
    useState(stageInstances);

  const location = useLocation();
  const [searchParams] = useSearchParams();

  const navigateTopFormFill = (elementInstanceId, url) => {
    admissionProcessService.startElementInstance(elementInstanceId);
    localStorage.setItem(
      "redirectUrl",
      `${location.pathname}?element_to_complete=${elementInstanceId}`
    );
    window.location.href = url;
  };

  const isStageInstanceCompleted = (stageInstace) => {
    let isCompleted = true;
    stageInstace.element_instances.forEach((elementInstance) => {
      if (elementInstance.status !== "completed") isCompleted = false;
    });
    return isCompleted;
  };

  useEffect(() => {
    const elementInstanceId = searchParams.get("element_to_complete");
    if (elementInstanceId)
      admissionProcessService
        .markElementCompleted(elementInstanceId)
        .then(() => {
          const updatedStages = stageInstancesToRender.map((stage) => ({
            ...stage,
            elementInstances: stage.elementInstances.map((element) => {
              if (element.id === elementInstanceId) {
                return { ...element, propertyToUpdate: "newValue" };
              }
              return element;
            }),
          }));
          setStageInstancesToRender(updatedStages);
        });
  }, [searchParams.get("element_to_complete")]);

  const formatDateTime = (str) => moment(str).format(dateTimeFormats.MMDDYYYY);

  return stageInstancesToRender.map((stageInstance) => (
    <Grid container item md={12} key={stageInstance}>
      <Grid item md={12} container mt={5}>
        <Grid item md={0.5} container alignItems="center">
          <QontoStepIcon checked={isStageInstanceCompleted(stageInstance)} />
        </Grid>
        <Grid item md={4.5} container alignItems="center">
          <Typography color="black" fontSize={20} fontWeight={500}>
            {stageInstance.admission_process_stage.name}
          </Typography>
        </Grid>
        <Grid item md={2.33} container alignItems="center">
          <Typography color="black" fontWeight={500}>
            DUE
          </Typography>
        </Grid>
        <Grid item md={2.33} container alignItems="center">
          <Typography color="black" fontWeight={500}>
            COMPLETED
          </Typography>
        </Grid>
        <Grid item md={2.33} container alignItems="center" />
      </Grid>
      {stageInstance.element_instances.map((elementInstance) => (
        <Grid item md={12} container mt={2.5}>
          <Grid item md={0.5} container alignItems="center">
            {/* <QontoStepIcon /> */}
          </Grid>
          <Grid item md={4.5} container alignItems="center">
            <QontoStepIcon checked={elementInstance.status === "completed"} />
            <Typography color="black" ml={1}>
              {elementInstance.stage_element.name}
            </Typography>
          </Grid>
          <Grid item md={2.33} container alignItems="center">
            <Typography color="black">
              {stageInstance.admission_process_stage.deadline
                ? formatDateTime(stageInstance.admission_process_stage.deadline)
                : "-"}
            </Typography>
          </Grid>
          <Grid item md={2.33} container alignItems="center">
            <Typography color="black">
              {elementInstance.date_completed
                ? formatDateTime(elementInstance.date_completed)
                : "-"}
            </Typography>
          </Grid>
          <Grid item md={2.33} container alignItems="center">
            {elementInstance.status !== "completed" && (
              <Button
                sx={{
                  ...submitButton,
                  backgroundColor:
                    elementInstance.status === "in_process"
                      ? "#ED6C02"
                      : "#2196F3",
                  width: "100%",
                  maxWidth: 100,
                }}
                onClick={() => {
                  navigateTopFormFill(
                    elementInstance.id,
                    elementInstance.stage_element.properties.earlyFeePaymentUrl
                  );
                }}
              >
                {elementInstance.status === "in_process"
                  ? "Continue"
                  : "Pay Fee"}
              </Button>
            )}
          </Grid>
        </Grid>
      ))}
    </Grid>
  ));
}
