import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { SnackbarContext } from "../../../context/SnackbarContext";
import assignmentService from "../../../service/assignmentService";

export default function AssignmentsBulkDelete({
  assignments,
  typeDeleteToConfirm,
  showNamesList,
  onClose,
  onSuccess,
}) {
  const snackbarContext = useContext(SnackbarContext);
  const [deleteDisabled, setDeleteDisabled] = useState(typeDeleteToConfirm);
  const assignmentsIds = assignments.map((assignment) => assignment.id);
  const assignmentsNames = assignments.map((assignment) => assignment.name);

  const handleSuccess = () => {
    snackbarContext.setSnackbar({
      message: "Assignments deleted.",
      severity: "success",
      open: true,
    });

    onSuccess();
  };

  const handleDelete = (e) => {
    e.preventDefault();
    if (deleteDisabled) return;

    const request = assignmentService.bulkDeleteAssignments(assignmentsIds);

    request
      .then(() => {
        handleSuccess();
      })
      .catch(() => {
        snackbarContext.setSnackbar({
          message: "Error deleting assignments.",
          severity: "error",
          open: true,
        });

        onClose();
      });
  };

  useEffect(() => {
    if (assignmentsIds.length === 0) {
      snackbarContext.setSnackbar({
        message: "Select at least one assignment to delete.",
        severity: "error",
        open: true,
      });

      onClose();
    }
  }, [assignmentsIds]);

  return (
    <Dialog
      open
      onClose={onClose}
      PaperProps={{
        component: "form",
        onSubmit: handleDelete,
      }}
    >
      <DialogTitle>
        <Typography variant="h6" color="error">
          Confirm deleting <b>{assignmentsIds.length}</b> selected assignments?
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`
            If you proceed, the selected assignments will be permanently deleted.
          `}

          {typeDeleteToConfirm &&
            `
            If you really want to permanently remove the selected assignments, type DELETE
            in the field below and then click on the "Delete permanently" button.
          `}

          {showNamesList && (
            <>
              {`
                The following assignments will be deleted:
              `}

              <ul>
                {assignmentsNames.map((name) => (
                  <li key={name}>{name}</li>
                ))}
              </ul>
            </>
          )}
        </DialogContentText>

        {typeDeleteToConfirm && (
          <TextField
            sx={{ marginTop: 3 }}
            autoFocus
            autoComplete="off"
            required
            label="Type DELETE to confirm"
            type="text"
            fullWidth
            variant="standard"
            placeholder="DELETE"
            onChange={(e) => {
              if (e.target.value.toLowerCase() === "delete") {
                setDeleteDisabled(false);
              } else {
                setDeleteDisabled(true);
              }
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button type="submit" color="error" disabled={deleteDisabled}>
          Delete permanently
        </Button>
      </DialogActions>
    </Dialog>
  );
}
