import FieldBase from "./FieldBase";

export default class EmailAddress extends FieldBase {
  constructor(field) {
    super(field);
    this.label = field.label;
    this.dataType = "value_text";

    this.propertyComponents = ["name", "label", "supporting-text", "required"];
  }
}
