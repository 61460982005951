import * as React from "react";
import { Grid, Typography, Stack } from "@mui/material";
import { mb25 } from "../../sharedStyles";

const whatsNextGrid = {
  py: "45px !important",
  color: "#000000",
  pl: "30px !important",
};

const whatsNextStack = { py: "10px", borderBottom: 1, borderColor: "#000000" };

export default function WhatsNext() {
  return (
    <Grid item lg={5} md={6} sx={whatsNextGrid}>
      <Typography variant="h2" sx={mb25}>
        What&apos;s next
      </Typography>

      <Stack>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          sx={whatsNextStack}
        >
          <Typography variant="boxTitle">00/00/00</Typography>
          <Typography variant="title">Parent/Teacher Conference</Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          sx={whatsNextStack}
        >
          <Typography variant="boxTitle">00/00/00</Typography>
          <Typography variant="title">Vacation</Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          sx={whatsNextStack}
        >
          <Typography variant="boxTitle">00/00/00</Typography>
          <Typography variant="title">Some other scheduled event</Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          sx={whatsNextStack}
        >
          <Typography variant="boxTitle">00/00/00</Typography>
          <Typography variant="title">Something else</Typography>
        </Stack>
      </Stack>
    </Grid>
  );
}
