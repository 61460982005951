import React from "react";
import { useFormContext } from "react-hook-form";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  courseFormDivider,
  courseFormRow,
  reportCardInput,
  courseFormDuration,
} from "./CourseForm.styles";
import { formTitle, mr10, mr30, mt25 } from "../../sharedStyles";

export default function Transcript({ activeCourse }) {
  const { register } = useFormContext();
  return (
    <Box sx={courseFormDivider}>
      <Typography sx={formTitle}>Transcript</Typography>
      <Stack direction="row" sx={[mt25, courseFormRow]}>
        <Stack direction="row" sx={mr30} alignItems="center">
          <Typography sx={mr10}>Credits</Typography>
          <FormControl sx={reportCardInput}>
            <TextField
              size="small"
              {...register("ts_credits")}
              defaultValue={activeCourse?.ts_credits}
            />
          </FormControl>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Typography sx={mr10}>Duration</Typography>
          <FormControl fullWidth sx={courseFormDuration}>
            <Select
              labelId="duration"
              id="duration"
              defaultValue={activeCourse?.ts_duration || ""}
              {...register("ts_duration")}
            >
              <MenuItem value="quarter">Quarter</MenuItem>
              <MenuItem value="semester">Semester</MenuItem>
              <MenuItem value="all_year">All Year</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </Stack>
      <FormGroup>
        <FormControlLabel
          required
          control={
            <Checkbox
              {...register("ts_not_load")}
              defaultChecked={activeCourse?.ts_not_load}
            />
          }
          label="Do not load on transcript"
        />
        <FormControlLabel
          required
          control={
            <Checkbox
              {...register("ts_not_calculate")}
              defaultChecked={activeCourse?.ts_not_calculate}
            />
          }
          label="Do not calculate on transcript"
        />
      </FormGroup>
    </Box>
  );
}
