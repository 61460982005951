import theme from "../../../utils";

const preferencesBorderedGrid2 = {
  borderRight: { xs: 0, md: 1 },
  borderColor: "divider",
};

const preferencesSendButton = {
  mt: "20px",
  borderRadius: "4px",
  background: "#2196F3",
  textTransform: "none",
  width: "159px",
  "&:hover": {
    background: "#2196F3",
  },
};

const preferencesFormControlProp = {
  typography: { fontSize: "20px", textAlign: "center", marginLeft: "0.75rem" },
};

const preferencesCheckIcon = {
  bgcolor: theme.palette.text.lightBlue,
  color: theme.palette.text.primary,
};

const preferencesContainer = {
  display: "flex",
  alignItems: "center",
};

const preferencesAvatar = {
  bgcolor: theme.palette.text.lightBlue,
};

const preferencesTypographyButton = {
  display: "flex",
  alignItems: "center",
};

const setPasswordTypography = {
  fontSize: "24px",
  lineHeight: "25px",
  letterSpacing: "-0.04em",
};

const resetPasswordButton = {
  ...preferencesSendButton,
  mt: "28px",
};

export {
  preferencesAvatar,
  preferencesCheckIcon,
  preferencesContainer,
  preferencesBorderedGrid2,
  preferencesFormControlProp,
  preferencesSendButton,
  preferencesTypographyButton,
  setPasswordTypography,
  resetPasswordButton,
};
