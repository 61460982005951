import { Box, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { pageTabsContainer, pb0 } from "../../sharedStyles";
import StudentsTabs from "./StudentsTabs";
import { admissionsContainer } from "../Setup/Setup.styles";

export default function Students({ schoolId, selectedSchoolYear }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { getValues, setValue } = useForm();
  const [query, setQuery] = useState(searchParams.get("query") || "");
  const [tabChanged, setTabChanged] = useState();

  const handleSearch = (data) => {
    searchParams.set("query", data.search || "");
    setQuery(data.search || "");
    setSearchParams(searchParams);
  };

  const handleClickOnClearButton = () => {
    setValue("search", null);
    handleSearch(getValues());
  };

  useEffect(() => {
    if (tabChanged) {
      handleClickOnClearButton();
      setTabChanged(false);
    }
  }, [tabChanged]);

  return (
    <>
      <Container maxWidth={false} variant="header" sx={pb0}>
        <Box sx={pageTabsContainer}>
          <StudentsTabs setTabChanged={setTabChanged} />
        </Box>
      </Container>

      <Box sx={admissionsContainer}>
        <Outlet context={[schoolId, selectedSchoolYear, query, setQuery]} />
      </Box>
    </>
  );
}
