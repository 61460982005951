import React, { createContext, useContext, useMemo, useRef } from "react";
import { queueScheduler } from "rxjs";
import { SnackbarContext } from "./SnackbarContext";

export const SchedulerContext = createContext(null);

export function SchedulerContextProvider({ children }) {
  const snackbarContext = useContext(SnackbarContext);
  const taskCount = useRef(0);
  const savingCurrent = useRef(false);
  const scheduleUpdateTask = (
    saveMethod,
    params,
    newData,
    queryParams,
    successMessage,
    afterSaving
  ) => {
    queueScheduler.schedule(async () => {
      await saveMethod(...params, newData, queryParams)
        .then(() => {
          taskCount.current -= 1;
          snackbarContext.setSnackbar({
            message: successMessage,
            severity: "success",
            open: true,
          });
          if (afterSaving) afterSaving();
        })
        .catch((err) => {
          taskCount.current -= 1;
          snackbarContext.setSnackbar({
            message: err.response.data.message || "Error, try again later.",
            severity: "error",
            open: true,
          });
        });
    });
  };

  const contextValue = useMemo(
    () => ({
      scheduleUpdateTask,
      taskCount,
      savingCurrent,
    }),
    [taskCount, savingCurrent]
  );

  return (
    <SchedulerContext.Provider value={contextValue}>
      {children}
    </SchedulerContext.Provider>
  );
}
