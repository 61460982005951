import React from "react";
import { Box, Typography, Pagination } from "@mui/material";
import { klassFormDivider, klassFormTitle } from "./KlassForm.styles";
import StudentListForm from "../StudentListForm";
import { paginationContent } from "../sharedStyles";

export default function Students({
  schoolId,
  students,
  totalPages,
  page,
  setPage,
  selectedStudents,
  setSelectedStudents,
  activeKlass,
  removedStudents,
  setRemovedStudents,
  studentsToEnroll,
  setStudentsToEnroll,
  unenrolledStudents,
}) {
  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleCheckboxClick = (event, name) => {
    const selectedStudentsIndex = selectedStudents.indexOf(name);
    let newSelected = [];

    if (selectedStudentsIndex === -1) {
      newSelected = newSelected.concat(selectedStudents, name);
    } else if (selectedStudentsIndex === 0) {
      newSelected = newSelected.concat(selectedStudents.slice(1));
    } else if (selectedStudentsIndex === selectedStudents.length - 1) {
      newSelected = newSelected.concat(selectedStudents.slice(0, -1));
    } else if (selectedStudentsIndex > 0) {
      newSelected = newSelected.concat(
        selectedStudents.slice(0, selectedStudentsIndex),
        selectedStudents.slice(selectedStudentsIndex + 1)
      );
    }

    setSelectedStudents(newSelected);
  };

  const isSelected = (id) => selectedStudents.indexOf(id) !== -1;

  const remove = (id) => {
    const selected = new Set(removedStudents);

    if (selected.has(id)) {
      selected.delete(id);
    } else {
      selected.add(id);
    }

    if (selected.size !== removedStudents.size) {
      setRemovedStudents(Array.from(selected));
    }
  };

  const enroll = (id) => {
    const selected = new Set(studentsToEnroll);

    if (selected.has(id)) {
      selected.delete(id);
    } else {
      selected.add(id);
    }

    if (selected.size !== studentsToEnroll.size) {
      setStudentsToEnroll(Array.from(selected));
    }
  };

  return (
    <>
      <Box sx={klassFormDivider}>
        <Typography sx={klassFormTitle}>Students</Typography>
        <StudentListForm
          schoolId={schoolId}
          students={students}
          totalPages={totalPages}
          page={page}
          activeKlass={activeKlass}
          remove={remove}
          isSelected={isSelected}
          handleCheckboxClick={handleCheckboxClick}
          handleChangePage={handleChangePage}
          removedStudents={removedStudents}
        />

        <Pagination
          count={totalPages}
          onChange={handleChangePage}
          sx={paginationContent}
          page={parseInt(page, 10)}
        />
      </Box>
      {unenrolledStudents.length > 0 && (
        <Box sx={klassFormDivider}>
          <Typography sx={klassFormTitle}>Unenrolled Students</Typography>
          <StudentListForm
            schoolId={schoolId}
            students={unenrolledStudents}
            totalPages={totalPages}
            page={page}
            activeKlass={activeKlass}
            remove={remove}
            isSelected={isSelected}
            handleCheckboxClick={handleCheckboxClick}
            handleChangePage={handleChangePage}
            removedStudents={removedStudents}
            enroll={enroll}
            isUnenrolledList
            studentsToEnroll={studentsToEnroll}
          />

          <Pagination
            count={totalPages}
            onChange={handleChangePage}
            sx={paginationContent}
            page={parseInt(page, 10)}
          />
        </Box>
      )}
    </>
  );
}
