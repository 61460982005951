import { tableCellClasses } from "@mui/material";

const tableStyles = {
  minWidth: 650,
  [`& .${tableCellClasses.root}`]: {
    borderBottom: "none",
  },
  "& .MuiTableCell-head": {
    color: "#000000",
    fontSize: 12,
    fontWeight: "700 !important",
    textTransform: "uppercase",
  },
  "& .MuiTableCell-body": {
    color: "black",
    fontSize: 16,
  },
  "& .MuiTableCell-root:nth-child(1)": {
    width: "4%",
  },
  "& .MuiTableCell-root:nth-child(2)": {
    width: "50%",
  },
  "& .MuiTableCell-root:nth-child(3)": {
    width: "14%",
  },
  "& .MuiTableCell-root:nth-child(4)": {
    width: "16%",
    whiteSpace: "nowrap",
  },
  "& .MuiTableRow-root:first-child .MuiTableCell-body": {
    paddingTop: 0,
  },
};
const primaryIcon = {
  ml: "25px",
  fontSize: "20px",
};
const guardianDrawer = {
  "& .MuiPaper-root": {
    backgroundColor: "white",
    width: "800px",
  },
};
export { tableStyles, primaryIcon, guardianDrawer };
