const portfolioTableHead = (isVisible, isActive = false) => ({
  borderBottom: "none",
  display: isVisible ? "table-cell" : "none",
  color: isActive ? "black" : "#808080",
  cursor: "pointer",
  padding: 0,
  userSelect: "none",
  "& p": {
    fontSize: "14px",
    display: "flex",
    alignItems: "end",
    justifyContent: "flex-start",
  },
});

const portfolioTableCell = (isVisible) => ({
  display: isVisible ? "table-cell" : "none",
  color: "black",
  px: 0,
  cursor: "pointer",
});

export { portfolioTableHead, portfolioTableCell };
