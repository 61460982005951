import { tableCellClasses, tableRowClasses } from "@mui/material";

const tableStyles = {
  [`& .${tableCellClasses.root}`]: {
    borderBottom: "none",
  },
  [`& .${tableRowClasses.root}`]: {
    borderBottom: "1px solid #d9d9d9",
  },
  "& .MuiTableRow-head": {
    borderBottom: "none",
    "& .MuiTableCell-head": {
      color: "#8f8e8e",
      textTransform: "uppercase",
      fontWeight: 400,
      fontSize: 11,
    },
  },
  "& .MuiTableCell-body": {
    color: "black",
    fontSize: 14,
  },
  //   "& .MuiTableCell-root:nth-child(1)": {
  //     width: "10%",
  //   },
  //   "& .MuiTableCell-root:nth-child(2)": {
  //     width: "5%",
  //   },
  //   "& .MuiTableCell-root:nth-child(3)": {
  //     width: "10%",
  //   },
  //   "& .MuiTableCell-root:nth-child(4)": {
  //     width: "10%",
  //   },
  //   "& .MuiTableCell-root:nth-child(5)": {
  //     width: "10%",
  //   },
  //   "& .MuiTableCell-root:nth-child(6)": {
  //     width: "37.5%",
  //   },
  //   "& .MuiTableCell-root:nth-child(7)": {
  //     width: "7.5%",
  //   },
};

const processesButtonGroup = (activeProcess, isFirst, isLast) => ({
  backgroundColor: activeProcess ? "#2196F3" : "white",
  color: activeProcess ? "white" : "#2196F3",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#2196F3",
    color: "white",
  },
  borderTopLeftRadius: isFirst ? "15px" : "0",
  borderBottomLeftRadius: isFirst ? "15px" : "0",
  borderTopRightRadius: isLast ? "15px" : "0",
  borderBottomRightRadius: isLast ? "15px" : "0",
});

export { tableStyles, processesButtonGroup };
