import React from "react";
import { Stack, Typography, Box } from "@mui/material";
import {
  classSchedulesRowPeriod,
  classSchedulesRowTitle,
  classSchedulesRowContainer,
  classSchedulesRowTime,
  classSchedulesRowItem,
} from "./ClassSchedulesRow.styles";
import moment from "../../../../utils/constants/momentConfig";
import dateTimeFormats from "../../../../utils/constants/dateTimeFormats";

export default function ClassSchedulesRow({ period, selectedClass }) {
  return (
    <Stack direction="row" sx={classSchedulesRowContainer}>
      <Stack direction="row" sx={classSchedulesRowPeriod}>
        <Typography sx={classSchedulesRowTitle}>{period.name}</Typography>
        <Box sx={classSchedulesRowTime}>
          <Typography>
            {moment(period?.start_time).utc().format(dateTimeFormats.TIME)}
          </Typography>
          <Typography>
            {moment(period?.end_time).utc().format(dateTimeFormats.TIME)}
          </Typography>
        </Box>
      </Stack>
      {period.klasses.map(
        (klass) =>
          (klass.id === selectedClass || !selectedClass) && (
            <Box key={klass.id} sx={classSchedulesRowItem}>
              <Typography>{`${klass.name} (${klass.abbreviation})`}</Typography>
              <Typography>{klass.teacher}</Typography>
            </Box>
          )
      )}
    </Stack>
  );
}
