import React, { useContext, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import Field from "./Field";
import customFieldsModelMap from "./CustomFieldRenderer/FieldModels/customFieldsModelMap";
import customFieldsService from "../../../service/customFieldsService";
import { SnackbarContext } from "../../../context/SnackbarContext";

export default function Fields({ responseType, sectionType, _fields = [] }) {
  const [fields, setFields] = useState(_fields);
  const [accordionState, setAccordionState] = useState(null);
  const snackbarContext = useContext(SnackbarContext);
  const [elementHeight, setElementHeight] = useState(0);

  useEffect(() => {
    setFields(_fields);
  }, [_fields.length]);

  const addFields = (type) => {
    const updatedFields = [...fields];
    const newField = new customFieldsModelMap[type]();
    newField.sectionType = sectionType;
    newField.responseType = responseType;
    newField.order = fields.length + 1;
    updatedFields.push(newField);
    setFields(updatedFields);
  };

  const deleteField = async (index, id) => {
    if (id) {
      await customFieldsService.deleteField(id);
      snackbarContext.setSnackbar({
        message: "Field deleted successfully",
        severity: "success",
        open: true,
      });
    }
    const updatedFields = [...fields];
    updatedFields.splice(index, 1);
    setFields(updatedFields);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);

    const startItem = result[startIndex];
    const endItem = result[endIndex];

    const tempOrder = startItem.order;
    startItem.order = endItem.order;
    endItem.order = tempOrder;

    result[startIndex] = endItem;
    result[endIndex] = startItem;

    return result;
  };

  const onDragEnd = (result) => {
    const newFields = reorder(
      fields,
      result.source.index,
      result.destination.index
    );
    customFieldsService.updateFieldsOrder({
      section_type: sectionType,
      updating_fields: newFields
        .filter((f) => f.id)
        .map((f) => ({ id: f.id, order: f.order })),
    });
    setFields(newFields);
  };

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={sectionType}>
          {(provided) => (
            <Box
              sx={{
                ".Mui-expanded": {
                  borderRadius: "15px",
                  borderTopLeftRadius: "15px !important",
                  borderTopRightRadius: "15px !important",
                  boxShadow: "0px 0px 20px -10px grey",
                },
              }}
              className="container"
              minHeight={elementHeight * fields.length}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {fields.map((field, index) => (
                <Field
                  key={field.fieldId}
                  index={index}
                  fieldProp={field}
                  deleteField={deleteField}
                  accordionState={accordionState}
                  setAccordionState={setAccordionState}
                  setElementHeight={setElementHeight}
                />
              ))}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
      <div>
        <Accordion
          sx={{
            boxShadow: "none",
            width: "fit-content",
            mt: 1,
          }}
          expanded={accordionState === `${sectionType}_add_field`}
        >
          <AccordionSummary
            sx={{
              p: 0,
              boxShadow: "none !important",
              ".MuiAccordionSummary-content": { boxShadow: "none" },
            }}
            onClick={() =>
              setAccordionState(
                accordionState === `${sectionType}_add_field`
                  ? null
                  : `${sectionType}_add_field`
              )
            }
          >
            <Button
              sx={{
                textTransform: "none",
                color: "#2196F3",
              }}
              startIcon={<Add />}
            >
              Add New Field
            </Button>
          </AccordionSummary>
          <AccordionDetails>
            <Button
              sx={{
                textTransform: "none",
                width: "max-content",
                display: "block",
              }}
              onClick={() => addFields("short-text")}
            >
              <Typography width="100%" align="left">
                Text
              </Typography>
            </Button>
            <Button
              sx={{
                textTransform: "none",
                width: "max-content",
                display: "block",
              }}
              onClick={() => addFields("number-field")}
            >
              <Typography width="100%" align="left">
                Number
              </Typography>
            </Button>
            <Button
              sx={{
                textTransform: "none",
                width: "max-content",
                display: "block",
              }}
              onClick={() => addFields("date-field")}
            >
              <Typography width="100%" align="left">
                Date
              </Typography>
            </Button>
            <Button
              sx={{
                textTransform: "none",
                width: "max-content",
                display: "block",
              }}
              onClick={() => addFields("dropdown-list")}
            >
              <Typography width="100%" align="left">
                Dropdown List
              </Typography>
            </Button>
            <Button
              sx={{
                textTransform: "none",
                width: "max-content",
                display: "block",
              }}
              onClick={() => addFields("radio-button-list")}
            >
              <Typography width="100%" align="left">
                Radio List
              </Typography>
            </Button>
            <Button
              sx={{
                textTransform: "none",
                width: "max-content",
                display: "block",
              }}
              onClick={() => addFields("checkbox-field")}
            >
              <Typography width="100%" align="left">
                Checkbox
              </Typography>
            </Button>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}
