const accountVerticalContainer = {
  borderRight: 1,
  borderColor: "divider",
};
const profilePicContainer = {
  textAlign: "center",
  position: "relative",
  display: "flex",
  justifyContent: "center",
};
const academicsImg = {
  width: "100%",
  height: "inherit",
  objectFit: "cover",
  backgroundColor: "black",
};
const fieldsContainer = {
  paddingTop: "45px",
  marginLeft: "24px",
};
const replaceButton = {
  position: "absolute",
  bottom: "15%",
  color: "#ededed",
  borderColor: "#ededed",
  paddingX: "1.5rem",
  borderRadius: "0.4rem",
  textTransform: "capitalize",
};

export {
  accountVerticalContainer,
  profilePicContainer,
  academicsImg,
  fieldsContainer,
  replaceButton,
};
