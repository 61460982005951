import DeleteIcon from "@mui/icons-material/Delete";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import React from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { ArrowCircleUpOutlined } from "@mui/icons-material";
import {
  accordionStyle,
  accordionSummaryStyle,
  alignIcon,
  buttons,
} from "./DayTemplatesForm.styles";
import Periods from "./Periods";
import { requiredMessage, renderError } from "../../../utils/constants/forms";

export default function Tracks({
  index,
  value,
  remove,
  errors,
  validationError,
}) {
  const { register, getValues } = useFormContext({});

  const {
    fields,
    remove: removePeriod,
    append: appendPeriod,
  } = useFieldArray({
    name: `tracks[${index}].periods`,
  });

  const removeButtonDisabled = () => {
    const periods = getValues(`tracks[${index}].periods`);
    return periods?.some(
      (period) => period?.klass_schedule_details?.length > 0
    );
  };

  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Accordion
      elevation={0}
      sx={accordionStyle}
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
    >
      <AccordionSummary
        expandIcon={
          expanded ? (
            <ArrowCircleUpOutlined fontSize="large" />
          ) : (
            <ArrowCircleLeftOutlinedIcon fontSize="large" />
          )
        }
        sx={accordionSummaryStyle}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Track Name*"
              {...register(`tracks.${index}.name`, {
                required: requiredMessage,
              })}
              error={!!errors?.tracks?.[index]?.name}
              fullWidth
              autoComplete="off"
              defaultValue={value?.name}
            />
            {errors?.tracks?.[index]?.name &&
              renderError(errors?.tracks?.[index]?.name?.message)}
          </Grid>
          <Grid item xs={1} sx={alignIcon}>
            <IconButton
              size="large"
              disabled={removeButtonDisabled()}
              onClick={() => remove(index)}
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ paddingX: 0 }}>
        {fields.map((field, nestedIndex) => (
          <Periods
            key={field.id}
            index={index}
            nestedIndex={nestedIndex}
            periodValue={field}
            remove={removePeriod}
            errors={errors}
            validationError={validationError}
          />
        ))}
        <Grid container sx={{ textAlign: "right" }}>
          <Grid item xs={12}>
            <Button
              onClick={() =>
                appendPeriod({
                  track: value?.name,
                  name: "",
                  start_time: "",
                  end_time: "",
                })
              }
              sx={buttons}
            >
              Add Period
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
