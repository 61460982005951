import React from "react";
import { useFormContext } from "react-hook-form";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import {
  assignmentCategoryRow,
  assignmentInputErrorColumn,
  assignmentInputFormControl,
  assignmentInputRow,
  assignmentKlassRow,
  assignmentMaxErrorColumn,
  assignmentMaxFormControl,
  assignmentCategoryErrorColumn,
} from "./KlassRow.styles";
import { bgWhite, font12, font16 } from "../sharedStyles";
import {
  assignmentsFormKlassesCheckbox,
  assignmentsFormKlassesOptionsCheckbox,
} from "./Klasses.styles";
import ErasableTextField from "../ErasableTextField";
import { renderError } from "../../utils/constants/forms";

export default function AddAssignmentRow({
  klass,
  categories,
  affectsGrade,
  setAffectsGrade,
  selectedKlasses,
  setSelectedKlasses,
  selectedInputAs,
  setSelectedInputAs,
  hasCategoryError,
  hasInputAsError,
  hasMaxError,
}) {
  const {
    register,
    getValues,
    formState: { errors },
  } = useFormContext();

  const isSelected = (id) => selectedKlasses.indexOf(id) !== -1;

  const handleCheckboxClick = (event, name) => {
    const selectedKlassesIndex = selectedKlasses.indexOf(name);
    let newSelected = [];

    if (selectedKlassesIndex === -1) {
      newSelected = newSelected.concat(selectedKlasses, name);
    } else if (selectedKlassesIndex === 0) {
      newSelected = newSelected.concat(selectedKlasses.slice(1));
    } else if (selectedKlassesIndex === selectedKlasses.length - 1) {
      newSelected = newSelected.concat(selectedKlasses.slice(0, -1));
    } else if (selectedKlassesIndex > 0) {
      newSelected = newSelected.concat(
        selectedKlasses.slice(0, selectedKlassesIndex),
        selectedKlasses.slice(selectedKlassesIndex + 1)
      );
    }

    setSelectedKlasses(newSelected);
  };

  return (
    <>
      <TableRow
        hover
        tabIndex={-1}
        key={klass.id}
        sx={assignmentKlassRow(getValues(`klasses.klass-${klass.id}.checked`))}
      >
        <TableCell padding="none" sx={assignmentsFormKlassesCheckbox}>
          <Checkbox
            onClick={(e) => handleCheckboxClick(e, klass.id)}
            color="primary"
            checked={isSelected(klass.id)}
            {...register(`klasses.klass-${klass.id}.checked`, {})}
          />
        </TableCell>
        <TableCell padding="none">
          <Typography
            sx={font16}
          >{`${klass.name} (${klass.abbreviation})`}</Typography>
        </TableCell>
        <TableCell padding="none" sx={assignmentsFormKlassesOptionsCheckbox}>
          {getValues(`klasses.klass-${klass.id}.checked`) && (
            <>
              <Checkbox
                color="primary"
                checked={affectsGrade}
                {...register(`klasses.klass-${klass.id}.affectsGrade`, {
                  onChange: () => setAffectsGrade(!affectsGrade),
                })}
              />
              <Typography sx={font16}>Does not affect grade</Typography>
            </>
          )}
        </TableCell>
      </TableRow>
      {getValues(`klasses.klass-${klass.id}.checked`) && (
        <TableRow
          tabIndex={-1}
          sx={assignmentCategoryRow(
            hasMaxError() || hasCategoryError() || hasInputAsError()
          )}
        >
          <TableCell padding="none" />
          <TableCell padding="none">
            {categories.length > 0 && (
              <FormControl
                fullWidth
                error={errors[`klasses.klass-${klass.id}.category`] && true}
              >
                <InputLabel
                  id={`klasses.klass-${klass.id}.category`}
                  shrink
                  sx={bgWhite}
                >
                  Category*
                </InputLabel>
                <Select
                  labelId={`klasses.klass-${klass.id}.category`}
                  id={`klasses.klass-${klass.id}.category`}
                  defaultValue={categories[0].id}
                  {...register(`klasses.klass-${klass.id}.category`, {
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  })}
                >
                  {categories.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.category}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </TableCell>
          <TableCell padding="none" sx={assignmentInputRow}>
            <FormControl
              fullWidth
              sx={assignmentInputFormControl}
              error={errors[`klasses.klass-${klass.id}.inputAs`] && true}
            >
              <InputLabel
                id={`klasses.klass-${klass.id}.inputAs`}
                shrink
                sx={bgWhite}
              >
                Input as*
              </InputLabel>
              <Select
                labelId={`klasses.klass-${klass.id}.inputAs`}
                id={`klasses.klass-${klass.id}.inputAs`}
                defaultValue={klass.defaultGrading}
                disabled={klass.maxPointsAsWeight}
                {...register(`klasses.klass-${klass.id}.inputAs`, {
                  required: {
                    message: "This field is required.",
                  },
                  onChange: () => {
                    setSelectedInputAs(
                      getValues(`klasses.klass-${klass.id}.inputAs`)
                    );
                  },
                })}
              >
                <MenuItem value="points">Points</MenuItem>
                <MenuItem value="percent">Percentage</MenuItem>
                <MenuItem value="letter_grade">Letter Grade</MenuItem>
              </Select>
            </FormControl>

            {(selectedInputAs === "points" ||
              (klass.defaultGrading === "points" && !selectedInputAs)) && (
              <FormControl
                fullWidth
                sx={assignmentMaxFormControl}
                error={errors[`klasses.klass-${klass.id}.max`] && true}
              >
                <ErasableTextField
                  id={`klasses.klass-${klass.id}.max`}
                  label="Max*"
                  fullWidth
                  hasError={hasMaxError()}
                  isRequired
                  type="text"
                  defaultValue={klass.defaultMaxScore}
                />
              </FormControl>
            )}
          </TableCell>
          <TableCell>
            {(selectedInputAs === "points" && klass.maxPointsAsWeight) ||
              (klass.defaultGrading === "points" &&
                !selectedInputAs &&
                klass.maxPointsAsWeight && (
                  <>
                    <FormControl fullWidth sx={{ font12 }}>
                      <FormControlLabel
                        control={<Checkbox defaultChecked disabled />}
                        label={
                          <Typography>
                            Use max points as weight calculation
                          </Typography>
                        }
                      />
                    </FormControl>

                    {errors?.maxPointsAsWeight &&
                      renderError(errors?.maxPointsAsWeight.message)}
                  </>
                ))}
          </TableCell>
        </TableRow>
      )}
      {(hasMaxError() || hasCategoryError() || hasInputAsError()) && (
        <TableRow tabIndex={-1}>
          <TableCell padding="none" />
          <TableCell padding="none">
            <Box sx={assignmentCategoryErrorColumn}>
              {hasCategoryError() &&
                renderError(
                  errors?.klasses?.[`klass-${klass.id}`]?.category?.message
                )}
            </Box>
          </TableCell>
          <TableCell padding="none">
            <Box sx={assignmentInputErrorColumn}>
              {hasInputAsError() &&
                renderError(
                  errors?.klasses?.[`klass-${klass.id}`]?.inputAs?.message
                )}
            </Box>
            <Box sx={assignmentMaxErrorColumn}>
              {hasMaxError() &&
                renderError(
                  errors?.klasses?.[`klass-${klass.id}`]?.max.message
                )}
            </Box>
          </TableCell>
          <TableCell />
        </TableRow>
      )}
    </>
  );
}
