import { tableCellClasses, tableRowClasses } from "@mui/material";

const tableStyles = {
  width: "100%",
  [`& .${tableCellClasses.root}`]: {
    borderBottom: "none",
  },
  [`& .${tableRowClasses.root}`]: {
    borderBottom: "1px solid #d9d9d9",
  },
  "& .MuiTableRow-head": {
    borderBottom: "none",
  },
  "& .MuiTableCell-head": {
    color: "#000000",
    fontSize: 11,
    textTransform: "uppercase",
    opacity: "50%",
  },
  "& .MuiTableCell-body": {
    color: "black",
    fontSize: 14,
  },
  "& .MuiTableCell-root:nth-child(1)": {
    width: "50%",
  },
  "& .MuiTableCell-root:nth-child(2)": {
    textAlign: "center",
    width: "15%",
  },
  "& .MuiTableCell-root:nth-child(3)": {
    width: "15%",
  },
  "& .MuiTableCell-root:nth-child(4)": {
    textAlign: "right",
    width: "20%",
  },
};

export default tableStyles;
