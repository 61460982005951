const tableContainer = {
  padding: "80px",
  paddingTop: "20px",
};

const tableHead = {
  borderBottom: "1.5px solid #000000",
};

const tableHeadRow = {
  "& th": {
    fontWeight: 500,
    fontSize: "11px",
    lineHeight: "16px",
    letterSpacing: "-0.3px",
    color: "#000000",
    textTransform: "uppercase",
  },
};

const tableBodyRow = {
  borderBottom: "1.5px solid #BFC7CA",
  "& td": {
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "-0.25px",
    color: "#000000",
  },
};

const assignmentName = {
  cursor: "pointer",
  fontSize: "16px !important",
  lineHeight: "24px !important",
  letterSpacing: "-0.5px !important",
  width: "30%",
};

const noAssignments = {
  pt: "20px",
  display: "flex",
  justifyContent: "center",
};

const missingAssignments = {
  ...noAssignments,
  color: "#F50E0E",
};

const commentBox = {
  display: "flex",
  pl: "30px",
  pt: "10px",
};

const commentIcon = {
  mr: "10px",
  color: "#1976d2",
};

export {
  tableContainer,
  tableHead,
  tableHeadRow,
  tableBodyRow,
  assignmentName,
  noAssignments,
  missingAssignments,
  commentBox,
  commentIcon,
};
