import React, { createContext, useState, useMemo } from "react";

export const SnackbarContext = createContext({
  setSnackbar: () => {},
  snackbar: {},
});

export function SnackbarProvider({ children }) {
  const [snackbar, setSnackbar] = useState({
    message: "",
    severity: "success",
    open: false,
  });

  const handleSnackbarSet = ({
    message,
    open = false,
    severity = "success",
  }) => {
    setSnackbar({
      message,
      open,
      severity,
    });
  };

  const handleClose = () => {
    setSnackbar({
      message: "",
      open: false,
      severity: "success",
    });
  };

  const contextValue = useMemo(
    () => ({
      closeSnackbar: handleClose,
      setSnackbar: handleSnackbarSet,
      snackbar,
    }),
    [snackbar]
  );

  return (
    <SnackbarContext.Provider value={contextValue}>
      {children}
    </SnackbarContext.Provider>
  );
}
