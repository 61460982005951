import { generateGUID } from "../../../../../../../utils/constants/utils";
import Expression from "./Expression";
import { ConditionNodeType, LogicalOperator } from "./constants";

export default class ConditionNode {
  constructor(nodeType) {
    this.id = generateGUID();
    if (nodeType === ConditionNodeType.NODE) {
      this.logicalOperator = LogicalOperator.AND;
      this.type = ConditionNodeType.NODE;
      this.expression = new Expression();
    } else {
      this.logicalOperator = LogicalOperator.AND;
      this.type = ConditionNodeType.GROUP;
      this.conditionNodes = [new ConditionNode(ConditionNodeType.NODE)];
    }
  }
}
