import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import moment from "moment";
import admissionFormService from "../../../service/admissionFormService";
import tableStyles from "./inquiries.style";
import { submitButton } from "../Setup/FormBuilder/FormBuilder.style";
import dateTimeFormats from "../../../utils/constants/dateTimeFormats";
import admissionProcessService from "../../../service/admissionProcessService";
import { SnackbarContext } from "../../../context/SnackbarContext";
import { QuickBarContext } from "../../../context/QuickBarContext";
import { cursorPointer } from "../../sharedStyles";
import { CommonContext } from "../../../context/CommonContext";
import DeleteFormResponse from "../DeleteFormResponse";

export default function Inquiries() {
  const navigate = useNavigate();
  const schoolId = useParams().school_id;
  const [formResponses, setFormResponses] = useState([]);
  const [processes, setProcesses] = useState([]);
  const snackbarContext = useContext(SnackbarContext);
  const quickBarContext = useContext(QuickBarContext);
  const commonContext = useContext(CommonContext);
  const [searchParams] = useSearchParams();

  const formattedParams = () => {
    let paramsUrl = "?";
    if (searchParams.get("school_year")) {
      paramsUrl = paramsUrl.concat(
        `&school_year=${searchParams.get("school_year")}`
      );
    }

    return paramsUrl;
  };

  const navigateToInProcess = () => {
    navigate(
      `/school/${schoolId}/admissions/students/in-process${formattedParams()}`
    );
  };

  const createProcessInstance = (formResponseId, processId) => {
    admissionProcessService
      .createProcessInstance(formResponseId, processId)
      .then(() => {
        navigateToInProcess();
        snackbarContext.setSnackbar({
          message: "Process started.",
          severity: "success",
          open: true,
        });
        commonContext.fireProcessStarted();
      })
      .catch(() => {
        snackbarContext.setSnackbar({
          message: "Failed to start process.",
          severity: "error",
          open: true,
        });
      });
  };

  const fetchInquiries = () => {
    admissionFormService
      .getInquiryFormResponses(searchParams.get("school_year"))
      .then((response) => {
        setFormResponses(response.data);
      });
  };

  useEffect(() => {
    if (searchParams.get("school_year")) {
      fetchInquiries();
      admissionProcessService
        .fetchAllProcesses(searchParams.get("school_year"))
        .then((response) => {
          setProcesses(response.data);
        });
    }
  }, [searchParams.get("school_year")]);

  useEffect(() => {
    if (quickBarContext.fetchInquiries) {
      fetchInquiries();
      quickBarContext.cleanFetchInquiries();
    }
  }, [quickBarContext.fetchInquiries]);

  const formatDateTime = (str) => moment(str).format(dateTimeFormats.MMDDYYYY);

  const navigateTopDataView = (formResponse) => {
    let paramsUrl = formattedParams();
    paramsUrl = paramsUrl.concat(`&form_response_id=${formResponse.id}`);
    paramsUrl = paramsUrl.concat(`&form_id=${formResponse.admission_form_id}`);
    navigate(`/school/${schoolId}/data-view${paramsUrl}`);
  };

  return (
    <Grid container mt={5}>
      <TableContainer>
        <Table sx={tableStyles} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell>STUDENT NAME</TableCell>
              <TableCell align="center">GRADE</TableCell>
              <TableCell>CREATED</TableCell>
              <TableCell>PARENT NAME 1</TableCell>
              <TableCell>PARENT NAME 2</TableCell>
              <TableCell>School Year</TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {formResponses.map((row) => (
              <TableRow key={row.id}>
                <TableCell
                  sx={cursorPointer}
                  onClick={() => navigateTopDataView(row)}
                  component="th"
                  scope="row"
                >
                  {`${row["student last name"]}, ${row["student first name"]}`}
                </TableCell>
                <TableCell
                  sx={cursorPointer}
                  onClick={() => navigateTopDataView(row)}
                  align="center"
                >
                  {row["grade level"]}
                </TableCell>
                <TableCell
                  sx={cursorPointer}
                  onClick={() => navigateTopDataView(row)}
                >
                  {formatDateTime(row.created_at)}
                </TableCell>
                <TableCell
                  sx={cursorPointer}
                  onClick={() => navigateTopDataView(row)}
                >
                  {`${row["parent first name"]} ${row["parent last name"]}`}
                </TableCell>
                <TableCell
                  sx={cursorPointer}
                  onClick={() => navigateTopDataView(row)}
                >
                  -
                </TableCell>
                <TableCell
                  sx={cursorPointer}
                  onClick={() => navigateTopDataView(row)}
                >
                  {row["school year"]}
                </TableCell>
                <TableCell>
                  <FormControl sx={{ width: "45%", float: "right" }}>
                    <InputLabel id="processLabel">Process</InputLabel>
                    <Select
                      labelId="processLabel"
                      id="process"
                      label="Process"
                      defaultValue=""
                      onChange={(e) => {
                        // eslint-disable-next-line no-param-reassign
                        row.processId = e.target.value;
                      }}
                    >
                      {processes.map((process) => (
                        <MenuItem key={process.id} value={process.id}>
                          <Typography textAlign="left">
                            {process.name}
                          </Typography>
                        </MenuItem>
                      ))}
                      {!processes.length && (
                        <MenuItem key="select process" disabled>
                          <Typography textAlign="left">
                            Please create process
                          </Typography>
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell>
                  <Button
                    sx={submitButton}
                    onClick={() => {
                      createProcessInstance(row.id, row.processId);
                    }}
                  >
                    Start
                  </Button>
                </TableCell>
                <TableCell>
                  <DeleteFormResponse
                    formResponseId={row.id}
                    typeDeleteToConfirm={false}
                  >
                    <IconButton>
                      <DeleteIcon />
                    </IconButton>
                  </DeleteFormResponse>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
