import React from "react";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Box,
  Grid,
  AccordionSummary,
  AccordionDetails,
  Accordion,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import theme from "../../utils";
import { fw600, pt0, px0 } from "../sharedStyles";

const navigationListGrid = (borderRight) => ({
  color: "white",
  borderRight: borderRight ? 1 : 0,
  borderColor: "white",
});

const navigationListAccordion = {
  backgroundColor: theme.palette.primary.main,
  boxShadow: "none",
  color: "white",
};

const navigationExpandMoreIcon = { color: theme.palette.text.simpleCard };

function NavigationList({ list, pl = 0, borderRight = false }) {
  return (
    <Grid
      item
      xs={12}
      sm={2}
      pl={pl}
      mt={3}
      sx={navigationListGrid(borderRight)}
    >
      <Accordion sx={navigationListAccordion} defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={navigationExpandMoreIcon} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ width: "13vw" }}
        >
          <Box sx={fw600}>{list.header}</Box>
        </AccordionSummary>
        <List sx={pt0}>
          <AccordionDetails sx={pt0}>
            {list.menu.map((menuItem) => (
              <Box key={`${list.header}${menuItem.label}`}>
                <ListItem disablePadding>
                  <ListItemButton dense sx={px0}>
                    <ListItemText primary={menuItem.label} />
                  </ListItemButton>
                </ListItem>
              </Box>
            ))}
          </AccordionDetails>
        </List>
      </Accordion>
      {/* { list.header == "Tasks" && <Divider orientation="vertical" color="#ffffff" /> } */}
    </Grid>
  );
}

export default NavigationList;
