import React from "react";
import { useFormContext } from "react-hook-form";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
  Stack,
} from "@mui/material";
import { m0, mx5 } from "../../../sharedStyles";

function NotificationCheckmarkSelect({ label, currentGuardian }) {
  const { register } = useFormContext();

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Typography>{label}</Typography>
      <Stack direction="row">
        <FormGroup sx={mx5}>
          <FormControlLabel
            sx={m0}
            control={
              <Checkbox
                defaultChecked={currentGuardian.email_notifications}
                {...register("email_notifications")}
              />
            }
          />
        </FormGroup>
        <FormGroup sx={mx5}>
          <FormControlLabel
            sx={m0}
            control={
              <Checkbox
                defaultChecked={currentGuardian.text_notifications}
                {...register("text_notifications")}
              />
            }
          />
        </FormGroup>
      </Stack>
    </Stack>
  );
}

export default NotificationCheckmarkSelect;
