const klassFormStudentsAvatar = {
  width: "50px",
};

const klassFormStudentsCheckbox = {
  width: "46px",
};

const klassFormStudentsTable = {
  "& th": {
    color: "black",
    borderBottom: "none",
  },
  "& td": {
    color: "black",
    py: "10px",
  },
};

const rowStyle = (removed = false) => ({
  color: removed ? "gray" : "inherit",
});

export {
  klassFormStudentsAvatar,
  klassFormStudentsCheckbox,
  klassFormStudentsTable,
  rowStyle,
};
