import { formHeader, formTitle, formButtons } from "../../sharedStyles";

const formHeaderAlert = {
  ...formHeader,
  backgroundColor: "red",
};

const formTitleAlert = {
  ...formTitle,
  color: "white",
};

const formButtonsAlert = {
  ...formButtons,
  color: "white",
  borderColor: "white",
};

export { formHeaderAlert, formTitleAlert, formButtonsAlert };
