import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Pagination,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { useForm } from "react-hook-form";
import { Link, useParams, useSearchParams } from "react-router-dom";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import {
  gridContainer,
  gridItemMessages,
  gridItemNewMessage,
  gridItemNewMessageButton,
  gridItemSearchBox,
  formTextField,
  iconButtonSearch,
  inboxRowCount,
  messagesAttachIcon,
  messagesRow,
  messagesRowDescription,
  messagesRowName,
  messagesRowTimestamp,
  newMessageIcon,
} from "./Messages.styles";
import { messagesUnreadIcon } from "../../../views/Messages.styles";
import {
  loader,
  mx25,
  paginationContent,
  textCenter,
  mr15,
} from "../../sharedStyles";
import { displayInline } from "../Home/Home.styles";
import { fetchStudentMessages } from "../../../service/studentPortalService";
import moment from "../../../utils/constants/momentConfig";
import dateTimeFormats from "../../../utils/constants/dateTimeFormats";
import SnackBarNotification from "../../SnackBarNotification";
import NewMessage from "./New";
import profilePictureIcon from "../../../utils/profilePictureIcon";

export default function StudentPortalMessages() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { register, handleSubmit, getValues, setValue } = useForm();
  const [showClearButton, setShowClearButton] = useState(
    searchParams.get("query") || false
  );
  const [messages, setMessages] = useState([]);
  const [query, setQuery] = useState(searchParams.get("query") || null);
  const [page, setPage] = useState(searchParams.get("page") || 1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const studentId = params.id;
  const schoolId = params.school_id;
  const [snackBarOpen, setSnackBarOpen] = useState({
    open: false,
    message: null,
  });
  const [reloadPage, setReloadPage] = useState(false);
  const [newMessageOpen, setNewMessageOpen] = React.useState(false);
  const handleModalOpen = () => setNewMessageOpen(true);
  const handleModalClose = () => setNewMessageOpen(false);

  useEffect(() => {
    (async () => {
      if (studentId && schoolId) {
        try {
          setLoading(true);
          const response = await fetchStudentMessages(studentId, {
            params: {
              school_id: schoolId,
              query,
              count: 10,
              page,
            },
          });
          if (response.data) {
            setLoading(false);
            setMessages(response.data);
            setTotalPages(response.pagination.total_pages);
          }
        } catch (e) {
          // handle catch
        }
      }
    })();
  }, [page, query, reloadPage]);

  const handleSearch = (data) => {
    searchParams.set("query", data.search.toLowerCase());
    setQuery(data.search.toLowerCase());
    // reset page
    searchParams.set("page", "1");
    setPage(1);
    setSearchParams(searchParams);
  };

  const handleQuickFindChange = (event) => {
    if (event.target.value) setShowClearButton(true);
    else setShowClearButton(false);
  };

  const handleClickOnClearButton = () => {
    setValue("search", "");
    setShowClearButton(false);
    handleSearch(getValues());
  };

  const handleChangePage = (event, value) => {
    setPage(value);
    searchParams.set("page", value);
    setSearchParams(searchParams);
  };

  function truncate(str) {
    const newStr = str.length > 100 ? `${str.substring(0, 100)}...` : str;
    return newStr.replace(/(<([^>]+)>)/gi, "").replaceAll("&nbsp;", "");
  }

  function formatDateTime(str) {
    return moment(str).format(dateTimeFormats.LLL);
  }

  function displayName(sender) {
    return `${sender.first_name} ${sender.last_name}`;
  }

  const cancelButtonStyle = {
    visibility: showClearButton ? "inherit" : "hidden",
  };

  const conversationKey = (message) =>
    message.conversation_key || message.original_message_conversation_key;

  if (loading) {
    return <CircularProgress sx={loader} size={100} />;
  }

  return (
    <>
      <Grid container spacing={2} sx={gridContainer}>
        <Grid item md={4} xs={4} sx={gridItemMessages}>
          <Box>Messages</Box>
        </Grid>
        <Grid item md={4} xs={4} sx={gridItemNewMessage}>
          <Button
            sx={gridItemNewMessageButton}
            variant="contained"
            endIcon={<SendOutlinedIcon sx={newMessageIcon} />}
            onClick={() => handleModalOpen()}
          >
            New Message
          </Button>
        </Grid>
        <Grid item md={4} xs={4}>
          <Box sx={gridItemSearchBox}>
            <form
              onSubmit={handleSubmit(handleSearch)}
              onChange={handleQuickFindChange}
            >
              <TextField
                sx={formTextField}
                label="Search messages"
                variant="standard"
                color="primary"
                defaultValue={searchParams.get("query")}
                {...register("search")}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickOnClearButton}
                        sx={cancelButtonStyle}
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                      <IconButton type="submit">
                        <SearchIcon
                          fontSize="large"
                          color="primary"
                          sx={iconButtonSearch}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </form>
          </Box>
        </Grid>
      </Grid>

      <Container maxWidth={false} variant="header">
        {messages.length > 0 ? (
          <>
            <Box>
              {messages.map((message) => {
                const shouldShowUnreadIcon =
                  !message.recent_message.read_at &&
                  message.recent_message.sender_id !== Number(studentId);
                return (
                  <Box sx={messagesRow} key={message.id}>
                    <Link
                      to={`/student/${studentId}/schools/${schoolId}/messages/${conversationKey(
                        message
                      )}`}
                    >
                      <Stack direction="row" justifyContent="space-between">
                        <Stack direction="row" alignItems="center">
                          {shouldShowUnreadIcon ? (
                            <CircleIcon sx={messagesUnreadIcon} />
                          ) : (
                            <Box sx={mr15} />
                          )}
                          <img
                            alt="profile_image"
                            src={profilePictureIcon(
                              message?.recent_message?.sender
                            )}
                            style={{
                              borderRadius: "100px",
                              width: "90px",
                              height: "90px",
                              objectFit: "cover",
                            }}
                          />
                          <Box sx={[mx25, displayInline]}>
                            <Typography sx={messagesRowName}>
                              {displayName(message.recent_message?.sender)}
                            </Typography>
                            <Typography sx={messagesRowDescription}>
                              {truncate(message.recent_message.description)}
                            </Typography>
                            <Typography sx={messagesRowTimestamp}>
                              {formatDateTime(
                                message.recent_message.created_at
                              )}
                            </Typography>
                          </Box>
                        </Stack>
                        <Stack direction="row" alignItems="center">
                          {message.has_attachments_in_thread && (
                            <AttachFileIcon sx={messagesAttachIcon} />
                          )}
                          <Avatar sx={inboxRowCount}>
                            {message.replies_count + 1}
                          </Avatar>
                        </Stack>
                      </Stack>
                    </Link>
                  </Box>
                );
              })}
            </Box>

            <Pagination
              count={totalPages}
              page={parseInt(page, 10)}
              onChange={handleChangePage}
              sx={paginationContent}
            />
          </>
        ) : (
          <Box sx={textCenter}>
            <Typography>No messages.</Typography>
          </Box>
        )}
      </Container>

      <Modal
        open={newMessageOpen}
        onClose={handleModalClose}
        aria-labelledby="new-message-title"
        aria-describedby="new-message-description"
      >
        <Box>
          <NewMessage
            handleModalClose={handleModalClose}
            setSnackBarOpen={setSnackBarOpen}
            setReloadPage={setReloadPage}
          />
        </Box>
      </Modal>

      <SnackBarNotification
        message={snackBarOpen.message}
        open={snackBarOpen.open}
        severity={snackBarOpen.severity}
        handleClose={() => setSnackBarOpen(false)}
      />
    </>
  );
}
