import axios from "../utils/axios-util";

const termGradesEndpoint = "/api/term_grades";

const fetchTermGrades = async (params) => {
  const { data } = await axios.get(termGradesEndpoint, params);

  if (!data.data) {
    console.error("Error fetching term grades from the API");
    return [];
  }

  return data.data;
};

const save = async (params) => {
  const { data } = await axios.post(`${termGradesEndpoint}`, params);

  if (!data.data) {
    console.error("Error fetching term grades from the API");
    return [];
  }
  return data;
};

const fetchCategoryScores = async (studentId, params) => {
  const { data } = await axios.get(
    `${termGradesEndpoint}/scores/${studentId}`,
    params
  );

  if (!data.data) {
    console.error("Error fetching term grades from the API");
    return [];
  }

  return data.data;
};

const saveGrades = async (grades) => {
  await axios.post(`${termGradesEndpoint}/grades`, grades);
};

export default {
  fetchTermGrades,
  save,
  saveGrades,
  fetchCategoryScores,
};
