import React, { useContext } from "react";
import { Snackbar } from "@mui/material";
import Alert from "@mui/material/Alert";
import { SnackbarContext } from "../context/SnackbarContext";

export default function SimpleSnackbar() {
  const { snackbar, closeSnackbar } = useContext(SnackbarContext);

  return (
    <Snackbar
      open={snackbar.open}
      autoHideDuration={6000}
      onClose={closeSnackbar}
    >
      <Alert onClose={closeSnackbar} severity={snackbar.severity}>
        {snackbar.message}
      </Alert>
    </Snackbar>
  );
}
