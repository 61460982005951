import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  Drawer,
  Grid,
  IconButton,
  InputAdornment,
  Pagination,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import CircleIcon from "@mui/icons-material/Circle";
import { useForm } from "react-hook-form";
import { Link, useParams, useSearchParams } from "react-router-dom";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import {
  gridContainer,
  gridItemMessages,
  gridItemNewMessage,
  gridItemNewMessageButton,
  gridItemSearchBox,
  formTextField,
  iconButtonSearch,
} from "./Messages.styles";
import { messagesUnreadIcon } from "../../../views/Messages.styles";
import {
  inboxRowCount,
  messagesAttachIcon,
  messagesRow,
  messagesRowDescription,
  messagesRowName,
  messagesRowTimestamp,
} from "../Kids/Messages/Messages.styles";
import {
  loader,
  mx25,
  paginationContent,
  rightDrawerContainer,
  textCenter,
  mr15,
} from "../../sharedStyles";
import { displayInline } from "../Kids/Home/Home.styles";
import { fetchGuardianKidMessages } from "../../../service/guardiansService";
import NewMessage from "./New";
import SnackBarNotification from "../../SnackBarNotification";
import profilePictureIcon from "../../../utils/profilePictureIcon";
import { longShortDateTime } from "../../../utils/dateTimePresets";

export default function GuardianMessages() {
  const { t, i18n } = useTranslation("messages");
  const [searchParams, setSearchParams] = useSearchParams();
  const { register, handleSubmit, getValues, setValue } = useForm();
  const [showClearButton, setShowClearButton] = useState(
    searchParams.get("query") || false
  );
  const [messages, setMessages] = useState([]);
  const [query, setQuery] = useState(searchParams.get("query") || null);
  const [page, setPage] = useState(searchParams.get("page") || 1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const guardianId = params.id;
  const [messageDrawerStatus, setMessageDrawerStatus] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState({
    open: false,
    message: null,
  });
  const [reloadPage, setReloadPage] = useState(false);

  useEffect(() => {
    (async () => {
      if (guardianId) {
        try {
          setLoading(true);
          const response = await fetchGuardianKidMessages({
            params: {
              guardianId,
              query,
              count: 10,
              page,
              type: "guardian",
            },
          });
          if (response.data) {
            setLoading(false);
            setMessages(response.data);
            setTotalPages(response.pagination.total_pages);
          }
        } catch (e) {
          // handle catch
        }
      }
    })();
  }, [page, query, reloadPage]);

  const handleMessagesDrawer = (status) => {
    setMessageDrawerStatus(status);
  };

  const handleSearch = (data) => {
    searchParams.set("query", data.search.toLowerCase());
    setQuery(data.search.toLowerCase());
    // reset page
    searchParams.set("page", "1");
    setPage(1);
    setSearchParams(searchParams);
  };

  const handleQuickFindChange = (event) => {
    if (event.target.value) setShowClearButton(true);
    else setShowClearButton(false);
  };

  const handleClickOnClearButton = () => {
    setValue("search", "");
    setShowClearButton(false);
    handleSearch(getValues());
  };

  const handleChangePage = (event, value) => {
    setPage(value);
    searchParams.set("page", value);
    setSearchParams(searchParams);
  };

  function truncate(str) {
    const newStr = str.length > 100 ? `${str.substring(0, 100)}...` : str;
    return newStr.replace(/(<([^>]+)>)/gi, "").replaceAll("&nbsp;", "");
  }

  function displayName(sender) {
    return `${sender.first_name} ${sender.last_name}`;
  }

  const cancelButtonStyle = {
    visibility: showClearButton ? "inherit" : "hidden",
  };

  const conversationKey = (message) =>
    message.conversation_key || message.original_message_conversation_key;

  if (loading) {
    return <CircularProgress sx={loader} size={100} />;
  }

  return (
    <>
      <Grid container spacing={2} sx={gridContainer}>
        <Grid item md={8} xs={7} sx={gridItemMessages}>
          <Box>{t("messages")}</Box>
        </Grid>
        <Grid item md={2.6} xs={3}>
          <Box sx={gridItemSearchBox}>
            <form
              onSubmit={handleSubmit(handleSearch)}
              onChange={handleQuickFindChange}
            >
              <TextField
                sx={formTextField}
                label={t("searchMessages")}
                variant="standard"
                color="primary"
                defaultValue={searchParams.get("query")}
                {...register("search")}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickOnClearButton}
                        sx={cancelButtonStyle}
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                      <IconButton type="submit">
                        <SearchIcon
                          fontSize="large"
                          color="primary"
                          sx={iconButtonSearch}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </form>
          </Box>
        </Grid>
        <Grid item md={1.4} xs={2} sx={gridItemNewMessage}>
          <Button
            sx={gridItemNewMessageButton}
            variant="contained"
            startIcon={<EmailOutlinedIcon />}
            onClick={() => handleMessagesDrawer(true)}
          >
            {t("newMessage")}
          </Button>
        </Grid>
      </Grid>

      <Container maxWidth={false} variant="header">
        <Container>
          {messages.length > 0 ? (
            <>
              <Box>
                {messages.map((message) => {
                  const shouldShowUnreadIcon =
                    !message.recent_message.read_at &&
                    message.recent_message.sender_id !== Number(guardianId);

                  return (
                    <Box sx={messagesRow} key={message.id}>
                      <Link
                        to={`/guardian/${guardianId}/messages/${conversationKey(
                          message
                        )}`}
                      >
                        <Stack direction="row" justifyContent="space-between">
                          <Stack direction="row" alignItems="center">
                            {shouldShowUnreadIcon ? (
                              <CircleIcon sx={messagesUnreadIcon} />
                            ) : (
                              <Box sx={mr15} />
                            )}
                            <img
                              alt="profile_image"
                              src={profilePictureIcon(
                                message?.recent_message?.sender
                              )}
                              style={{
                                borderRadius: "100px",
                                width: "90px",
                                height: "90px",
                                objectFit: "cover",
                              }}
                            />
                            <Box sx={[mx25, displayInline]}>
                              <Typography sx={messagesRowName}>
                                {displayName(message.recent_message?.sender)}
                              </Typography>
                              <Typography sx={messagesRowDescription}>
                                {truncate(message.recent_message.description)}
                              </Typography>
                              <Typography sx={messagesRowTimestamp}>
                                {longShortDateTime(
                                  message.recent_message.created_at,
                                  { language: i18n.language }
                                )}
                              </Typography>
                            </Box>
                          </Stack>
                          <Stack direction="row" alignItems="center">
                            {message.has_attachments_in_thread && (
                              <AttachFileIcon sx={messagesAttachIcon} />
                            )}
                            <Avatar sx={inboxRowCount}>
                              {message.replies_count + 1}
                            </Avatar>
                          </Stack>
                        </Stack>
                      </Link>
                    </Box>
                  );
                })}
              </Box>

              <Pagination
                count={totalPages}
                page={parseInt(page, 10)}
                onChange={handleChangePage}
                sx={paginationContent}
              />
            </>
          ) : (
            <Box sx={textCenter}>
              <Typography>{t("noMessages")}</Typography>
            </Box>
          )}
        </Container>
      </Container>

      <Drawer anchor="right" open={messageDrawerStatus}>
        <Box sx={rightDrawerContainer}>
          <NewMessage
            handleMessagesDrawer={handleMessagesDrawer}
            setSnackBarOpen={setSnackBarOpen}
            setReloadPage={setReloadPage}
          />
        </Box>
      </Drawer>

      <SnackBarNotification
        message={snackBarOpen.message}
        open={snackBarOpen.open}
        severity={snackBarOpen.severity}
        handleClose={() => setSnackBarOpen(false)}
      />
    </>
  );
}
