import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function PaymentSuccessful() {
  const navigate = useNavigate();
  useEffect(() => {
    const redirectUrl = localStorage.getItem("redirectUrl");
    navigate(redirectUrl);
  }, []);
  return <>Payment Successful</>;
}
