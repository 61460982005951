const reportCardExplainFeature = {
  display: "flex",
  alignItems: "center",
  fontSize: "16px",
  color: "#7D5260",
};

const reportCardExplainFeatureOpen = {
  display: "flex",
  alignItems: "center",
  fontSize: "16px",
  color: "#5077A4",
};

const reportCardDistributeContainer = {
  padding: "20px 55px",
  color: "black",
};

const reportCardExplainFeatureBox = {
  borderBottom: "1px solid",
  borderColor: "#BFC7CA",
  cursor: "pointer",
};

const reportCardLinkText = { color: "#5077A4", marginRight: "15px" };

const reportCardLinksContainer = {
  display: "flex",
  justifyContent: "center",
  marginTop: "15px",
  fontSize: "10px",
  alignItems: "center",
};

const directionsIcon = {
  fontSize: "34px",
  color: "orange",
};

export {
  reportCardExplainFeature,
  reportCardDistributeContainer,
  reportCardExplainFeatureBox,
  reportCardExplainFeatureOpen,
  reportCardLinkText,
  reportCardLinksContainer,
  directionsIcon,
};
