import React, { useState, useContext, useRef } from "react";
import { TableCell, TextField } from "@mui/material";
import {
  termGradeSkillCellGrade,
  termGradeSkillCellSquare,
  termGradeTableInput,
} from "./TermGradeSkillRow.styles";
import { SnackbarContext } from "../../../context/SnackbarContext";
import studentSkillsService from "../../../service/studentSkillsService";
import { isNumber } from "../../../utils/constants/utils";
import PopoverComponent from "../../ToolTip/ToolTip";
import PartialValidCodes from "../../ToolTip/PartialValidCodes";
import ValidInputCodes from "../../ToolTip/ValidInputCodes";
import useFormattedMarkingCodes from "../../../hooks/useFormattedMarkingCodes";
import { PermissionsContext } from "../../../context/PermissionsContext";
import APP_PERMISSIONS from "../../../utils/constants/permissions";
import PERMISSION_TYPES from "../../../utils/constants/permission_types";
import GradeLockIcon from "../../GradeLockIcon";

export default function TermGradeSkillCell({
  gradingScale,
  skill,
  termGrade,
  studentScore,
  getStudentSkills,
  isPublished,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentTarget, setCurrentTarget] = useState(null);
  const [isBackspace, setBackspace] = useState(false);

  const timeout = useRef();
  const snackbarContext = useContext(SnackbarContext);
  const { hasPermission } = useContext(PermissionsContext);

  const managePermission = hasPermission(
    APP_PERMISSIONS.KLASSES_TERM_GRADES,
    PERMISSION_TYPES.MANAGE
  );

  const isLetterGrade = gradingScale?.name === "TK-K";
  const formattedMarkingCodes = useFormattedMarkingCodes(
    gradingScale?.marking_codes
  );
  const partialCode = PartialValidCodes(
    formattedMarkingCodes,
    null,
    isLetterGrade,
    true
  );
  const validCodes = ValidInputCodes(
    formattedMarkingCodes,
    null,
    isLetterGrade,
    true
  ).concat(partialCode);

  const shouldShowToolTip = (value) => {
    if (
      !isLetterGrade &&
      !validCodes.filter((a) => a === value.toLowerCase()).length > 0
    ) {
      return true;
    }

    if (
      isLetterGrade &&
      (isNumber(value) ||
        !validCodes.filter((a) => a === value.toLowerCase()).length > 0)
    ) {
      return true;
    }

    return false;
  };

  const handleKeyDown = (event) => {
    if (event.key === "Backspace") {
      setBackspace(true);
    }
  };

  const handleGradeChange = async (target, score) => {
    const cellTarget = target;
    setCurrentTarget(cellTarget);

    if (!isBackspace && shouldShowToolTip(score)) {
      cellTarget.value = "";
      setAnchorEl(cellTarget);
    }

    if (!shouldShowToolTip(score) || isBackspace) {
      clearTimeout(timeout.current);
      snackbarContext.setSnackbar({
        message: "Saving score...",
        severity: "info",
        open: true,
      });

      timeout.current = setTimeout(async () => {
        const enteredPartialCode = partialCode.includes(score.toLowerCase());

        if (enteredPartialCode) {
          setAnchorEl(cellTarget);
        } else {
          const postSkill = await studentSkillsService
            .createStudentSkill({
              klass_id: termGrade.klass_id,
              subject_id: termGrade.klass.subject_id,
              student_id: termGrade.student_id,
              skill_id: skill.id,
              score,
            })
            .catch((err) =>
              snackbarContext.setSnackbar({
                message: `Value ${err.response.data.errors.score[0]}`,
                severity: "error",
                open: true,
              })
            );

          if (postSkill) {
            snackbarContext.setSnackbar({
              message: "Grades saved successfully",
              severity: "success",
              open: true,
            });

            await getStudentSkills();
          }
        }
      }, 2000);
    }
  };

  const handleTooltipInput = (input) => {
    currentTarget.value = input;
    setAnchorEl(null);
    handleGradeChange(currentTarget, input.toString());
  };

  return (
    <TableCell
      key={gradingScale.id}
      sx={termGradeSkillCellGrade}
      align="center"
    >
      {isPublished() ? <GradeLockIcon /> : null}

      <TextField
        disabled={!managePermission || isPublished()}
        variant="standard"
        fullWidth
        sx={termGradeSkillCellSquare}
        defaultValue={studentScore}
        inputProps={{
          min: 0,
          sx: {
            ...termGradeTableInput,
          },
          className: `grading_input_${gradingScale.id} skill_input_${skill.id}`,
        }}
        /* eslint-disable-next-line react/jsx-no-duplicate-props */
        InputProps={{
          sx: {
            ...termGradeTableInput,
          },
          disableUnderline: true,
          id: `${termGrade.id}_${skill.id}`,
        }}
        onChange={(e) => handleGradeChange(e.currentTarget, e.target.value)}
        onKeyDown={(e) => {
          handleKeyDown(e);
        }}
        onKeyUp={() => setBackspace(false)}
      />
      <PopoverComponent
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        handleClick={handleTooltipInput}
        currentTarget={currentTarget}
        showSpecialMarks={false}
        specialMarks={null}
        markingCodes={gradingScale.marking_codes}
        skill={gradingScale}
        render
        isLetterGrade
      />
    </TableCell>
  );
}
