import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const addButton = (theme) => ({
  color: "#EDFAFF",
  padding: 2,
  textTransform: "none",
  borderRadius: theme.roundedButton.radius,
});

const addIcon = {
  marginRight: 1,
};

function AddButton({ children, onClick }) {
  const theme = useTheme();

  return (
    <Button
      variant="contained"
      size="large"
      sx={addButton(theme)}
      onClick={onClick}
    >
      <AddIcon sx={addIcon} />
      {children}
    </Button>
  );
}

export default AddButton;
