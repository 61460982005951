const admissionsContainer = {
  borderTop: "1px solid black",
  mx: "65px",
  mb: "150px",
};

const submitButton = {
  backgroundColor: "#2196F3",
  color: "white",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#2196F3",
  },
};

const searchAndSelectContainer = { display: "flex", alignItems: "end" };

export { admissionsContainer, submitButton, searchAndSelectContainer };
