import axios from "../utils/axios-util";

const schoolYearApplicationsEndpoint = "/api/school_year_applications";

const fetchAll = async (params) => {
  const { data } = await axios.get(schoolYearApplicationsEndpoint, params);

  if (!data.data) {
    console.error("Error fetching applications from API");
    return [];
  }
  return data;
};

const fetchById = async (schoolYearApplication) => {
  const { data } = await axios.get(
    `${schoolYearApplicationsEndpoint}/${schoolYearApplication}`,
    {
      params: { school_year_id: schoolYearApplication },
    }
  );
  if (!data.data) {
    console.error("Error fetching application from the API");
    return [];
  }
  return data;
};

const create = async (params) => {
  const { data } = await axios.post(schoolYearApplicationsEndpoint, params);
  if (!data.data) {
    console.error("Error creating new school year application");
    return [];
  }
  return data;
};

const update = async (application) => {
  const { data } = await axios.put(
    `${schoolYearApplicationsEndpoint}/${application.id}`,
    application
  );
  if (!data.data) {
    console.error("Error updating school year");
    return [];
  }
  return data;
};

export default {
  fetchAll,
  fetchById,
  create,
  update,
};
