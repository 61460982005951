import React, { useEffect, useState } from "react";
import { useOutletContext, useSearchParams } from "react-router-dom";
import { Grid, Typography, Box, Stack, CircularProgress } from "@mui/material";
import PrintLayout from "../PrintLayoutComponents/PrintLayout";
import {
  attendanceReportHeader,
  attendanceReportDay,
  attendanceCalendarRow,
} from "./Attendance.style";
import { mx5, loader } from "../../sharedStyles";
import reportsService from "../../../service/reportsService";
import attendanceService from "../../../service/attendanceService";
import moment from "../../../utils/constants/momentConfig";
import dateTimeFormats from "../../../utils/constants/dateTimeFormats";
import schoolDayService from "../../../service/schoolDayService";
import staffsService from "../../../service/staffsService";

export default function Attendance() {
  const [searchParams] = useSearchParams();
  const [students, setStudents] = useState([]);
  const [markingCodes, setMarkingCodes] = useState([]);
  const [
    schoolName,
    selectedTeacher,
    selectedTeacherStudent,
    setHomeroomTeachers,
    params,
    schoolDaysByMonth,
    setSchoolDaysByMonth,
    selectedMonth,
    selectedMenu,
    ,
    loading,
    setLoading,
  ] = useOutletContext();

  const getSchoolDaysByMonth = async () => {
    const response = await schoolDayService.byMonth();
    if (response.data) {
      const list = [];
      Object.values(response.data.school_days).forEach((_months) => {
        let date = _months[0].date.split("-");
        date = new Date(date[0], date[1] - 1, date[2]);
        const firstDayOfSchoolDays = date.getDate() - 1;
        const firstDayOfMonth =
          Number(moment(date).startOf("month").format("d")) +
          firstDayOfSchoolDays;
        const paddedSchoolDays = _months;
        for (let i = 0; i < firstDayOfMonth; i += 1) {
          paddedSchoolDays.unshift(i);
        }
        list.push({
          monthYear: date,
          schoolDays: paddedSchoolDays,
        });
      });

      setSchoolDaysByMonth(list);
    }
  };

  const getTeachers = async () => {
    const dbTeachers = await staffsService.fetchHomeroomTeachers({
      params: { school_id: params.schoolId },
    });

    setHomeroomTeachers(dbTeachers);
  };

  const getAttendanceMarkingCodes = async () => {
    const response = await attendanceService.getAttendanceMarkingCodes();

    if (response) {
      setMarkingCodes(response);
    }
  };

  const getAttendanceReport = async () => {
    setLoading(true);
    const response = await reportsService.attendance({
      teacher_id: selectedTeacher,
      student_id:
        selectedTeacherStudent === "all" ? null : selectedTeacherStudent,
      month:
        selectedMonth === "all" || searchParams.get("term") !== "null"
          ? null
          : selectedMonth,
      term_id:
        searchParams.get("term") !== "null" ? searchParams.get("term") : null,
    });

    if (response) {
      setLoading(false);
      setStudents(response);
    }
  };

  const dateRange = () => {
    const firstMonth = schoolDaysByMonth[0]?.monthYear;
    const lastMonth =
      schoolDaysByMonth[schoolDaysByMonth.length - 1]?.monthYear;

    return `School Year - ${moment(firstMonth).format(
      dateTimeFormats.MMMMYYYY
    )} - ${moment(lastMonth).format(dateTimeFormats.MMMMYYYY)}`;
  };

  const renderCodes = (period, student, date) => {
    const amCode =
      student.attendances.find((attendance) => attendance.date === date)?.am ||
      "-";
    const pmCode =
      student.attendances.find((attendance) => attendance.date === date)?.pm ||
      "-";

    return period === "am" ? amCode : pmCode;
  };

  const renderSummary = (period, markingCode, student, date = null) => {
    const amCodes = student.attendances.filter((a) => a.am === markingCode);
    const pmCodes = student.attendances.filter((a) => a.pm === markingCode);

    if (date) {
      const amCodesByMonth = amCodes.filter(
        (a) => moment(a.date).format("MMMM") === moment(date).format("MMMM")
      );
      const pmCodesByMonth = pmCodes.filter(
        (a) => moment(a.date).format("MMMM") === moment(date).format("MMMM")
      );

      return period === "am" ? amCodesByMonth.length : pmCodesByMonth.length;
    }

    return period === "am" ? amCodes.length : pmCodes.length;
  };

  const renderComments = (attendances, date) => {
    const attendancesByMonth = attendances.filter(
      (attendance) =>
        moment(attendance.date).format("MMMM") === moment(date).format("MMMM")
    );
    const hasComments = attendancesByMonth.filter(
      (attendance) => attendance.comment !== null && attendance.comment !== ""
    );

    if (!hasComments.length > 0) {
      return null;
    }

    return (
      <>
        <Typography fontWeight={700} fontSize={9} color="black">
          Comments
        </Typography>
        {hasComments.map((attendance) => (
          <Typography fontSize={9} color="black">
            {moment(attendance.date).format(dateTimeFormats.MMDDYYYY)}:{" "}
            {attendance.comment}
          </Typography>
        ))}
      </>
    );
  };

  const generateBodyContent = (
    <tr className="report-data">
      <td
        className="report-content-cell"
        style={{ padding: "2rem 1rem 0 2rem" }}
      >
        <div>
          {students.length > 0 &&
            students.map((student) => (
              <>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  px="16px"
                  sx={attendanceReportHeader(student.is_enrolled)}
                >
                  <Grid>
                    <Typography fontWeight={700} fontSize={18} color="black">
                      {student.name}
                    </Typography>
                    <Typography fontSize={14} color="black">
                      {student.grade === "TK" || student.grade === "K"
                        ? `${student.grade} grade`
                        : `${student.grade}th grade`}{" "}
                      {student.homeroom_teacher &&
                        `/ ${student.homeroom_teacher}`}
                    </Typography>
                    <Typography fontSize={14} color="black">
                      {dateRange()}
                    </Typography>
                  </Grid>
                  <Grid py={2}>
                    <Stack direction="row">
                      <Box>
                        <Typography fontWeight={700} fontSize={9} color="black">
                          Total
                        </Typography>
                        {markingCodes.map((mc) => (
                          <Typography fontSize={9} color="black">
                            {mc.description}
                          </Typography>
                        ))}
                      </Box>
                      <Box mx={1}>
                        <Typography fontWeight={700} fontSize={9} color="black">
                          AM
                        </Typography>
                        {markingCodes.map((mc) => (
                          <Typography
                            fontSize={9}
                            color="black"
                            textAlign="center"
                          >
                            {renderSummary("am", mc.code, student)}
                          </Typography>
                        ))}
                      </Box>
                      <Box>
                        <Typography fontWeight={700} fontSize={9} color="black">
                          PM
                        </Typography>
                        {markingCodes.map((mc) => (
                          <Typography
                            fontSize={9}
                            color="black"
                            textAlign="center"
                          >
                            {renderSummary("pm", mc.code, student)}
                          </Typography>
                        ))}
                      </Box>
                    </Stack>
                  </Grid>
                </Grid>
                {schoolDaysByMonth.map((schoolMonth) => {
                  if (
                    !student.attendances.filter(
                      (a) =>
                        moment(a.date).format("MMMM") ===
                        moment(schoolMonth.monthYear).format("MMMM")
                    ).length > 0
                  ) {
                    return null;
                  }
                  return (
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      px="16px"
                      sx={attendanceCalendarRow(student.is_enrolled)}
                    >
                      <Grid>
                        <Typography
                          fontWeight={700}
                          fontSize={18}
                          color="black"
                        >
                          {moment(schoolMonth.monthYear).format(
                            dateTimeFormats.MMMMYYYY
                          )}
                        </Typography>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          align="end"
                          pb={1}
                        >
                          {schoolMonth.schoolDays
                            .filter(
                              (schoolDay) => typeof schoolDay !== "number"
                            )
                            .map((sd) => (
                              <Box sx={mx5}>
                                <Box sx={attendanceReportDay}>
                                  <Typography
                                    fontWeight={700}
                                    fontSize={9}
                                    color="black"
                                  >
                                    {moment(sd.date).format("dddd")[0]}
                                  </Typography>
                                  <Typography
                                    fontWeight={700}
                                    fontSize={9}
                                    color="black"
                                  >
                                    {moment(sd.date).format("D")}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography fontSize={11} color="black">
                                    {renderCodes("am", student, sd.date)}
                                  </Typography>
                                  <Typography fontSize={11} color="black">
                                    {renderCodes("pm", student, sd.date)}
                                  </Typography>
                                </Box>
                              </Box>
                            ))}
                        </Grid>
                        <Grid>
                          {renderComments(
                            student.attendances,
                            schoolMonth?.monthYear
                          )}
                        </Grid>
                      </Grid>
                      <Grid py={2}>
                        <Stack direction="row">
                          <Box>
                            <Typography
                              fontWeight={700}
                              fontSize={9}
                              color="black"
                            >
                              Summary
                            </Typography>
                            {markingCodes.map((mc) => (
                              <Typography fontSize={9} color="black">
                                {mc.description}
                              </Typography>
                            ))}
                          </Box>
                          <Box mx={1}>
                            <Typography
                              fontWeight={700}
                              fontSize={9}
                              color="black"
                            >
                              AM
                            </Typography>
                            {markingCodes.map((mc) => (
                              <Typography
                                fontSize={9}
                                color="black"
                                textAlign="center"
                              >
                                {renderSummary(
                                  "am",
                                  mc.code,
                                  student,
                                  schoolMonth?.monthYear
                                )}
                              </Typography>
                            ))}
                          </Box>
                          <Box>
                            <Typography
                              fontWeight={700}
                              fontSize={9}
                              color="black"
                            >
                              PM
                            </Typography>
                            {markingCodes.map((mc) => (
                              <Typography
                                fontSize={9}
                                color="black"
                                textAlign="center"
                              >
                                {renderSummary(
                                  "pm",
                                  mc.code,
                                  student,
                                  schoolMonth?.monthYear
                                )}
                              </Typography>
                            ))}
                          </Box>
                        </Stack>
                      </Grid>
                    </Grid>
                  );
                })}
              </>
            ))}
        </div>
      </td>
    </tr>
  );

  useEffect(() => {
    getTeachers();
    getAttendanceReport();
    getSchoolDaysByMonth();
    getAttendanceMarkingCodes();
  }, [
    selectedTeacher,
    selectedTeacherStudent,
    selectedMonth,
    selectedMenu,
    searchParams.get("term"),
  ]);

  if (loading) {
    return <CircularProgress size={100} sx={loader} />;
  }

  return (
    <PrintLayout
      headerContent="Attendance"
      bodyContent={generateBodyContent}
      footerContent={schoolName}
    />
  );
}
