import React, { createContext, useState, useMemo } from "react";
import { useParams } from "react-router-dom";

export const StudentActionsContext = createContext({
  isGuardianDrawerOpen: false,
  drawerProperties: {},
  showGuardianCreateForm: false,
  showGuardianSearchForm: true,
  isEContactsDrawerOpen: false,
  isMedicalRecordDrawerOpen: false,
  isEventDrawerOpen: false,
  medicalRecordsUpdated: false,
  medicalEventsUpdated: false,
  parentsUpdated: false,
  econtactUpdated: false,
});
export function StudentActionsProvider({ children }) {
  const params = useParams();
  const [isGuardianDrawerOpen, setIsGuardianDrawerOpen] = useState(false);
  const [isEContactsDrawerOpen, setIsEContactsDrawerOpen] = useState(false);
  const [isMedicalRecordDrawerOpen, setMedicalRecordDrawerOpen] =
    React.useState(false);
  const [isEventDrawerOpen, setIsEventDrawerOpen] = useState(false);
  const [drawerProperties, setDrawerProperties] = useState({});
  const [showGuardianCreateForm, setShowGuardianCreateForm] = useState(false);
  const [showGuardianSearchForm, setShowGuardianSearchForm] = useState(true);

  const [medicalRecordsUpdated, setMedicalRecordsUpdated] = useState(false);
  const [medicalEventsUpdated, setMedicalEventsUpdated] = useState(false);
  const [parentsUpdated, setParentsUpdated] = useState(false);
  const [econtactUpdated, setEContactUpdated] = useState(false);

  const openGuardianDrawer = (currentGuardianId) => {
    if (currentGuardianId) {
      setShowGuardianCreateForm(true);
      setShowGuardianSearchForm(false);
    }
    const properties = {
      setShowGuardianSearchForm,
      setShowGuardianCreateForm,
      currentGuardianId,
      setIsGuardianDrawerOpen,
    };
    setDrawerProperties(properties);
    setIsGuardianDrawerOpen(true);
  };

  const openEmergencyContactDrawer = (currentContact, fetchAllContacts) => {
    const properties = {
      studentId: params.student_slug,
      currentContact,
      fetchAllContacts,
      setIsEContactsDrawerOpen,
    };
    setDrawerProperties(properties);
    setIsEContactsDrawerOpen(true);
  };

  const openMedicalRecordDrawer = (loadMedicalRecords, medicalRecordId) => {
    const properties = {
      loadMedicalRecords,
      medicalRecordId,
      setMedicalRecordDrawerOpen,
    };
    setDrawerProperties(properties);
    setMedicalRecordDrawerOpen(true);
  };

  const openEventDrawer = (fetchAllEvents, eventId) => {
    const properties = {
      fetchAllEvents,
      eventId,
      setIsEventDrawerOpen,
    };
    setDrawerProperties(properties);
    setIsEventDrawerOpen(true);
  };

  const fireMedicalRecordsUpdated = () => {
    setMedicalRecordsUpdated(true);
  };

  const cleanMedicalRecordsUpdated = () => {
    setMedicalRecordsUpdated(false);
  };

  const fireMedicalEventsUpdated = () => {
    setMedicalEventsUpdated(true);
  };

  const cleanMedicalEventsUpdated = () => {
    setMedicalEventsUpdated(false);
  };

  const fireParentsUpdated = () => {
    setParentsUpdated(true);
  };

  const cleanParentsUpdated = () => {
    setParentsUpdated(false);
  };

  const fireEContactUpdated = () => {
    setEContactUpdated(true);
  };

  const cleanEContactUpdated = () => {
    setEContactUpdated(false);
  };

  const contextValue = useMemo(
    () => ({
      openGuardianDrawer,
      isGuardianDrawerOpen,
      drawerProperties,
      showGuardianCreateForm,
      showGuardianSearchForm,
      isEContactsDrawerOpen,
      openEmergencyContactDrawer,
      isMedicalRecordDrawerOpen,
      openMedicalRecordDrawer,
      openEventDrawer,
      isEventDrawerOpen,
      fireMedicalRecordsUpdated,
      cleanMedicalRecordsUpdated,
      medicalRecordsUpdated,
      medicalEventsUpdated,
      fireMedicalEventsUpdated,
      cleanMedicalEventsUpdated,
      fireParentsUpdated,
      cleanParentsUpdated,
      fireEContactUpdated,
      cleanEContactUpdated,
      parentsUpdated,
      econtactUpdated,
    }),
    [
      isGuardianDrawerOpen,
      drawerProperties,
      showGuardianCreateForm,
      showGuardianSearchForm,
      isEContactsDrawerOpen,
      isMedicalRecordDrawerOpen,
      isEventDrawerOpen,
      medicalRecordsUpdated,
      medicalEventsUpdated,
      parentsUpdated,
      econtactUpdated,
    ]
  );

  return (
    <StudentActionsContext.Provider value={contextValue}>
      {children}
    </StudentActionsContext.Provider>
  );
}
