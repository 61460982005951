import Editor from "@draft-js-plugins/editor";
import { EditorState, RichUtils, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import createLinkifyPlugin from "@draft-js-plugins/linkify";
import { useTranslation } from "react-i18next";
import "draft-js/dist/Draft.css";
import "@draft-js-plugins/linkify/lib/plugin.css";
import * as React from "react";
import "./TextEditor.css";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import { Box, ButtonBase, Popover, Typography } from "@mui/material";
import { CloseFullscreen, OpenInFull } from "@mui/icons-material";

function StyleButton({ style, onToggle, active, label }) {
  const onMouseDown = (e) => {
    e.preventDefault();
    onToggle(style);
  };

  const className = !active
    ? "RichEditor-styleButton"
    : "RichEditor-styleButton RichEditor-activeButton";

  const iconStyle = {
    verticalAlign: "text-bottom",
    fontSize: "1.2rem",
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <span className={className} onMouseDown={onMouseDown} role="cell">
      {label === "UL" && (
        <Typography>
          <FormatListBulletedIcon sx={iconStyle} />
        </Typography>
      )}
      {label === "OL" && (
        <Typography>
          <FormatListNumberedIcon sx={iconStyle} />
        </Typography>
      )}
      {label !== "OL" && label !== "UL" && <Typography>{label}</Typography>}
    </span>
  );
}

function InlineStyleControls({ editorState, onToggle, isSimplified }) {
  const { t } = useTranslation("common", {
    keyPrefix: "textEditor.inlineStyleControls",
  });
  const currentStyle = editorState.getCurrentInlineStyle();

  const simplifiedTypes = [
    { label: t("bold"), style: "BOLD" },
    { label: t("italic"), style: "ITALIC" },
    { label: t("underline"), style: "UNDERLINE" },
  ];

  const fullTypes = [...simplifiedTypes, { label: "Monospace", style: "CODE" }];

  const INLINE_STYLES = isSimplified ? simplifiedTypes : fullTypes;
  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
}

function BlockStyleControls({ editorState, onToggle, isSimplified }) {
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  const simplifiedTypes = [
    { label: "OL", style: "ordered-list-item" },
    { label: "UL", style: "unordered-list-item" },
  ];

  const fullTypes = [
    { label: "H1", style: "header-one" },
    { label: "H2", style: "header-two" },
    { label: "H3", style: "header-three" },
    { label: "H4", style: "header-four" },
    { label: "H5", style: "header-five" },
    { label: "H6", style: "header-six" },
    { label: "Blockquote", style: "blockquote" },
    { label: "Code Block", style: "code-block" },
    ...simplifiedTypes,
  ];

  const BLOCK_TYPES = isSimplified ? simplifiedTypes : fullTypes;

  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map((type) => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
}

export default function TextEditor({
  style,
  isSimplified,
  onChange,
  value,
  handleBodyValidation = () => {},
  editorRef,
  hasError,
  readonly = false,
  showExpandIcon,
}) {
  const [editorExpanded, setEditorExpanded] = React.useState(false);
  const [updated, setUpdated] = React.useState(false);
  const [initiated, setInitiated] = React.useState(false);
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );
  // eslint-disable-next-line no-shadow
  const onEditorStateChange = (editorState) => {
    setUpdated(true);
    let channgedEditorState = null;
    if (!initiated) {
      const defaultValue = value || "";
      const blocksFromHtml = htmlToDraft(defaultValue);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHtml.contentBlocks,
        blocksFromHtml.entityMap
      );
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
      channgedEditorState = newEditorState;
      setInitiated(true);
    } else {
      setEditorState(editorState);
      channgedEditorState = editorState;
    }
    return onChange(
      draftToHtml(convertToRaw(channgedEditorState.getCurrentContent()))
    );
  };

  React.useEffect(() => {
    handleBodyValidation(editorState.getCurrentContent().hasText());

    if (!updated) {
      const defaultValue = value || "";
      const blocksFromHtml = htmlToDraft(defaultValue);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHtml.contentBlocks,
        blocksFromHtml.entityMap
      );
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
    }
  }, [value]);

  const toggleBlockType = (blockType) => {
    onEditorStateChange(RichUtils.toggleBlockType(editorState, blockType));
  };

  const toggleInlineStyle = (inlineStyle) => {
    onEditorStateChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };

  const styleMap = {
    CODE: {
      backgroundColor: "rgba(0, 0, 0, 0.05)",
      fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
      fontSize: 16,
      padding: 2,
    },
  };

  const getBlockStyle = (block) => {
    switch (block.getType()) {
      case "blockquote":
        return "RichEditor-blockquote";
      default:
        return null;
    }
  };

  let className = "RichEditor-editor";
  const contentState = editorState.getCurrentContent();
  if (!contentState.hasText()) {
    if (contentState.getBlockMap().first().getType() !== "unstyled") {
      className += " RichEditor-hidePlaceholder";
    }
  }

  /* eslint-disable jsx-a11y/anchor-has-content, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, no-shadow, react/no-unstable-nested-components */
  const linkifyPlugin = createLinkifyPlugin({
    component: (props) => {
      const { contentState, ...rest } = props;
      return (
        <a
          {...rest}
          onClick={() => {
            window.open(rest.href, "_blank");
          }}
        />
      );
    },
  });
  /* eslint-enable jsx-a11y/anchor-has-content, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, no-shadow, react/no-unstable-nested-components */

  const editorContent = (
    <div
      style={{
        ...style,
        ...(editorExpanded && { height: "80vh", width: "80vw" }),
      }}
      className={`RichEditor-root ${
        hasError ? "RichEditor-border-error" : "RichEditor-border-normal"
      }`}
    >
      <div style={style} className="RichEditor-controls-container">
        {showExpandIcon && (
          <Box display="flex" alignItems="center" mr={2} color="#999">
            <ButtonBase onClick={() => setEditorExpanded(!editorExpanded)}>
              {editorExpanded ? (
                <CloseFullscreen />
              ) : (
                <OpenInFull sx={{ fontSize: "1rem" }} />
              )}
            </ButtonBase>
          </Box>
        )}
        <BlockStyleControls
          editorState={editorState}
          onToggle={toggleBlockType}
          isSimplified={isSimplified}
        />
        <InlineStyleControls
          editorState={editorState}
          onToggle={toggleInlineStyle}
          isSimplified={isSimplified}
        />
      </div>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
      <div className={className} onClick={window.focus} role="application">
        <Editor
          blockStyleFn={getBlockStyle}
          customStyleMap={styleMap}
          editorState={editorState}
          onChange={onEditorStateChange}
          ref={editorRef}
          placeholder=""
          plugins={[linkifyPlugin]}
          readOnly={readonly}
        />
      </div>
    </div>
  );
  return !editorExpanded ? (
    editorContent
  ) : (
    <Popover
      open={editorExpanded}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
    >
      {editorContent}
    </Popover>
  );
}
