import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React from "react";

export default function DropdownList({ field }) {
  return (
    <Box>
      <FormControl fullWidth required={field.properties.isRequired}>
        <InputLabel id={field.fieldId}>{field.label}</InputLabel>
        <Select
          labelId={field.fieldId}
          label={field.label}
          placeholder="Select"
          required={field.properties.isRequired}
          disabled
        >
          <MenuItem value={null}>
            <Typography textAlign="left" />
          </MenuItem>
        </Select>
        <FormHelperText>{field.properties.supportingText}</FormHelperText>
      </FormControl>
    </Box>
  );
}
