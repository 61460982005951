import { Box, Container } from "@mui/material";
import React, { useContext } from "react";
import {
  Outlet,
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import { pageTabsContainer } from "../../sharedStyles";
import { admissionsContainer } from "./Setup.styles";
import SetupTabs from "./SetupTabs";
import { QuickBarContext } from "../../../context/QuickBarContext";

export default function Setup() {
  const [schoolId] = useOutletContext();
  const quickBarContext = useContext(QuickBarContext);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (quickBarContext.createNewProcess) {
      navigate(
        `/school/${schoolId}/admissions/setup/process-builder/form?school_year=${searchParams.get(
          "school_year"
        )}`
      );
      quickBarContext.cleanCreateNewProcess();
    }
  }, [quickBarContext.createNewProcess]);

  React.useEffect(() => {
    if (quickBarContext.createNewForm) {
      navigate(
        `/school/${schoolId}/admissions/setup/form-builder?school_year=${searchParams.get(
          "school_year"
        )}`
      );
      quickBarContext.cleanCreateNewForm();
    }
  }, [quickBarContext.createNewForm]);

  return (
    <>
      <Container maxWidth={false} variant="header" sx={{ py: "0 !important" }}>
        <Box sx={pageTabsContainer}>
          <SetupTabs />
        </Box>
      </Container>

      <Box sx={admissionsContainer}>
        <Outlet context={[schoolId]} />
      </Box>
    </>
  );
}
