import { Outlet, useOutletContext, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { fetchStudentInfo } from "../service/studentPortalService";
import { loader } from "../components/sharedStyles";

export default function StudentPortal() {
  const params = useParams();
  const studentId = params.id;
  const [student, setStudent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [setStudentSchool] = useOutletContext();

  useEffect(() => {
    (async () => {
      if (studentId) {
        try {
          setLoading(true);
          const response = await fetchStudentInfo(studentId);

          if (response.data) {
            setLoading(false);
            setStudent(response.data);
            setStudentSchool(response.data.school);
          }
        } catch (e) {
          if (e.response && e.response.data.message === "Student mismatched") {
            window.location.replace("/login");
          }
        }
      }
    })();
  }, [studentId]);

  if (loading) {
    return <CircularProgress sx={loader} size={100} />;
  }

  return student && <Outlet context={[student]} />;
}
