import axios from "../utils/axios-util";

const gpaScalesEndpoint = "/api/gpa_scales";

const fetchAll = async (params) => {
  const { data } = await axios.get(`${gpaScalesEndpoint}`, params);
  return data;
};

const updateGpaScales = async (params) => {
  const { data } = await axios.post(gpaScalesEndpoint, params);
  return data;
};

export default {
  fetchAll,
  updateGpaScales,
};
