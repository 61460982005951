import React, { useState, useEffect } from "react";
import { Interweave } from "interweave";
import { UrlMatcher } from "interweave-autolink";
import { useParams } from "react-router-dom";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Box, Typography, Stack } from "@mui/material";
import {
  conversationSenderBox,
  conversationAttachIcon,
  conversationBoxInner,
  conversationRecipientBox,
  conversationSenderMessageContent,
  conversationSenderTimeStamp,
  conversationSenderFileContainer,
  conversationSenderFileContainerBorderless,
} from "./Conversation.styles";
import conversationAttachment from "./ConversationBox.styles";
import dateTimeFormats from "../../../utils/constants/dateTimeFormats";
import moment from "../../../utils/constants/momentConfig";
import messagesService from "../../../service/messagesService";
import messageFileDownload from "../../../utils/messageFileDownload";
import profilePictureIcon from "../../../utils/profilePictureIcon";

export default function StudentPortalConversationBox({ message }) {
  const [attachments, setAttachments] = useState([]);
  const params = useParams();
  const studentId = params.id;
  const conversationKey = params.conversation_key;
  const isSender =
    message.sender_type === "Student" &&
    message.sender_id.toString() === studentId;

  const getAttachments = async () => {
    const response = await messagesService.fetchMessageAttachment(message.id);

    if (response) {
      setAttachments(response);
    }
  };

  useEffect(() => {
    getAttachments();
  }, []);

  const renderMessageContent = () => (
    <>
      <Stack direction="row" spacing={1} justifyContent="space-between">
        <Typography>{`${message.sender.first_name} ${message.sender.last_name}`}</Typography>
      </Stack>

      <Box sx={conversationSenderMessageContent}>
        <Interweave
          newWindow
          matchers={[new UrlMatcher("url")]}
          content={message.description}
        />

        <Typography sx={conversationSenderTimeStamp}>
          {moment(message.created_at).format(dateTimeFormats.LLL)}
        </Typography>
      </Box>
    </>
  );

  const attachmentsContainer = message.has_attachments
    ? conversationSenderFileContainer
    : conversationSenderFileContainerBorderless;

  return (
    <Stack direction="row" alignItems="center">
      {!isSender && (
        <img
          alt="profile_image"
          src={profilePictureIcon(message?.sender)}
          style={{
            borderRadius: "100px",
            width: 56,
            height: 56,
            objectFit: "cover",
          }}
        />
      )}
      <Box sx={isSender ? conversationSenderBox : conversationRecipientBox}>
        <Box sx={conversationBoxInner}>
          {renderMessageContent()}

          <Stack
            sx={attachmentsContainer}
            direction="row"
            alignItems="center"
            justifyContent="start"
          >
            {message.has_attachments && (
              <AttachFileIcon sx={conversationAttachIcon} />
            )}
            <Box>
              {attachments?.map((attachment) => (
                <Box
                  sx={conversationAttachment}
                  key={attachment.id}
                  onClick={async () => {
                    await messageFileDownload(attachment, conversationKey);
                  }}
                >
                  {attachment.filename}
                </Box>
              ))}
            </Box>
          </Stack>
        </Box>
      </Box>
    </Stack>
  );
}
