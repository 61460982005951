import React, { createContext, useMemo, useState } from "react";
import AdmissionProcess from "./Models/AdmissionProcess";

export const ProcessBuilderContext = createContext({
  selectedElement: null,
  process: new AdmissionProcess(),
  currentStageIndex: 0,
});

export function ProcessBuilderContextProvider({ children }) {
  const [process, setProcess] = useState(null);
  const [selectedElement, setSelectedElement] = useState(null);
  const [currentStageIndex, setCurrentStageIndex] = useState(0);

  const selectElement = (fieldId) => {
    setSelectedElement(fieldId);
  };

  const updateProcess = (updatedProcess) => {
    setProcess(updatedProcess);
  };

  const updateCurrentStageIndex = (sectionIndex) => {
    setCurrentStageIndex(sectionIndex);
  };

  const contextValue = useMemo(
    () => ({
      selectElement,
      selectedElement,
      process,
      updateProcess,
      updateCurrentStageIndex,
      currentStageIndex,
    }),
    [selectedElement, process, currentStageIndex]
  );

  return (
    <ProcessBuilderContext.Provider value={contextValue}>
      {children}
    </ProcessBuilderContext.Provider>
  );
}
