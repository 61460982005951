import {
  IconButton,
  Grid,
  Input,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
} from "@mui/material";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import React, { useContext, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  profileFilesGrid,
  profileFilesRow,
  tableCellName,
  tableCellType,
  tableCellDateAdded,
  tableStyles,
  actionButtonStyle,
  actionContainerStyle,
} from "./Files.style";
import studentsService from "../../../service/studentsService";
import { SnackbarContext } from "../../../context/SnackbarContext";
import { QuickBarContext } from "../../../context/QuickBarContext";
import AlertDialog from "../../AlertDialog";
import fileService from "../../../service/fileService";
import { PermissionsContext } from "../../../context/PermissionsContext";
import APP_PERMISSIONS from "../../../utils/constants/permissions";
import PERMISSION_TYPES from "../../../utils/constants/permission_types";

export default function Files({ student }) {
  // eslint-disable-next-line camelcase
  const { school_id } = useParams();
  const [files, setFiles] = useState([]);
  const [isDialogOpen, setDialogState] = useState(false);
  const [fileIdToDelete, setFileIdToDelete] = useState(null);
  const maxFileSize = 12000000;
  const { setSnackbar } = useContext(SnackbarContext);
  const quickBarContext = useContext(QuickBarContext);
  const fileInputRef = useRef(null);
  const { hasPermission, hasAnyPermissionType } =
    useContext(PermissionsContext);

  const downloadPermission = hasAnyPermissionType(
    APP_PERMISSIONS.STUDENT_PROFILE
  );

  const managePermission = hasPermission(
    APP_PERMISSIONS.STUDENT_PROFILE,
    PERMISSION_TYPES.MANAGE
  );

  const getFiles = async () => {
    const attachments = await studentsService.fetchStudentFiles(student.id);
    setFiles(attachments);
  };

  const deleteFile = async (fileId) => {
    setFileIdToDelete(fileId);
    setDialogState(true);
  };
  const handleDeleteFileConfirm = () => {
    setDialogState(false);
    setSnackbar({
      message: "Deleting file...",
      open: true,
      severity: "info",
    });
    studentsService
      .deleteStudentFile(student.id, fileIdToDelete)
      .then(() => {
        setSnackbar({
          message: "File deleted successfully.",
          open: true,
          severity: "success",
        });
        setFiles(files.filter((file) => file.id !== fileIdToDelete));
      })
      .catch(() => {
        setSnackbar({
          message: "Error while deleting file.",
          open: true,
          severity: "error",
        });
      });
    setFileIdToDelete(null);
  };
  const handleClose = () => {
    setDialogState(false);
  };

  useEffect(() => {
    (async () => {
      if (quickBarContext.addStudentFiles) {
        fileInputRef.current.click();
        quickBarContext.cleanAddStudentFiles();
      }
    })();
  }, [quickBarContext.addStudentFiles]);

  useEffect(() => {
    (async () => {
      await getFiles();
    })();
  }, []);

  function SizeException(message) {
    return {
      message,
      error: "sizeException",
    };
  }

  const handleFileUploads = async (e) => {
    const formData = new FormData();
    if (e.target.files) {
      try {
        Array.prototype.forEach.call(e.target.files, (file, index) => {
          if (file.size > maxFileSize)
            throw new SizeException(
              `The file ${file.name} is bigger than 12Mb.`
            );
          formData.append(`file${index}`, file, file.name);
        });
        await studentsService.postStudentFiles(student.id, formData);
        await getFiles();
        setSnackbar({
          message: "Files uploaded successfully.",
          open: true,
          severity: "success",
        });
      } catch (err) {
        if (err.error === "sizeException") {
          setSnackbar({ message: err.message, open: true, severity: "error" });
        }
      }
    }
  };

  const handleFileDownload = async (row) => {
    await fileService.fetchProfileFile({
      id: row.id,
      student_id: student.id,
      filename: row.filename,
      // eslint-disable-next-line camelcase
      school_id,
    });
  };

  return (
    <Grid container mb={5}>
      <Grid item xs={12} mt={3} sx={profileFilesGrid}>
        <Typography variant="profileTitle">Files</Typography>
        <Input
          style={{ display: "none" }}
          inputRef={fileInputRef}
          accept=".jpeg, .jpg, .pdf, .png"
          type="file"
          multiple
          onChange={handleFileUploads}
        />
      </Grid>
      <Grid item xs={12} px={3}>
        {files.length > 0 && (
          <TableContainer component={Paper} elevation={0}>
            <Table sx={tableStyles} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>NAME</TableCell>
                  <TableCell>TYPE</TableCell>
                  <TableCell>DATE ADDED</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {files.map((row) => (
                  <TableRow key={row.id} sx={profileFilesRow}>
                    <TableCell sx={tableCellName}>{row.filename}</TableCell>
                    <TableCell sx={tableCellType}>{row.content_type}</TableCell>
                    <TableCell sx={tableCellDateAdded}>
                      {row.created_at}
                    </TableCell>
                    <TableCell>
                      {downloadPermission && (
                        <Box
                          style={actionContainerStyle}
                          onClick={() => handleFileDownload(row)}
                        >
                          <SimCardDownloadOutlinedIcon sx={actionButtonStyle} />
                          Download
                        </Box>
                      )}
                    </TableCell>
                    <TableCell>
                      {managePermission && (
                        <div style={actionContainerStyle}>
                          <IconButton onClick={() => deleteFile(row.id)}>
                            <DeleteForeverOutlinedIcon sx={actionButtonStyle} />
                          </IconButton>
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
      <AlertDialog
        isOpen={isDialogOpen}
        handleClose={handleClose}
        handleConfirm={handleDeleteFileConfirm}
      />
    </Grid>
  );
}
