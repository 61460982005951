import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  courseFormDivider,
  courseFormRow,
  courseFormSubject,
  courseFormCheckbox,
} from "./CourseForm.styles";
import IOSSwitch from "../../IOSSwitch";
import { bgWhite, mb20, mr10, pt0 } from "../../sharedStyles";
import ErasableTextField from "../../ErasableTextField";
import { renderError, requiredMessage } from "../../../utils/constants/forms";

export default function GeneralInfo({
  subjects,
  predefinedCourses,
  activeCourse,
  departments,
  gpaScales,
}) {
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(
    activeCourse?.predefined_course?.id || ""
  );
  const [courseIdSda, setCourseIdSda] = useState(
    activeCourse?.course_id_sda || ""
  );
  const [customCourseName, setCustomCourseName] = useState(
    activeCourse?.name || ""
  );
  const [notNadCourse, setNotNadCourse] = useState(
    activeCourse?.not_nad_course || false
  );
  const {
    setValue,
    register,
    formState: { errors },
    trigger,
  } = useFormContext();

  const handleSubjectSelect = async (subjectId, state) => {
    const subject = subjects.find((sub) => sub.id === subjectId);
    setCourses(
      predefinedCourses.filter(
        (pc) =>
          pc.subject_name.toLowerCase().trim() ===
          subject.name.toLowerCase().trim()
      )
    );
    if (state !== "on-load" && !notNadCourse) {
      setSelectedCourse("");
      setCourseIdSda("");
      setCustomCourseName("");
    }
  };

  const handleCourseSelect = async (courseId) => {
    const predefinedCourse = predefinedCourses.find((pc) => pc.id === courseId);
    setSelectedCourse(predefinedCourse.id);
    setCourseIdSda(predefinedCourse?.course_id_sda);
    setValue("course_id_sda", predefinedCourse?.course_id_sda || "", {
      shouldDirty: true,
    });
    trigger("course_id_sda");
  };

  const handleNotNad = async (value) => {
    setNotNadCourse(value);
    setSelectedCourse("");
    setCourseIdSda("");
    setCustomCourseName("");
    setValue("predefined_course", "", {
      shouldDirty: true,
    });
    setValue("custom_course", "", {
      shouldDirty: true,
    });
    setValue("course_id_sda", "", {
      shouldDirty: true,
    });
  };

  useEffect(() => {
    if (activeCourse) {
      handleSubjectSelect(activeCourse?.subject?.id, "on-load");
    }
    setValue("active", activeCourse?.active || false, {
      shouldDirty: true,
    });
    setValue("course_id_sda", activeCourse?.course_id_sda || "", {
      shouldDirty: true,
    });
    setValue("not_nad_course", activeCourse?.not_nad_course || false, {
      shouldDirty: true,
    });
  }, [predefinedCourses]);

  return (
    <Box sx={[courseFormDivider, pt0]}>
      <Stack sx={courseFormRow} justifyContent="end" direction="row">
        <Typography mr={2}>Active</Typography>
        <IOSSwitch
          defaultChecked={activeCourse?.active}
          onChange={(e) =>
            setValue("active", e.target.checked, {
              shouldDirty: true,
            })
          }
        />
      </Stack>

      <Box sx={courseFormRow}>
        <FormControl
          fullWidth
          sx={courseFormSubject}
          error={errors?.subject && true}
        >
          <InputLabel id="subject" shrink sx={bgWhite}>
            Subject*
          </InputLabel>
          <Select
            labelId="subject"
            id="subject"
            defaultValue={activeCourse?.subject?.id || ""}
            {...register("subject", {
              required: requiredMessage,
              onChange: (e) => handleSubjectSelect(e.target.value),
              value: activeCourse?.subject?.id || "",
            })}
          >
            {subjects.map((subject) => (
              <MenuItem key={subject.id} value={subject.id}>
                {subject.name}
              </MenuItem>
            ))}
          </Select>
          {errors?.subject && renderError(errors?.subject.message)}
        </FormControl>
      </Box>

      <Stack sx={courseFormRow} direction="row">
        <FormControl
          fullWidth
          sx={[courseFormSubject, mr10]}
          error={errors?.predefined_course && true}
        >
          {!notNadCourse ? (
            <>
              <InputLabel id="course" shrink sx={bgWhite}>
                Name of Course*
              </InputLabel>
              <Select
                labelId="course"
                id="course"
                defaultValue={selectedCourse}
                {...register("predefined_course", {
                  required: requiredMessage,
                  onChange: (e) => handleCourseSelect(e.target.value),
                  value: selectedCourse,
                })}
              >
                {courses.map((course) => (
                  <MenuItem key={course.id} value={course.id}>
                    {course.name}
                  </MenuItem>
                ))}
              </Select>
              {errors?.predefined_course &&
                renderError(errors?.predefined_course.message)}
            </>
          ) : (
            <>
              <TextField
                sx={courseFormSubject}
                id="course"
                label="Name of Course*"
                fullWidth
                value={customCourseName}
                {...register("custom_course", {
                  required: "This field is required.",
                  onChange: (e) => setCustomCourseName(e.target.value),
                })}
                error={errors?.custom_course && true}
              />
              {errors?.custom_course &&
                renderError(errors?.custom_course.message)}
            </>
          )}
        </FormControl>

        <FormControl>
          <TextField
            sx={courseFormSubject}
            id="course-no"
            label="Course No.*"
            fullWidth
            value={courseIdSda}
            type="number"
            {...register("course_id_sda", {
              onChange: (e) => {
                setCourseIdSda(e.target.value);
              },
              required: "This field is required.",
            })}
            error={errors?.course_id_sda && true}
            disabled={!notNadCourse}
          />
          {errors?.course_id_sda && renderError(errors?.course_id_sda.message)}
        </FormControl>
      </Stack>

      <FormControlLabel
        disabled={activeCourse && true}
        sx={mb20}
        required
        control={
          <Checkbox
            {...register("not_nad_course", {
              onChange: (e) => handleNotNad(e.target.checked),
            })}
            checked={notNadCourse}
          />
        }
        label="Not NAD course"
      />

      <Box sx={courseFormRow}>
        <ErasableTextField
          id="description"
          label="Description"
          fullWidth
          placeholder="Description"
          isMultiline
          lines={4}
          hasError={errors?.description && true}
          defaultValue={activeCourse?.description || ""}
          maxLength={250}
        />
        {errors?.description && renderError(errors?.description.message)}
      </Box>

      <Stack direction="row" sx={courseFormRow}>
        <FormControl fullWidth sx={[courseFormSubject, mr10]}>
          <InputLabel id="department" shrink sx={bgWhite}>
            Department
          </InputLabel>
          <Select
            labelId="department"
            id="department"
            defaultValue={activeCourse?.department?.id || ""}
            {...register("department", {
              value: activeCourse?.department?.id || "",
            })}
          >
            {departments.map((department) => (
              <MenuItem key={department.id} value={department.id}>
                {department.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl
          fullWidth
          sx={courseFormSubject}
          error={errors?.level && true}
        >
          <InputLabel id="level" shrink sx={bgWhite}>
            Level*
          </InputLabel>
          <Select
            labelId="level"
            id="level"
            defaultValue={activeCourse?.level || ""}
            {...register("level", {
              required: requiredMessage,
              value: activeCourse?.level || "",
            })}
          >
            <MenuItem value="elementary">Elementary</MenuItem>
            <MenuItem value="secondary">Secondary</MenuItem>
          </Select>
          {errors?.level && renderError(errors?.level.message)}
        </FormControl>
      </Stack>

      <FormGroup sx={courseFormCheckbox}>
        <FormControlLabel
          required
          control={
            <Checkbox
              {...register("honors")}
              defaultChecked={activeCourse?.honors}
            />
          }
          label="Honors"
        />
        <FormControlLabel
          required
          control={
            <Checkbox {...register("ap")} defaultChecked={activeCourse?.ap} />
          }
          label="AP"
        />
      </FormGroup>

      <Box>
        <FormControl
          fullWidth
          sx={courseFormSubject}
          error={errors?.gpa_scale && true}
        >
          <InputLabel id="gpa_scale" shrink sx={bgWhite}>
            GPA*
          </InputLabel>
          <Select
            labelId="gpa-scale"
            id="gpa-scale"
            defaultValue={activeCourse?.gpa_scale?.id || ""}
            {...register("gpa_scale", {
              required: requiredMessage,
              value: activeCourse?.gpa_scale?.id || "",
            })}
          >
            {gpaScales.map((gpaScale) => (
              <MenuItem key={gpaScale.id} value={gpaScale.id}>
                {gpaScale.name}
              </MenuItem>
            ))}
          </Select>
          {errors?.gpa_scale && renderError(errors?.gpa_scale.message)}
        </FormControl>
      </Box>
    </Box>
  );
}
