import React, { useState, useEffect, useContext } from "react";
import { Grid, Typography } from "@mui/material";
import EmergencyContactTable from "./EmergencyContactTable";
import { textCenter } from "../../../sharedStyles";
import emergencyContactsService from "../../../../service/emergencyContactsService";
import { QuickBarContext } from "../../../../context/QuickBarContext";
import { StudentActionsContext } from "../../../../context/StudentActionsContext";

function EmergencyContacts({ student, currentGuardianId }) {
  const studentActionsContext = useContext(StudentActionsContext);
  const quickBarContext = useContext(QuickBarContext);
  const [eContactDrawer, setEcontactDrawer] = useState(false);
  const [emergencyContacts, setEmergencyContacts] = useState([]);
  const [guardians, setGuardians] = useState([]);

  const fetchAllContacts = async () => {
    const response = await emergencyContactsService.fetchAllContacts(
      student.id
    );
    if (response.data) {
      setEmergencyContacts(response.data.emergency_contacts);
      setGuardians(response.data.guardians);
    }
  };

  const handleEcontactDrawer = (eContactDrawerOpen) => {
    setEcontactDrawer(eContactDrawerOpen);
  };

  const getContact = async (currentContactId) => {
    const response = await emergencyContactsService.getEmergencyContact(
      student.id,
      currentContactId
    );

    if (response.data) {
      studentActionsContext.openEmergencyContactDrawer(
        response.data,
        fetchAllContacts
      );
    }
  };

  const handleTableClick = (id) => {
    getContact(id);
  };

  const editGuardian = (id) => {
    studentActionsContext.openGuardianDrawer(id);
  };

  useEffect(() => {
    if (
      studentActionsContext.parentsUpdated ||
      studentActionsContext.econtactUpdated
    ) {
      fetchAllContacts();
      studentActionsContext.cleanParentsUpdated();
      studentActionsContext.cleanEContactUpdated();
    }
  }, [
    studentActionsContext.parentsUpdated,
    studentActionsContext.econtactUpdated,
  ]);

  useEffect(() => {
    (async () => {
      fetchAllContacts();
    })();
  }, [eContactDrawer, currentGuardianId]);

  useEffect(() => {
    (async () => {
      if (quickBarContext.addEmergencyContacts) {
        handleEcontactDrawer(true);
        quickBarContext.hideAddEmergencyContacts();
      }
    })();
  }, [quickBarContext.addEmergencyContacts]);

  return (
    <Grid
      container
      item
      justifyContent="space-around"
      alignItems="baseline"
      rowSpacing={3}
      sm={11}
      md={12}
    >
      <Grid container item sm={12} justifyContent="space-between">
        <Typography variant="h3">Emergency Contact & Transportation</Typography>
      </Grid>

      <Grid item sm={12}>
        {emergencyContacts.length > 0 || guardians.length > 0 ? (
          <EmergencyContactTable
            emergencyContacts={emergencyContacts}
            guardians={guardians}
            handleTableClick={handleTableClick}
            editGuardianContact={editGuardian}
          />
        ) : (
          <Typography sx={textCenter}>No emergency contacts found.</Typography>
        )}
      </Grid>
    </Grid>
  );
}

export default EmergencyContacts;
