const stageContainer = (selected) => ({
  position: "relative",
  minWidth: 275,
  borderRadius: "1rem",
  boxShadow: selected
    ? "0px 4px 12px 0px rgba(17, 194, 233, 0.30)"
    : "0 8px 16px 0 rgba(0,0,0,0.2)",
  "&:hover": {
    "& #deleteField": { display: "inline-flex" },
  },
});

const stageElement = {
  backgroundColor: "#42A5F5",
  borderRadius: 2,
  color: "white",
};

export { stageContainer, stageElement };
