const drawerContainer = {
  width: 597,
  height: "100%",
  mx: "50px",
  "@media screen and (max-width: 600px)": {
    width: "100%",
    height: "100%",
  },
};

const changeSchoolsTitle = {
  color: "white",
  fontSize: "45px",
  mt: "80px",
};

const leftDrawerStyle = {
  "& .MuiPaper-root": {
    backgroundColor: "#3F464B",
  },
  zIndex: 10000,
};

const closeIconStyle = {
  position: "absolute",
  fontSize: 50,
  cursor: "pointer",
  color: "#929292",
  right: 0,
  mt: "25px",
  mr: "25px",
  "&:hover": {
    color: "white",
  },
};

const changeSchoolsListRow = {
  borderBottom: "1px solid #929292",
  py: "20px",
  cursor: "pointer",
  display: "flex",
  justifyContent: "space-between",
  "&:hover": {
    "& p": {
      color: "#929292",
    },
  },
};

const changeSchoolsName = {
  color: "white",
  fontSize: "18px",
};

const changeSchoolsNameActive = {
  color: "#929292",
  fontSize: "18px",
};

const changeSchoolsActiveLink = {
  pointerEvents: "none",
};

export {
  closeIconStyle,
  drawerContainer,
  changeSchoolsListRow,
  leftDrawerStyle,
  changeSchoolsTitle,
  changeSchoolsName,
  changeSchoolsNameActive,
  changeSchoolsActiveLink,
};
