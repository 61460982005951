import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ArrowRightAlt, ExpandMore, Segment } from "@mui/icons-material";
import admissionFormService from "../../../../../../service/admissionFormService";
import { fieldStyle } from "../../FormBuilder.style";
import { FormBuilderContext } from "../../FormBuilderContext";

export default function Data() {
  const [formFields, setFormFields] = useState([]);
  const [searchParams] = useSearchParams();
  const [accordionState, setAccordionState] = useState(null);
  const excludeFieldTypes = ["sub-heading", "page-break", "horizontal-divider"];

  useEffect(() => {
    (async () => {
      const response = await admissionFormService.fetchAllForms(
        searchParams.get("school_year")
      );
      const currentFormId = searchParams.get("form_id");
      const fields = response.data
        .filter((f) => f.id !== currentFormId)
        .map((data) => {
          const allFields = data.admission_form_pages[0].form_sections
            .reduce(
              (ff, section) =>
                ff.concat(
                  section.form_fields.sort((a, b) => {
                    // First, sort by 'y' value (column number)
                    if (a.y !== b.y) {
                      return a.y - b.y;
                    }
                    // If 'y' values are equal, sort by 'x' value (row number)
                    return a.x - b.x;
                  })
                ),
              []
            )
            .filter((f) => !excludeFieldTypes.includes(f.field_type));

          return {
            ...data,
            allFields,
          };
        });

      setFormFields(fields);
    })();
  }, []);

  return (
    <Box color="black" width="100%">
      {formFields.map((ff) => (
        <Accordion
          sx={{
            boxShadow: "none",
            ".MuiAccordionSummary-root": {
              minHeight: "fit-content !important",
              padding: "0 !important",
            },
            mt: 1,
          }}
          expanded={accordionState === ff.id}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            sx={{
              ".MuiAccordionSummary-content": {
                margin: "0 !important",
              },
            }}
            onClick={() =>
              setAccordionState(accordionState === ff.id ? null : ff.id)
            }
          >
            <Typography variant="body1">{ff.name}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {ff.allFields.map((field) => (
              <DraggableField key={field.id} field={field} formId={ff.id} />
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>

    // <Box color="black" width="100%">
    //   {formFields.map((ff) => (
    //     <>
    //       <Typography variant="body1">{ff.name}</Typography>
    //       <Box ml={2}>
    //         {ff.allFields.map((field) => (
    //           <DraggableField
    //             key={field.id}
    //             field={field}
    //             formId={ff.id}
    //             cursorPointer={cursorPointer}
    //           />
    //         ))}
    //       </Box>
    //     </>
    //   ))}
    // </Box>
  );
}

function DraggableField({ field, formId }) {
  const formBuilderContext = useContext(FormBuilderContext);
  return (
    <Grid
      container
      draggable
      unselectable="on"
      onDragStart={(e) => {
        formBuilderContext.fireOnDragStart({ type: "form_field" });
        e.dataTransfer.setData(
          "text/plain",
          JSON.stringify({
            ...field,
            type: field.field_type,
            id: null,
            source_field_id: field.id,
            source_form_id: formId,
            field_id: null,
            properties: {
              ...field.properties,
              dbRequiredField: false,
              validityCondition: [],
              visibilityCondition: [],
              validityDependents: [],
              visibilityDependents: [],
              validityDependee: [],
              visibilityDependee: [],
              validityConditionJS: "",
              visibilityConditionJS: "",
              validityMessage: "",
            },
          })
        );
      }}
      onDragEnd={() => {
        formBuilderContext.fireOnDragStop();
      }}
      sx={fieldStyle}
    >
      <Grid item>
        <Typography variant="body2">{field.label}</Typography>
      </Grid>
      <Grid item>
        <ArrowRightAlt fontSize="small" />
        <Segment fontSize="small" />
      </Grid>
    </Grid>
  );
}
