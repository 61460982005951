const courseFormDivider = {
  borderBottom: "1px solid #BFC7CA",
  py: "25px",
};

const courseFormRow = {
  mb: "20px",
};

const courseFormSubject = {
  width: "340px",
};

const reportCardInput = {
  width: "85px",
};

const courseFormCheckbox = { display: "inline-block", marginBottom: "20px" };

const courseFormDuration = {
  width: "200px",
};

export {
  courseFormDivider,
  courseFormRow,
  courseFormSubject,
  reportCardInput,
  courseFormCheckbox,
  courseFormDuration,
};
