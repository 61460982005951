import { tableCellClasses, tableRowClasses } from "@mui/material";

const tableStyles = {
  minWidth: 650,
  [`& .${tableCellClasses.root}`]: {
    borderBottom: "none",
  },
  [`& .${tableRowClasses.root}:not(:last-child)`]: {
    borderBottom: "1px solid #d9d9d9",
  },
  [`& .${tableRowClasses.head} .${tableCellClasses.root}:first-child`]: {
    fontSize: 18,
    textTransform: "none",
  },
  [`& .${tableRowClasses.head} .${tableCellClasses.root}`]: {
    borderBottom: "1px solid #d9d9d9",
    paddingBottom: 1,
  },
  "& .MuiTableCell-head": {
    color: "#000000",
    fontWeight: "700 !important",
    textTransform: "uppercase",
  },
  "& .MuiTableCell-body": {
    color: "black",
    fontSize: 16,
  },
  "& .MuiTableCell-root:first-child": {
    paddingLeft: 0,
  },
  "& .MuiTableRow-root:first-child .MuiTableCell-body": {
    paddingTop: 1,
  },
  "& .MuiTableCell-root:nth-child(1)": {
    width: "40%",
  },
};
const primaryIcon = {
  ml: "25px",
  fontSize: "20px",
};
const guardianDrawer = {
  "& .MuiPaper-root": {
    backgroundColor: "white",
    width: "800px",
  },
};
const tableHeader = {
  fontSize: "9px !important",
  borderBottom: "none !important",
};

export { tableStyles, primaryIcon, guardianDrawer, tableHeader };
