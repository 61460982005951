import React, { useEffect, useState } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  fetchGuardianKidClasses,
  fetchGuardianKidCurrentTerm,
} from "../../../../service/guardiansService";
import {
  tableContainer,
  tableHead,
  tableHeadRow,
  tableBodyRow,
  gridItemDate,
  gridItemSelect,
} from "./Classes.styles";
import termService from "../../../../service/termService";
import { mediumDate } from "../../../../utils/dateTimePresets";
import { textCenter } from "../../../sharedStyles";
import termGradeLetter from "../../../../utils/termGradeLetter";

export default function GuardianKidsInfoClasses() {
  const { t, i18n } = useTranslation(["guardian", "common"]);
  const params = useParams();
  const guardianId = params.id;
  const kidId = params.kid_id;
  const [searchParams, setSearchParams] = useSearchParams();
  const termIdParam = searchParams.get("term") || "";
  const [schoolId, setSchoolId] = useState();
  const [terms, setTerms] = useState([]);
  const [termGrades, setTermGrades] = useState([]);
  const [specialMarks, setSpecialMarks] = useState([]);
  const [selectedTerm, setSelectedTerm] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const response = await fetchGuardianKidCurrentTerm(guardianId, kidId);

        if (!termIdParam) {
          if (response.data.current_term?.id) {
            searchParams.set("term", response.data.current_term?.id);
            setSearchParams(searchParams);
          }
        }
        setSchoolId(response.data.school_id);
      } catch (e) {
        // handle catch
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (schoolId) {
        try {
          const response = await termService.fetchAllTermsInSchool({
            params: {
              school_id: schoolId,
              upcoming_terms: false,
            },
          });
          if (response.data) {
            setTerms(response.data.terms);
            setSelectedTerm(
              response.data.terms.find(
                (term) => term.id === parseInt(termIdParam, 10)
              )
            );
          }
        } catch (e) {
          // handle catch
        }
      }
    })();
  }, [schoolId]);

  useEffect(() => {
    (async () => {
      if (termIdParam) {
        try {
          setTermGrades([]);
          setLoading(true);

          const response = await fetchGuardianKidClasses(
            guardianId,
            kidId,
            termIdParam
          );

          if (response.data) {
            setTermGrades(response.data.term_grades);

            setSpecialMarks(response.data.special_marks);
            setLoading(false);
          }
        } catch (e) {
          // handle catch
        }
      }
    })();
  }, [kidId, termIdParam]);

  const handleTermChange = (event) => {
    const termId = event.target.value;
    searchParams.set("term", termId);
    setSearchParams(searchParams);
  };

  const displayCurrentTerm = () => {
    const date = (strDate) =>
      mediumDate(strDate, { language: i18n.language, timeZone: "UTC" });
    return `${selectedTerm?.name}, ${date(selectedTerm?.start_date)} - ${date(
      selectedTerm?.end_date
    )}`;
  };

  const calculateMissingAssignments = (assignments) => {
    let count = 0;
    // eslint-disable-next-line array-callback-return
    assignments.map((assignment) => {
      count += assignment.assignment_students.filter(
        (as) => as.score === "/"
      ).length;
    });
    return count;
  };

  return (
    <>
      {terms.length > 0 ? (
        <Grid container>
          <Grid item md={6} xs={6}>
            <Typography sx={gridItemDate}>{displayCurrentTerm()}</Typography>
          </Grid>
          <Grid item md={6} xs={6}>
            <FormControl sx={gridItemSelect}>
              <InputLabel id="term-label">
                {t("term", { ns: "common" })}
              </InputLabel>
              {terms.length > 0 && (
                <Select
                  labelId="term-label"
                  id="term"
                  value={termIdParam || selectedTerm.id}
                  label={t("term", { ns: "common" })}
                  onChange={handleTermChange}
                >
                  {terms.map((term) => (
                    <MenuItem
                      key={term.id}
                      value={term.id}
                      onClick={() => setSelectedTerm(term)}
                    >
                      {term.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FormControl>
          </Grid>
        </Grid>
      ) : (
        <TableContainer sx={tableContainer}>
          <Table aria-label="classes">
            <TableBody>
              <TableRow sx={textCenter}>
                <TableCell style={{ borderBottom: "none" }} sx={textCenter}>
                  {loading
                    ? t("loading", { ns: "common" })
                    : t("noTerms", { keyPrefix: "classes" })}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <TableContainer sx={tableContainer}>
        <Table aria-label="classes">
          {termGrades.length > 0 && (
            <>
              <TableHead sx={tableHead}>
                <TableRow sx={tableHeadRow}>
                  <TableCell>{t("class", { ns: "common" })}</TableCell>
                  <TableCell>{t("teacher", { ns: "common" })}</TableCell>
                  <TableCell sx={textCenter}>
                    {t("countMissingAssignments", { keyPrefix: "classes" })}
                  </TableCell>
                  <TableCell sx={textCenter}>
                    {t("currentGrade", { keyPrefix: "classes" })}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {termGrades.map((termGrade) => (
                  <TableRow key={termGrade.id} hover sx={tableBodyRow}>
                    <TableCell
                      sx={{ cursor: "pointer" }}
                      /* eslint-disable-next-line no-return-assign */
                      onClick={() =>
                        navigate(
                          `/guardian/${guardianId}/students/${kidId}/classes/${termGrade.klass.id}?term=${termIdParam}`
                        )
                      }
                    >
                      {termGrade.klass.name}
                    </TableCell>
                    <TableCell>
                      {termGrade.klass.teacher.first_name}{" "}
                      {termGrade.klass.teacher.last_name}
                    </TableCell>
                    <TableCell
                      style={{ color: "#F50E0E", cursor: "pointer" }}
                      sx={textCenter}
                      onClick={() =>
                        navigate(
                          `/guardian/${guardianId}/students/${kidId}/classes/${termGrade.klass.id}?term=${termIdParam}&assignments=missing`
                        )
                      }
                    >
                      {calculateMissingAssignments(
                        termGrade.klass.assignments
                      ) || ""}
                    </TableCell>
                    <TableCell sx={textCenter}>
                      {termGradeLetter(termGrade, specialMarks)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </>
          )}
          {terms.length > 0 && termGrades.length === 0 && (
            <TableBody>
              <TableRow sx={textCenter}>
                <TableCell style={{ borderBottom: "none" }} sx={textCenter}>
                  {loading
                    ? t("loading", { ns: "common" })
                    : t("noClassesSelected", { keyPrefix: "classes" })}
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
}
