const klassFormAssignmentGradingContainer = {
  mt: "20px",
  width: "200px",
};

const klassFormAssignmentGradingWeightContainer = {
  mt: "105px",
  width: "250px",
};

export {
  klassFormAssignmentGradingContainer,
  klassFormAssignmentGradingWeightContainer,
};
