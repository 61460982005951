import { Checkbox, Grid, FormControlLabel } from "@mui/material";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

export default function IsMultiselectComponent({ selectedField }) {
  const { register, setValue, getValues } = useFormContext();

  const isPrefilledOptions = () =>
    [
      "Gender",
      "Language",
      "Ethnicity",
      "Denomination",
      "State",
      "Country",
      "Relationship to the student",
    ].includes(selectedField.label);

  useEffect(() => {
    setValue("isMultiselect", selectedField.properties.isMultiselect, {
      shouldDirty: true,
    });
  }, [selectedField]);

  if (isPrefilledOptions()) return;

  // eslint-disable-next-line consistent-return
  return (
    <Grid item md={12}>
      <FormControlLabel
        sx={{ width: "100%" }}
        control={
          <Checkbox
            checked={getValues("isMultiselect")}
            onChange={(e) => {
              setValue("isMultiselect", e.target.checked, {
                shouldDirty: true,
              });
            }}
          />
        }
        label="Multi select"
        {...register("isMultiselect")}
        disabled={selectedField.source_field_id}
      />
    </Grid>
  );
}
