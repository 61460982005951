import React, { useRef, useContext } from "react";
import { useForm, FormProvider } from "react-hook-form";
import eventsService from "../../../../service/eventsService";
import { SnackbarContext } from "../../../../context/SnackbarContext";
import EventsForm from "../../Events/EventsForm";
import { StudentActionsContext } from "../../../../context/StudentActionsContext";

export default function EventCreate({
  studentId,
  setIsEventDrawerOpen,
  fetchAllEvents = () => {},
}) {
  const snackbarContext = useRef(useContext(SnackbarContext));
  const methods = useForm();
  const studentActionsContext = useContext(StudentActionsContext);

  const onSubmit = async (data) => {
    const response = await eventsService.createEvent(studentId, data);

    if (response.data) {
      snackbarContext.current.setSnackbar({
        open: true,
        message: "Event created.",
      });
      setIsEventDrawerOpen(false);
      fetchAllEvents();
      if (studentActionsContext.fireMedicalEventsUpdated)
        studentActionsContext.fireMedicalEventsUpdated(true);
    }
  };

  return (
    <FormProvider {...methods}>
      <EventsForm
        setIsEventDrawerOpen={setIsEventDrawerOpen}
        onSubmit={onSubmit}
      />
    </FormProvider>
  );
}
