import React, { useEffect, useState, useRef, useContext } from "react";
import {
  Link as RouterLink,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  Box,
  Stack,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
  Tooltip,
  ButtonBase,
  FormControlLabel,
  Switch,
} from "@mui/material";
import GradebookRow from "./GradebookRow";
import {
  gTAssignmentContainer,
  gTAssignmentName,
  gTDayHead,
  gTAssignmentCell,
  gTStudentRow,
  gTTableCellsSm,
  gTClassWork,
  gTClassWorkContainer,
  gTReportedGrade,
  gTReportedGradeContainer,
  gTMax,
  gTHeader,
  gTTableContainer,
  gTTable,
  gTTableEmptyName,
  gTTableStickyCell,
  gTTableHead,
  emptyAssignmentMessageContainer,
  gTTableBorderlessHeader,
} from "./GradebookTable.styles";
import { font14, font20, switchBtn } from "../../sharedStyles";
import moment from "../../../utils/constants/momentConfig";
import dateTimeFormats from "../../../utils/constants/dateTimeFormats";
import specialMarkService from "../../../service/specialMarkService";
import truncateString from "../../../utils/truncateString";
import PopoverComponent from "../../ToolTip/ToolTip";
import assignmentStudentService from "../../../service/assignmentStudentService";
import assignmentService from "../../../service/assignmentService";
import { SnackbarContext } from "../../../context/SnackbarContext";

export default function Gradebook({
  assignmentDates,
  renderStudents,
  grades,
  categories,
  setCategories,
  categoryScore,
  totalScores,
  setGrades,
  setCategoryScores,
  setTotalScores,
  currentCell,
  setCurrentCell,
  noCategoryKey,
  loading,
  setLoading,
  setMaxScoreChanged,
  ungradedOnly,
  setUngradedOnly,
}) {
  const schoolId = useParams().school_id;
  const subjectId = useParams().subject_id;
  const [searchParams] = useSearchParams();
  const klassesIds = searchParams.get("classes") || null;

  const [specialMarks, setSpecialMarks] = useState([]);
  const inputsRef = useRef();

  const snackbarContext = useContext(SnackbarContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentTarget, setCurrentTarget] = useState(null);
  const [showSpecialMarks, setShowSpecialMarks] = useState(false);
  const [currentMarkingCodes, setCurrentMarkingCodes] = useState([]);
  const [currentAssignment, setCurrentAssignment] = useState([]);
  const timeout = useRef();

  const renderGrading = (grading, maxScore) => {
    if (grading === "percent") {
      return <Typography sx={gTMax}>Percent</Typography>;
    }

    if (grading === "letter_grade") {
      return <Typography sx={gTMax}>Grade</Typography>;
    }

    return <Typography sx={gTMax}>Max {maxScore}</Typography>;
  };

  useEffect(() => {
    (async () => {
      const response = await specialMarkService.fetchSchoolSpecialMarks({
        params: {
          school_id: schoolId,
        },
      });
      if (response.data) {
        setSpecialMarks(response.data.special_marks);
      }
    })();
  }, []);

  const getInputsRefMap = () => {
    if (!inputsRef.current) {
      inputsRef.current = new Map();
    }
    return inputsRef.current;
  };

  const getMarkingCodes = (student) => {
    const markingCodes = student?.klass?.grading_scale?.marking_codes;

    if (!markingCodes) {
      return [];
    }

    const isPassFail = student?.klass?.is_pass_fail;
    const isABC = markingCodes.some(
      (item) => item.code === "A" || item.code === "B"
    );

    if (isPassFail) {
      return markingCodes.filter(({ code }) => code === "P" || code === "F");
    }
    if (isABC) {
      return markingCodes.filter(({ code }) => code !== "P");
    }

    return markingCodes;
  };

  const updateStudentsAssignmentsGrade = (studentGradeArray) => {
    clearTimeout(timeout.current);
    snackbarContext.setSnackbar({
      message: "Saving...",
      severity: "info",
      open: true,
    });
    timeout.current = setTimeout(async () => {
      const postGrade = await assignmentStudentService
        .updateAndCalculateMultipleScores({
          students_scores: studentGradeArray,
        })
        .catch(() =>
          snackbarContext.setSnackbar({
            message: "Error, try again later.",
            severity: "error",
            open: true,
          })
        );

      if (postGrade) {
        const gradesResponse = postGrade.data.data.scores;
        const categoryScoresResponse = {};
        const totalScoreResponse = {};
        studentGradeArray.forEach((studentGrade) => {
          const student = renderStudents.find(
            (rs) => rs.id === Number(studentGrade.student_id)
          );
          const key = `${studentGrade.student_id}-${studentGrade.assignment_id}-${student.klass.id}`;
          const categoryKey = grades[key].assignment.category
            ? `${student.id}-${student.klass.id}-${grades[key].assignment.category}`
            : `${student.id}-${student.klass.id}-${noCategoryKey}`;
          const totalKey = `${student.id}-${student.klass.id}`;

          const categoryScoresKey =
            grades[key].assignment.category === null
              ? `[${student.id}, ${student.klass.id}, "${noCategoryKey}"]`
              : `[${student.id}, ${student.klass.id}, "${grades[key].assignment.category}"]`;

          const grade = gradesResponse.find(
            (g) => !!g.category_grades[categoryScoresKey]
          );

          categoryScoresResponse[categoryKey] =
            grade.category_grades[categoryScoresKey]?.category_score;

          totalScoreResponse[totalKey] = grade.total_grades[totalKey];
        });

        setCategoryScores({
          ...categoryScore,
          ...categoryScoresResponse,
        });
        setTotalScores({
          ...totalScores,
          ...totalScoreResponse,
        });

        snackbarContext.setSnackbar({
          message: "Grades saved successfully",
          severity: "success",
          open: true,
        });
      }
    }, 2000);
  };

  const handleGradeChange = (_student, _assignment, value) => {
    setTimeout(() => {
      setAnchorEl(null);
    });
    const inputs = document.getElementsByClassName(
      `assignment_grade_input_${_assignment.id}`
    );
    const studentGradeArray = [];
    for (let i = 0; i < inputs.length; i += 1) {
      if (!inputs[i].value && !inputs[i].disabled) {
        inputs[i].value = value;
        const studentId = inputs[i].id;
        const student = renderStudents.find(
          (as) => as.id === Number(studentId)
        );
        // eslint-disable-next-line no-param-reassign
        grades[`${student.id}-${_assignment.id}-${student.klass.id}`].score =
          value;
        studentGradeArray.push({
          assignment_id: _assignment.id,
          date: searchParams.get("date"),
          klasses_ids: klassesIds?.split(",").map((k) => Number(k)),
          subject_id: subjectId,
          student_id: studentId,
          score: value === "\\" ? "✓" : value.toUpperCase(),
        });
      }
    }
    updateStudentsAssignmentsGrade(studentGradeArray);
  };

  const handleMaxScoreChange = async (assignment, maxScore) => {
    setTimeout(() => {
      setAnchorEl(null);
    });
    const updateMaxScore = await assignmentService
      .updateMaxScore({
        assignmentId: assignment.id,
        max_score: maxScore || assignment.max_score,
      })
      .catch(() =>
        snackbarContext.setSnackbar({
          message: "Error, try again later.",
          severity: "error",
          open: true,
        })
      );
    if (updateMaxScore) {
      snackbarContext.setSnackbar({
        message: "Saved",
        severity: "success",
        open: true,
      });
      setMaxScoreChanged(true);
    }
  };

  const handleGradeClick = (target, assignment) => {
    setAnchorEl(target);
    setCurrentTarget(target);
    setCurrentAssignment(assignment);
    setCurrentMarkingCodes(assignment.klass.grading_scale.marking_codes);
  };

  return (
    <>
      <TableContainer sx={gTTableContainer}>
        <Table sx={gTTable} stickyHeader>
          <TableHead sx={gTTableHead}>
            <TableRow>
              <TableCell
                sx={{
                  ...gTTableStickyCell,
                  pt: 10,
                  pl: 0,
                  borderBottom: "none !important",
                }}
              >
                <FormControlLabel
                  sx={{ pt: "10px" }}
                  label="Ungraded Only"
                  labelPlacement="start"
                  control={
                    <Switch
                      sx={switchBtn}
                      checked={ungradedOnly}
                      onChange={(e) => {
                        setUngradedOnly(e.target.checked);
                      }}
                    />
                  }
                />
              </TableCell>
              {assignmentDates.map((date) => (
                <TableCell
                  key={date.assignedDate}
                  align="center"
                  sx={gTDayHead}
                >
                  <Typography sx={font20}>
                    {`${moment(date.assignedDate).format(
                      dateTimeFormats.ddDYY
                    )}`}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
            <TableRow sx={gTStudentRow}>
              <TableCell sx={gTTableStickyCell}>
                <TableCell sx={gTTableEmptyName} />
                <TableCell sx={gTTableCellsSm} align="center">
                  <Typography sx={font14}>Class</Typography>
                </TableCell>
              </TableCell>
              {assignmentDates.length > 0 ? (
                assignmentDates.map((date) => (
                  <TableCell key={date.assignedDate} sx={gTAssignmentCell}>
                    <Stack direction="row">
                      {Object.values(date.assignments).map((assignment) => (
                        <Box
                          key={`assignment_${assignment.id}`}
                          sx={gTAssignmentContainer}
                        >
                          <Typography sx={font14}>
                            {assignment.klass.abbreviation}
                          </Typography>
                          <Link
                            to={`/school/${schoolId}/subjects/${subjectId}/class/${
                              assignment.klass.id
                            }/assignments/${
                              assignment.id
                            }?term=${searchParams.get("term")}`}
                            component={RouterLink}
                            key={`${assignment.id}_${Math.random()}`}
                            sx={gTAssignmentName}
                          >
                            <Tooltip
                              placement="top"
                              title={assignment.name}
                              key={assignment.id}
                            >
                              <Typography lineHeight={1}>
                                {truncateString(assignment.name, 30)}
                              </Typography>
                            </Tooltip>
                          </Link>
                          <ButtonBase
                            onClick={(e) =>
                              handleGradeClick(e.currentTarget, assignment)
                            }
                          >
                            {renderGrading(
                              assignment.grading,
                              assignment.max_score
                            )}
                          </ButtonBase>
                        </Box>
                      ))}
                    </Stack>
                  </TableCell>
                ))
              ) : (
                <TableCell sx={gTTableBorderlessHeader}>
                  <Box />
                </TableCell>
              )}
              {categories.map((category) => (
                <TableCell key={category} sx={gTClassWork}>
                  <Box sx={gTClassWorkContainer}>
                    <Typography align="center" sx={gTHeader}>
                      {category}
                    </Typography>
                  </Box>
                </TableCell>
              ))}
              <TableCell sx={gTReportedGrade}>
                <Box sx={gTReportedGradeContainer}>
                  <Typography align="center" sx={gTHeader}>
                    REPORTED <br /> GRADE
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderStudents.map((student, index) => (
              <GradebookRow
                key={`${student.id}-${student.klass.id}`}
                student={student}
                assignmentDates={assignmentDates}
                grades={grades}
                categories={categories}
                setCategories={setCategories}
                categoryScore={categoryScore}
                totalScores={totalScores}
                setGrades={setGrades}
                setCategoryScores={setCategoryScores}
                setTotalScores={setTotalScores}
                loading={loading}
                setLoading={setLoading}
                currentCell={currentCell}
                setCurrentCell={setCurrentCell}
                specialMarks={specialMarks}
                getInputsRefMap={getInputsRefMap}
                noCategoryKey={noCategoryKey}
                markingCodes={getMarkingCodes(student)}
                index={index}
                numberOfRows={renderStudents.length}
              />
            ))}
            {assignmentDates.length === 0 && (
              <Box sx={emptyAssignmentMessageContainer}>
                <Typography>No assignments for this week</Typography>
              </Box>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <PopoverComponent
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        showSpecialMarks={showSpecialMarks}
        setShowSpecialMarks={setShowSpecialMarks}
        handleClick={handleGradeChange}
        handleMaxScoreChange={handleMaxScoreChange}
        currentAssignment={currentAssignment}
        currentTarget={currentTarget}
        specialMarks={specialMarks}
        markingCodes={currentMarkingCodes}
        gradingInput={currentAssignment.grading}
        grades={Object.values(grades)}
      />
    </>
  );
}
