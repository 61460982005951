const classesSubtitle = {
  fontSize: "24px",
  color: "#3F464B",
};

const classesClassSelectContainer = {
  width: "200px",
};

const classesLink = {
  color: "#5077A4",
  textDecoration: "none",
  fontSize: "14px",
};

const subjectContainer = {
  borderTop:
    "1px sol<>\n" +
    "      <Box sx={subjectContainer} />\n" +
    '      <Container maxWidth={false} variant="header">\n' +
    '        <Container maxWidth="xl">\n' +
    "          <TermGradeTabs\n" +
    "            schoolId={schoolId}\n" +
    "            subjectId={subjectId}\n" +
    "            klassesIds={klassesIds}\n" +
    "            termId={termId}\n" +
    "          />\n" +
    "\n" +
    "          <Box sx={termGradeContainer}>\n" +
    "            <Outlet context={[schoolId, subjectId, klassesIds, termId]} />\n" +
    "          </Box>\n" +
    "        </Container>\n" +
    "      </Container>\n" +
    "    </>id black",
  mx: "65px",
};

const searchContainer = {
  display: "flex",
  justifyContent: "end",
  paddingX: "65px",
};

const subjectTopContainer = {
  height: "55px",
  zIndex: 9999,
};

const pageTabsSubject = {
  overflow: "visible",
  mt: 0,
  mr: "10px",
  minHeight: "32px",
  "& .MuiTab-root": {
    p: 0,
    fontSize: "18px",
    color: "black",
    textTransform: "none",
    fontWeight: "normal",
    mr: "10px",
    minHeight: "100%",
  },
};

export {
  classesSubtitle,
  classesClassSelectContainer,
  classesLink,
  subjectContainer,
  searchContainer,
  subjectTopContainer,
  pageTabsSubject,
};
