import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import {
  Box,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { font12, font20, mb45 } from "../../../sharedStyles";
import {
  termGradeDetailRadioContainer,
  termGradeDetailLabel,
} from "./InstructionalLevel.styles";

export default function InstructionalLevel({ activeGrade }) {
  const { control } = useFormContext();

  return (
    <Box sx={mb45}>
      <Typography sx={font20}>Instructional Level</Typography>
      <Controller
        name="instructional_level"
        control={control}
        defaultValue={activeGrade?.termGrade?.instructional_level}
        render={({ field }) => (
          <FormControl>
            <RadioGroup row {...field}>
              <FormControlLabel
                value="_none"
                control={<Radio />}
                label={
                  <Box sx={termGradeDetailLabel}>
                    <Typography>None</Typography>
                  </Box>
                }
                sx={termGradeDetailRadioContainer}
                disabled={activeGrade?.readOnly}
              />
              <FormControlLabel
                value="x"
                control={<Radio />}
                sx={termGradeDetailRadioContainer}
                disabled={activeGrade?.readOnly}
                label={
                  <Box sx={termGradeDetailLabel}>
                    <Typography>X</Typography>
                    <Typography sx={font12}>
                      Student performs beyond what a standard requires
                    </Typography>
                  </Box>
                }
              />
              <FormControlLabel
                value="y"
                control={<Radio />}
                sx={termGradeDetailRadioContainer}
                disabled={activeGrade?.readOnly}
                label={
                  <Box sx={termGradeDetailLabel}>
                    <Typography>Y</Typography>
                    <Typography sx={font12}>
                      Student is proficient on grade level standards
                    </Typography>
                  </Box>
                }
              />
              <FormControlLabel
                value="ya"
                control={<Radio />}
                sx={termGradeDetailRadioContainer}
                disabled={activeGrade?.readOnly}
                label={
                  <Box sx={termGradeDetailLabel}>
                    <Typography>YA</Typography>
                    <Typography sx={font12}>
                      Student can attain grade level standards with
                      accommodations
                    </Typography>
                  </Box>
                }
              />
              <FormControlLabel
                value="z"
                control={<Radio />}
                sx={termGradeDetailRadioContainer}
                disabled={activeGrade?.readOnly}
                label={
                  <Box sx={termGradeDetailLabel}>
                    <Typography>Z</Typography>
                    <Typography sx={font12}>
                      Grade level standards are modified for student success
                    </Typography>
                  </Box>
                }
              />
            </RadioGroup>
          </FormControl>
        )}
      />
    </Box>
  );
}
