import CheckboxRenderer from "./Components/CheckboxRenderer";
import DateFieldRenderer from "./Components/DateFieldRenderer";
import DescriptionAreaRenderer from "./Components/DescriptionAreaRenderer";
import DropdownListRenderer from "./Components/DropdownListRenderer";
import EmailAddressRenderer from "./Components/EmailAddressRenderer";
import HorizontalDividerRenderer from "./Components/HorizontalDividerRenderer";
import LongTextRenderer from "./Components/LongTextRenderer";
import NameRenderer from "./Components/NameRenderer";
import NumberRenderer from "./Components/NumberRenderer";
import PhoneNumberRenderer from "./Components/PhoneNumberRenderer";
import RadiobuttonListRenderer from "./Components/RadiobuttonListRenderer";
import ShortTextRenderer from "./Components/ShortTextRenderer";
import SignatureField from "./Components/SignatureField";
import SubHeadingRenderer from "./Components/SubHeadingRenderer";

const formRendererMap = {
  "short-text": ShortTextRenderer,
  "long-text": LongTextRenderer,
  "name-field": NameRenderer,
  "phone-number": PhoneNumberRenderer,
  "email-address": EmailAddressRenderer,
  "number-field": NumberRenderer,
  "dropdown-list": DropdownListRenderer,
  "radio-button-list": RadiobuttonListRenderer,
  "checkbox-field": CheckboxRenderer,
  "date-field": DateFieldRenderer,
  "description-area": DescriptionAreaRenderer,
  "horizontal-divider": HorizontalDividerRenderer,
  "signature-field": SignatureField,
  "sub-heading": SubHeadingRenderer,
};

export default formRendererMap;
