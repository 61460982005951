const styledGrid = {
  borderRight: 1,
  borderColor: "divider",
  py: "45px !important",
  px: "0 !important",
};

const styledRow = {
  "&:last-child td, &:last-child th": {
    border: 0,
  },
};

const styledCell = {
  color: "#000000",
  padding: 0,
  borderColor: "#000000",
};

const headerCell = {
  color: "#000000",
  textTransform: "uppercase",
  padding: 0,
  borderColor: "#000000",
};

const paddedHeaderCell = {
  color: "#000000",
  textTransform: "uppercase",
  borderColor: "#000000",
};

const calendarIconLeft = { transform: "rotate(90deg)" };
const calendarIconRight = { transform: "rotate(-90deg)" };

const calendar = (open) => ({
  mr: open ? "25px" : "40px",
});

export {
  styledGrid,
  styledRow,
  styledCell,
  headerCell,
  paddedHeaderCell,
  calendarIconLeft,
  calendarIconRight,
  calendar,
};
