import React from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Box, Typography, Button, Chip } from "@mui/material";
import {
  attachmentsInfoContainer,
  attachmentBtnLabel,
  attachmentsBox,
  messagesAttachIcon,
  attachButton,
  attachmentsList,
} from "./Attachments.styles";

export default function StudentPortalAttachments({
  handleFileUploads,
  uploadedFiles,
  setUploadedFiles,
}) {
  const handleDeleteFile = (objId) => {
    setUploadedFiles(uploadedFiles.filter((file) => file.id !== objId));
  };

  return (
    <Box sx={attachmentsBox}>
      <Button
        sx={attachButton}
        variant="text"
        component="label"
        startIcon={<AttachFileIcon sx={messagesAttachIcon} />}
      >
        <Typography sx={attachmentBtnLabel}>Add attachment...</Typography>
        <input
          hidden
          accept=".jpeg, .jpg, .pdf, .png, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .zip"
          type="file"
          multiple
          onChange={handleFileUploads}
        />
      </Button>

      <Box sx={attachmentsList}>
        {uploadedFiles &&
          uploadedFiles.map((file) => (
            <Box sx={{ margin: "5px" }} key={file.id}>
              <Chip
                label={file.name}
                onDelete={() => handleDeleteFile(file.id)}
              />
            </Box>
          ))}
      </Box>

      <Box sx={attachmentsInfoContainer}>
        <Typography>
          A maximum of 10 attachments may be added to a message. Supported file
          types include PDF, Word, Excel, PowerPoint, ZIP, JPEG, TIFF, and PNG.
          File size limited to 20 megs per file.
        </Typography>
      </Box>
    </Box>
  );
}
