import theme from "../../../utils";

const infoMedicalContainer = { mb: "45px", width: "100%", textAlign: "right" };

const infoMedicalFormControl = {
  "& .MuiFormControlLabel-label": {
    fontSize: "25.92px",
    color: "black",
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: theme.palette.hover.primary,
  },
};

const infoMedicalBox = {
  width: "100%",
  pr: "30px",
  color: "black",
};

const infoMedicalStack = { mt: "17px", alignItems: "center" };

const infoMedicalImgBox = {
  width: 250,
  height: 325,
  bottom: 47,
  position: "relative",
  margin: "auto",
};

const infoMedicalImg = {
  width: "inherit",
  height: "inherit",
  objectFit: "contain",
  position: "absolute",
};

const infoMedicalButton = { color: "black" };
const medicalRecordDrawer = {
  "& .MuiPaper-root": {
    backgroundColor: "white",
    width: "900px",
  },
};
const profilePicContainer = {
  textAlign: "center",
  position: "relative",
  display: "flex",
  justifyContent: "center",
};
const academicsImg = {
  width: "100%",
  height: "inherit",
  objectFit: "cover",
  backgroundColor: "black",
};
const accountVerticalContainer = {
  borderRight: 1,
  borderColor: "divider",
};

export {
  infoMedicalBox,
  infoMedicalImg,
  infoMedicalContainer,
  infoMedicalFormControl,
  infoMedicalImgBox,
  infoMedicalStack,
  infoMedicalButton,
  medicalRecordDrawer,
  profilePicContainer,
  academicsImg,
  accountVerticalContainer,
};
