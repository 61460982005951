import { useState, useEffect } from "react";
import formattedGrade from "../utils/formattedGrade";

function useGrade(score, maxScore, markingCodes, specialMarks) {
  const [grade, setGrade] = useState("");

  useEffect(
    () => formattedGrade(score, maxScore, markingCodes, specialMarks, setGrade),
    [score, maxScore, specialMarks]
  );

  return grade;
}

export default useGrade;
