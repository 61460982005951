export const ConditionNodeType = {
  GROUP: 1,
  NODE: 2,
};

export const LogicalOperator = {
  AND: 0,
  OR: 1,
};

export const ValueType = {
  FIELD: 0,
  FIXED: 1,
};

export const conditionOperators = [
  {
    value: "==",
    text: "Equals",
    fieldTypes: [
      "short-text",
      "long-text",
      "name-field",
      "dropdown-list",
      "radio-button-list",
      "checkbox-field",
      "number-field",
      "email-address",
      "phone-number",
      "date-field",
    ],
    type: "comparison",
  },
  {
    value: "!=",
    text: "Not equals",
    fieldTypes: [
      "short-text",
      "long-text",
      "name-field",
      "dropdown-list",
      "radio-button-list",
      "number-field",
      "email-address",
      "phone-number",
      "date-field",
    ],
    type: "comparison",
  },
  {
    value: ">",
    text: "Greater than",
    fieldTypes: ["number-field", "date-field"],
    type: "comparison",
  },
  {
    value: "<",
    text: "Less than",
    fieldTypes: ["number-field", "date-field"],
    type: "comparison",
  },
  {
    value: "<=",
    text: "Less than or Eqaul",
    fieldTypes: ["number-field", "date-field"],
    type: "comparison",
  },
  {
    value: ">=",
    text: "Greater than or Eqaul",
    fieldTypes: ["number-field", "date-field"],
    type: "comparison",
  },
  {
    value: "includes",
    text: "Contains",
    fieldTypes: [
      "short-text",
      "long-text",
      "dropdown-list",
      "radio-button-list",
      "name-field",
      "email-address",
      "phone-number",
    ],
    type: "string",
  },
  {
    value: "startsWith",
    text: "Starts with",
    fieldTypes: [
      "short-text",
      "long-text",
      "name-field",
      "email-address",
      "phone-number",
    ],
    type: "string",
  },
  {
    value: "endsWith",
    text: "Ends with",
    fieldTypes: [
      "short-text",
      "long-text",
      "name-field",
      "email-address",
      "phone-number",
    ],
    type: "string",
  },
];
