import * as React from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  ButtonBase,
} from "@mui/material";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import PrivacyTipOutlinedIcon from "@mui/icons-material/PrivacyTipOutlined";
import { useContext } from "react";
import {
  paddedMedicalCell,
  paddedMedicalCellHeader,
  titleStyle,
  tableContainerStyle,
} from "./MedicalDetailsSection.style";
import { my30 } from "../../sharedStyles";
import APP_PERMISSIONS from "../../../utils/constants/permissions";
import PERMISSION_TYPES from "../../../utils/constants/permission_types";
import { PermissionsContext } from "../../../context/PermissionsContext";

const medicalTableHeader = { "td, th": { border: 0 } };
const medicalTableCell = {
  "&:last-child td, &:last-child th": {
    border: 0,
  },
};

export default function MedicalDetailsSection({
  title,
  details,
  editMedicalRecord,
}) {
  const { hasPermission } = useContext(PermissionsContext);

  const managePermission = hasPermission(
    APP_PERMISSIONS.STUDENT_MEDICAL,
    PERMISSION_TYPES.MANAGE
  );

  return (
    <Box sx={my30}>
      <Typography variant="title" sx={titleStyle}>
        {title}
      </Typography>

      <TableContainer sx={tableContainerStyle}>
        <Table>
          <TableHead>
            <TableRow sx={medicalTableHeader}>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {details.map((detail) => (
              <TableRow
                key={detail.id}
                hover
                role="checkbox"
                sx={medicalTableCell}
                onClick={() => {
                  if (managePermission) editMedicalRecord(detail.id);
                }}
                style={managePermission ? { cursor: "pointer" } : {}}
              >
                <TableCell padding="none" sx={paddedMedicalCellHeader}>
                  <p>{detail.name}</p>
                </TableCell>
                <TableCell align="left" sx={paddedMedicalCell}>
                  <p>{detail.comment_or_instruction}</p>
                </TableCell>
                <TableCell padding="checkbox" align="center">
                  {detail.has_attachment && (
                    <ButtonBase>
                      <DescriptionOutlinedIcon />
                    </ButtonBase>
                  )}
                </TableCell>
                <TableCell>
                  {detail.display_alerts_in_application && (
                    <PrivacyTipOutlinedIcon />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
