import { Grid, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import * as React from "react";
import { useContext } from "react";
import TextEditor from "../../TextEditor/TextEditor";
import { PermissionsContext } from "../../../context/PermissionsContext";
import APP_PERMISSIONS from "../../../utils/constants/permissions";
import PERMISSION_TYPES from "../../../utils/constants/permission_types";

export default function Notes({ student }) {
  const { setValue, getValues } = useFormContext();
  let timer;

  const { hasPermission } = useContext(PermissionsContext);

  const managePermission = hasPermission(
    APP_PERMISSIONS.STUDENT_PROFILE,
    PERMISSION_TYPES.MANAGE
  );

  React.useEffect(() => {
    setValue("notes", student.notes);
  }, []);
  const onNotesChange = (data) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      if (getValues("notes") === data) return;
      setValue("notes", data, { shouldDirty: true });
    }, 2000);
  };
  return (
    <Grid container mb={5}>
      <Grid item xs={12} my={3}>
        <Typography variant="profileTitle">Notes</Typography>
      </Grid>
      <Grid item xs={12} px={3}>
        <TextEditor
          value={student.notes}
          onChange={(data) => onNotesChange(data)}
          isSimplified
          readonly={!managePermission}
        />
      </Grid>
    </Grid>
  );
}
