import axios from "../utils/axios-util";

const fetchFile = async (params, url) => {
  axios({
    url,
    method: "GET",
    responseType: "blob",
  }).then((response) => {
    // create file link in browser's memory
    const href = URL.createObjectURL(response.data);

    // create "a" HTML element with href to file & click
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", params.filename);
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
};

const fetchProfileFile = async (params) => {
  await fetchFile(
    params,
    `/api/student/${params.student_id}/files/${params.id}/profile`
  );
};

const fetchMedicalFile = async (params) => {
  await fetchFile(
    params,
    `/api/student/${params.student_id}/files/${params.id}/medical`
  );
};

const fetchTurnedInAssignmentFile = async (params) => {
  await fetchFile(
    params,
    `/api/student/${params.student_id}/files/${params.id}/turned_in_assignment`
  );
};

const fetchAssignmentFile = async (params) => {
  await fetchFile(
    params,
    `/api/student/${params.student_id}/files/${params.id}/assignment`
  );
};

const fetchMessageFile = async (params) => {
  await fetchFile(
    params,
    `/api/messages/${params.conversationKey}/files/${params.id}`
  );
};

const fetchStageElementFile = async (params) => {
  await fetchFile(
    params,
    `/api/messages/${params.element_instance_id}/files/${params.document_id}`
  );
};

export default {
  fetchProfileFile,
  fetchMedicalFile,
  fetchTurnedInAssignmentFile,
  fetchAssignmentFile,
  fetchMessageFile,
  fetchStageElementFile,
};
