import { useContext, useEffect } from "react";
import { SchedulerContext } from "../context/SchedulerContext";
import { useUnsavedChanges } from "../context/UnsavedChangesContext";

function BeforeUnload() {
  const schedulerContext = useContext(SchedulerContext);
  const { unsavedChanges } = useUnsavedChanges();

  const handleBeforeUnload = (event) => {
    if (
      schedulerContext.taskCount.current > 0 ||
      schedulerContext.savingCurrent.current ||
      unsavedChanges
    ) {
      // Cancel the event to prevent the default alert behavior
      event.preventDefault();

      // Chrome requires returnValue to be set
      // eslint-disable-next-line no-param-reassign
      event.returnValue = "";

      // Display a custom alert message to the user
      const confirmationMessage =
        "You have unsaved changes. Are you sure you want to leave?";

      return confirmationMessage;
    }
    return null;
  };

  useEffect(() => {
    // Add the event listener when the component mounts
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return null;
}

export default BeforeUnload;
