import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Box,
  Stack,
  Button,
  Typography,
  Divider,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { formHeader, formButtons, formTitle } from "../../sharedStyles";
import { form, schoolYearField, checkbox } from "./TermsForm.styles";
import schoolYearService from "../../../service/schoolYearService";
import termService from "../../../service/termService";
import GenericSubmitButton from "../../GenericSubmitButton";

export default function TermsForm({
  handleTermsDrawer,
  term,
  schoolYearId,
  setSnackBarOpen,
}) {
  const { control, register, handleSubmit } = useForm();
  const [schoolYear, setSchoolYear] = useState();
  const [fieldError, setFieldError] = useState([]);

  const onSubmit = async (formData) => {
    if (term) {
      const updatedTerm = { ...term, ...formData };
      try {
        const response = await termService.update({
          ...updatedTerm,
        });

        if (response.data) {
          setSnackBarOpen({
            open: true,
            message: "Term updated.",
          });
          handleTermsDrawer(false);
        }
      } catch (error) {
        setFieldError(error.response.data.errors);
      }
    } else {
      try {
        const response = await termService.create({
          ...formData,
          school_year_id: schoolYearId,
        });

        if (response.data) {
          setSnackBarOpen({
            open: true,
            message: "Term created.",
          });
          handleTermsDrawer(false);
        }
      } catch (error) {
        setFieldError(error.response.data.errors);
      }
    }
  };

  const deleteTerm = async () => {
    try {
      const response = await termService.deleteTerm(term.id);

      if (response.data) {
        setSnackBarOpen({
          open: true,
          message: "Term deleted.",
        });
        handleTermsDrawer(false);
      }
    } catch (error) {
      if (error.response.data.errors.length) {
        setFieldError(error.response.data.errors);
      } else {
        setSnackBarOpen({
          open: true,
          message: error.response.data.message,
          severity: "error",
        });
      }
    }
  };

  useEffect(() => {
    if (!term) {
      (async () => {
        const response = await schoolYearService.fetchById(schoolYearId);
        if (response.data) {
          setSchoolYear(response.data.school_year);
        }
      })();
    } else {
      setSchoolYear(term.school_year);
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="row" sx={formHeader}>
        <Typography sx={formTitle}>
          {term ? "Edit Term" : "New Term"}
        </Typography>
        <Stack direction="row">
          <Button sx={formButtons} onClick={() => handleTermsDrawer(false)}>
            Cancel
          </Button>
          {term && (
            <GenericSubmitButton
              text="Delete"
              submittingText="Deleting..."
              type="button"
              onClick={() => deleteTerm()}
            />
          )}
          <GenericSubmitButton
            text="Save and exit"
            submittingText="Saving..."
          />
        </Stack>
      </Stack>

      <Box sx={form}>
        {schoolYear && (
          <>
            <Stack mt={3}>
              <TextField
                disabled
                label="School Year"
                defaultValue={term?.school_year.name || schoolYear?.name}
                sx={schoolYearField}
              />
            </Stack>
            <Stack mt={2}>
              <TextField
                label="Name"
                defaultValue={term?.name}
                fullWidth
                {...register("name", {})}
                error={!!fieldError?.name}
                helperText={fieldError?.name ?? ""}
              />
            </Stack>
            <Stack direction="row" mt={2} mb={3}>
              <Box mr={2}>
                <Controller
                  name="start_date"
                  control={control}
                  defaultValue={term?.start_date || null}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      label="Start Date"
                      id="start_date"
                      value={value}
                      required
                      onChange={(e) => {
                        onChange(e);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={!!fieldError?.start_date}
                          helperText={fieldError?.start_date ?? ""}
                          sx={{ maxWidth: "250px" }}
                        />
                      )}
                    />
                  )}
                />
              </Box>
              <Controller
                name="end_date"
                control={control}
                defaultValue={term?.end_date || null}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    label="End Date"
                    id="end_date"
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={!!fieldError?.end_date}
                        helperText={fieldError?.end_date ?? ""}
                        sx={{ maxWidth: "250px" }}
                      />
                    )}
                  />
                )}
              />
            </Stack>
            <Divider />
            <FormControlLabel
              sx={checkbox}
              control={
                <Checkbox
                  defaultChecked={term?.is_summer_term || false}
                  {...register("is_summer_term", {})}
                />
              }
              label={
                <Box>
                  <Typography>Is a summer term</Typography>
                </Box>
              }
            />
          </>
        )}
      </Box>
    </form>
  );
}
