import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
  TextField,
  Button,
  Box,
} from "@mui/material";
import moment from "moment";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import temporaryStaffService from "../../../service/temporaryStaffService";
import staffsService from "../../../service/staffsService";
import { SnackbarContext } from "../../../context/SnackbarContext";
import { danger } from "../../sharedStyles";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 476,
  bgcolor: "white",
  boxShadow:
    "0px 4px 18px 3px rgba(0, 0, 0, 0.12), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 6px 6px -3px rgba(0, 0, 0, 0.2)",
  p: "30px",
  borderRadius: "12px",
};
export default function RecordSubstitute({
  open,
  substituteId,
  setRecordSubstituteOpen,
  isTemporary,
}) {
  const [substitutionDate, setSubstitutionDate] = useState(null);
  const [substitues, setSubstitutes] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const params = useParams();
  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const snackbarContext = useContext(SnackbarContext);

  const handleClose = () => {
    setRecordSubstituteOpen(false);
  };

  const onSubstitutionDate = (date) => {
    if (date) {
      setSubstitutionDate(date);
      setValue("substitution_date", date.format("MM/DD/YYYY"), {
        shouldDirty: true,
      });
    }
  };

  const onSubmit = (formData) => {
    const data = { ...formData };
    data.school_id = params.school_id;
    if (isTemporary) {
      data.substitute_type = "TemporaryStaff";
    } else {
      data.substitute_type = "Staff";
    }
    temporaryStaffService
      .createRecordSubtitute(data)
      .then(() => {
        handleClose();
        snackbarContext.setSnackbar({
          message: "Saved successfully.",
          severity: "success",
          open: true,
        });
      })
      .catch((err) => {
        if (err.response)
          snackbarContext.setSnackbar({
            message: err.response.data.error,
            severity: "error",
            open: true,
          });
      });
  };

  useEffect(() => {
    setValue("substitute_id", substituteId, { shouldDirty: true });
    temporaryStaffService.fetchAllStaffs().then((response) => {
      setSubstitutes(response);
    });
    staffsService
      .fetchAllStaffs({
        params: { school_id: params.school_id },
      })
      .then((response) => {
        setTeachers(response);
      });
  }, []);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        {open && (
          <Grid sx={style} container rowGap={4}>
            <Typography color="black" variant="h6" mb={2}>
              Record substitute
            </Typography>
            <Typography color="black" variant="body2">
              {isTemporary
                ? "Select the teacher and the date this temporary staff will be substituting for below."
                : "Select the teacher and the date this staff will be substituting for below."}
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="substitute-label" required>
                Substitute
              </InputLabel>
              <Select
                labelId="substitute-label"
                id="substitute"
                disabled
                defaultValue={substituteId || ""}
                label="Substitute*"
                {...register("substitute_id", {
                  required: "This field is required.",
                })}
              >
                {isTemporary &&
                  substitues.map((substitute) => (
                    <MenuItem key={substitute.id} value={substitute.id}>
                      {`${substitute.first_name} ${substitute.last_name}`}
                    </MenuItem>
                  ))}
                {!isTemporary &&
                  teachers.map((substitute) => (
                    <MenuItem key={substitute.id} value={substitute.id}>
                      {`${substitute.first_name} ${substitute.last_name}`}
                    </MenuItem>
                  ))}
              </Select>
              {errors.substitute_id && (
                <Typography sx={danger}>
                  {errors.substitute_id.message}
                </Typography>
              )}
            </FormControl>
            <FormControl fullWidth>
              <InputLabel
                id="teacher-label"
                required
                error={!!errors.teacher_id}
              >
                Teachers
              </InputLabel>
              <Select
                labelId="teacher-label"
                id="teacher"
                label="Teachers*"
                defaultValue=""
                error={!!errors.teacher_id}
                {...register("teacher_id", {
                  required: "This field is required.",
                })}
              >
                {isTemporary &&
                  teachers.map((teacher) => (
                    <MenuItem key={teacher.id} value={teacher.id}>
                      {`${teacher.first_name} ${teacher.last_name}`}
                    </MenuItem>
                  ))}
                {!isTemporary &&
                  teachers.map(
                    (teacher) =>
                      teacher.id !== substituteId && (
                        <MenuItem key={teacher.id} value={teacher.id}>
                          {`${teacher.first_name} ${teacher.last_name}`}
                        </MenuItem>
                      )
                  )}
              </Select>
              {errors.teacher_id && (
                <Typography sx={danger}>{errors.teacher_id.message}</Typography>
              )}
            </FormControl>
            <Grid container columnSpacing={2}>
              <Grid item md={7}>
                <DesktopDatePicker
                  id="substitute_date"
                  label="Date*"
                  onChange={onSubstitutionDate}
                  value={
                    substitutionDate || moment().format("MM/DD/YYYY").toString()
                  }
                  inputFormat="MM/DD/YYYY"
                  renderInput={(inputParams) => (
                    <TextField
                      {...inputParams}
                      error={!!errors.substitution_date}
                      {...register("substitution_date", {
                        required: "This field is required.",
                        pattern: {
                          value:
                            /(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/(19|20)\d{2}/,
                          message: "Invalid date.",
                        },
                      })}
                    />
                  )}
                />
                {errors.substitution_date && (
                  <Typography sx={danger}>
                    {errors.substitution_date.message}
                  </Typography>
                )}
              </Grid>
              <Grid item md={5}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    height: "100%",
                    alignItems: "center",
                  }}
                >
                  <Button
                    onClick={handleClose}
                    type="button"
                    variant="contained"
                    size="medium"
                    sx={{
                      backgroundColor: "#2196F3",
                      color: "white",
                      textTransform: "none",
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    size="medium"
                    sx={{
                      backgroundColor: "#2196F3",
                      color: "white",
                      textTransform: "none",
                    }}
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        )}
      </form>
    </Modal>
  );
}
