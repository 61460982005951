import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { Button, Fade, Grid, IconButton, Popover } from "@mui/material";
import NavigationStructure from "../../utils/constants/navigationStructure";
import FunctionCard from "./FunctionCard";
import Message from "./Message";
import NavigationList from "./NavigationList";
import {
  drawerAddIcon,
  drawerBorderGrid,
  drawerButton,
  drawerCardsContainer,
  drawerMessagesContainer,
  drawerNavigationContainer,
  drawerNavigationListContainer,
  drawerPopover,
} from "./Drawer.style";

export default function Drawer(props) {
  const [showNavigation, setShowNavigation] = React.useState(false);

  const handleNavigation = () => {
    setShowNavigation(!showNavigation);
  };

  return (
    <Popover
      {...props}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      sx={drawerPopover}
      TransitionComponent={Fade}
      BackdropProps={{ invisible: false, sx: { top: "30vh" } }}
    >
      <Grid container mt={3}>
        <Grid container item xs={12} sm={2} sx={drawerBorderGrid}>
          <Grid item xs={5} textAlign="end">
            <IconButton size="large" edge="start" color="inherit">
              <AddIcon sx={drawerAddIcon} />
            </IconButton>
          </Grid>
          <Grid item xs={7} alignSelf="flex-end">
            <Button onClick={handleNavigation} sx={drawerButton}>
              {showNavigation ? "Hide" : "Show"} Navigation
            </Button>
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={7} pr={2} sx={drawerCardsContainer}>
          <FunctionCard />
          <FunctionCard />
          <FunctionCard />
          <FunctionCard />
          <FunctionCard />
          <FunctionCard />
          <FunctionCard />
          <FunctionCard />
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          pr={5}
          pl={2}
          pt={2}
          sx={drawerMessagesContainer}
        >
          <Message />
          <Message />
          <Message />
          <Message />
          <Message />
        </Grid>
        <Grid container item xs={12} sx={drawerNavigationContainer}>
          {showNavigation && (
            <Fade in={showNavigation}>
              <Grid container item xs={12} sx={drawerNavigationListContainer}>
                <NavigationList
                  list={NavigationStructure.tasks}
                  pl={3}
                  borderRight
                />
                <NavigationList list={NavigationStructure.controls} />
                <NavigationList list={NavigationStructure.organizations} />
                <NavigationList list={NavigationStructure.staff} />
                <NavigationList list={NavigationStructure.students} />
                <NavigationList list={NavigationStructure.academics} />
              </Grid>
            </Fade>
          )}
        </Grid>
      </Grid>
    </Popover>
  );
}
