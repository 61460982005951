const SecurityGroupHeader = {
  fontSize: "24px",
  fontWeight: "400",
  marginBottom: "30px",
};

const SecurityGroupContainer = {
  borderTop: "1px solid black",
  height: "100%",
};

const SecurityGroupText = (selected) => ({
  fontSize: "20px",
  fontWeight: "500",
  padding: "20px 0px 20px 20px",
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  cursor: "pointer",
  ...(selected && { backgroundColor: "#2196F3", color: "white" }),
});

const SecurityGroupPermissionText = { fontSize: "20px", fontWeight: "500" };

const SecurityGroupUser = {
  fontSize: "16px",
  fontWeight: "400",
  marginBottom: "30px",
};

const SecurityGroupModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};

const securityGroupRowText = {
  fontSize: "10px",
  fontWeight: "500",
  textAlign: "center",
};

export {
  SecurityGroupHeader,
  SecurityGroupContainer,
  SecurityGroupText,
  SecurityGroupPermissionText,
  SecurityGroupUser,
  SecurityGroupModalStyle,
  securityGroupRowText,
};
