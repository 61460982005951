import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import dateTimeFormats from "./dateTimeFormats";

const convertBase64 = (file) =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });

const parameterizeString = (string) =>
  string
    .trim()
    .toLowerCase()
    .replace(/[^a-zA-Z0-9 -]/, "")
    .replace(/\s/g, "-");

const isNumber = (value) => /^(\d+\.?\d?)$/.test(value);

const formatPhoneNumber = (phoneNumber) => {
  // eslint-disable-next-line no-param-reassign
  phoneNumber ||= "";
  let formattedPhoneNumber = phoneNumber.replace(/\D/g, "");

  if (formattedPhoneNumber.length < 4) {
    formattedPhoneNumber = formattedPhoneNumber.replace(/(\d{1,3})/, "($1)");
  } else if (formattedPhoneNumber.length < 7) {
    formattedPhoneNumber = formattedPhoneNumber.replace(
      /(\d{1,3})(\d{1,3})/,
      "($1) $2"
    );
  } else {
    formattedPhoneNumber = formattedPhoneNumber.replace(
      /(\d{1,3})(\d{1,3})(\d{1,4})/,
      "($1) $2-$3"
    );
  }
  return formattedPhoneNumber;
};

function convertToOrdinal(text) {
  if (text === "K") {
    return "Kindergarten";
  }
  if (text === "TK") {
    return "Transitional Kindergarten";
  } // Use a regular expression to match numbers in the text
  const numberRegex = /\d+/g;

  // Replace each matched number with its ordinal form
  const result = text.replace(numberRegex, (match) => {
    const number = parseInt(match, 10); // Parse the matched number
    if (number % 100 >= 11 && number % 100 <= 13) {
      return `${match}th`; // Special case for 11th, 12th, and 13th
    }
    switch (number % 10) {
      case 1:
        return `${match}st`;
      case 2:
        return `${match}nd`;
      case 3:
        return `${match}rd`;
      default:
        return `${match}th`;
    }
  });

  return result;
}

function calculateAge(dateOfBirth) {
  const birthDate = new Date(dateOfBirth);

  const currentDate = new Date();

  const age = currentDate.getFullYear() - birthDate.getFullYear();

  if (
    currentDate.getMonth() < birthDate.getMonth() ||
    (currentDate.getMonth() === birthDate.getMonth() &&
      currentDate.getDate() < birthDate.getDate())
  ) {
    return age - 1;
  }

  return age;
}

function generateGUID() {
  return uuidv4();
}

const formatDateTime = (str) =>
  moment.utc(str).format(dateTimeFormats.MMDDYYYY);

const getProgressColor = (progress) => {
  if (progress === 100) return "rgba(102, 187, 106, 1)";
  if (progress >= 50) return "rgba(245, 203, 23, 1)";
  return "rgba(245, 127, 23, 1)";
};

export {
  convertBase64,
  parameterizeString,
  isNumber,
  formatPhoneNumber,
  convertToOrdinal,
  calculateAge,
  generateGUID,
  formatDateTime,
  getProgressColor,
};
