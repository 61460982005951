const admissionsContainer = {
  borderTop: "1px solid black",
  mx: "65px",
  mb: "150px",
};

const submitButton = {
  backgroundColor: "#2196F3",
  color: "white",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#2196F3",
  },
};

const approvalsButtonStyle = (selected) => ({
  backgroundColor: selected ? "#42A5F5" : "transparent",
  color: selected ? "white" : "black",
  "&:hover": {
    opacity: 0.7,
    backgroundColor: selected ? "#42A5F5" : "transparent",
  },
  "& .approvals-text": {
    px: 2,
    borderRight: "1px solid",
    borderColor: selected ? "white" : "black",
    fontWeight: 500,
  },
  "& .count-text": {
    px: 2,
    fontSize: 20,
    fontWeight: 500,
  },
});

const searchAndSelectContainer = { display: "flex", alignItems: "center" };

export {
  admissionsContainer,
  submitButton,
  searchAndSelectContainer,
  approvalsButtonStyle,
};
