import { Box, Container, FormControl, Stack, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import {
  Outlet,
  useParams,
  useSearchParams,
  useOutletContext,
} from "react-router-dom";
import moment from "moment";
import SchoolYearSelect from "../components/SchoolYearSelect";
import {
  pageHeaderTitle,
  pageTabsContainer,
  pb0,
} from "../components/sharedStyles";
import dateTimeFormats from "../utils/constants/dateTimeFormats";
import schedulesContainer from "./Schedules.styles";
import ScheduleTabs from "./SchedulesTabs";
import APP_PERMISSIONS from "../utils/constants/permissions";
import PERMISSION_TYPES from "../utils/constants/permission_types";
import { PermissionsContext } from "../context/PermissionsContext";

export default function Schedules() {
  const params = useParams();
  const schoolId = params.school_id;
  const [selectedSchoolYear, setSelectedSchoolYear] = useState(null);
  const [searchParams] = useSearchParams();
  const schoolYearId = searchParams.get("school_year") || "";
  const [
    klassesDrawerStatus,
    setSnackBarOpen,
    // eslint-disable-next-line no-unused-vars
    teacher,
    termsDrawerStatus,
    dayTemplateDrawerStatus,
    schoolDaysDrawerStatus,
    classScheduleDrawerStatus,
    bulkEnrollDrawerStatus,
    ,
    ,
    ,
    currentSchool,
  ] = useOutletContext();
  const { hasPermission } = useContext(PermissionsContext);

  const managePermission = hasPermission(
    APP_PERMISSIONS.TERMS,
    PERMISSION_TYPES.MANAGE
  );

  return (
    <>
      <Container maxWidth={false} variant="header" sx={pb0}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Stack direction="row" alignItems="baseline">
              <Typography sx={pageHeaderTitle}>Scheduling</Typography>
            </Stack>
          </Box>
          <Box>
            <FormControl fullWidth>
              <SchoolYearSelect
                schoolId={schoolId}
                setSnackBarOpen={setSnackBarOpen}
                setSelectedSchoolYear={setSelectedSchoolYear}
                disabled={!managePermission}
              />
            </FormControl>
          </Box>
        </Stack>

        <Box sx={pageTabsContainer}>
          <ScheduleTabs schoolYearId={schoolYearId} />
          <Typography>
            {moment(selectedSchoolYear?.start_date)?.format(
              dateTimeFormats.MMMDDYYYY
            )}
            {" - "}
            {moment(selectedSchoolYear?.end_date)?.format(
              dateTimeFormats.MMMDDYYYY
            )}
          </Typography>
        </Box>
      </Container>

      <Box sx={schedulesContainer}>
        <Outlet
          context={[
            selectedSchoolYear,
            setSelectedSchoolYear,
            termsDrawerStatus,
            setSnackBarOpen,
            dayTemplateDrawerStatus,
            schoolDaysDrawerStatus,
            classScheduleDrawerStatus,
            klassesDrawerStatus,
            bulkEnrollDrawerStatus,
            currentSchool,
          ]}
        />
      </Box>
    </>
  );
}
