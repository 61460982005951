const assignmentsFormKlassesCheckbox = {
  width: "46px",
};

const assignmentsFormKlassesTable = {
  "& td": {
    color: "black",
    py: "5px",
  },
};

const assignmentsTableBox = {
  mt: "15px",
};

const assignmentsFormKlassesOptionsCheckbox = {
  width: "230px",
  "> p": {
    display: "inline-block",
  },
};

export {
  assignmentsFormKlassesCheckbox,
  assignmentsFormKlassesTable,
  assignmentsFormKlassesOptionsCheckbox,
  assignmentsTableBox,
};
