import React, { useEffect, useState } from "react";
import moment from "moment";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import {
  Box,
  Stack,
  Button,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  CircularProgress,
  Checkbox,
  TextField,
  Autocomplete,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {
  formHeader,
  formButtons,
  formTitle,
  formContainer,
  formButtonsContainer,
  minW200,
  my15,
  mt35,
  mt15,
  loader,
} from "../../sharedStyles";
import ErasableTextField from "../../ErasableTextField";
import {
  addFileFormDocumentTitle,
  addFileFormDocumentBtn,
} from "./AddFileForm.styles";
import GenericSubmitButton from "../../GenericSubmitButton";
import lookupService from "../../../service/lookupService";
import schoolYearService from "../../../service/schoolYearService";
import portfolioFilesService from "../../../service/portfolioFilesService";
import { renderError } from "../../../utils/constants/forms";
import AlertDialog from "../../AlertDialog";

export default function AddFileForm({
  setDrawerOpen,
  setSnackBarOpen,
  activeFile,
  handleDrawerClose,
  collections,
}) {
  const [fileTypes, setFileTypes] = useState([]);
  const [schoolYears, setSchoolYears] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [emptyAttachmentMessage, setEmptyAttachmentMessage] = useState(null);
  const [currentSchoolYear, setCurrentSchoolYear] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);

  const params = useParams();
  const methods = useForm();

  const requiredMessage = "This field is required.";

  const maxFileSize = 20000000;

  function SizeException(message) {
    return {
      message,
      error: "sizeException",
    };
  }

  const handleCurrentSchoolYear = (_schoolYears) => {
    const today = moment();
    const findCurrentSchoolYear =
      _schoolYears.find((schoolYear) => {
        const start = moment(schoolYear.start_date);
        const end = moment(schoolYear.end_date);
        return today.isBetween(start, end);
      }) || _schoolYears[0];

    setCurrentSchoolYear(findCurrentSchoolYear);
  };

  const getFileTypes = async () => {
    const response = await lookupService.fetchFileTypes();
    setFileTypes(response.data.data);
  };

  const getSchoolYears = async () => {
    const response = await schoolYearService.fetchAll({
      params: {
        school_id: params.school_id,
      },
    });

    setSchoolYears(response.data.school_years);
    handleCurrentSchoolYear(response.data.school_years);
  };

  const getAttachments = async () => {
    setLoading(true);
    const response = await portfolioFilesService.fetchAttachments(
      activeFile.id
    );

    if (response) {
      setAttachments(response.data);
      setLoading(false);
    }
  };

  const onSubmit = async (data) => {
    const selectedCollections =
      data.selectedCollections?.map((collection) => collection.id) || [];
    const formData = new FormData();

    const formParams = {
      ...data,
      selectedCollections,
      student_slug: params.student_slug,
    };

    Object.keys(formParams).map((keyName) =>
      formData.append(keyName, formParams[keyName])
    );

    Array.prototype.forEach.call(uploadedFiles, (file, index) => {
      if (uploadedFiles.length > 10)
        throw new SizeException("Maximum of 10 files are permitted.");
      if (file.size > maxFileSize)
        throw new SizeException(`The file ${file.name} is bigger than 20Mb.`);
      formData.append(`attachments[]`, uploadedFiles[index]);
    });

    if (activeFile) {
      const response = await portfolioFilesService.update(
        activeFile.id,
        formData
      );

      if (response.data) {
        setSnackBarOpen({
          open: true,
          message: "File updated.",
        });
      }

      handleDrawerClose(false);
    }

    if (!activeFile && uploadedFiles.length > 0) {
      const response = await portfolioFilesService.create(formData);

      if (response.data) {
        setSnackBarOpen({
          open: true,
          message: "File created.",
        });

        handleDrawerClose(false);
      }
    } else {
      setEmptyAttachmentMessage("Please select a file to upload.");
    }
  };

  const handleFileUploads = (e) => {
    const files = [...uploadedFiles];
    Array.prototype.forEach.call(e.target.files, (file, index) => {
      const fileWithId = file;
      const fileId =
        uploadedFiles.length > 0 ? index + uploadedFiles.at(-1).id + 1 : index;
      fileWithId.id = fileId;
      files.push(fileWithId);
    });

    try {
      if (files) {
        setUploadedFiles(files);
      }
    } catch (err) {
      if (err.error === "sizeException") {
        setSnackBarOpen({
          message: err.message,
          open: true,
          severity: "error",
        });
      }
    }
  };

  const handleDeleteFile = (objId) => {
    setUploadedFiles(uploadedFiles.filter((file) => file.id !== objId));
  };

  const deletePortfolioFile = async () => {
    await portfolioFilesService
      .deleteFile(activeFile.id)
      .then(() => {
        setSnackBarOpen({
          message: "File deleted.",
          severity: "success",
          open: true,
        });
        setDrawerOpen(false);
      })
      .catch(() => {
        setSnackBarOpen({
          message: "Failed to delete file.",
          severity: "error",
          open: true,
        });
      });
  };

  useEffect(() => {
    getFileTypes();
    getSchoolYears();

    if (activeFile) {
      getAttachments();
    }
  }, []);

  if (loading) {
    return <CircularProgress sx={loader} size={100} />;
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack direction="row" sx={formHeader}>
          <Grid container item sm={5}>
            <Typography sx={formTitle}>
              {activeFile ? "Edit" : "Add"} file
            </Typography>
          </Grid>
          <Grid container item sm={7}>
            <Stack sx={formButtonsContainer} direction="row">
              <Button onClick={() => handleDrawerClose()} sx={formButtons}>
                Cancel
              </Button>
              {activeFile && (
                <GenericSubmitButton
                  text="Delete"
                  submittingText="Deleting..."
                  type="button"
                  onClick={() => {
                    setDialogOpen(true);
                  }}
                />
              )}
              <GenericSubmitButton text="Save" submittingText="Saving..." />
            </Stack>
          </Grid>
        </Stack>
        <Box sx={formContainer}>
          <Stack direction="row" spacing={2}>
            {schoolYears.length > 0 && (
              <Box>
                <FormControl
                  sx={minW200}
                  error={methods.formState.errors?.school_year_id}
                >
                  <InputLabel>Year*</InputLabel>
                  <Select
                    label="Year*"
                    defaultValue={
                      activeFile?.school_year_id || currentSchoolYear.id
                    }
                    {...methods.register("school_year_id", {
                      required: requiredMessage,
                    })}
                  >
                    {schoolYears.map((schoolYear) => (
                      <MenuItem key={schoolYear.id} value={schoolYear.id}>
                        {schoolYear.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {methods.formState.errors?.school_year_id &&
                  renderError(methods.formState.errors?.school_year_id.message)}
              </Box>
            )}
            {fileTypes.length > 0 && (
              <Box>
                <FormControl
                  sx={minW200}
                  error={methods.formState.errors?.type}
                >
                  <InputLabel>Type*</InputLabel>
                  <Select
                    label="Type*"
                    defaultValue={activeFile?.portfolio_file_type || null}
                    {...methods.register("type", {
                      required: requiredMessage,
                    })}
                  >
                    {fileTypes.map((fileType) => (
                      <MenuItem key={fileType.id} value={fileType.name}>
                        {fileType.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {methods.formState.errors?.type &&
                  renderError(methods.formState.errors?.type.message)}
              </Box>
            )}
          </Stack>
          <Box sx={my15}>
            <ErasableTextField
              id="name"
              label="Name*"
              fullWidth
              isRequired
              defaultValue={activeFile?.name || null}
              hasError={methods.formState.errors?.name}
            />

            {methods.formState.errors?.name &&
              renderError(methods.formState.errors?.name.message)}
          </Box>
          <Box>
            <ErasableTextField
              id="description"
              label="Description"
              fullWidth
              defaultValue={activeFile?.description || null}
              hasError={false}
            />
          </Box>
          <Box>
            {collections.length > 0 && (
              <FormControl sx={{ width: "415px", my: "15px" }}>
                <Controller
                  name="selectedCollections"
                  control={methods.control}
                  defaultValue={
                    collections.filter((item1) =>
                      activeFile?.portfolio_collections.some(
                        (item2) => item1.id === item2.id
                      )
                    ) || []
                  }
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      // eslint-disable-next-line no-shadow
                      onChange={(evt, value) => onChange(value)}
                      value={value}
                      multiple
                      id="checkboxes-selected-collections"
                      options={collections}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox checked={selected} />
                          {option.name}
                        </li>
                      )}
                      style={{ width: 500 }}
                      // eslint-disable-next-line no-shadow
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Add to collection"
                          placeholder="Add to collection"
                        />
                      )}
                    />
                  )}
                />
              </FormControl>
            )}
          </Box>

          <Box sx={mt35}>
            <Typography sx={addFileFormDocumentTitle}>Document</Typography>
            {uploadedFiles.length > 0 ? (
              uploadedFiles.map((file) => (
                <Box sx={my15} key={file.id}>
                  <Chip
                    label={file.name}
                    onDelete={() => handleDeleteFile(file.id)}
                  />
                </Box>
              ))
            ) : (
              <>
                {activeFile &&
                  attachments.length > 0 &&
                  attachments.map((attachment) => (
                    <Stack
                      sx={mt15}
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Typography>{attachment.filename}</Typography>
                      <Typography>{attachment.created_at}</Typography>
                    </Stack>
                  ))}
                <Button
                  sx={addFileFormDocumentBtn}
                  variant="outlined"
                  startIcon={<AddIcon />}
                  component="label"
                >
                  {activeFile ? "Replace file" : "Add file from your computer"}
                  <input
                    hidden
                    accept=".jpeg, .jpg, .pdf, .png, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .zip"
                    type="file"
                    onChange={handleFileUploads}
                  />
                </Button>

                {emptyAttachmentMessage && renderError(emptyAttachmentMessage)}
              </>
            )}
          </Box>
        </Box>
      </form>
      <AlertDialog
        isOpen={isDialogOpen}
        handleClose={() => setDialogOpen(false)}
        handleConfirm={deletePortfolioFile}
      />
    </FormProvider>
  );
}
