import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Typography,
  Container,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Drawer,
  CircularProgress,
  Switch,
} from "@mui/material";
import { useParams, useSearchParams, useOutletContext } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import {
  hidden,
  listTable,
  listHead,
  listName,
  listSubject,
  listCell,
  listCellName,
  settingsCell,
} from "./Terms.styles";
import termService from "../../../service/termService";
import moment from "../../../utils/constants/momentConfig";
import dateTimeFormats from "../../../utils/constants/dateTimeFormats";
import {
  mr10,
  py30,
  rightDrawerContainer,
  loader,
  switchBtn,
} from "../../sharedStyles";
import TermsForm from "./TermsForm";
import { PermissionsContext } from "../../../context/PermissionsContext";
import APP_PERMISSIONS from "../../../utils/constants/permissions";
import PERMISSION_TYPES from "../../../utils/constants/permission_types";
import Authorize from "../../Authorize";
import { SnackbarContext } from "../../../context/SnackbarContext";

export default function Terms() {
  const params = useParams();
  const schoolId = params.school_id;
  const [searchParams, setSearchParams] = useSearchParams();
  const [terms, setTerms] = useState([]);
  const [order, setOrder] = useState(searchParams.get("direction") || "desc");
  const [orderBy, setOrderBy] = useState(
    searchParams.get("sort") || "start_date"
  );
  const [drawerStatusEdit, setDrawerStatusEdit] = useState(false);
  const [selectedTerm, setSelectedTerm] = useState(null);
  const [loading, setLoading] = useState(false);
  const snackbarContext = useContext(SnackbarContext);
  const [
    selectedSchoolYear,
    // eslint-disable-next-line no-unused-vars
    setSelectedSchoolYear,
    termsDrawerStatus,
    setSnackBarOpen,
    // eslint-disable-next-line no-unused-vars
    dayTemplateDrawerStatus,
  ] = useOutletContext();
  const { hasPermission, hasAnyPermissionType } =
    useContext(PermissionsContext);

  const managePermission = hasPermission(
    APP_PERMISSIONS.TERMS,
    PERMISSION_TYPES.MANAGE
  );

  const getTerms = async () => {
    if (!drawerStatusEdit && !termsDrawerStatus) {
      setLoading(true);
    }

    if (!drawerStatusEdit) {
      const response = await termService.fetchAllTermsInSchool({
        params: {
          school_id: schoolId,
          school_year_id: selectedSchoolYear.id,
          order,
          order_by: orderBy,
          upcoming_terms: true,
        },
      });
      if (response.data) {
        setTerms(response.data.terms);
        setLoading(false);
      }
    }
  };
  const renderSortChevron = (label) => {
    const noColumnSelected = searchParams.get("sort") === null;

    if ((noColumnSelected && label === "start_date") || label === orderBy) {
      return order === "asc" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
    }
    return <ArrowDropUpIcon sx={hidden} />;
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    const direction = isAsc ? "desc" : "asc";
    setOrder(direction);
    setOrderBy(property);

    searchParams.set("sort", property);
    searchParams.set("direction", direction);
    setSearchParams(searchParams);
  };

  const handleDrawer = (status, term) => {
    setDrawerStatusEdit(status);
    if (status === false) {
      setSelectedTerm(null);
    } else {
      setSelectedTerm(term);
    }
  };

  const handleUpdateDefault = async (event, term) => {
    const { checked } = event.target;

    try {
      const response = await termService.updateDefault({
        term_id: term.id,
        is_default: checked,
      });

      if (response) {
        // eslint-disable-next-line no-param-reassign
        snackbarContext.setSnackbar({
          message: "Saved",
          severity: "success",
          open: true,
        });
      }
    } catch (e) {
      snackbarContext.setSnackbar({
        message: e.response.data.errors,
        severity: "error",
        open: true,
      });
    }
  };

  useEffect(() => {
    getTerms();
    setOrder(searchParams.get("direction") || "desc");
    setOrderBy(searchParams.get("sort") || "start_date");
  }, []);

  useEffect(() => {
    getTerms();
    setOrder(searchParams.get("direction") || "desc");
    setOrderBy(searchParams.get("sort") || "start_date");
  }, [
    order,
    orderBy,
    drawerStatusEdit,
    selectedSchoolYear,
    termsDrawerStatus,
    snackbarContext,
  ]);

  if (loading) {
    return <CircularProgress sx={loader} size={100} />;
  }

  return (
    <Authorize permission={hasAnyPermissionType(APP_PERMISSIONS.TERMS)}>
      <Container maxWidth={false} variant="header">
        <Container maxWidth="lg">
          <Grid container>
            <Grid item sm={12}>
              {terms.length > 0 ? (
                <TableContainer sx={py30}>
                  <Table sx={listTable}>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={listHead(false, orderBy === "name")}
                          onClick={() => handleRequestSort("name")}
                        >
                          <Typography sx={listName}>
                            NAME {renderSortChevron("name")}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={listHead(false, orderBy === "start_date")}
                          onClick={() => handleRequestSort("start_date")}
                        >
                          <Typography sx={mr10}>
                            START DATE {renderSortChevron("start_date")}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={listHead(false, orderBy === "end_date")}
                          onClick={() => handleRequestSort("end_date")}
                        >
                          <Typography sx={listSubject}>
                            END DATE {renderSortChevron("end_date")}
                          </Typography>
                        </TableCell>
                        <TableCell sx={listHead}>DEFAULT</TableCell>
                        <TableCell sx={listHead} />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {terms.map((term) => (
                        <TableRow hover key={term.id}>
                          <TableCell sx={listCellName}>
                            <Typography>
                              <span>{term.name}</span>
                            </Typography>
                          </TableCell>
                          <TableCell sx={listCell()}>
                            <Typography>
                              <span>
                                {moment(term?.start_date).format(
                                  dateTimeFormats.MMDDYYYY
                                )}
                              </span>
                            </Typography>
                          </TableCell>
                          <TableCell sx={listCell()}>
                            <Typography>
                              {moment(term?.end_date).format(
                                dateTimeFormats.MMDDYYYY
                              )}
                            </Typography>
                          </TableCell>
                          <TableCell sx={listCell()}>
                            <Switch
                              sx={switchBtn}
                              checked={term?.is_default}
                              onChange={(event) =>
                                handleUpdateDefault(event, term)
                              }
                            />
                          </TableCell>
                          {managePermission && (
                            <TableCell
                              sx={settingsCell}
                              onClick={() => handleDrawer(true, term)}
                            >
                              <SettingsOutlinedIcon />
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography align="center">No terms found.</Typography>
              )}
            </Grid>
          </Grid>
        </Container>
      </Container>

      <Drawer anchor="right" open={drawerStatusEdit}>
        <Box sx={rightDrawerContainer}>
          {selectedTerm ? (
            <TermsForm
              schoolId={schoolId}
              handleTermsDrawer={handleDrawer}
              term={selectedTerm}
              setSnackBarOpen={setSnackBarOpen}
            />
          ) : (
            <CircularProgress sx={loader} size={100} />
          )}
        </Box>
      </Drawer>
    </Authorize>
  );
}
