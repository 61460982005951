import React, { useState, useEffect, useContext } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import TextEditor from "../../TextEditor/TextEditor";
import schoolYearService from "../../../service/schoolYearService";
import { submitButton } from "./FormBuilder/FormBuilder.style";
import { loader } from "../../sharedStyles";
import { admissionFormUnsavedKey } from "./FormBuilder/FormBuilderUtils";
import { SnackbarContext } from "../../../context/SnackbarContext";

export default function WelcomeMessage() {
  const snackbarContext = useContext(SnackbarContext);
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState(null);
  const [originalMessage, setOriginalMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const saveMessage = async () => {
    setLoading(true);
    const response = await schoolYearService.updateMessage({
      params: {
        school_year_id: searchParams.get("school_year"),
        message,
      },
    });

    if (response.data) {
      setMessage(response.data.welcome_message);
      setOriginalMessage(response.data.welcome_message);
      setLoading(false);
      setUnsavedChanges(false);
      snackbarContext.setSnackbar({
        message: "Message saved.",
        severity: "success",
        open: true,
      });
    }
  };

  const getMessage = async () => {
    setLoading(true);
    const response = await schoolYearService.fetchById(
      searchParams.get("school_year")
    );

    if (response.data) {
      setMessage(response.data.school_year.welcome_message);
      setOriginalMessage(response.data.school_year.welcome_message);
      setLoading(false);
      setUnsavedChanges(false);
    }
  };

  useEffect(() => {
    if (originalMessage !== message) {
      setUnsavedChanges(true);
    }
  }, [message]);

  useEffect(() => {
    getMessage();
  }, [searchParams.get("school_year")]);

  // handle page leave
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (unsavedChanges) {
      localStorage.setItem(admissionFormUnsavedKey, true);

      const unloadCallback = (event) => {
        event.preventDefault();
        // eslint-disable-next-line no-param-reassign
        event.returnValue = "";
        return "";
      };

      window.addEventListener("beforeunload", unloadCallback);
      return () => {
        window.removeEventListener("beforeunload", unloadCallback);
      };
    }
    localStorage.removeItem(admissionFormUnsavedKey);
  }, [unsavedChanges]);

  if (loading) {
    return <CircularProgress sx={loader} size={100} />;
  }

  return (
    <Box sx={{ mt: "35px", mx: "100px", height: "50vh" }}>
      <TextEditor
        style={{ backgroundColor: "transparent" }}
        value={message}
        onChange={(data) => setMessage(data)}
      />
      <Box sx={{ display: "flex", justifyContent: "center", mt: "35px" }}>
        <Button
          onClick={() => saveMessage()}
          variant="contained"
          sx={submitButton}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
}
