const assignmentFormDivider = {
  borderBottom: "1px solid #BFC7CA",
  py: "25px",
};

const assignmentFormTitle = {
  fontSize: "28px",
};

const assignmentFormRow = {
  mb: "20px",
};

const actionButtonsContainer = {
  width: "40%",
  "& .MuiButtonBase-root": {
    flexGrow: 1,
  },
};

export {
  assignmentFormDivider,
  assignmentFormTitle,
  assignmentFormRow,
  actionButtonsContainer,
};
