import React, { useContext, useEffect } from "react";
import { Box, Drawer } from "@mui/material";
import { useParams } from "react-router-dom";
import { StudentActionsContext } from "../../../context/StudentActionsContext";
import Guardian from "./ActionDrawers/Guardian";
import SearchGuardian from "./ActionDrawers/SearchGuardian";
import { QuickBarContext } from "../../../context/QuickBarContext";
import { guardianDrawer } from "../Account/ParentSection.styles";
import { rightDrawerContainer } from "../../sharedStyles";
import EmergencyContactForm from "./ActionDrawers/EmergencyContactForm";
import AddMedicalRecord from "./ActionDrawers/AddMedicalRecord";
import { medicalRecordDrawer } from "../Medical/StudentInfoMedical.style";
import EventUpdate from "./ActionDrawers/EventUpdate";
import EventCreate from "./ActionDrawers/EventCreate";

export default function StudentActions() {
  const studentActionsContext = useContext(StudentActionsContext);
  const quickBarContext = useContext(QuickBarContext);
  const params = useParams();

  useEffect(() => {
    (async () => {
      if (quickBarContext.addParents) {
        studentActionsContext.openGuardianDrawer();
        quickBarContext.hideAddParents();
      }
      if (quickBarContext.addEmergencyContacts) {
        studentActionsContext.openEmergencyContactDrawer();
        quickBarContext.hideAddEmergencyContacts();
      }
      if (quickBarContext.addStudentMedicalRecord) {
        studentActionsContext.openMedicalRecordDrawer();
        quickBarContext.cleanAddStudentMedicalRecord();
      }
      if (quickBarContext.addStudentEvent) {
        studentActionsContext.openEventDrawer();
        quickBarContext.cleanAddStudentEvent();
      }
    })();
  }, [
    quickBarContext.addParents,
    quickBarContext.addEmergencyContacts,
    quickBarContext.addStudentMedicalRecord,
    quickBarContext.addStudentEvent,
  ]);

  return (
    <>
      <Drawer
        open={studentActionsContext.isGuardianDrawerOpen}
        anchor="right"
        sx={guardianDrawer}
      >
        {studentActionsContext.showGuardianSearchForm && (
          <SearchGuardian
            setIsGuardianDrawerOpen={
              studentActionsContext.drawerProperties.setIsGuardianDrawerOpen
            }
            setShowGuardianCreateForm={
              studentActionsContext.drawerProperties.setShowGuardianCreateForm
            }
            setShowGuardianSearchForm={
              studentActionsContext.drawerProperties.setShowGuardianSearchForm
            }
            setSnackBarOpen={
              studentActionsContext.drawerProperties.setSnackBarOpen
            }
            studentGuardians={studentActionsContext.drawerProperties.guardians}
          />
        )}
        {studentActionsContext.showGuardianCreateForm && (
          <Guardian
            setIsGuardianDrawerOpen={
              studentActionsContext.drawerProperties.setIsGuardianDrawerOpen
            }
            currentGuardianId={
              studentActionsContext.drawerProperties.currentGuardianId
            }
            setShowGuardianCreateForm={
              studentActionsContext.drawerProperties.setShowGuardianCreateForm
            }
            setShowGuardianSearchForm={
              studentActionsContext.drawerProperties.setShowGuardianSearchForm
            }
          />
        )}
      </Drawer>
      <Drawer anchor="right" open={studentActionsContext.isEContactsDrawerOpen}>
        <Box sx={rightDrawerContainer}>
          <EmergencyContactForm
            studentId={params.student_slug}
            setIsEContactsDrawerOpen={
              studentActionsContext.drawerProperties.setIsEContactsDrawerOpen
            }
            currentContact={
              studentActionsContext.drawerProperties.currentContact
            }
            fetchAllContacts={
              studentActionsContext.drawerProperties.fetchAllContacts
            }
          />
        </Box>
      </Drawer>
      <Drawer
        open={studentActionsContext.isMedicalRecordDrawerOpen}
        anchor="right"
        sx={medicalRecordDrawer}
      >
        <AddMedicalRecord
          setMedicalRecordDrawerOpen={
            studentActionsContext.drawerProperties.setMedicalRecordDrawerOpen
          }
          loadMedicalRecords={
            studentActionsContext.drawerProperties.loadMedicalRecords
          }
          medicalRecordId={
            studentActionsContext.drawerProperties.medicalRecordId
          }
        />
      </Drawer>
      <Drawer anchor="right" open={studentActionsContext.isEventDrawerOpen}>
        <Box sx={rightDrawerContainer}>
          {studentActionsContext.drawerProperties.eventId ? (
            <EventUpdate
              studentId={params.student_slug}
              setIsEventDrawerOpen={
                studentActionsContext.drawerProperties.setIsEventDrawerOpen
              }
              eventId={studentActionsContext.drawerProperties.eventId}
              fetchAllEvents={
                studentActionsContext.drawerProperties.fetchAllEvents
              }
            />
          ) : (
            <EventCreate
              studentId={params.student_slug}
              setIsEventDrawerOpen={
                studentActionsContext.drawerProperties.setIsEventDrawerOpen
              }
              setSnackBarOpen={
                studentActionsContext.drawerProperties.setSnackBarOpen
              }
              fetchAllEvents={
                studentActionsContext.drawerProperties.fetchAllEvents
              }
            />
          )}
        </Box>
      </Drawer>
    </>
  );
}
