import { tableCellClasses } from "@mui/material";

const tableStyles = {
  minWidth: 650,
  [`& .${tableCellClasses.root}`]: {
    borderBottom: "none",
  },
  "& .MuiTableCell-head": {
    color: "#000000",
    fontSize: 11,
    fontWeight: "700 !important",
    textTransform: "uppercase",
    borderBottom: "1px solid #D6D6D6",
    paddingBottom: "6px",
  },
  "& .MuiTableCell-body": {
    color: "black",
    fontSize: 10,
    paddingBottom: 0,
    paddingTop: 0.8,
  },
  "& .MuiTableCell-head:nth-child(1)": {
    paddingLeft: 0,
  },
  "& .MuiTableCell-root:nth-child(1)": {
    width: "50%",
  },
  "& .MuiTableCell-root:nth-child(2)": {
    width: "15%",
  },
  "& .MuiTableCell-root:nth-child(3)": {
    width: "15%",
  },
  "& .MuiTableCell-root:nth-child(4)": {
    width: "20%",
  },
};

const monthText = {
  fontSize: 14,
  textTransform: "none",
  fontWeight: 700,
  color: "black",
};

const birthdaysHeader = {
  justifyContent: "space-between",
  alignItems: "baseline",
  paddingRight: "2rem",
  direction: "row",
};
export { tableStyles, monthText, birthdaysHeader };
