import React, { useEffect, useState } from "react";
import { useOutletContext, useSearchParams } from "react-router-dom";
import PrintLayout from "../PrintLayoutComponents/PrintLayout";
import StudentListTable from "./StudentListTable";
import reportsService from "../../../service/reportsService";

export default function StudentList() {
  const [searchParams] = useSearchParams();
  const termId = searchParams.get("term");
  const [students, setStudents] = useState([]);

  const [schoolName] = useOutletContext();

  const getStudentSchoolList = async () => {
    const response = await reportsService.studentSchoolList({
      params: { term_id: termId },
    });

    if (response.students) {
      setStudents(response.students);
    }
  };

  const generateBodyContent = () => <StudentListTable students={students} />;

  useEffect(() => {
    getStudentSchoolList();
  }, [termId]);

  return (
    <PrintLayout
      headerContent="Student School List"
      bodyContent={generateBodyContent()}
      footerContent={schoolName}
    />
  );
}
