import React, { useContext, useEffect, useState } from "react";
import { Container, CircularProgress } from "@mui/material";
import {
  useSearchParams,
  useLocation,
  useParams,
  useOutletContext,
} from "react-router-dom";
import StaffTable from "../components/StaffTable";
import teachersService from "../service/staffsService";
import { QuickBarContext } from "../context/QuickBarContext";
import StaffType from "../utils/constants/staffTypeEnum";
import { loader } from "../components/sharedStyles";

const staffListContainer = (isCompact) => ({
  px: isCompact && "40px !important",
});

export default function StaffMembers({ isCompact = false }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [order, setOrder] = React.useState(
    searchParams.get("direction") || "asc"
  );
  const [orderBy, setOrderBy] = React.useState(
    searchParams.get("sort") || "name"
  );
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [staffs, setStaffs] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [count, setCount] = useState(25);
  const [loading, setLoading] = useState(false);
  const quickBarContext = useContext(QuickBarContext);
  const [, query, setQuery] = useOutletContext();

  const params = useParams();
  const schoolId = params.school_id;

  const location = useLocation();

  const getStaffList = async () => {
    setLoading(true);
    const response = await teachersService.fetchPaginatedTeachers({
      params: {
        staff_type: StaffType.SUPPORT_STAFF,
        school_id: schoolId,
        page,
        orderBy,
        order,
        query: (!isCompact && query) || "",
        count: (!isCompact && count) || "",
      },
    });
    const queryPage = searchParams.get("page") || 1;
    const lastPage = response.pagination.total_pages;
    const currentPage = queryPage > lastPage ? lastPage : queryPage;
    const countPerPage = searchParams.get("count") || 25;
    setStaffs(response.data);
    setTotalPages(lastPage);
    setPage(currentPage);
    setCount(countPerPage);

    setOrder(searchParams.get("direction") || "asc");
    setOrderBy(searchParams.get("sort") || "name");
    if (!isCompact) setQuery(searchParams.get("query") || "");
    setLoading(false);
  };
  useEffect(() => {
    getStaffList();
  }, [page, order, orderBy, query, count, location]);

  const syncStaffs = () => {
    teachersService
      .syncStaff({ school_id: schoolId })
      .then(() => {
        getStaffList();
        quickBarContext.cleanSyncStaffs();
      })
      .catch(() => {
        quickBarContext.cleanSyncStaffs();
      });
  };

  useEffect(() => {
    if (quickBarContext.syncStaffs) {
      syncStaffs();
    }
  }, [quickBarContext.syncStaffs]);

  if (loading) {
    return <CircularProgress sx={loader} size={100} />;
  }

  return (
    <div>
      <Container
        maxWidth={false}
        variant="header"
        sx={staffListContainer(isCompact)}
      >
        <StaffTable
          isCompact={isCompact}
          staffs={staffs}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
          selected={selected}
          order={order}
          orderBy={orderBy}
          setSelected={setSelected}
          setPage={setPage}
          totalPages={totalPages}
          setOrderBy={setOrderBy}
          setOrder={setOrder}
          page={page}
        />
      </Container>
    </div>
  );
}
