import React, { useContext } from "react";
import { useDrop } from "react-dnd";
import { Box } from "@mui/material";
import { FormBuilderContext } from "../FormBuilderContext";
import FormSection from "./FormSection";
import Section from "../Models/Section";
import classesMap from "../Models/Fields/classesMap";
import lookupService from "../../../../../service/lookupService";
import countriesService from "../../../../../service/countriesService";
import { generateGUID } from "../../../../../utils/constants/utils";

export default function FormContainer() {
  const formBuilderContext = useContext(FormBuilderContext);
  const { form, currentPageIndex, updateForm, selectField } =
    formBuilderContext;

  const fetchOptions = (label) => {
    switch (label) {
      case "Gender":
        return lookupService.fetchGenders();
      case "Language":
        return lookupService.fetchLanguages();
      case "Ethnicity":
        return lookupService.fetchEthnicities();
      case "Denomination":
        return lookupService.fetchDenomination();
      case "Relationship to the student":
        return lookupService.fetchGuardians();
      case "State":
        return countriesService.fetchAllStates();
      case "Country":
        return countriesService.fetchAllCountries();
      default:
        return Promise.resolve([]);
    }
  };

  const prefillOptions = async (newSection, sectionIndex) => {
    const updatedPages = [...form.formPages];
    const updatedSections = [...updatedPages[0].sections];
    updatedSections.push(newSection);

    const newFields = newSection.fields.filter(
      (f) => (f?.properties?.options || []).length === 0
    );
    const fetchPromises = Object.entries(newFields).map(
      ([fieldName, newField]) =>
        fetchOptions(newField.label).then((response) => ({
          fieldName,
          response: response?.data?.data || response?.data || [],
        }))
    );

    const fieldResponses = await Promise.all(fetchPromises);

    fieldResponses.forEach(({ fieldName, response }) => {
      const cloneOptions = response.map((prefillOption) => ({
        id: generateGUID(),
        value: prefillOption.name,
        valueId: ["State", "Country"].includes(
          newSection.fields[fieldName].label
        )
          ? prefillOption.id
          : "",
      }));
      updatedSections[sectionIndex].fields[fieldName].properties.options =
        cloneOptions;
    });

    updatedPages[currentPageIndex] = {
      ...updatedPages[currentPageIndex],
      sections: updatedSections,
    };
    updateForm({ ...form, formPages: updatedPages });
  };

  const [, drop] = useDrop({
    accept: "section-type",
    drop: (item) => {
      const updatedPages = [...form.formPages];
      const updatedSections = [...updatedPages[0].sections];

      const newSection = new Section(item);
      newSection.order = updatedSections.length;

      item.fieldComponents.forEach((field) => {
        const newField = new classesMap[field.type](field);
        newSection.fields.push(newField);
      });

      selectField(null);
      prefillOptions(newSection, updatedSections.length);
    },
  });

  return (
    <Box height="100%" ref={drop}>
      {formBuilderContext.form.formPages[currentPageIndex].sections.map(
        (section, index) => (
          // <DraggableFormSection
          //   key={section.sectionId}
          //   section={section}
          //   sectionIndex={index}
          // />
          <FormSection
            key={section.sectionId}
            section={section}
            sectionIndex={index}
          />
        )
      )}
    </Box>
  );
}

// function DraggableFormSection({ section, sectionIndex }) {
//   const [{ isDragging }, dragRef] = useDrag({
//     type: "section-type",
//     item: { sectionIndex },
//     collect: (monitor) => ({
//       isDragging: monitor.isDragging(),
//     }),
//   });

//   return (
//     <div ref={dragRef} style={{ opacity: isDragging ? 0.5 : 1 }}>
//       <FormSection section={section} sectionIndex={sectionIndex} />
//     </div>
//   );
// }
