import React, { useContext } from "react";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ArrowBackIcon from "@mui/icons-material/ArrowBackOutlined";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { flex } from "../../sharedStyles";
import {
  eventActionText,
  eventActionTextContent,
  eventHeaderCell,
  eventHeaderRow,
  pinnedRow,
  row,
  eventBodyText,
  eventTableStrike,
  eventTablePinned,
  eventTableBodyCell,
  eventTablePinnedRow,
  eventHeaderCellWithCursor,
  paginationContainer,
} from "./EventsTable.style";
import dateTimeFormats from "../../../utils/constants/dateTimeFormats";
import { PermissionsContext } from "../../../context/PermissionsContext";
import APP_PERMISSIONS from "../../../utils/constants/permissions";
import PERMISSION_TYPES from "../../../utils/constants/permission_types";

function EventsTable({
  events,
  searchParams,
  setSearchParams,
  setPage,
  page,
  totalPages,
  order,
  orderBy,
  setOrder,
  setOrderBy,
  handleEventClick,
}) {
  const pinnedEvents = events.filter((event) => event.is_pinned === true);
  const unPinnedEvents = events.filter((event) => event.is_pinned === false);
  const { hasPermission } = useContext(PermissionsContext);

  const managePermission = hasPermission(
    APP_PERMISSIONS.STUDENT_EVENTS,
    PERMISSION_TYPES.MANAGE
  );

  const handleChangePage = (event, value) => {
    setPage(value);
    searchParams.set("page", value);
    setSearchParams(searchParams);
  };

  const handlePrevious = () => {
    searchParams.set("page", Number(page) - 1);
    setSearchParams(searchParams);
    setPage(Number(page) - 1);
  };

  const handleNext = () => {
    searchParams.set("page", Number(page) + 1);
    setSearchParams(searchParams);
    setPage(Number(page) + 1);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    const direction = isAsc ? "desc" : "asc";
    setOrder(direction);
    setOrderBy(property);

    searchParams.set("sort", property);
    searchParams.set("direction", direction);
    setSearchParams(searchParams);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const renderSortChevron = (column) => {
    if (column !== orderBy) return "";

    return order === "asc" ? (
      <KeyboardArrowUpIcon />
    ) : (
      <KeyboardArrowDownIcon />
    );
  };

  const renderTable = (items) =>
    items.map((event) => (
      <React.Fragment key={event.id}>
        <TableRow
          hover
          sx={
            items[items.length - 1] === event && event.is_pinned
              ? pinnedRow(managePermission)
              : row(managePermission)
          }
        >
          <TableCell
            sx={eventTableBodyCell(event.is_pinned)}
            onClick={() => {
              if (!managePermission) return;
              handleEventClick(event.id);
            }}
          >
            <Typography sx={eventActionText}>Comment: </Typography>
            <Typography sx={eventActionTextContent}>{event.comment}</Typography>
            <br />
            <br />
            <Typography sx={eventActionText}>Action needed/Taken: </Typography>
            <Typography sx={eventActionTextContent}>{event.action}</Typography>
          </TableCell>
          <TableCell sx={eventTableBodyCell(event.is_pinned)} align="left">
            <Typography sx={eventBodyText}>
              {event.student_event_category.name}
            </Typography>
          </TableCell>
          <TableCell sx={eventTableBodyCell(event.is_pinned)} align="left">
            <Typography sx={eventBodyText}>
              {event.created_by.first_name || event.created_by.last_name
                ? `${event.created_by.first_name} ${event.created_by.last_name}`
                : "-"}
            </Typography>
          </TableCell>
          <TableCell sx={eventTableBodyCell(event.is_pinned)} align="left">
            <Typography sx={eventBodyText}>
              {moment(event.created_at).format(dateTimeFormats.LL)}
            </Typography>
          </TableCell>
          <TableCell sx={eventTableBodyCell(event.is_pinned)}>
            {event.is_pinned && (
              <PushPinOutlinedIcon sx={{ fontSize: "2.5rem" }} />
            )}
          </TableCell>
        </TableRow>
      </React.Fragment>
    ));

  return (
    <>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow sx={eventHeaderRow}>
              <TableCell sx={eventHeaderCell} />
              <TableCell
                sx={eventHeaderCellWithCursor}
                align="left"
                onClick={createSortHandler("category")}
              >
                <Typography variant="preTitle" sx={flex}>
                  Category
                  {renderSortChevron("category")}
                </Typography>
              </TableCell>
              <TableCell
                sx={eventHeaderCellWithCursor}
                align="left"
                onClick={createSortHandler("created_by")}
              >
                <Typography variant="preTitle" sx={flex}>
                  Author
                  {renderSortChevron("created_by")}
                </Typography>
              </TableCell>
              <TableCell
                sx={eventHeaderCellWithCursor}
                align="left"
                onClick={createSortHandler("date")}
              >
                <Typography variant="preTitle" sx={flex}>
                  Date Entered
                  {renderSortChevron("date")}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pinnedEvents.length > 0 && (
              <TableRow>
                <TableCell sx={eventTablePinnedRow} colSpan={6}>
                  <h2 style={eventTableStrike}>
                    <span style={eventTablePinned}>PINNED</span>
                  </h2>
                </TableCell>
              </TableRow>
            )}
            {renderTable(pinnedEvents)}
            {renderTable(unPinnedEvents)}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={paginationContainer}>
        <IconButton
          variant="contained"
          disabled={Number(page) === 1}
          onClick={handlePrevious}
        >
          <ArrowBackIcon />
        </IconButton>

        <Pagination
          count={totalPages}
          onChange={handleChangePage}
          page={parseInt(page, 10)}
          hideNextButton
          hidePrevButton
        />
        <IconButton
          variant="contained"
          disabled={Number(page) === totalPages}
          onClick={handleNext}
        >
          <ArrowForwardIcon />
        </IconButton>
      </Box>
    </>
  );
}

export default EventsTable;
