import { createTheme } from "@mui/material/styles";

export default createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 924,
      md1280: 1280,
      lg: 1368,
      xl: 1656,
    },
  },
  palette: {
    background: {
      default: "#ffffff",
      tan: "#F2F1ECDD",
    },
    text: {
      primary: "#3F464B",
      simpleCard: "rgba(245, 243, 240, 0.9)",
      strongBlue: "#5077A4",
      lightBlue: "#DDF5FF",
      black: "#000000",
    },
    primary: {
      main: "#22282C",
    },
    success: {
      main: "#95D122",
    },
    disabled: {
      main: "#cdcdcd",
      secondary: "rgba(55, 104, 139, 0.5)",
    },
    alert: {
      main: "#FDBB30",
      secondary: "#5077A4",
    },
    hover: {
      primary: "#11C2E9",
    },
    cell: {
      selected: "#BBE0EE",
    },
  },
  typography: {
    fontFamily: [
      "halcom",
      '"Roboto"',
      '"Helvetica"',
      '"Arial"',
      "sans-serif",
    ].join(","),
    title: {
      fontSize: 25.92,
      fontWeight: 400,
    },
    subtitle: {
      fontSize: 18,
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: 26,
      fontWeight: 400,
    },
    preTitle: {
      fontSize: 10,
      fontWeight: "bold",
      textTransform: "uppercase",
    },
    h1: {
      margin: 0,
      fontSize: 68,
      fontWeight: 400,
    },
    h2: {
      margin: 0,
      fontSize: 42,
      fontWeight: 400,
    },
    h3: {
      margin: 0,
      fontSize: 38,
      fontWeight: 400,
    },
    h4: {
      textTransform: "uppercase",
      fontSize: 17,
      fontWeight: 400,
      margin: 0,
    },
    boxTitle: {
      fontSize: 20,
      fontWeight: 400,
    },
    profileTitle: { fontSize: 28, textAlign: "left", color: "black" },
    preferencesTitle: { fontWeight: "bold", fontSize: "14px" },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          display: "block",
          cursor: "pointer",
        },
      },
    },
    MuiContainer: {
      variants: [
        {
          props: { variant: "header" },
          style: {
            padding: "25px 65px !important",
          },
        },
        {
          props: { variant: "table-group" },
          style: {
            padding: "0 140px !important",
          },
        },
        {
          props: { variant: "table-group-md" },
          style: {
            padding: "0 65px !important",
          },
        },
        {
          props: { variant: "container" },
          style: {
            paddingTop: "25px !important",
            paddingBottom: "25px !important",
          },
        },
        {
          props: { variant: "tabs" },
          style: {
            padding: "0 25px !important",
          },
        },
      ],
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          "&.Mui-active": {
            color: "#22282C",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: "black",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    PrivatePickersYear: {
      styleOverrides: {
        root: {
          ">button": {
            fontSize: "14px",
          },
        },
      },
    },
  },
  topNavBar: {
    secondary: {
      backgroundColor: "rgba(242, 241, 236)",
    },
  },
  status: {
    active: "#95D122",
    inactive: "#FDBB30",
  },
  roundedButton: {
    radius: "16px",
  },
});
