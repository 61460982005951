import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import {
  Box,
  FormControlLabel,
  Checkbox,
  Stack,
  FormControl,
  Select,
  MenuItem,
  Typography,
  TextField,
} from "@mui/material";
import { mb10 } from "../sharedStyles";
import {
  gradeAttendanceInput,
  gradeAttendanceLabel,
  klassFormAttendancePointSelect,
  klassFormRow,
} from "./KlassForm.styles";

export default function GradeAttendance({
  categoryList,
  setCategoryList,
  activeKlass,
}) {
  const gradeCategoryActive = activeKlass?.klass_categories.find(
    (kc) => kc.grade_attendance_active === true
  );

  const gradeCategoryInactive = activeKlass?.klass_categories.find(
    (kc) =>
      kc.grade_attendance_active === false && kc.grade_attendance_total !== null
  );

  const gradeCategory = gradeCategoryActive || gradeCategoryInactive;

  const { register, watch, setValue } = useFormContext();

  const handleGradeAttendance = (e) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      setCategoryList(
        categoryList.concat({
          id: gradeCategory?.id || categoryList[categoryList.length - 1].id + 1,
          name: gradeCategory?.category || null,
          weight: Number(gradeCategory?.weight) || null,
          attendance_category: true,
          grade_attendance_grading:
            gradeCategory?.grade_attendance_grading || null,
          grade_attendance_total: gradeCategory?.grade_attendance_total || null,
          grade_attendance_absence:
            gradeCategory?.grade_attendance_absence || null,
          grade_attendance_tardy: gradeCategory?.grade_attendance_tardy || null,
          grade_attendance_no_penalty:
            gradeCategory?.grade_attendance_no_penalty || null,
        })
      );
    } else {
      const attendanceCategory = categoryList.find(
        (category) => category.attendance_category === true
      );
      setValue(`categoryName${attendanceCategory.id}`);
      setCategoryList(
        categoryList.filter((category) => category.attendance_category !== true)
      );
    }
  };

  useEffect(() => {
    setValue(
      "grade_attendance_grading",
      gradeCategory?.grade_attendance_grading || "points"
    );
  }, [gradeCategory]);

  return (
    <Box>
      <Stack direction="row">
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                onClick={(e) => handleGradeAttendance(e)}
                defaultChecked={gradeCategoryActive}
                {...register("grade_attendance")}
              />
            }
            label="Grade attendance"
          />
        </Box>
        {watch("grade_attendance") === true && (
          <Box>
            <Box sx={klassFormAttendancePointSelect}>
              <FormControl
                fullWidth
                sx={[klassFormRow, klassFormAttendancePointSelect]}
              >
                <Select
                  labelId="gradeAttendanceGrading"
                  id="gradeAttendanceGrading"
                  size="small"
                  defaultValue={
                    gradeCategory?.grade_attendance_grading || "points"
                  }
                  {...register("grade_attendance_grading")}
                >
                  <MenuItem value="points">Points</MenuItem>
                  <MenuItem value="percent">Percentage</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
        )}
      </Stack>

      {watch("grade_attendance") === true && (
        <Box>
          {watch("grade_attendance_grading") === "points" && (
            <Stack direction="row" alignItems="center" sx={mb10}>
              <Box sx={gradeAttendanceLabel}>
                <Typography>Total Points Possible</Typography>
              </Box>
              <Box>
                <FormControl sx={gradeAttendanceInput}>
                  <TextField
                    defaultValue={
                      Math.floor(gradeCategory?.grade_attendance_total) || null
                    }
                    required
                    size="small"
                    {...register("grade_attendance_total")}
                  />
                </FormControl>
              </Box>
              <Box>
                <Typography>Points</Typography>
              </Box>
            </Stack>
          )}
          <Stack direction="row" alignItems="center" sx={mb10}>
            <Box sx={gradeAttendanceLabel}>
              <Typography>Absence equals</Typography>
            </Box>
            <Box>
              <FormControl sx={gradeAttendanceInput}>
                <TextField
                  defaultValue={
                    Math.floor(gradeCategory?.grade_attendance_absence) || null
                  }
                  required
                  size="small"
                  {...register("grade_attendance_absence")}
                />
              </FormControl>
            </Box>
            <Box>
              <Typography>
                {watch("grade_attendance_grading") === "points"
                  ? "Points"
                  : "Percentage"}
              </Typography>
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={mb10}>
            <Box sx={gradeAttendanceLabel}>
              <Typography>Tardy equals</Typography>
            </Box>
            <Box>
              <FormControl sx={gradeAttendanceInput}>
                <TextField
                  defaultValue={
                    Math.floor(gradeCategory?.grade_attendance_tardy) || null
                  }
                  required
                  size="small"
                  {...register("grade_attendance_tardy")}
                />
              </FormControl>
            </Box>
            <Box>
              <Typography>
                {watch("grade_attendance_grading") === "points"
                  ? "Points"
                  : "Percentage"}
              </Typography>
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center">
            <Box sx={gradeAttendanceLabel}>
              <Typography>No penalty for</Typography>
            </Box>
            <Box>
              <FormControl sx={gradeAttendanceInput}>
                <TextField
                  defaultValue={
                    Math.floor(gradeCategory?.grade_attendance_no_penalty) ||
                    null
                  }
                  required
                  size="small"
                  {...register("grade_attendance_no_penalty")}
                />
              </FormControl>
            </Box>
            <Box>
              <Typography>Tardies</Typography>
            </Box>
          </Stack>
        </Box>
      )}
    </Box>
  );
}
