import React from "react";
import { Grid } from "@mui/material";
import { reportCardDashboardTableRow } from "./ReportCardDashboard.styles";

export default function ReportCardDashboardTableRow(props) {
  const { children } = props;
  return (
    <Grid container sx={reportCardDashboardTableRow} spacing={2}>
      {children}
    </Grid>
  );
}
