import * as React from "react";
import { Typography, Box, Divider } from "@mui/material";
import { p10 } from "../../sharedStyles";

const medicalNotesContainer = {
  color: "black",
  my: 5,
};
const titleStyle = {
  fontSize: "28px",
  p: "10px",
  display: "block",
};

export default function MedicalAccomodations({ details, editMedicalRecord }) {
  return (
    <Box sx={medicalNotesContainer}>
      <Typography variant="title" sx={titleStyle}>
        Medical Accommodations
      </Typography>
      {details.map((detail) => (
        <Box
          key={detail.id}
          onClick={() => editMedicalRecord(detail.id)}
          style={{ cursor: "pointer" }}
        >
          <Typography sx={p10}>{detail.comment_or_instruction}</Typography>
          <Typography sx={p10}>
            <b>BEGINS:</b>{" "}
            {new Date(detail.reported_date.replace("Z", "")).toLocaleDateString(
              "en-US",
              {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              }
            )}
            <br />
            <b>ENDS:</b>{" "}
            {detail.ending_date &&
              new Date(detail.ending_date.replace("Z", "")).toLocaleDateString(
                "en-US",
                {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                }
              )}
          </Typography>
          <Divider />
        </Box>
      ))}
    </Box>
  );
}
