import React from "react";
import { Outlet, useParams, useSearchParams } from "react-router-dom";
import { Box, Container } from "@mui/material";
import TermGradeTabs from "./TermGradeTabs";
import { subjectContainer } from "../../../views/Subject.styles";
import termGradeContainer from "./TermGrade.styles";

export default function TermGrade() {
  const [searchParams] = useSearchParams();
  const schoolId = useParams().school_id;
  const subjectId = useParams().subject_id;
  const klassesIds = searchParams.get("classes") || null;
  const termId = searchParams.get("term");

  return (
    <>
      <Box sx={subjectContainer} />
      <Container maxWidth={false} variant="header">
        <Container maxWidth="xl">
          <TermGradeTabs
            schoolId={schoolId}
            subjectId={subjectId}
            klassesIds={klassesIds}
            termId={termId}
          />

          <Box sx={termGradeContainer}>
            <Outlet context={[schoolId, subjectId, klassesIds, termId]} />
          </Box>
        </Container>
      </Container>
    </>
  );
}
