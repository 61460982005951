import React, { useEffect, useState } from "react";
import {
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { Grid } from "@mui/material";
import customFieldsService from "../../../../service/customFieldsService";
import dataTypeClassMap from "../../../Admissions/Setup/FormBuilder/Models/Fields/dataTypeClassMap";
import customFieldComponentsMap from "./CustomFieldComponents/customFieldComponentsMap";

export default function CustomFieldRenderer({
  sectionType,
  customFieldResponses,
}) {
  const [fields, setFields] = useState([]);
  const methods = useForm({ mode: "onChange" });
  const { control } = methods;
  const { setValue } = useFormContext();
  const watcher = useWatch({
    control,
  });
  const [fieldsMap, setFieldsMap] = useState();
  useEffect(() => {
    customFieldsService.fetchAllCustomFields().then((response) => {
      const studentProfileFields = response.data.filter(
        (f) => f.section_type === sectionType
      );
      setFields(studentProfileFields.sort((a, b) => a.order - b.order));
      setFieldsMap(
        new Map(
          studentProfileFields.map((field, index) => [
            field.field_id,
            { element: field, index },
          ])
        )
      );
    });
  }, []);

  useEffect(() => {
    if (
      methods.formState.isValidating ||
      Object.keys(methods.formState.errors).length
    )
      return;
    const customFieldsData = [];
    const data = watcher.custom_field_responses;
    if (data) {
      Object.keys(data).forEach((key) => {
        const fieldElement = fieldsMap.get(key).element;
        const fieldResponse = {
          custom_field_id: fieldElement.id,
        };
        fieldResponse[`${fieldElement.data_type}`] = dataTypeClassMap[
          fieldElement.data_type
        ](data[key]);
        customFieldsData.push(fieldResponse);
      });
      setValue("custom_field_responses", customFieldsData, {
        shouldDirty: true,
      });
    }
  }, [watcher, methods.formState.isValidating]);

  return (
    <FormProvider {...methods}>
      <form style={{ width: "100%" }}>
        <Grid container>
          {fields.map((field) => {
            const defaultValue = (customFieldResponses.find(
              (r) => r.custom_field_id === field.id
            ) || {})[field.data_type];
            const Component = customFieldComponentsMap[field.field_type];
            return (
              field.properties.isVisible && (
                <Grid item md={3}>
                  <Component
                    field={field}
                    properties={field.properties}
                    defaultValue={defaultValue}
                  />
                </Grid>
              )
            );
          })}
        </Grid>
      </form>
    </FormProvider>
  );
}
