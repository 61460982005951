import { tableCellClasses } from "@mui/material";

const tableStyles = {
  width: "100%",
  display: "table",
  tableLayout: "fixed",
  [`& .${tableCellClasses.root}`]: {
    borderBottom: "none",
  },
  "& .MuiTableCell-head": {
    color: "#000000",
    fontWeight: "700 !important",
    textTransform: "uppercase",
  },
  "& .MuiTableCell-body": {
    color: "black",
    fontSize: "11px",
  },
  "& .email-container": {
    maxWidth: "160px",
    wordWrap: "break-word",
  },
};
export default tableStyles;
