import FieldBase from "./FieldBase";

export default class PageBreak extends FieldBase {
  constructor(field) {
    super(field);
    this.editable = false;
    this.removable = false;
    this.isDraggable = false;
    this.isResizable = false;
    this.w = 12;
    this.propertyComponents = [];
  }
}
