import {
  Divider,
  Grid,
  Typography,
  TableContainer,
  Table,
  TableRow,
  Input,
  TableCell,
  TableBody,
  IconButton,
  Box,
} from "@mui/material";
import * as React from "react";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";
import { useFormContext } from "react-hook-form";
import { useContext } from "react";
import AddButton from "../../Account/AddButton";
import {
  addButtonStyle,
  tableStyles,
  documentFilesRow,
  tableCellName,
  tableCellType,
  actionContainerStyle,
  actionButtonStyle,
  iconButtonStyle,
  tableCellNameInnerContainer,
  fileIconStyle,
  actionButtonCursorStyle,
} from "./Document.style";
import { convertBase64 } from "../../../../utils/constants/utils";
import AlertDialog from "../../../AlertDialog";
import fileService from "../../../../service/fileService";
import { PermissionsContext } from "../../../../context/PermissionsContext";
import APP_PERMISSIONS from "../../../../utils/constants/permissions";
import PERMISSION_TYPES from "../../../../utils/constants/permission_types";

export default function Documents({ document, studentId }) {
  const [file, setFile] = React.useState(false);
  const { setValue } = useFormContext();
  const fileInputRef = React.useRef(null);
  const [isDialogOpen, setDialogState] = React.useState(false);

  const { hasPermission } = useContext(PermissionsContext);

  const managePermission = hasPermission(
    APP_PERMISSIONS.STUDENT_MEDICAL,
    PERMISSION_TYPES.MANAGE
  );
  const handleFileChange = async (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
      const fileData = await convertBase64(e.target.files[0]);
      setValue("document", fileData, { shouldDirty: true });
      setValue("document_name", e.target.files[0].name, { shouldDirty: true });
    } else {
      setValue("document", null, { shouldDirty: true });
    }
  };
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const deleteFile = async () => {
    setDialogState(true);
  };
  const handleDeleteFileConfirm = () => {
    setFile(null);
    setValue("document", null, { shouldDirty: true });
    setDialogState(false);
  };
  const handleClose = () => {
    setDialogState(false);
  };
  const initializeData = async () => {
    if (document) {
      const response = await fetch(document);
      const blobObj = await response.blob();
      const base64 = await convertBase64(blobObj);
      setValue("document", base64, { shouldDirty: true });
      setValue("document_name", document.filename, {
        shouldDirty: true,
      });
      setFile({ name: document.filename });
    }
  };

  const handleFileDownload = async (row) => {
    await fileService.fetchMedicalFile({
      id: row.id,
      student_id: studentId,
      filename: row.filename,
    });
  };

  React.useEffect(() => {
    initializeData();
  }, []);
  return (
    <Grid item xs={12} mt={2}>
      <Typography variant="h6">Document</Typography>
      <Divider sx={{ borderColor: "black" }} />
      <Grid item xs={12} sx={addButtonStyle}>
        {!file && (
          <>
            <AddButton onClick={handleButtonClick}>
              <Typography>Add file from your computer</Typography>
            </AddButton>
            <Input
              type="file"
              inputRef={fileInputRef}
              style={{ display: "none" }}
              accept=".jpeg, .jpg, .png"
              onChange={handleFileChange}
            />
          </>
        )}
        {file && (
          <TableContainer elevation={0}>
            <Table sx={tableStyles} aria-label="simple table">
              <TableBody>
                <TableRow sx={documentFilesRow}>
                  <TableCell sx={tableCellName}>
                    <div style={tableCellNameInnerContainer}>
                      <InsertDriveFileOutlinedIcon sx={fileIconStyle} />
                      {file.name}
                    </div>
                  </TableCell>
                  <TableCell sx={tableCellType}>
                    {file.name.split(".").pop().toUpperCase()}
                  </TableCell>
                  {document && (
                    <TableCell sx={actionButtonCursorStyle}>
                      <Box
                        sx={{ display: "flex" }}
                        onClick={() => handleFileDownload(document)}
                      >
                        <SimCardDownloadOutlinedIcon sx={actionButtonStyle} />
                        Download
                      </Box>
                    </TableCell>
                  )}
                  <TableCell>
                    {managePermission && (
                      <div style={actionContainerStyle}>
                        <IconButton
                          onClick={deleteFile}
                          style={iconButtonStyle}
                        >
                          <DeleteForeverOutlinedIcon sx={actionButtonStyle} />
                        </IconButton>
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
      <AlertDialog
        isOpen={isDialogOpen}
        handleClose={handleClose}
        handleConfirm={handleDeleteFileConfirm}
      />
    </Grid>
  );
}
