import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { useParams } from "react-router-dom";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {
  backButton,
  backArrow,
  detailedAssignmentContainer,
  detailedAssignmentCard,
  assignmentName,
  assignmentDue,
  assignmentDesc,
  assignmentAttachment,
  assignmentAttachments,
  assignmentAttachmentIcon,
  assignmentAttachmentName,
  turnInBox,
  turnInTitle,
  turnInLink,
  turnInSubmit,
  turnInFile,
  turnedInAt,
  turnInFileName,
  assignmentGrade,
} from "./AssignmentDetail.style";
import moment from "../../../utils/constants/momentConfig";
import dateTimeFormats from "../../../utils/constants/dateTimeFormats";
import { SnackbarContext } from "../../../context/SnackbarContext";
import { updateTurnInAssignment } from "../../../service/studentPortalService";
import { danger } from "../../sharedStyles";
import fileService from "../../../service/fileService";

export default function StudentPortalAssignmentDetail({
  setShowDetailedAssignment,
  activeAssignment,
  activeAssignmentGrade,
}) {
  const [linkError, setLinkError] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [linkErrorMessage, setLinkErrorMessage] = useState(null);
  const [fileErrorMessage, setFileErrorMessage] = useState(null);
  const [turnInOnlineLink, setTurnInOnlineLink] = useState(
    activeAssignment?.assignment_students[0]?.turned_in_online_link || null
  );
  const [turnInOnlineFile, setTurnInOnlineFile] = useState(
    activeAssignment?.assignment_students[0]
      ?.turned_in_online_file_attachment_details || null
  );
  const snackbarContext = useContext(SnackbarContext);
  const params = useParams();
  const studentId = params.id;
  const schoolId = params.school_id;

  const handleFileUpload = (e) => {
    setFileErrorMessage(null);
    setTurnInOnlineFile(e.target.files[0]);
  };

  // eslint-disable-next-line consistent-return
  const displayGrade = () => {
    if (activeAssignment.assignment_students[0].score === "/") {
      return "missing";
    }
    if (activeAssignmentGrade !== "-") {
      return activeAssignmentGrade;
    }
  };

  const handleAssignmentFileDownload = async (file) => {
    await fileService.fetchAssignmentFile({
      id: file?.id,
      student_id: studentId,
      filename: file?.name,
      schoolID: schoolId,
    });
  };

  const handleAssignmentTurnInFileDownload = async () => {
    await fileService.fetchTurnedInAssignmentFile({
      id: turnInOnlineFile?.id,
      student_id: studentId,
      filename: turnInOnlineFile?.name,
      schoolID: schoolId,
    });
  };

  const submitTurnInOnline = async () => {
    setSubmitLoading(true);
    setLinkError(false);
    setLinkErrorMessage(null);
    setFileErrorMessage(null);

    if (turnInOnlineLink || turnInOnlineFile) {
      try {
        // URL validation
        if (turnInOnlineLink) {
          const isTurnInOnlineLinkValid = document
            .querySelector("#turn-in-link")
            .checkValidity();
          if (!isTurnInOnlineLinkValid) {
            setLinkError(true);
            setLinkErrorMessage("Invalid URL");
            setSubmitLoading(false);
            return;
          }
        }

        // file size validation
        if (turnInOnlineFile && turnInOnlineFile.size / 1000000 > 20) {
          setFileErrorMessage("Max file size is 20 MB");
          setSubmitLoading(false);
          return;
        }

        const formData = new FormData();
        formData.append("assignment_id", activeAssignment.id);
        formData.append(
          "assignment_student_id",
          activeAssignment?.assignment_students[0]?.id
        );
        formData.append("turnInOnlineLink", turnInOnlineLink);
        formData.append("turnInOnlineFile", turnInOnlineFile);
        const response = await updateTurnInAssignment(studentId, formData);
        if (response) {
          // eslint-disable-next-line no-param-reassign
          activeAssignment.assignment_students[0].turned_in_online_link =
            turnInOnlineLink;
          // eslint-disable-next-line no-param-reassign
          activeAssignment.assignment_students[0].turned_in_online_file_attachment_details =
            response.file;
          setTurnInOnlineFile(response.file);
          // eslint-disable-next-line no-param-reassign
          activeAssignment.assignment_students[0].turned_in_online_at =
            moment();
          snackbarContext.setSnackbar({
            message: "Assignment turned in.",
            severity: "success",
            open: true,
          });
        }
      } catch (e) {
        snackbarContext.setSnackbar({
          message: "Something went wrong.",
          severity: "error",
          open: true,
        });
      }
    } else {
      setLinkError(true);
      setLinkErrorMessage("This field is required");
    }
    setSubmitLoading(false);
  };

  return (
    <Container sx={detailedAssignmentContainer}>
      <Box>
        <Button
          onClick={() => {
            setShowDetailedAssignment(false);
          }}
          sx={backButton}
        >
          <ArrowBackRoundedIcon size="small" sx={backArrow} />
          View all assignments
        </Button>
      </Box>
      <Card sx={detailedAssignmentCard}>
        <CardContent>
          <Typography sx={assignmentGrade}>{displayGrade()}</Typography>
          <Typography sx={assignmentName}>{activeAssignment.name}</Typography>
          <Typography sx={assignmentDue}>
            Due:{" "}
            {moment(activeAssignment.due_date).format(
              dateTimeFormats.ddddMMMMD
            )}
          </Typography>
          {activeAssignment.description && (
            <Typography sx={assignmentDesc}>
              {activeAssignment.description}
            </Typography>
          )}

          {activeAssignment.assignment_attachments.length > 0 && (
            <Box sx={assignmentAttachments}>
              {activeAssignment.assignment_attachments.map((attachment) => (
                <Box sx={assignmentAttachment} key={attachment.id}>
                  <InsertDriveFileOutlinedIcon sx={assignmentAttachmentIcon} />
                  <Typography
                    sx={assignmentAttachmentName}
                    onClick={async () => {
                      await handleAssignmentFileDownload(attachment);
                    }}
                  >
                    {attachment.name}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
        </CardContent>
      </Card>

      {activeAssignment.turn_in_online && (
        <Card sx={detailedAssignmentCard}>
          <CardContent>
            <Box sx={turnInBox}>
              <Typography sx={turnInTitle}>Turn in assignment</Typography>
              {activeAssignment.turn_in_online_using === "file" ? (
                <>
                  <Button
                    sx={turnInFile}
                    variant="contained"
                    component="label"
                    startIcon={<FileDownloadOutlinedIcon />}
                  >
                    Upload File
                    <input
                      accept=".jpeg, .jpg, .pdf, .png, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .zip"
                      hidden
                      type="file"
                      onChange={handleFileUpload}
                    />
                  </Button>
                  {turnInOnlineFile && (
                    <>
                      <Typography
                        sx={turnInFileName}
                        onClick={async () => {
                          if (turnInOnlineFile.id) {
                            await handleAssignmentTurnInFileDownload();
                          }
                        }}
                      >
                        {turnInOnlineFile.name.substring(0, 40)}
                      </Typography>

                      {turnInOnlineFile?.lastModified && (
                        <Button
                          sx={turnInSubmit}
                          variant="contained"
                          disabled={submitLoading}
                          onClick={submitTurnInOnline}
                        >
                          {submitLoading ? "Submitting..." : "Submit"}
                        </Button>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <TextField
                    type="url"
                    error={linkError}
                    sx={turnInLink}
                    required
                    id="turn-in-link"
                    label="Website URL"
                    defaultValue={
                      activeAssignment?.assignment_students[0]
                        ?.turned_in_online_link
                    }
                    onChange={(event) => {
                      const targetValue = event.target.value;
                      if (targetValue) {
                        setLinkError(false);
                        setLinkErrorMessage(null);
                      }
                      setTurnInOnlineLink(event.target.value);
                    }}
                    helperText={linkErrorMessage && linkErrorMessage}
                  />

                  <Button
                    sx={turnInSubmit}
                    variant="contained"
                    disabled={submitLoading}
                    onClick={submitTurnInOnline}
                  >
                    {submitLoading ? "Submitting..." : "Submit"}
                  </Button>
                </>
              )}
            </Box>

            {fileErrorMessage && (
              <Typography sx={danger} style={{ paddingLeft: "240px" }}>
                {fileErrorMessage}
              </Typography>
            )}

            {activeAssignment?.assignment_students[0]?.turned_in_online_at && (
              <Box>
                <Typography sx={turnedInAt}>
                  Turned in{" "}
                  {moment(
                    activeAssignment?.assignment_students[0]
                      ?.turned_in_online_at
                  ).format(dateTimeFormats.MMDDYYYY)}{" "}
                  at{" "}
                  {moment(
                    activeAssignment?.assignment_students[0]
                      ?.turned_in_online_at
                  ).format(dateTimeFormats.TIME)}
                </Typography>
              </Box>
            )}
          </CardContent>
        </Card>
      )}
    </Container>
  );
}
