import axios from "../utils/axios-util";

const temporaryStaffsEndpoint = "/api/temporary_staffs";

const fetchAllStaffs = async (params) => {
  const { data } = await axios.get(`${temporaryStaffsEndpoint}`, params);
  if (!data) {
    console.error("Error fetching users from the API");
    return [];
  }
  return data;
};

const fetchPaginatedTemporaryStaffs = async (params) => {
  const { data } = await axios.get(
    `${temporaryStaffsEndpoint}/paginated_list`,
    params
  );
  if (!data) {
    console.error("Error fetching users from the API");
    return [];
  }
  return data;
};

const fetchStaff = async (id) => {
  const { data } = await axios.get(`${temporaryStaffsEndpoint}/${id}`);
  if (!data) {
    console.error("Error fetching teacher from the API");
    return [];
  }
  return data;
};

const create = (data) => axios.post(`${temporaryStaffsEndpoint}`, data);

const update = (id, data) =>
  axios.put(`${temporaryStaffsEndpoint}/${id}`, data);

const deleteStaff = (id) => axios.delete(`${temporaryStaffsEndpoint}/${id}`);

const createRecordSubtitute = (data) =>
  axios.post(`${temporaryStaffsEndpoint}/record_substitute`, data);

export default {
  fetchAllStaffs,
  fetchPaginatedTemporaryStaffs,
  fetchStaff,
  update,
  create,
  deleteStaff,
  createRecordSubtitute,
};
