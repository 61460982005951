import { Box, Checkbox, FormControl, FormControlLabel } from "@mui/material";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";

export default function IsRequired({ fieldProp }) {
  const { setValue } = useFormContext();
  const [isRequired, setIsRequired] = useState(fieldProp.properties.isRequired);
  return (
    <Box ml={2} mb={2}>
      <FormControl fullWidth>
        <FormControlLabel
          control={
            <Checkbox
              checked={isRequired}
              onChange={(e) => {
                setValue("properties.isRequired", e.target.checked, {
                  shouldDirty: true,
                });
                setIsRequired(e.target.checked);
              }}
            />
          }
          label="Required"
          defaultChecked={false}
        />
      </FormControl>
    </Box>
  );
}
