import {
  Box,
  Button,
  Grid,
  Popover,
  TableCell,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import prekDevelopmentService from "../../service/preKDevelopmentService";
import { SnackbarContext } from "../../context/SnackbarContext";
import {
  tableBodyGrade,
  tableBorderRight,
  cellInput,
  cellSquare,
  cellGrade,
  cellPopover,
  popoverButton,
  popoverHead,
  popoverGradeBox,
} from "./Cell.styles";
import theme from "../../utils";
import GradeLockIcon from "../GradeLockIcon";

export default function Cell({
  element,
  setReloadComment,
  availableGrades,
  studentId,
  isReportCardPublished,
  managePermission,
}) {
  const snackbarContext = useContext(SnackbarContext);
  const [grade, setGrade] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openPopover = Boolean(anchorEl);
  const popoverId = openPopover ? "prek-popover" : undefined;

  const handlePopoverClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  // eslint-disable-next-line consistent-return
  const handleGradeChange = async (event, selectedGrade = "") => {
    const newGrade = selectedGrade || event.target.value;
    if (
      newGrade !== "" &&
      !Object.keys(availableGrades).includes(newGrade.toUpperCase())
    ) {
      return handlePopoverClick(event);
    }
    setGrade(newGrade);
    await handlePopoverClose();

    const response =
      await prekDevelopmentService.updateStudentDomainElementGrade(
        [element.id],
        newGrade
      );

    if (response) {
      snackbarContext.setSnackbar({
        message: "Grade saved.",
        severity: "success",
        open: true,
      });
    }

    if (!newGrade) {
      // check posted_at
      const gradeValues = [];
      const row = document.getElementsByClassName(
        `prek-tbody-row-${studentId}`
      )[0];
      const cells = row.children;
      for (let j = 1; j < cells.length - 1; j += 1) {
        gradeValues.push(cells[j].querySelector("input").value);
      }
      if (gradeValues.filter((value) => !value.trim()).length === 1) {
        setReloadComment(true);
      }
    }
  };

  useEffect(() => {
    setGrade(element.grade || "");
  }, [element]);

  return (
    <>
      <TableCell
        sx={[tableBodyGrade, tableBorderRight, cellGrade]}
        key={element.id}
        align="center"
      >
        <TextField
          variant="standard"
          sx={cellSquare}
          disabled={isReportCardPublished || !managePermission}
          InputProps={{
            sx: {
              ...cellInput,
            },
            disableUnderline: true,
            id: `${element.id}`,
            className: `grading_input_${element.prek_development_domain_element_id}`,
          }}
          value={grade}
          onChange={(event) => handleGradeChange(event)}
        />
        {isReportCardPublished && <GradeLockIcon />}
      </TableCell>

      <Popover
        PaperProps={{
          elevation: 0,
          sx: cellPopover,
        }}
        id={popoverId}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Grid container mt={3}>
          <Grid item xs={12} align="center">
            <Typography
              variant="title"
              sx={{ color: theme.palette.text.black }}
            >
              Input Options
            </Typography>
          </Grid>
          <Grid item xs={12} mt={2} mb={1} align="center">
            <Typography variant="subtitle" sx={popoverHead}>
              The following options are <br /> allowed for this assessment.
            </Typography>
            {Object.keys(availableGrades).map((g) => (
              <Box key={g} sx={popoverGradeBox}>
                <Button
                  variant="outlined"
                  sx={popoverButton}
                  onClick={(event) => handleGradeChange(event, g)}
                >
                  {g}
                </Button>
                <Typography>{availableGrades[g]}</Typography>
              </Box>
            ))}
          </Grid>
        </Grid>
      </Popover>
    </>
  );
}
