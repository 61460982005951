import axios from "../utils/axios-util";

const klassesStudentsEndpoint = "/api/klasses_students";

const fetchAllKlassesStudents = async (params) => {
  const { data } = await axios.get(klassesStudentsEndpoint, params);

  if (!data.data) {
    console.error("Error fetching klasses from the API");
    return [];
  }
  return data;
};

const fetchKlassesStudentsInSubject = async (params) => {
  const { data } = await axios.get(
    `${klassesStudentsEndpoint}/in_subject`,
    params
  );

  if (!data) {
    console.error("Error fetching klass students from the API");
    return [];
  }
  return data;
};

const create = async (params) => {
  const { data } = await axios.post(klassesStudentsEndpoint, params);
  if (!data.data) {
    console.error("Error creating classes students");
    return [];
  }
  return data;
};

const update = async (klassStudentId, data) =>
  axios.patch(`${klassesStudentsEndpoint}/${klassStudentId}`, data);

export default {
  fetchAllKlassesStudents,
  create,
  fetchKlassesStudentsInSubject,
  update,
};
