const groupSearchButton = {
  height: "33px",
  width: "33px",
};

const groupSearchButtonIcon = {
  color: "action.active",
  mr: 1,
  my: 0.5,
};

export { groupSearchButton, groupSearchButtonIcon };
