import { Box, Grid, Typography } from "@mui/material";
import React from "react";

export default function SchoolDayItem({ schoolDay, terms, dayTemplates }) {
  const termsStartDates = terms.map((term) => term.start_date);
  const termsEndDates = terms.map((term) => term.end_date);

  const shouldShowSchoolDay = typeof schoolDay === "object";

  const dayDesignationText = (_schoolDay) => {
    if (_schoolDay.day_template_id) {
      return dayTemplates.find((dt) => dt.id === _schoolDay.day_template_id)
        .name;
    }
    return _schoolDay?.school_day_designation?.name;
  };

  return (
    <Grid
      item
      xs={1.7}
      sx={
        shouldShowSchoolDay
          ? {
              minHeight: "90px",
            }
          : {}
      }
    >
      <Box sx={{ minHeight: "90px", padding: 1 }}>
        <Typography color="black" fontWeight={500} fontSize="14px">
          {shouldShowSchoolDay ? new Date(schoolDay.date).getUTCDate() : ""}
        </Typography>
        {shouldShowSchoolDay && (
          <>
            <Typography fontSize="11px" color="black">
              {dayDesignationText(schoolDay)}
            </Typography>
            {termsStartDates.includes(schoolDay.date) && (
              <Typography
                fontSize="11px"
                fontWeight="bold"
                color="black"
                textTransform="uppercase"
              >{`${
                terms.find((term) => term.start_date === schoolDay.date).name
              } starts`}</Typography>
            )}
            {termsEndDates.includes(schoolDay.date) && (
              <Typography
                fontSize="11px"
                fontWeight="bold"
                color="black"
                textTransform="uppercase"
              >{`${
                terms.find((term) => term.end_date === schoolDay.date).name
              } ends`}</Typography>
            )}
            <Typography
              color="black"
              variant="body2"
              fontStyle="italic"
              fontSize="11px"
            >
              {schoolDay.comment}
            </Typography>
          </>
        )}
      </Box>
    </Grid>
  );
}
