const termGradeKlassCell = {
  color: "black",
  borderColor: "black",
  py: 0,
  position: "relative",
};

const termGradeKlassCellPadding = {
  borderBottom: "none",
};

const termGradeKlassCellName = {
  ...termGradeKlassCell,
  fontSize: "20px",
};

const termGradeKlassCellGrade = {
  ...termGradeKlassCell,
  px: 0,
  width: "100px",
  height: "70px",
  borderLeft: "1px solid black",
  backgroundColor: "white",
  "&:not(:last-of-type)": {
    borderRight: "1px solid black",
  },
  "& :hover": {
    cursor: "pointer",
    backgroundColor: "#ededed",
  },
  "& p": {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

const termGradeKlassCellGradeDisabled = {
  ...termGradeKlassCellGrade,
  backgroundColor: "#D9D9D9",
  "& :hover": {
    cursor: "normal",
    backgroundColor: "#D9D9D9",
  },
};

const termGradeKlassCellComment = {
  ...termGradeKlassCell,
  width: "560px",
};

export {
  termGradeKlassCell,
  termGradeKlassCellPadding,
  termGradeKlassCellName,
  termGradeKlassCellGrade,
  termGradeKlassCellComment,
  termGradeKlassCellGradeDisabled,
};
