import React, { useState } from "react";
import {
  Typography,
  Stack,
  Grid,
  Popover,
  Button,
  TextField,
  Divider,
} from "@mui/material";
import theme from "../../utils/index";
import SkillOptions from "./SkillOptions";
import SocialDevelopmentOptions from "./SocialDevelopmentOptions";
import useFormattedMarkingCodes from "../../hooks/useFormattedMarkingCodes";
import { my5 } from "../sharedStyles";

export default function PopoverComponent({
  anchorEl,
  setAnchorEl,
  showSpecialMarks,
  setShowSpecialMarks,
  handleClick,
  handleMaxScoreChange,
  student,
  currentAssignment,
  currentTarget,
  specialMarks,
  markingCodes,
  grades,
  scores,
  skill = null,
  gradingScale = null,
  gradingInput = null,
}) {
  const open = Boolean(anchorEl);
  const formattedMarkingCodes = useFormattedMarkingCodes(markingCodes);
  const [scoreInputValue, setScoreInputValue] = useState(null);
  const [maxScoreInputValue, setMaxScoreInputValue] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
    if (skill === null && gradingScale === null) {
      setShowSpecialMarks(false);
    }
  };

  const isLetterGrade = currentAssignment?.grading === "letter_grade";

  const renderSpecialMarks =
    (showSpecialMarks && isLetterGrade) || !isLetterGrade;

  const assignmentGrade = grades?.filter(
    (g) => g.assignment_id === currentAssignment?.id
  );
  const hasScores = assignmentGrade
    ? !assignmentGrade?.every(
        (grade) => grade.score === null || grade.score === ""
      )
    : !scores?.every((score) => score === null || score === "");

  function renderPopoverHeader() {
    return (
      <Grid container mt={3}>
        <Grid item xs={12} align="center">
          <Typography variant="title" sx={{ color: theme.palette.text.black }}>
            Input Options
          </Typography>
        </Grid>
        <Grid item xs={12} mt={2} mb={1} align="center">
          <Typography variant="subtitle">
            The following options are <br />
            allowed for this assignment:
          </Typography>
        </Grid>
      </Grid>
    );
  }

  function renderInputOptions() {
    return (
      <>
        {formattedMarkingCodes.map((row, index) => (
          <Grid
            container
            item
            xs={12}
            align="center"
            mt={1}
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            key={row[index]?.code}
          >
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              {row.map((option) => (
                <Button
                  key={option?.code}
                  variant="outlined"
                  sx={{
                    maxWidth: "50px",
                    maxHeight: "40px",
                    minWidth: "50px",
                    minHeight: "40px",
                  }}
                  disabled={option?.code === ""}
                  onClick={() =>
                    handleClick(
                      student,
                      currentAssignment,
                      option?.code,
                      currentTarget
                    )
                  }
                >
                  {option?.code}
                </Button>
              ))}
            </Stack>
          </Grid>
        ))}
        <Grid item xs={12} align="center" mt={2}>
          <Button
            variant="text"
            sx={{
              fontWeight: "400",
              textTransform: "none",
              color: theme.palette.text.strongBlue,
            }}
            onClick={() => setShowSpecialMarks(true)}
          >
            Show special marks
          </Button>
        </Grid>
      </>
    );
  }

  return (
    <Popover
      anchorEl={anchorEl}
      id="menu"
      open={open}
      onClose={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          width: "350px",
          borderRadius: 5,
        },
      }}
      anchorOrigin={{
        vertical: "center",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
    >
      {renderPopoverHeader()}

      {!showSpecialMarks && isLetterGrade && renderInputOptions()}

      {skill && SkillOptions(skill, formattedMarkingCodes, handleClick)}
      {gradingScale &&
        SocialDevelopmentOptions(
          gradingScale,
          formattedMarkingCodes,
          handleClick
        )}

      {renderSpecialMarks && (
        <>
          <Grid item xs={12} align="center">
            {isLetterGrade && (
              <Button
                variant="text"
                sx={{
                  fontWeight: "light",
                  textTransform: "none",
                  color: theme.palette.text.strongBlue,
                }}
                onClick={() => setShowSpecialMarks(false)}
              >
                Hide special marks
              </Button>
            )}
          </Grid>
          <Grid
            container
            item
            xs={12}
            align="center"
            mb={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            {!isLetterGrade &&
              (gradingInput === "points" || gradingInput === "percent") && (
                <>
                  <Grid container item xs={12}>
                    <Grid item xs={4}>
                      <TextField
                        sx={{ width: "64px" }}
                        inputProps={{
                          style: {
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            textAlign: "center",
                          },
                        }}
                        variant="outlined"
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleClick(
                              student,
                              currentAssignment,
                              scoreInputValue,
                              currentTarget
                            );
                          }
                        }}
                        onChange={(e) => {
                          setScoreInputValue(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={4} alignSelf="center">
                      <Typography>Enter Score</Typography>
                    </Grid>
                    <Grid item xs={4} alignSelf="center">
                      {/* <Typography>{specialMark.score}</Typography> */}
                    </Grid>
                  </Grid>
                  <Divider
                    sx={{
                      width: "80%",
                      marginTop: "0.5rem",
                    }}
                  />
                </>
              )}

            {specialMarks?.map((specialMark) => (
              <Grid container item xs={12} key={specialMark.code}>
                <Grid item xs={4}>
                  <Button
                    variant="outlined"
                    sx={{
                      textTransform: "none",
                    }}
                    onClick={() =>
                      handleClick(
                        student,
                        currentAssignment,
                        specialMark.code,
                        currentTarget
                      )
                    }
                  >
                    {specialMark.code}
                  </Button>
                </Grid>
                <Grid item xs={4} alignSelf="center">
                  <Typography>{specialMark.text}</Typography>
                </Grid>
                <Grid item xs={4} alignSelf="center">
                  <Typography>{specialMark.score}</Typography>
                </Grid>
              </Grid>
            ))}
            {gradingInput === "points" && (
              <Divider
                sx={{
                  width: "80%",
                  my: "0.5rem",
                }}
              />
            )}
            {gradingInput === "points" && (
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ width: "250px" }}
              >
                <Typography sx={my5}>Max Points</Typography>
                <TextField
                  disabled={hasScores}
                  defaultValue={currentAssignment?.max_score}
                  sx={{ width: "64px", mr: "5px" }}
                  inputProps={{
                    style: {
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      textAlign: "center",
                    },
                  }}
                  variant="outlined"
                  onChange={(e) => {
                    setMaxScoreInputValue(e.target.value);
                  }}
                />
                <Button
                  sx={{
                    color: "#2196F3",
                    borderColor: "#2196F3",
                    "&:hover": {
                      borderColor: "#2196F3",
                    },
                  }}
                  variant="outlined"
                  size="small"
                  disabled={hasScores || maxScoreInputValue === ""}
                  onClick={() =>
                    handleMaxScoreChange(currentAssignment, maxScoreInputValue)
                  }
                >
                  Update
                </Button>
              </Stack>
            )}
          </Grid>
        </>
      )}
    </Popover>
  );
}
