import Grid from "@mui/material/Grid";
import { Outlet } from "react-router-dom";
import * as React from "react";
import { Footer } from "../components";

export default function LoginLayout() {
  return (
    <Grid
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      sx={{
        backgroundColor: "primary.main",
        color: "#ffffff",
        minHeight: "100vh",
      }}
      container
    >
      <Grid container item xs={12} sx={{ flex: 1 }} />
      <Grid container item xs={12} sx={{ flex: 1 }}>
        <Outlet />
      </Grid>
      <Footer />
    </Grid>
  );
}
