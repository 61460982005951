const ipntOptionsWithNa = (formattedMarkingCodes) => [
  ...formattedMarkingCodes,
  [{ code: "N/A", value: "N/A" }],
];

const osnOptions = [{ button: "O" }, { button: "S" }, { button: "N" }];

const proficiencyLevels = [
  {
    value: 4,
    title: "Exceeds",
    description:
      "I understand, I can teach a friend. I can even show you more.",
  },
  {
    value: 3,
    title: "Proficient",
    description: "I understand and can do it by myself.",
  },
  {
    value: 2,
    title: "Developing",
    description:
      "I understand some and can get started, but I'm a little confused.",
  },
  {
    value: 1,
    title: "Novice",
    description: "I don't understand.  I can't start without help.",
  },

  {
    value: "N/A",
    title: "N/A",
    description: "Not evaluated.",
  },
];

export { proficiencyLevels, ipntOptionsWithNa, osnOptions };
