import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  backButton,
  backArrow,
  detailedAssignmentContainer,
  detailedAssignmentCard,
  assignmentName,
  assignmentDue,
  assignmentDesc,
  assignmentAttachment,
  assignmentAttachments,
  assignmentAttachmentIcon,
  assignmentAttachmentName,
  turnInBox,
  turnInTitle,
  turnInLink,
  turnedInAt,
  turnInFileName,
  assignmentGrade,
} from "./AssignmentDetail.style";
import {
  weekShortDate,
  shortDateTime,
} from "../../../../utils/dateTimePresets";
import fileService from "../../../../service/fileService";

export default function GuardianKidsInfoAssignmentDetail({
  setShowDetailedAssignment,
  activeAssignment,
  activeAssignmentGrade,
}) {
  const { t, i18n } = useTranslation("guardian", {
    keyPrefix: "classAssignments",
  });
  const [linkError, setLinkError] = useState(false);
  const [linkErrorMessage, setLinkErrorMessage] = useState(null);
  const [setTurnInOnlineLink] = useState(
    activeAssignment?.assignment_students[0]?.turned_in_online_link || null
  );
  const [turnInOnlineFile] = useState(
    activeAssignment?.assignment_students[0]
      ?.turned_in_online_file_attachment_details || null
  );
  const params = useParams();
  const kidId = params.kid_id;

  // eslint-disable-next-line consistent-return
  const displayGrade = () => {
    if (activeAssignment.assignment_students[0].score === "/") {
      return t("missing");
    }
    if (activeAssignmentGrade !== "-") {
      return activeAssignmentGrade;
    }
  };

  const handleAssignmentFileDownload = async (file) => {
    await fileService.fetchAssignmentFile({
      id: file?.id,
      student_id: kidId,
      filename: file?.name,
    });
  };

  const handleAssignmentTurnInFileDownload = async () => {
    await fileService.fetchTurnedInAssignmentFile({
      id: turnInOnlineFile?.id,
      student_id: kidId,
      filename: turnInOnlineFile?.name,
    });
  };

  return (
    <Container sx={detailedAssignmentContainer}>
      <Box>
        <Button
          onClick={() => {
            setShowDetailedAssignment(false);
          }}
          sx={backButton}
        >
          <ArrowBackRoundedIcon size="small" sx={backArrow} />
          {t("viewAllAssignments")}
        </Button>
      </Box>
      <Card sx={detailedAssignmentCard}>
        <CardContent>
          <Typography sx={assignmentGrade}>{displayGrade()}</Typography>
          <Typography sx={assignmentName}>{activeAssignment.name}</Typography>
          <Typography sx={assignmentDue}>
            {t("due")}:{" "}
            {weekShortDate(activeAssignment.due_date, {
              language: i18n.language,
              timeZone: "UTC",
            })}
          </Typography>
          {activeAssignment.description && (
            <Typography sx={assignmentDesc}>
              {activeAssignment.description}
            </Typography>
          )}

          {activeAssignment.assignment_attachments.length > 0 && (
            <Box sx={assignmentAttachments}>
              {activeAssignment.assignment_attachments.map((attachment) => (
                <Box sx={assignmentAttachment} key={attachment.id}>
                  <InsertDriveFileOutlinedIcon sx={assignmentAttachmentIcon} />
                  <Typography
                    sx={assignmentAttachmentName}
                    onClick={async () => {
                      await handleAssignmentFileDownload(attachment);
                    }}
                  >
                    {attachment.name}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
        </CardContent>
      </Card>

      {activeAssignment.turn_in_online && (
        <Card sx={detailedAssignmentCard}>
          <CardContent>
            <Box sx={turnInBox}>
              <Typography sx={turnInTitle}>{t("turnInAssignment")}</Typography>
              {activeAssignment.turn_in_online_using === "file" ? (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                  {turnInOnlineFile && (
                    <Typography
                      sx={turnInFileName}
                      onClick={async () => {
                        if (turnInOnlineFile.id) {
                          await handleAssignmentTurnInFileDownload();
                        }
                      }}
                    >
                      {turnInOnlineFile.name.substring(0, 40)}
                    </Typography>
                  )}
                </>
              ) : (
                <TextField
                  disabled
                  type="url"
                  error={linkError}
                  sx={turnInLink}
                  required
                  id="turn-in-link"
                  label={t("websiteUrl")}
                  defaultValue={
                    activeAssignment?.assignment_students[0]
                      ?.turned_in_online_link
                  }
                  onChange={(event) => {
                    const targetValue = event.target.value;
                    if (targetValue) {
                      setLinkError(false);
                      setLinkErrorMessage(null);
                    }
                    setTurnInOnlineLink(event.target.value);
                  }}
                  helperText={linkErrorMessage && linkErrorMessage}
                />
              )}
            </Box>

            {activeAssignment?.assignment_students[0]?.turned_in_online_at && (
              <Box>
                <Typography sx={turnedInAt}>
                  {t("turnedIn")}{" "}
                  {shortDateTime(
                    activeAssignment?.assignment_students[0]
                      ?.turned_in_online_at,
                    { language: i18n.language, timeZone: "UTC" }
                  )}
                </Typography>
              </Box>
            )}
          </CardContent>
        </Card>
      )}
    </Container>
  );
}
