import React from "react";
import { InputAdornment, TextField } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useFormContext } from "react-hook-form";
import { cursorPointer } from "./sharedStyles";

function ErasableTextField({
  id,
  label,
  fullWidth,
  hasError,
  isRequired,
  type = "text",
  isMultiline = false,
  defaultValue,
  disabled = false,
  onChange = () => {},
  eraseCallback = () => {},
  inputSx,
  lines,
  pattern = "",
  maxLength = 0,
}) {
  const { register, watch, resetField } = useFormContext();

  const hasMaxLength = maxLength !== 0;

  return (
    <TextField
      multiline={isMultiline}
      rows={isMultiline ? lines || 10 : null}
      id={id}
      label={label}
      fullWidth={fullWidth}
      error={Boolean(hasError)}
      type={type}
      defaultValue={defaultValue}
      disabled={disabled}
      sx={inputSx}
      {...register(id, {
        onChange,
        required: isRequired ? "This field is required." : false,
        pattern: {
          value: pattern,
          message: "Invalid format",
        },
        ...(hasMaxLength && {
          maxLength: {
            value: maxLength,
            message: `Maximum of ${maxLength} characters`,
          },
        }),
      })}
      InputProps={{
        endAdornment: watch(id) ? (
          <InputAdornment position="end">
            <CancelOutlinedIcon
              sx={cursorPointer}
              onClick={() => {
                resetField(id, { defaultValue: "" });
                eraseCallback();
              }}
            />
          </InputAdornment>
        ) : (
          ""
        ),
      }}
    />
  );
}

export default ErasableTextField;
