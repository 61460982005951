import { Button, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Add } from "@mui/icons-material";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { ProcessBuilderContext } from "../ProcessBuilderContext";
import FileDetail from "./FileDetail";
import { generateGUID } from "../../../../../utils/constants/utils";

export default function ProcessFileRequestProperties() {
  const methods = useForm();
  const { handleSubmit, control, reset } = methods;
  const formWatcher = useWatch({
    control,
  });

  const { process, updateProcess, selectedElement } = useContext(
    ProcessBuilderContext
  );

  const [files, setFiles] = useState(selectedElement.properties.files);

  const addFile = () => {
    const updatedFiles = [...files];
    updatedFiles.push({
      id: generateGUID(),
      type: "",
      name: "",
      description: "",
      require: "",
      includeInTimeline: "",
    });
    setFiles(updatedFiles);
  };

  const onSubmit = (d) => {
    const data = d;
    const fileIds = files.map((f) => f.id);
    data.files = data.files.filter((f) => fileIds.includes(f.id));
    data.includeInTimeline = data.files.some((f) => f.includeInTimeline);

    const updatedStages = [...process.stages];
    let stageIndex = null;

    // find the stage index the element was dropped to
    updatedStages.some((stage, index) => {
      const foundElementIndex = stage.elements.findIndex(
        (element) => element.elementId === selectedElement.elementId
      );
      if (foundElementIndex !== -1) {
        stageIndex = index;
        return true; // exit the loop once the element is found
      }
      return false;
    });

    const updateElements = [...updatedStages[stageIndex].elements];
    const elementIndex = updateElements.findIndex(
      (el) => el.elementId === selectedElement.elementId
    );
    updateElements[elementIndex].properties = {
      ...updateElements[elementIndex].properties,
      ...data,
    };
    updatedStages[stageIndex] = {
      ...updatedStages[stageIndex],
      elements: updateElements,
    };

    updateProcess({ ...process, stages: updatedStages });
  };

  useEffect(() => {
    reset(
      {
        files: selectedElement.properties.files,
      },
      { keepTouched: true, keepDirty: true }
    );
    setFiles(selectedElement.properties.files);
  }, [selectedElement]);

  const deleteFileFrom = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  useEffect(() => {
    if (Object.keys(formWatcher).length) onSubmit(formWatcher);
  }, [formWatcher]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          rowGap={4}
          boxShadow="0px 4px 50px 0px rgba(29, 182, 231, 0.12)"
          borderRadius={1}
          padding={2.5}
        >
          <Grid item md={12}>
            <Typography align="center" variant="body1">
              File Request
            </Typography>
          </Grid>
          {files.map((file, index) => (
            <FileDetail
              file={file}
              index={index}
              key={file.id}
              deleteFileFrom={deleteFileFrom}
            />
          ))}
          <Grid item md={12} textAlign="center">
            <Button
              sx={{
                textTransform: "none",
                color: "#2196F3",
                borderColor: "#2196F3",
              }}
              variant="outlined"
              startIcon={<Add />}
              onClick={addFile}
            >
              Add File
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}
