import { Box, TextField } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";
import { renderError } from "../../../../../utils/constants/forms";

export default function TextFieldRenderer({ field, properties, defaultValue }) {
  const {
    register,
    formState: { errors },
    trigger,
  } = useFormContext();

  return (
    <Box padding={1}>
      <TextField
        fullWidth
        error={Boolean(
          errors.custom_field_responses &&
            errors.custom_field_responses[`${field.field_id}`]
        )}
        label={field.label}
        key={`custom_field_responses.${field.field_id}`}
        defaultValue={defaultValue}
        InputLabelProps={{
          required: properties.isRequired,
        }}
        {...register(`custom_field_responses.${field.field_id}`, {
          required: properties.isRequired && "This field is required.",
          maxLength: {
            value: field.properties.maxLength || null,
            message: `${field.label} must not exceed ${field.properties.maxLength} characters`,
          },
          onChange: () => {
            trigger(`custom_field_responses.${field.field_id}`);
          },
        })}
      />
      {errors.custom_field_responses &&
        errors.custom_field_responses[`${field.field_id}`] &&
        renderError(errors.custom_field_responses[`${field.field_id}`].message)}
    </Box>
  );
}
