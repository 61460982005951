const medicalRecordActions = {
  display: "flex",
  justifyContent: "flex-end",
};
const formGrid = {
  py: 5,
  px: 8,
  justifyContent: "space-between",
  alignItems: "flex-start",
};
const medicalRecordGrid = {
  py: 5,
  px: 8,
};
export { medicalRecordActions, formGrid, medicalRecordGrid };
