import React, { useState } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useFormContext } from "react-hook-form";

export default function DateFormats({ fieldProp }) {
  const [selectedOptions, setSelectedOption] = useState(
    fieldProp.properties.dateFormat
  );
  const { register, setValue } = useFormContext();

  const dateFormats = [
    "MM/DD/YYYY",
    "DD/MM/YYYY",
    "YYYY-MM-DD",
    "MMM DD, YYYY",
    "DD MMM YYYY",
    "dddd, MMM DD, YYYY",
    "YYYY/MM/DD",
  ];

  return (
    <Grid container mb={2}>
      <FormControl fullWidth>
        <InputLabel id="typeDropdownId">Date Formats</InputLabel>
        <Select
          labelId="typeDropdownId"
          label="Type"
          value={selectedOptions}
          placeholder="Select"
          defaultValue="MM/DD/YYYY"
          {...register("properties.dateFormat", {
            onChange: (event) => {
              setSelectedOption(event.target.value);
              setValue("properties.dateFormat", event.target.value, {
                shouldDirty: true,
              });
            },
          })}
        >
          {dateFormats.map((dateFormat) => (
            <MenuItem key={dateFormat} value={dateFormat}>
              <Typography textAlign="left">{dateFormat}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
}
