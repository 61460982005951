import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import staffsService from "../../../service/staffsService";
import StaffType from "../../../utils/constants/staffTypeEnum";

export default function HomeroomTeacherSelect({
  schoolId,
  setSelectedTeacherStudent,
  hasAllTeachersOption,
}) {
  const [teachersLoaded, setTeachersLoaded] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const loadTeachers = async () => {
    const response = await staffsService.fetchAllStaffs({
      params: { school_id: schoolId, staff_type: StaffType.TEACHER },
    });
    setTeachers(response);
    setTeachersLoaded(true);
  };

  const handleChange = (teacher) => {
    if (teacher) {
      setSelectedTeacherStudent("all");
      setSearchParams({
        term: searchParams.get("term"),
        homeroomTeacherId: teacher,
      });
    }
  };

  useEffect(() => {
    loadTeachers();
  }, []);

  return (
    <Grid item md={8} pt={6} pl={4}>
      {teachersLoaded && (
        <FormControl fullWidth>
          <InputLabel id="homeroom_teacher_label">Homeroom Teacher</InputLabel>
          <Select
            labelId="homeroom_teacher_label"
            id="homeroom_teacher"
            label="Homeroom Teacher"
            defaultValue={searchParams.get("homeroomTeacherId")}
          >
            <MenuItem disabled value="">
              Homeroom Teacher
            </MenuItem>
            {hasAllTeachersOption && (
              <MenuItem value="all" onClick={() => handleChange("all")}>
                All teachers
              </MenuItem>
            )}
            {teachers.map((option) => (
              <MenuItem
                value={option.id}
                key={option.id}
                onClick={() => handleChange(option.id)}
              >
                {`${option.first_name} ${option.last_name}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Grid>
  );
}
