import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import {
  reportCardDashboardGradeCell,
  reportCardDashboardHeaderTableCell,
  reportCardDashboardTableCell,
} from "./ReportCardDashboard.styles";
import { mt15 } from "../sharedStyles";

export default function ReportCardDashboardTableHeader(props) {
  const { headers } = props;

  return (
    <Grid container sx={{ ...mt15, ml: 5, mb: 1 }} spacing={2}>
      {headers.map((header) => (
        <Grid
          item
          key={header.title}
          sm={header.fullWidth ? true : undefined}
          sx={
            header.fullWidth
              ? reportCardDashboardGradeCell
              : reportCardDashboardTableCell
          }
        >
          {header.fullWidth ? (
            <Box sx={{ width: "200px" }}>
              <Typography sx={reportCardDashboardHeaderTableCell}>
                {header.title}
              </Typography>
            </Box>
          ) : (
            <Typography sx={reportCardDashboardHeaderTableCell}>
              {header.title}
            </Typography>
          )}
        </Grid>
      ))}
    </Grid>
  );
}
