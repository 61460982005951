const backButton = {
  color: "#6750A4",
  fontWeight: 500,
  textTransform: "none",
  "&:hover": {
    backgroundColor: "transparent",
  },
  float: "right",
};

const backArrow = {
  paddingRight: "5px",
};

const detailedAssignmentContainer = {
  px: "200px !important",
  display: "grid",
};

const detailedAssignmentCard = {
  mt: "20px",
  mb: "20px",
  borderRadius: "6px",
  boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.15)",
};

const assignmentName = {
  fontSize: "24px",
  color: "black",
  pt: "30px",
};

const assignmentDue = {
  color: "black",
  pt: "20px",
};

const assignmentDesc = {
  pt: "50px",
  color: "black",
};

const assignmentAttachments = {
  mt: "30px",
  borderTop: "1px solid #BFC7CA",
  pt: "30px",
  display: "flex",
  pb: "10px",
};

const assignmentAttachment = {
  pr: "30px",
  display: "flex",
};

const assignmentAttachmentIcon = {
  color: "#757575",
  mr: "10px",
};

const assignmentAttachmentName = {
  cursor: "pointer",
  fontSize: "16px",
  color: "black",
};

const turnInBox = {
  display: "flex",
};

const turnInTitle = {
  pr: "20px",
  fontSize: "24px",
  pt: "10px",
  color: "black",
};

const turnInLink = {
  width: "500px",
};

const turnInSubmit = {
  mt: "7px",
  fontWeight: 500,
  textTransform: "none",
  height: "40px",
  width: "120px",
  ml: "20px",
  borderRadius: "100px",
  backgroundColor: "#6750A4",
  "&:hover": {
    backgroundColor: "#6750A4",
  },
};

const turnInFile = {
  mt: "7px",
  color: "#6750A4",
  background: "white",
  textTransform: "none",
  borderRadius: "100px",
  border: "1px solid #79747E",
  "& span": {
    color: "black",
  },
  "&:hover": {
    background: "white",
  },
};

const turnedInAt = {
  pt: "20px",
  color: "black",
};

const turnInFileName = {
  cursor: "pointer",
  color: "black",
  pt: "17px",
  pl: "10px",
};

const assignmentGrade = {
  color: "black",
  fontWeight: 700,
  fontSize: "24px",
  mr: "22px",
  float: "right",
};

export {
  backArrow,
  backButton,
  detailedAssignmentContainer,
  detailedAssignmentCard,
  assignmentName,
  assignmentDue,
  assignmentDesc,
  assignmentAttachment,
  assignmentAttachments,
  assignmentAttachmentIcon,
  assignmentAttachmentName,
  turnInBox,
  turnInTitle,
  turnInLink,
  turnInSubmit,
  turnInFile,
  turnedInAt,
  turnInFileName,
  assignmentGrade,
};
