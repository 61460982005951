const notificationsTitle = {
  color: "black",
  fontSize: "34px",

  "&.main": {
    fontSize: "52px",
  },
};

const notificationsTopContainer = {
  borderBottom: "1px solid black",
};

const notificationsSearchContainer = {
  display: "flex",
  alignItems: "end",
};

const notificationsRow = {
  borderBottom: "1px solid #BFC7CA",
  p: "15px",
  color: "black",

  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    cursor: "pointer",
  },

  "& .category": {
    textTransform: "uppercase",
    fontSize: "10px",
  },

  "& .title": {
    fontWeight: "bold",
  },

  "& .timestamp": {
    textAlign: "right",
    fontSize: "12px",
    color: "#336898",

    "& .BaseBadge-root": {
      marginRight: "10px",
    },
  },

  "&.unread": {
    "& .timestamp": {
      fontWeight: "bold",
    },
  },
};

const notificationDetails = {
  "& .category": {
    textTransform: "uppercase",
    fontSize: "12px",
  },
};

const notificationDetailsBackBtn = {
  "& button": {
    textTransform: "inherit",
    fontSize: "15px",
    display: "inline",
    color: "#336898",
    textDecoration: "none",
  },
};

export {
  notificationsTitle,
  notificationsTopContainer,
  notificationsSearchContainer,
  notificationsRow,
  notificationDetails,
  notificationDetailsBackBtn,
};
