import React from "react";
import { TableCell, TableRow, Typography } from "@mui/material";
import TermGradeSkillCell from "./TermGradeSkillCell";
import { termGradeKlassCellPadding } from "./TermGradeKlassRow.styles";
import {
  termGradeKlassCell,
  termGradeKlassCellName,
} from "./TermGradeKlass.styles";
import { termGradeSkillCellDisabled } from "./TermGradeSkillRow.styles";
import GradeLockIcon from "../../GradeLockIcon";
import GradeCheckIcon from "../../GradeCheckIcon";
import GradeAlertIcon from "../../GradeAlertIcon";

export default function TermGradeSkillRow({
  termGrade,
  studentSkills,
  gradingScales,
  studentSkillsByKlassAndStudent,
  skillsByGradingScaleID,
  getStudentSkills,
  reportCards,
}) {
  const klassGradingScale = termGrade.klass.grading_scale.name;
  const studentScore = (skillId) => {
    const studentSkill = studentSkills.find(
      (ss) =>
        ss.student_id === termGrade.student.id &&
        ss.klass_id === termGrade.klass.id &&
        ss.skill_id === skillId
    );

    return studentSkill?.score;
  };

  const isPublished = () => {
    let reportCard = false;
    if (reportCards) {
      reportCard = reportCards[termGrade.student_id];
    }

    return reportCard && reportCard.is_published;
  };

  const renderCells = () =>
    gradingScales.map((gs) =>
      gs.skills.map((skill) =>
        gs.name === klassGradingScale ? (
          <TermGradeSkillCell
            key={skill.id}
            gradingScale={gs}
            skill={skill}
            termGrade={termGrade}
            studentScore={studentScore(skill.id)}
            getStudentSkills={getStudentSkills}
            reportCards={reportCards}
            isPublished={isPublished}
          />
        ) : (
          <TableCell sx={termGradeSkillCellDisabled} key={skill.id}>
            {isPublished() ? <GradeLockIcon /> : null}
          </TableCell>
        )
      )
    );

  const studentSkillsStatus = () => {
    let status = null;

    const studentSkillGradingScale =
      skillsByGradingScaleID[termGrade.klass.grading_scale_id];

    const studentSkill =
      studentSkillsByKlassAndStudent[
        `${termGrade.student.id}-${termGrade.klass.id}`
      ];

    if (studentSkill) {
      if (
        studentSkill.student_skills &&
        studentSkillGradingScale &&
        studentSkill.student_skills.length === studentSkillGradingScale.length
      ) {
        status = true;
        studentSkill.student_skills.forEach((ss) => {
          const updatedAt = new Date(ss.updated_at).getTime();
          const postedAt = new Date(ss.posted_at).getTime();
          if (updatedAt > postedAt) {
            status = false;
          }
        });
      }

      if (
        studentSkill.student_skills &&
        studentSkillGradingScale &&
        studentSkill.student_skills.length !== studentSkillGradingScale.length
      ) {
        status = null;
      }
    }

    return status;
  };

  const displayIcon = studentSkillsStatus();

  return (
    <TableRow>
      <TableCell sx={termGradeKlassCellPadding}>
        {displayIcon !== null && displayIcon ? (
          <GradeCheckIcon isGreen needsContainer={false} />
        ) : null}
        {displayIcon !== null && !displayIcon ? (
          <GradeAlertIcon isGreen={false} isForSkill />
        ) : null}
      </TableCell>
      <TableCell sx={termGradeKlassCellName}>
        {`${termGrade.student.last_name}, ${termGrade.student.first_name}`}
      </TableCell>
      <TableCell sx={termGradeKlassCell} align="center">
        <Typography> {termGrade.klass.abbreviation}</Typography>
      </TableCell>
      <TableCell sx={termGradeKlassCell} align="center">
        <Typography>{termGrade.student.grade_level}</Typography>
      </TableCell>
      {renderCells()}
    </TableRow>
  );
}
