import React, { useState, useEffect } from "react";
import { Outlet, useSearchParams, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Box,
  Typography,
  Container,
  Stack,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import {
  notificationsTitle,
  notificationsTopContainer,
  notificationsSearchContainer,
} from "./Notifications.styles";
import { mb55 } from "../components/sharedStyles";

export default function Notifications() {
  const { t } = useTranslation("notifications");
  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState(searchParams.get("query") || null);
  const { register, handleSubmit, getValues, setValue } = useForm();
  const location = useLocation();

  const [showClearButton, setShowClearButton] = useState(
    searchParams.get("query") || false
  );

  const handleSearch = (data) => {
    searchParams.set("query", data.search.toLowerCase());
    setQuery(data.search.toLowerCase());
    setSearchParams(searchParams);
  };

  const handleQuickFindChange = (event) => {
    if (event.target.value) setShowClearButton(true);
    else setShowClearButton(false);
  };

  const handleClickOnClearButton = () => {
    setValue("search", "");
    setShowClearButton(false);
    handleSearch(getValues());
  };

  const cancelButtonStyle = {
    visibility: showClearButton ? "inherit" : "hidden",
  };

  useEffect(() => {
    setShowClearButton(searchParams.get("query") || false);
    setQuery(searchParams.get("query") || "");
  }, []);

  return (
    <Container maxWidth={false} variant="header">
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={notificationsTopContainer}
      >
        <Typography
          sx={notificationsTitle}
          className={
            location.pathname.includes("/school/") ? "main" : undefined
          }
        >
          {t("notifications")}
        </Typography>

        {!location.pathname.includes("/details") && (
          <Box sx={notificationsSearchContainer}>
            <form
              onSubmit={handleSubmit(handleSearch)}
              onChange={handleQuickFindChange}
            >
              <TextField
                label={t("searchNotifications")}
                variant="standard"
                color="primary"
                defaultValue={searchParams.get("query")}
                {...register("search")}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickOnClearButton}
                        sx={cancelButtonStyle}
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                      <IconButton type="submit">
                        <SearchIcon size="small" color="primary" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </form>
          </Box>
        )}
      </Stack>

      <Box sx={mb55}>
        <Outlet context={{ query }} />
      </Box>
    </Container>
  );
}
