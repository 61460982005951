import React, { useState, useEffect, useContext } from "react";
import { Drawer, Typography, Box, Button, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {
  closeIconStyle,
  changeSchoolsName,
  drawerContainer,
  leftDrawerStyle,
  changeSchoolsTitle,
  changeSchoolsListRow,
  changeSchoolsNameActive,
  changeSchoolsActiveLink,
} from "./LeftDrawer.style";
import { buttonWithStartIcon, mt35 } from "./sharedStyles";
import teachersService from "../service/staffsService";
import axios from "../utils/axios-util";
import SnackBarNotification from "./SnackBarNotification";
import { PermissionsContext } from "../context/PermissionsContext";
import APP_PERMISSIONS from "../utils/constants/permissions";
import PERMISSION_TYPES from "../utils/constants/permission_types";

function LeftDrawer({
  drawerStatus,
  toggleDrawer,
  teacher,
  defaultSchoolSlug,
  schoolId,
}) {
  const [schools, setSchools] = useState([]);
  const [snackBarOpen, setSnackBarOpen] = useState({
    open: false,
    message: null,
  });
  const { hasPermission } = useContext(PermissionsContext);

  const oneRosterPermission = hasPermission(
    APP_PERMISSIONS.ONEROSTER,
    PERMISSION_TYPES.MANAGE
  );

  const OneRosterActions = {
    UPLOAD: 1,
    DOWNLOAD: 2,
  };

  const getSchools = async () => {
    const response = await teachersService.fetchSchools(teacher.id);
    if (response.data) {
      setSchools(response.data.schools);
    }
  };

  const oneRosterService = async (url, _params, method) => {
    try {
      axios({
        url,
        method,
        data: _params,
      }).then(
        async (response) => {
          setSnackBarOpen({
            open: true,
            message: response.data.message,
          });
        },
        async (e) => {
          const errorMessage = JSON.parse(
            await e.response?.data?.text()
          )?.message;
          console.log(errorMessage);
        }
      );
    } catch (e) {
      console.log(e);
    }
  };

  const handleOneRoster = async (action) => {
    const oneRosterEndpoint = "/api/reports/";
    let url = "";
    if (action === OneRosterActions.UPLOAD) {
      url = `${oneRosterEndpoint}/upload_one_roster`;
    } else {
      url = `${oneRosterEndpoint}/download_one_roster`;
    }
    try {
      await oneRosterService(url, { school_id: schoolId }, "POST");
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getSchools();
  }, []);

  return (
    <Box>
      <Drawer
        open={drawerStatus}
        sx={leftDrawerStyle}
        ModalProps={{ onBackdropClick: toggleDrawer(false) }}
      >
        <CloseIcon onClick={toggleDrawer(false)} sx={closeIconStyle} />
        <Box sx={drawerContainer}>
          <Typography sx={changeSchoolsTitle}>Change Schools</Typography>

          <Box sx={mt35}>
            {schools.map((school) => {
              const activeSchool = schoolId === school.slug;

              return (
                <Box sx={changeSchoolsListRow} key={school.name}>
                  <Box
                    onClick={() =>
                      window.location.replace(
                        `/school/${school.slug}/entry-screen`
                      )
                    }
                    sx={activeSchool ? changeSchoolsActiveLink : {}}
                  >
                    <Typography
                      sx={
                        activeSchool
                          ? changeSchoolsNameActive
                          : changeSchoolsName
                      }
                    >
                      {`${school.name} ${
                        defaultSchoolSlug === school.slug ? "(Default)" : ""
                      }`}
                    </Typography>
                  </Box>
                  {oneRosterPermission &&
                    school.one_roster_export &&
                    activeSchool && (
                      <Box>
                        <Button
                          variant="text"
                          startIcon={
                            <Tooltip title="Upload OneRoster files" arrow>
                              <UploadFileIcon />
                            </Tooltip>
                          }
                          sx={{
                            ...buttonWithStartIcon,
                            justifyContent: "flex-end",
                          }}
                          onClick={() =>
                            handleOneRoster(OneRosterActions.UPLOAD)
                          }
                        />
                        <Button
                          variant="text"
                          startIcon={
                            <Tooltip title="Download OneRoster files" arrow>
                              <DownloadIcon size="small" />
                            </Tooltip>
                          }
                          sx={buttonWithStartIcon}
                          onClick={() =>
                            handleOneRoster(OneRosterActions.DOWNLOAD)
                          }
                        />
                      </Box>
                    )}
                </Box>
              );
            })}
          </Box>
        </Box>
      </Drawer>

      <SnackBarNotification
        message={snackBarOpen.message}
        open={snackBarOpen.open}
        severity={snackBarOpen.severity}
        handleClose={() => setSnackBarOpen(false)}
      />
    </Box>
  );
}

export default LeftDrawer;
