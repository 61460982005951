const messagesBox = {
  fontSize: "28px",
  lineHeight: "36px",
  letterSpacing: "-0.05em",
  pb: "10px",
};

const messagesCard = {
  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
  borderRadius: "6px",
  maxHeight: 600,
  minHeight: 600,
  overflowY: "auto",
};

const tableBodyImage = { width: "14%", borderBottom: "none" };

const tableBodyContent = {
  borderBottom: "none",
  verticalAlign: "baseline",
  color: "#000000",
};

const tableBodyContentName = {
  fontWeight: 700,
  fontSize: "16px",
  letterSpacing: "-0.01em",
};

const tableBodyContentDesc = {
  fontSize: "12px",
  letterSpacing: "-0.25px",
};

const tableBodyContentTime = {
  fontSize: "11px",
  color: "#336898",
  letterSpacing: "-0.01em",
};

export {
  messagesBox,
  messagesCard,
  tableBodyImage,
  tableBodyContent,
  tableBodyContentName,
  tableBodyContentDesc,
  tableBodyContentTime,
};
