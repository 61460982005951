const eventTableBodyCell = (isPinned) =>
  isPinned
    ? { borderBottom: "none", paddingBottom: "1.5rem" }
    : { borderBottom: "none" };
const medicalCell = {
  fontWeight: "bold",
  color: "black",
  py: 0,
};

const paddedMedicalCellHeader = {
  color: "black",
  width: "5%",
  fontSize: "1.2rem",
};

const paddedMedicalCell = {
  paddingX: "1.5rem",
  color: "black",
  width: "70%",
};

const paddedMedicalCellDate = {
  color: "black",
  pr: "13px",
};
const titleStyle = {
  fontSize: "28px",
  color: "black",
};
const authorNameCell = {
  textAlign: "left",
  width: "12.5%",
  fontSize: "16px",
};
const authorNameHead = {
  fontSize: "14px",
  textTransform: "uppercase",
  fontWeight: "400",
};
const eventTablePinnedRow = { borderBottom: "none", p: 0 };
const eventTableStrike = {
  width: "100%",
  textAlign: "left",
  borderBottom: "1px solid #000",
  lineHeight: " 0.1em",
  margin: "10px 0 20px",
};
const eventTablePinned = {
  fontSize: 11,
  fontWeight: 500,
  background: "#fff",
  padding: "0 5px",
};
const pinnedRow = (cursor) => ({
  ...(cursor && { cursor: "pointer" }),
  borderBottom: "1px solid black",
});

export {
  medicalCell,
  paddedMedicalCellHeader,
  paddedMedicalCell,
  paddedMedicalCellDate,
  titleStyle,
  authorNameCell,
  authorNameHead,
  eventTablePinnedRow,
  eventTableStrike,
  eventTablePinned,
  eventTableBodyCell,
  pinnedRow,
};
