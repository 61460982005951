import { tableCellClasses, tableRowClasses } from "@mui/material";

const tableStyles = {
  [`& .${tableCellClasses.root}`]: {
    borderBottom: "none",
  },
  [`& .${tableRowClasses.root}:last-child .${tableCellClasses.root}`]: {
    borderBottom: "1px solid #d9d9d9",
  },
  "& .MuiTableCell-body": {
    color: "black",
    fontSize: 13,
  },
  "& .MuiTableCell-root:first-child": {
    paddingLeft: 0,
  },
  "& .MuiTableCell-root:nth-child(2), .MuiTableCell-root:nth-child(3)": {
    textTransform: "uppercase",
    "& .MuiTypography-root": {
      fontSize: 13,
    },
  },
  "& .MuiTableCell-root:nth-child(1)": {
    width: "20%",
  },
  "& .MuiTableCell-root:nth-child(2)": {
    width: "20%",
  },
  "& .MuiTableCell-root:nth-child(3)": {
    width: "40%",
  },
  "& .MuiTableCell-root:nth-child(4)": {
    width: "20%",
    whiteSpace: "nowrap",
  },
  "& .MuiTableRow-root:first-child .MuiTableCell-root": {
    paddingTop: 1,
  },
};
const primaryIcon = {
  ml: "25px",
  fontSize: "20px",
};
const guardianDrawer = {
  "& .MuiPaper-root": {
    backgroundColor: "white",
    width: "800px",
  },
};
const email = {
  textTransform: "lowercase",
  fontSize: 13,
};
export { tableStyles, primaryIcon, guardianDrawer, email };
