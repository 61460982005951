const recipientsContainer = {
  borderBottom: "1px solid #BFC7CA",
  mb: "10px",
};

const recipientsTitle = {
  fontSize: "18px",
};

const autocompleteLabel = {
  mr: "15px",
};

export { recipientsContainer, recipientsTitle, autocompleteLabel };
