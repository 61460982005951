import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import {
  useParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom"; // Make sure to import useLocation
import { useTranslation } from "react-i18next";
import GuardianKidsInfoHomeSchedule from "./Schedule";
import GuardianKidsInfoHomeMessages from "./Messages";
import GuardianKidsInfoHomeGrades from "./Grades";
import { fetchGuardianKidInfo } from "../../../../service/guardiansService";
import {
  gridContainer,
  gridItemSchoolInfo,
  displayInline,
  gridItemTeacherInfo,
  gridItemTeacherInfoLabel,
  gridItemTeacherInfoPrincipalLabel,
} from "./Home.styles";
import { pl10, pl20, pt30 } from "../../../sharedStyles";
import EnrollmentChecklist from "../../EnrollmentChecklist/EnrollmentChecklist";

export default function GuardianKidsInfoHome() {
  const { t } = useTranslation(["guardian", "common"]);
  const params = useParams();
  const location = useLocation();
  const guardianId = params.id;
  const kidId = params.kid_id;
  const [kidInfo, setKidInfo] = useState(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const formattedParams = () => {
    let paramsUrl = "?";
    const processId = searchParams.get("process_id");
    if (processId) {
      paramsUrl = paramsUrl.concat(`process_id=${processId}`);
    }

    return paramsUrl;
  };

  useEffect(() => {
    (async () => {
      if (guardianId && kidId) {
        try {
          const response = await fetchGuardianKidInfo(guardianId, kidId);
          setKidInfo(response.data);
        } catch (e) {
          // handle catch
        }
      }
    })();
  }, [guardianId, kidId]);

  useEffect(() => {
    if (kidInfo && !kidInfo?.has_enrollment) {
      navigate(
        `/guardian/${guardianId}/students/${kidId}/home/enrollments/checklist${formattedParams()}`
      );
    }
  }, [kidInfo]);

  const displayGrade = (grade) => {
    switch (grade) {
      case "TK":
        return t("transitionalKindergarten", {
          ns: "common",
          keyPrefix: "grades",
        });
      case "K":
        return t("kindergarten", { ns: "common", keyPrefix: "grades" });
      case "PK":
        return t("preKindergarten", { ns: "common", keyPrefix: "grades" });
      default:
        return t("grade", {
          count: Number(grade),
          ordinal: true,
          ns: "common",
          keyPrefix: "grades",
        });
    }
  };

  const isStudentRoute = location.pathname.endsWith("/home");

  if (isStudentRoute && kidInfo && kidInfo?.has_enrollment) {
    return (
      <Grid container sx={gridContainer}>
        <Grid item md={6} xs={6} sx={gridItemSchoolInfo}>
          <Box sx={displayInline}>{kidInfo.school?.name}</Box>
          {kidInfo.grade_level && (
            <Box sx={[displayInline, pl20]}>
              {displayGrade(kidInfo.grade_level)}
            </Box>
          )}
        </Grid>
        <Grid item md={6} xs={6} sx={gridItemTeacherInfo}>
          {kidInfo.teacher && (
            <>
              <Box sx={displayInline}>
                {kidInfo.teacher.first_name} {kidInfo.teacher.last_name}
              </Box>
              <Box sx={gridItemTeacherInfoLabel}>
                {t("homeroomTeacher", { keyPrefix: "home" })}
              </Box>
            </>
          )}
          {kidInfo.counselor_name && (
            <>
              <Box sx={displayInline}>{kidInfo.counselor_name}</Box>
              <Box sx={gridItemTeacherInfoLabel}>
                {t("counselor", { keyPrefix: "home" })}
              </Box>
            </>
          )}
          {kidInfo.school?.principal && (
            <>
              <Box sx={displayInline}>{kidInfo.school?.principal}</Box>
              <Box sx={gridItemTeacherInfoPrincipalLabel}>
                {t("principal", { keyPrefix: "home" })}
              </Box>
            </>
          )}
        </Grid>

        <Grid container sx={[pt30, pl10]}>
          <Grid item md={4} xs={4}>
            <GuardianKidsInfoHomeSchedule />
          </Grid>

          <Grid item md={0.5} xs={0.5} />

          <Grid item md={7.5} xs={7.5}>
            <GuardianKidsInfoHomeMessages />
          </Grid>
        </Grid>

        <Grid container sx={[pt30, pl10]}>
          <Grid item md={12}>
            <GuardianKidsInfoHomeGrades />
          </Grid>
        </Grid>
      </Grid>
    );
  }

  const isChecklistRoute = location.pathname.includes(
    "/home/enrollments/checklist"
  );

  const isDocumentsRoute = location.pathname.includes(
    "/home/enrollments/documents"
  );

  // Render the Checklist directly if the route matches, else proceed with the usual logic
  if (isChecklistRoute && kidInfo && kidInfo?.has_process && guardianId) {
    return <EnrollmentChecklist student={kidInfo} guardianId={guardianId} />;
  }
  if (isDocumentsRoute && kidInfo && kidInfo?.has_process && guardianId) {
    return <EnrollmentChecklist student={kidInfo} guardianId={guardianId} />;
  }
}
