const assignmentFormDivider = {
  borderBottom: "1px solid #BFC7CA",
  pb: "25px",
};

const assignmentFormName = {
  width: "533px",
};

const assignmentFormDescription = {
  width: "720px",
};

const assignmentsTextArea = {
  font: "inherit",
};

const displayInPortalSwitch = {
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#1976d2",
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#1976d2",
  },
};

export {
  assignmentFormDescription,
  assignmentFormDivider,
  assignmentFormName,
  assignmentsTextArea,
  displayInPortalSwitch,
};
