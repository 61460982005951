import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { gradesTableStyles } from "./StudentProgress.style";
import formattedGrade from "../../../utils/formattedGrade";

export default function StudentGrades({ klasses, specialMarks }) {
  const termGradeLetter = (grade, klass) => {
    if (grade === null) {
      return "";
    }

    if (klass.is_pass_fail) {
      return grade >
        Number(
          klass?.marking_codes?.find((markingCode) => markingCode.code === "P")
            ?.value
        )
        ? "P"
        : "F";
    }

    const letterGrade = formattedGrade(
      grade,
      100,
      klass?.marking_codes,
      specialMarks
    );
    return letterGrade;
  };

  return (
    <TableContainer>
      <Table sx={gradesTableStyles} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">ClASS</TableCell>
            <TableCell align="left">%</TableCell>
            <TableCell align="center">GRADE</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {klasses.map((klass) => (
            <TableRow>
              <TableCell align="left" scope="student">
                <Typography
                  sx={{ fontWeight: 700, fontSize: "11px !important" }}
                >
                  {" "}
                  {klass.name}{" "}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography sx={{ fontSize: "11px !important" }}>
                  {klass.percentage}{" "}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  sx={{ fontWeight: 700, fontSize: "11px !important" }}
                >
                  {specialMarks && termGradeLetter(klass.percentage, klass)}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
