import axios from "../utils/axios-util";

const fetchGradeLevels = async () => axios.get("/api/lookups/grade_levels");
const fetchGenders = async () => axios.get("/api/lookups/genders");
const fetchLanguages = async () => axios.get("/api/lookups/languages");
const fetchEthnicities = async () => axios.get("/api/lookups/ethnicities");
const fetchSchoolEthnicities = async (params) =>
  axios.get("/api/lookups/school_ethnicities", params);
const fetchDenomination = async () => axios.get("/api/lookups/denominations");
const fetchAdventistOrganizations = async () =>
  axios.get("/api/active_organization/organization_lookups");
const fetchECPPrograms = async () =>
  axios.get("/api/active_organization/ecp_lookups");
const fetchFinancialHolds = async () =>
  axios.get("/api/lookups/financial_holds");
const fetchStudentStatuses = async () =>
  axios.get("/api/lookups/student_statuses");
const fetchStudentNextYearStatuses = async () =>
  axios.get("/api/lookups/student_next_year_statuses");
const fetchMedicalRecordsCategories = async () =>
  axios.get("/api/lookups/medical_record_categories");
const fetchProofsOfbirth = async () =>
  axios.get("/api/lookups/proofs_of_birth");
const fetchGuardians = async () => axios.get("/api/lookups/guardians");
const fetchNameTitles = async () => axios.get("/api/lookups/name_titles");
const fetchDegrees = async () => axios.get("/api/lookups/degrees");
const fetchFteClassifications = async () =>
  axios.get("/api/lookups/fte_classifications");
const fetchStaffRelationships = async () =>
  axios.get("/api/lookups/staff_relationships");
const fetchTeacherTitles = async () => axios.get("/api/lookups/teacher_titles");
const fetchSuffixes = async () => axios.get("/api/lookups/suffixes");
const fetchApplicationStatuses = async () =>
  axios.get("/api/lookups/application_statuses");
const fetchFileTypes = async () => axios.get("/api/lookups/file_types");

export default {
  fetchLanguages,
  fetchGradeLevels,
  fetchGenders,
  fetchEthnicities,
  fetchDenomination,
  fetchAdventistOrganizations,
  fetchECPPrograms,
  fetchFinancialHolds,
  fetchStudentStatuses,
  fetchStudentNextYearStatuses,
  fetchMedicalRecordsCategories,
  fetchProofsOfbirth,
  fetchGuardians,
  fetchNameTitles,
  fetchDegrees,
  fetchFteClassifications,
  fetchStaffRelationships,
  fetchTeacherTitles,
  fetchSuffixes,
  fetchApplicationStatuses,
  fetchSchoolEthnicities,
  fetchFileTypes,
};
