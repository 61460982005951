import React, { useState, useEffect } from "react";
import { Interweave } from "interweave";
import { UrlMatcher } from "interweave-autolink";
import { useOutletContext, useParams } from "react-router-dom";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Box, Typography, Stack, IconButton, Avatar } from "@mui/material";
import {
  conversationSenderBox,
  conversationAttachIcon,
  conversationBoxInner,
  conversationDeleteButton,
  conversationRecipientBox,
  conversationSenderMessageContent,
  conversationSenderTimeStamp,
  conversationSenderFileContainer,
  conversationSenderFileContainerBorderless,
} from "./Conversation.styles";
import conversationAttachment from "./ConversationBox.styles";
import dateTimeFormats from "../../../utils/constants/dateTimeFormats";
import moment from "../../../utils/constants/momentConfig";
import AlertDialog from "../../AlertDialog";
import messagesService from "../../../service/messagesService";
import messageFileDownload from "../../../utils/messageFileDownload";
import { tableAvatar } from "../../sharedStyles";

export default function ConversationBox({ message, handleDelete }) {
  const { teacher } = useOutletContext();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const isSender =
    message.sender_type === "Staff" && message.sender_id === teacher.id;
  const params = useParams();
  const conversationKey = params.conversation_key;

  const getAttachments = async () => {
    const response = await messagesService.fetchMessageAttachment(message.id);

    if (response) {
      setAttachments(response);
    }
  };

  useEffect(() => {
    getAttachments();
  }, []);

  const renderMessageContent = () => (
    <>
      <Stack direction="row" spacing={1} justifyContent="space-between">
        <Typography>{`${message.sender.first_name} ${message.sender.last_name}`}</Typography>
        {teacher && (
          <IconButton
            aria-label="delete"
            sx={conversationDeleteButton}
            onClick={() => setDialogOpen(true)}
          >
            <DeleteOutlineIcon />
          </IconButton>
        )}
      </Stack>

      <Box sx={conversationSenderMessageContent}>
        <Interweave
          newWindow
          matchers={[new UrlMatcher("url")]}
          content={message.description}
        />

        <Typography sx={conversationSenderTimeStamp}>
          {moment(message.created_at).format(dateTimeFormats.LLL)}
        </Typography>
      </Box>
    </>
  );

  const attachmentsContainer = message.has_attachments
    ? conversationSenderFileContainer
    : conversationSenderFileContainerBorderless;

  function handleProfilePictureSrc() {
    if (message?.sender?.image_url) {
      return (
        <img
          alt="profile_image"
          src={message?.sender?.image_url.url}
          style={{
            borderRadius: "100px",
            width: "56px",
            height: "56px",
            objectFit: "cover",
          }}
        />
      );
    }
    return (
      <Avatar sx={{ ...tableAvatar, width: 56, height: 56 }}>
        {message.sender.first_name.charAt(0).toUpperCase()}
      </Avatar>
    );
  }

  if (!isSender) {
    return (
      <>
        <Stack direction="row" alignItems="center">
          {handleProfilePictureSrc()}
          <Box sx={conversationRecipientBox}>
            <Box sx={conversationBoxInner}>
              {renderMessageContent()}

              <Stack
                sx={attachmentsContainer}
                direction="row"
                alignItems="center"
                justifyContent="start"
              >
                {message.has_attachments && (
                  <AttachFileIcon sx={conversationAttachIcon} />
                )}
                <Box>
                  {attachments?.map((attachment) => (
                    <Box
                      sx={conversationAttachment}
                      key={attachment.id}
                      onClick={async () => {
                        await messageFileDownload(attachment, conversationKey);
                      }}
                    >
                      {attachment.filename}
                    </Box>
                  ))}
                </Box>
              </Stack>
            </Box>
          </Box>
        </Stack>

        <AlertDialog
          isOpen={isDialogOpen}
          handleClose={() => setDialogOpen(false)}
          handleConfirm={() => handleDelete(message.id)}
        />
      </>
    );
  }

  return (
    <>
      <Box sx={conversationSenderBox}>
        <Box sx={conversationBoxInner}>
          {renderMessageContent()}

          <Stack
            sx={attachmentsContainer}
            direction="row"
            alignItems="center"
            justifyContent="start"
          >
            {message.has_attachments && (
              <AttachFileIcon sx={conversationAttachIcon} />
            )}
            <Box>
              {attachments?.map((attachment) => (
                <Box
                  sx={conversationAttachment}
                  key={attachment.id}
                  onClick={async () => {
                    await messageFileDownload(attachment, conversationKey);
                  }}
                >
                  {attachment.filename}
                </Box>
              ))}
            </Box>
          </Stack>
        </Box>
      </Box>

      <AlertDialog
        isOpen={isDialogOpen}
        handleClose={() => setDialogOpen(false)}
        handleConfirm={() => handleDelete(message.id)}
      />
    </>
  );
}
