import { StepConnector, styled } from "@mui/material";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  "& .MuiStepConnector-line": {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
  },
}));

export default QontoConnector;
