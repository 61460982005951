export default class Properties {
  constructor(_properties) {
    const properties = _properties || {};
    this.name = properties.name || "";
    this.supportingText = properties.supportingText || "";
    this.isRequired = properties.isRequired || false;
    this.fieldWidth = properties.fieldWidth || 4;

    this.validityCondition = properties.validityCondition || [];
    this.visibilityCondition = properties.visibilityCondition || [];

    this.validityDependents = properties.validityDependents || [];
    this.visibilityDependents = properties.visibilityDependents || [];

    this.validityDependee = properties.validityDependee || [];
    this.visibilityDependee = properties.visibilityDependee || [];

    this.validityConditionJS = properties.validityConditionJS || "";
    this.visibilityConditionJS = properties.visibilityConditionJS || "";

    this.validityMessage = properties.validityMessage || "";

    this.fieldWidthOptions = [
      1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5,
      10, 10.5, 11, 11.5, 12,
    ];
    this.dbRequiredField = properties.dbRequiredField || false;
  }
}
