import React, { useState, useEffect, useContext } from "react";
import { Grid, Typography } from "@mui/material";
import EmergencyContactTable from "./EmergencyContactTable";
import { textCenter } from "../../../../sharedStyles";
import staffEmergencyContactsService from "../../../../../service/staffEmergencyContactsService";
import { QuickBarContext } from "../../../../../context/QuickBarContext";
import { StaffActionsContext } from "../../../../../context/StaffActionsContext";

function EmergencyContacts({ teacher, managePermission }) {
  const staffActionsContext = useContext(StaffActionsContext);
  const quickBarContext = useContext(QuickBarContext);
  const [eContactDrawer, setEcontactDrawer] = useState(false);
  const [emergencyContacts, setEmergencyContacts] = useState([]);

  const fetchAllContacts = async () => {
    const response = await staffEmergencyContactsService.fetchAllContacts(
      teacher.id
    );
    if (response.data) {
      setEmergencyContacts(response.data.emergency_contacts);
    }
  };

  const handleEcontactDrawer = (eContactDrawerOpen) => {
    setEcontactDrawer(eContactDrawerOpen);
  };

  const getContact = async (currentContactId) => {
    const response = await staffEmergencyContactsService.getEmergencyContact(
      teacher.id,
      currentContactId
    );

    if (response.data) {
      staffActionsContext.openEmergencyContactDrawer(
        response.data,
        fetchAllContacts
      );
    }
  };

  const handleTableClick = (id) => {
    getContact(id);
  };

  useEffect(() => {
    if (staffActionsContext.econtactUpdated) {
      fetchAllContacts();
      staffActionsContext.cleanEContactUpdated();
    }
  }, [staffActionsContext.econtactUpdated]);

  useEffect(() => {
    (async () => {
      fetchAllContacts();
    })();
  }, [eContactDrawer]);

  useEffect(() => {
    (async () => {
      if (quickBarContext.addEmergencyContacts) {
        handleEcontactDrawer(true);
        quickBarContext.hideAddEmergencyContacts();
      }
    })();
  }, [quickBarContext.addEmergencyContacts]);

  return (
    <Grid
      container
      item
      justifyContent="space-around"
      alignItems="baseline"
      rowSpacing={3}
      sm={11}
      md={12}
    >
      <Grid container item sm={12} justifyContent="space-between">
        <Typography variant="profileTitle">Emergency Contacts</Typography>
      </Grid>

      <Grid item sm={12}>
        {emergencyContacts.length > 0 ? (
          <EmergencyContactTable
            emergencyContacts={emergencyContacts}
            handleTableClick={handleTableClick}
            managePermission={managePermission}
          />
        ) : (
          <Typography sx={textCenter}>No emergency contacts found.</Typography>
        )}
      </Grid>
    </Grid>
  );
}

export default EmergencyContacts;
