import theme from "../../utils";

const drawerPopover = {
  "& .MuiPopover-paper": {
    maxHeight: "80vh",
    width: "100vw",
    maxWidth: "100vw",
    backgroundColor: theme.topNavBar.secondary.backgroundColor,
    boxShadow: "none",
    left: "0 !important",
  },
};

const drawerAddIcon = {
  backgroundColor: theme.palette.background.default,
  color: theme.palette.primary.main,
  borderRadius: "100%",
  fontSize: 40,
  verticalAlign: "middle",
};

const drawerButton = {
  fontWeight: "light",
  whiteSpace: "pre-wrap",
  fontSize: "14px",
  textTransform: "none",
};

const drawerCardsContainer = {
  height: "fit-content",
  overflowY: "auto",
  maxHeight: "33vh",
  scrollbarWidth: "none",
  msOverflowStyle: "none",
  "&::-webkit-scrollbar": {
    width: 0,
  },
};

const drawerBorderGrid = {
  borderRight: `solid 1px ${theme.palette.text.primary}`,
};

const drawerMessagesContainer = {
  borderLeft: `solid 1px ${theme.palette.text.primary}`,
  overflowY: "auto",
  maxHeight: "33vh",
  scrollbarWidth: "none",
  msOverflowStyle: "none",
  "&::-webkit-scrollbar": {
    width: 0,
  },
  "& .MuiPaper-root": {
    width: "calc(100% - 16px)",
    maxWidth: "calc(100% - 16px)",
  },
};

const drawerNavigationContainer = {
  width: `calc(100vw - ${window.innerWidth - document.body.clientWidth}px)`,
  maxWidth: `calc(100vw - ${window.innerWidth - document.body.clientWidth}px)`,
};

const drawerNavigationListContainer = {
  justifyContent: "center",
  backgroundColor: theme.palette.primary.main,
  color: "white",
};

export {
  drawerAddIcon,
  drawerButton,
  drawerBorderGrid,
  drawerNavigationListContainer,
  drawerPopover,
  drawerNavigationContainer,
  drawerMessagesContainer,
  drawerCardsContainer,
};
