import axios from "../utils/axios-util";

const endpoint = "/api/departments";

const bulkUpdate = async (params) => {
  const { data } = await axios.put(endpoint, params);

  if (!data.data) {
    console.error("Error updating department");
    return [];
  }

  return data;
};

const bulkCreate = async (params) => {
  const { data } = await axios.post(endpoint, params);

  if (!data.data) {
    console.error("Error updating department");
    return [];
  }

  return data;
};

const fetchAll = async () => {
  const { data } = await axios.get(endpoint);

  if (!data.data) {
    console.error("Error fetching departments");
    return [];
  }

  return data;
};

const bulkDelete = async (params) => {
  const { data } = await axios.delete(endpoint, params);

  if (!data.data) {
    console.error("Error deleting collection from the API");
    return [];
  }

  return data;
};

export default {
  bulkUpdate,
  fetchAll,
  bulkCreate,
  bulkDelete,
};
