import React, { useState, useEffect } from "react";
import { useOutletContext, useParams, useSearchParams } from "react-router-dom";
import { Container, Box, Pagination, Typography } from "@mui/material";
import InboxRow from "./InboxRow";
import messagesService from "../../service/messagesService";
import { paginationContent, textCenter } from "../sharedStyles";

export default function Inbox() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { query, messageDrawerStatus } = useOutletContext();
  const [inboxMessages, setInboxMessages] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [count, setCount] = useState(10);
  const params = useParams();
  const schoolId = params.school_id;

  const getInboxMessages = async () => {
    const response = await messagesService.listSentMessages({
      params: {
        query,
        count,
        page,
        type: "inbox",
        school_id: schoolId,
      },
    });
    if (response) {
      const queryPage = searchParams.get("page") || 1;
      const lastPage = response.pagination.total_pages;
      const currentPage = queryPage > lastPage ? lastPage : queryPage;
      const countPerPage = searchParams.get("count") || 10;
      setTotalPages(lastPage);
      setPage(currentPage || 1);
      setCount(countPerPage);
      setInboxMessages(response.data);
    }
  };

  const handleChangePage = (event, value) => {
    setPage(value);
    searchParams.set("page", value);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (page > 0 && query != null) {
      getInboxMessages();
    }
  }, [page, query, messageDrawerStatus]);

  return (
    <Container maxWidth={false} variant="header">
      <Container maxWidth="xl">
        {inboxMessages.length > 0 ? (
          <>
            <Box>
              {inboxMessages.map((message) => (
                <InboxRow message={message} key={message.id} />
              ))}
            </Box>

            <Pagination
              count={totalPages}
              page={parseInt(page, 10)}
              onChange={handleChangePage}
              sx={paginationContent}
            />
          </>
        ) : (
          <Box sx={textCenter}>
            <Typography>No messages</Typography>
          </Box>
        )}
      </Container>
    </Container>
  );
}
