import {
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
  Divider,
} from "@mui/material";
import React, { useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { font22, formButtons, formHeader, formTitle } from "../../sharedStyles";
import { buttons, form } from "./DayTemplatesForm.styles";
import { requiredMessage, renderError } from "../../../utils/constants/forms";
import dayTemplateService from "../../../service/dayTemplateService";
import Tracks from "./Tracks";
import dateTimeFormats from "../../../utils/constants/dateTimeFormats";
import GenericSubmitButton from "../../GenericSubmitButton";

export default function DayTemplatesForm({
  handleDayTemplateDrawer,
  dayTemplate,
  schoolId,
  setSnackBarOpen,
}) {
  const formMethods = useForm({
    defaultValues: {
      tracks: dayTemplate?.tracks
        ? dayTemplate?.tracks.map((track) => ({
            name: track.name,
            periods: track.periods,
          }))
        : [{ name: null }],
    },
  });
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = formMethods;
  const [fieldError, setFieldError] = useState([]);
  const { fields, append, remove } = useFieldArray({
    control,
    name: "tracks",
  });
  const [searchParams] = useSearchParams();
  const schoolYearId = searchParams.get("school_year");

  const onSubmit = async (formData) => {
    const periods = [];
    formData.tracks.forEach((track) => {
      track.periods.forEach((_period) => {
        const period = _period;
        period.track = track.name;
        period.start_time = moment(period.start_time).format(
          dateTimeFormats.LTS
        );
        period.end_time = moment(period.end_time).format(dateTimeFormats.LTS);
        periods.push(period);
      });
    });
    const dayTemplateFormatted = {
      name: formData.name,
      abbreviation: formData.abbreviation,
      periods,
    };
    if (dayTemplate) {
      const updatedDayTemplate = { ...dayTemplate, ...dayTemplateFormatted };
      try {
        const response = await dayTemplateService.update({
          ...updatedDayTemplate,
          school_id: schoolId,
        });

        if (response.data) {
          setSnackBarOpen({
            open: true,
            message: "Day Template updated.",
          });
          handleDayTemplateDrawer(false);
        }
      } catch (error) {
        setFieldError(error.response.data.errors);
      }
    } else {
      try {
        const response = await dayTemplateService.create({
          ...dayTemplateFormatted,
          school_id: schoolId,
          school_year_id: Number(schoolYearId),
        });

        if (response.data) {
          setSnackBarOpen({
            open: true,
            message: "Day Template created.",
          });
          handleDayTemplateDrawer(false);
        }
      } catch (error) {
        setFieldError(error.response.data.errors);
      }
    }
  };

  const deleteDayTemplate = async () => {
    try {
      const response = await dayTemplateService.deleteDayTemplate(
        dayTemplate.id
      );

      if (response.data) {
        setSnackBarOpen({
          open: true,
          message: "Day Template deleted.",
        });
        handleDayTemplateDrawer(false);
      }
    } catch (error) {
      if (error.response.data.errors.length) {
        setFieldError(error.response.data.errors);
      } else {
        setSnackBarOpen({
          open: true,
          message: error.response.data.message,
          severity: "error",
        });
      }
    }
  };

  const displayError = (formField) => {
    const errorInModelValidation = fieldError?.model === "day_template";
    if (errors?.name) {
      return renderError(errors?.name?.message || requiredMessage);
    }
    if (errorInModelValidation) {
      return renderError(fieldError?.errors?.[formField]);
    }
    return "";
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="row" sx={formHeader}>
          <Typography sx={formTitle}>
            {dayTemplate ? "Edit Day Template" : "New Day Template"}
          </Typography>
          <Stack direction="row">
            <Button
              sx={formButtons}
              onClick={() => handleDayTemplateDrawer(false)}
            >
              Cancel
            </Button>
            {dayTemplate && (
              <GenericSubmitButton
                text="Delete"
                submittingText="Deleting..."
                type="button"
                onClick={() => deleteDayTemplate()}
              />
            )}
            <GenericSubmitButton
              text="Save and exit"
              submittingText="Saving..."
            />
          </Stack>
        </Stack>

        <Box sx={form}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="Name*"
                {...register("name", {
                  required: requiredMessage,
                })}
                error={!!errors?.name}
                fullWidth
                autoComplete="off"
                defaultValue={dayTemplate?.name}
              />
              {displayError("name")}
            </Grid>
            <Grid item xs={4}>
              <TextField
                error={!!errors?.abbreviation}
                label="Abbreviation*"
                {...register("abbreviation", {
                  required: requiredMessage,
                })}
                autoComplete="off"
                defaultValue={dayTemplate?.abbreviation}
              />
              {displayError("abbreviation")}
            </Grid>
          </Grid>
          <Grid container spacing={2} my={2}>
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography sx={font22}>Tracks</Typography>
              </Grid>
              <Grid item>
                <Button sx={buttons} onClick={() => append({ name: "" })}>
                  Add new track
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Box>
            <Divider />
          </Box>
          {fields.map((field, index) => (
            <Tracks
              key={field.id}
              index={index}
              value={field}
              remove={remove}
              errors={errors}
              validationError={fieldError}
            />
          ))}
        </Box>
      </form>
    </FormProvider>
  );
}
