import axios from "../utils/axios-util";

const schoolDayEndpoint = "/api/school_days";

const byMonth = async (params) => {
  const { data } = await axios.get(`${schoolDayEndpoint}/by_month`, params);

  if (!data.data) {
    console.error("Error fetching school days from the API");
    return [];
  }
  return data;
};

const getSchoolDaysBySchool = async (params) => {
  const { data } = await axios.get(`${schoolDayEndpoint}`, params);

  if (!data.data) {
    console.error("Error fetching school days from the API");
    return [];
  }
  return data;
};

const update = async (schoolDay) => {
  const { data } = await axios.put(
    `${schoolDayEndpoint}/${schoolDay.id}`,
    schoolDay
  );
  if (!data.data) {
    console.error("Error updating school day");
    return [];
  }
  return data;
};

const bulkUpdate = async (params) => {
  const { data } = await axios.put(`${schoolDayEndpoint}/bulk_update`, params);
  if (!data.data) {
    console.error("Error updating school days");
    return [];
  }
  return data;
};

const nextSchoolDay = (activeDate, termId) =>
  axios.get(`${schoolDayEndpoint}/next_school_day`, {
    params: { active_date: activeDate, term_id: termId },
  });

const getSchoolDayDesignations = async () => {
  const { data } = await axios.get(`${schoolDayEndpoint}/designations`);
  if (!data.data) {
    console.error("Error fetching school day designations");
    return [];
  }
  return data;
};

export default {
  byMonth,
  update,
  bulkUpdate,
  nextSchoolDay,
  getSchoolDaysBySchool,
  getSchoolDayDesignations,
};
