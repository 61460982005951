const klassesListName = {
  width: "250px",
};

const klassesListSubject = {
  width: "250px",
};

const klassesListDescription = {
  width: "300px",
};

const klassesSettingsCell = {
  color: "#808080",
  cursor: "pointer",
};

export {
  klassesListName,
  klassesListSubject,
  klassesListDescription,
  klassesSettingsCell,
};
