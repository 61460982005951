import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import schoolYearService from "../service/schoolYearService";
import { bgWhite, link } from "./sharedStyles";
import SchoolYearDrawer from "./SchoolYearDrawer";
import { PermissionsContext } from "../context/PermissionsContext";
import APP_PERMISSIONS from "../utils/constants/permissions";
import PERMISSION_TYPES from "../utils/constants/permission_types";

export default function SchoolYearSelect({
  schoolId,
  setSnackBarOpen,
  setSelectedSchoolYear = () => {},
  disabled,
  schoolYearActivated,
  setSchoolYearActivated = () => {},
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const schoolYearIdParam = searchParams.get("school_year");
  const [drawerStatus, setDrawerStatus] = useState(false);
  const [schoolYears, setSchoolYears] = useState([]);
  const [selectOpen, setSelectOpen] = useState(false);

  const setCurrentSchoolYear = (_schoolYears) => {
    const today = moment();
    const currentSchoolYear =
      _schoolYears.find((schoolYear) => {
        const start = moment(schoolYear.start_date);
        const end = moment(schoolYear.end_date);
        return today.isBetween(start, end);
      }) || _schoolYears[0];
    if (!schoolYearIdParam) {
      searchParams.set("school_year", currentSchoolYear.id);
      setSearchParams(searchParams);
    }
    setSelectedSchoolYear(
      _schoolYears.find(
        (schoolYear) =>
          schoolYear.id === (Number(schoolYearIdParam) || currentSchoolYear.id)
      )
    );
  };
  const { hasPermission } = useContext(PermissionsContext);

  const managePermission = hasPermission(
    APP_PERMISSIONS.TERMS,
    PERMISSION_TYPES.MANAGE
  );

  const getSchoolYears = async () => {
    const response = await schoolYearService.fetchAll({
      params: {
        school_id: schoolId,
      },
    });
    if (response.data) {
      setSchoolYears(response.data.school_years);
      setCurrentSchoolYear(response.data.school_years);
    }
  };

  const handleChange = (schoolYear) => {
    setSelectedSchoolYear(schoolYear);
    searchParams.set("school_year", schoolYear.id);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    getSchoolYears();
  }, []);

  useEffect(() => {
    if (schoolYearActivated && schoolYearIdParam) {
      let updatingSchoolYears = [...schoolYears];
      updatingSchoolYears = updatingSchoolYears.map((sy) => {
        const updateSchoolYear = sy;
        if (sy.id === Number(schoolYearIdParam))
          updateSchoolYear.admissions_active = true;
        else updateSchoolYear.admissions_active = false;
        return updateSchoolYear;
      });
      setSchoolYears(updatingSchoolYears);
      setSchoolYearActivated(false);
    }
  }, [schoolYearActivated]);

  useEffect(() => {
    if (schoolYearIdParam)
      setSelectedSchoolYear(
        schoolYears.find(
          (schoolYear) => schoolYear.id === Number(schoolYearIdParam)
        )
      );
    else if (schoolYears.length) setCurrentSchoolYear(schoolYears);
  }, [schoolYearIdParam]);

  const handleSelect = () => {
    setSelectOpen(!selectOpen);
  };

  return (
    <>
      <Box sx={{ minWidth: "200px" }}>
        <FormControl fullWidth>
          <InputLabel id="class" shrink sx={bgWhite}>
            Select School Year
          </InputLabel>
          {schoolYears.length > 0 && (
            <Select
              labelId="class"
              id="class"
              value={schoolYearIdParam || ""}
              onClick={handleSelect}
              open={selectOpen}
              disabled={disabled}
            >
              {managePermission && (
                <MenuItem key={0} onClick={() => setDrawerStatus(true)}>
                  <Typography sx={link}>Start New Year</Typography>
                </MenuItem>
              )}

              {schoolYears.map((schoolYear) => (
                <MenuItem
                  key={schoolYear.id}
                  value={schoolYear.id}
                  onClick={() => handleChange(schoolYear)}
                  disabled={disabled}
                >
                  {schoolYear.name}
                </MenuItem>
              ))}
            </Select>
          )}
        </FormControl>
      </Box>
      <SchoolYearDrawer
        schoolId={schoolId}
        setSnackBarOpen={setSnackBarOpen}
        getSchoolYears={getSchoolYears}
        drawerStatus={drawerStatus}
        setDrawerStatus={setDrawerStatus}
      />
    </>
  );
}
