import { useMediaQuery } from "@mui/material";
import theme from "../utils";
import { font16, font28 } from "../components/sharedStyles";

const backgroundStyle = (loadRegistration) => ({
  backgroundImage: loadRegistration
    ? "unset"
    : `url(/registration_form_background.jpg)`,
  backgroundSize: "cover",
  position: "absolute",
  left: 0,
  paddingLeft: 1,
  paddingRight: 1,
});

const gridStyleUp = {
  textAlign: () =>
    (useMediaQuery(theme.breakpoints.up("lg")) && "right") || "center",
};

const applyStyle = { ...font28, color: "#678FBE" };

const backgroundWhite = { backgroundColor: "white" };

const minWidthFit = { minWidth: "fit-content" };

const alignStart = { alignItems: "start" };

const dateOfBaptismInput = {
  width: () =>
    (useMediaQuery(theme.breakpoints.down("sm")) && "100%") || "unset",
};

const submitButton = {
  backgroundColor: "#2196F3",
  color: "white",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#2196F3",
  },
};

const footerStyle = {
  position: "absolute",
  left: 0,
  backgroundColor: "primary.main",
};

const footerTextStyle = { ...font16, color: "#FFFFFF" };

const footerButtonStyle = {
  textTransform: "none",
  color: "#000000",
  height: 30,
  width: 218,
  fontWeight: 400,
  fontSize: 17,
  letterSpacing: -0.05,
  backgroundColor: "#FFFFFF",
  "&:hover": {
    backgroundColor: "#FFFFFF",
  },
};

const colorWhite = { color: "#FFFFFF" };

const centerContent = { width: "100vw", height: "100vh", textAlign: "center" };

export {
  backgroundStyle,
  gridStyleUp,
  applyStyle,
  backgroundWhite,
  minWidthFit,
  alignStart,
  dateOfBaptismInput,
  submitButton,
  footerStyle,
  footerTextStyle,
  footerButtonStyle,
  colorWhite,
  centerContent,
};
