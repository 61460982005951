import { tableCellClasses, tableRowClasses } from "@mui/material";

const tableStyles = {
  [`& .${tableCellClasses.root}`]: {
    borderBottom: "none",
  },
  [`& .${tableRowClasses.root}`]: {
    borderBottom: "1px solid #d9d9d9",
  },
  [`& .${tableCellClasses.root}:not(:first-child)`]: {
    maxWidth: 100,
    width: 100,
  },
  "& .MuiTableRow-head": {
    borderBottom: "none",
    "& .MuiTableCell-head": {
      color: "#8f8e8e",
      textTransform: "uppercase",
      fontWeight: 400,
      fontSize: 11,
    },
  },
  "& .MuiTableCell-body": {
    color: "black",
    fontSize: 14,
  },
  "& .MuiTableCell-root:nth-child(1)": {
    width: "10%",
  },
  "& .MuiTableCell-root:nth-child(2)": {
    width: "5%",
  },
  "& .MuiTableCell-root:nth-child(3)": {
    width: "10%",
  },
  "& .MuiTableCell-root:nth-child(4)": {
    width: "9%",
  },
  "& .MuiTableCell-root:nth-child(5)": {
    width: "9%",
  },
  "& .MuiTableCell-root:nth-child(6)": {
    width: "9%",
  },
  "& .MuiTableCell-root:nth-child(7)": {
    width: "9%",
  },
  "& .MuiTableCell-root:nth-child(8)": {
    width: "9%",
  },
  "& .MuiTableCell-root:nth-child(9)": {
    width: "5%",
  },
};

const linearProgress = (color) => ({
  height: 4,
  borderRadius: 5,
  backgroundColor: "rgba(224, 224, 224, 1) !important",
  "& .MuiLinearProgress-bar ": { backgroundColor: color },
});

export { tableStyles, linearProgress };
