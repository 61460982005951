import React, { useState, useEffect, useContext } from "react";
import {
  useLocation,
  useParams,
  Outlet,
  useSearchParams,
} from "react-router-dom";
import { Container, Box, Stack, Tab, Tabs } from "@mui/material";
import { mb55, pageTabsContainer, pb0 } from "../components/sharedStyles";
import { subjectTopContainer, pageTabsSubject } from "./Subject.styles";
import SubjectSelect from "../components/Subject/SubjectSelect";
import TermSelect from "../components/TermSelect";
import SubjectFilterByClass from "../components/Subject/SubjectFilterByClass";
import subjectService from "../service/subjectService";
import { parameterizeString } from "../utils/constants/utils";
import { PermissionsContext } from "../context/PermissionsContext";
import APP_PERMISSIONS from "../utils/constants/permissions";
import { NavigationContext } from "../context/NavigationContext";
import schoolsService from "../service/schoolsService";

export default function Subject() {
  const [searchParams] = useSearchParams();
  const params = useParams();
  const subjectId = params.subject_id;
  const schoolId = params.school_id;
  const klassesIds = searchParams.get("classes");
  const termId = searchParams.get("term");
  const [school, setSchool] = useState(null);

  const { hasAnyPermissionType } = useContext(PermissionsContext);

  const studentsPermission = hasAnyPermissionType(
    APP_PERMISSIONS.KLASSES_STUDENTS
  );

  const gradebookPermission = hasAnyPermissionType(
    APP_PERMISSIONS.KLASSES_GRADEBOOK
  );
  const assignmentsPermission = hasAnyPermissionType(
    APP_PERMISSIONS.KLASSES_ASSIGNMENTS
  );
  const termGradePermission = hasAnyPermissionType(
    APP_PERMISSIONS.KLASSES_TERM_GRADES
  );

  const formattedParams = () => {
    let paramsUrl = "?";
    if (klassesIds) {
      paramsUrl = paramsUrl.concat(`&classes=${klassesIds}`);
    }

    if (termId) {
      paramsUrl = paramsUrl.concat(`&term=${termId}`);
    }
    return paramsUrl;
  };

  const location = useLocation();
  const tabs = [
    ...(studentsPermission
      ? [
          {
            label: "Students",
            url: `/school/${schoolId}/subjects/${subjectId}/students${formattedParams()}`,
          },
        ]
      : []),
    ...((gradebookPermission && school?.gradebook_version === "v1") ||
    school?.gradebook_version === "both"
      ? [
          {
            label: "Gradebook",
            url: `/school/${schoolId}/subjects/${subjectId}/gradebook${formattedParams()}`,
          },
        ]
      : []),
    ...((gradebookPermission && school?.gradebook_version === "v2") ||
    school?.gradebook_version === "both"
      ? [
          {
            label: "Gradebook V.2",
            url: `/school/${schoolId}/subjects/${subjectId}/new-gradebook${formattedParams()}`,
          },
        ]
      : []),
    ...(assignmentsPermission
      ? [
          {
            label: "Assignments",
            url: `/school/${schoolId}/subjects/${subjectId}/assignments${formattedParams()}`,
          },
        ]
      : []),
    ...(termGradePermission
      ? [
          {
            label: "Term Grade",
            url: `/school/${schoolId}/subjects/${subjectId}/term-grade/class-grade${formattedParams()}`,
          },
        ]
      : []),
  ].map((tab, index) => ({ ...tab, index }));

  const [tabValue, setTabValue] = useState(tabs[0]);
  const [subject, setSubject] = useState(null);
  const { navigateTo } = useContext(NavigationContext);

  const showTermFilter = location.pathname.includes("term-grade/");

  const handleTabChange = (value, path) => {
    // setTabValue(tabs[value]);

    navigateTo(path);
  };

  const getSubject = async () => {
    const response = await subjectService.fetchSubject(subjectId, schoolId);

    setSubject(response.data);
  };

  const updateTabValue = (_school) => {
    // FIXME: see why getTabFromUrl is not working for new-gradebook
    if (
      location.pathname.includes("new-gradebook") &&
      (!!school?.gradebook_version || !!_school?.gradebook_version)
    ) {
      const dbSchool = _school || school;
      const tab = dbSchool?.gradebook_version === "both" ? tabs[2] : tabs[1];
      setTabValue(tab);
      return;
    }
    const getTabFromUrl = tabs.find((tab) =>
      parameterizeString(location.pathname).includes(
        parameterizeString(tab.label)
      )
    );

    setTabValue(getTabFromUrl || tabs[0]);
  };

  const getSchoolInfo = async () => {
    const response = await schoolsService.fetchSchool(schoolId);
    const dbSchool = response.data;
    setSchool(dbSchool);
    return dbSchool;
  };

  useEffect(() => {
    Promise.all([getSubject(), getSchoolInfo()]).then((res) => {
      updateTabValue(res[1]);
    });
  }, [
    subjectId,
    studentsPermission,
    gradebookPermission,
    assignmentsPermission,
    termGradePermission,
  ]);

  useEffect(() => {
    updateTabValue();
  }, [location]);

  return (
    <>
      <Container maxWidth={false} variant="header" sx={pb0}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={subjectTopContainer}
        >
          {subject ? (
            <SubjectSelect
              subjectId={subjectId}
              schoolId={schoolId}
              subjectName={subject.name}
            />
          ) : (
            "Loading..."
          )}
          {showTermFilter && <TermSelect schoolId={schoolId} />}
        </Stack>

        <Box sx={pageTabsContainer}>
          {tabValue && (
            <Tabs value={tabValue.index} sx={pageTabsSubject}>
              {tabs.map((tab) => (
                <Tab
                  key={tab.index}
                  label={tab.label}
                  onClick={() => handleTabChange(tab.index, tab.url)}
                />
              ))}
            </Tabs>
          )}
          <SubjectFilterByClass subjectId={subjectId} schoolId={schoolId} />
        </Box>
      </Container>

      <Box sx={mb55}>
        <Outlet />
      </Box>
    </>
  );
}
