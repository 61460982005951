import React, { useState, useEffect } from "react";
import { ArrowCircleRight } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Typography,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Box,
  Button,
  FormControlLabel,
  Checkbox,
  Stack,
  Link,
} from "@mui/material";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import {
  setToken,
  getRememberMeToken,
  setRememberMeToken,
  removeRememberMeToken,
} from "../../utils/constants/auth";
import {
  CssTextField,
  loginScreenArrowCircle,
  loginScreenContainer,
  loginScreenForm,
  loginScreenGrid,
  loginScreenIconButton,
  loginScreenLogo,
  continueButton,
  checkbox,
  loginScreenPasswordSpacing,
  loginScreenPasswordLink,
} from "./LoginScreen.style";
import { danger } from "../sharedStyles";
import axios from "../../utils/axios-util";
import OtpInput from "../OtpInput";

export default function LoginScreen({
  user,
  setUser,
  defaultSchoolSlug,
  guardianId,
  defaultGuardianKidId,
  defaultStudentSchoolId,
  studentId,
}) {
  const { t, i18n } = useTranslation("common", { keyPrefix: "login" });
  const { register, handleSubmit, setValue } = useForm();
  const [searchParams] = useSearchParams();
  const [dbErrors, setDbErrors] = useState(null);
  const [showOtpScreen, setShowOtpScreen] = useState(false);
  const [rememberMeTokenRemoved, setRememberMeTokenRemoved] = useState(false);
  const [otpCode, setOtpCode] = useState("");
  const onChange = (value) => setOtpCode(value);
  const PARTIAL_CONTENT_CODE = 206;
  const NUMBER_OF_OTP_DIGITS = 6;

  // eslint-disable-next-line consistent-return
  const handleRedirectLocation = (payload) => {
    try {
      const userType = payload.data.type;
      let location = searchParams.get("redirect");

      // check permissions for routes based on user role
      if (
        (userType === "guardian" && !location?.includes("guardian")) ||
        (userType === "student" && !location?.includes("student")) ||
        (userType === "staff" && !location?.includes("school"))
      ) {
        // reset "redirect location" - when the route is not accessible by the current user
        location = null;
      }

      // set redirect location based on user role
      if (!location) {
        if (userType === "staff" || userType === "administrator") {
          location = `/school/${payload.data.default_school.slug}/entry-screen`;
        } else if (userType === "guardian") {
          if (payload.data.default_guardian_kid_id) {
            location = `/guardian/${payload.data.guardian.id}/students/${payload.data.default_guardian_kid_id}/home`;
          } else {
            location = `/guardian/${payload.data.guardian.id}`;
          }
        } else if (userType === "student") {
          location = `student/${payload.data.student.id}/schools/${payload.data.default_student_school_id}/home`;
        }
      }

      return location;
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  const onSubmit = async (data) => {
    setDbErrors(null);
    try {
      const payload = await axios.post("users/sign_in", {
        locale: i18n.language,
        user: {
          email: data.email,
          password: data.password,
          otp_code: data.otpCode,
          remember_me: data.remember_me,
          remember_me_token: rememberMeTokenRemoved ? null : getRememberMeToken,
        },
      });

      if (payload.status === PARTIAL_CONTENT_CODE) {
        if (getRememberMeToken) {
          removeRememberMeToken();
          setRememberMeTokenRemoved(true);
        }
        setShowOtpScreen(true);
      }
      if (payload.data.message === "You are logged in.") {
        setToken(payload.headers.authorization);
        setUser(true);
        if (payload.data.remember_me_token) {
          setRememberMeToken(payload.data.remember_me_token);
        }

        window.location.replace(handleRedirectLocation(payload));
      }
    } catch (e) {
      // handle both rails devise errors and custom error (like "something went wrong")
      const errorMessage = e.response.data?.message || e.response.data;
      setDbErrors(errorMessage);
    }
  };

  const handleLoggedInUser = () => {
    if (defaultSchoolSlug) {
      window.location.replace(`/school/${defaultSchoolSlug}/entry-screen`);
    } else if (user.guardian && guardianId) {
      if (defaultGuardianKidId) {
        window.location.replace(
          `/guardian/${guardianId}/students/${defaultGuardianKidId}/home`
        );
      } else {
        window.location.replace(`/guardian/${guardianId}`);
      }
    } else if (user.student && studentId) {
      window.location.replace(
        `/student/${studentId}/schools/${defaultStudentSchoolId}/home`
      );
    }
  };

  useEffect(() => {
    handleLoggedInUser();
  });

  useEffect(() => {
    if (otpCode.length === NUMBER_OF_OTP_DIGITS) {
      setValue("otpCode", otpCode);
    }
  }, [otpCode]);

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={loginScreenGrid}
    >
      <Grid item xs={3}>
        <Container style={loginScreenContainer}>
          <img src="assets/logo-new.png" style={loginScreenLogo} alt="logo" />
        </Container>

        {showOtpScreen ? (
          <>
            <Box>
              <Typography variant="title" align="center">
                <p>{t("enterMfaCode")}</p>
              </Typography>
            </Box>

            <form onSubmit={handleSubmit(onSubmit)}>
              <OtpInput otpCode={otpCode} onChange={onChange} />
              <Stack mt={4} alignItems="center" direction="column">
                <FormControlLabel
                  control={
                    <Checkbox sx={checkbox} {...register("remember_me", {})} />
                  }
                  label={
                    <Box>
                      <Typography>
                        {t("notRequireCodeFor", { days: 30 })}
                      </Typography>
                    </Box>
                  }
                />
                <Button sx={continueButton} type="submit">
                  {t("continue")}
                </Button>
                {dbErrors && (
                  <Typography mt={3} sx={danger}>
                    {dbErrors}
                  </Typography>
                )}
              </Stack>
            </form>
          </>
        ) : (
          <>
            <Box>
              <Typography variant="title" align="center">
                <p>{t("signInHeader")}</p>
              </Typography>

              <form onSubmit={handleSubmit(onSubmit)} style={loginScreenForm}>
                <CssTextField
                  required
                  id="email-required"
                  label={t("enterEmail")}
                  variant="filled"
                  size="small"
                  color="text"
                  margin="dense"
                  fullWidth
                  {...register("email", { required: true })}
                />

                <CssTextField
                  required
                  type="password"
                  id="password-required"
                  label={t("enterPassword")}
                  size="small"
                  variant="filled"
                  color="text"
                  margin="dense"
                  fullWidth
                  {...register("password", { required: true })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton type="submit" sx={loginScreenIconButton}>
                          <ArrowCircleRight
                            color="success"
                            sx={loginScreenArrowCircle}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                {dbErrors && <Typography sx={danger}>{dbErrors}</Typography>}
              </form>
            </Box>
            <Box sx={loginScreenPasswordSpacing}>
              <Link
                sx={loginScreenPasswordLink}
                component={RouterLink}
                underline="hover"
                color="text"
                to={`/forgot-password?locale=${i18n.language}`}
              >
                {t("forgotPassword")}
              </Link>
            </Box>
          </>
        )}
      </Grid>
    </Grid>
  );
}
