import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  Typography,
  FormControl,
  FormControlLabel,
  Checkbox,
  InputLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { klassFormDivider, klassFormTitle } from "./KlassForm.styles";
import {
  klassCategoryCloseIcon,
  klassCategoryFormControl,
  klassCategoryInputBox,
  klassCategoryWeightFormControl,
  klassFormAddCatBtn,
  klassFormCategoryBox,
  klassWeightError,
} from "./Gradebook.styles";
import { bgWhite, mb30, formCheckBox } from "../sharedStyles";
import ErasableTextField from "../ErasableTextField";
import { renderError } from "../../utils/constants/forms";
import GradeAttendance from "./GradeAttendance";

function CategoryInput({
  currentCategory,
  categoryList,
  setCategoryList,
  isEvenlyWeighted,
}) {
  const {
    setValue,
    formState: { errors },
  } = useFormContext();

  const categoryName = `categoryName${currentCategory.id}`;

  const removeCategory = (id) => {
    setValue(categoryName);
    setCategoryList(categoryList.filter((category) => category.id !== id));
  };

  const handleWeightChange = (e, id) => {
    let value = Math.round(e.target.value);

    if (value > 100) {
      value = 100;
    }

    setCategoryList(
      categoryList.map((category) => {
        if (category.id === id) {
          return {
            id,
            name: category.name,
            weight: value,
            assignmentCount: category.assignmentCount,
            attendance_category: category.attendance_category,
          };
        }
        return category;
      })
    );
  };

  const handleNameChange = (e, id) => {
    setCategoryList(
      categoryList.map((category) => {
        if (category.id === id) {
          return {
            id,
            name: e.target.value,
            weight: category.weight,
            assignmentCount: category.assignmentCount,
            attendance_category: category.attendance_category,
          };
        }
        return category;
      })
    );
  };

  const eraseWeight = (id) => {
    setCategoryList(
      categoryList.map((category) => {
        if (category.id === id) {
          return {
            id,
            name: category.name,
            weight: 0,
            assignmentCount: category.assignmentCount,
            attendance_category: category.attendance_category,
          };
        }
        return category;
      })
    );
  };

  return (
    <Box sx={mb30}>
      <Box sx={klassCategoryInputBox}>
        <FormControl
          fullWidth
          sx={klassCategoryFormControl}
          error={errors?.[categoryName] && true}
        >
          <Box sx={klassFormCategoryBox}>
            <InputLabel
              id={`categoryNameLabel${currentCategory.id}`}
              shrink
              sx={bgWhite}
            >
              {currentCategory?.attendance_category
                ? "Grade attendance"
                : "Category Name"}
            </InputLabel>
            <ErasableTextField
              labelId={`categoryNameLabel${currentCategory.id}`}
              id={categoryName}
              label={
                currentCategory?.attendance_category
                  ? "Grade attendance"
                  : "Category Name"
              }
              fullWidth
              isRequired
              type="text"
              hasError={errors?.[categoryName] && true}
              onChange={(e) => handleNameChange(e, currentCategory.id)}
              defaultValue={currentCategory?.name}
            />
          </Box>
        </FormControl>

        {!isEvenlyWeighted && (
          <FormControl fullWidth sx={klassCategoryWeightFormControl}>
            <Box sx={klassFormCategoryBox}>
              <InputLabel
                id={`weight${currentCategory.id}`}
                shrink
                sx={bgWhite}
              >
                Weight
              </InputLabel>
              <ErasableTextField
                labelId={`weight${currentCategory.id}`}
                id={`weight${currentCategory.id}`}
                isRequired
                type="number"
                hasError={false}
                onChange={(e) => handleWeightChange(e, currentCategory.id)}
                eraseCallback={() => eraseWeight(currentCategory.id)}
                defaultValue={currentCategory?.weight}
              />
            </Box>
          </FormControl>
        )}

        <Box>
          {currentCategory.assignmentCount > 0 ? (
            <Typography>
              <b>{currentCategory.assignmentCount}</b> assignments
            </Typography>
          ) : (
            categoryList[0].id !== currentCategory.id &&
            currentCategory.attendance_category !== true && (
              <CloseIcon
                onClick={() => removeCategory(currentCategory.id)}
                sx={klassCategoryCloseIcon}
              />
            )
          )}
        </Box>
      </Box>
      {errors?.[categoryName] && renderError(errors?.[categoryName].message)}
    </Box>
  );
}

export default function Gradebook({
  categoryList,
  setCategoryList,
  weightError,
  setWeightError,
  activeKlass,
}) {
  const defaultCheckBox = activeKlass
    ? activeKlass.has_evenly_weighted_categories
    : true;
  const { register, setError, watch, setValue } = useFormContext();
  const [numberOfCategories, setNumberOfCategories] = useState(0);
  const [isEvenlyWeighted, setIsEvenlyWeighted] = useState(defaultCheckBox);
  const [totalWeight, setTotalWeight] = useState(0);

  useEffect(() => {
    let weight = 0;

    categoryList.forEach((category) => {
      weight += category.weight;
    });
    setTotalWeight(weight);

    if (activeKlass) {
      setNumberOfCategories(categoryList.length);
    }
  }, [categoryList]);

  useEffect(() => {
    if (!isEvenlyWeighted && categoryList.length > 0 && totalWeight !== 100) {
      setWeightError(true);
      setError("hasEvenlyWeightedCategories", {
        type: "custom",
        message: "Weight must equal 100",
      });
    } else {
      setWeightError(false);
    }
  }, [totalWeight, isEvenlyWeighted, categoryList]);

  const addCategory = () => {
    setNumberOfCategories(numberOfCategories + 1);

    setCategoryList(
      categoryList.concat({
        id: categoryList[categoryList.length - 1].id + 1,
        name: null,
        weight: null,
        attendance_category: false,
      })
    );
  };

  useEffect(() => {
    if (watch("klass_attendance") === false) {
      setValue("attendance_category", false);
      setCategoryList(
        categoryList.filter((category) => category.attendance_category !== true)
      );
    }
  }, [watch("klass_attendance")]);

  return (
    <Box sx={klassFormDivider}>
      <Typography sx={klassFormTitle}>Gradebook</Typography>

      <FormControl fullWidth sx={formCheckBox}>
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked={defaultCheckBox}
              {...register("hasEvenlyWeightedCategories", {
                onChange: () => setIsEvenlyWeighted(!isEvenlyWeighted),
              })}
            />
          }
          label={<Typography>Evenly weighted categories</Typography>}
        />
        {weightError && <Box sx={klassWeightError}>Weight must equal 100</Box>}
      </FormControl>
      {categoryList
        .sort((a, b) => a.id - b.id)
        .map((category) => (
          <CategoryInput
            key={category.id}
            currentCategory={category}
            categoryList={categoryList}
            setCategoryList={setCategoryList}
            isEvenlyWeighted={isEvenlyWeighted}
          />
        ))}
      <Button
        sx={klassFormAddCatBtn}
        startIcon={<AddIcon />}
        onClick={addCategory}
      >
        Add category
      </Button>

      {watch("klass_attendance") && (
        <GradeAttendance
          categoryList={categoryList}
          setCategoryList={setCategoryList}
          activeKlass={activeKlass}
        />
      )}
    </Box>
  );
}
