import { Grid, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";

export default function MinLengthComponent({ selectedField }) {
  const { control, setValue } = useFormContext();

  useEffect(() => {
    setValue("minLength", selectedField.properties.minLength);
  }, [selectedField]);

  return (
    <Grid item md={12}>
      <Controller
        name="minLength"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            disabled={
              selectedField.source_field_id ||
              selectedField.properties.dbRequiredField
            }
            label="Min Length"
          />
        )}
      />
    </Grid>
  );
}
