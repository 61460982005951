import React from "react";
import { Typography, CircularProgress, Grid } from "@mui/material";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import SimpleCard from "../../SimpleCard";
import { loader } from "../../sharedStyles";
import { summaryStyle, cardTitle } from "./Summary.style";

function Summary({
  open,
  categories,
  setSelectedCategory,
  searchParams,
  setSearchParams,
  setPage,
}) {
  if (categories.length <= 0) {
    return <CircularProgress sx={loader} size={100} />;
  }
  const onCategorySelect = (category) => {
    setSelectedCategory(category);
    if (!category) searchParams.delete("selectedCategory");
    else searchParams.set("selectedCategory", category);
    setPage(1);
    searchParams.set("page", 1);
    setSearchParams(searchParams);
  };
  return (
    <>
      <Grid
        sx={summaryStyle}
        key="All"
        onClick={() => {
          onCategorySelect("");
        }}
        item
        xs={2}
      >
        <SimpleCard
          marginless
          compact
          isSelected={!searchParams.get("selectedCategory")}
          title={
            <Typography sx={cardTitle} mb={3}>
              All
            </Typography>
          }
          content={
            <Typography variant="title">
              {categories.reduce((acc, category) => acc + category.count, 0)}
            </Typography>
          }
          icon={<FilterListOutlinedIcon color="action" />}
          fullWidth={open}
        />
      </Grid>
      {categories.map((category) => (
        <Grid
          sx={summaryStyle}
          key={category.name}
          onClick={() => {
            onCategorySelect(category.category_id);
          }}
          item
          xs={2}
        >
          <SimpleCard
            marginless
            isSelected={
              Number(searchParams.get("selectedCategory")) ===
              category.category_id
            }
            compact
            title={
              <Typography sx={cardTitle} mb={3}>
                {category.name}
              </Typography>
            }
            content={<Typography variant="title">{category.count}</Typography>}
            icon={<FilterListOutlinedIcon color="action" />}
            fullWidth={open}
          />
        </Grid>
      ))}
    </>
  );
}
export default Summary;
