import React from "react";
import { Grid, Typography } from "@mui/material";

export default function PrintLayoutHeader({ content }) {
  return (
    <thead className="report-header">
      <tr>
        <th
          className="report-header-cell"
          align="left"
          style={{ padding: "2rem 1rem 0 2rem" }}
        >
          {typeof content === "string" && (
            <Grid container direction="column" className="header-info">
              <Typography fontSize={32} color="black">
                {content}
              </Typography>
              <Typography fontSize={11} color="black">
                Date:{" "}
                {new Date().toLocaleDateString("en-US", {
                  weekday: "long",
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                })}
              </Typography>
            </Grid>
          )}
          {React.isValidElement(content) && content}
        </th>
      </tr>
    </thead>
  );
}
