import { useMediaQuery } from "@mui/material";

const accountHorizontalContainer = {
  borderBottom: 1,
  borderColor: "divider",
  margin: 0,
  color: "#000000",
};

const accountVerticalContainer = {
  borderRight: 1,
  borderColor: "divider",
};

const accountProfileImage = (theme) => {
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return {
    transform: matches ? "scale(2.5)" : "scale(1)",
  };
};

const accountProfileImageButton = {
  position: "absolute",
  top: "75%",
  left: "50%",
  transform: "translate(-50%, -10%) scale(.5)",
  color: "white",
  border: "1px solid white",
  textTransform: "capitalize",
  "&:hover": {
    border: "1px solid white",
  },
};

const accountProfileForm = (theme) => {
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return {
    width: matches ? "77%" : "100%",
  };
};

const accountSecondarySections = { borderTop: 1, py: "45px !important" };
const textCenter = { textAlign: "center" };
const academicsImg = {
  width: "100%",
  height: "inherit",
  objectFit: "cover",
  backgroundColor: "black",
};

const profilePicContainer = {
  textAlign: "center",
  position: "relative",
  display: "flex",
  justifyContent: "center",
};

const replaceButton = {
  position: "absolute",
  bottom: "15%",
  color: "#ededed",
  borderColor: "#ededed",
  paddingX: "1.5rem",
  borderRadius: "0.4rem",
  textTransform: "capitalize",
};

const academicsGridDividerBottom = {
  borderBottom: 1,
  borderColor: "divider",
};

const fieldsContainer = {
  paddingTop: "45px",
  marginLeft: "24px",
};

export {
  accountSecondarySections,
  accountHorizontalContainer,
  accountVerticalContainer,
  accountProfileImage,
  accountProfileImageButton,
  accountProfileForm,
  textCenter,
  academicsImg,
  academicsGridDividerBottom,
  replaceButton,
  profilePicContainer,
  fieldsContainer,
};
