import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import Group from "./ConditionNodes/Group";
import { cancelButton, submitButton } from "../../FormBuilder.style";
import { ConditionNodeType } from "../../Models/Fields/ConditionNodes/constants";
import ConditionNode from "../../Models/Fields/ConditionNodes/ConditionNode";
import generateCode from "./ConditionNodes/generateConditionJS";
import { FormBuilderContext } from "../../FormBuilderContext";

export default function VisibilityCondition({ selectedField }) {
  const { setValue } = useFormContext();
  const methods = useForm();
  const formBuilderContext = useContext(FormBuilderContext);
  const { form, currentSectionIndex, currentPageIndex } = formBuilderContext;
  const [showVisibilityConditions, setShowVisibilityConditions] =
    useState(false);

  const [visibilityCondition, setVisibilityCondition] = useState(
    selectedField.properties.visibilityCondition
  );

  useEffect(() => {
    setValue("visibilityCondition", visibilityCondition, { shouldDirty: true });
  }, [visibilityCondition]);

  useEffect(() => {
    setVisibilityCondition(selectedField.properties.visibilityCondition);
  }, [selectedField]);

  const createVisibilityCondition = () => {
    setVisibilityCondition(
      new Array(new ConditionNode(ConditionNodeType.GROUP))
    );
  };

  const generateJS = () => {
    const visibilityConditionTree = methods.getValues("visibilityCondition");
    if (visibilityConditionTree) {
      setVisibilityCondition(visibilityConditionTree);
      const jsString = generateCode(
        visibilityConditionTree,
        form.formPages[currentPageIndex].sections[currentSectionIndex].fields
      );
      setValue("visibilityConditionJS", jsString, { shouldDirty: true });
      setValue("visibilityMessage", methods.getValues("visibilityMessage"));
      setShowVisibilityConditions(false);
    }
  };

  const onSubmit = () => {
    if (visibilityCondition.length) generateJS();
    else {
      setShowVisibilityConditions(false);
    }
  };

  const onCancel = () => {
    setValue(
      "visibilityCondition",
      selectedField.properties.visibilityCondition
    );
    setVisibilityCondition(selectedField.properties.visibilityCondition);
    setShowVisibilityConditions(false);
  };

  return (
    <Grid item md={12}>
      <Button
        sx={{ ...cancelButton, width: "100%" }}
        onClick={() => {
          setShowVisibilityConditions(true);
        }}
      >
        <Typography>Visibility Conditions</Typography>
      </Button>
      <Dialog
        open={showVisibilityConditions}
        sx={{
          "& .MuiDialog-paper": {
            width: "35%",
            maxHeight: 435,
            maxWidth: "unset",
          },
        }}
      >
        <DialogTitle>
          <Typography>
            Visibility Conditions for <b>{selectedField.label}</b>
          </Typography>
        </DialogTitle>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <DialogContent>
              {!visibilityCondition.length && (
                <Grid container justifyContent="center" my={2}>
                  <Button sx={cancelButton} onClick={createVisibilityCondition}>
                    <Typography>Create Condition</Typography>
                  </Button>
                </Grid>
              )}
              {visibilityCondition.map((node, index) => (
                <Group
                  key={node.id}
                  conditionNode={node}
                  parent={visibilityCondition}
                  nodeIndex={index}
                  control={`visibilityCondition[${index}]`}
                  updateParent={setVisibilityCondition}
                />
              ))}
            </DialogContent>
          </form>
        </FormProvider>
        <DialogActions>
          <Button sx={cancelButton} onClick={onCancel} type="button">
            <Typography>Cancel</Typography>
          </Button>
          <Button onClick={generateJS} sx={submitButton}>
            <Typography>Save</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
