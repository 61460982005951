const reportCardDashboardCardsContainer = {
  display: "flex",
  justifyContent: "center",
  gap: "30px",
  marginTop: "30px",
};

const reportCardDashboardTableHeader = {
  fontSize: "22px",
  color: "inherit",
  fontWeight: "400",
};

const reportCardDashboardTableCell = { minWidth: "200px", maxWidth: "200px" };

const reportCardDashboardHeaderTableCell = {
  fontSize: "11px",
  fontWeight: "500",
};

const reportCardDashboardGradeCell = {
  display: "flex",
  justifyContent: "flex-end",
};

const reportCardDashboardTableRow = {
  mt: 1,
  pb: 2,
  ml: 5,
  borderBottom: "1px solid #BFC7CA",
  ":last-child": { borderBottom: "none" },
};

export {
  reportCardDashboardCardsContainer,
  reportCardDashboardTableHeader,
  reportCardDashboardTableCell,
  reportCardDashboardHeaderTableCell,
  reportCardDashboardGradeCell,
  reportCardDashboardTableRow,
};
