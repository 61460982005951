import { AddCircleOutlined, CancelOutlined } from "@mui/icons-material";
import {
  ButtonBase,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Grid,
} from "@mui/material";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { generateGUID } from "../../../../../../utils/constants/utils";
import lookupService from "../../../../../../service/lookupService";
import countriesService from "../../../../../../service/countriesService";

export default function OptionComponent({ selectedField }) {
  const [options, setOptions] = React.useState(
    selectedField.properties.options
  );
  const methods = useFormContext();

  const addOptions = () => {
    const cloneOptions = [...options];
    cloneOptions.push({ id: generateGUID(), value: "Option Text" });
    setOptions(cloneOptions);
    methods.setValue("options", cloneOptions);
  };

  const deleteOption = (index) => {
    const updatedOptions = [...options];
    updatedOptions.splice(index, 1);
    setOptions(updatedOptions);
    methods.setValue("options", updatedOptions);
  };

  const isPrefilledOptions = () =>
    [
      "Gender",
      "Language",
      "Ethnicity",
      "Denomination",
      "State",
      "Country",
      "Relationship to the student",
    ].includes(selectedField.label);

  const prefillOptions = async () => {
    if (selectedField.properties.options.length === 0) {
      // fetch options from DB
      let response;
      switch (selectedField.label) {
        case "Gender":
          response = await lookupService.fetchGenders();
          response = response?.data?.data;
          break;
        case "Language":
          response = await lookupService.fetchLanguages();
          response = response?.data?.data;
          break;
        case "Ethnicity":
          response = await lookupService.fetchEthnicities();
          response = response?.data?.data;
          break;
        case "Denomination":
          response = await lookupService.fetchDenomination();
          response = response?.data?.data;
          break;
        case "Relationship to the student":
          response = await lookupService.fetchGuardians();
          response = response?.data?.data;
          break;
        case "State":
          response = await countriesService.fetchAllStates();
          response = response?.data;
          break;
        case "Country":
          response = await countriesService.fetchAllCountries();
          response = response?.data;
          break;
        default:
          response = null; // Default case, you can handle it as needed
      }

      // set options to form fields
      if (response && response.length > 0) {
        const cloneOptions = [];
        // eslint-disable-next-line array-callback-return
        response.map((prefillOption) => {
          cloneOptions.push({
            id: generateGUID(),
            value: prefillOption.name,
            valueId: ["State", "Country"].includes(selectedField.label)
              ? prefillOption.id
              : "",
          });
          setOptions(cloneOptions);
          methods.setValue("options", cloneOptions);
        });
      }
    }
  };

  useEffect(() => {
    prefillOptions();
    setOptions(selectedField.properties.options);
    methods.setValue("options", selectedField.properties.options);
  }, [selectedField]);

  return (
    <Grid item md={12}>
      <FormControl disabled={selectedField.source_field_id} fullWidth>
        <InputLabel id="optionsLabel">
          {options.length
            ? `${options.length} options created`
            : "Create options"}
        </InputLabel>
        <Select
          labelId="optionLabel"
          label={
            options.length
              ? `${options.length} options created`
              : "Create options"
          }
          placeholder={
            options.length
              ? `${options.length} options created`
              : "Create options"
          }
        >
          {options.map((option, index) => (
            <MenuItem key={option.id} sx={{ justifyContent: "between" }}>
              <TextField
                disabled={isPrefilledOptions()}
                defaultValue={option.value}
                onChange={(e) => {
                  if (isPrefilledOptions()) return;
                  const formOptions = methods.getValues("options");
                  formOptions[index].value = e.target.value;
                  setOptions(formOptions);
                  methods.setValue("options", formOptions);
                }}
              />
              {!isPrefilledOptions() && (
                <ButtonBase
                  sx={{ p: 2 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteOption(index);
                  }}
                >
                  <CancelOutlined />
                </ButtonBase>
              )}
            </MenuItem>
          ))}
          {!isPrefilledOptions() && (
            <MenuItem
              key="add_option_button"
              sx={{
                justifyContent: "center",
                border: "1px grey solid",
                margin: 1.5,
              }}
            >
              <ButtonBase
                onClick={(e) => {
                  e.stopPropagation();
                  addOptions();
                }}
              >
                <AddCircleOutlined />
              </ButtonBase>
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </Grid>
  );
}
