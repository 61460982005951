const pageTabs = {
  mt: "10px",
  "& .MuiTab-root": {
    padding: 0,
    textTransform: "none",
    mr: "10px",
    fontSize: 14,
    fontWeight: "normal",
    color: "black",
    minWidth: "unset",
    flexGrow: 1,
  },
  "& .MuiTabs-scroller": {
    width: "100%",
    "& .MuiTabs-flexContainer": {
      width: "100%",
    },
  },
};

const tabsStyle = {
  ...pageTabs,
  width: "100%",
  "& .Mui-selected": {
    color: "#2196F3 !important",
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "#2196F3 !important",
  },
};

export { pageTabs, tabsStyle };
