const popperStyle = {
  backgroundColor: "#FFFFFF",
  bottom: "-5px !important",
  top: "auto !important",
  left: "calc(50% - 250px) !important",
  width: "500px",
  borderRadius: "5px",
  zIndex: "1400",
  boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
};

const cancelButtonStyle = {
  backgroundColor: "#0000001F",
  color: "rgba(0, 0, 0, 0.38)",
  padding: "2px 15px",
  height: "40px",
  textTransform: "none",
  fontSize: "13px",
  ":hover": {
    backgroundColor: "#0000001F",
    color: "rgba(0, 0, 0, 0.38)",
  },
};

const saveButtonStyle = {
  backgroundColor: "#2196F3",
  color: "white",
  padding: "2px 15px",
  height: "40px",
  textTransform: "none",
  fontSize: "13px",
  ":hover": {
    backgroundColor: "#2196F3",
    color: "white",
  },
};

export { popperStyle, cancelButtonStyle, saveButtonStyle };
