import axios from "../utils/axios-util";

const securityGroupPermissionsEndpoint = "/api/security_group_permissions";

const fetchAll = async (params) => {
  const { data } = await axios.post(securityGroupPermissionsEndpoint, params);

  if (!data.data) {
    console.error("Error fetching security groups permissions from API");
    return [];
  }
  return data;
};

const update = async (params) => {
  const { data } = await axios.put(securityGroupPermissionsEndpoint, params);

  if (!data.data) {
    console.error("Error updating security groups permissions from API");
    return [];
  }
  return data;
};

const updateGroup = async (params) => {
  const { data } = await axios.put(
    `${securityGroupPermissionsEndpoint}/update_group`,
    params
  );

  if (!data.data) {
    console.error("Error updating security groups permissions from API");
    return [];
  }
  return data;
};

export default {
  fetchAll,
  update,
  updateGroup,
};
