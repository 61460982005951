import { Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useOutletContext, useParams, useSearchParams } from "react-router-dom";
import reportsService from "../../../service/reportsService";
import HomeroomStudents from "./HomeroomStudents";
import PrintLayout from "../PrintLayoutComponents/PrintLayout";

export default function HomeroomRoster() {
  const [teachers, setTeachers] = useState([]);

  const params = useParams();
  const [searchParams] = useSearchParams();
  const [schoolName] = useOutletContext();
  const teacherId = searchParams.get("homeroomTeacherId");

  useEffect(() => {
    (async () => {
      const response = await reportsService.homeroomRoster({
        school_id: params.school_id,
        teacher_id: teacherId === "all" ? null : teacherId,
      });

      setTeachers(response);
    })();
  }, [teacherId]);

  const generateBodyContent = (
    <tr className="report-data">
      <td
        className="report-content-cell"
        style={{ padding: "2rem 1rem 0 2rem" }}
      >
        <div>
          {teachers.length > 0 &&
            teachers.map((teacher) => (
              <Grid>
                <Grid container pt={3}>
                  <Grid item md={6}>
                    <Typography fontSize={24} color="black">
                      {teacher.name_title}. {teacher.first_name}{" "}
                      {teacher.last_name}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    md={6}
                    direction="row"
                    justifyContent="end"
                    alignItems="end"
                    px="16px"
                  >
                    <Typography fontWeight={700} color="black" fontSize={13}>
                      TOTAL STUDENTS: {teacher.students.length}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid pt={5}>
                  <HomeroomStudents students={teacher.students} />
                </Grid>
              </Grid>
            ))}
        </div>
      </td>
    </tr>
  );
  return (
    <PrintLayout
      headerContent="Homeroom Roster"
      bodyContent={generateBodyContent}
      footerContent={schoolName}
    />
  );
}
