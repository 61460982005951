import React, { useContext, useState } from "react";
import {
  Box,
  Stack,
  Button,
  Typography,
  Grid,
  TextareaAutosize,
} from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import GeneralInfo from "../../Account/EmergencyContacts/EmergencyContactForm/GeneralInfo";
import Options from "../../Account/EmergencyContacts/EmergencyContactForm/Options";
import Notifications from "../../Account/EmergencyContacts/EmergencyContactForm/Notifications";
import {
  optionsContainer,
  notesTextArea,
} from "../../Account/EmergencyContacts/EmergencyContactForm/EmergencyContactForm.styles";
import {
  mb25,
  mt70,
  formHeader,
  formButtons,
  formTitle,
  formContainer,
} from "../../../sharedStyles";
import emergencyContactsService from "../../../../service/emergencyContactsService";
import AlertDialog from "../../../AlertDialog";
import { convertBase64 } from "../../../../utils/constants/utils";
import SnackBarNotification from "../../../SnackBarNotification";
import { StudentActionsContext } from "../../../../context/StudentActionsContext";
import GenericSubmitButton from "../../../GenericSubmitButton";

export default function EmergencyContactForm({
  setIsEContactsDrawerOpen,
  studentId,
  currentContact,
  fetchAllContacts,
}) {
  const methods = useForm();
  const studentActionsContext = useContext(StudentActionsContext);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState({
    open: false,
    message: null,
  });

  const handleSackBarClose = () => {
    setSnackBarOpen({ open: false });
  };

  const handleCreate = async (params) => {
    const response = await emergencyContactsService.createEmergencyContact(
      studentId,
      params
    );

    if (response.data) {
      setSnackBarOpen({
        open: true,
        message: "Emergency contact created.",
      });
      setIsEContactsDrawerOpen(false);
      studentActionsContext.fireEContactUpdated(true);
    }
  };

  const handleEcontactDrawer = (state) => {
    setIsEContactsDrawerOpen(state);
    fetchAllContacts();
  };

  const handleUpdate = async (params) => {
    const response = await emergencyContactsService.updateEmergencyContact(
      studentId,
      currentContact.id,
      params
    );

    if (response.data) {
      setSnackBarOpen({
        open: true,
        message: "Emergency contact updated.",
      });
      handleEcontactDrawer(false);
    }
  };

  const handleDelete = async () => {
    const response = await emergencyContactsService.deleteEmergencyContact(
      studentId,
      currentContact.id
    );

    if (response.data.message === "Deleted successfully") {
      setSnackBarOpen({
        open: true,
        message: "Emergency contact deleted.",
      });
      handleEcontactDrawer(false);
    }
  };

  const onSubmit = async (data) => {
    const body = data;
    if (data.profile_picture[0])
      body.profile_picture = await convertBase64(data.profile_picture[0]);

    if (currentContact) {
      handleUpdate(body);
    } else {
      handleCreate(body);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack direction="row" sx={formHeader}>
          <Typography sx={formTitle}>
            {currentContact ? "Contact" : "Add Contact"}
          </Typography>
          <Stack direction="row">
            <Button
              sx={formButtons}
              onClick={() => handleEcontactDrawer(false)}
            >
              Cancel
            </Button>
            {currentContact && (
              <GenericSubmitButton
                text="Delete"
                submittingText="Deleting..."
                type="button"
                onClick={() => setDialogOpen(true)}
              />
            )}
            <GenericSubmitButton text="Save" submittingText="Saving..." />
          </Stack>
        </Stack>
        <Box sx={formContainer}>
          <GeneralInfo currentContact={currentContact} />

          <Grid container item columnSpacing={2} sx={mt70}>
            <Grid container item sm={5} sx={optionsContainer}>
              <Options currentContact={currentContact} />
            </Grid>
            <Grid container item sm={7}>
              <Notifications currentContact={currentContact} />
            </Grid>
          </Grid>
          <Grid container item columnSpacing={2} sx={mt70}>
            <Grid container item sm={12}>
              <Typography variant="h2" sx={mb25}>
                Notes
              </Typography>
            </Grid>
            <Grid container item sm={12}>
              <TextareaAutosize
                aria-label="empty textarea"
                placeholder="Notes"
                style={notesTextArea}
                defaultValue={currentContact?.notes}
                {...methods.register("notes")}
              />
            </Grid>
          </Grid>
        </Box>
      </form>

      <AlertDialog
        isOpen={isDialogOpen}
        handleClose={handleCloseDialog}
        handleConfirm={handleDelete}
      />
      <SnackBarNotification
        message={snackBarOpen.message}
        open={snackBarOpen.open}
        handleClose={handleSackBarClose}
      />
    </FormProvider>
  );
}
