import React from "react";
import { Grid, Typography, IconButton } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  font40,
  daySelectInner,
  daySelectButton,
  black,
} from "../../../sharedStyles";
import moment from "../../../../utils/constants/momentConfig";
import dateTimeFormats from "../../../../utils/constants/dateTimeFormats";
import { currentDateTypography } from "./ClassSchedulesDaySelect.styles";

export default function ClassSchedulesDaySelect({
  term,
  searchParams,
  setSearchParams,
  dayTemplate,
  setSelectedTrack,
}) {
  const directions = { back: 1, forward: 2 };

  const currentDate =
    searchParams.get("date") !== null
      ? moment(searchParams.get("date"))
      : moment(term?.start_date);

  const handleDayChange = (direction) => {
    const canGoBack = currentDate.add(-1, "d").isSameOrAfter(term?.start_date);
    const canGoForward = currentDate.add(1, "d").isBefore(term?.end_date);

    if (direction === directions.back && canGoBack) {
      searchParams.set(
        "date",
        currentDate.add(-1, "d").format(dateTimeFormats.YYYYMMDD)
      );
    } else if (direction === directions.forward && canGoForward) {
      searchParams.set(
        "date",
        currentDate.add(1, "d").format(dateTimeFormats.YYYYMMDD)
      );
    }
    setSearchParams(searchParams);
    setSelectedTrack(dayTemplate?.tracks?.[0] || "");
  };

  return (
    <Grid container sx={daySelectInner}>
      <Grid item xs={5}>
        <Typography sx={currentDateTypography}>
          {currentDate.format(dateTimeFormats.MMMDYYYY)}
        </Typography>
      </Grid>
      <Grid container item xs={2}>
        <Grid item xs={6}>
          <IconButton
            disableRipple
            sx={daySelectButton}
            onClick={() => handleDayChange(directions.back)}
          >
            <KeyboardArrowLeftIcon sx={font40} color="primary" />
          </IconButton>
        </Grid>
        <Grid item xs={6}>
          <IconButton
            disableRipple
            sx={daySelectButton}
            onClick={() => handleDayChange(directions.forward)}
          >
            <KeyboardArrowRightIcon sx={font40} color="primary" />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item xs={5} pl={1}>
        <Typography sx={black}>
          Day Template: {dayTemplate?.abbreviation}
        </Typography>
      </Grid>
    </Grid>
  );
}
