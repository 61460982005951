import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  TextField,
} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import schoolDayService from "../../../service/schoolDayService";
import { boxStyle, dayStyle } from "./SchoolDays.styles";
import { PermissionsContext } from "../../../context/PermissionsContext";
import APP_PERMISSIONS from "../../../utils/constants/permissions";
import PERMISSION_TYPES from "../../../utils/constants/permission_types";

export default function SchoolDaysItem({
  schoolDay,
  setSnackBarOpen,
  dayTemplates,
  getSchoolDaysByMonth,
  options,
}) {
  const dayTemplateId = schoolDay?.day_template_id
    ? `${schoolDay?.day_template_id}-${schoolDay?.day_template?.created_at}`
    : "-1";
  const shouldShowSchoolDay = typeof schoolDay === "object";
  const [color, setColor] = useState();
  const [addCommentMode, setAddCommentMode] = useState(
    schoolDay.comment && schoolDay.comment !== ""
  );
  const [comment, setComment] = useState(schoolDay.comment);
  const [designation, setDesignation] = useState(
    schoolDay?.school_day_designation?.id
      ? `${schoolDay?.school_day_designation?.id}-${schoolDay?.school_day_designation?.created_at}`
      : dayTemplateId
  );
  const { hasPermission } = useContext(PermissionsContext);

  const managePermission = hasPermission(
    APP_PERMISSIONS.SCHOOL_DAYS,
    PERMISSION_TYPES.MANAGE
  );

  const handleChange = async (event) => {
    setDesignation(event.target.value);
    let params = {};
    const isDesignation = options.some(
      (option) => `${option.id}-${option.created_at}` === event.target.value
    );
    if (isDesignation) {
      params = {
        ...schoolDay,
        school_day_designation_id: event.target.value.split("-")[0],
        day_template_id: null,
      };
    } else {
      params = {
        ...schoolDay,
        school_day_designation_id: null,
        day_template_id: event.target.value.split("-")[0],
      };
    }
    try {
      const response = await schoolDayService.update(params);

      if (response.data) {
        const onlyStats = true;
        getSchoolDaysByMonth(onlyStats);
        setSnackBarOpen({
          open: true,
          message: "School day updated",
        });
      }
    } catch (error) {
      setDesignation(designation || (schoolDay?.day_template_id ?? "-1"));
      setSnackBarOpen({
        open: true,
        message:
          error?.response?.data?.errors?.designation[0] ||
          error?.response?.data?.message,
        severity: "error",
      });
    }
  };

  const handleCommentChange = async (event) => {
    setComment(event.target.value);
  };

  const handleCommentBlur = async (event) => {
    const params = {
      ...schoolDay,
      comment: event.target.value,
    };

    try {
      const response = await schoolDayService.update(params);

      if (response.data) {
        setSnackBarOpen({
          open: true,
          message: "Comment updated",
        });
      }
    } catch (error) {
      setSnackBarOpen({
        open: true,
        message:
          error?.response?.data?.errors?.designation[0] ||
          error?.response?.data?.message,
        severity: "error",
      });
    }
  };

  const handleAdd = () => {
    setAddCommentMode(true);
  };

  const boxColor = () => {
    if (!shouldShowSchoolDay) {
      setColor("inherit");
    }

    if (
      dayTemplates.some(
        (dayTemplate) =>
          `${dayTemplate.id}-${dayTemplate.created_at}` === designation
      ) ||
      schoolDay?.school_day_designation?.category === "school_day"
    ) {
      setColor("grey");
    } else if (
      schoolDay?.school_day_designation !== undefined ||
      options.some(
        (option) => `${option.id}-${option.created_at}` === designation
      )
    ) {
      setColor("red");
    } else {
      setColor("grey");
    }
  };

  useEffect(() => {
    boxColor();
  }, [designation]);

  return (
    <Grid item xs={1.7} mt={2} sx={shouldShowSchoolDay ? dayStyle : {}}>
      <Box sx={boxStyle(color)}>
        <Typography>
          {shouldShowSchoolDay ? new Date(schoolDay.date).getUTCDate() : ""}
        </Typography>
        {shouldShowSchoolDay && (
          <Grid container direction="column" spacing={2} py={1}>
            <Grid item md={12}>
              <FormControl fullWidth size="small" sx={{ maxWidth: "140px" }}>
                <Select
                  value={designation || ""}
                  onChange={handleChange}
                  disabled={!managePermission}
                >
                  {options.map((option) => (
                    <MenuItem
                      key={option.category}
                      value={`${option.id}-${option.created_at}`}
                    >
                      {option.name}{" "}
                      {option.category === "school_day" && "(School Day)"}
                    </MenuItem>
                  ))}
                  <MenuItem disabled value="-1">
                    Day Templates
                  </MenuItem>
                  {dayTemplates.map((dayTemplate) => (
                    <MenuItem
                      key={dayTemplate.id}
                      value={`${dayTemplate.id}-${dayTemplate.created_at}`}
                      sx={{
                        paddingLeft: 4,
                      }}
                    >
                      {dayTemplate.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={12} display="flex" justifyContent="end">
              {addCommentMode ? (
                <FormControl fullWidth size="small" sx={{ width: "100%" }}>
                  <TextField
                    value={comment}
                    onChange={handleCommentChange}
                    onBlur={handleCommentBlur}
                    placeholder="Comment"
                    inputProps={{
                      sx: { padding: "0.5rem" },
                    }}
                  />
                </FormControl>
              ) : (
                <IconButton onClick={handleAdd} disabled={!managePermission}>
                  <AddIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>
        )}
      </Box>
    </Grid>
  );
}
