import {
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { ProcessBuilderContext } from "../ProcessBuilderContext";
import { renderError } from "../../../../../utils/constants/forms";

export default function ProcessStageProperties() {
  const { process, currentStageIndex, updateProcess } = useContext(
    ProcessBuilderContext
  );

  const [dateValue, setDateValue] = useState(
    process.stages[currentStageIndex].deadline || null
  );

  const [includeInTimeline, setIncludeInTimeline] = useState(
    process.stages[currentStageIndex].includeInTimeline
  );

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
    control,
  } = useForm({
    defaultValues: {
      name: process.stages[currentStageIndex].name,
      description: process.stages[currentStageIndex].description,
      deadline: process.stages[currentStageIndex].deadline,
      includeInTimeline: process.stages[currentStageIndex].includeInTimeline,
    },
  });

  const formWatcher = useWatch({
    control,
  });

  useEffect(() => {
    const stage = process.stages[currentStageIndex];
    if (stage) {
      reset(
        {
          name: stage.name,
          description: stage.description,
          deadline: stage.deadline,
          includeInTimeline: stage.includeInTimeline,
        },
        { keepTouched: true, keepDirty: true }
      );
    }
    setDateValue(stage.deadline);
    setIncludeInTimeline(stage.includeInTimeline);
  }, [currentStageIndex]);

  const onSubmit = (data) => {
    const updatedStages = [...process.stages];

    // Update the specific section by adding the new field
    updatedStages[currentStageIndex] = {
      ...updatedStages[currentStageIndex],
      ...data,
    };

    // Update the process with the new stages array
    updateProcess({ ...process, stages: updatedStages });
  };

  const onDateChanged = (date) => {
    if (date) {
      setDateValue(date);
      setValue("deadline", date.format("MM/DD/YYYY"), {
        shouldDirty: true,
      });
    }
  };

  useEffect(() => {
    if (Object.keys(formWatcher).length) onSubmit(formWatcher);
  }, [formWatcher]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        rowGap={4}
        boxShadow="0px 4px 50px 0px rgba(29, 182, 231, 0.12)"
        borderRadius={1}
        padding={2.5}
      >
        <Grid item md={12}>
          <Typography variant="body1" align="center">
            STAGE PROPERTIES
          </Typography>
        </Grid>
        <Grid item md={12}>
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField {...field} fullWidth label="NAME" />
            )}
          />
        </Grid>
        <Grid item md={12}>
          <Controller
            name="description"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField {...field} fullWidth label="DESCRIPTION" />
            )}
          />
        </Grid>
        <Grid item md={8}>
          <DesktopDatePicker
            onChange={onDateChanged}
            label="Deadline"
            inputFormat="MM/DD/YYYY"
            value={dateValue}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ width: "100%" }}
                {...register("deadline", {
                  pattern: {
                    value:
                      /(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/(19|20)\d{2}/,
                    message: "Invalid date.",
                  },
                })}
              />
            )}
          />
          {errors.deadline && renderError(errors.deadline.message)}
        </Grid>
        <Grid item md={12}>
          <Typography variant="body1">Options</Typography>
          <FormControlLabel
            sx={{ width: "100%" }}
            control={
              <Checkbox
                checked={includeInTimeline}
                onChange={(e) => {
                  setValue("includeInTimeline", e.target.checked, {
                    shouldDirty: true,
                  });
                  setIncludeInTimeline(e.target.checked);
                }}
              />
            }
            label="Include in timeline"
          />
        </Grid>
      </Grid>
    </form>
  );
}
