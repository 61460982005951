import axios from "../utils/axios-util";

const termsEndpoint = "/api/terms";

const fetchAllTermsInSchool = async (params) => {
  const { data } = await axios.get(termsEndpoint, params);

  if (!data.data) {
    console.error("Error fetching terms from the API");
    return [];
  }
  return data;
};

const create = async (params) => {
  const { data } = await axios.post(termsEndpoint, params);
  if (!data.data) {
    console.error("Error creating new term");
    return [];
  }
  return data;
};

const update = async (term) => {
  const { data } = await axios.put(`${termsEndpoint}/${term.id}`, term);
  if (!data.data) {
    console.error("Error updating term");
    return [];
  }
  return data;
};

const deleteTerm = async (id) => {
  const { data } = await axios.delete(`${termsEndpoint}/${id}`);
  if (!data.data) {
    console.error("Error deleting term");
    return [];
  }
  return data;
};

const fetchTerm = (termId) => axios.get(`${termsEndpoint}/${termId}`);

const updateDefault = (params) =>
  axios.put(`${termsEndpoint}/${params.term_id}/update_default`, params);

export default {
  fetchAllTermsInSchool,
  create,
  update,
  deleteTerm,
  fetchTerm,
  updateDefault,
};
