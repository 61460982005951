import React, { useState, useRef, useContext, useEffect } from "react";
import {
  Chip,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import {
  assignmentDetailCellName,
  assignmentDetailCellSmall,
  assignmentDetailLast,
  assignmentDetailScore,
  assignmentDetailScoreInput,
  assignmentDetailGoogleScore,
  assignmentDetailTurnedIn,
} from "./AssignmentRow.style";
import useGrade from "../../../hooks/useGrade";
import { black } from "../../sharedStyles";
import { assignmentDetailCommentInput } from "./AssignmentTable.style";
import assignmentService from "../../../service/assignmentService";
import PopoverComponent from "../../ToolTip/ToolTip";
import ValidInputCodes from "../../ToolTip/ValidInputCodes";
import PartialValidCodes from "../../ToolTip/PartialValidCodes";
import moment from "../../../utils/constants/momentConfig";
import useFormattedMarkingCodes from "../../../hooks/useFormattedMarkingCodes";
import { SnackbarContext } from "../../../context/SnackbarContext";
import fileService from "../../../service/fileService";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import { useUnsavedChanges } from "../../../context/UnsavedChangesContext";

export default function AssignmentRow({
  index,
  assignmentStudent,
  specialMarks,
  gradingInput,
  isGoogleAssignment,
  markingCodes,
  setTurnedInOnlineDeleteDialog,
  setAssignmentStudentId,
}) {
  const timeout = useRef();
  const [comment, setComment] = useState(assignmentStudent?.comment || "");
  const [score, setScore] = useState(assignmentStudent?.score || "");
  const { setUnsavedChanges } = useUnsavedChanges();
  const calculatedScore = () => {
    if (
      assignmentStudent?.assignment?.max_score &&
      score !== "" &&
      !specialMarks.filter((e) => e.code.toLowerCase() === score.toLowerCase())
        .length > 0
    ) {
      return (Number(score) * 100) / assignmentStudent.assignment.max_score;
    }
    return score;
  };

  const grade = useGrade(
    gradingInput === "points" ? calculatedScore() : score,
    assignmentStudent?.assignment?.max_score,
    markingCodes,
    specialMarks
  );

  const formattedMarkingCodes = useFormattedMarkingCodes(markingCodes);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showSpecialMarks, setShowSpecialMarks] = useState(false);
  const [currentAssignment, setCurrentAssignment] = useState(null);
  const [currentTarget, setCurrentTarget] = useState(null);
  const [isBackspace, setBackspace] = useState(false);
  const subjectId = useParams().subject_id;
  const schoolId = useParams().school_id;
  const snackbarContext = useRef(useContext(SnackbarContext));
  const [isUserTriggeredUpdate, setIsUserTriggeredUpdate] = useState(false);
  const timer = useRef();

  useEffect(() => {
    setScore(assignmentStudent?.score || "");
    setIsUserTriggeredUpdate(false);
  }, [assignmentStudent?.score]);

  const shouldShowToolTip = (value, isLetterGrade, validCode, isNumber) => {
    if (
      !isLetterGrade &&
      !isNumber &&
      !validCode.filter((a) => a === value.toLowerCase()).length > 0
    ) {
      return true;
    }

    if (
      isLetterGrade &&
      (!validCode.filter((a) => a === value.toLowerCase()).length > 0 ||
        isNumber)
    ) {
      return true;
    }

    return false;
  };

  const handleScoreChange = (_student, _assignment, value, target) => {
    setAnchorEl(null);
    setIsUserTriggeredUpdate(true);
    const isInSpecialMarks =
      specialMarks.filter((e) => e.code.toLowerCase() === value.toLowerCase())
        .length > 0;
    const isLetterGrade =
      assignmentStudent?.assignment?.grading === "letter_grade";
    setCurrentAssignment(assignmentStudent?.assignment);
    setCurrentTarget(target);
    const isNumber = /^(\d+\.?\d?)$/.test(value);
    const partialCode = PartialValidCodes(
      formattedMarkingCodes,
      specialMarks,
      isLetterGrade
    );
    const validCode = ValidInputCodes(
      formattedMarkingCodes,
      specialMarks,
      isLetterGrade
    ).concat(partialCode, "\\");
    if (
      !isBackspace &&
      shouldShowToolTip(value, isLetterGrade, validCode, isNumber)
    ) {
      setAnchorEl(target);

      return;
    }

    const enteredPartialCode = partialCode.includes(value.toLowerCase());

    // eslint-disable-next-line no-param-reassign
    assignmentStudent.score = value === "\\" ? "✓" : value.toUpperCase();
    setScore(value === "\\" ? "✓" : value.toUpperCase());

    clearTimeout(timeout.current);
    if (
      enteredPartialCode ||
      (!isLetterGrade &&
        !isNumber &&
        !isInSpecialMarks &&
        value !== "" &&
        !validCode.filter((a) => a === value.toLowerCase()).length > 0)
    ) {
      timeout.current = setTimeout(async () => {
        // eslint-disable-next-line no-param-reassign
        assignmentStudent.score = "";
        setAnchorEl(target);
      }, 2000);
    }
  };
  const handleCommentChange = (event) => {
    setIsUserTriggeredUpdate(true);
    setComment(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Backspace") {
      setBackspace(true);
    }

    if (event.key === "Enter") {
      event.preventDefault();

      const currentElement = event.target;
      const nextRowId =
        parseInt(currentElement.getAttribute("data-row").split("-")[2], 10) + 1;
      const nextInput = document.querySelector(
        `[data-row="assignment-row-${nextRowId}"]`
      );

      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const renderTurnedInIcon = () => {
    const dueDateTime = `${assignmentStudent?.assignment.due_date} ${assignmentStudent?.assignment.due_time}`;
    const turnedInAt = assignmentStudent?.lms_turned_in_at;

    if (
      moment(turnedInAt, "YYYY-MM-DD LT").isAfter(
        moment(dueDateTime, "YYYY-MM-DD LT")
      )
    ) {
      return <ErrorOutlineIcon color="warning" />;
    }

    return <CheckCircleOutlineIcon color="success" />;
  };

  const renderTurnedInOnlineIcon = () => {
    const dueDateTime = `${assignmentStudent?.assignment.due_date} ${assignmentStudent?.assignment.due_time}`;
    const turnedInOnlineAt = assignmentStudent?.turned_in_online_at;

    if (
      moment(turnedInOnlineAt, "YYYY-MM-DD LT").isAfter(
        moment(dueDateTime, "YYYY-MM-DD LT")
      )
    ) {
      return <ErrorIcon color="error" sx={{ mt: "8px" }} />;
    }

    return <DoneIcon sx={{ mt: "8px" }} />;
  };

  const handleFileDownload = async () => {
    await fileService.fetchTurnedInAssignmentFile({
      id: assignmentStudent?.turned_in_online_file_attachment_details?.id,
      student_id: assignmentStudent?.student_id,
      filename:
        assignmentStudent?.turned_in_online_file_attachment_details?.name,
      schoolID: schoolId,
    });
  };

  const handleTurnedInOnlineClick = async () => {
    if (assignmentStudent?.assignment.turn_in_online_using === "link") {
      let link = assignmentStudent?.turned_in_online_link;
      link = link?.match(/^https?:/) ? link : `//${link}`;
      window.open(link, "_blank", "noreferrer");
    } else {
      await handleFileDownload();
    }
  };

  const handleTurnedInOnlineDelete = () => {
    setTurnedInOnlineDeleteDialog(true);
    setAssignmentStudentId(assignmentStudent?.id);
  };

  useDidMountEffect(() => {
    // Skip if update is bulk update
    if (!isUserTriggeredUpdate) {
      return;
    }

    setUnsavedChanges(true);
    snackbarContext.current.setSnackbar({
      message: "Saving...",
      severity: "info",
      open: true,
    });
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      const data = {
        student_id: assignmentStudent?.student?.id,
        assignment_id: assignmentStudent?.assignment?.id,
        subject_id: subjectId,
        score,
        comment,
      };
      assignmentService
        .updateStudentAssignment(data)
        .then(() => {
          snackbarContext.current.setSnackbar({
            message: "Saved",
            severity: "success",
            open: true,
          });
          setUnsavedChanges(false);
        })
        .catch((err) => {
          setUnsavedChanges(false);
          snackbarContext.current.setSnackbar({
            message: err.response.data.message || "Error, try again later.",
            severity: "error",
            open: true,
          });
        });
    }, 3000);
  }, [score, comment]);

  return (
    <TableRow
      key={assignmentStudent?.student?.id}
      {...(index + 1 === assignmentStudent?.assignment?.students?.length && {
        sx: assignmentDetailLast,
      })}
    >
      {isGoogleAssignment && (
        <TableCell align="left" sx={assignmentDetailTurnedIn}>
          {assignmentStudent?.lms_turned_in && renderTurnedInIcon()}
        </TableCell>
      )}
      {assignmentStudent?.assignment?.turn_in_online && (
        <TableCell align="left" sx={assignmentDetailTurnedIn}>
          {assignmentStudent?.turned_in_online_at && renderTurnedInOnlineIcon()}
        </TableCell>
      )}
      <TableCell align="left" sx={assignmentDetailCellName}>
        <Typography>
          {`${assignmentStudent?.student.last_name}, ${assignmentStudent?.student.first_name}`
            ?.replace(/null|NULL/g, " ")
            ?.replace(/\s+/g, " ")
            ?.trim()}
        </Typography>
      </TableCell>
      {isGoogleAssignment && (
        <TableCell align="center" sx={assignmentDetailGoogleScore}>
          <Typography sx={black}>{assignmentStudent?.google_score}</Typography>
        </TableCell>
      )}
      {gradingInput === "points" && (
        <TableCell align="center" sx={assignmentDetailCellSmall}>
          <Typography sx={black}>
            {assignmentStudent?.assignment?.max_score}
          </Typography>
        </TableCell>
      )}
      <TableCell align="center" sx={assignmentDetailScore}>
        <TextField
          variant="outlined"
          inputProps={{
            min: 0,
            style: {
              textAlign: "center",
            },
            id: assignmentStudent?.student?.id,
            className: "assignment_score_input",
            "data-row": `assignment-row-${index}`,
          }}
          sx={assignmentDetailScoreInput}
          value={score}
          onChange={(e) =>
            handleScoreChange(
              assignmentStudent?.student,
              assignmentStudent?.assignment,
              e.target.value,
              e.currentTarget
            )
          }
          onKeyDown={handleKeyDown}
          onKeyUp={() => setBackspace(false)}
        />
        <PopoverComponent
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          showSpecialMarks={showSpecialMarks}
          setShowSpecialMarks={setShowSpecialMarks}
          handleClick={handleScoreChange}
          student={assignmentStudent?.student}
          currentAssignment={currentAssignment}
          currentTarget={currentTarget}
          specialMarks={specialMarks}
          markingCodes={markingCodes}
          maxScore={assignmentStudent?.assignment?.max_score}
        />
      </TableCell>
      <TableCell align="center" sx={assignmentDetailCellSmall}>
        <Typography sx={black}>{grade}</Typography>
      </TableCell>
      <TableCell>
        <TextField
          fullWidth
          placeholder="Comment"
          sx={assignmentDetailCommentInput}
          value={comment}
          onChange={handleCommentChange}
          inputProps={{ maxLength: 500 }}
        />
      </TableCell>
      {assignmentStudent?.assignment?.turn_in_online && (
        <TableCell sx={{ width: 0 }}>
          {assignmentStudent?.turned_in_online_at && (
            <Chip
              label="View Assignment"
              onClick={handleTurnedInOnlineClick}
              onDelete={handleTurnedInOnlineDelete}
            />
          )}
        </TableCell>
      )}
    </TableRow>
  );
}
