const enrollmentChecklist = {
  backgroundColor: "white",
  minHeight: "inherit",
  p: 5,
  color: "black",
  mt: 5,
  borderRadius: "6px",
  boxShadow: "0px 0px 20px 0px rgba(0,0,0,0.1)",
  width: "1280px",
  mx: "auto",
};

const enrollmentChecklistStatusLabel = {
  fontSize: 16,
  color: "#EF7878",
  fontWeight: 500,
};

const processesButtonGroup = (activeProcess, isFirst, isLast) => ({
  backgroundColor: activeProcess ? "#2196F3" : "#F4F4F4",
  color: activeProcess ? "white" : "#2196F3",
  "&:hover": {
    backgroundColor: "#2196F3",
    color: "white",
  },
  "&.MuiButtonGroup-grouped:not(:last-of-type)": {
    border: "none",
  },
  borderTopLeftRadius: isFirst ? "15px" : "0",
  borderBottomLeftRadius: isFirst ? "15px" : "0",
  borderTopRightRadius: isLast ? "15px" : "0",
  borderBottomRightRadius: isLast ? "15px" : "0",
  fontWeight: "normal",
  textTransform: "uppercase",
});

const messageContainer = {
  width: "40%",
  borderRight: "1px solid #8A8A8A",
  pr: "20px",
  mr: "20px",
};

const messageContainerTitle = {
  fontSize: "24px",
  mb: "30px",
  lineHeight: "32px",
};

const enrollmentChecklistTab = {
  fontSize: "20px !important",
  fontWeight: "500 !important",
  "&:hover": {
    color: "#2196F3",
    opacity: 1,
  },
};

export {
  enrollmentChecklist,
  enrollmentChecklistStatusLabel,
  processesButtonGroup,
  messageContainer,
  messageContainerTitle,
  enrollmentChecklistTab,
};
