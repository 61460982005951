const addFileFormDocumentTitle = {
  borderBottom: "1px solid #BFC7CA",
  fontSize: "18px",
};

const addFileFormDocumentBtn = {
  color: "#2196F3",
  textTransform: "none",
  mt: "15px",
};

export { addFileFormDocumentTitle, addFileFormDocumentBtn };
