import FieldBase from "./FieldBase";

export default class CheckboxField extends FieldBase {
  constructor(field) {
    super(field);
    this.label = field.label;
    this.dataType = "value_boolean";

    this.properties.defaultChecked = field.properties?.defaultChecked || false;
    this.propertyComponents = ["name", "label", "supporting-text"];
  }
}
