import React, { useContext, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Box, Drawer } from "@mui/material";
import Collections from "./Collections";
import Filters from "./Filters/Filters";
import PortfolioTable from "./PortfolioTable";
import AddFileForm from "./AddFileForm";
import { QuickBarContext } from "../../../context/QuickBarContext";
import { rightDrawerContainer } from "../../sharedStyles";
import SnackBarNotification from "../../SnackBarNotification";
import portfolioCollectionsService from "../../../service/portfolioCollectionsService";
import APP_PERMISSIONS from "../../../utils/constants/permissions";
import { PermissionsContext } from "../../../context/PermissionsContext";
import Authorize from "../../Authorize";

export default function StudentInfoPortfolio() {
  const [tableColumns, setTableColumns] = useState([
    {
      id: "name",
      name: "Name",
      visible: true,
    },
    {
      id: "type",
      name: "Type",
      visible: true,
    },
    {
      id: "date",
      name: "Date",
      visible: true,
    },
    {
      id: "added-by",
      name: "Added By",
      visible: true,
    },
    {
      id: "year",
      name: "Year",
      visible: true,
    },
    {
      id: "note",
      name: "Note",
      visible: true,
    },
  ]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [collections, setCollections] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [activeFile, setActiveFile] = useState(null);
  const [snackBarOpen, setSnackBarOpen] = useState({
    open: false,
    message: null,
  });

  const collectionsParams = searchParams.get("collections");
  const quickBarContext = useContext(QuickBarContext);
  const { hasAnyPermissionType } = useContext(PermissionsContext);

  const portfolioFilesPermission = hasAnyPermissionType(
    APP_PERMISSIONS.STUDENT_PORTFOLIO
  );

  const getCollections = async () => {
    const response = await portfolioCollectionsService.fetchAll();

    if (response.data) {
      const returnedCollections = response.data.map((collection, i) => ({
        ...collection,
        isActive: false,
        index: i,
      }));

      if (collectionsParams) {
        const filteredCollections = [...returnedCollections];
        const formattedParams = collectionsParams
          .split(",")
          .map((p) => Number(p));

        filteredCollections
          .filter((c) => formattedParams.includes(c.id))
          // eslint-disable-next-line no-return-assign
          .map((f) => (filteredCollections[f.index].isActive = true));

        setCollections(filteredCollections);
      } else {
        setCollections(returnedCollections);
      }
    }
  };

  const handleEditDrawer = (file) => {
    setDrawerOpen(true);
    setActiveFile(file);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setActiveFile(null);
  };

  useEffect(() => {
    if (quickBarContext.addStudentPortfolioFiles) {
      setDrawerOpen(true);
      quickBarContext.cleanAddStudentPortfolioFiles();
    }
  });

  useEffect(() => {
    const columnsParam = searchParams.get("columns");

    if (columnsParam) {
      const selectedColumns = columnsParam ? columnsParam.split(",") : [];
      const updatedColumns = tableColumns.map((column) => ({
        ...column,
        visible:
          columnsParam === "hide-all"
            ? false
            : selectedColumns.includes(column.id),
      }));
      setTableColumns(updatedColumns);
    }
  });

  useEffect(() => {
    getCollections();
  }, [snackBarOpen]);

  return (
    <Authorize permission={portfolioFilesPermission}>
      <Collections collections={collections} />
      <Filters
        tableColumns={tableColumns}
        setTableColumns={setTableColumns}
        setSearchParams={setSearchParams}
        searchParams={searchParams}
      />
      <PortfolioTable
        drawerOpen={drawerOpen}
        handleEditDrawer={handleEditDrawer}
        tableColumns={tableColumns}
      />
      <Drawer open={drawerOpen} anchor="right">
        <Box sx={rightDrawerContainer}>
          <AddFileForm
            setDrawerOpen={setDrawerOpen}
            setSnackBarOpen={setSnackBarOpen}
            activeFile={activeFile}
            handleDrawerClose={handleDrawerClose}
            collections={collections}
          />
        </Box>
      </Drawer>
      <SnackBarNotification
        message={snackBarOpen.message}
        open={snackBarOpen.open}
        severity={snackBarOpen.severity}
        handleClose={() => setSnackBarOpen(false)}
      />
    </Authorize>
  );
}
