const StageElementType = {
  FORM: "FORM",
  FILE: "FILE",
  FEE: "FEE",
  TASK: "TASK",
  EVENT: "EVENT",
  ALERT: "ALERT",
};

export default StageElementType;
