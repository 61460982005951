import {
  Alert,
  Box,
  Divider,
  FormControl,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useOutletContext, useSearchParams } from "react-router-dom";
import { PermissionsContext } from "../../context/PermissionsContext";
import gradingScaleService from "../../service/gradingScaleService";
import APP_PERMISSIONS from "../../utils/constants/permissions";
import Authorize from "../Authorize";
import {
  codeFieldStyle,
  dividerStyle,
  gradingScaleName,
  minValueStyle,
  valueFieldStyle,
} from "./GradingScales.style";
import SaveChangesPopper from "../SaveChangesPopper";
import PERMISSION_TYPES from "../../utils/constants/permission_types";

function GradingScales() {
  const [, currentSchool] = useOutletContext();
  const [searchParams] = useSearchParams();
  const [gradingScales, setGradingScales] = useState([]);
  const schoolYearId = searchParams.get("school_year");
  const { hasAnyPermissionType, hasPermission } =
    useContext(PermissionsContext);
  const managePermission = hasPermission(
    APP_PERMISSIONS.GRADING_SCALES,
    PERMISSION_TYPES.MANAGE
  );
  const [openPopper, setOpenPopper] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState({
    open: false,
    message: "",
    severity: null,
  });

  const getGradingScales = async () => {
    const response = await gradingScaleService.fetchAllGradingScales({
      params: {
        school_year_id: schoolYearId,
      },
    });

    if (response.data) {
      let resData = response.data;
      if (!currentSchool.has_secondary_students) {
        resData = response.data.filter((d) => d.name !== "9-12");
      }

      const formattedGradingScales = resData.map((gradingScale) => {
        if (gradingScale.name === "3-8" || gradingScale.name === "9-12") {
          const customOrder = [
            "A+",
            "A",
            "A-",
            "B+",
            "B",
            "B-",
            "C+",
            "C",
            "C-",
            "D+",
            "D",
            "D-",
            "P",
            "F",
          ];
          const sortedMarkingCodes = gradingScale.marking_codes.sort((a, b) => {
            const indexA = customOrder.indexOf(a.code);
            const indexB = customOrder.indexOf(b.code);
            return indexA - indexB;
          });
          return { ...gradingScale, marking_codes: sortedMarkingCodes };
        }
        const sortedMarkingCodes = gradingScale.marking_codes.sort(
          (a, b) => a.id - b.id
        );
        return { ...gradingScale, marking_codes: sortedMarkingCodes };
      });
      setGradingScales(formattedGradingScales);
    }
  };

  const handleChange = (event, type, markingCodeId) => {
    const { value } = event.target;
    setGradingScales((prevGradingScales) => {
      const updatedGradingScales = prevGradingScales.map((gradingScale) => {
        const updatedMarkingCodes = gradingScale.marking_codes.map(
          (markingCode) => {
            if (markingCode.id === markingCodeId) {
              return { ...markingCode, [type]: value };
            }
            return markingCode;
          }
        );
        return { ...gradingScale, marking_codes: updatedMarkingCodes };
      });
      return updatedGradingScales;
    });
    setOpenPopper(true);
  };

  const handleSave = async () => {
    try {
      const response = await gradingScaleService.updateMarkingCodes({
        grading_scales: gradingScales,
      });
      if (response.data) {
        setSnackBarOpen({ open: true, message: "Saved successfully." });
      }
      setOpenPopper(false);
    } catch (e) {
      setSnackBarOpen({
        open: true,
        message: "An error occurred. Please try again.",
        severity: "error",
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarOpen({ message: "", open: false });
  };

  useEffect(() => {
    (async () => {
      await getGradingScales();
    })();
  }, [schoolYearId]);

  return (
    <Authorize
      permission={hasAnyPermissionType(APP_PERMISSIONS.GRADING_SCALES)}
    >
      <Box sx={{ display: "flex" }}>
        {gradingScales.map((gradingScale) => (
          <Box sx={{ width: "33%" }} mt={5} key={gradingScale.id}>
            <Typography sx={gradingScaleName}>{gradingScale.name}</Typography>
            {gradingScale.marking_codes.map((markingCode, index) => (
              <div key={markingCode.id}>
                {markingCode.code === "P" && <Divider sx={dividerStyle} />}
                <Box key={markingCode.id} mb={2}>
                  <FormControl>
                    {index === 0 && (
                      <Typography sx={minValueStyle}>&nbsp;</Typography>
                    )}
                    <TextField
                      placeholder="Code"
                      defaultValue={markingCode.code || ""}
                      sx={codeFieldStyle}
                      onChange={(e) => handleChange(e, "code", markingCode.id)}
                      disabled={!managePermission}
                    />
                  </FormControl>
                  <FormControl>
                    {index === 0 && (
                      <Typography sx={minValueStyle}>MIN. VALUE</Typography>
                    )}
                    <TextField
                      placeholder="Value"
                      defaultValue={markingCode.value || ""}
                      sx={valueFieldStyle}
                      onChange={(e) => handleChange(e, "value", markingCode.id)}
                      disabled={!managePermission}
                    />
                  </FormControl>
                </Box>
              </div>
            ))}
          </Box>
        ))}
        <SaveChangesPopper
          open={openPopper}
          setOpen={setOpenPopper}
          handleSave={handleSave}
        />
        <Snackbar
          open={snackBarOpen.open}
          autoHideDuration={5000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={snackBarOpen.severity}>
            {snackBarOpen.message}
          </Alert>
        </Snackbar>
      </Box>
    </Authorize>
  );
}

export default GradingScales;
