import { pageTabs, pageTabsContainer } from "../../../../../sharedStyles";

const fieldSetContainer = {
  padding: "0 !important",
  minHeight: "25rem",
  position: "sticky",
  top: 0,
};

const tabsContainer = {
  ...pageTabsContainer,
  borderBottom: "1px solid #b5b5b5",
};

const tabsStyle = {
  ...pageTabs,
  "& .Mui-selected": {
    color: "#2196F3 !important",
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "#2196F3 !important",
  },
  "& .MuiTab-root": {
    padding: 0,
    textTransform: "none",
    minWidth: "65px",
    mr: 0,
    fontSize: "22px",
    fontWeight: "normal",
    color: "black",
  },
  "& .MuiTabs-scroller": {
    width: "100%",
    "& .MuiTabs-flexContainer": {
      width: "100%",
      justifyContent: "space-around",
    },
  },
};

export { tabsContainer, fieldSetContainer, tabsStyle };
