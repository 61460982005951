const reportCardPreviewArrowContainer = {
  mb: "20px",
};

const reportCardPreviewPrevArrow = {
  mr: "50px",
  cursor: "pointer",
};

const reportCardPreviewNxtArrow = {
  cursor: "pointer",
};

const reportCardPreviewClose = {
  position: "absolute",
  right: 0,
  mr: "20px",
};

const previewText = {
  position: "sticky",
  top: "300px",
  textAlign: "center",
  color: "rgba(255, 206.13, 229.59, 0.20)",
  fontSize: 300,
  fontFamily: "Halcom",
  fontWeight: "900",
  zIndex: "-1",
};

const previewBox = {
  pb: "20px",
  mt: "-430px",
};

export {
  reportCardPreviewArrowContainer,
  reportCardPreviewPrevArrow,
  reportCardPreviewNxtArrow,
  reportCardPreviewClose,
  previewText,
  previewBox,
};
