const formDivider = {
  borderBottom: "1px solid #BFC7CA",
  py: "25px",
};

const formTitle = {
  fontSize: "28px",
};

const formRow = {
  mb: "20px",
};

const formStudentsAvatar = {
  width: "50px",
};

const formStudentsCheckbox = {
  width: "46px",
};

const formStudentsTable = {
  "& th": {
    color: "black",
    borderBottom: "none",
  },
  "& td": {
    color: "black",
    py: "10px",
  },
};

const borderRight = { borderRight: "solid 1px rgba(191, 199, 202, 1)" };
const filtersBorder = {
  borderTop: "solid 1px rgba(191, 199, 202, 1)",
  borderBottom: "solid 1px rgba(191, 199, 202, 1)",
  borderRight: "solid 1px rgba(191, 199, 202, 1)",
  paddingTop: "0px !important",
  paddingLeft: "0px !important",
};

const assignmentsFilterButton = (active = false) => ({
  borderRadius: 0,
  borderRight: "1px solid black",
  textTransform: "capitalize",
  color: active ? "white" : "#49454F",
  maxWidth: "35px",
  maxHeight: "35px",
  minWidth: "35px",
  minHeight: "35px",
  backgroundColor: active ? "black" : "white",
  "&:hover": {
    backgroundColor: active ? "black" : "white",
    color: active ? "white" : "#49454F",
  },
});

const assignmentFiltersInner = {
  justifyContent: "space-between",
  alignItems: "center",
};

export {
  formDivider,
  formTitle,
  formRow,
  formStudentsAvatar,
  formStudentsCheckbox,
  formStudentsTable,
  borderRight,
  assignmentsFilterButton,
  assignmentFiltersInner,
  filtersBorder,
};
