import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import FolderSpecialOutlinedIcon from "@mui/icons-material/FolderSpecialOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import {
  collectionsContainer,
  collectionsCardsContainer,
  collectionsCard,
  collectionsCardTitle,
  collectionsIcon,
} from "./Collections.styles";
import SimpleCard from "../../SimpleCard";

export default function Collections({ collections }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleCollectionFilter = (collectionId) => {
    const filteredCollections = [...collections];
    const filteredCollection = filteredCollections.filter(
      (c) => c.id === collectionId
    )[0];
    filteredCollection.isActive = !filteredCollection.isActive;

    const urlParams = filteredCollections
      .filter((c) => c.isActive === true)
      .map((f) => f.id);
    searchParams.set("collections", urlParams);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (searchParams.get("collections") === "") {
      searchParams.delete("collections");
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  return (
    <Accordion sx={collectionsContainer}>
      <AccordionSummary>
        <FolderSpecialOutlinedIcon sx={collectionsIcon} />
      </AccordionSummary>
      <AccordionDetails sx={collectionsCardsContainer}>
        {collections.length > 0 ? (
          collections.map((collection) => (
            <Grid
              sx={collectionsCard}
              key="All"
              item
              xs={2}
              onClick={() => handleCollectionFilter(collection.id)}
            >
              <SimpleCard
                marginless
                compact
                isSelected={collection.isActive}
                title={
                  <Typography mb={3} sx={collectionsCardTitle}>
                    {collection.name}
                  </Typography>
                }
                content={
                  <Typography variant="title">
                    {collection.portfolio_collection_files.length}
                  </Typography>
                }
                icon={<FilterListOutlinedIcon color="action" />}
              />
            </Grid>
          ))
        ) : (
          <Typography>No collections available.</Typography>
        )}
      </AccordionDetails>
    </Accordion>
  );
}
