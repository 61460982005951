import React, { useEffect, useState, useRef, useContext } from "react";
import { useForm, FormProvider } from "react-hook-form";
import eventsService from "../../../../service/eventsService";
import EventsForm from "../../Events/EventsForm";
import { SnackbarContext } from "../../../../context/SnackbarContext";
import { StudentActionsContext } from "../../../../context/StudentActionsContext";

export default function EventUpdate({
  studentId,
  setIsEventDrawerOpen,
  fetchAllEvents = () => {},
  eventId,
}) {
  const snackbarContext = useRef(useContext(SnackbarContext));
  const methods = useForm();
  const [currentEvent, setCurrentEvent] = useState(null);
  const studentActionsContext = useContext(StudentActionsContext);

  const updateOnDrawerClose = (fetch) => {
    setIsEventDrawerOpen(false);
    if (fetch) {
      fetchAllEvents();
      studentActionsContext.fireMedicalEventsUpdated(true);
    }
  };

  const onSubmit = async (data) => {
    const response = await eventsService.updateEvent(studentId, eventId, data);

    if (response.data) {
      snackbarContext.current.setSnackbar({
        open: true,
        message: "Event updated.",
      });
      updateOnDrawerClose(true);
    }
  };

  const getEvent = async () => {
    const response = await eventsService.fetchEvent(studentId, eventId);
    if (response.data) {
      setCurrentEvent(response.data);
    }
  };

  const handleEventDelete = async () => {
    const response = await eventsService.deleteEvent(studentId, eventId);

    if (response.data.message === "Deleted successfully") {
      snackbarContext.current.setSnackbar({
        open: true,
        message: "Event deleted.",
      });
      updateOnDrawerClose(true);
    }
  };

  useEffect(() => {
    getEvent();
  }, []);

  return currentEvent ? (
    <FormProvider {...methods}>
      <EventsForm
        handleEventDelete={handleEventDelete}
        setIsEventDrawerOpen={setIsEventDrawerOpen}
        onSubmit={onSubmit}
        currentEvent={currentEvent}
      />
    </FormProvider>
  ) : (
    <p>Loading...</p>
  );
}
