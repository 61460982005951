import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Stack,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListSubheader,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { Controller, useFormContext } from "react-hook-form";
import { recipientsContainer, recipientsTitle } from "./Recipients.styles";
import { fetchGuardianRecipients } from "../../../service/guardiansService";
import { danger } from "../../sharedStyles";

export default function Recipients({ setRecipientsValue }) {
  const { t } = useTranslation(["messages", "common"]);
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();
  const [recipients, setRecipients] = useState([]);
  const params = useParams();
  const guardianId = params.id;

  useEffect(() => {
    (async () => {
      try {
        const response = await fetchGuardianRecipients(guardianId);
        if (response) {
          setRecipients(response.data);
        }
      } catch (e) {
        // handle catch
      }
    })();
  }, []);

  return (
    <Box>
      <Box sx={recipientsContainer}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography sx={recipientsTitle}>{t("recipient")}</Typography>
        </Stack>
      </Box>

      <Box>
        <Stack direction="row" alignItems="center">
          <Controller
            render={() => (
              <FormControl fullWidth>
                <InputLabel htmlFor="recipients">
                  {t("select", { ns: "common" })}
                </InputLabel>
                <Select
                  defaultValue=""
                  id="recipients"
                  label={t("select", { ns: "common" })}
                  error={errors?.recipients}
                  {...register("recipients", {
                    required: t("fieldRequired"),
                  })}
                >
                  {recipients.reduce(
                    (listItems, recipient) => [
                      ...listItems,
                      <ListSubheader key={recipient.school_name}>
                        {recipient.school_name}
                      </ListSubheader>,
                      ...recipient.data.map((data) => (
                        <MenuItem
                          key={data.id}
                          value={data.id}
                          sx={{ pl: "30px" }}
                          onClick={() =>
                            setRecipientsValue([
                              {
                                target: "single_teacher",
                                teacher_id: data.teacher?.id,
                                school_id: data.school.id,
                              },
                            ])
                          }
                        >
                          {data.non_student_teacher ? (
                            <>
                              {data.teacher?.first_name}{" "}
                              {data.teacher?.last_name}
                            </>
                          ) : (
                            <>
                              {data.teacher?.first_name}{" "}
                              {data.teacher?.last_name} (
                              <b>{t("student", { ns: "common" })}:</b>&nbsp;
                              {data.first_name} {data.last_name})
                            </>
                          )}
                        </MenuItem>
                      )),
                    ],
                    []
                  )}
                </Select>
              </FormControl>
            )}
            name="recipients"
            control={control}
          />
        </Stack>
        {errors.recipients && (
          <Typography sx={danger}>{errors.recipients.message}</Typography>
        )}
      </Box>
    </Box>
  );
}
