const boxAvatar = {
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  width: "200px",
};

const cellComment = {
  width: "350px",
};

const tableBodyFontColor = {
  fontSize: 14,
  color: "black",
  fontWeight: 400,
};

const tableBorderRight = {
  borderRight: "1px solid #e0e0e0",
};

const avatarImage = {
  borderRadius: "100px",
  width: "40px",
  height: "40px",
  objectFit: "cover",
  marginRight: "10px",
};

const postedIcon = (rowPosted) => ({
  color: rowPosted ? "#95D122" : "white",
  marginLeft: "-18px",
  marginTop: "-10px",
});

const displayFlex = {
  display: "flex",
};

const boxAge = {
  paddingTop: "8px",
};

const stickyCell = {
  position: "sticky",
  left: 0,
  zIndex: 98,
};

export {
  boxAvatar,
  cellComment,
  tableBodyFontColor,
  tableBorderRight,
  avatarImage,
  postedIcon,
  displayFlex,
  boxAge,
  stickyCell,
};
