import axios from "../utils/axios-util";

const endpoint = "/api/google_classroom";

const getRequest = async (params) => {
  const { data } = await axios.get(`${endpoint}/requests`, params);

  return data;
};

const sync = async (params) => {
  const { data } = await axios.get(`${endpoint}/sync`, params);

  return data;
};

export default {
  getRequest,
  sync,
};
