import { Box, Button, Container, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams, useOutletContext } from "react-router-dom";
import classScheduleService from "../../../service/classScheduleService";
import dateTimeFormats from "../../../utils/constants/dateTimeFormats";
import moment from "../../../utils/constants/momentConfig";
import { px0 } from "../../sharedStyles";
import {
  classSchedulesBtn,
  classSchedulesBtnActive,
  classSchedulesSubtitle,
  classSchedulesTitle,
  classSchedulesTitleSection,
  classSchedulesTop,
} from "./ClassScheduleDetails.styles";
import ClassScheduleListView from "./ClassScheduleListView";
import ClassSchedulesDayView from "./DayView/ClassSchedulesDayView";

export default function ClassScheduleDetails() {
  const params = useParams();
  const [activeViewMode, setActiveViewMode] = useState("class");
  const [classSchedule, setClassSchedule] = useState(null);
  const [periods, setPeriods] = useState([]);
  const [dayTemplate, setDayTemplate] = useState([]);
  const [classes, setClasses] = useState([]);
  const [classesFilter, setClassesFilter] = useState([]);
  const [teachersFilter, setTeachersFilter] = useState([]);
  const [studentsFilter, setStudentsFilter] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTrack, setSelectedTrack] = useState("");
  const [loading, setLoading] = useState(false);
  const defaultSelectedParam = (param) => {
    if (searchParams.get(param) && searchParams.get(param) !== "0") {
      return searchParams.get(param);
    }
    return "";
  };

  const [selectedClass, setSelectedClass] = useState(
    defaultSelectedParam("class")
  );
  const [selectedTeacher, setSelectedTeacher] = useState(
    defaultSelectedParam("teacher")
  );
  const [selectedStudent, setSelectedStudent] = useState(
    defaultSelectedParam("student")
  );
  const [order, setOrder] = useState(searchParams.get("direction") || "asc");
  const [orderBy, setOrderBy] = useState(searchParams.get("sort") || "name");
  const [drawerStatusEdit, setDrawerStatusEdit] = useState(false);
  const [
    // eslint-disable-next-line no-unused-vars
    selectedSchoolYear,
    // eslint-disable-next-line no-unused-vars
    setSelectedSchoolYear,
    // eslint-disable-next-line no-unused-vars
    termsDrawerStatus,
    // eslint-disable-next-line no-unused-vars
    setSnackBarOpen,
    // eslint-disable-next-line no-unused-vars
    dayTemplateDrawerStatus,
    // eslint-disable-next-line no-unused-vars
    schoolDaysDrawerStatus,
    // eslint-disable-next-line no-unused-vars
    classScheduleDrawerStatus,
    klassesDrawerStatus,
    bulkEnrollDrawerStatus,
    currentSchool,
  ] = useOutletContext();

  const isButtonActive = (viewMode) =>
    viewMode === activeViewMode ? classSchedulesBtnActive : classSchedulesBtn;

  const sortClasses = (_classSchedule) => {
    let list = [];
    if (orderBy === "name") {
      if (order === "asc") {
        list = _classSchedule?.klasses?.sort((a, b) =>
          a.name > b.name ? 1 : -1
        );
      } else {
        list = _classSchedule?.klasses?.sort((a, b) =>
          a.name < b.name ? 1 : -1
        );
      }
    }
    if (orderBy === "subject") {
      if (order === "asc") {
        list = _classSchedule?.klasses?.sort((a, b) =>
          a.subject.name > b.subject.name ? 1 : -1
        );
      } else {
        list = _classSchedule?.klasses?.sort((a, b) =>
          a.subject.name < b.subject.name ? 1 : -1
        );
      }
    }

    if (list) {
      setClassSchedule({ ..._classSchedule, klasses: list });
    }
  };

  const fetchClassSchedule = async (dayChanged = false) => {
    if (!bulkEnrollDrawerStatus && !drawerStatusEdit && !klassesDrawerStatus) {
      setLoading(true);
    }

    if (!bulkEnrollDrawerStatus && !drawerStatusEdit) {
      const response = await classScheduleService.fetchClassSchedule(
        params.school_id,
        params.class_schedule_id,
        searchParams.get("date"),
        selectedClass,
        selectedTeacher,
        selectedStudent,
        order,
        orderBy,
        selectedTrack
      );

      if (response.data) {
        setClassSchedule(response.data.klass_schedule);
        setPeriods(response.data.periods);
        setDayTemplate(response.data.day_template);
        setClasses(response.data.klasses);
        if (dayChanged) {
          setSelectedTrack(response.data.day_template?.tracks?.[0]);
        }
        setLoading(false);
      }
    }
  };

  const fetchFilters = async () => {
    const response = await classScheduleService.fetchFiltersBySchedule(
      params.class_schedule_id,
      params.school_id
    );
    if (response.data) {
      setClassesFilter(response.data.klasses);
      setTeachersFilter(response.data.teachers);
      setStudentsFilter(response.data.students);
    }
  };

  useEffect(() => {
    fetchFilters();
  }, []);

  useEffect(() => {
    sortClasses(classSchedule);
  }, [order, orderBy]);

  useEffect(() => {
    fetchClassSchedule();
  }, [
    drawerStatusEdit,
    klassesDrawerStatus,
    selectedClass,
    selectedTeacher,
    selectedStudent,
    bulkEnrollDrawerStatus,
    searchParams.get("sort"),
    searchParams.get("direction"),
    selectedTrack,
  ]);

  useEffect(() => {
    const dayChanged = true;
    fetchClassSchedule(dayChanged);
  }, [searchParams.get("date")]);

  useEffect(() => {
    if (selectedClass !== "") {
      setSelectedTeacher("");
      setSelectedStudent("");
    }
  }, [selectedClass]);

  useEffect(() => {
    if (selectedTeacher !== "") {
      setSelectedStudent("");
      setSelectedClass("");
    }
  }, [selectedTeacher]);

  useEffect(() => {
    if (selectedStudent !== "") {
      setSelectedTeacher("");
      setSelectedClass("");
    }
  }, [selectedStudent]);

  if (!classSchedule?.klasses?.length === 0) {
    return <Typography align="center">No classes found.</Typography>;
  }

  return (
    <>
      <Stack direction="row" sx={classSchedulesTop}>
        {classSchedule && (
          <>
            <Box sx={classSchedulesTitleSection}>
              <Typography sx={classSchedulesTitle}>
                {classSchedule?.name}
              </Typography>
              <Typography sx={classSchedulesSubtitle}>
                {`${moment(classSchedule?.term?.start_date).format(
                  dateTimeFormats.MMMDYYYY
                )} -
                ${moment(classSchedule?.term?.end_date).format(
                  dateTimeFormats.MMMDYYYY
                )}`}
              </Typography>
            </Box>
            <Box>
              <Button
                onClick={() => setActiveViewMode("class")}
                sx={isButtonActive("class")}
              >
                Class List
              </Button>
              <Button
                sx={isButtonActive("day")}
                onClick={() => setActiveViewMode("day")}
              >
                Day View
              </Button>
            </Box>
          </>
        )}
      </Stack>

      <Container maxWidth={false} variant="header">
        <Container maxWidth="lg" sx={px0}>
          {activeViewMode === "class" ? (
            <ClassScheduleListView
              loading={loading}
              classes={classes}
              order={order}
              orderBy={orderBy}
              setOrder={setOrder}
              setOrderBy={setOrderBy}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              schoolId={params.school_id}
              drawerStatusEdit={drawerStatusEdit}
              setDrawerStatusEdit={setDrawerStatusEdit}
              schoolYearId={selectedSchoolYear?.id}
              currentSchool={currentSchool}
            />
          ) : (
            <ClassSchedulesDayView
              term={classSchedule?.term}
              periods={periods}
              dayTemplate={dayTemplate}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              classes={classesFilter}
              selectedClass={selectedClass}
              setSelectedClass={setSelectedClass}
              teachers={teachersFilter}
              selectedTeacher={selectedTeacher}
              setSelectedTeacher={setSelectedTeacher}
              students={studentsFilter}
              selectedStudent={selectedStudent}
              setSelectedStudent={setSelectedStudent}
              selectedTrack={selectedTrack}
              setSelectedTrack={setSelectedTrack}
              fetchFilters={fetchFilters}
            />
          )}
        </Container>
      </Container>
    </>
  );
}
