import { Box, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { FormBuilderContext } from "../../FormBuilderContext";
import { cursorPointer } from "../../../../../sharedStyles";
import fieldPropertiesMap from "./fieldPropertiesMap";
import ValidityCondition from "./ValidityCondition";
import VisibilityCondition from "./VisibilityCondition";

export default function FieldProperties() {
  const formBuilderContext = useContext(FormBuilderContext);
  const { form, currentSectionIndex, currentPageIndex, updateForm } =
    formBuilderContext;

  const [selectedField, setSelectedField] = useState(null);

  const methods = useForm();

  const { control } = methods;

  const formWatcher = useWatch({
    control,
  });

  useEffect(() => {
    setSelectedField(null);
    if (formBuilderContext.selectedFieldId) {
      const field = form.formPages[currentPageIndex].sections[
        currentSectionIndex
      ].fields.find((fe) => fe.fieldId === formBuilderContext.selectedFieldId);

      setSelectedField(field);
      methods.reset(field.properties, { keepTouched: true, keepDirty: true });
    }
  }, [formBuilderContext.selectedFieldId]);

  const onSubmit = (data) => {
    const updatedSections = [...form.formPages[currentPageIndex].sections];
    let updatedField = updatedSections[currentSectionIndex].fields.find(
      (f) => f.fieldId === selectedField.fieldId
    );
    updatedField = {
      ...updatedField,
      properties: { ...updatedField.properties, ...data },
    };

    updatedField.label = data.label;

    const fieldIndex = updatedSections[currentSectionIndex].fields.findIndex(
      (f) => f.fieldId === selectedField.fieldId
    );

    updatedSections[currentSectionIndex].fields[fieldIndex] = updatedField;

    updateForm({ ...form, sections: updatedSections });
  };

  useEffect(() => {
    if (Object.keys(formWatcher).length) onSubmit(formWatcher);
  }, [formWatcher]);
  return (
    <FormProvider {...methods}>
      <Box position="sticky" top="0px">
        <Grid container borderBottom="1px solid #D3D4D5" pl={3} pb={2}>
          <Box display="flex" sx={cursorPointer}>
            <Typography fontSize={14}>Properties</Typography>
          </Box>
        </Grid>
        {selectedField && !!selectedField.propertyComponents.length && (
          <Grid container spacing={2} padding={2.5}>
            {selectedField.propertyComponents.map((property) => {
              const Component = fieldPropertiesMap[property];
              return <Component selectedField={selectedField} />;
            })}
            {!selectedField.properties.dbRequiredField && (
              <>
                <VisibilityCondition selectedField={selectedField} />
                <ValidityCondition selectedField={selectedField} />
              </>
            )}
          </Grid>
        )}
        {!selectedField && (
          <Grid container height="100%" padding={2.5} marginTop={3}>
            <Typography align="center">
              Select an element to view properties
            </Typography>
          </Grid>
        )}
      </Box>
    </FormProvider>
  );
}
