import { Checkbox, Grid, FormControlLabel } from "@mui/material";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

export default function IsDecimalComponent({ selectedField }) {
  const { register, setValue } = useFormContext();

  useEffect(() => {
    setValue("isDecimal", selectedField.properties.isDecimal);
  }, [selectedField]);

  return (
    <Grid item md={12}>
      <FormControlLabel
        sx={{ width: "100%" }}
        control={
          <Checkbox defaultChecked={selectedField.properties.isDecimal} />
        }
        label="Decimal"
        {...register("isDecimal")}
        disabled={selectedField.source_field_id}
      />
    </Grid>
  );
}
