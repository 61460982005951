import React, { useContext, useState } from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Box, Checkbox, Grid } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  SecurityGroupPermissionText,
  securityGroupRowText,
} from "./SecurityGroup.style";
import securitygGroupPermissionsService from "../../service/securitygGroupPermissionsService";
import { PermissionsContext } from "../../context/PermissionsContext";
import APP_PERMISSIONS from "../../utils/constants/permissions";
import PERMISSION_TYPES from "../../utils/constants/permission_types";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  borderBottom: `1px solid #BFC7CA`,
  "&:last-child": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({
    backgroundColor: "white",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  })
);

const AccordionDetails = styled(MuiAccordionDetails)(() => ({}));

export default function SecurityGroupPermissions({
  permissionsByCategory,
  securityGroupPermissions,
  selectedSecurityGroup,
  getSecurityGroupPermissions,
}) {
  const [expand, setExpand] = useState(new Set());
  const { hasPermission } = useContext(PermissionsContext);

  const hasManagePermission = hasPermission(
    APP_PERMISSIONS.SECURITY,
    PERMISSION_TYPES.MANAGE
  );

  const isExpanded = (key) => expand.has(key);

  const toggleAcordion = (key) => {
    const expandSet = new Set(expand);

    if (isExpanded(key)) {
      expandSet.delete(key);
      return setExpand(expandSet);
    }

    expandSet.add(key);
    return setExpand(expandSet);
  };

  const permissionChecked = (permission, type) => {
    if (securityGroupPermissions.has(permission.id)) {
      return securityGroupPermissions.get(permission.id)[type];
    }

    return false;
  };

  const updatePermission = async (permission, type) => {
    await securitygGroupPermissionsService.update({
      securityGroupId: selectedSecurityGroup.id,
      permissionId: permission.id,
      type,
    });

    await getSecurityGroupPermissions(selectedSecurityGroup);
  };

  const groupPermissionValue = (key, type, property) => {
    if (permissionsByCategory) {
      let hasOne = false;
      let missesOne = false;

      const groupPermissions = permissionsByCategory.get(key);

      if (groupPermissions && groupPermissions.length > 0) {
        groupPermissions.forEach((gp) => {
          const sgPermission = securityGroupPermissions.get(gp.id);
          if (
            sgPermission &&
            sgPermission[type] &&
            sgPermission[type] === true
          ) {
            hasOne = true;
          } else {
            missesOne = true;
          }
        });
      }

      if (hasOne && missesOne && property === "indeterminate") {
        return true;
      }

      if (hasOne && !missesOne && property === "checked") {
        return true;
      }

      if (!hasOne && missesOne && property === "checked") {
        return false;
      }
    }

    return false;
  };

  const updateGroupPermissions = async (category, type, e) => {
    try {
      await securitygGroupPermissionsService.updateGroup({
        securityGroupId: selectedSecurityGroup.id,
        category,
        type,
        value: e.target.checked,
      });
      await getSecurityGroupPermissions(selectedSecurityGroup);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {Array.from(permissionsByCategory.entries()).map(([key, category]) => (
        <React.Fragment key={key}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
              width: "100%",
            }}
          >
            <Grid
              container
              sx={{
                marginRight: "10px",
              }}
            >
              <Grid item sm={10} />
              {key === "App Settings" ? (
                <Grid item sm={2}>
                  <Typography sx={securityGroupRowText}>Manage</Typography>
                </Grid>
              ) : (
                <>
                  <Grid item sm={1}>
                    <Typography sx={securityGroupRowText}>View</Typography>
                  </Grid>
                  <Grid item sm={1}>
                    <Typography sx={securityGroupRowText}>Manage</Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
          <Accordion key={key} expanded={isExpanded(key)}>
            <AccordionSummary
              id="panel3d-header"
              expandIcon={
                isExpanded(key) ? (
                  <KeyboardArrowUpIcon onClick={() => toggleAcordion(key)} />
                ) : (
                  <KeyboardArrowRightIcon onClick={() => toggleAcordion(key)} />
                )
              }
              aria-controls="panel1a-content"
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                  width: "100%",
                }}
              >
                <Grid container>
                  <Grid item sm={10} onClick={() => toggleAcordion(key)}>
                    <Typography sx={SecurityGroupPermissionText}>
                      {key}
                    </Typography>
                  </Grid>
                  {key === "App Settings" ? (
                    <Grid item sm={2} container justifyContent="center">
                      <Checkbox
                        color="primary"
                        indeterminate={groupPermissionValue(
                          key,
                          "manage",
                          "indeterminate"
                        )}
                        checked={groupPermissionValue(key, "manage", "checked")}
                        onClick={(e) => {
                          updateGroupPermissions(key, "manage", e);
                        }}
                        disabled={!hasManagePermission}
                      />
                    </Grid>
                  ) : (
                    <>
                      <Grid item sm={1}>
                        <Checkbox
                          color="primary"
                          indeterminate={groupPermissionValue(
                            key,
                            "view",
                            "indeterminate"
                          )}
                          checked={groupPermissionValue(key, "view", "checked")}
                          onClick={(e) =>
                            updateGroupPermissions(key, "view", e)
                          }
                          disabled={!hasManagePermission}
                        />
                      </Grid>
                      <Grid item sm={1}>
                        <Checkbox
                          color="primary"
                          indeterminate={groupPermissionValue(
                            key,
                            "manage",
                            "indeterminate"
                          )}
                          checked={groupPermissionValue(
                            key,
                            "manage",
                            "checked"
                          )}
                          onClick={(e) =>
                            updateGroupPermissions(key, "manage", e)
                          }
                          disabled={!hasManagePermission}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item sm={10} />
                {key === "App Settings" ? (
                  <Grid item sm={2} container justifyContent="center">
                    <Typography sx={securityGroupRowText}>Manage</Typography>
                  </Grid>
                ) : (
                  <>
                    <Grid item sm={1}>
                      <Typography sx={securityGroupRowText}>View</Typography>
                    </Grid>
                    <Grid item sm={1}>
                      <Typography sx={securityGroupRowText}>Manage</Typography>
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid container>
                {category.map((permission) => (
                  <React.Fragment key={permission.id}>
                    {permission.category === "App Settings" ? (
                      <>
                        <Grid item sm={10}>
                          <Typography sx={{ paddingLeft: "35px" }}>
                            {permission.name}
                          </Typography>
                        </Grid>
                        <Grid container item sm={2} justifyContent="center">
                          <Checkbox
                            color="primary"
                            checked={permissionChecked(permission, "manage")}
                            onChange={() => {
                              updatePermission(permission, "manage");
                            }}
                            disabled={!hasManagePermission}
                          />
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item sm={10}>
                          <Typography sx={{ paddingLeft: "35px" }}>
                            {permission.name}
                          </Typography>
                        </Grid>
                        <Grid item sm={1}>
                          <Checkbox
                            color="primary"
                            checked={permissionChecked(permission, "view")}
                            onChange={() =>
                              updatePermission(permission, "view")
                            }
                            disabled={!hasManagePermission}
                          />
                        </Grid>
                        <Grid item sm={1}>
                          <Checkbox
                            color="primary"
                            checked={permissionChecked(permission, "manage")}
                            onChange={() =>
                              updatePermission(permission, "manage")
                            }
                            disabled={!hasManagePermission}
                          />
                        </Grid>
                      </>
                    )}
                  </React.Fragment>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </React.Fragment>
      ))}
    </div>
  );
}
